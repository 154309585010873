import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { DataItem, DataList, DataProgress } from '../../../../ui/data-item';
import { changePosition } from '../../../../../actions/details';

// Load actions, own fcts and components
import lang from '../../../../../lang';

class position extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAll: false,
    };

    this.renderposition = this.renderposition.bind(this);
  }

  renderposition() {
    let { positions, position, performance, details, changePosition } =
      this.props;
    const { showAll } = this.state;
    const { id, current_league, current_season, current_position } = details;
    const width = 90;

    let align = '';
    if (performance != {}) {
      if (performance.alignment >= 0 && performance.alignment <= 2)
        align = lang.align1;
      else if (performance.alignment > 2 && performance.alignment <= 4)
        align = lang.align2;
      else if (performance.alignment > 4 && performance.alignment <= 6)
        align = lang.align3;
      else if (performance.alignment > 6 && performance.alignment <= 8)
        align = lang.align4;
      else if (performance.alignment > 8 && performance.alignment <= 10)
        align = lang.align5;
    }

    if (!position.length) return null;

    // sort position
    position = _.orderBy(position, ['fraction_pc_'], ['desc']);

    const activePositionIndex =
      _.findIndex(position, { position_id: +current_position }) + 1;
    const sliceCount = activePositionIndex > 4 ? activePositionIndex : 4;

    const fullPosition = _.map(_.cloneDeep(position), position => ({
      ...position,
      description: _.get(positions, `${position.position_id}.description`, ''),
      position_name: _.get(
        positions,
        `${position.position_id}.position_name`,
        '',
      ),
    }));

    // get position names
    const positionElements = [
      ...fullPosition
        .map((position, p) => (
          <DataProgress
            key={p}
            desc={p === 0 ? lang.position : lang.otherpos}
            progress={position.fraction_pc_}
          >
            <Link
              onClick={() => {
                changePosition(`${position.position_id}`);
              }}
              className="data-text"
              activeClassName="active"
              to={`/details/player/${id}/${current_league}/${current_season}/summary?position=${position.position_id}`}
            >
              {position.description}
            </Link>
          </DataProgress>
        ))
        .slice(0, !showAll ? sliceCount : fullPosition.length),
      !showAll && fullPosition.length > sliceCount ? (
        <DataItem key="show-all">
          <a
            href="#"
            onClick={event => {
              event.preventDefault();
              this.setState({ showAll: true });
            }}
            className="data-text"
          >
            + Show all
          </a>
        </DataItem>
      ) : null,
      <DataItem key="align" text={align} desc={lang.alignment} />,
      /* , this.renderActualPerformance() */
    ];

    return (
      <div>
        <div className="row-new">
          <div className="col-new-auto" ref="canvasContainer">
            <PositionCanvas
              width={width}
              height={130}
              margin={{
                top: 10,
                right: 10,
                bottom: 30,
                left: 40,
              }}
              position={fullPosition}
            />
          </div>
          <DataList className="col-new-auto">{positionElements}</DataList>
        </div>
      </div>
    );
  }

  render() {
    return <div>{this.renderposition()}</div>;
  }
}

class PositionCanvas extends Component {
  componentDidMount() {
    this.renderCanvas();
  }

  renderCanvas() {
    if (typeof this.refs.posCanvas === 'undefined') return;

    const ctx = this.refs.posCanvas.refs.canvas.getContext('2d');
    const { position, width, height } = this.props;

    ctx.clearRect(0, 0, width, height);

    let fieldHeight = height - 10;
    let fieldWidth = fieldHeight / 1.5;
    let centerX = width / 2;
    let centerY = height / 2;

    const fieldPaddingX = 5; // centerX-fieldWidth/2
    const fieldPaddingY = centerY - fieldHeight / 2;
    const fieldColor = '#5e6e80';

    ctx.strokeStyle = fieldColor;
    ctx.lineWidth = '1';

    // field
    ctx.beginPath();
    ctx.rect(fieldPaddingX, fieldPaddingY, fieldWidth, fieldHeight);
    // 16m
    ctx.rect(
      fieldPaddingX + fieldWidth / 4,
      fieldPaddingY,
      fieldWidth / 2,
      fieldHeight / 6,
    );
    // 8m
    ctx.rect(
      fieldPaddingX + fieldWidth / 2 - fieldWidth / 8,
      fieldPaddingY,
      fieldWidth / 4,
      fieldHeight / 16,
    );
    // ctx.rect(fieldPaddingX,  fieldPaddingY+fieldHeight/2-fieldHeight/8, fieldWidth/14, fieldHeight/4);
    // 16m
    ctx.rect(
      fieldPaddingX + fieldWidth / 4,
      fieldHeight - fieldHeight / 6 + fieldPaddingY,
      fieldWidth / 2,
      fieldHeight / 6,
    );
    // ctx.rect(fieldWidth-fieldWidth/6+fieldPaddingX, fieldPaddingY+fieldHeight/4, fieldWidth/6, fieldHeight/2);
    // 8m
    // ctx.rect(fieldWidth-fieldWidth/14+fieldPaddingX, fieldPaddingY+fieldHeight/2-fieldHeight/8, fieldWidth/14, fieldHeight/4);
    ctx.rect(
      fieldPaddingX + fieldWidth / 2 - fieldWidth / 8,
      fieldHeight - fieldHeight / 16 + fieldPaddingY,
      fieldWidth / 4,
      fieldHeight / 16,
    );
    ctx.stroke();

    // center cirlce
    ctx.beginPath();
    ctx.arc(
      fieldPaddingX + fieldWidth / 2,
      fieldPaddingY + fieldHeight / 2,
      fieldHeight / 12,
      0,
      2 * Math.PI,
    );
    ctx.stroke();

    // seperate line
    ctx.beginPath();
    ctx.moveTo(fieldPaddingX, fieldPaddingY + fieldHeight / 2);
    ctx.lineTo(fieldPaddingX + fieldWidth, fieldPaddingY + fieldHeight / 2);
    ctx.stroke();

    position.map((d, i) => {
      const pos = d.position_name;
      const firstChar = pos.charAt(0);
      const secondChar = pos.charAt(1);
      const thirdChar = pos.charAt(2);
      let positionX = 0;
      let positionY = 0;

      if (pos == 'GK') positionX = fieldWidth / 2;
      else if (thirdChar != '' && thirdChar != 'B') {
        if (thirdChar == 'L') positionX = (fieldWidth / 6) * 2;
        else if (thirdChar == 'C') positionX = fieldWidth / 2;
        else if (thirdChar == 'R') positionX = (fieldWidth / 6) * 4;
      } else if (firstChar == 'L') positionX = (fieldWidth / 6) * 0.8;
      else if (firstChar == 'R') positionX = (fieldWidth / 6) * 5.2;
      else if (firstChar == 'C') positionX = fieldWidth / 2;

      if (pos == 'GK') positionY = fieldHeight - (fieldHeight / 4) * 0.3;
      else if (firstChar + secondChar == 'CB')
        positionY = fieldHeight - (fieldHeight / 4) * 0.8;
      else if (firstChar + secondChar == 'DM')
        positionY = fieldHeight - (fieldHeight / 4) * 1.5;
      else if (firstChar + secondChar == 'CM')
        positionY = fieldHeight - fieldHeight / 2;
      else if (firstChar + secondChar == 'AM')
        positionY = fieldHeight - (fieldHeight / 4) * 2.5;
      else if (firstChar + secondChar == 'FW')
        positionY = fieldHeight - (fieldHeight / 4) * 3.3;
      else if (pos == 'LWB' || pos == 'RWB')
        positionY = fieldHeight - (fieldHeight / 4) * 1.3;
      else if (secondChar == 'B')
        positionY = fieldHeight - (fieldHeight / 4) * 0.8;
      else if (secondChar == 'M') positionY = fieldHeight - fieldHeight / 2;
      else if (secondChar == 'W')
        positionY = fieldHeight - (fieldHeight / 4) * 3;

      // center cirlce
      ctx.beginPath();
      ctx.fillStyle = `rgba(1,186,140,${d.fraction_pc_ / 100})`;
      //
      ctx.strokeStyle = 'rgba(1,186,140,.5)';
      ctx.lineWidth = '1';
      ctx.arc(
        fieldPaddingX + positionX,
        fieldPaddingY + positionY,
        fieldHeight / 20,
        0,
        2 * Math.PI,
      );
      ctx.fill();
      ctx.stroke();
    });
  }

  render() {
    const { width, height } = this.props;
    return (
      <div>
        <Canvas ref="posCanvas" width={width} height={height} />
        {this.renderCanvas()}
      </div>
    );
  }
}

class Canvas extends Component {
  componentDidMount() {
    this.retinaCheck();
  }

  retinaCheck = () => {
    if (typeof this.refs.canvas === 'undefined' || this.props.width == 0)
      return;
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext('2d');

    if (window.devicePixelRatio > 1) {
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      canvas.width = canvasWidth * window.devicePixelRatio;
      canvas.height = canvasHeight * window.devicePixelRatio;
      canvas.style.width = `${canvasWidth}px`;
      canvas.style.height = `${canvasHeight}px`;

      ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
    }
  };

  render() {
    const { width, height } = this.props;

    return (
      <canvas
        ref="canvas"
        className="canvas"
        width={width}
        height={height}
        style={{ pointerEvents: 'none' }}
      />
    );
  }
}

export default connect(
  state => ({
    details: state.details,
  }),
  { changePosition },
)(position);
