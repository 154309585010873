import React, { Component } from 'react';
import Icon from '../../ui/svg-icon';
import { DragSource, DropTarget } from 'react-dnd';

class Position extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      player,
      coords,
      connectDropTarget,
      connectDragSource,
      isOver,
      isDragging,
      position,
    } = this.props;

    const clsIsOver = isOver ? 'hovered' : '';

    if (!player) {
      return connectDropTarget(
        <div
          className={`player-mark player-mark__no-player ${clsIsOver} ${position.position_name}`}
          style={{
            left: `${(1 - coords.y) * 100}%`,
            top: `${coords.x * 100}%`,
          }}
          onClick={this.props.showPopup}
        >
          <Icon name="plus" />
        </div>,
      );
    }

    return connectDragSource(
      connectDropTarget(
        <div
          className={`player-mark player-mark__with-player ${clsIsOver} ${
            position.position_name
          } ${isDragging ? 'inactive' : ''}`}
          style={{
            left: `${(1 - coords.y) * 100}%`,
            top: `${coords.x * 100}%`,
          }}
          onClick={this.props.showPopup}
        >
          <span className="rating">{player.season_rating}</span>
          <span className="name">{player.short_name}</span>
          {isOver && player ? <Icon className="swap" name="swap" /> : null}
        </div>,
      ),
    );
  }
}

export default DragSource(
  'player',
  {
    beginDrag(props) {
      return {
        player: { ...props.player },
        position: { ...props.position },
      };
    },
    endDrag(props, monitor, component) {
      if (!monitor.didDrop()) {
        props.setPlayer(props.position.id, null);
        return;
      }

      const dropResult = monitor.getDropResult();
      const newPosition = dropResult.position;
      const newPlayer = dropResult.player;

      const oldPlayer = props.player;
      const oldPosition = props.position;

      if (!newPlayer) {
        props.setPlayer(
          newPosition.id,
          oldPlayer.player_id,
          oldPosition.id,
          null,
        );
      } else {
        props.setPlayer(
          newPosition.id,
          oldPlayer.player_id,
          oldPosition.id,
          newPlayer.player_id,
        );
      }
    },
  },
  (connect, monitor) => ({
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    canDrag: monitor.canDrag(),
  }),
)(
  DropTarget(
    'player',
    {
      drop(props, monitor) {
        return props;
      },
    },
    (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver({ shallow: true }),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
      itemType: monitor.getItemType(),
      monitor,
    }),
  )(Position),
);
