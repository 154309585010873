import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import app from './app';
import auth from './auth';
import groups from './groups';
import overview from './overview';
import details from './details-old';
import compare from './compare';
import favorites from './favorites';
import shadowTeams from './shadow-teams';
import welcome from './welcome';
import shadowTabs from './shadow-tabs';
import notes from './notes';
import userTypes from './user-types';
import overviewRankings from './overview-rankings';
import search from './search';
import competitionLevel from './competition-level';
import loader from './loader';
import roles from './roles';
import users from './users';
import { createReducer } from '../store/reducers';

const rootReducer = {
  app,
  auth,
  groups,
  welcome,
  overview,
  overviewRankings,
  details,
  compare,
  routing: routerReducer,
  favorites,
  shadowTeams,
  shadowTabs,
  notes,
  search,
  userTypes,
  competitionLevel,
  loader,
  roles,
  users,
  ...createReducer(),
};

export default rootReducer;
