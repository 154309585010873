import _ from 'lodash';

import {
  CHANGE_FILTER,
  GET_LEAGUE,
  GET_OVERVIEW_LEAGUES,
  GET_USER_LEAGUE,
} from '../actions/competition-level';

const INITIAL_STATE = {
  selectedLeague: null,
  userLeague: null,
  overviewLeagues: [],
  filter: {
    firstLeagueId: null,
    leagueIds: [],
    countryIds: [],
    regionIds: [],
    division: [1, 5],
    rating: [0, 10],
    stability: [0, 10],
    dynamicZoom: true,
  },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_LEAGUE: {
      const league = _.get(action, 'payload.data.league', null);

      if (!league) return state;

      return {
        ...state,
        selectedLeague: league,
      };
    }
    case GET_USER_LEAGUE: {
      const league = _.get(action, 'payload.data.league', null);

      if (!league) return state;

      return {
        ...state,
        userLeague: league,
      };
    }
    case CHANGE_FILTER: {
      const { name, value } = action;

      return {
        ...state,
        filter: {
          ...state.filter,
          [name]: value,
        },
      };
    }
    case GET_OVERVIEW_LEAGUES: {
      const leagues = action.payload?.data?.leagues || [];

      return {
        ...state,
        overviewLeagues: leagues,
      };
    }
    default:
      return state;
  }
}
