import React, { useContext } from 'react';

import { ListItemContext } from './index';
import { FlattenListItem } from '../../../../../../../reducers/overview/types';

interface Props {
  onClick: (item: FlattenListItem) => void;
}

export function Cross(props: Props) {
  const { onClick } = props;
  const { item } = useContext(ListItemContext);

  return (
    <span
      className="icon-close"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClick(item);
      }}
    />
  );
}
