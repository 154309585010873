import {
  CHANGE_FAVORITES_CATEGORY,
  CHANGE_FAVORITES_FILTER,
  GET_FAVORITES,
} from '../constants';

const initState = {
  filter: {
    position: [],
    category: [],
    age: [0, 100],
    age_range: [0, 100],
  },
};

export default function (state = initState, action) {
  switch (action.type) {
    case GET_FAVORITES:
      const [data, mode] = action.payload;
      const { list, history } = data.data;

      return {
        ...state,
        [mode]: { list, history },
      };

    case CHANGE_FAVORITES_FILTER:
      const { name, values } = action;

      return {
        ...state,
        filter: {
          ...state.filter,
          [name]: values,
        },
      };

    case CHANGE_FAVORITES_CATEGORY:
      const { player_id, user_id, payload } = action;

      const index = state.player.list.findIndex(p => {
        return p.player_id == player_id && p.user_id == user_id;
      });
      const newList = [...state.player.list];
      newList[index] = {
        ...newList[index],
        category: payload,
      };

      return {
        ...state,
        player: {
          ...state.player,
          list: newList,
        },
      };

    default:
      return state;
  }
}
