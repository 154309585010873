import { RootState } from '../../types';
import { AppState } from './types';
import { createSelector } from '@reduxjs/toolkit';
import { ModeType } from '../../types/Dict';

const selectApp = (state: RootState): AppState => state.app;
const selectMode = (state, props: { mode: ModeType }): ModeType => props.mode;

export const selectLeagues = createSelector([selectApp], app => {
  return app.leagues;
});

export const selectCurrentSeason = createSelector(
  [selectApp, selectMode],
  (app, mode) => {
    return app.season_current[mode];
  },
);
