import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router';
import { BarChart, PolarChart } from 'd3-react-touch';

import lang from '../../../../lang';
import Image from '../../../ui/image';
import Icon from '../../../ui/svg-icon';
import Header from './header';

import LineUp from './lineup';

import { createBarChartValues, createPolarCharts } from '../../../../helper/';
import { COLOR_SCALE } from '../../../../constants';
import { parseMatchGoals } from '../../../../helper/goals';
import { database } from '../../../../constants/database';

class Summary extends Component {
  constructor(props) {
    super(props);

    this._renderGoals = this._renderGoals.bind(this);
    this._renderCards = this._renderCards.bind(this);
    this._renderBarCharts = this._renderBarCharts.bind(this);
    this._renderMatchDay = this._renderMatchDay.bind(this);
    this._renderBestPlayers = this._renderBestPlayers.bind(this);
  }

  _renderMatchDay() {
    const { matchDay = [], information = {} } = this.props.details;
    const { teams } = this.props.app;

    if (!matchDay.length || !_.size(information)) return null;

    const renderDayList = () =>
      matchDay.map((day, d) => {
        const {
          isExtraTimeGoals,
          isPenaltyGoals,
          homegoals,
          awaygoals,
          infoText,
        } = parseMatchGoals(day);

        return +day.homegoals === 99 || +day.awaygoals === 99 ? (
          <span
            key={d}
            className={`match-link ${
              +day.team_id_home === +information.team_id_home &&
              +day.team_id_away === +information.team_id_away
                ? 'highlight'
                : ''
            }`}
          >
            <span className="td">
              <span className="team">
                {day.team_id_home ? (
                  <Image
                    src={`${database.assets}teams/${day.team_id_home}.jpg`}
                    aspectRatio={0.769}
                  />
                ) : null}
                {_.get(teams, `${day.team_id_home}.abk`, null)}
              </span>
            </span>
            <span className="td td-goals">
              {+day.homegoals === 99 ? '-' : homegoals} :{' '}
              {+day.awaygoals === 99 ? '-' : awaygoals}
            </span>
            <span className="td text-right">
              <span className="team">
                {day.team_id_away ? (
                  <Image
                    src={`${database.assets}teams/${day.team_id_away}.jpg`}
                    aspectRatio={0.769}
                  />
                ) : null}
                {_.get(teams, `${day.team_id_away}.abk`, null)}
              </span>
            </span>
          </span>
        ) : (
          <Link
            to={`/details/match/${day.match_id}/${day.league_id}/${day.season_id}/summary`}
            key={d}
            className={`match-link ${
              +day.team_id_home === +information.team_id_home &&
              +day.team_id_away === +information.team_id_away
                ? 'highlight'
                : ''
            }`}
          >
            <span className="td">
              <span className="team">
                {day.team_id_home ? (
                  <Image
                    src={`${database.assets}teams/${day.team_id_home}.jpg`}
                    aspectRatio={0.769}
                  />
                ) : null}
                {_.get(teams, `${day.team_id_home}.abk`, null)}
              </span>
            </span>
            <span className="td td-goals">
              {+day.homegoals === 99 ? '-' : homegoals} :{' '}
              {+day.awaygoals === 99 ? '-' : awaygoals}
              {isExtraTimeGoals || isPenaltyGoals ? (
                <small className="goals-extra-info">{infoText}</small>
              ) : null}
            </span>
            <span className="td text-right">
              <span className="team">
                {day.team_id_away ? (
                  <Image
                    src={`${database.assets}teams/${day.team_id_away}.jpg`}
                    aspectRatio={0.769}
                  />
                ) : null}
                {_.get(teams, `${day.team_id_away}.abk`, null)}
              </span>
            </span>
          </Link>
        );
      });

    return <div className="match-matchdays">{renderDayList()}</div>;
  }

  _renderGoals() {
    const { information = {} } = this.props.details;
    const { playersMetaData } = this.props.app;
    let goals;

    if (!_.size(information) || !information.goals) return null;

    try {
      goals = _.sortBy(JSON.parse(information.goals), 'min');
    } catch (error) {
      console.info(`goals JSON in match ${information.match_id} is not valid`);
      console.error(error);
      return null;
    }

    if (!goals || !goals.length) {
      return <div className="no-events">No goals</div>;
    }

    const renderGoalsList = () =>
      goals.map((goal, i) => {
        if (
          (+information.team_id_home === +goal.team_id &&
            ['goal', 'penalty'].indexOf(goal.type_desc) !== -1) ||
          (+information.team_id_home !== +goal.team_id &&
            ['own'].indexOf(goal.type_desc) !== -1)
        ) {
          return (
            <tr key={i}>
              <td className="td-team-image">
                <Link
                  to={`/details/team/${goal.team_id}/summary`}
                  className="team-image"
                >
                  <Image
                    src={`${database.assets}teams/${goal.team_id}.jpg`}
                    aspectRatio={0.769}
                  />
                </Link>
              </td>
              <td className="td-min">
                {goal.min || +goal.min === 0
                  ? `${+goal.min === 0 ? 1 : goal.min}\``
                  : 'N/D'}
              </td>
              <td className="td-goals-player">
                <Link
                  to={`/details/player/${goal.player_id}/summary`}
                  className="player-link"
                >
                  {goal.player_id ? (
                    <Image
                      src={`${database.assets}players/${goal.player_id}.jpg`}
                      aspectRatio={0.769}
                    />
                  ) : null}
                  {_.get(playersMetaData, `${goal.player_id}.short_name`, null)}
                  {goal.type_desc === 'penalty' ? ' (P)' : ''}
                  {goal.type_desc === 'own' ? ' (OG)' : ''}
                </Link>
              </td>
              <td className="td-goals">
                {goal.homegoals} : {goal.awaygoals}
              </td>
              <td className="td-goals-player text-right">&nbsp;</td>
              <td className="td-min">&nbsp;</td>
              <td className="td-team-image">&nbsp;</td>
            </tr>
          );
        }
        return (
          <tr key={i}>
            <td className="td-team-image">&nbsp;</td>
            <td className="td-min">&nbsp;</td>
            <td className="td-goals-player">&nbsp;</td>
            <td className="td-goals">
              {goal.homegoals} : {goal.awaygoals}
            </td>
            <td className="td-goals-player text-right">
              <Link
                to={`/details/player/${goal.player_id}/summary`}
                className="player-link"
              >
                {_.get(playersMetaData, `${goal.player_id}.short_name`, null)}
                {goal.type_desc === 'penalty' ? ' (P)' : ''}
                {goal.type_desc === 'own' ? ' (OG)' : ''}
                {goal.player_id ? (
                  <Image
                    src={`${database.assets}players/${goal.player_id}.jpg`}
                    aspectRatio={0.769}
                  />
                ) : null}
              </Link>
            </td>
            <td className="td-min">
              {goal.min || +goal.min === 0
                ? `${+goal.min === 0 ? 1 : goal.min}\``
                : 'N/D'}
            </td>
            <td className="td-team-image">
              <Link
                to={`/details/team/${goal.team_id}/summary`}
                className="team-image"
              >
                <Image
                  src={`${database.assets}teams/${goal.team_id}.jpg`}
                  aspectRatio={0.769}
                />
              </Link>
            </td>
          </tr>
        );
      });

    return (
      <table className="match-goals">
        <tbody>{renderGoalsList()}</tbody>
      </table>
    );
  }

  _renderCards() {
    const { information = {} } = this.props.details;
    const { playersMetaData } = this.props.app;
    let cards;

    if (!_.size(information) || !information.cards || !information.cards.length)
      return null;

    try {
      cards = _.chain(JSON.parse(information.cards))
        .remove(player => player.player_id !== 0)
        .sortBy('min')
        .value();
    } catch (error) {
      console.info(`cards JSON in match ${information.match_id} is not valid`);
      console.error(error);
      return null;
    }

    const getCardType = number => {
      if (+number === 31) {
        return 'yellow';
      }
      if (+number === 32) {
        return 'yellow-second';
      }
      if (+number === 33) {
        return 'red';
      }
    };

    if (!cards || !cards.length) {
      return <div className="no-events">No cards</div>;
    }

    const renderCardsList = () =>
      cards.map((card, i) => (
        <tr key={i}>
          <td className="td-card">
            <span className={`card ${getCardType(card.card)}`} />
          </td>
          <td className="td-team-image">
            <Link
              to={`/details/team/${card.team_id}/summary`}
              className="team-image"
            >
              <Image
                src={`${database.assets}teams/${card.team_id}.jpg`}
                aspectRatio={0.769}
              />
            </Link>
          </td>
          <td className="td-cards-player">
            <Link
              to={`/details/player/${card.player_id}/summary`}
              className="player-link"
            >
              {_.get(playersMetaData, `${card.player_id}.short_name`, null)}
            </Link>
          </td>
          <td className="td-min">{card.min ? `${card.min}\`` : 'N/D'}</td>
        </tr>
      ));

    return (
      <table className="match-cards">
        <tbody>{renderCardsList()}</tbody>
      </table>
    );
  }

  _renderBarCharts() {
    const { performance = [], information = {} } = this.props.details;
    const { teams } = this.props.app;
    const bars_vals = ['rating', 'offence', 'defence'];
    const width = 120,
      height = 50,
      margin = {
        top: 13,
        right: 0,
        bottom: 1,
        left: 0,
      };
    let counter = 0;

    if (!performance.length || !_.size(information)) return null;

    const data = [
      _.find(performance, { team_id: information.team_id_home }),
      _.find(performance, { team_id: information.team_id_away }),
    ];
    const bars = createBarChartValues(data, bars_vals);

    const renderGroup = (group, g) =>
      group.map((bar, b) => {
        counter++;

        return (
          <div className="col-6" key={b}>
            <div className="chart_group">
              <h3>{bar.name}</h3>

              <BarChart
                {...{
                  data: bar.values,
                  classNames: bar.classes,
                  width,
                  height,
                  margin,
                  animation: true,
                  animationDelay: 1000 + counter * 200,
                  topBarAccessor_fir: d => d.y,
                  topBarAccessor_sec: d =>
                    d.desc != '0th' && d.desc != 'NaNth' ? d.desc : '',
                  showZero: true,
                }}
              />

              <span className={`chart_bar_value_name ${bar.classes[0]}`}>
                <Link
                  to={`/details/team/${information.team_id_home}/${information.league_id}/${information.season_id}/summary`}
                >
                  {_.get(teams, `${information.team_id_home}.abk`, '')}
                </Link>
              </span>

              <div className="chart_bar_value_benchmark" style={{ width }}>
                <Link
                  to={`/details/team/${information.team_id_away}/${information.league_id}/${information.season_id}/summary`}
                >
                  {_.get(teams, `${information.team_id_away}.abk`, '')}
                </Link>
              </div>
            </div>
          </div>
        );
      });

    return _.chunk(bars, 2).map((group, g) => (
      <div className="row first_el" key={g}>
        {renderGroup(group, g)}
      </div>
    ));
  }

  _renderBestPlayers() {
    const { information = {}, playerStats = [] } = this.props.details;
    const { playersMetaData } = this.props.app;

    if (!_.size(information) || !playerStats.length) return null;

    const bestPlayersOfTeam = team_id =>
      _.chain(playerStats)
        .filter(player => player.team_id == team_id)
        .orderBy(player => +player.rating, 'desc')
        .slice(0, 3)
        .value();

    const renderBestPlayersList = team_id => {
      const players = bestPlayersOfTeam(team_id);

      return players.map(player => (
        <tr key={player.player_id}>
          <td className="td-team-image">
            <Link
              to={`/details/team/${team_id}/summary`}
              className="team-image"
            >
              <Image
                src={`${database.assets}teams/${team_id}.jpg`}
                aspectRatio={0.769}
              />
            </Link>
          </td>
          <td className="td-best-player">
            <Link
              to={`/details/player/${player.player_id}/summary`}
              className="player-link"
            >
              {_.get(playersMetaData, `${player.player_id}.short_name`, null)}
              {player.player_id ? (
                <Image
                  src={`${database.assets}players/${player.player_id}.jpg`}
                  aspectRatio={0.769}
                />
              ) : null}
            </Link>
          </td>
          <td className="text-right">{+player.rating}</td>
        </tr>
      ));
    };

    return (
      <div>
        <div className="h4 background">
          <span>{lang.bestPlayers}</span>
        </div>
        <div className="row">
          <div className="col-6">
            <table className="match-best-players">
              <tbody>{renderBestPlayersList(information.team_id_home)}</tbody>
            </table>
          </div>
          <div className="col-6">
            <table className="match-best-players">
              <tbody>{renderBestPlayersList(information.team_id_away)}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { performance, information = {}, matchDays } = this.props.details;
    const { teams } = this.props.app;

    if (!_.size(information) || !information.team_id_home) return null;

    const radar_width = 270,
      radar_height = 230,
      radar_margin = {
        top: 20,
        right: 20,
        bottom: 17,
        left: 20,
      };
    const radars_vals_against = [
      'tackle_defence',
      'tackle_offence',
      'intercept_defence',
      'intercept_offence',
      'blocked',
      'save',
      'aerial_defence',
      'aerial_offence',
    ];
    const radars_vals_on = [
      'take_on',
      'crossing',
      'pass_short',
      'pass_long',
      'corner',
      'delivery',
      'scoring',
      'scoring_effort',
    ];

    const data = [
      _.find(performance, { team_id: information.team_id_home }),
      _.find(performance, { team_id: information.team_id_away }),
    ].map(data => ({ data }));
    const radars_against = createPolarCharts(data, radars_vals_against);
    const radars_onball = createPolarCharts(data, radars_vals_on);

    return (
      <div>
        <Header {...{ information, matchDays }} />

        <LineUp />

        <div className="content-container">
          <div className="summary summary--match">
            <div className="content_group">
              <div>
                <div className="h4 background">
                  <span>{lang.goals}</span>
                </div>
                {this._renderGoals()}
              </div>
            </div>

            <div className="content_group">{this._renderBestPlayers()}</div>

            <div className="content_group">
              <div className="h4 background">
                <span>{lang.cards}</span>
              </div>
              {this._renderCards()}
            </div>

            <div className="content_group summary_data">
              <div className="h4 background">
                <Icon name="ranking-winner" width="33px" height="28px" />
                <span>{lang.ownstats}</span>
                <span className="description">{lang.thismatchValues}</span>
              </div>

              <div className="charts_bar">{this._renderBarCharts()}</div>

              <div className="charts_radar">
                <div className="chart_group radar radar_1">
                  <PolarChart
                    comparison
                    width={radar_width}
                    animation
                    animationDelay={1200}
                    height={radar_height}
                    margin={radar_margin}
                    tooltips={radars_against.tooltips}
                    classNames={radars_against.classes}
                    data={radars_against.items}
                    xDomain={radars_against.xDomain}
                    colors={COLOR_SCALE}
                    interpolationType="linear-closed"
                  />
                </div>
                <div className="chart_group radar radar_2">
                  <PolarChart
                    comparison
                    width={radar_width}
                    animation
                    animationDelay={1400}
                    height={radar_height}
                    margin={radar_margin}
                    tooltips={radars_onball.tooltips}
                    classNames={radars_onball.classes}
                    data={radars_onball.items}
                    xDomain={radars_onball.xDomain}
                    colors={COLOR_SCALE}
                    nterpolationType="linear-closed"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="chart-legend">
                    <div className="chart-legend-list">
                      <div className="chart-legend-list-row">
                        <div className="chart-legend-list-item">
                          <div className="legend-color-col">
                            <div
                              className="legend-color"
                              style={{ backgroundColor: '#3f809c' }}
                            />
                          </div>
                          <div className="legend-image-col">
                            {information.team_id_home ? (
                              <Image
                                src={`${database.assets}teams/${information.team_id_home}.jpg`}
                                aspectRatio={0.769}
                              />
                            ) : null}
                          </div>
                          <div className="legend-label-col">
                            {information.team_id_home
                              ? teams[information.team_id_home].team_name
                              : null}
                          </div>
                        </div>
                      </div>
                      <div className="chart-legend-list-row">
                        <div className="chart-legend-list-item">
                          <div className="legend-color-col">
                            <div
                              className="legend-color"
                              style={{ backgroundColor: '#e3ab3a' }}
                            />
                          </div>
                          <div className="legend-image-col">
                            {information.team_id_away ? (
                              <Image
                                src={`${database.assets}teams/${information.team_id_away}.jpg`}
                                aspectRatio={0.769}
                              />
                            ) : null}
                          </div>
                          <div className="legend-label-col">
                            {_.get(
                              teams,
                              `${information.team_id_away}.team_name`,
                              null,
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="content_group">
              <div className="h4 background">
                <span>
                  {information.matchday}. {lang.matchday}
                </span>
              </div>
              {this._renderMatchDay()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    details: state.details,
    app: state.app,
    overview: state.overview,
  };
}

export default connect(mapStateToProps)(Summary);
