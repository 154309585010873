import axios from 'axios';
import _ from 'lodash';

import eventTracker from '../helper/event-tracker';

import {
  CLOSE_SHADOW_TAB,
  COPY_SHADOW_TEAM,
  CREATE_SHADOW_TEAM,
  DELETE_SHADOW_TEAM,
  FETCH_SHADOW_TABS,
  FETCH_SHADOW_TEAMS,
  OPEN_SHADOW_TAB,
  UPDATE_SHADOW_TEAM,
} from '../constants/index';

export const fetchShadowTeams = () => {
  const request = axios.get('/shadow-team/');

  return {
    type: FETCH_SHADOW_TEAMS,
    payload: request,
  };
};

export const createShadowTeam = team => {
  const request = axios.post('/shadow-team/', { team });

  eventTracker.trackEvent('Shadow team', 'Create shadow team');

  return {
    type: CREATE_SHADOW_TEAM,
    payload: request,
  };
};

export const updateShadowTeam = t => {
  const team = _.cloneDeep(t);

  if (typeof team.players !== 'string') {
    team.players = JSON.stringify(team.players) || null;
  }

  eventTracker.trackEvent('Shadow team', 'Update shadow team');

  const request = axios.post(`/shadow-team/${team.id}`, { team });

  return {
    type: UPDATE_SHADOW_TEAM,
    payload: { data: t },
  };
};

export const copyShadowTeam = id => {
  const request = axios.get(`/shadow-team/copy/${id}`);

  eventTracker.trackEvent('Shadow team', 'Copy shadow team');

  return {
    type: COPY_SHADOW_TEAM,
    payload: request,
  };
};

export const deleteShadowTeam = id => {
  const request = axios.delete(`/shadow-team/${id}`);

  eventTracker.trackEvent('Shadow team', 'Delete shadow team');

  return {
    type: DELETE_SHADOW_TEAM,
    payload: request,
  };
};

export const fetchShadowTabs = userId => {
  return {
    type: FETCH_SHADOW_TABS,
    payload: { userId },
  };
};

export const openShadowTab = (id, userId) => {
  return {
    type: OPEN_SHADOW_TAB,
    payload: { id, userId },
  };
};

export const closeShadowTab = (id, userId) => {
  return {
    type: CLOSE_SHADOW_TAB,
    payload: { id, userId },
  };
};
