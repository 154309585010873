// Import Style
import './slider.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * To prevent text selection while dragging.
 * http://stackoverflow.com/questions/5429827/how-can-i-prevent-text-element-selection-with-cursor-drag
 */
function pauseEvent(e) {
  if (e.stopPropagation) e.stopPropagation();
  if (e.preventDefault) e.preventDefault();
  // e.cancelBubble = true;
  // e.returnValue = false;
  return false;
}

// These functions allow to treat a single value and an array of values equally:
// e.g. map(5, x => x + 1) returns 6
//      map([5, 6, 7], x => x + 1) returns [6, 7, 8]

/**
 * Apply `f` to each value in `v` or call `f` with `v` directly if it is a single value.
 */
function map(v, f, context) {
  return v && v.map ? v.map(f, context) : f.call(context, v, 0);
}

/**
 * Reduce `v` with `f` and `init` or call `f` directly with `init` and `v` if it is a single value.
 */
function reduce(v, f, init) {
  return v && v.reduce ? v.reduce(f, init) : f(init, v, 0);
}

/**
 * Returns the size of `v` if it is an array, or 1 if it is a single value or 0 if it does not exists.
 */
function size(v) {
  return v != null ? (v.length ? v.length : 1) : 0;
}

/**
 * Returns the value at `i` if `v` is an array. Just returns the value otherwise.
 */
function at(v, i) {
  return v && v.map ? v[i] : v;
}

/**
 * Compares `a` and `b` which can be either single values or an array of values.
 */
function is(a, b) {
  return (
    size(a) === size(b) &&
    reduce(
      a,
      (res, v, i) => {
        return res && v === at(b, i);
      },
      true,
    )
  );
}

/**
 * Spreads `count` values equally between `min` and `max`.
 */
function linspace(min, max, count) {
  const range = (max - min) / (count - 1);
  const res = [];
  for (let i = 0; i < count; i++) {
    res.push(min + range * i);
  }
  return res;
}

/**
 * If `v` is an array, returns a copy of `v` with the `i`th element set to `newv`. Otherwise returns `newv`.
 */
function insteadOf(v, i, newv) {
  if (v.length) {
    v = v.slice();
    v[i] = newv;
    return v;
  }
  return newv;
}

export default class ReactSliders extends Component {
  static defaultProps = {
    min: 0,
    max: 10,
    step: 0.5,
    defaultValue: 0,
    orientation: 'horizontal',
    className: 'horizontal-slider',
    handleClassName: 'handle',
    handleActiveClassName: 'active',
    minDistance: 0.5,
    barClassName: 'bar',
    withBars: true,
    pearling: true,
    value: 0,
    // onChange: this.onChange,
    disabled: false,
  };

  static propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    defaultValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number),
    ]),
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number),
    ]),
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    className: PropTypes.string,
    handleClassName: PropTypes.string,
    handleActiveClassName: PropTypes.string,
    minDistance: PropTypes.number,
    barClassName: PropTypes.string,
    withBars: PropTypes.bool,
    pearling: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onChanged: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const value = map(
      this._or(this.props.value, this.props.defaultValue),
      this._trimAlignValue,
    );

    this.state = {
      index: -1, // TODO: find better solution
      min: this._trimAlignValue(this.props.min),
      max: this._trimAlignValue(this.props.max),
      upperBound: 0,
      sliderLength: 0,
      value,
      zIndices: reduce(
        value,
        (acc, x, i) => {
          acc.push(i);
          return acc;
        },
        [],
      ),
    };
  }

  // Keep the internal `value` consistent with an outside `value` if present.
  // This basically allows the slider to be a controlled component.
  componentWillReceiveProps = newProps => {
    this.setState({
      value: newProps.value,
      min: newProps.min,
      max: newProps.max,
    });
  };

  // Check if the arity of `value` or `defaultValue` matches the number of children (= number of custom handles).
  // If no custom handles are provided, just returns `value` if present and `defaultValue` otherwise.
  // If custom handles are present but neither `value` nor `defaultValue` are applicable the handles are spread out
  // equally.
  // TODO: better name? better solution?
  _or = (value, defaultValue) => {
    const count = React.Children.count(this.props.children);
    switch (count) {
      case 0:
        return value != null ? value : defaultValue;
      case size(value):
        return value;
      case size(defaultValue):
        return defaultValue;
      default:
        if (size(value) !== count || size(defaultValue) !== count) {
          console.warn(
            'ReactSlider: Number of values does not match number of children.',
          );
        }
        return linspace(this.props.min, this.props.max, count);
    }
  };

  componentDidMount = () => {
    window.addEventListener('resize', this._handleResize);
    this._handleResize();

    // values could have changed due to `this._trimAlignValue`.
    if (this.props.onChange && !is(this.props.value, this.state.value)) {
      this.props.onChange(this.state.value);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this._handleResize);
  };

  getValue = () => {
    return this.state.value;
  };

  _handleResize = () => {
    const { slider } = this.refs;
    const handle = this.refs.handle0;
    const rect = slider.getBoundingClientRect();

    const size = {
      horizontal: 'clientWidth',
      vertical: 'clientHeight',
    }[this.props.orientation];
    const sliderMax = rect[this._max()] - handle[size];
    const sliderMin = rect[this._min()];

    this.setState({
      upperBound: slider[size] - handle[size],
      sliderLength: sliderMax - sliderMin,
      sliderMin,
      handleSize: handle[size],
    });
  };

  // calculates the offset of a handle in pixels based on its value.
  _calcOffset = value => {
    const ratio = (value - this.state.min) / (this.state.max - this.state.min);
    return ratio * this.state.upperBound;
  };

  // calculates the value corresponding to a given pixel offset, i.e. the inverse of `_calcOffset`.
  _calcValue = offset => {
    const ratio = offset / this.state.upperBound;
    return ratio * (this.props.max - this.props.min) + this.props.min;
  };

  _buildHandleStyle = (offset, i) => {
    const transform = `translate${this._axis()}(${offset}px)`;
    return {
      WebkitTransform: transform,
      MozTransform: transform,
      msTransform: transform,
      OTransform: transform,
      transform,
      position: 'absolute',
      willChange: this.state.index >= 0 ? 'transform' : '',
      zIndex: this.state.zIndices.indexOf(i) + 1,
    };
  };

  _buildBarStyle = minMax => {
    const obj = {
      position: 'absolute',
      willChange: this.state.index >= 0 ? `${this._min()},${this._max()}` : '',
    };
    obj[this._min()] = minMax.min;
    obj[this._max()] = minMax.max;
    return obj;
  };

  _getClosestIndex = pixelOffset => {
    // TODO: No need to iterate all
    return reduce(
      this.state.value,
      (min, value, i) => {
        const minDist = min[1];

        const offset = this._calcOffset(value);
        const dist = Math.abs(pixelOffset - offset);

        return dist < minDist ? [i, dist] : min;
      },
      [-1, Number.MAX_VALUE],
    )[0];
  };

  // Snaps the nearest handle to the value corresponding to `position` and calls `callback` with that handle's index.
  _forceValueFromPosition = (position, callback) => {
    const pixelOffset =
      position - this.state.sliderMin - this.state.handleSize / 2;
    const closestIndex = this._getClosestIndex(pixelOffset);

    const nextValue = this._trimAlignValue(this._calcValue(pixelOffset));

    this.setState(
      {
        value: insteadOf(this.state.value, closestIndex, nextValue),
      },
      () => {
        if (typeof callback === 'function') {
          callback(closestIndex);
        }
      },
    );
  };

  _dragStart = i => {
    if (this.props.disabled) return;

    return function (e) {
      const position = e[`page${this._axis()}`];
      this._start(i, position);

      document.addEventListener('mousemove', this._dragMove, false);
      document.addEventListener('mouseup', this._dragEnd, false);

      pauseEvent(e);
    }.bind(this);
  };

  _touchStart = i => {
    if (this.props.disabled) return;

    return function (e) {
      const last = e.changedTouches[e.changedTouches.length - 1];
      const position = last[`page${this._axis()}`];
      this._start(i, position);

      document.addEventListener('touchmove', this._touchMove, false);
      document.addEventListener('touchend', this._touchEnd, false);

      pauseEvent(e);
    }.bind(this);
  };

  _start = (i, position) => {
    const { zIndices } = this.state;
    zIndices.splice(zIndices.indexOf(i), 1); // remove wherever the element is
    zIndices.push(i); // add to end

    this.setState({
      startValue: at(this.state.value, i),
      startPosition: position,
      index: i,
      zIndices,
    });
  };

  _dragEnd = () => {
    document.removeEventListener('mousemove', this._dragMove, false);
    document.removeEventListener('mouseup', this._dragEnd, false);
    this._end();
  };

  _touchEnd = () => {
    document.removeEventListener('touchmove', this._touchMove, false);
    document.removeEventListener('touchend', this._touchEnd, false);
    this._end();
  };

  _end = () => {
    this.setState({
      index: -1,
    });

    if (this.props.onChanged) {
      this.props.onChanged(this.state.value);
    }
  };

  _dragMove = e => {
    const position = e[`page${this._axis()}`];
    this._move(this.state.index, position);
  };

  _touchMove = e => {
    const last = e.changedTouches[e.changedTouches.length - 1];
    const position = last[`page${this._axis()}`];
    this._move(this.state.index, position);
    e.preventDefault();
  };

  _move = (i, position) => {
    if (this.props.disabled) return;

    const lastValue = this.state.value;
    const nextValue = map(
      this.state.value,
      function (value, j) {
        if (i !== j) return value;

        const diffPosition = position - this.state.startPosition;
        const diffValue =
          (diffPosition / this.state.sliderLength) *
          (this.state.max - this.state.min);
        let nextValue = this._trimAlignValue(this.state.startValue + diffValue);

        if (!this.props.pearling) {
          if (i > 0) {
            const valueBefore = at(this.state.value, i - 1);
            if (nextValue < valueBefore + this.props.minDistance) {
              nextValue = this._trimAlignValue(
                valueBefore + this.props.minDistance,
              );
            }
          }

          if (i < size(this.state.value) - 1) {
            const valueAfter = at(this.state.value, i + 1);
            if (nextValue > valueAfter - this.props.minDistance) {
              nextValue = this._trimAlignValue(
                valueAfter - this.props.minDistance,
              );
            }
          }
        }

        return nextValue;
      },
      this,
    );

    if (this.props.pearling) {
      const n = nextValue.length;
      if (n && n > 1) {
        if (nextValue[i] > lastValue[i]) {
          this._pearlNext(i, nextValue);
          this._limitNext(n, nextValue);
        } else if (nextValue[i] < lastValue[i]) {
          this._pearlPrev(i, nextValue);
          this._limitPrev(n, nextValue);
        }
      }
    }

    const changed = !is(nextValue, lastValue);
    if (changed) {
      this.setState({ value: nextValue });
      if (this.props.onChange) this.props.onChange(nextValue);
    }
  };

  _pearlNext = (i, nextValue) => {
    if (
      nextValue[i + 1] &&
      nextValue[i] + this.props.minDistance > nextValue[i + 1]
    ) {
      nextValue[i + 1] = this._trimAlignValue(
        nextValue[i] + this.props.minDistance,
      );
      this._pearlNext(i + 1, nextValue);
    }
  };

  _limitNext = (n, nextValue) => {
    for (let i = 0; i < n; i++) {
      if (nextValue[n - 1 - i] > this.state.max - i * this.props.minDistance) {
        nextValue[n - 1 - i] = this.state.max - i * this.props.minDistance;
      }
    }
  };

  _pearlPrev = (i, nextValue) => {
    if (
      nextValue[i - 1] &&
      nextValue[i] - this.props.minDistance < nextValue[i - 1]
    ) {
      nextValue[i - 1] = this._trimAlignValue(
        nextValue[i] - this.props.minDistance,
      );
      this._pearlPrev(i - 1, nextValue);
    }
  };

  _limitPrev = (n, nextValue) => {
    for (let i = 0; i < n; i++) {
      if (nextValue[i] < this.state.min + i * this.props.minDistance) {
        nextValue[i] = this.state.min + i * this.props.minDistance;
      }
    }
  };

  _axis = () => {
    return {
      horizontal: 'X',
      vertical: 'Y',
    }[this.props.orientation];
  };

  _min = () => {
    return {
      horizontal: 'left',
      vertical: 'top',
    }[this.props.orientation];
  };

  _max = () => {
    return {
      horizontal: 'right',
      vertical: 'bottom',
    }[this.props.orientation];
  };

  _trimAlignValue = val => {
    if (val <= this.props.min) val = this.props.min;
    if (val >= this.props.max) val = this.props.max;

    const valModStep = (val - this.props.min) % this.props.step;
    let alignValue = val - valModStep;

    if (Math.abs(valModStep) * 2 >= this.props.step) {
      alignValue += valModStep > 0 ? this.props.step : -this.props.step;
    }

    return parseFloat(alignValue.toFixed(5));
  };

  _renderHandle = styles => {
    return function (child, i) {
      const className = `${this.props.handleClassName} ${
        this.props.handleClassName
      }-${i} ${this.state.index === i ? this.props.handleActiveClassName : ''}`;

      return React.createElement(
        'div',
        {
          ref: `handle${i}`,
          key: `handle${i}`,
          className,
          style: at(styles, i),
          onMouseDown: this._dragStart(i),
          onTouchStart: this._touchStart(i),
          // onTouchMove: this._touchMove,
          // onTouchEnd: this._onTouchEnd
        },
        React.createElement('div', { className: 'handle-inner' }, null),
        child,
      );
    }.bind(this);
  };

  _renderHandles = offset => {
    const styles = map(offset, this._buildHandleStyle, this);

    if (React.Children.count(this.props.children) > 0) {
      return React.Children.map(
        this.props.children,
        this._renderHandle(styles),
        this,
      );
    }
    return map(
      offset,
      function (offset, i) {
        return this._renderHandle(styles)(null, i);
      },
      this,
    );
  };

  _renderBar = (i, offsetFrom, offsetTo) => {
    return React.createElement('div', {
      key: `bar${i}`,
      ref: `bar${i}`,
      className: `${this.props.barClassName} ${this.props.barClassName}-${i}`,
      style: this._buildBarStyle({
        min: offsetFrom,
        max: this.state.upperBound - offsetTo,
      }),
    });
  };

  _renderBars = offset => {
    const bars = [];
    const lastIndex = size(offset) - 1;

    bars.push(this._renderBar(0, 0, at(offset, 0)));

    for (let i = 0; i < lastIndex; i++) {
      bars.push(this._renderBar(i + 1, offset[i], offset[i + 1]));
    }

    bars.push(
      this._renderBar(
        lastIndex + 1,
        at(offset, lastIndex),
        this.state.upperBound,
      ),
    );

    return bars;
  };

  // Handle mouseDown events on the slider.
  _onSliderMouseDown = e => {
    if (this.props.disabled) return;

    const position = e[`page${this._axis()}`];

    this._forceValueFromPosition(position, i => {
      // Set up a drag operation.
      if (this.props.onChange) {
        this.props.onChange(this.state.value);
      }

      this._start(i, position);

      document.addEventListener('mousemove', this._dragMove, false);
      document.addEventListener('mouseup', this._dragEnd, false);
    });

    pauseEvent(e);
  };

  // Handle touchStart events on the slider.
  _onSliderTouchStart = e => {
    if (this.props.disabled) return;

    const last = e.changedTouches[e.changedTouches.length - 1];
    const position = last[`page${this._axis()}`];

    this._forceValueFromPosition(position, i => {
      // Set up a drag operation.
      if (this.props.onChange) {
        this.props.onChange(this.state.value);
      }

      this._start(i, position);

      document.addEventListener('touchmove', this._touchMove, false);
      document.addEventListener('touchend', this._touchEnd, false);
    });

    pauseEvent(e);
  };

  render = () => {
    const offset = map(this.state.value, this._calcOffset, this);

    return (
      <div className="slider">
        <div className="slider-value">
          {this.props.renderValue
            ? this.props.renderValue(this.state.value[0], 0)
            : this.state.value[0] > 999 && this.props.round
            ? `${
                (this.state.value[0] / 1000) % 1 === 0
                  ? this.state.value[0] / 1000
                  : (this.state.value[0] / 1000).toFixed(2).replace('.', ',')
              }k`
            : this.state.value[0]}
        </div>
        <div
          ref="slider"
          style={{ position: 'relative', width: `${this.props.width}px` }}
          className={this.props.className}
          onMouseDown={this._onSliderMouseDown}
          onTouchStart={this._onSliderTouchStart}
        >
          <div className="slider-content-container">
            <div className="slider-content">
              {this.props.withBars ? this._renderBars(offset) : null}
              {this._renderHandles(offset)}
            </div>
          </div>
        </div>
        <div className="slider-value">
          {this.props.renderValue
            ? this.props.renderValue(this.state.value[1], 1)
            : this.state.value[1] > 999 && this.props.round
            ? `${
                (this.state.value[1] / 1000) % 1 === 0
                  ? this.state.value[1] / 1000
                  : (this.state.value[1] / 1000).toFixed(2).replace('.', ',')
              }k`
            : this.state.value[1]}
        </div>
      </div>
    );
  };
}
