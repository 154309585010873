import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import _ from 'lodash';

import Image from '../../../../ui/image';
import Select from 'react-select';

import './notes-filter.scss';
import { updateNotesFilter } from '../../../../../actions/notes';
import { filterNotes } from '../../../../../reducers/notes';

class NotesFilter extends Component {
  constructor(props) {
    super(props);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
  }

  render() {
    const { player_id, height, user } = this.props;

    return (
      <div className="notes-filter">
        <Scrollbars style={{ height }} autoHide>
          <div className="notes-filter__wrapper">
            <h4 className="notes-filter__section-header">Filter</h4>
            {player_id ? null : this.renderPlayers()}
            {this.renderTags()}
            {user.role.name === 'Admin' || user.role.name === 'Group admin'
              ? this.renderUsers()
              : null}
          </div>
        </Scrollbars>
      </div>
    );
  }

  renderPlayers() {
    return (
      <div className="notes-filter__section">
        <h4 className="notes-filter__section-title">Players</h4>
        {this.renderSelect()}
      </div>
    );
  }

  renderTags() {
    const { tags, filter } = this.props;
    const { notesByTag } = this.props.dictionaries;

    const filteredNotes = filterNotes(this.props.notes, {
      ...this.props.filter,
      tag_id: null,
    });

    if (!tags.length) {
      return null;
    }

    const isAllActive = filter.tag_id === null ? 'active' : null;

    const allItems = (
      <li
        className={`notes-filter__item all-items ${isAllActive}`}
        key="all"
        onClick={() => {
          this.resetFilter({ tag_id: null });
        }}
      >
        <span className="text">All</span>
      </li>
    );

    return (
      <div className="notes-filter__section">
        <h4 className="notes-filter__section-title">Tags</h4>
        <ul>
          {allItems}
          {tags.map(t => {
            const activeClass = +filter.tag_id === +t.id ? 'active' : '';
            const count = _.get(notesByTag, `[${t.id}].length`, 0);
            const realCount = filteredNotes.filter(n =>
              n.tags.find(tag => t.id === tag.id),
            ).length;

            if (!count && t.user_id !== null) return null;

            return (
              <li
                className={`notes-filter__item category ${activeClass}`}
                key={t.id}
                onClick={() => {
                  this.onFilterClick({ tag_id: t.id });
                }}
              >
                <span className="text">{t.tag}</span>
                <span className="label">{realCount}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  renderUsers() {
    const { notesByUser } = this.props.dictionaries;
    const { filter } = this.props;

    const filteredNotes = filterNotes(this.props.notes, {
      ...this.props.filter,
      user_id: null,
    });

    const list = [];

    const isAllActive = filter.user_id === null ? 'active' : null;

    const allItems = (
      <li
        className={`notes-filter__item all-items ${isAllActive}`}
        key="all"
        onClick={() => {
          this.resetFilter({ user_id: null });
        }}
      >
        <span className="text">All</span>
      </li>
    );

    list.push(allItems);

    _.forOwn(notesByUser, (value, key) => {
      const user_id = _.get(value, `[0].user_id`, null);
      const activeClass = filter.user_id === key ? 'active' : '';
      list.push(
        <li
          className={`notes-filter__item author ${activeClass}`}
          key={key}
          onClick={() => {
            this.onFilterClick({ user_id: key });
          }}
        >
          {activeClass ? <span className="icon icon-check" /> : null}
          <span className="text">{key}</span>
          <span className="label">
            {filteredNotes.filter(n => +n.user_id === +user_id).length}
          </span>
        </li>,
      );
    });

    return (
      <div className="notes-filter__section">
        <h4 className="notes-filter__section-title">Notes By</h4>
        <ul>{list}</ul>
      </div>
    );
  }

  onFilterClick(newFilter) {
    const { filter } = this.props;

    if (newFilter.player_id) {
      if (filter.player_id === newFilter.player_id) {
        return this.props.updateNotesFilter({ player_id: null });
      }
      return this.props.updateNotesFilter({ ...newFilter });
    }

    if (newFilter.user_id) {
      if (filter.user_id === newFilter.user_id) {
        return this.props.updateNotesFilter({ user_id: null });
      }
      return this.props.updateNotesFilter({ ...newFilter });
    }

    if (newFilter.tag_id) {
      if (filter.tag_id === newFilter.tag_id) {
        return this.props.updateNotesFilter({ ...newFilter, tag_id: null });
      }
      return this.props.updateNotesFilter({ ...newFilter });
    }

    return this.props.updateNotesFilter({ ...newFilter });
  }

  resetFilter(props) {
    const { filter } = this.props;
    this.props.updateNotesFilter({ ...filter, ...props });
  }

  renderSelect() {
    const { playersOptions, notesByPlayer } = this.props.dictionaries;
    const { filteredNotes } = this.props;
    const { filter } = this.props;
    let options = _.uniqBy(playersOptions, 'value');
    options = options.map(p => ({
      ...p,
      count: _.get(notesByPlayer, `[${p.value}].length`, 0),
    }));
    options = options.filter(o => o.count);
    options = _.orderBy(
      options,
      [o => !!o.count, 'last_name'],
      ['desc', 'asc'],
    );
    options = [{ value: null, label: 'All players' }, ...options];

    return (
      <Select
        className="note-player-select filter-select"
        placeholder="Select player..."
        value={filter.player_id || options[0]}
        options={options}
        onChange={val => {
          this.onFilterClick({
            player_id: val.value,
            user_id: null,
            tag_id: null,
          });
        }}
        optionRenderer={opt => {
          const count = opt.count;
          const emptyCls = count || opt.value === null ? '' : 'empty';

          return (
            <span>
              <span className={`player-card ${emptyCls}`}>
                {opt.value !== null ? (
                  <span className="player-img">
                    <span className="player-photo">
                      <Image src={opt.player_img} aspectRatio={0.96} />
                    </span>
                  </span>
                ) : null}
                <span className="player-text">
                  <span className="player-name">{opt.label}</span>
                </span>
                <span className="count">{count}</span>
              </span>
            </span>
          );
        }}
        valueRenderer={val => {
          const count = val.count;
          const emptyCls = count || val.value === null ? '' : 'empty';

          return (
            <span>
              <span className={`player-card ${emptyCls}`}>
                {val.value !== null ? (
                  <span className="player-img">
                    <span className="player-photo">
                      <Image src={val.player_img} aspectRatio={0.96} />
                    </span>
                  </span>
                ) : null}
                <span className="player-text">
                  <span className="player-name">{val.label}</span>
                </span>
              </span>
            </span>
          );
        }}
      />
    );
  }
}

export default connect(
  state => ({
    dictionaries: state.notes.dictionaries,
    notes: state.notes.notes,
    filter: state.notes.filter,
    filteredNotes: state.notes.filteredNotes,
    tags: state.notes.tags,
    user: state.auth.user,
  }),
  {
    updateNotesFilter,
  },
)(NotesFilter);
