import React, { useContext } from 'react';
import Icon from '../../../../../../ui/svg-icon';
import { FlattenListItem } from '../../../../../../../reducers/overview/types';
import { ListItemContext } from './index';

interface Props {
  active: boolean;
  onChange: (active: boolean, { item }: { item: FlattenListItem }) => void;
}

export function Favorite(props: Props) {
  const { active, onChange } = props;
  const { item } = useContext(ListItemContext);

  const onFavoriteClick = event => {
    event.preventDefault();
    event.stopPropagation();
    onChange(!active, { item });
  };

  return (
    <span
      className={`add-to-favorite ${active ? 'checked' : 'unchecked'}`}
      onClick={onFavoriteClick}
    >
      <Icon name="favorite" />
    </span>
  );
}
