import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { voronoi as Voronoi } from 'd3-voronoi';
import { polygonArea, polygonCentroid } from 'd3-polygon';

import './competition-level-chart.scss';

import AxisLeft from './axis-left';
import AxisBottom from './axis-bottom';
import Grid from './grid';
import { Image } from './image';

const CompetitionLevelChart = props => {
  const {
    getPointImageUrl,
    getPointFallbackImageUrl,
    getPointLabelText,
    points,
    useDynamicZoom,
  } = props;

  const width = props.width - 76;
  const height = props.height - 76;

  const xValueGetter = d => d.stability;
  const yValueGetter = d => d.rating;
  const xDomain =
    points.length > 1 && useDynamicZoom
      ? d3.extent(points, xValueGetter)
      : [0, 10];
  const yDomain =
    points.length > 1 && useDynamicZoom
      ? d3.extent(points, yValueGetter)
      : [0, 10];
  const xScale = d3.scale
    .linear()
    .domain([
      (Math.floor(xDomain[0] * 5) / 5).toFixed(1),
      (Math.ceil(xDomain[1] * 5) / 5).toFixed(1),
    ])
    .nice()
    .range([0, width]);
  const yScale = d3.scale
    .linear()
    .domain([
      (Math.floor(yDomain[0] * 5) / 5).toFixed(1),
      (Math.ceil(yDomain[1] * 5) / 5).toFixed(1),
    ])
    .nice()
    .range([height, 0]);

  const voronoi = Voronoi()
    .extent([
      [0, 0],
      [width, height],
    ])
    .x(d => xScale(xValueGetter(d)))
    .y(d => yScale(yValueGetter(d)))
    .polygons(points);

  const showLabels =
    voronoi.length === voronoi.filter(d => polygonArea(d) > 2500).length;

  return (
    <div
      className="competition-level-chart"
      style={{ width: props.width, height: props.height }}
    >
      <div className="axis-label axis-label--x">
        <div className="axis-label-text">Effective Play Index: Stability</div>
      </div>
      <div className="axis-label axis-label--y">
        <div className="axis-label-text">Effective Play Index: Rating</div>
      </div>
      <svg className="competition-level-svg" width={width} height={height}>
        <clipPath id="clipCircle">
          <circle r="12" cx="50" cy="50" />
        </clipPath>
        <Grid xScale={xScale} yScale={yScale} height={height} width={width} />
        <AxisLeft yScale={yScale} />
        <AxisBottom xScale={xScale} height={height} />
        {voronoi.map(v => {
          const centroid = polygonCentroid(v);
          const radius = v.data.highlight ? 15 : 12;
          const LabelPadding = 4;

          const pointX = xScale(xValueGetter(v.data));
          const pointY = yScale(yValueGetter(v.data));
          const point = [pointX, pointY];

          const angle = Math.round(
            (Math.atan2(centroid[1] - point[1], centroid[0] - point[0]) /
              Math.PI) *
              2,
          );
          const orient =
            angle === 0
              ? 'right'
              : angle === -1
              ? 'top'
              : angle === 1
              ? 'bottom'
              : 'left';
          const direction =
            orient === 'right'
              ? 'start'
              : orient === 'top'
              ? 'middle'
              : orient === 'bottom'
              ? 'middle'
              : 'end';
          const dy =
            orient === 'left' || orient === 'right'
              ? '.35em'
              : orient === 'bottom'
              ? '.71em'
              : null;
          const x =
            orient === 'right'
              ? radius + LabelPadding
              : orient === 'left'
              ? -radius - LabelPadding
              : null;
          const y =
            orient === 'bottom'
              ? radius + LabelPadding
              : orient === 'top'
              ? -radius - LabelPadding
              : null;

          const imageSrc = getPointImageUrl(v.data);
          const fallback = getPointFallbackImageUrl(v.data);
          const dataTip = getPointLabelText(v.data, { isHint: true });

          return (
            <g
              className="point"
              transform={`translate(${pointX}, ${pointY})`}
              key={v.data?.id}
            >
              {v.data.highlight ? (
                <circle className="green" cx="0" cy="0" r="13" />
              ) : (
                <circle className="black" cx="0" cy="0" r="12" />
              )}
              <Image src={imageSrc} fallback={fallback} dataTip={dataTip} />
              {showLabels || v.data.showLabel ? (
                <text dy={dy} x={x} y={y} textAnchor={direction}>
                  {getPointLabelText(v.data, { isHint: false })}
                </text>
              ) : null}
            </g>
          );
        })}
      </svg>
    </div>
  );
};

CompetitionLevelChart.defaultProps = {
  useDynamicZoom: false,
};

CompetitionLevelChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  getPointImageUrl: PropTypes.func.isRequired,
  getPointLabelText: PropTypes.func.isRequired,
  points: PropTypes.array.isRequired,
  useDynamicZoom: PropTypes.bool,
};

export default CompetitionLevelChart;
