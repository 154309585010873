import React, { Component } from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import { Link } from 'react-router';
import _ from 'lodash';

import Image from '../../ui/image';
import Icon from '../../ui/svg-icon';
import { Scrollbars } from 'react-custom-scrollbars';
import { database } from '../../../constants/database';

import './popup.scss';

class PlayerPopup extends Component {
  constructor(props) {
    super(props);
    const tabs = props.data.player
      ? ['Substitutes', 'Player']
      : ['Substitutes'];

    this.state = {
      tabs,
      activeTab: 'Substitutes',
    };

    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.setTab = this.setTab.bind(this);
  }

  handleClickOutside() {
    this.props.closePopup();
  }

  handleCloseClick() {
    this.props.closePopup();
  }

  setTab(tabName) {
    this.setState({ activeTab: tabName });
  }

  renderTab(tab) {
    const activeClass = this.state.activeTab === tab ? 'active' : '';
    return (
      <span
        role="button"
        className={`tab ${activeClass}`}
        key={tab}
        onClick={() => {
          this.setTab(tab);
        }}
      >
        {tab}
      </span>
    );
  }

  renderPlayerTab() {
    const { data, teams, leagues } = this.props;

    const { player } = data;
    const playerTeam = teams[player.team_id];
    const league = leagues[player.league_id];
    var url = player.player_id
      ? database.assets + 'players/' + player.player_id + '.jpg'
      : '';

    return (
      <div className="tab-content tab-content__player">
        <div className="row">
          <div className="col-5 noSpacing">
            <Image src={url} aspectRatio={0.768} />
          </div>

          <div className="col-7">
            <p className="firstname">
              {player.first_name?.substring(0, 19) || ''}
            </p>
            <p className="lastname">
              {player.last_name?.substring(0, 15) || ''}
            </p>
            {playerTeam ? (
              <p>
                <span className="team-logo">
                  <img
                    src={`https://static.sp7f.com/v2/teams/${playerTeam.id}.jpg`}
                    alt={playerTeam.team_name}
                  />
                </span>{' '}
                {playerTeam.team_name}
              </p>
            ) : null}
            {league ? (
              <p>
                <span className="team-logo">
                  <img
                    src={`https://static.sp7f.com/v2/leagues/${league.id}.png`}
                    alt={league.name}
                  />
                </span>{' '}
                {league.name}
              </p>
            ) : null}
            <div className="ratings">
              <div className="value">
                <div className="val">{player.season_rating || 'N/D'}</div>
                <div className="name">RAT</div>
              </div>
              <div className="value">
                <div className="val">{player.defence || 'N/D'}</div>
                <div className="name">DEF</div>
              </div>
              <div className="value">
                <div className="val">{player.offence || 'N/D'}</div>
                <div className="name">OFF</div>
              </div>
              <div className="value">
                <div className="val">{player.season_mins || 'N/D'}</div>
                <div className="name">MINS</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSubstitutes() {
    const { data, playersList, userId } = this.props;
    const height = data.player ? '173px' : '210px';

    const filteredList = userId
      ? playersList.filter(p => +p.user_id === +userId)
      : playersList;

    return (
      <Scrollbars style={{ height }} autoHide>
        <ul className="tab-content tab-content__substitutes">
          {filteredList.map(p => this.renderSubstitute(p))}
        </ul>
      </Scrollbars>
    );
  }

  renderSubstitute(player) {
    const { data, teamPlayers, setPlayer } = this.props;
    const { position } = data;
    const posPlayer = data.player;

    let cls = '';

    _.forIn(teamPlayers, value => {
      if (+value === +player.player_id) {
        cls = 'inactive';
      }
    });

    return (
      <li className="substitue-player" key={player.player_id}>
        <div
          className={`player ${cls}`}
          onClick={() => {
            setPlayer(position.id, player.player_id);
            this.handleCloseClick();
          }}
        >
          <span className="roundedRat">{player.season_rating}</span>
          {player.short_name}
        </div>
        {posPlayer ? (
          <Link
            to={`/compare/player/${posPlayer.player_id}-${posPlayer.league_id}-${posPlayer.season_id}-${posPlayer.position_first_id}/${player.player_id}-${player.league_id}-${player.season_id}-${player.position_first_id}`}
            className="compare-substitute"
          >
            <Icon className="compare-icon" name="swap" /> Compare
          </Link>
        ) : null}
      </li>
    );
  }

  render() {
    const { data, teams, leagues } = this.props;
    const { player, coords } = data;

    let posClass = 'left';

    if (1 - coords.y > 0.5) {
      posClass = 'right';
    }

    // left

    return (
      <div
        className={`shadow-team__popup ${posClass}`}
        style={{ left: `${(1 - coords.y) * 100}%`, top: `${coords.x * 100}%` }}
      >
        <nav>
          {this.state.tabs.map(t => this.renderTab(t))}
          <span className="tab close" onClick={this.handleCloseClick}>
            <Icon name="close" />
          </span>
        </nav>
        <div className="shadow-team__popup-content">
          {this.state.activeTab === 'Player'
            ? this.renderPlayerTab()
            : this.renderSubstitutes()}
        </div>
        {player ? (
          <div className="shadow-team__popup-bottom">
            <Link
              to={`/details/player/${player.player_id}/${player.league_id}/${player.season_id}/summary`}
            >
              <Icon name="info" className="link-icon" />
              Details
            </Link>
            <Link
              to={`/details/player/${player.player_id}/${player.league_id}/${player.season_id}/alternatives`}
            >
              <Icon name="alternatives" className="link-icon" />
              Alternatives
            </Link>
          </div>
        ) : null}
      </div>
    );
  }
}

export default enhanceWithClickOutside(PlayerPopup);
