import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import lang from '../../../../../lang';
import { ListType } from '../../../../../reducers/overview/types';
import { selectListFilter } from '../../../../../reducers/overview/selectors';
import { ModeType } from '../../../../../types/Dict';
import { RootState } from '../../../../../types';
import { ListField } from '../fields/ListField';
import { Tab } from './Tab';
import { changePositionSplitMode } from '../../../../../actions/overview';
import {
  loading,
  loadPlayersPerformanceData,
} from '../../../../../actions/app';
import eventTracker from '../../../../../helper/event-tracker';
import { selectCurrentSeason } from '../../../../../reducers/app/selectors';

interface Props {
  listName?: ListType;
  mode: ModeType;
}

const defaultProps = {
  listName: ListType.POSITION_FIRST_ID,
};

const tabOptions = [
  {
    value: 1,
    label: lang['position_overall'],
    description: lang['position_overall_tip'],
  },
  {
    value: 2,
    label: lang['position_group'],
    description: lang['position_group_tip'],
  },
  {
    value: 3,
    label: lang['position_detail'],
    description: lang['position_detail_tip'],
  },
];

const emitEvent = (value: 1 | 2 | 3) => {
  eventTracker.trackEvent(
    'Overview Filter',
    'Separate performance by position',
  );

  let type = '';
  switch (value) {
    case 1:
      type = 'overall';
      break;
    case 2:
      type = 'group';
      break;
    case 3:
      type = 'detailed';
      break;
  }

  eventTracker.trackEvent(
    'Overview Filter',
    `Separate performance by position: ${type}`,
  );
};

export function PositionsFilter(props: Props & typeof defaultProps) {
  const { listName, mode } = props;
  const dispatch = useDispatch();

  const positionList = useSelector((state: RootState) =>
    selectListFilter(state, { listName, mode }),
  );
  const formationList = useSelector((state: RootState) =>
    selectListFilter(state, { listName: ListType.FORMATION_ID, mode }),
  );
  const currentSeason = useSelector((state: RootState) =>
    selectCurrentSeason(state, { mode }),
  );

  const onChangeMode = async option => {
    dispatch(loading(true, 'loading_players_performance'));
    dispatch(changePositionSplitMode(option.value));

    let playersGroupBy;

    if (mode === ModeType.PLAYER) {
      const splittedPosition = option.value;
      const splittedFormation = formationList?.splitted || false;

      if (splittedPosition && splittedFormation) {
        playersGroupBy = `formation_position_${splittedPosition}`;
      } else if (splittedPosition) {
        playersGroupBy = `position_${splittedPosition}`;
      } else if (splittedFormation) {
        playersGroupBy = `formation_position_${splittedPosition}`;
      }
    }

    emitEvent(option.value);

    dispatch(
      loadPlayersPerformanceData({ season_id: currentSeason, playersGroupBy }),
    );
  };

  return (
    <div className="filter_list_long">
      <div className="filters__positions-tabs">
        {tabOptions.map(option => (
          <Tab
            key={option.value}
            option={option}
            onChange={onChangeMode}
            active={positionList?.mode === option.value}
          />
        ))}
      </div>
      <ListField mode={mode} listName={listName} />
    </div>
  );
}

PositionsFilter.defaultProps = defaultProps;
