import {
  ADD_GROUP,
  DELETE_GROUP,
  GET_GROUPS,
  UPDATE_GROUP,
} from '../constants';

const initState = [];

export default function (state = initState, action) {
  switch (action.type) {
    case GET_GROUPS:
      return action.payload?.data?.groups || state;

    case ADD_GROUP:
      return action.payload?.data?.groups || state;

    case DELETE_GROUP:
      return action.payload?.data?.groups || state;

    case UPDATE_GROUP:
      return action.payload?.data?.groups || state;

    default:
      return state;
  }
}
