import React from 'react';

import Slider from '../../../../ui/slider';
import { IndicatorType } from '../../../../../reducers/overview/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../types';
import { selectSliderFilter } from '../../../../../reducers/overview/selectors';
import { ModeType } from '../../../../../types/Dict';
import { changeSliderVal } from '../../../../../actions/overview';

interface Props {
  sliderName: IndicatorType;
  mode: ModeType;
}

export function CompetitionSlider(props: Props) {
  const { sliderName, mode } = props;
  const dispatch = useDispatch();

  const slider = useSelector((state: RootState) =>
    selectSliderFilter(state, {
      mode,
      sliderName,
    }),
  );

  if (!slider) return null;

  const onChanged = value => {
    dispatch(changeSliderVal(sliderName, value, mode));
  };

  return (
    <Slider
      value={slider.data}
      min={slider.minMax[0]}
      max={slider.minMax[1]}
      step={slider.step}
      minDistance={1}
      onChanged={onChanged}
    />
  );
}
