import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import Dropdown from 'react-dropdown';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';

import SeasonSelect from './season-select';

import Image from '../../../ui/image';
import { DataItem } from '../../../ui/data-item';
import FavoriteStar from '../../../ui/favorite-star-with-category';
import Icon from '../../../ui/svg-icon';
import QuickCopy from './quick-copy';
import eventTracker from '../../../../helper/event-tracker';
import { database } from '../../../../constants/database';

import {
  changeFormation,
  changeLeague,
  changePosition,
  changeSeason,
} from '../../../../actions/details';
import { addToFavorite, removeFromFavorite } from '../../../../actions/app';
import lang from '../../../../lang';

class TopBar extends Component {
  static defaultProps = {
    tab: 'summary',
  };

  constructor(props) {
    super(props);

    this.renderMainInfo = this.renderMainInfo.bind(this);
    this.renderProfileDetails = this.renderProfileDetails.bind(this);
    this.changeSeason = this.changeSeason.bind(this);
    this.changeLeague = this.changeLeague.bind(this);
    this.changeFormation = this.changeFormation.bind(this);
    this.changePosition = this.changePosition.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
  }

  renderMainInfo() {
    const { app, details } = this.props;
    const { information, mode, current_league } = details;
    const { leagues, countries } = app;

    if (!_.size(information)) return;

    const { first_name = '', last_name = '', team_id = '' } = information;

    let { team_name } = information;
    team_name =
      team_name || _.get(app.teams, `[${information.team_id}].team_name`, '');

    const name_last =
      last_name.length > 16 ? `${last_name.substring(0, 15)}.` : last_name;
    const name_team =
      information && team_name.length > 21
        ? `${team_name.substring(0, 20)}.`
        : team_name;
    let emblemUrl;

    const league = _.get(leagues, current_league, {});
    const league_name = _.get(league, 'name', '');
    const country_name = _.get(countries, `${league.country_id}.country`, null);

    switch (mode) {
      case 'player':
        emblemUrl = team_id ? `${database.assets}teams/${team_id}.jpg` : '';

        return (
          <div>
            <div className="firstname">{first_name}</div>
            <div className="lastname">{name_last}</div>
            <Link className="team" to={`/details/team/${team_id}/summary`}>
              {emblemUrl ? <Image src={emblemUrl} aspectRatio={0.769} /> : ''}
              {` ${name_team || 'Unknown'}`}
            </Link>
          </div>
        );

      case 'team':
        emblemUrl =
          current_league || +current_league === 0
            ? `${database.assets}leagues/${current_league}.png`
            : '';

        return (
          <div>
            <div className="lastname">{name_team}</div>
            <div className="team">
              {emblemUrl ? <Image src={emblemUrl} aspectRatio={0.769} /> : null}
              {league_name ? ` ${league_name},` : ''} {` ${country_name}`}
            </div>
          </div>
        );
    }
  }

  renderProfileDetails() {
    const { information, mode } = this.props.details;
    const { app } = this.props;

    if (!_.size(information)) return null;
    const {
      country_id,
      marketvalue,
      weight,
      age,
      preferred_foot,
      height,
      manager_first_name,
      manager_last_name,
      stadium_name,
      deceased,
      contract_expiry,
      birth_date,
    } = information;

    const weightHeight = [
      weight && weight !== 'Unknown' ? `${weight} kg` : null,
      height && height !== 'Unknown' ? `${height} cm` : null,
    ]
      .filter(indicator => indicator)
      .join(' / ');
    const weightHeightDesc = [
      weight && weight !== 'Unknown' ? lang.weight : null,
      height && height !== 'Unknown' ? lang.height : null,
    ]
      .filter(indicator => indicator)
      .join(' / ');
    const marketvalueF = marketvalue > 0 ? `${marketvalue}` : 'N/D';
    let birthYear = 'N/D';

    const nationality = _.get(
      app.countriesByAbbr,
      `[${country_id}].country`,
      'N/D',
    );
    const flag = `https://static.sp7f.com/v2/countries/${country_id}.jpg`;

    if (moment(birth_date).isValid()) {
      birthYear = moment(birth_date).get('year');
    }

    let ageFormated = +age ? `${age} (${birthYear})` : null;

    switch (mode) {
      case 'player':
        return (
          <div>
            <DataItem
              text={nationality}
              desc={lang.nation}
              image={flag}
              aspectRatio={125 / 75}
              imageClass="country-flag"
              reverse
            />
            <DataItem text={marketvalueF} desc={lang.marketvalue} reverse />
            <div className="break" />
            <DataItem text={weightHeight} desc={weightHeightDesc} reverse />
            <DataItem text={ageFormated || 'N/D'} desc={lang.age} reverse />
            <DataItem text={preferred_foot} desc={lang.foot} reverse />
            <DataItem
              text={
                contract_expiry && +contract_expiry !== 1900
                  ? contract_expiry
                  : '–'
              }
              desc={lang.contract}
              reverse
            />
            <DataItem
              text={
                deceased && moment(deceased, 'YYYY-MM-DD').format('YYYY-MM-DD')
              }
              desc={lang.deceased}
              reverse
            />
          </div>
        );
      case 'team':
        return (
          <div>
            <DataItem text={stadium_name} desc={lang.stadium} reverse />
            {manager_first_name || manager_last_name ? (
              <DataItem
                text={`${manager_first_name} ${manager_last_name}`}
                desc={lang.coach}
                reverse
              />
            ) : null}
          </div>
        );
    }

    return null;
  }

  changeSeason(season) {
    const { id, current_league, mode } = this.props.details;
    const { tab, changeSeason } = this.props;

    changeSeason(season);

    browserHistory.push(
      `/details/${mode}/${id}/${current_league}/${season.join('/')}/${tab}`,
    );
  }

  changeLeague(value) {
    const { id, current_season, mode } = this.props.details;
    const { tab, changeLeague } = this.props;

    changeLeague(value);
    browserHistory.push(
      `/details/${mode}/${id}/${value}/${current_season.join('/')}/${tab}`,
    );
  }

  changeFormation(value) {
    const { id, current_season, current_league, mode } = this.props.details;
    const { tab, changeFormation } = this.props;

    changeFormation(value);
    browserHistory.push(
      `/details/${mode}/${id}/${current_league}/${current_season.join(
        '/',
      )}/${tab}?formation=${value}`,
    );
  }

  changePosition(value) {
    const { id, current_season, current_league, mode } = this.props.details;
    const { tab, changePosition } = this.props;

    changePosition(value);
    browserHistory.push(
      `/details/${mode}/${id}/${current_league}/${current_season.join(
        '/',
      )}/${tab}?position=${value}`,
    );
  }

  renderFilter() {
    const {
      current_league,
      current_season,
      current_formation,
      current_position,
      information,
      lineups,
      position,
      mode,
    } = this.props.details;
    const { leagues, formations, positions, teams } = this.props.app;

    if (!_.size(information)) return null;

    let within6months = false;
    let data = '';
    let dateData = '';

    if (mode === 'player') {
      if (current_season.length === 2) {
        within6months =
          parseInt(
            moment
              .duration(
                moment().subtract(6, 'month').diff(moment(current_season[0])),
              )
              .asMonths(),
            10,
          ) <= 0;

        if (within6months) {
          dateData = _.get(information, `date_data`, '');
        }
      }

      data = _.get(information, `data`, '');
    } else if (mode === 'team') {
      data = _.get(teams, `${information.id}.data`);
    }

    const comb = data ? data.replace(/ /g, '').split(',') : [];
    const dateComb =
      dateData && within6months ? dateData.replace(/ /g, '').split(',') : [];

    const league_available = _.chain([...comb, ...dateComb])
      .map(SPL => SPL.split(':'))
      .filter(([season]) => {
        if (current_season.length === 1 && +season === +current_season[0]) {
          return true;
        } else if (current_season.length === 2) {
          if (
            season.indexOf('-') === -1 &&
            +season >=
              +current_season[0].substr(0, current_season[0].indexOf('-')) &&
            +season <=
              +current_season[1].substr(0, current_season[1].indexOf('-'))
          ) {
            return true;
          } else if (
            season.indexOf('-') > -1 &&
            new Date(`${season}-31`) >= new Date(current_season[0]) &&
            new Date(`${season}-01`) <= new Date(current_season[1])
          ) {
            return true;
          }
        }

        return false;
      })
      .map(([, league]) => parseInt(league, 10))
      .uniq()
      .sortBy()
      .value();

    const league_options = league_available.map(league_id => ({
      value: +league_id,
      label: _.get(leagues, `${league_id}.name`, 'Unknown league'),
    }));

    let league_defaultOption = _.find(league_options, {
      value: +current_league,
    });

    if (!league_options.length) {
      league_options.push({
        value: +current_league,
        label: _.get(leagues, `${current_league}.name`, 'Unknown league'),
      });
      league_defaultOption = league_options[0];
    }

    const league = {
      disabled: league_options.length <= 1,
      options: league_options.filter(
        league => league.label && league.label.length,
      ),
      value: league_defaultOption,
    };

    const formationOptions = [
      { value: '0', label: 'All' },
      ...lineups.map(lineup => ({
        value: `${lineup.formation_id}`,
        label: _.get(
          formations,
          `[${lineup.formation_id}].name`,
          'Unknown formation',
        ),
      })),
    ];

    const formation = {
      disabled: false,
      options: formationOptions,
      value: current_formation
        ? _.find(formationOptions, { value: `${current_formation}` })
        : {
            value: '0',
            label: 'All',
          },
    };

    let positionSelectProps;
    if (mode === 'player') {
      const positionsByGroup = _.groupBy(
        position,
        ({ position_id }) => positions[position_id].upper_position_id,
      );
      const options = [];

      for (const upper_position_id in positionsByGroup) {
        options.push({
          value: `${upper_position_id}`,
          label: _.get(positions, `${upper_position_id}.position_name`, ''),
          className: 'position_group',
        });

        positionsByGroup[upper_position_id].map(({ position_id }) =>
          options.push({
            value: `${position_id}`,
            label: _.get(positions, `${position_id}.position_name`, ''),
            className: 'position_detail',
          }),
        );
      }

      const positionOptions = [
        {
          value: '0',
          label: 'Overall',
          className: 'position_group',
        },
        ...options,
      ];

      positionSelectProps = {
        disabled: false,
        options: positionOptions,
        value: current_position
          ? _.find(positionOptions, { value: `${current_position}` })
          : {
              value: '0',
              label: 'All',
            },
      };
    }

    return (
      <div className="row">
        {mode === 'team' ? (
          <div className="col-2 header__actionButtons_container formation">
            <DataItem desc={lang.formation_id} reverse>
              <div className="data-text">
                <Dropdown
                  {...formation}
                  onChange={e => {
                    this.changeFormation(e.value);
                  }}
                  placeholder=""
                />
              </div>
            </DataItem>
          </div>
        ) : null}
        {mode === 'player' ? (
          <div className="col-2 header__actionButtons_container position">
            <DataItem desc={lang.position_id} reverse>
              <div className="data-text">
                <Dropdown
                  {...positionSelectProps}
                  onChange={e => {
                    this.changePosition(e.value);
                  }}
                  placeholder=""
                />
              </div>
            </DataItem>
          </div>
        ) : null}
        {mode === 'team' || mode === 'player' ? (
          <div className="col-1">&nbsp;</div>
        ) : null}
        <div
          className={`${
            mode === 'team' || mode === 'player' ? 'col-4' : 'col-5'
          } header__actionButtons_container season`}
        >
          <SeasonSelect onChange={this.changeSeason} />
        </div>

        <div className="col-1">&nbsp;</div>

        <div
          className={`${
            mode === 'team' || mode === 'player' ? 'col-4' : 'col-6'
          } header__actionButtons_container league`}
        >
          <DataItem desc={lang.league} reverse>
            <div className="data-text">
              <Dropdown
                {...league}
                onChange={e => {
                  this.changeLeague(e.value);
                }}
                placeholder="Choose league"
              />
            </div>
          </DataItem>
        </div>
      </div>
    );
  }

  pdfLink(url, event) {
    event.preventDefault();
    const pdfWindow = window.open('', '', '');

    axios.get('/file/token').then(result => {
      const { token } = result.data;
      if (token) {
        pdfWindow.location = `${url}&token=${token}`;
      }
    });
  }

  render() {
    const { sort } = this.props;
    const {
      id,
      current_league,
      current_season,
      current_formation,
      current_position,
      performance_current,
      mode,
      isAccessGranted,
    } = this.props.details;
    const { favorites } = this.props.app;

    const closeMode = localStorage.getItem('overview-mode') || mode;

    const returnTo = localStorage.getItem('returnTo');
    let closeLink;
    if (returnTo !== 'overview/rankings') {
      closeLink = returnTo === 'welcome' ? '/home' : `/overview/${closeMode}`;
    } else {
      closeLink = '/overview/rankings/';
    }

    const url = id ? `${database.assets}${mode}s/${id}.jpg` : '';
    const url_placeholder = `${database.assets}img/profile-placeholder.png`;

    const season_active = current_season || performance_current.season_id;
    const favorite = _.find(
      favorites[mode],
      favorite => favorite[`${mode}_id`] == id,
    );

    let query = '?';
    if (mode === 'player' && sort) {
      query += `sortAlternatives=${sort.alternatives.value}`;
    }
    if (mode === 'player' && current_position) {
      query += `&&position=${current_position}`;
    }
    if (mode === 'team' && current_formation) {
      query += `&&formation=${current_formation}`;
    }

    return (
      <div className="bar-container">
        <div className="row header">
          <div className="col-3 header__profile_key">
            <div className="row">
              <div className="col-3">
                <Image
                  src={id ? url : url_placeholder}
                  backgroundColor={mode === 'team' ? 'white' : null}
                  aspectRatio={0.768}
                />
              </div>
              <div className="col-9 header__profile_key_name">
                {this.renderMainInfo()}
              </div>
            </div>
          </div>

          <div className="col-9 header__profile_key">
            <div className="header__actionButtons_container primary">
              {isAccessGranted ? (
                <span>
                  {mode === 'player' ? (
                    <QuickCopy
                      item={this.props.details}
                      onAfterCopy={() => {
                        eventTracker.trackEvent(
                          'Details page',
                          'Copy player link / top bar',
                        );
                      }}
                    />
                  ) : null}
                  {mode === 'player' ? (
                    <FavoriteStar
                      className="btn_star"
                      favorite={favorite}
                      item={this.props.details.information}
                      mode={mode}
                    />
                  ) : null}
                  {mode ? (
                    <a
                      href="#"
                      onClick={this.pdfLink.bind(
                        this,
                        `${
                          database.pdf.details
                        }${mode}/${id}/${current_season.join(
                          '/',
                        )}/${current_league}/Details${query}`,
                      )}
                    >
                      <span className="icon-pdf">
                        <svg role="img">
                          <use xlinkHref="/img/sprite.symbol.v106.svg#download" />
                        </svg>
                      </span>
                    </a>
                  ) : null}
                  <Link
                    to={`/compare/${mode}/${`${id}-${current_league}-${season_active}${
                      current_formation && mode === 'team'
                        ? `-${current_formation}`
                        : `${current_position}` && mode === 'player'
                        ? `-${current_position}`
                        : ''
                    }`}`}
                  >
                    <span className="icon-compare" />
                  </Link>
                  <span className="divider" />
                </span>
              ) : null}
              <span className="help-placeholder">
                <Icon name="question-circle-big" />
              </span>
              <Link to={`/search/${mode}/`}>
                <span className="icon-search" />
              </Link>
              <Link to={closeLink}>
                <div className="icon-close" />
              </Link>
            </div>
            <div className="row">
              <div className="col-6 header__profile_detail">
                <div className="header__profile_detail_container">
                  {this.renderProfileDetails()}
                </div>
              </div>

              <div className="col-6 header__actionButtons">
                {this.renderFilter()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    details: state.details,
    app: state.app,
  }),
  {
    changeSeason,
    changeLeague,
    addToFavorite,
    removeFromFavorite,
    changeFormation,
    changePosition,
  },
)(TopBar);
