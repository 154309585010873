import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import {
  deleteNote,
  selectNote,
  setNoteMode,
} from '../../../../../actions/notes';

import Image from '../../../../ui/image';
import Tag from '../tag';
import lang from '../../../../../lang';

import './notes-list.scss';

class NotesList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { notes } = this.props;

    return (
      <ul className="notes-list">
        {notes.map(note => this.renderListItem(note))}
      </ul>
    );
  }

  renderListItem(note) {
    const { favoritePlayersByKey } = this.props.dictionaries;
    const { player_id } = this.props;
    const note_player_id = note.player_id;
    const player = favoritePlayersByKey[note_player_id];

    if (!note) {
      return null;
    }

    const createDate = note.updatedAt || note.createdAt;
    const date = createDate
      ? moment(createDate).format('YYYY-MM-DD - HH:mm')
      : '';
    const selectedNoteId = _.get(this.props.selectedNote, 'id', null);
    const activeClass = selectedNoteId === note.id ? 'active' : '';
    const playerImg = _.get(player, 'player_img', null);
    const teamImg = _.get(player, 'team_img', null);

    return (
      <li
        className={`notes-list__item ${activeClass}`}
        key={note.id}
        onClick={() => {
          const { selectedNote } = this.props;
          if (selectedNote && !selectedNote.title && !selectedNote.note) {
            this.props.setNoteMode(null);
            this.props.deleteNote(selectedNote.id);
          } else {
            this.props.selectNote(note);
          }
        }}
      >
        {!player || player_id ? null : (
          <div className="notes-list__item-player-pic">
            <div className="player-photo">
              <Image src={playerImg} aspectRatio={0.96} />
            </div>
            <div className="team-logo">
              <Image src={teamImg} aspectRatio={0.769} />
            </div>
          </div>
        )}
        <div className="notes-list__item-text">
          <h4>{note.title || lang.note_title_placeholder}</h4>
          <p className="text-preview">
            {note.note || lang.note_text_placeholder}
          </p>
          {this.renderTags(note.tags)}
          <div className="notes-list__item-meta">
            <div className="author">{`${note.user.lastName}`}</div>
            <div className="date">{date}</div>
          </div>
        </div>
      </li>
    ); // active
  }

  renderTags(tags) {
    if (!tags || !tags.length) {
      return null;
    }

    return (
      <div className="notes-list__item-tag">
        {tags.map(t => {
          return <Tag tag={t.tag} key={t.id} />;
        })}
      </div>
    );
  }
}

export default connect(
  state => ({
    dictionaries: state.notes.dictionaries,
    selectedNote: state.notes.selectedNote,
  }),
  { selectNote, deleteNote, setNoteMode },
)(NotesList);
