import './auth.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Login } from './auth/Login';
import Reset from './auth/reset';
import Icon from './ui/svg-icon';

class Auth extends Component {
  constructor(props) {
    super(props);

    this.renderAuthType = this.renderAuthType.bind(this);
  }

  renderAuthType() {
    const auth_type = this.props.params.type;

    switch (auth_type) {
      case 'login':
        return <Login />;
      case 'reset':
        return <Reset to={this.props.location.query.to || null} />;
    }
  }

  render() {
    return (
      <div className="auth">
        <div className="auth-logo">
          <Icon name="logo" />
          <div className="logo-text">Scoutpanel</div>
        </div>
        {this.renderAuthType()}
      </div>
    );
  }
}

Auth.propTypes = {
  app: PropTypes.object,
  location: PropTypes.object,
  params: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    app: state.app,
  };
};

export default connect(mapStateToProps)(Auth);
