export const ADD_LOADER = 'ADD_LOADER';
export const DELETE_LOADER = 'DELETE_LOADER';

export function addLoader(name) {
  return {
    type: ADD_LOADER,
    name,
  };
}

export function deleteLoader(name) {
  return {
    type: DELETE_LOADER,
    name,
  };
}
