import React, { Component } from 'react';
import breaks from 'remark-breaks';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import moment from 'moment';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';

import Image from '../../../../ui/image';
import Tag from '../tag';
import NotesList from '../notes-list';
import {
  deleteNote,
  selectNote,
  setNoteMode,
} from '../../../../../actions/notes';
import Icon from '../../../../ui/svg-icon';

class Note extends Component {
  constructor(props) {
    super(props);

    this.deleteNote = this.deleteNote.bind(this);
  }

  render() {
    const note = this.props.selectedNote;
    const { player_id } = this.props;

    if (!note) {
      return null;
    }
    const createDate = note.updatedAt || note.createdAt;
    const date = createDate
      ? moment(createDate).format('YYYY-MM-DD - HH:mm')
      : '';
    const playerProfileCls = player_id ? 'player-profile' : '';

    return (
      <div>
        <div
          className={`note-content ${playerProfileCls}`}
          onDoubleClick={() => {}}
        >
          <div className="note-content__title">
            <h2>{note.title}</h2>
            {note.id ? (
              <a
                onClick={() => {
                  this.props.setNoteMode('edit');
                }}
                className="note-content__button edit-note"
              >
                <Icon name="edit-note" />
              </a>
            ) : null}
            {note.id ? (
              <a
                onClick={this.deleteNote}
                className="note-content__button delete-note"
              >
                <Icon name="trash-can" />
              </a>
            ) : null}
          </div>
          <div className="note-content__text">
            <ReactMarkdown source={note.note} plugins={[breaks]} />
          </div>
          <div className="note-content__tags">{this.renderTags()}</div>
          <div className="note-content__meta">
            By{' '}
            {`${note.user.firstName} ${note.user.lastName}${
              date ? `, ${date}` : ''
            }`}
          </div>
        </div>

        {!player_id ? this.renderPlayer() : null}
        {!player_id ? this.renderMoreNotes() : null}
      </div>
    );
  }

  deleteNote() {
    const note = this.props.selectedNote;

    if (window.confirm('Do you really want to delete note?')) {
      this.props.deleteNote(note.id);
    }
  }

  renderPlayer() {
    const { favoritePlayersByKey } = this.props.dictionaries;
    const note = this.props.selectedNote;
    const player = _.get(favoritePlayersByKey, `[${note.player_id}]`, null);

    if (!player) return null;

    return (
      <div className="note-content__player">
        <h4>Player information</h4>
        <Link className="player-card" to={player.link}>
          <span className="player-img">
            <span className="player-photo">
              <Image src={player.player_img} aspectRatio={0.96} />
            </span>
            <span className="team-logo">
              <Image src={player.team_img} aspectRatio={0.769} />
            </span>
          </span>
          <span className="player-text">
            <span className="player-name-first">{player.first_name}</span>
            <span className="player-name-last">{player.last_name}</span>
            <span className="player-team">{player.team_name}</span>
          </span>
        </Link>
      </div>
    );
  }

  renderTags() {
    const { tags } = this.props.selectedNote;
    return tags.map(t => <Tag tag={t.tag} key={t.id} />);
  }

  renderMoreNotes() {
    const { moreNotes } = this.props;

    if (!moreNotes.length) return null;

    return (
      <div className="note-content__more">
        <h4>More notes</h4>
        <NotesList notes={moreNotes} />
      </div>
    );
  }
}

export default connect(
  state => ({
    dictionaries: state.notes.dictionaries,
    selectedNote: state.notes.selectedNote,
    moreNotes: state.notes.moreNotes,
  }),
  { selectNote, deleteNote, setNoteMode },
)(Note);
