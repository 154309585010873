import _ from 'lodash';
import {
  AUTH_LOGOUT,
  CREATE_NOTE,
  CREATE_NOTE_TAG,
  DELETE_NOTE,
  FETCH_NOTE_TAGS,
  FETCH_NOTES,
  SELECT_NOTE,
  SET_NOTE_MODE,
  UPDATE_NOTE,
  UPDATE_NOTE_DICTIONARIES,
  UPDATE_NOTE_FILTER,
} from '../constants';

export const emptyNote = `
To add a note, click the plus button.

To remove a note, click the Trash button at the top right.

You can search all your notes by typing in the search field at the top of the note list.

You can add tags to a note at the bottom of the note editor, and then use the sidebar to help you stay organized.

This note will hide after you’ve created your first note
`;

export const initState = {
  dictionaries: {
    favoritePlayersByKey: {},
    favoritePlayersByTeam: {},
    teamsByKey: {},
    tagsByKey: {},
    playersOptions: [],
    tagsOptions: [],
    notesByKey: {},
    notesByPlayer: {},
    notesByTag: {},
    notesByUser: {},
  },
  filter: {
    player_search: '',
    player_id: null,
    user_id: null,
    tag_id: null,
    search: '',
  },
  tags: [],
  notes: [],
  filteredNotes: [],
  selectedNote: null,
  moreNotes: [],
  mode: null, // edit / create / null (view)
  newNote: null,
  emptyNote: {
    id: null,
    title: 'Welcome to Scoutpanel Notes',
    note: emptyNote,
    user: { firstName: 'Scoutpanel', lastName: 'app' },
    player_id: null,
    tags: [],
    createdAt: '2018-01-01T12:00:58.000Z',
    updatedAt: '2018-01-01T12:00:58.000Z',
  },
};

export default function (state = initState, action) {
  switch (action.type) {
    case FETCH_NOTES:
      const notes = _.get(action, 'payload.data', []);

      return {
        ...state,
        notes: [...notes],
      };
    case CREATE_NOTE:
      const newNote = {
        ...action.payload.data,
      };

      return {
        ...state,
        notes: [newNote, ...state.notes],
        selectedNote: newNote,
        mode: 'create',
      };
    case UPDATE_NOTE:
      const updIndex = state.notes.findIndex(n => +n.id === action.payload.id);
      return {
        ...state,
        selectedNote: { ...action.payload },
        notes: [
          ...state.notes.slice(0, updIndex),
          { ...action.payload },
          ...state.notes.slice(updIndex + 1),
        ],
      };
    case DELETE_NOTE:
      const id = action.payload;
      const index = state.notes.findIndex(n => +n.id === +id);
      return {
        ...state,
        notes: [
          ...state.notes.slice(0, index),
          ...state.notes.slice(index + 1),
        ],
        mode: null,
        selectedNote: null,
      };
    case SELECT_NOTE:
      const note = _.cloneDeep(action.payload);
      return {
        ...state,
        moreNotes: note
          ? [
              ...state.notes.filter(
                n => +n.player_id === +note.player_id && n.id !== note.id,
              ),
            ]
          : [],
        mode: null,
        selectedNote: note,
      };

    case UPDATE_NOTE_FILTER:
      const newFilter = { ...state.filter, ...action.payload };
      return {
        ...state,
        filteredNotes: filterNotes(state.notes, newFilter, state),
        filter: newFilter,
      };
    case UPDATE_NOTE_DICTIONARIES:
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          ...action.payload,
        },
      };
    case FETCH_NOTE_TAGS:
      return {
        ...state,
        tags: action.payload.data,
      };
    case CREATE_NOTE_TAG:
      const newTag = action.payload.data;
      return {
        ...state,
        tags: [...state.tags, newTag],
      };
    case SET_NOTE_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case AUTH_LOGOUT:
      return initState;
    default:
      return state;
  }
}

export function filterNotes(notes, filter) {
  let filteredNotes = [...notes];

  if (filter.player_id) {
    filteredNotes = filteredNotes.filter(
      n => +n.player_id && +n.player_id === +filter.player_id,
    );
  }

  if (filter.tag_id) {
    filteredNotes = filteredNotes.filter(n => {
      const f = n.tags.find(t => +n.id && +t.id === +filter.tag_id);
      return !!f;
    });
  }

  if (filter.user_id) {
    filteredNotes = filteredNotes.filter(
      n => filter.user_id === `${n.user.firstName} ${n.user.lastName}`,
    );
  }

  if (filter.search) {
    const regexp = new RegExp(filter.search, 'ig');
    filteredNotes = filteredNotes.filter(n => {
      return regexp.test(n.title) || regexp.test(n.note);
    });
  }

  return [...filteredNotes];
}
