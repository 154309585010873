import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';

import lang from '../../../lang';
import eventTracker from '../../../helper/event-tracker';
import Icon from '../../ui/svg-icon';

const SidePanel = props => {
  const { height, teams, leagues, welcome, user } = props;

  const username = `${user.firstName} ${user.lastName}`;
  const { team_id, team_color } = user.group;
  const bgColor = team_color || '#4E6A83';

  const teamId = team_id || null;
  const team = teams[teamId];
  const standard_league = _.get(team, 'standard_league', '');
  const league_id = standard_league.split(':')[0] || null;
  const teamName = (team && team.team_name) || '';
  const league_name = _.get(leagues, `[${league_id}].name`, 'N/D');

  const { rank } = welcome;

  let suffix = ['', 'st', 'nd', 'rd'];

  if (rank < 10) {
    suffix = suffix[rank] || 'th';
  } else {
    const digit = rank % 10;
    suffix = suffix[digit] || 'th';
  }

  const teamUrl = team_id => `https://static.sp7f.com/v2/teams/${team_id}.jpg`;

  const lastMatches = _.get(welcome, 'nextLastGames', [])
    .filter(m => m.last_next === 'l')
    .slice(0, 3);
  const nextMatches = _.get(welcome, 'nextLastGames', [])
    .filter(m => m.last_next === 'n')
    .slice(0, 3)
    .reverse();
  const bestTeamPlayers = _.get(welcome, 'bestTeamPlayers', []);
  const bestTeamPlayersTitle = _.get(welcome, 'bestTeamPlayersTitle', '');

  const curr_mo_date = moment().format('YYYY-MM-DD');
  const monthes = bestTeamPlayersTitle === 'top_players_last3month' ? 3 : 1;
  const last_mo_date = moment().subtract(monthes, 'month').format('YYYY-MM-DD');

  return (
    <div className="welcome__team" style={{ backgroundColor: bgColor }}>
      <Scrollbars style={{ height }} autoHide>
        <div className="wrapper">
          <div className="welcome__user">
            {teamId ? (
              <div
                className="welcome__team-logo"
                style={{
                  backgroundImage: `url(${teamUrl(teamId)})`,
                }}
              />
            ) : null}
            <h1>
              {lang.welcome} <br />
              <strong>{username}</strong>
            </h1>
            {teamId ? (
              <div>
                <p className="team-name">{teamName}</p>
                <p className="team-league">{`${
                  rank + suffix
                } in ${league_name}`}</p>
              </div>
            ) : null}
          </div>

          {teamId ? (
            <div className="welcome__matches">
              {bestTeamPlayers.length ? (
                <div className="welcome__matches-block">
                  <p>{lang[bestTeamPlayersTitle]}</p>
                  <ul>
                    {bestTeamPlayers.map(p => {
                      let player_name = p.short_name;

                      if (p.last_name) {
                        player_name = `${p.last_name}`;
                      }
                      return (
                        <li key={p.player_id}>
                          <Link
                            className="link"
                            to={`/details/player/${p.player_id}/${p.league_id}/${last_mo_date}/${curr_mo_date}/summary`}
                            onClick={() => {
                              eventTracker.trackEvent(
                                'Welcome',
                                'Click Top players last month',
                              );
                            }}
                          >
                            <div className="image-wrapper">
                              <span
                                style={{
                                  backgroundImage: `url("https://static.sp7f.com/v2/players/${p.player_id}.jpg")`,
                                }}
                                className="player-photo"
                              />
                              <span className="player-rating">
                                {bestTeamPlayersTitle ===
                                'top_players_last3month'
                                  ? p.rating_last_3_month
                                  : p.rating_last_1_month}
                              </span>
                            </div>
                            <span className="name">{player_name}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}

              <div className="welcome__matches-block">
                <p>{lang.last_3_matches}</p>
                <ul>
                  {lastMatches.map(m => {
                    const nextTeamId =
                      +m.team_id_home === +teamId
                        ? +m.team_id_away
                        : +m.team_id_home;
                    const homeOrAway =
                      +m.team_id_home === +teamId ? 'home' : 'away';

                    let className = 'wc-draw';
                    if (homeOrAway === 'home') {
                      if (+m.homegoals > +m.awaygoals) {
                        className = 'wc-won';
                      } else if (+m.homegoals < +m.awaygoals) {
                        className = 'wc-lost';
                      }
                    } else if (+m.homegoals > +m.awaygoals) {
                      className = 'wc-lost';
                    } else if (+m.homegoals < +m.awaygoals) {
                      className = 'wc-won';
                    }

                    const badge = (
                      <span className={`result ${className}`}>
                        {homeOrAway === 'home' ? 'H' : 'A'} {+m.homegoals}:
                        {+m.awaygoals}
                      </span>
                    );

                    const team = teams[nextTeamId];
                    const teamName = (team && team.short_club_name) || '';

                    return (
                      <li key={m.match_id}>
                        <Link
                          className="link"
                          to={`/details/match/${m.match_id}/${m.league_id}/${m.season_id}`}
                          onClick={() => {
                            eventTracker.trackEvent(
                              'Welcome',
                              'Click last 3 matches',
                            );
                          }}
                        >
                          <div className="image-wrapper">
                            <span
                              style={{
                                backgroundImage: `url(${`https://static.sp7f.com/v2/teams/${nextTeamId}.jpg`})`,
                              }}
                              className="team-logo-small"
                            />
                            {badge}
                          </div>
                          <span className="name">{teamName}</span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="welcome__matches-block">
                <p>{lang.upcoming_opponents}</p>
                <ul>
                  {nextMatches.map(m => {
                    const nextTeamId =
                      +m.team_id_home === +teamId
                        ? +m.team_id_away
                        : +m.team_id_home;
                    const team = teams[nextTeamId];
                    const teamName = (team && team.short_club_name) || '';
                    const homeOrAway =
                      +m.team_id_home === +teamId ? 'home' : 'away';
                    const badge = (
                      <span className="result wc-draw small">
                        {homeOrAway === 'home' ? 'H' : 'A'}
                      </span>
                    );
                    return (
                      <li key={m.match_id}>
                        <Link
                          className="link"
                          to={`/details/team/${nextTeamId}`}
                          onClick={() => {
                            eventTracker.trackEvent(
                              'Welcome',
                              'Click upcoming opponent',
                            );
                          }}
                        >
                          <div className="image-wrapper">
                            <span
                              style={{
                                backgroundImage: `url(${`https://static.sp7f.com/v2/teams/${nextTeamId}.jpg`})`,
                              }}
                              className="team-logo-small"
                            />
                            {badge}
                          </div>
                          <span className="name">{teamName}</span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <Link className="btn-stroked" href={`/details/team/${teamId}`}>
                <Icon name="wc-team" />
                Go to team page
              </Link>
            </div>
          ) : null}
        </div>
      </Scrollbars>
    </div>
  );
};

const mapStateToProps = state => ({
  teams: state.app.teams,
  leagues: state.app.leagues,
  welcome: state.welcome,
  user: state.auth.user,
});

SidePanel.propTypes = {
  height: PropTypes.number,
  teams: PropTypes.object,
  leagues: PropTypes.object,
  welcome: PropTypes.object,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(SidePanel);
