import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

const AxisBottom = props => {
  const { xScale, height } = props;
  const axisLeft = d3.svg.axis().scale(xScale).orient('bottom');

  const axisRef = axis => {
    d3.select(axis).attr('transform', `translate(0, ${height})`).call(axisLeft);
  };

  return <g className="axis axis--y" ref={axisRef} />;
};

AxisBottom.propTypes = {
  xScale: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
};

export default AxisBottom;
