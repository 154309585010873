import React from 'react';
import { Link } from 'react-router';
import { BarChart } from 'd3-react-touch';
import { COLOR_SCALE } from '../../../constants';
import lang from '../../../lang';

const BarChartGroup = ({
  data,
  indicator,
  width,
  height,
  margin,
  link,
  name,
  renderName,
  benchmarkName,
  renderBenchmarkName,
  className,
  animationDelay,
  showZero,
  ...props
}) => (
  <div className="chart_group">
    {lang[`${indicator}_desc_short`] ? (
      <h3>
        <span data-tooltip-content={`${indicator}`} data-tooltip-id="Indicator">
          {data.name}
        </span>
      </h3>
    ) : (
      <h3>{data.name}</h3>
    )}
    <BarChart
      topBarAccessor_fir={d => d.y}
      topBarAccessor_sec={d => d.desc}
      animation={!!animationDelay}
      data={data.values}
      classNames={data.classes}
      colors={COLOR_SCALE}
      width={width}
      height={height}
      margin={margin}
      className={className}
      animationDelay={animationDelay}
      showZero={showZero}
    />
    <span
      className="chart_bar_value_name"
      style={{ color: `${data.classes[0]}` }}
    >
      {name ? name.substring(0, 8) : ''}
    </span>
    <div className="chart_bar_value_benchmark" style={{ width }}>
      <Link to={link.replace(':id', data.benchmark_id)}>
        {benchmarkName
          ? benchmarkName.substring(0, 8)
          : renderBenchmarkName && data.benchmark_id
          ? renderBenchmarkName(data.benchmark_id)
          : ''}
      </Link>
    </div>
  </div>
);

BarChartGroup.defaultProps = {
  showZero: true,
};

export default BarChartGroup;
