import React, { Component } from 'react';
import d3 from 'd3';
import moment from 'moment';
import _ from 'lodash';

import './chart.scss';

export default class HistoryChart extends Component {
  static defaultProps = {
    ...Component.defaultProps,
    width: 120,
    height: 32,
    parseDate: d3.time.format('%Y-%m-%dT%H:%M:%S.%LZ').parse,
    data: [],
  };

  render() {
    const { data, width, height, parseDate, added } = this.props;
    let addedDay;

    const parsedData = _.chain(data)
      .map(d => ({
        date: parseDate(d.date),
        rating: +d.rating,
      }))
      .sortBy(d => moment(d.date).format('X'))
      .value();

    const x = d3.scale
      .linear()
      .range([0, width])
      .domain([0, data.length - 1]);
    const y = d3.scale
      .linear()
      .range([height - 1, 1])
      .domain([0, 10]);

    const line = d3.svg
      .line()
      .defined(d => d.rating || d.rating === 0)
      .x((d, i) => x(i))
      .y(d => y(d.rating));

    const addedDate = moment(added).format('X');

    for (let i = 0, l = parsedData.length; i < l; i++) {
      const data = parsedData[i];
      const dataDate = moment(data.date).format('X');

      if (addedDate === dataDate) {
        addedDay = i;
      } else if (addedDate > dataDate) {
        addedDay = i + 0.5;
      }
    }

    const addedLine = `M${width * (addedDay / (data.length - 1))},0 L${
      width * (addedDay / (data.length - 1))
    },${height}`;
    const middleLine = `M${width},${height / 2} L0,${height / 2}`;

    return (
      <svg className="history-chart" width={width} height={height}>
        <path className="middle-line" d={middleLine} />
        <path className="chart-line" d={line(parsedData)} />
        <path className="added-line" d={addedLine} />
      </svg>
    );
  }
}
