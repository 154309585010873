import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Slider from '../../../../../ui/slider';
import { IndicatorType } from '../../../../../../reducers/overview/types';
import { selectSliderFilter } from '../../../../../../reducers/overview/selectors';
import { ModeType } from '../../../../../../types/Dict';
import { RootState } from '../../../../../../types';
import { changeSliderVal } from '../../../../../../actions/overview';
/*import { marketValueMarks } from './utils';*/
import lang from '../../../../../../lang';
import { marketValueMarks } from '../../../../../../helper';

interface Props {
  sliderName?: IndicatorType;
  mode: ModeType;
}

const defaultProps = {
  sliderName: IndicatorType.MARKET_VALUE,
};

export function MarketValueSliderField(props: Props & typeof defaultProps) {
  const { mode, sliderName } = props;
  const dispatch = useDispatch();
  const slider = useSelector((state: RootState) =>
    selectSliderFilter(state, { mode, sliderName }),
  );

  if (!slider) return null;

  const { minMax, step, data } = slider;
  const minDistance = 1;

  const min = marketValueMarks(minMax[1]).getFromNumber(minMax[0]);
  const max = marketValueMarks(minMax[1]).getFromNumber(minMax[1]);

  const onChanged = value => {
    dispatch(
      changeSliderVal(
        sliderName,
        [
          marketValueMarks(minMax[1]).getFromSlider(value[0]),
          marketValueMarks(minMax[1]).getFromSlider(value[1]),
        ],
        mode,
      ),
    );
  };

  const renderValue = value => marketValueMarks(minMax[1]).getFromSlider(value);

  return (
    <div className="filters__slider">
      <h2>{lang[sliderName]}</h2>
      <Slider
        value={[
          marketValueMarks(minMax[1]).getFromNumber(data[0]),
          marketValueMarks(minMax[1]).getFromNumber(data[1]),
        ]}
        min={min}
        max={max}
        step={step}
        minDistance={minDistance}
        onChanged={onChanged}
        renderValue={renderValue}
      />
    </div>
  );
}

MarketValueSliderField.defaultProps = defaultProps;
