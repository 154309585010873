import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Dropdown from 'react-dropdown';

// Load actions, own fcts and components
import lang from '../../../lang';

// Load sub-components
import Similar from './summary/similar.js';
import { database } from '../../../constants/database';

class Alternatives extends Component {
  static propTypes = {
    changeSorting: PropTypes.func.isRequired,
    sortValue: PropTypes.object.isRequired,
  };

  static defaultProps = {
    ...Component.defaultProps,
    sortOptions: [
      {
        label: lang.rating,
        value: 'season_rating',
      },
      {
        label: lang.score,
        value: 'score',
      },
      {
        label: lang.score_defence,
        value: 'score_defence',
      },
      {
        label: lang.score_offence,
        value: 'score_offence',
      },
    ],
  };

  render() {
    const { sortOptions, details, changeSorting, sortValue } = this.props;
    const { similar, id, current_season, current_league, isAccessGranted } =
      details;

    const im = !_.isEmpty(similar)
      ? similar.filter(datum => +datum.type === 2)
      : [];
    const ba = !_.isEmpty(similar)
      ? similar.filter(datum => +datum.type === 1)
      : [];
    const pe = !_.isEmpty(similar)
      ? similar.filter(datum => +datum.type === 0)
      : [];

    return (
      <div className={`alternatives ${!isAccessGranted ? 'blurred' : ''}`}>
        <div className="alternatives-filter pull-right">
          <div className="Dropdown--green">
            <div className="Dropdown-label">{`${lang.sort} by`}</div>
            <Dropdown
              value={sortValue}
              options={sortOptions}
              onChange={changeSorting}
              placeholder=""
            />
          </div>
        </div>

        <div className="h4">
          <span>{`All ${lang.alternatives}`}</span>
          <span className="description">{lang.values30games}</span>
          <span className="description">{lang.alternatives_description}</span>
        </div>

        <div className="content_group alternatives first">
          <div className="row">
            <div className="h3">
              <span>{lang.alternatives_immediate}</span>
              <span className="description">
                {lang.alternative_immediate_description}
              </span>
            </div>
          </div>

          <Similar
            id={id}
            compare={id ? `${id}-${current_league}-${current_season}` : null}
            similar={im}
            assets={database.assets}
            toggleable
            sortBy={sortValue.value}
          />
        </div>

        <div className="content_group alternatives">
          <div className="row">
            <div className="h3">
              <span>{lang.alternatives_backup}</span>
              <span className="description">
                {lang.alternative_backup_description}
              </span>
            </div>
          </div>

          <Similar
            id={id}
            compare={id ? `${id}-${current_league}-${current_season}` : null}
            similar={ba}
            assets={database.assets}
            toggleable
            sortBy={sortValue.value}
          />
        </div>

        <div className="content_group alternatives">
          <div className="row">
            <div className="h3">
              <span>{lang.alternatives_perspective}</span>
              <span className="description">
                {lang.alternative_perspective_description}
              </span>
            </div>
          </div>

          <Similar
            id={id}
            compare={id ? `${id}-${current_league}-${current_season}` : null}
            similar={pe}
            assets={database.assets}
            toggleable
            sortBy={sortValue.value}
          />
        </div>

        <div className="content_group">
          <div className="chart-legend">
            <div className="chart-legend-list">
              <div className="chart-legend-list-row">
                <div className="chart-legend-list-item">
                  <div className="legend-label-col">
                    {lang.similarity_index_short}
                    {' = '}
                    {lang.similarity_index}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    details: state.details,
    overview: state.overview,
  };
}

export default connect(mapStateToProps)(Alternatives);
