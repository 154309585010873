import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import Icon from '../../../ui/svg-icon';

import './color-picker.scss';

export default class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false, color: props.color };

    this.handleChangeComplete = this.handleChangeComplete.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.clearColor = this.clearColor.bind(this);
  }

  handleChangeComplete(color) {
    this.setState({ color: color ? color.hex : null });
    this.props.onChange && this.props.onChange(color);
  }

  handleButtonClick() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  clearColor() {
    this.setState({ color: null, isOpen: false });
    this.props.onChange && this.props.onChange(null);
  }

  render() {
    return (
      <div className="color-picker">
        <button
          style={{
            background: this.state.color,
            color: this.state.color ? invertColor(this.state.color) : 'inherit',
          }}
          onClick={this.handleButtonClick}
        >
          Choose color...
        </button>
        {this.state.isOpen ? (
          <div className="color-picker__container">
            <SketchPicker
              disableAlpha
              color={this.state.color ? this.state.color : undefined}
              onChangeComplete={this.handleChangeComplete}
            />
            <button className="btn" onClick={this.clearColor}>
              Clear color
            </button>
            <Icon
              name="close"
              className="icon-close"
              onClick={() => this.setState({ isOpen: false })}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

function invertColor(hex) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  // invert color components
  const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16);
  const g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16);
  const b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return `#${padZero(r)}${padZero(g)}${padZero(b)}`;
}

function padZero(str, len) {
  len = len || 2;
  const zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}
