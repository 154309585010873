import axios from 'axios';

import {
  CHANGE_FAVORITES_CATEGORY,
  CHANGE_FAVORITES_FILTER,
  GET_FAVORITES,
} from '../constants';
import eventTracker from '../helper/event-tracker';

import { database } from '../constants/database';

export function getFavorites({ mode }) {
  const request = Promise.all([
    axios.get(`${database.favorites}/performance/${mode}`),
    Promise.resolve(mode),
  ]);

  return {
    type: GET_FAVORITES,
    payload: request,
  };
}

export function changeFilter({ name, values }) {
  //TODO: Add different values for slider, list, checkbox, toggle
  eventTracker.trackEvent('Overview Filter', 'Favorites filter used');
  return {
    type: CHANGE_FAVORITES_FILTER,
    name,
    values,
  };
}

export function changeCategory({ player_id, user_id, value }) {
  const request = axios.post(`${database.favorites}/category/player`, {
    player_id,
    user_id,
    value,
  });

  eventTracker.trackEvent('Watchlist', 'Change player category');
  return {
    type: CHANGE_FAVORITES_CATEGORY,
    payload: value,
    player_id,
    user_id,
  };
}
