// reducer =  takes any kind of action - along with the current state - and invokes the core function that matches the action
import * as types from '../../constants';
import _ from 'lodash';
import { marketValueMarks } from '../../helper';
import {
  FlattenListItem,
  HasChild,
  ListItem,
  ListType,
  MatchListsId,
  NestingIndex,
  PlayerListsId,
  PlayerSlidersId,
  Status,
  TeamListsId,
} from './types';
import { ModeType } from '../../types/Dict';
import { current } from '@reduxjs/toolkit';

export function overview(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      const newState = handlers[action.type](state, action);

      let { mode } = action;

      // save filter state to local storage to fix iPad bug with page reset
      if (action.type === types.CHANGE_POSITION_SPLIT_MODE) {
        mode = 'player';
      }

      if (
        typeof mode === 'string' &&
        _.get(newState, `[${mode}].filterComponents.filterLoaded`, false) &&
        action.type !== ''
      ) {
        const filterState = prepareFilterState(
          mode,
          newState[mode].filterComponents,
        );
        const scatterplotState = newState[mode].scatterplot;

        const stateDump = { filterState, scatterplotState };

        const dump = JSON.stringify(stateDump);
        localStorage.setItem(`overview_filter_${mode}`, dump);
      }

      return newState;
    }
    return state;
  };
}

export function prepareFilterState(activeMode, filterComponents) {
  const additionalOptionsName = [
    'include_missing_data',
    'splitted',
    'mode',
    'isByYear',
  ];

  const filterLine = {};
  const filterSlider = {};
  const filterSwitcher = {};
  const additionalOptions = {};

  const changedList = filterComponents.list.filter(
    (list, i) => filterComponents.changeFilter.list.indexOf(i) > -1,
  );

  for (let a = 0, b = changedList.length; a < b; a++) {
    const additional = _.pick(changedList[a], additionalOptionsName);

    if (_.size(additional)) {
      additionalOptions[changedList[a].name] = additional;
    }

    filterLine[changedList[a].name] = changedList[a].data.map(firstLevel => {
      if (!firstLevel.items) return firstLevel;

      return firstLevel.items.map(secondLevel => {
        if (!secondLevel.items) return secondLevel;

        return secondLevel.items.map(thirdLevel => {
          if (!thirdLevel.items) return thirdLevel;
          return thirdLevel.items.map(fourthLevel => fourthLevel);
        });
      });
    });

    filterLine[changedList[a].name] = _.flattenDeep(
      filterLine[changedList[a].name],
    );
    filterLine[changedList[a].name] = filterLine[changedList[a].name]
      .filter(line => line.active)
      .map(line => line.id);
  }

  const changedSlider = filterComponents.slider.filter(
    (slider, i) => filterComponents.changeFilter.slider.indexOf(i) > -1,
  );
  for (let i = 0, l = changedSlider.length; i < l; i++) {
    const additional = _.pick(changedSlider[i], additionalOptionsName);

    if (_.size(additional)) {
      additionalOptions[changedSlider[i].name] = additional;
    }

    filterSlider[changedSlider[i].name] = changedSlider[i].data;
  }

  const changedSwitcher = filterComponents.switcher.filter(
    switcher => switcher.data,
  );
  for (let c = 0, d = changedSwitcher.length; c < d; c++) {
    const additional = _.pick(changedSwitcher[c], additionalOptionsName);

    if (_.size(additional)) {
      additionalOptions[changedSwitcher[c].name] = additional;
    }

    filterSwitcher[changedSwitcher[c].name] = changedSwitcher[c].data;
  }

  const preset = {
    list: _.map(filterLine, (data, name) => ({
      data,
      name,
      ...additionalOptions[name],
    })),
    slider: _.map(filterSlider, (data, name) => ({
      data,
      name,
      ...additionalOptions[name],
    })),
    switcher: _.map(filterSwitcher, (data, name) => ({
      data,
      name,
      ...additionalOptions[name],
    })),
    changeFilter: filterComponents.changeFilter,
  };
  return preset;
}

export function loadPreset(
  preset,
  filterComponents,
  doNotSkipSeason = false,
  mode: ModeType,
) {
  ['list', 'slider', 'switcher'].map(key => {
    filterComponents[key] = _.mapValues(filterComponents[key], item => {
      if (item.name === 'preset_id') return item;
      if (item.name === 'season_id' && !doNotSkipSeason) return item;

      const presetIndex = preset[key].findIndex(p => p && p.name === item.name);

      if (presetIndex !== -1) {
        if (preset[key][presetIndex].data) {
          if (key === 'slider') {
            if (item.name === 'marketvalue') {
              item.data[0] = marketValueMarks(item.minMax[1]).getClosest(
                preset[key][presetIndex].data[0] < item.minMax[0]
                  ? item.minMax[0]
                  : preset[key][presetIndex].data[0],
              );
              item.data[1] = marketValueMarks(item.minMax[1]).getClosest(
                preset[key][presetIndex].data[1] > item.minMax[1]
                  ? item.minMax[1]
                  : preset[key][presetIndex].data[1],
              );
            } else if (
              item.name === 'seasonAge' &&
              preset[key][presetIndex].isByYear
            ) {
              item.data[0] = preset[key][presetIndex].data[0];
              item.data[1] = preset[key][presetIndex].data[1];
            } else {
              item.data[0] =
                preset[key][presetIndex].data[0] < item.minMax[0]
                  ? item.minMax[0]
                  : preset[key][presetIndex].data[0];
              item.data[1] =
                preset[key][presetIndex].data[1] > item.minMax[1]
                  ? item.minMax[1]
                  : preset[key][presetIndex].data[1];
            }
          } else if (key === 'list') {
            item.data = item.data.map(firstNesting => {
              if (!firstNesting.items) {
                return {
                  ...firstNesting,
                  active:
                    preset[key][presetIndex].data.indexOf(firstNesting.id) !==
                    -1,
                };
              }

              const firstItems = firstNesting.items.map(secondNesting => {
                if (!secondNesting.items) {
                  return {
                    ...secondNesting,
                    active:
                      preset[key][presetIndex].data.indexOf(
                        secondNesting.id,
                      ) !== -1,
                  };
                }

                const secondItems = secondNesting.items.map(thirdNesting => {
                  if (!thirdNesting.items) {
                    return {
                      ...thirdNesting,
                      active:
                        preset[key][presetIndex].data.indexOf(
                          thirdNesting.id,
                        ) !== -1,
                    };
                  }

                  const thirdItems = thirdNesting.items.map(fourthNesting => {
                    return {
                      ...fourthNesting,
                      active:
                        preset[key][presetIndex].data.indexOf(
                          fourthNesting.id,
                        ) !== -1,
                    };
                  });

                  return {
                    ...thirdNesting,
                    items: thirdItems,
                    active: !thirdItems.filter(({ active }) => !active).length,
                  };
                });

                return {
                  ...secondNesting,
                  items: secondItems,
                  active: !secondItems.filter(({ active }) => !active).length,
                };
              });

              return {
                ...firstNesting,
                items: firstItems,
                active: !firstItems.filter(({ active }) => !active).length,
              };
            });
          } else {
            item.data = preset[key][presetIndex].data;
          }
        }

        return {
          ...item,
          ..._.omit(preset[key][presetIndex], ['name', 'data']),
        };
      }
      return item;
    });
  });

  filterComponents.changeFilter.slider = preset.changeFilter.slider.map(
    sliderId => {
      switch (mode) {
        case ModeType.PLAYER:
          return PlayerSlidersId[sliderId];
        case ModeType.TEAM:
          return TeamListsId[sliderId];
        case ModeType.MATCH:
          return MatchListsId[sliderId];
      }
    },
  );

  filterComponents.changeFilter.list = preset.changeFilter.list.map(listId => {
    switch (mode) {
      case ModeType.PLAYER:
        return PlayerListsId[listId];
      case ModeType.TEAM:
        return PlayerListsId[listId];
      case ModeType.MATCH:
        return PlayerListsId[listId];
    }
  });

  filterComponents.preset = true;

  return filterComponents;
}

export function deactivateAll(list) {
  if (list.children) {
    return list.map(item => ({
      ...item,
      active: false,
      children: deactivateAll(item.children),
    }));
  }

  return list.map(item => ({
    ...item,
    active: false,
  }));
}

export function setListActive(list, active: boolean, nestKey?: string) {
  if (nestKey && list[nestKey]) {
    return list.map(item => ({
      ...item,
      active,
      [nestKey]: setListActive(item[nestKey], active),
    }));
  }

  return list.map(item => ({
    ...item,
    active,
  }));
}

export function changeListValues(
  items: ListItem[],
  values: { open?: boolean; active?: boolean },
) {
  if (!items) return undefined;

  return items.map(item => {
    if (item.items) {
      return {
        ...item,
        ...values,
        items: changeListValues(item.items, values),
      };
    }

    return {
      ...item,
      ...values,
    };
  });
}

export function generateFlattenList(
  listItems: ListItem[],
  ignoreOpen?: boolean,
  listName?: ListType,
): FlattenListItem[] {
  const flattenList: FlattenListItem[] = [];

  function mapItem(
    item: ListItem,
    nestingIndex: NestingIndex,
    open: boolean = false,
  ) {
    const hasChild: HasChild = !!item?.items?.length;
    const itemsActiveState: boolean[] = hasChild ? [] : [item.active];

    if (item.items) {
      for (let i = 0, l = item.items.length; i < l; i++) {
        mapItem(item.items[i], [...nestingIndex, i], item.open);
        itemsActiveState.push(item.items[i].active);
      }
    }

    const checked = itemsActiveState.indexOf(true) !== -1;
    const unchecked = itemsActiveState.indexOf(false) !== -1;
    const indeterminate = checked && unchecked;
    let status: Status = 'unchecked';
    if (indeterminate) status = 'indeterminate';
    else if (checked) status = 'checked';

    if (open || ignoreOpen) {
      flattenList.push({
        status,
        hasChild: hasChild,
        nestingIndex,
        level: nestingIndex.length,
        ..._.omit(item, 'items'),
      });
    }
  }

  try {
    for (let i = 0, l = listItems.length; i < l; i++) {
      mapItem(listItems[i], [i], true);
    }

    return _.orderBy<NestingIndex[]>(flattenList, [
      ({ nestingIndex }) =>
        typeof nestingIndex[0] === 'number' ? nestingIndex[0] : -1,
      ({ nestingIndex }) =>
        typeof nestingIndex[1] === 'number' ? nestingIndex[1] : -1,
      ({ nestingIndex }) =>
        typeof nestingIndex[2] === 'number' ? nestingIndex[2] : -1,
      ({ nestingIndex }) =>
        typeof nestingIndex[3] === 'number' ? nestingIndex[3] : -1,
    ]);
  } catch (error) {
    return [];
  }
}

export function changeListActive(
  list: ListItem[],
  nestingIndex: NestingIndex,
  active: boolean,
) {
  switch (nestingIndex.length) {
    case 1: {
      list[nestingIndex[0]].active = active;
      list[nestingIndex[0]].items = changeListValues(
        list[nestingIndex[0]].items,
        { active },
      );
      break;
    }
    case 2: {
      list[nestingIndex[0]].items[nestingIndex[1]].active = active;
      list[nestingIndex[0]].items[nestingIndex[1]].items = changeListValues(
        list[nestingIndex[0]].items[nestingIndex[1]].items,
        { active },
      );
      break;
    }
    case 3: {
      list[nestingIndex[0]].items[nestingIndex[1]].items[
        nestingIndex[2]
      ].active = active;
      list[nestingIndex[0]].items[nestingIndex[1]].items[
        nestingIndex[2]
      ].items = changeListValues(
        list[nestingIndex[0]].items[nestingIndex[1]].items[nestingIndex[2]]
          .items,
        { active },
      );
      break;
    }
    case 4: {
      list[nestingIndex[0]].items[nestingIndex[1]].items[nestingIndex[2]].items[
        nestingIndex[3]
      ].active = active;

      list[nestingIndex[0]].items[nestingIndex[1]].items[nestingIndex[2]].items[
        nestingIndex[3]
      ].items = changeListValues(
        list[nestingIndex[0]].items[nestingIndex[1]].items[nestingIndex[2]]
          .items[nestingIndex[3]].items,
        { active },
      );
      break;
    }
  }
}
