import React from 'react';

import './loader-icon.scss';

export default () => (
  <div className="load-icon">
    <div className="load-icon-container">
      <div className="loading-spinner" />
    </div>
  </div>
);
