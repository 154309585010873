import React, { createContext, ReactNode } from 'react';
import { useDispatch } from 'react-redux';

import {
  FlattenListItem,
  ListType,
} from '../../../../../../../reducers/overview/types';
import { CheckedIcon } from './CheckedIcon';
import Image from '../../../../../../ui/image';
import Icon from '../../../../../../ui/svg-icon';
import { ModeType } from '../../../../../../../types/Dict';
import {
  changeListActive,
  changeListOpen,
} from '../../../../../../../actions/overview';
import lang from '../../../../../../../lang';
import { Favorite } from './Favorite';
import { Cross } from './Cross';

interface Props {
  children?: ReactNode;
  mode: ModeType;
  listName: ListType;
  item: FlattenListItem;
  getImageSource?: (item: FlattenListItem) => string | undefined | null;
  onItemClick?: (item: FlattenListItem) => void;
}

interface ListItemContextData {
  item: FlattenListItem;
}

export const ListItemContext = createContext({} as ListItemContextData);

function ListItem(props: Props) {
  const { mode, children, listName, item, getImageSource, onItemClick } = props;
  const dispatch = useDispatch();

  const {
    status,
    nestingIndex,
    id,
    name,
    hasChild,
    open,
    type,
    active,
    level,
  } = item;

  const imageSrc = getImageSource ? getImageSource(item) : null;

  const onClick = () => {
    onItemClick
      ? onItemClick(item)
      : dispatch(changeListActive({ mode, listName, active: !active, item }));
  };

  const onDeselectClick = () => {
    dispatch(
      changeListActive({
        mode,
        listName,
        active: false,
        item,
      }),
    );
  };

  const onCollapseClick = () => {
    dispatch(changeListOpen({ mode, listName, nestingIndex, open: !open }));
  };

  return (
    <ListItemContext.Provider value={{ item }}>
      <div className="filters__list_item_row">
        <div className={`filters__list_item level-${level}`} onClick={onClick}>
          <CheckedIcon status={status} />
          {imageSrc ? <Image src={imageSrc} aspectRatio={0.769} /> : null}
          {name}
          {children}
        </div>
        {hasChild && id !== 'ALL' ? (
          <Icon
            name="overview-expand"
            className={`list-icon-collapse ${open ? 'open' : ''}`}
            onClick={onCollapseClick}
          />
        ) : null}
        {id === 'ALL' ? (
          <div
            className="filters__list_item__deselect_all"
            onClick={onDeselectClick}
          >
            {lang.clear_all}
          </div>
        ) : null}
      </div>
    </ListItemContext.Provider>
  );
}

ListItem.Favorite = Favorite;
ListItem.Cross = Cross;

export { ListItem };
