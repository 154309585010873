import axios from 'axios';

import * as types from '../constants';

export function getRoles() {
  const request = axios.get('roles');

  return {
    type: types.ROLES,
    payload: request,
  };
}
