import React from 'react';
import { ModeType } from '../../../../types/Dict';
import { ListType } from '../../../../reducers/overview/types';
import { CollapseGroupType } from './slice/types';
import { Collapse } from './Collapse';
import { LeaguesFilter } from './LeaguesFilter';
import { ListField } from './fields/ListField';

export function MatchFilters() {
  const mode = ModeType.MATCH;

  return (
    <div className="filters">
      <Collapse name={CollapseGroupType.CORE} type="group" mode={mode}>
        <Collapse name={ListType.LEAGUE_ID} type="list" mode={mode}>
          <LeaguesFilter mode={mode} />
        </Collapse>
        <Collapse name={ListType.TEAM_ID} type="list" mode={mode}>
          <ListField mode={mode} listName={ListType.TEAM_ID} withSearch />
        </Collapse>
      </Collapse>
    </div>
  );
}
