import React, { Component } from 'react';
import { Link } from 'react-router';

import Icon from '../ui/svg-icon';

export default class Change extends Component {
  render() {
    return (
      <div className="overview-change">
        <ul className="change-list">
          <li>
            <Link to="/overview/player">
              <Icon name="player" width="100px" height="100px" />
              <span className="title">Players</span>
              <span className="desc">
                Find new players,
                <br />
                see their performance and compare them with others.
              </span>
            </Link>
          </li>
          <li>
            <Link to="/overview/team">
              <Icon name="team" width="128px" height="100px" />
              <span className="title">Teams</span>
              <span className="desc">
                Compare your team to others, look into your next opponent and
                gain some new knowledge.
              </span>
            </Link>
          </li>
          <li>
            <Link to="/overview/match">
              <Icon name="match-change" width="100px" height="100px" />
              <span className="title">Matches</span>
              <span className="desc">
                Find recent matches, compare the individual team performances
                and identify most important players.
              </span>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}
