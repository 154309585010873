import React from 'react';
import PropTypes from 'prop-types';

import './top-players-list.scss';

import TopPlayer from './top-player';

const TopPlayersList = props => {
  const { heading, description, index, players, type } = props;

  const headingIsString = typeof heading === 'string';
  const headingBackgroundImage =
    headingIsString && `url(/img/top-players-list-header-${index}.jpg)`;

  return (
    <div className="top-players-list">
      <div className="heading">
        {headingBackgroundImage ? (
          <div
            className="heading__bg"
            style={{ backgroundImage: headingBackgroundImage }}
          />
        ) : null}
        <div className="heading__container">
          {headingIsString ? (
            <div className="heading__title">{heading}</div>
          ) : (
            heading
          )}
          {description ? (
            <div className="heading__description">{description}</div>
          ) : null}
        </div>
      </div>
      <div className="content">
        {players.slice(0, 5).map(player => (
          <TopPlayer key={player.player_id} player={player} type={type} />
        ))}
      </div>
    </div>
  );
};

TopPlayersList.defaultProps = {
  type: 'rising',
};

TopPlayersList.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  index: PropTypes.number,
  children: PropTypes.any,
  players: PropTypes.array.isRequired,
  type: PropTypes.string,
  description: PropTypes.string,
};

export default TopPlayersList;
