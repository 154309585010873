import 'react-virtualized/styles.css';
import './App.scss';

import { browserHistory } from 'react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import ReactModal from 'react-modal';

import { fetchDataForOverview, loadData, loading } from '../actions/app';
import { dataErrorModal } from '../actions/details';
import { logout, setPrivacy } from '../actions/auth';
import Overview from '../components/overview/mobile';
import Confirm from '../components/confirm';
import SvgIcon from '../components/ui/svg-icon';

import { DEFAULT_SEASON } from '../constants/index';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };

    this.getData = this.getData.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.auth.user &&
      nextProps.auth.token &&
      !nextProps.app.loaded &&
      !nextState.loading &&
      !nextState.error
    ) {
      this.setState({ loading: true });
      this.getData();
    }

    if (this.props.auth.user && nextProps.location !== this.props.location) {
      window.previousLocation = this.props.location;
    }
  }

  getData() {
    this.props
      .loadData(DEFAULT_SEASON, 'mobile')
      .then(data => {
        return this.props
          .fetchDataForOverview(DEFAULT_SEASON, 'mobile')
          .then(() => {
            this.setState({ loading: false, error: false });

            const text = (dismiss, onCheck, isChecked) => {
              const checked = isChecked ? 'checked' : '';
              return (
                <div className="privacy-confirm">
                  <h2 className="h2">We’re updating our Privacy Policy</h2>

                  <p>
                    The EU’s General Data Protection Regulation (GDPR) goes into
                    effect on May 25, 2018.
                  </p>
                  <p>
                    To help comply with GDPR consent requirements, please
                    confirm that you accept our privacy policy, would like to
                    make use of your Scoutpanel account and receive content from
                    us.
                  </p>

                  <p className="with-checkbox">
                    <span className={`checkbox ${checked}`} onClick={onCheck}>
                      <span className="icon-check" />
                    </span>
                    By checking this box, I confirm having read, understood and
                    accepted the{' '}
                    <a
                      href="https://scoutpanel.com/privacy-policy/"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                    .
                  </p>
                </div>
              );
            };

            const { user } = this.props.auth;

            if (!user.acceptPrivacy) {
              Confirm(text, {
                context: this.context,
                withCheckbox: true,
                cancelText: 'Disagree',
                confirmText: 'Agree and proceed',
                hideCancel: true,
              }).then(
                () => {
                  this.props.setPrivacy(true);
                },
                () => {
                  this.props.setPrivacy(false).then(() => {
                    this.props.logout();
                    document.location = '/';
                  });
                },
              );
            }
          });
      })
      .catch(error => {
        console.error(error);
        this.setState({ loading: false, error: true });
        browserHistory.replace('/auth/login');
      });
  }

  render() {
    const { details, dataErrorModal } = this.props;
    const { pathname } = this.props.location;

    return (
      <div className="app">
        <div
          className={`loader-small ${
            this.props.app.loading ? 'page-opacity on' : 'page-opacity'
          }`}
        >
          <div className="loader-container">
            <div className="loader" />
          </div>
          <div className="loader-bg" />
        </div>

        <div
          className={`loading page-transition page-left ${
            this.state.loading == false || this.state.error
              ? 'off'
              : 'no-transition on'
          }`}
        >
          <div className="loading-container">
            <div className="logo-centered">
              <div className="logo-icon">
                <SvgIcon name="logo" />
              </div>
            </div>
          </div>
          <div className="loader-container">
            <div className="loader" />
          </div>
        </div>

        <div className={`error ${this.state.error ? 'show' : 'hide'}`}>
          <div className="error-container">
            <div className="error-centered">
              <h1>
                Houston, we have a Problem.
                <br />
                Please make sure that you have a working internet connection.
              </h1>
            </div>
          </div>
        </div>

        {this.props.app.loaded && this.props.auth.token ? (
          <div>
            <Overview
              mode={this.props.params.mode}
              overview={pathname.split('/')[1] === 'overview'}
            />
            <CSSTransitionGroup
              transitionName="modal"
              transitionEnter
              transitionEnterTimeout={500}
              transitionLeave
              transitionLeaveTimeout={500}
              transitionAppear={false}
            >
              <div
                key={
                  pathname.split('/')[1] === 'overview' ? 'overview' : 'modal'
                }
              >
                {this.props.children}
              </div>
            </CSSTransitionGroup>
          </div>
        ) : null}

        {pathname.indexOf('auth') !== -1 ? this.props.children : null}

        <svg width="0" height="0">
          <defs>
            <clipPath id="clipPolygon" clipPathUnits="objectBoundingBox">
              <polygon points="0 0, 1 0, 0.75 1, 0 1" />
            </clipPath>
          </defs>
          <defs>
            <clipPath id="clipPolygonRight" clipPathUnits="objectBoundingBox">
              <polygon points="0.25 0, 1 0, 1 1, 0 1" />
            </clipPath>
          </defs>
        </svg>

        <ReactModal
          isOpen={details.dataModalError}
          className="modal-alert"
          overlayClassName="modal-alert-overlay"
          closeTimeoutMS={300}
        >
          <a
            className="icon-close"
            onClick={e => {
              e.preventDefault();
              dataErrorModal(false);
            }}
          />
          <h2>{`No data for this ${details.dataModalErrorMode}`}</h2>
          <p>
            <a href="mailto:support@scoutpanel.com">Contact support team.</a>
          </p>
        </ReactModal>
      </div>
    );
  }
}

export default connect(
  state => ({
    app: state.app,
    details: state.details,
    auth: state.auth,
  }),
  {
    loadData,
    loading,
    dataErrorModal,
    setPrivacy,
    logout,
    fetchDataForOverview,
  },
)(App);
