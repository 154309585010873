import { DEFAULT_SEASON } from '../../constants';
import Moment from 'moment/moment';

export function precalculatePlayerValues(players, season) {
  const seasonAgedPlayers = {};
  const playersId = Object.keys(players);

  if (+season > 2000) {
    const date = new Date();
    const year = date.getFullYear();
    const delta = +year - +season;

    for (let i = 0, l = playersId.length; i < l; i += 1) {
      // calculate season age
      const playerId = +playersId[i];

      seasonAgedPlayers[playerId] = players[playerId];
      if (season >= DEFAULT_SEASON) {
        seasonAgedPlayers[playerId].seasonAge =
          +players[playerId].age > 0 ? +players[playerId].age : null;
      } else {
        seasonAgedPlayers[playerId].seasonAge =
          +players[playerId].age > 0 ? +players[playerId].age - delta : null;
      }

      seasonAgedPlayers[playerId].contract_expiry_year =
        +seasonAgedPlayers[playerId].contract_expiry - 2000;
      seasonAgedPlayers[playerId].birth_date = Moment(
        players[playerId].birth_date,
        'YYYY-MM-DD',
      ).isValid()
        ? Moment(players[playerId].birth_date, 'YYYY-MM-DD').toDate()
        : null;

      seasonAgedPlayers[playerId].birth_year = seasonAgedPlayers[playerId]
        .birth_date
        ? seasonAgedPlayers[playerId].birth_date.getFullYear()
        : null;
    }
  } else {
    for (let i = 0, l = playersId.length; i < l; i += 1) {
      // save age
      const playerId = playersId[i];
      seasonAgedPlayers[playerId] = { ...players[playerId] };
      seasonAgedPlayers[playerId].seasonAge =
        +players[playerId].age > 0 ? +players[playerId].age : null;
      seasonAgedPlayers[playerId].birth_date = Moment(
        players[playerId].birth_date,
        'YYYY-MM-DD',
      ).isValid()
        ? Moment(players[playerId].birth_date, 'YYYY-MM-DD').toDate()
        : null;
      seasonAgedPlayers[playerId].birth_year = seasonAgedPlayers[playerId]
        .birth_date
        ? seasonAgedPlayers[playerId].birth_date.getFullYear()
        : null;
      seasonAgedPlayers[playerId].contract_expiry_year =
        +seasonAgedPlayers[playerId].contract_expiry - 2000;
    }
  }

  return seasonAgedPlayers;
}
