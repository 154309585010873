import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import {
  LOAD_DATA_PROGRESS,
  LOAD_DATA,
  FETCH_DATA_FOR_OVERVIEW,
  FETCH_DATA_FOR_WELCOME,
  START_WELCOME_LOAD,
  LOAD_PLAYERS_PERFORMANCE,
  CHANGE_PLAYERS_GROUP_BY,
  LOAD_TEAMS_PERFORMANCE,
  LOADING,
  OVERVIEW_LOADING,
  AUTH_LOGOUT,
  ADD_TO_FAVORITE,
  REMOVE_FROM_FAVORITE,
  CHANGE_FAVORITES_CATEGORY,
  LOAD_QUOTE,
  ADD_FILTER_PRESET,
  REMOVE_FILTER_PRESET,
  ADD_FAVORITE_LEAGUE,
  REMOVE_FAVORITE_LEAGUE,
  AUTH_LOGIN_REQUEST_SUCCESS,
  DEFAULT_SEASON,
} from '../../constants';
import { AppState } from './types';
import { removeDiacritics } from '../../helper';
import { precalculatePlayerValues } from './utils';
import { PlayerInfo, PlayerOption } from '../../types/Player';

export const initialState: AppState = {
  userLeagues: [],
  loading: false,
  loading_message: '',
  loaded: false,
  load_progress: {
    current: 0,
    total: 0,
    message: '',
  },
  welcomeLoaded: false,
  overviewLoaded: false,
  overviewLoading: false,
  returnTo: 'welcome',
  device: null,
  load_quote: null,
  seasons: [],
  leagues: {},
  teams: {},
  positions: {},
  countries: {},
  countriesByAbbr: {},
  formations: {},
  regions: {},
  regionGroups: {},
  leagueFormats: {},
  playersMetaData: {}, // all meta data of all players
  playersPerformanceData: [], // all seasons after loading
  matchesPerformanceData: [],
  matchesLastData: [],
  playersListAll: [],
  teamsPerformanceData: [],
  season_current: {
    player: DEFAULT_SEASON,
    team: DEFAULT_SEASON,
    match: DEFAULT_SEASON,
    rankings: DEFAULT_SEASON,
  },
  supportsTouch: ('ontouchstart' in window || navigator.maxTouchPoints) === 0,
  favorites: {
    player: [],
    team: [],
    match: [],
  },
  favoriteLeagues: [],
  playersGroupBy: 'position_1',
  teamsListAll: [],
  formationPosition: {},
  presets: [],
};

export default createReducer(initialState, {
  [AUTH_LOGIN_REQUEST_SUCCESS]: (state, action) => {
    state.userLeagues = action.payload?.user?.leagues || [];
  },
  [LOAD_DATA_PROGRESS]: (state, action) => {
    const { total, message } = action.payload;
    const { current } = state.load_progress;

    if (total - 1 !== current) {
      state.load_progress = {
        total,
        message,
        current: current + 1,
      };
    } else {
      state.load_progress = initialState.load_progress;
    }
  },

  [LOAD_DATA]: (state, action: any) => {
    const { device, playersGroupBy } = action.meta;
    const [decoding_res, favorites, presets, favoriteLeagues] = action.payload;
    const decoding = decoding_res.data.data;
    const {
      seasons,
      leagues,
      teams,
      positions,
      countries,
      formations,
      formationPosition,
      regions,
      region_groups,
      league_formats,
    } = decoding;

    const formationsById = _.chain(formations)
      .map(f => {
        if (!parseInt(f.name, 10)) return f;
        return { ...f, name: f.name.split('').join('-') };
      })
      .keyBy('id')
      .value();

    const teamsById = _.keyBy(teams, 'id');
    const formationPosition_ = formationPosition.filter(
      f => f.position_id !== 0,
    );

    const teamsListAll = _.chain(teams)
      .filter('data')
      .filter('team_name')
      .map(team => {
        const label = team.team_name.trim();

        return {
          ...team,
          value: team.id,
          label,
          searchString: removeDiacritics(label.toLowerCase()),
        };
      })
      .sortBy('label')
      .value();

    state.loaded = true;
    state.device = device;
    state.playersGroupBy = playersGroupBy;
    state.teamsListAll = teamsListAll;
    state.seasons = seasons;
    state.leagues = _.keyBy(leagues, 'id');
    state.teams = teamsById;
    state.positions = _.keyBy(positions, 'id');
    state.countries = _.keyBy(countries, 'id');
    state.countriesByAbbr = _.keyBy(countries, 'abbreviation');
    state.formations = formationsById;
    state.formationPosition = _.groupBy(formationPosition_, 'formation_id');
    state.regions = _.keyBy(regions, 'id');
    state.regionGroups = _.keyBy(region_groups, 'id');
    state.leagueFormats = _.keyBy(league_formats, 'id');
    state.favorites = favorites.data.data;
    state.presets = presets.data;
    state.favoriteLeagues = favoriteLeagues.data.map(l => l.league_id);
  },

  [FETCH_DATA_FOR_OVERVIEW]: (state, action: any) => {
    const [
      players_meta_res,
      players_currentseason_res,
      teams_currentseason_res,
      matches_res,
      matches_last_res,
    ] = action.payload;
    const { device, playersGroupBy, currentSeason, teamSeason } = action.meta;
    const playersMetaData = players_meta_res.data;
    const playersPerformanceData = players_currentseason_res.data;
    const teams_currentseason = teams_currentseason_res.data;

    let playersMetaDataArray: PlayerInfo[] =
      Object.values<PlayerInfo>(playersMetaData);

    if (device !== 'mobile') {
      playersMetaDataArray = playersMetaDataArray.filter((player: any) => {
        const playerLeagues = `${player.leagues}`.split(',').map(l => +l);
        const intersection = _.intersection(playerLeagues, state.userLeagues);

        return intersection.length > 0;
      });
    }

    let playersListAll: PlayerOption[] = playersMetaDataArray.map(
      (player: any) => {
        const label = `${
          player.first_name
            ? `${player.first_name} ${player.last_name}`
            : player.last_name
        }`.trim();

        return {
          ...player,
          value: player.player_id,
          marketvalue: player.marketvalue ? +player.marketvalue : 0,
          label,
          searchString: `${removeDiacritics(label).toLowerCase()} ${_.get(
            state,
            `teams[${player.team_id}].team_name`,
            '',
          ).toLowerCase()}`,
        };
      },
    );
    playersListAll = _.sortBy(playersListAll, 'label');

    let playersData = playersMetaData;

    if (device === 'desktop') {
      playersData = precalculatePlayerValues(
        playersMetaData,
        state.season_current.player,
      );
    }

    state.playersListAll = playersListAll;
    state.playersMetaData = playersData;
    state.playersPerformanceData = playersPerformanceData;
    state.playersGroupBy = playersGroupBy;
    state.teamsPerformanceData = teams_currentseason;
    state.matchesPerformanceData = matches_res.data.data;
    state.matchesLastData = matches_last_res.data.data;
    state.overviewLoaded = true;
    state.overviewLoading = false;
    state.season_current.player = currentSeason;
    state.season_current.team = teamSeason;
  },

  [FETCH_DATA_FOR_WELCOME]: state => {
    state.welcomeLoaded = true;
  },

  [START_WELCOME_LOAD]: state => {
    state.welcomeLoaded = false;
  },

  [LOAD_PLAYERS_PERFORMANCE]: (state, action: any) => {
    const players_currentseason = action.payload.data;
    const { season_id, playersGroupBy } = action.meta;

    state.playersPerformanceData = players_currentseason;
    state.loading = false;
    state.season_current.player = season_id;
    state.playersGroupBy = playersGroupBy;
  },
  [CHANGE_PLAYERS_GROUP_BY]: (state, action: any) => {
    const playersPerformanceData = action.payload.data;
    const { playersGroupBy } = action.meta;

    state.playersPerformanceData = playersPerformanceData;
    state.playersGroupBy = playersGroupBy;
  },
  [LOAD_TEAMS_PERFORMANCE]: (state, action: any) => {
    const teamsPerformanceData = action.payload.data;
    const { season_id } = action.meta;

    state.teamsPerformanceData = teamsPerformanceData;
    state.loading = false;
    state.season_current.team = season_id;
  },
  [LOADING]: (state, action: any) => {
    state.loading = action.status;
    state.loading_message = action.status ? action.message : '';
  },
  [OVERVIEW_LOADING]: (state, action: any) => {
    state.overviewLoading = action.status;
  },
  [AUTH_LOGOUT]: (state, action: any) => {
    state = initialState;
  },
  [ADD_TO_FAVORITE]: (state, action: any) => {
    const [result, id, mode, category] = action.payload;

    if (result.data.success) {
      state.favorites[mode].push({ [`${mode}_id`]: +id, category: +category });
    }
  },
  [REMOVE_FROM_FAVORITE]: (state, action: any) => {
    const [result, id, mode] = action.payload;

    if (result.data.success) {
      state.favorites[mode].filter(favorite => favorite[`${mode}_id`] != id);
    }
  },
  [CHANGE_FAVORITES_CATEGORY]: (state, action: any) => {
    const { player_id, user_id, payload } = action;

    const index = state.favorites.player.findIndex(
      p => p.player_id == player_id && p.user_id == user_id,
    );

    state.favorites.player[index].category = payload;
  },
  [LOAD_QUOTE]: (state, action: any) => {
    const result = action.payload;

    state.load_quote = result?.data?.data?.quote || initialState.load_quote;
  },
  [ADD_FILTER_PRESET]: (state, action: any) => {
    state.presets.push(action.payload.data);
  },
  [REMOVE_FILTER_PRESET]: (state, action: any) => {
    const id = action.payload;

    state.presets.filter(preset => preset.id !== id);
  },
  [ADD_FAVORITE_LEAGUE]: (state, action: any) => {
    state.favoriteLeagues.push(action.payload);
  },
  [REMOVE_FAVORITE_LEAGUE]: (state, action: any) => {
    state.favoriteLeagues.filter((favorite, index) => index !== action.payload);
  },
});
