import * as d3_scale from 'd3-scale';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import { Tooltip } from 'd3-react-touch';
import React, { Component } from 'react';
import Image from '../../ui/image';
import lang from '../../../lang';
import { database } from '../../../constants/database';

import './heatmap.scss';

let scrolling = false;

export default class HeatMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltip: {
        id: null,
        x: 0,
        y: 0,
      },
    };

    this.renderTeams = this.renderTeams.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
    this._deSelectItem = this._deSelectItem.bind(this);
    this._clickHandler = this._clickHandler.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { height, width, activePointData } = nextProps;
    const { tooltip } = this.state;
    const { scrollbar } = this.refs;
    const scrollHeight = scrollbar.getClientHeight();
    const scrollTop = scrollbar.getScrollTop();

    if (activePointData) {
      const elem =
        this.refs[
          `heatmap-${activePointData.team_id}-${activePointData.league_id}`
        ];
      const offset = elem.getBoundingClientRect();

      if (offset.top < 0 || offset.top > scrollHeight) {
        scrolling = true;
        scrollbar.scrollTop(scrollTop + offset.top - scrollHeight / 2);

        this.setState({
          tooltip: {
            id: `${activePointData.team_id}-${activePointData.league_id}`,
            x:
              offset.left < width / 2
                ? offset.left + elem.offsetWidth
                : offset.left,
            y: scrollHeight / 2,
            screenWidth: width,
            screenHeight: height,
          },
        });
      } else {
        this.setState({
          tooltip: {
            ...tooltip,
            x:
              offset.left < width / 2
                ? offset.left + elem.offsetWidth
                : offset.left,
            y:
              offset.top < height / 2
                ? offset.top + elem.offsetHeight
                : offset.top,
          },
        });
      }
    }
  }

  _clickHandler(id, event) {
    const { width, height, onPointSelect, activePointData } = this.props;
    const elem = event.target;
    const offset = elem.getBoundingClientRect();

    if (activePointData) {
      this._deSelectItem();
      return;
    }

    onPointSelect({
      mode: 'team',
      team_id: id[0],
      league_id: id[1],
    });

    this.setState({
      tooltip: {
        id,
        x:
          offset.left < width / 2
            ? offset.left + elem.offsetWidth
            : offset.left,
        y:
          offset.top < height / 2 ? offset.top + elem.offsetHeight : offset.top,
        screenWidth: width,
        screenHeight: height,
      },
    });
  }

  _deSelectItem() {
    const { onPointSelect, activePointData } = this.props;

    if (!scrolling && activePointData) {
      onPointSelect(null);
      this.setState({
        tooltip: {
          id: null,
          x: 0,
          y: 0,
          child: null,
        },
      });
    } else {
      scrolling = false;
    }
  }

  renderTeams() {
    const options = {
      minWidth: 42,
      ratio: 0.618,
      groupNameHeight: 39,
      detailViewHeight: 50,
    };
    const {
      data = [],
      teams = {},
      regions = {},
      cDomain,
      cVal,
      sVal,
      width,
      height,
      groups = [],
      activePointData,
    } = this.props;
    const heatMapWidth = Math.floor(width - 10);

    const activeTeamId = _.get(activePointData, 'team_id', null);
    const activeLeagueId = _.get(activePointData, 'league_id', null);

    if (!data.length || !_.size(teams) || !_.size(regions) || !cDomain || !cVal)
      return null;

    const colorScale = d3_scale
      .scaleSequential()
      .domain([cDomain[0] - 0.001, cDomain[1] + 0.001 + cDomain[1] / 20])
      .interpolator(d3_scale.interpolateViridis);
    const byGroups = _.chain(data)
      .map(team => ({ ...teams[team.team_id], ...team }))
      .filter(
        team =>
          team.region_id &&
          team.team_name &&
          groups.filter(group => group.regions.indexOf(team.region_id) > -1)
            .length,
      )
      .groupBy(
        team =>
          groups.filter(group => group.regions.indexOf(team.region_id) > -1)[0]
            .name,
      )
      .value();

    const sortGroups = _.orderBy(
      groups,
      group => (byGroups[group.name] ? byGroups[group.name].length : 0),
      ['desc'],
    );

    function generateWidth(teamWidth) {
      const colsLength = heatMapWidth / teamWidth;
      const teamRows =
        data.length / colsLength + (_.size(byGroups) === 3 ? 2 : 1);
      const teamHeight = (heatMapWidth / colsLength) * options.ratio;
      const groupNames =
        _.size(byGroups) > 2 ? 2 : _.size(byGroups) * options.groupNameHeight;

      if (teamHeight * teamRows + groupNames + teamHeight < height) {
        return generateWidth(teamWidth + 1);
      }
      return teamWidth - 1;
    }

    const teamWidth = generateWidth(options.minWidth);
    const rowLength = Math.ceil(heatMapWidth / teamWidth);

    const renderGroup = group => {
      const detailed = teamWidth * options.ratio >= options.detailViewHeight;

      return _.orderBy(
        group,
        sVal === lang.alphabetically
          ? detailed
            ? 'short_club_name'
            : 'abk'
          : team => +team[sVal],
        sVal === lang.alphabetically ? 'asc' : 'desc',
      ).map((team, i) => (
        <div
          style={{
            backgroundColor: colorScale(team[cVal]),
            width: `${100 / rowLength}%`,
          }}
          key={`${team.team_id}_${team.league_id}_${i}`}
          className={`team ${
            activeTeamId == team.team_id && activeLeagueId == team.league_id
              ? 'active'
              : ''
          } ${detailed ? 'detail' : ''}`}
          onClick={e => this._clickHandler([team.team_id, team.league_id], e)}
          ref={`heatmap-${team.team_id}-${team.league_id}`}
        >
          <div className="align">
            {detailed ? (
              <Image
                src={
                  team.team_id
                    ? `${database.assets}teams/${team.team_id}.jpg`
                    : ''
                }
                aspectRatio={0.769}
              />
            ) : null}
            <div className="team-name">
              {detailed ? team.short_club_name : team.abk}
            </div>
            {detailed ? <div className="team-value">{team[cVal]}</div> : null}
          </div>
        </div>
      ));
    };

    return sortGroups
      .filter(group => byGroups[group.name])
      .map((group, g) => (
        <div className="group" key={g}>
          <div className="group-delay">
            <div className="group-name">{group.name}</div>
            <div className="group-list">
              {renderGroup(byGroups[group.name])}
            </div>
          </div>
        </div>
      ));
  }

  renderTooltip() {
    const { tooltipContent, activePointData, filteredData } = this.props;
    const { tooltip } = this.state;

    if (!activePointData) return null;

    return (
      <Tooltip
        style={{ left: tooltip.x, top: tooltip.y }}
        filteredData={filteredData}
      >
        {tooltipContent}
      </Tooltip>
    );
  }

  render() {
    const { height, width } = this.props;
    const heatMapWidth = Math.floor(width + 10);

    return (
      <div className="heatmap" style={{ width: heatMapWidth }}>
        <Scrollbars
          style={{ height }}
          autoHide
          onScrollStart={this._deSelectItem}
          ref="scrollbar"
        >
          {this.renderTeams()}
          {this.renderTooltip()}
        </Scrollbars>
      </div>
    );
  }
}
