import { IndicatorType, ListType, MatchListsId, ModeState } from '../types';

export const match: ModeState = {
  layouts: {
    contentWidth: null,
    visualHeight: null,
    tableHeight: 0,
    sidebarShow: true,
    tableVisible: 'hidden',
    initialTableShow: false,
  },
  scatterplot: {
    xVal: IndicatorType.RATING,
    yVal: IndicatorType.DEFENCE,
    sVal: IndicatorType.OFFENCE,
    cRange: [
      '#ecd100',
      '#c6cd19',
      '#a3c92b',
      '#84c43c',
      '#68c04c',
      '#4dbd5e',
      '#31ba71',
      '#00b886',
    ],
    xDomain: null,
    yDomain: null,
    rDomain: null,
    cDomain: null,
    sDomain: null,
    lassoSelection: null,
    sortLegendList: [
      IndicatorType.RATING,
      IndicatorType.OFFENCE,
      IndicatorType.DEFENCE,
      IndicatorType.TACKLE_OFFENCE,
      IndicatorType.TACKLE_DEFENCE,
      IndicatorType.INTERCEPT_OFFENCE,
      IndicatorType.INTERCEPT_DEFENCE,
      IndicatorType.BLOCKED,
      IndicatorType.SAVE,
      IndicatorType.AERIAL_OFFENCE,
      IndicatorType.AERIAL_DEFENCE,
      IndicatorType.TAKE_ON,
      IndicatorType.CROSSING,
      IndicatorType.PASS_SHORT,
      IndicatorType.PASS_LONG,
      IndicatorType.RECEPTION,
      IndicatorType.DELIVERY,
      IndicatorType.SCORING,
      IndicatorType.SCORING_EFFORT,
    ],
  },
  table: {
    sortBy: IndicatorType.RATING,
    sortDirection: 'DESC',
    sortedData: null,
    xVal: IndicatorType.RATING,
    yVal: IndicatorType.DEFENCE,
    sVal: IndicatorType.OFFENCE,
  },
  filterComponents: {
    filterLoaded: false,
    changeFilter: {
      slider: [],
      list: [],
    },
    switcher: {},
    slider: {
      [IndicatorType.COMPETITION_LEVEL]: {
        id: 1,
        name: IndicatorType.COMPETITION_LEVEL,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.25,
      },
    },
    list: {
      [ListType.LEAGUE_ID]: {
        id: MatchListsId[ListType.LEAGUE_ID],
        name: ListType.LEAGUE_ID,
        data: [],
      },
      [ListType.TEAM_ID]: {
        id: MatchListsId[ListType.TEAM_ID],
        name: ListType.TEAM_ID,
        data: [],
      },
      [ListType.FAVORITE_LEAGUES]: {
        id: MatchListsId[ListType.FAVORITE_LEAGUES],
        name: ListType.FAVORITE_LEAGUES,
        data: [],
      },
    },
  },
};
