import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Tooltip from './tooltip';

// Load actions, own fcts
import d3 from 'd3';
import * as OverviewActions from '../../../actions/overview';
import {
  changeScatterplotLegend,
  changeScatterplotDomain,
} from '../../../actions/overview';

// Load sub-components

// Load 3rd party libs and components
import HeatMap from './heatmap';

class Chart extends Component {
  static propTypes = {
    setOverviewState: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this._updateLegend = this._updateLegend.bind(this);
    this.onPointSelect = this.onPointSelect.bind(this);
  }

  _resizeScatterplot(nextProps) {
    const { activeMode } = nextProps;
    const { xVal, yVal, rVal, cVal, sVal } =
      nextProps.overview[activeMode].scatterplot;

    const xDomain = d3.extent(nextProps.filteredData, d => +d[xVal]);
    const yDomain = d3.extent(nextProps.filteredData, d => +d[yVal]);
    const rDomain = d3.extent(nextProps.filteredData, d => +d[rVal]);
    const cDomain = d3.extent(nextProps.filteredData, d => +d[cVal]);
    const sDomain = d3.extent(nextProps.filteredData, d => +d[sVal]);

    this.props.changeScatterplotDomain({
      xDomain,
      yDomain,
      rDomain,
      cDomain,
      sDomain,
      mode: activeMode,
    });
  }

  _updateLegend(e) {
    const { filteredData: data, activeMode } = this.props;
    let { xVal, yVal, rVal, cVal, sVal, xDomain, yDomain, rDomain, cDomain } =
      this.props.overview[activeMode].scatterplot;

    switch (e.id) {
      case 'x':
        xVal = e.val;
        xDomain = d3.extent(data, d => +d[e.val]);
        break;
      case 'y':
        yVal = e.val;
        yDomain = d3.extent(data, d => +d[e.val]);
        break;
      case 'r':
        rVal = e.val;
        rDomain = d3.extent(data, d => +d[e.val]);
        break;
      case 'c':
        cVal = e.val;
        cDomain = d3.extent(data, d => +d[e.val]);
        break;
      case 's':
        sVal = e.val;
        cDomain = d3.extent(data, d => +d[e.val]);
        break;
    }

    if (e.id !== 'x') xDomain = d3.extent(data, d => +d[xVal]);
    if (e.id !== 'y') yDomain = d3.extent(data, d => +d[yVal]);
    if (e.id !== 'r') rDomain = d3.extent(data, d => +d[rVal]);
    if (e.id !== 'c') cDomain = d3.extent(data, d => +d[cVal]);

    // reset selection, close popup if scale has been changed
    this.props.setOverviewState({ selectItem: undefined, tooltipOpen: false });

    // change scatterplot
    this.props.changeScatterplotLegend({
      xVal,
      yVal,
      rVal,
      cVal,
      sVal,
      xDomain,
      yDomain,
      rDomain,
      cDomain,
      mode: activeMode,
    });
  }

  onPointSelect(data) {
    const { setActivePointData } = this.props;

    if (data && data.match_id) {
      this.props.setOverviewState({
        selectItem: data.match_id,
        tooltipOpen: true,
      });
    }

    setActivePointData({
      mode: 'match',
      data,
    });
  }

  componentWillReceiveProps = nextProps => {
    if (
      (nextProps.app.loaded &&
        nextProps.app.overviewLoaded &&
        this.props.overview.match.scatterplot.xDomain == null) ||
      this.props.filteredData !== nextProps.filteredData
    ) {
      this._resizeScatterplot(nextProps);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.filteredData !== prevProps.filteredData) {
      this._updateLegend({});
    }
  }

  componentWillMount() {
    this._updateLegend({});
  }

  componentDidMount() {
    this._resizeScatterplot(this.props);
  }

  render() {
    const { teams, regions } = this.props.app;
    const {
      filteredData,
      clientWidth,
      clientHeight,
      selectItem,
      tooltipOpen,
      activeMode,
    } = this.props;
    const { rDomain, sDomain, sVal } = this.props.overview.match.scatterplot;
    const { contentWidth, visualHeight, tableHeight } =
      this.props.overview[activeMode].layouts;

    const activePointData = this.props.overview.activePointData.match;

    return (
      <div
        className="overview_scatterplot"
        style={{
          bottom: `${
            clientHeight - tableHeight + (visualHeight > 200 ? 60 : 0)
          }px`,
          opacity: tableHeight === clientHeight ? 0 : 1,
          visibility: tableHeight === clientHeight ? 'hidden' : 'visible',
        }}
      >
        <HeatMap
          {...{
            teams,
            regions,
            rDomain,
            sDomain,
            sVal,
            tooltipOpen,
          }}
          width={contentWidth ? contentWidth - 30 : 0}
          height={
            visualHeight ? visualHeight - (clientWidth <= 1024 ? 10 : 30) : 0
          }
          data={filteredData}
          cRange={['Viridis']}
          cScale="sequential"
          rScale="sqrt"
          selectItemID={selectItem}
          showTooltip
          tooltipContent={<Tooltip filteredData={filteredData} />}
          activePointData={activePointData}
          onPointSelect={this.onPointSelect}
        />

        <div className="legend">
          <div className="content-legend-inline">
            Sort and color by attractiveness
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    overview: state.overview,
    app: state.app,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      ...OverviewActions,
      changeScatterplotLegend,
      changeScatterplotDomain,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
