import './data-list.scss';

import React, { Component } from 'react';

export default class DataList extends Component {
  render() {
    const { children, className } = this.props;

    if (!children) return null;

    return <div className={`data-list ${className || ''}`}>{children}</div>;
  }
}
