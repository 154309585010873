import { GET_USERS, ADD_USER, UPDATE_USER, DELETE_USER } from '../constants';

const initState = [];

export default function (state = initState, action) {
  switch (action.type) {
    case GET_USERS: {
      return action.payload?.data || [];
    }

    case UPDATE_USER: {
      const { userId } = action.meta;

      return state.map(user => {
        if (user === userId) return action.payload.data;

        return user;
      });
    }

    case DELETE_USER: {
      return state.filter(user => user.id !== action.meta.userId);
    }

    case ADD_USER: {
      const { user, success } = action.payload.data || {};

      if (!user || !success) return state;

      return [...state, user];
    }

    default:
      return state;
  }
}
