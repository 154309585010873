// Import Style

import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Tooltip as ReactTooltip } from 'react-tooltip';

// Load actions, own fcts and components
import { ColorScale, PolarChart } from 'd3-react-touch';
import lang from '../../../lang';
import BarChartGroup from '../modules/bar-chart-group.js';
import { DataRank } from '../../ui/data-item';
import Icon from '../../ui/svg-icon';
import NoAccess from '../no-access';

// Load 3rd party libs and components
import { BENCHMARK_COLOR, COLOR_SCALE } from '../../../constants';
import {
  createBarChartsBenchmark,
  createPolarCharts,
  getPerformanceFromAnalyse,
} from '../../../helper';

import LeagueTable from './summary/league-table';
import Position from './summary/position';

class Summary extends Component {
  constructor(props) {
    super(props);

    this._renderBarCharts = this._renderBarCharts.bind(this);
  }

  _renderBarCharts() {
    const {
      information,
      mean,
      league_analyse,
      current_season,
      current_league,
      current_formation,
    } = this.props.details;

    const { teams } = this.props.app;
    const bars_vals = ['season_rating', 'stability', 'defence', 'offence'];
    const width = 120;
    const height = 50;
    const margin = {
      top: 13,
      right: 0,
      bottom: 1,
      left: 0,
    };
    const renderBenchmarkName = id => (teams[id] ? teams[id].abk : '');
    let animationDelayCounter = 0;
    const bars = createBarChartsBenchmark(
      {
        ...getPerformanceFromAnalyse(league_analyse, information, bars_vals),
        ...mean,
      },
      bars_vals,
      true,
    );

    const perf = getPerformanceFromAnalyse(
      league_analyse,
      information,
      bars_vals,
    );

    const renderGroup = group =>
      group.map((bar, b) => {
        animationDelayCounter++;

        return (
          <div className="col-6" key={b}>
            <BarChartGroup
              {...{
                data: bar,
                width,
                height,
                margin,
                link: `/details/team/:id/${current_league}/${current_season.join(
                  '/',
                )}/summary${
                  current_formation ? `?formation=${current_formation}` : ''
                }`,
                name: information.abk,
                renderBenchmarkName,
                animationDelay: 1000 + animationDelayCounter * 200,
                showZero: true,
                indicator: bar.indicator,
              }}
            />
          </div>
        );
      });

    return _.chunk(bars, 2).map((group, g) => (
      <div className="row first_el" key={g}>
        {renderGroup(group)}
      </div>
    ));
  }

  render() {
    const {
      performance_current,
      position,
      current_season,
      current_league,
      lineups,
      mean,
      league_analyse,
      information,
      isAccessGranted,
    } = this.props.details;
    const { leagues, formations } = this.props.app;
    const radar_width = 345;
    const radar_height = 260;
    const radar_margin = {
      top: 20,
      right: 14,
      bottom: 12,
      left: 16,
    };
    const radars_vals_against = [
      'tackle_defence',
      'tackle_offence',
      'intercept_defence',
      'intercept_offence',
      'blocked',
      'save',
      'aerial_defence',
      'aerial_offence',
    ];
    const radars_vals_on = [
      'take_on',
      'crossing',
      'pass_short',
      'pass_long',
      'corner',
      'delivery',
      'scoring',
      'scoring_effort',
    ];

    const filteredPositions = position.filter(
      p =>
        +p.season_id === +current_season &&
        +p.league_id === +current_league &&
        p.player_id,
    );

    const radars_against = createPolarCharts(
      {
        ...getPerformanceFromAnalyse(
          league_analyse,
          information,
          radars_vals_against,
        ),
        ...mean,
      },
      radars_vals_against,
    );
    const radars_onball = createPolarCharts(
      {
        ...getPerformanceFromAnalyse(
          league_analyse,
          information,
          radars_vals_on,
        ),
        ...mean,
      },
      radars_vals_on,
    );
    const currentLeagueName = _.get(leagues, `${current_league}.name`, '');

    return !isAccessGranted ? (
      <div className="summary no-access">
        <div className="content_group">
          <div className="row">
            <div className="col-6">
              <div className="h4 background">
                <span>{currentLeagueName}</span>
              </div>
            </div>
            <div className="col-1">&nbsp;</div>
            <div className="col-5">
              <div className="h4 background">
                <span>MOST COMMON LINEUP THIS SEASON</span>
              </div>
            </div>
          </div>
        </div>
        <NoAccess />
      </div>
    ) : (
      <div className="summary">
        <div className="content_group">
          <div className="row">
            <div className="col-6">
              <div className="h4 background">
                <span>{currentLeagueName}</span>
              </div>
              <LeagueTable />
            </div>
            <div className="col-1">&nbsp;</div>
            <div className="col-5">
              <div className="h4 background">
                <span>MOST COMMON LINEUP THIS SEASON</span>
              </div>
              <Position
                {...{ lineups, positions: filteredPositions, formations }}
              />
            </div>
          </div>
        </div>

        <div className="content_group summary_data">
          <div className="h4 background">
            <Icon name="ranking-winner" width="33px" height="28px" />
            <span>{lang.ownstats}</span>
          </div>

          <div className="row">
            <div className="col-4 charts_bar">
              {this._renderBarCharts()}
              {performance_current ? (
                <div className="row">
                  <DataRank
                    className="col-6"
                    title={lang.team_league}
                    rank={performance_current.rank_league}
                    desc={`of ${performance_current.rank_league_max} teams`}
                  />
                  <DataRank
                    className="col-6"
                    title={lang.team_season}
                    rank={performance_current.rank_season}
                    desc={`of ${performance_current.rank_season_max} teams`}
                  />
                </div>
              ) : null}
            </div>

            <div className="col-4">
              <div className="chart_group radar radar_1">
                <PolarChart
                  width={radar_width}
                  animation
                  animationDelay={1000}
                  height={radar_height}
                  margin={radar_margin}
                  tooltips={radars_against.tooltips}
                  classNames={radars_against.classes}
                  data={radars_against.items}
                  xDomain={radars_against.xDomain}
                  colors={COLOR_SCALE}
                  interpolationType="linear-closed"
                />
              </div>
            </div>

            <div className="col-4">
              <div className="chart_group radar radar_2">
                <PolarChart
                  width={radar_width}
                  animation
                  animationDelay={1400}
                  height={radar_height}
                  margin={radar_margin}
                  tooltips={radars_onball.tooltips}
                  classNames={radars_onball.classes}
                  data={radars_onball.items}
                  xDomain={radars_onball.xDomain}
                  colors={COLOR_SCALE}
                  interpolationType="linear-closed"
                />
              </div>
            </div>
          </div>

          <div>
            <ColorScale
              domain={radars_against.yDomain}
              colors={COLOR_SCALE}
              benchmarkColor={BENCHMARK_COLOR}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    details: state.details,
    app: state.app,
    overview: state.overview,
  };
}

export default connect(mapStateToProps)(Summary);
