import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --neongreen: #01BA8C;
    --primary-color: #01BA8C;
    --epi-color: #119A7E;
    --green: #018766;
    --red: #ff5959;
    --green-polar: #187141;
    --red-polar: #7A3B43;
    --blue-neon: #3f809c;
    --lila: #7F6788;
    --orange: #e3753a;
    --yellow: #e3ab3a;
    --green-palette: #3f9c6f;
    --purple: #88007F;
    --black-blue: #1B3345;
    --black-blue-light: #0B2839;
    --black-blue-dark: #113245;
    --blue: #35495C;
    --blue-light: #374B5F;
    --blue-lightest: #314455;
    --blue-light2: #476381;
    --blue-auth-container: #2B3F52;
    --blue-grey: #97ADC4;
    --blue-grey-light: #c8d7e3;
    --blue-grey-lightest: #E6ECF1;
    --grey: #dfe3e6;
    --grey-light: #f5f6f7;
    --grey-dark: #C9C9C9;
    --grey-darkness: #A4A4A4;
    --grey-darkness-ever: #54605b;
    --anthrazit: #2D343C;
    --anthrazit-dark: #23282F;
    --black: #111;
    --black-light: #21252b;
    --dark-background-primary: #35495C;
    --dark-background-secondary: #374B5F;
    --dark-background-tertiary: #314455;
    --dark-sidebar-primary: #2D343C;
    --dark-sidebar-secondary: #23282F;
    --dark-sidebar-line-hor-primary: #21252b;
    --dark-sidebar-line-hor-seconday: #171B20;
    --dark-link-text: #F2F2F4;
    --dark-h1: #E6ECF1;
    --dark-h2: #97ADC4;
    --dark-actionbar: #113245;
    --light-background-primary: #f8f9fa;
    --light-headline-primary: #35495C;
    --light-subheadline-primary: #374B5F;
    --light-link-text: #0B2839;
    --light-divider: #dfe3e6;
    --light-mean: #C9C9C9;
    --light-benchmark: #0c5b48;
    --light-chart-mean: darken(#C9C9C9, 20%);
    --light-chart-primary: #1D9879;
    --light-chart-secondary: #9f51c0;
    --light-chart-tertiary: #f5ac3a;
    --light-chart-fourth: #2980B9;
    --light-chart-fifth: #7F6788;
    --overview-navigation: #181C20;
    --squad-tr-color: rgba(#C4D3E1, .4);
    --squad-thead-border: #95A9BC;
    --squad-link-hover: #0F5983;
    --color-light-border: #E1E8EE;
    --color-meta-text: rgba(53, 73, 92, .6);
    --color-active-bg-blue: #D8DEE3;
    --color-active-light-blue: #F5F8FA;
    --color-link: #0F5983;
    --color-active: #5CBB8D;
    --color-tag-bg: #A4B2BF;
    --color-text-light: #63717F;
    --time-transition: .15s;
    --time-transition-long: .2s;
    --color-content-title: #113245;
    --color-content-text: #222222;
    --color-placeholder: rgba(17, 50, 69, .2);
    --color-dark-blue-background: #113245;
    --team-home: #2980B9;
    --team-away: #f5ac3a;

    // New colors
    --gray-lightest: #F2F2F4;
  }
`;
