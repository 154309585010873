import React, { Component } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { browserHistory } from 'react-router';

import Header from './layouts/header';

import Summary from './team/summary';
import SquadList from './team/squad-list';
import SquadAnalyse from './team/squad-analyse';
import Performance from './team/performance';
import History from './team/history';
import LeagueAnalyse from './team/league-analyse';

export default class Team extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabs: [
        'summary',
        'squadList',
        'squadAnalyse',
        'performance',
        'leagueAnalyse',
        'history',
      ],
      index: 0,
    };

    this.onChangeIndex = this.onChangeIndex.bind(this);
  }

  onChangeIndex(nextIndex) {
    const { index } = this.state;
    const tab = this.state.tabs[nextIndex];
    const { id, current_season, current_league, mode } = this.props.details;

    index !== nextIndex &&
      browserHistory.push(
        `/details/${mode}/${id}/${current_league}/${current_season}/${tab}`,
      );
  }

  componentWillMount() {
    const { tab } = this.props;
    const { tabs } = this.state;
    const index = tabs.indexOf(tab);

    index > -1 && this.setState({ index });
  }

  componentWillUpdate(nextProps) {
    const { tabs, index } = this.state;
    const nextTab = nextProps.tab;
    const nextIndex = tabs.indexOf(nextTab);

    nextIndex > -1 &&
      index !== nextIndex &&
      this.setState({ index: nextIndex });
  }

  render() {
    const { onChangeIndex } = this;
    const { tab } = this.props;
    const { index } = this.state;

    return (
      <div>
        <Header {...{ tab }} />

        <SwipeableViews
          style={{ width: '100%' }}
          animateHeight
          enableMouseEvents={false}
          ignoreNativeScroll
          {...{ index, onChangeIndex }}
        >
          <div>
            <div className="content-container">
              <Summary />
            </div>
          </div>

          <div>
            <div className="content-container">
              <SquadList />
            </div>
          </div>

          <div>
            <div className="content-container">
              <SquadAnalyse />
            </div>
          </div>

          <div>
            <div className="content-container">
              <Performance />
            </div>
          </div>

          <div>
            <div className="content-container">
              <LeagueAnalyse />
            </div>
          </div>

          <div>
            <div className="content-container">
              <History />
            </div>
          </div>
        </SwipeableViews>
      </div>
    );
  }
}
