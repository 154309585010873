import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CheckboxField } from '../fields/CheckboxField';
import { ListType } from '../../../../../reducers/overview/types';
import { ModeType } from '../../../../../types/Dict';
import { ListField } from '../fields/ListField';
import { selectListFilter } from '../../../../../reducers/overview/selectors';
import { RootState } from '../../../../../types';
import { changeListHelperValue } from '../../../../../actions/overview';
import {
  loading,
  loadPlayersPerformanceData,
} from '../../../../../actions/app';
import { selectCurrentSeason } from '../../../../../reducers/app/selectors';

interface Props {
  listName?: ListType;
  mode: ModeType;
}

const defaultProps = {
  listName: ListType.FORMATION_ID,
};

export function FormationFilter(props: Props & typeof defaultProps) {
  const { mode, listName } = props;
  const dispatch = useDispatch();

  const formationList = useSelector((state: RootState) =>
    selectListFilter(state, { mode, listName }),
  );
  const positionList = useSelector((state: RootState) =>
    selectListFilter(state, { mode, listName: ListType.POSITION_FIRST_ID }),
  );
  const currentSeason = useSelector((state: RootState) =>
    selectCurrentSeason(state, { mode }),
  );

  const onSplitChange = (name, value) => {
    dispatch(loading(true, 'loading_players_performance'));
    dispatch(changeListHelperValue(listName, { splitted: value }, mode));

    let playersGroupBy;
    const splittedPosition = positionList?.mode;
    const splittedFormation = value;

    if (splittedPosition && splittedFormation) {
      playersGroupBy = `formation_position_${splittedPosition}`;
    } else if (splittedPosition) {
      playersGroupBy = `position_${splittedPosition}`;
    } else if (splittedFormation) {
      playersGroupBy = `formation_position_${splittedPosition}`;
    }

    dispatch(
      loadPlayersPerformanceData({ season_id: currentSeason, playersGroupBy }),
    );
  };

  return (
    <div className="filter_list_long">
      {mode === ModeType.PLAYER ? (
        <CheckboxField
          name={listName}
          checked={!!formationList?.splitted}
          onChange={onSplitChange}
        >
          Separate data by formation
        </CheckboxField>
      ) : null}
      <ListField
        mode={mode}
        listName={listName}
        withSearch={listName === 'team_id' || listName === 'league_id'}
      />
    </div>
  );
}

FormationFilter.defaultProps = defaultProps;
