import { BASE_URL } from '../constants';
import { initState as notesInitialState } from '../reducers/notes';
import { initState as searchInitialState } from '../reducers/search';

import { initialState as app } from '../reducers/app';
import { initialState as overview } from '../reducers/overview/state';

export const ROOT_URL = BASE_URL || 'https://api.scoutpanel.com';

export default {
  app,
  welcome: {},
  overview,

  details: {
    id: null,
    performance: [[]],
    performance_current: [[]],
    history: [],
    history_details: [],
    similar: [],
    position: [],
    information: {},
    current_season: [],
    current_league: null,
    current_formation: null,
    current_position: null,
    squad_list: [],
    squad_analyse: [],
    league_table: [],
    lineups: [],
    mode: null,
    playerStats: [],
    matchDay: [],
    matchDays: [],
    dataModalError: false,
    dataModalErrorMode: '',
  },
  compare: {
    data: [
      {
        id: 0,
        player_id: null,
        season: 0,
        league: 0,
        data: {},
        edit: true,
      },
      {
        id: 1,
        player_id: null,
        season: 0,
        league: 0,
        data: {},
        edit: true,
      },
      {
        id: 2,
        player_id: null,
        season: 0,
        league: 0,
        data: {},
        edit: true,
      },
      {
        id: 3,
        player_id: null,
        season: 0,
        league: 0,
        data: {},
        edit: true,
      },
    ],
  },
  favorites: {
    filter: {
      position: [],
      category: [],
      age: [0, 100],
      age_range: [0, 100],
    },
    player: {
      list: [],
      history: [],
    },
    team: {
      list: [],
      history: [],
    },
    match: {
      list: [],
      history: [],
    },
  },
  shadowTeams: [],
  search: searchInitialState,
  notes: notesInitialState,
  users: [],
  roles: [],
};
