import {
  AUTH_LOGIN_REQUEST_ERROR,
  AUTH_LOGIN_REQUEST_SUCCESS,
  AUTH_LOGOUT,
  BASE_URL,
  PASSWORD_RESET,
  SET_PRIVACY,
} from '../constants';
import { browserHistory } from 'react-router';
import axios from 'axios';
import _ from 'lodash';

import eventTracker from '../helper/event-tracker';

import { database } from '../constants/database';

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
  'token',
)}`;

function initUsersnap() {
  var script = document.createElement('script');
  script.defer = 1;
  script.src =
    'https://widget.usersnap.com/load/9b90227c-c3d7-413e-92a1-0fb9f4348869?onload=onUsersnapLoad';
  document.getElementsByTagName('head')[0].appendChild(script);
}

export function sendLoginRequest(userData) {
  return function (dispatch) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${axios.defaults.baseURL}/auth/login`, true);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.withCredentials = true;
    xhr.send(JSON.stringify(userData));

    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) return;
      try {
        const res = JSON.parse(xhr.responseText);

        if (xhr.status === 200 && res.message === 'OK') {
          const groupName = _.get(res, 'user.group.name', '');
          if (groupName.toLowerCase() === 'Matchmetrics Team'.toLowerCase()) {
            initUsersnap();
          }

          eventTracker.trackEvent('Auth', 'Login');
          dispatch({
            type: AUTH_LOGIN_REQUEST_SUCCESS,
            payload: { token: res.token, user: res.user },
          });

          if (
            browserHistory.getCurrentLocation().query &&
            browserHistory.getCurrentLocation().query.next
          ) {
            browserHistory.replace(
              browserHistory.getCurrentLocation().query.next,
            );
          } else {
            browserHistory.replace('/home');
          }
        } else {
          dispatch({
            type: AUTH_LOGIN_REQUEST_ERROR,
            payload: res.message,
          });
        }
      } catch (e) {
        console.error(e);
      }
    };
  };
}

export function logout() {
  return function (dispatch) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `${axios.defaults.baseURL}/auth/logout`, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.withCredentials = true;
    xhr.send();

    localStorage.removeItem('token');
    localStorage.removeItem('overview_filter_player');
    localStorage.removeItem('overview_filter_team');
    localStorage.removeItem('overview_filter_match');
    axios.defaults.headers.Authorization = null;

    eventTracker.trackEvent('Auth', 'Logout');
    dispatch({ type: AUTH_LOGOUT });

    window.Intercom('shutdown');
  };
}

export function checkAuth() {
  return function (dispatch) {
    const token = localStorage.getItem('token');
    if (!token) {
      if (
        browserHistory.getCurrentLocation().pathname &&
        browserHistory.getCurrentLocation().pathname.indexOf('auth') === -1
      ) {
        browserHistory.replace({
          pathname: '/auth/login',
          query: {
            next: browserHistory.getCurrentLocation().pathname,
          },
        });
      }
      dispatch({ type: AUTH_LOGOUT });
    } else {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', `${axios.defaults.baseURL}/auth/iAm`, true);
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      xhr.withCredentials = true;
      xhr.send();

      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) return;

        try {
          if (xhr.status === 200) {
            const res = JSON.parse(xhr.responseText);
            if (res.success === true) {
              const groupName = _.get(res, 'user.group.name', '');
              if (
                groupName.toLowerCase() === 'Matchmetrics Team'.toLowerCase()
              ) {
                initUsersnap();
              }

              dispatch({
                type: AUTH_LOGIN_REQUEST_SUCCESS,
                payload: {
                  user: res.user,
                },
              });
            }
          } else {
            if (
              browserHistory.getCurrentLocation().pathname &&
              browserHistory.getCurrentLocation().pathname.indexOf('auth') ===
                -1
            ) {
              browserHistory.replace({
                pathname: '/auth/login',
                query: {
                  next: browserHistory.getCurrentLocation().pathname,
                },
              });
            }
          }

          if (xhr.status === 401) {
            dispatch({ type: AUTH_LOGOUT });
            dispatch({
              type: AUTH_LOGIN_REQUEST_ERROR,
              payload: '',
            });
          }
        } catch (e) {
          if (e.message !== 'Not Logged In') console.error('not logged in', e);

          dispatch({ type: AUTH_LOGOUT });
        }
      };
    }
  };
}

export function resetPassword({ email, password, token }) {
  const body = {};
  if (email) body.email = email;
  if (token) body.token = token;
  if (password) body.password = password;

  const request = axios.post(`${database.root}/auth/forgot`, body);

  eventTracker.trackEvent('Auth', 'Reset password');
  return {
    type: PASSWORD_RESET,
    payload: request,
  };
}

export function setPrivacy(isAgree) {
  const request = axios.post(`${database.root}/users/privacy`, {
    acceptPrivacy: isAgree,
  });

  return {
    type: SET_PRIVACY,
    payload: request,
  };
}
