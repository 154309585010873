import { createSelector } from 'reselect';
import _ from 'lodash';

const getOverviewLeagues = state => state.competitionLevel.overviewLeagues;
const getFilter = state => state.competitionLevel.filter;
const getLeagues = state => state.app.leagues;

export const getFilteredOverviewLeagues = createSelector(
  [getOverviewLeagues, getFilter, getLeagues],
  (overviewLeagues, filter, leagues) => {
    const {
      firstLeagueId,
      leagueIds,
      countryIds,
      division,
      rating,
      stability,
    } = filter;

    const selectedLeagues = overviewLeagues.filter(({ id, country_id }) => {
      if (
        id === firstLeagueId ||
        countryIds.indexOf(country_id) !== -1 ||
        leagueIds.indexOf(id) !== -1
      ) {
        return true;
      }

      return false;
    });

    const filteredLeagues = selectedLeagues.filter(league => {
      const { id } = league;
      const { divisionlevel } = leagues[id] || {};

      if (id === firstLeagueId) return true;

      if (divisionlevel < division[0]) return false;
      if (division[1] !== 5 && divisionlevel > division[1]) return false;
      if (!(league.rating >= rating[0] && league.rating <= rating[1]))
        return false;
      if (
        !(league.stability >= stability[0] && league.stability <= stability[1])
      )
        return false;

      return true;
    });

    return _.orderBy(
      filteredLeagues.map(league => ({
        ...league,
        showLabel: league.id === filter.firstLeagueId,
        highlight: league.id === filter.firstLeagueId,
      })),
      'showLabel',
    );
  },
);
