import _ from 'lodash';

import { FETCH_SEARCH, RESET_SEARCH, START_FETCH_SEARCH } from '../constants';

export const initState = {
  loading: false,
  loaded: false,
  list: [],
  count: 0,
  mode: 'player',
  error: null,
  page: 0,
};

export default function (state = initState, action) {
  switch (action.type) {
    case START_FETCH_SEARCH:
      return {
        ...state,
        mode: action.payload,
        loading: true,
        loaded: false,
        error: null,
      };
    case RESET_SEARCH:
      return {
        ...state,
        list: [],
        count: 0,
        loading: true,
        loaded: false,
        page: 0,
      };
    case FETCH_SEARCH:
      const search = _.get(action, 'payload.data', {});
      if (action.payload.status === 200) {
        return {
          ...state,
          loading: false,
          loaded: true,
          list: [...state.list, ...search.list],
          count: search.count,
          error: null,
          page: search.page,
        };
      }

      return {
        ...state,
        loading: true,
        loaded: true,
        list: [],
        count: 0,
        error: search.message,
        page: 0,
      };

    default:
      return state;
  }
}
