import * as types from '../constants';

// actually write the reducer
export default welcome(
  {},

  {
    [types.FETCH_DATA_FOR_WELCOME](state, action) {
      return {
        ...action.payload.data.data,
      };
    },
  },
);

// reducer =  takes any kind of action - along with the current state - and invokes the core function that matches the action
function welcome(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}
