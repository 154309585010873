import { createSelector } from 'reselect';
import _ from 'lodash';
import { ModeType } from '../types/Dict';
import { IndicatorType } from '../types/Indicators';

const radars_vals_against = [
  'tackle_defence',
  'tackle_offence',
  'intercept_defence',
  'intercept_offence',
  'blocked',
  'save',
  'aerial_offence',
  'aerial_defence',
];
const radars_vals_on = [
  'take_on',
  'crossing',
  'pass_short',
  'pass_long',
  'reception',
  'delivery',
  'scoring',
  'scoring_effort',
];
const all_vals = [...radars_vals_on, ...radars_vals_against];
const goalkeeper_vals = [
  'intercept_defence',
  'save',
  'claim',
  'keeper_throw',
  'pass_long',
  'pass_short',
  'reception',
  'delivery',
];
const intersectWithDuplicates = (f, xs, ys) =>
  xs.filter(x => ys.some(y => f(x, y)));
const equals = (x, y) => +x.player_id === +y.player_id;

const getPlayersMetaData = state => state.app.playersMetaData;
const getLeagues = state => state.app.leagues;
const getPlayersPerformanceData = state => state.app.playersPerformanceData;
const getPlayersFilterComponents = state =>
  state.overview.player.filterComponents;
const getFavoritePlayers = state => state.app.favorites.player;
const getRankingsFilterComponents = state =>
  state.overview[ModeType.RANKINGS].filterComponents;

const getTeamsFilterComponents = state => state.overview.team.filterComponents;
const getTeamsPerformanceData = state => state.app.teamsPerformanceData;
const getFavoriteTeams = state => state.app.favorites.team;

const getMatchesPerformanceData = state => state.app.matchesPerformanceData;
const getMatchesFilterComponents = state =>
  state.overview.match.filterComponents;
const getFavoriteMatches = state => state.app.favorites.match;
const getCurrentSeason = state => state.app.season_current;
const getPlayersRankingSettings = state => state.overviewRankings.settings;

let playerMinsCache = [];
let prevStart = null;
let prevEnd = null;
let prevSeason = null;

const filterData = (
  data,
  leagues,
  filterComponents,
  favorites,
  currentSeason,
  primaryKey,
  metaData,
) => {
  if (!filterComponents.filterLoaded) return [];
  data = data || [];
  let filtered = [];

  const dataLength = _.get(filterComponents, 'list.league_id.data.length', 0);

  if (dataLength > 0) {
    const filterLine = {};
    const filterSlider = {};
    const filterSwitcher = {};
    let ageMinAll = null;
    let ageMaxAll = null;
    let isByYear = false;

    const changedList = _.filter(
      filterComponents.list,
      (list, listName) =>
        filterComponents.changeFilter.list.indexOf(listName) > -1,
    );

    for (let a = 0, b = changedList.length; a < b; a++) {
      if (changedList[a].name === 'country_id') {
        filterLine[changedList[a].name] = changedList[a].data.map(
          firstLevel => {
            if (!firstLevel.items) return firstLevel;

            return firstLevel.items.map(secondLevel => {
              if (!secondLevel.items) return secondLevel;

              if (
                !secondLevel.active &&
                !secondLevel.items.filter(({ active }) => active).length
              ) {
                if (filterLine.region_id) {
                  filterLine.region_id.push(secondLevel.id);
                } else {
                  filterLine.region_id = [secondLevel.id];
                }
              }

              return secondLevel.items.map(thirdLevel => thirdLevel);
            });
          },
        );

        filterLine[changedList[a].name] = _.flattenDeep(
          filterLine[changedList[a].name],
        );
        filterLine[changedList[a].name] = filterLine[changedList[a].name]
          .filter(line => !line.active)
          .map(line => line.id);
      } else if (changedList[a].name !== 'season_id') {
        filterLine[changedList[a].name] = changedList[a].data.map(
          firstLevel => {
            if (!firstLevel.items) return firstLevel;

            return firstLevel.items.map(secondLevel => {
              if (!secondLevel.items) return secondLevel;

              return secondLevel.items.map(thirdLevel => {
                if (!thirdLevel.items) return thirdLevel;

                return thirdLevel.items;
              });
            });
          },
        );

        filterLine[changedList[a].name] = _.flattenDeep(
          filterLine[changedList[a].name],
        );

        if (changedList[a].name === 'team_id') {
          filterLine[changedList[a].name] = filterLine[changedList[a].name]
            .filter(line => line.active)
            .map(line => line.id);
        } else {
          filterLine[changedList[a].name] = filterLine[changedList[a].name]
            .filter(line => !line.active)
            .map(line => line.id);
        }
      }
    }

    const changedSlider = _.filter(
      filterComponents.slider,
      (slider, sliderName) =>
        filterComponents.changeFilter.slider.indexOf(sliderName) > -1,
    );

    for (let i = 0, l = changedSlider.length; i < l; i += 1) {
      if (changedSlider[i].name === 'seasonAge' && primaryKey === 'player_id') {
        [ageMinAll, ageMaxAll] = changedSlider[i].minMax;
        isByYear = changedSlider[i].isByYear;
      }
      if (changedSlider[i].name !== 'season_id') {
        filterSlider[changedSlider[i].name] = changedSlider[i].data;
      }
    }

    const changedSwitcher = _.values(filterComponents.switcher);

    for (let c = 0, d = changedSwitcher.length; c < d; c++) {
      filterSwitcher[changedSwitcher[c].name] = changedSwitcher[c].data;
    }

    const minsSlider = filterSlider[IndicatorType.SEASON_MINS];
    if (primaryKey === 'player_id') {
      if (
        minsSlider[0] === prevStart &&
        prevEnd === minsSlider[1] &&
        prevSeason === currentSeason
      ) {
        data = playerMinsCache;
      } else {
        playerMinsCache = data.filter(
          player =>
            +player[IndicatorType.SEASON_MINS] >= minsSlider[0] &&
            +player[IndicatorType.SEASON_MINS] <= minsSlider[1],
        );
        prevStart = minsSlider[0];
        prevEnd = minsSlider[1];
        prevSeason = currentSeason;
      }
    }

    const isFavorite = filterSwitcher.favorites;
    if (isFavorite) {
      delete filterSwitcher.favorites;
      data = intersectWithDuplicates(equals, data, favorites);
    }

    if (filterSlider.competition_level) {
      if (!filterLine.league_id) filterLine.league_id = [];

      for (const league_id in leagues) {
        const { rating } = leagues[league_id];

        if (
          !(
            rating >= filterSlider.competition_level[0] &&
            rating <= filterSlider.competition_level[1]
          )
        ) {
          filterLine.league_id.push(+league_id);
        }
      }
    }

    const hideTransfered = filterSwitcher.hidetransfered;

    data = data.reduce((filtered, d) => {
      let result = true;
      let player = { ...d };

      if (primaryKey === 'player_id') {
        player.contract_expiry_year =
          metaData &&
          metaData[d.player_id] &&
          (+metaData[d.player_id].contract_expiry_year || null);
        player.seasonAge =
          metaData &&
          metaData[d.player_id] &&
          (+metaData[d.player_id].seasonAge || null);
        player.birth_year =
          metaData &&
          metaData[d.player_id] &&
          (+metaData[d.player_id].birth_year || null);
        player.marketvalue =
          metaData &&
          metaData[d.player_id] &&
          (+metaData[d.player_id].marketvalue || 0);
        player.preferred_foot =
          (metaData && metaData[d.player_id]?.preferred_foot?.toLowerCase()) ||
          '–';
      }

      if (hideTransfered) {
        result = +player.team_id === +metaData[player.player_id]?.team_id;
      }

      if (result) {
        const includeMissing = _.chain(changedSlider)
          .find({ name: 'contract_expiry' })
          .get('include_missing_data')
          .value();

        for (const key in filterSlider) {
          const value = filterSlider[key];

          if (key === 'contract_expiry') {
            let contract_expiry = metaData[player.player_id]
              ? +metaData[player.player_id].contract_expiry
              : 1900;
            if (!contract_expiry) contract_expiry = 1900;

            if (
              !(
                key === 'contract_expiry' &&
                contract_expiry === 1900 &&
                includeMissing
              )
            ) {
              if (
                !(contract_expiry >= value[0] && contract_expiry <= value[1])
              ) {
                result = false;
              }
            }
          } else if (key === 'seasonAge' && primaryKey === 'player_id') {
            if (!isByYear) {
              // age range
              if (value[0] !== ageMinAll && value[1] !== ageMaxAll) {
                if (!(+player[key] >= value[0] && +player[key] <= value[1])) {
                  result = false;
                }
              } else if (value[0] !== ageMinAll && value[1] === ageMaxAll) {
                if (player[key] < value[0]) {
                  result = false;
                }
              } else if (value[0] === ageMinAll && value[1] !== ageMaxAll) {
                if (player[key] > value[1]) {
                  result = false;
                }
              }
            } else if (
              player.birth_year < +value[0] ||
              player.birth_year > +value[1]
            ) {
              result = false;
            }
          } else if (key === 'competition_level') {
            // do nothing
          } else if (!(+player[key] >= value[0] && +player[key] <= value[1])) {
            result = false;
          }
        }
      }

      if (result) {
        for (const key in filterLine) {
          const value = filterLine[key];

          // positionMode

          if (key === 'preferred_foot') {
            if (value.indexOf(player.preferred_foot) !== -1) {
              result = false;
            }
          } else if (key === 'team_id') {
            if (
              value.indexOf(+player.team_id) === -1 &&
              value.indexOf(+player.team_id_home) === -1 &&
              value.indexOf(+player.team_id_away) === -1
            ) {
              result = false;
            }
          } else if (key === 'region_id') {
            const playerRegion = +_.get(
              metaData,
              `[${player.player_id}][${key}]`,
            );
            if (value.indexOf(playerRegion) !== -1) {
              result = false;
            }
          } else if (key === 'country_id') {
            const playerCountry = _.get(
              metaData,
              `[${player.player_id}][${key}]`,
            );
            if (value.indexOf(playerCountry) !== -1) {
              result = false;
            }
          } else if (value.indexOf(+player[key]) !== -1) {
            result = false;
          }
        }
      }

      if (result) {
        filtered.push(player);
      }

      return filtered;
    }, []);
  }

  return data;
};

const filterDataRanks = (
  data,
  leagues,
  filterComponents,
  favorites,
  currentSeason,
  primaryKey,
  metaData,
  settings,
) => {
  let filteredData = filterData(
    data,
    leagues,
    filterComponents,
    favorites,
    currentSeason,
    primaryKey,
    metaData,
  );

  for (let i = 0; i < filteredData.length; i++) {
    const player = { ...filteredData[i] };

    player.score = 0;
    player.second_score = player[settings.colorBy];

    if (
      settings.colorBy === 'contract_expiry_year' &&
      player.second_score <= 0
    ) {
      player.second_score = 'N/D';
    }

    for (let j = 0; j < settings.rankBy.length; j++) {
      const indicator = settings.rankBy[j];
      player.score += +player[indicator] || 0;
    }
    player.score = parseFloat(
      Number(player.score / settings.rankBy.length).toFixed(2),
    );
  }

  filteredData = _.orderBy(
    filteredData,
    [p => +p.score, p => +p.second_score],
    ['desc', 'desc'],
  );
  const dataByPosition = _.groupBy(filteredData, 'position_first_id');
  const dataByPositionGroups = _.groupBy(filteredData, 'position_id');

  const meanByPositions = {};
  _.forEach(dataByPosition, (position, position_id) => {
    let vals = all_vals;
    if (+position_id === 10) {
      vals = goalkeeper_vals;
      return;
    }
    for (let i = 0; i < vals.length; i++) {
      const val = vals[i];
      if (!meanByPositions[position_id]) {
        meanByPositions[position_id] = {};
      }
      meanByPositions[position_id][`${val}_mean`] = _.meanBy(
        position,
        p => +p[val] || 0,
      );
    }
  });

  const meanByPositionGroups = {};
  _.forEach(dataByPositionGroups, (position, position_id) => {
    let vals = all_vals;
    if (+position_id === 1) {
      vals = goalkeeper_vals;
    }
    for (let i = 0; i < vals.length; i++) {
      const val = vals[i];
      if (!meanByPositionGroups[position_id]) {
        meanByPositionGroups[position_id] = {};
      }
      meanByPositionGroups[position_id][`${val}_mean`] = _.meanBy(
        position,
        p => +p[val] || 0,
      );
    }
  });

  return {
    dataByPosition,
    dataByPositionGroups,
    meanByPositions,
    meanByPositionGroups,
    data: filteredData,
    dataLength: filteredData.length,
  };
};

// filter data based on current filter components
export const getPlayersFilteredData = createSelector(
  [
    getPlayersPerformanceData,
    getLeagues,
    getPlayersFilterComponents,
    getFavoritePlayers,
    getCurrentSeason,
    getPlayersMetaData,
  ],
  (
    playersPerformanceData,
    leagues,
    filterComponents,
    favoritePlayers,
    currentSeason,
    metaData,
  ) => {
    const data = filterData(
      playersPerformanceData,
      leagues,
      filterComponents,
      favoritePlayers,
      currentSeason.player,
      'player_id',
      metaData,
    );

    const dataByPositionGroups = _.groupBy(data, 'position_id');
    const meanByPositionGroups = {};

    _.forEach(dataByPositionGroups, (position, position_id) => {
      let vals = all_vals;
      if (+position_id === 1) {
        vals = goalkeeper_vals;
      }
      for (let i = 0; i < vals.length; i++) {
        const val = vals[i];
        if (!meanByPositionGroups[position_id]) {
          meanByPositionGroups[position_id] = {};
        }
        meanByPositionGroups[position_id][`${val}_mean`] = _.meanBy(
          position,
          p => +p[val] || 0,
        );
      }
    });

    return { data, meanByPositionGroups };
  },
);

export const getTeamsFilteredData = createSelector(
  [
    getLeagues,
    getTeamsPerformanceData,
    getTeamsFilterComponents,
    getFavoriteTeams,
    getCurrentSeason,
  ],
  (
    leagues,
    teamsPerformanceData,
    filterComponents,
    favoriteTeams,
    currentSeason,
  ) => {
    return {
      data: filterData(
        teamsPerformanceData,
        leagues,
        filterComponents,
        favoriteTeams,
        currentSeason.team,
        'team_id',
      ),
    };
  },
);

export const getMatchesFilteredData = createSelector(
  [
    getMatchesPerformanceData,
    getLeagues,
    getMatchesFilterComponents,
    getFavoriteMatches,
    getCurrentSeason,
  ],
  (
    matchesPerformanceData,
    leagues,
    filterComponents,
    favoriteMatches,
    currentSeason,
  ) => {
    return {
      data: filterData(
        matchesPerformanceData,
        leagues,
        filterComponents,
        favoriteMatches,
        currentSeason.match,
        'match_id',
      ),
    };
  },
);

export const getPlayersFilteredDataRanks = createSelector(
  [
    getPlayersPerformanceData,
    getLeagues,
    getPlayersFilterComponents,
    getFavoritePlayers,
    getCurrentSeason,
    getPlayersMetaData,
    getPlayersRankingSettings,
  ],
  (
    playersPerformanceData,
    leagues,
    filterComponents,
    favoritePlayers,
    currentSeason,
    metaData,
    settings,
  ) => {
    let filteredData = filterData(
      playersPerformanceData,
      leagues,
      filterComponents,
      favoritePlayers,
      currentSeason,
      'player_id',
      metaData,
    );

    for (let i = 0; i < filteredData.length; i++) {
      const player = filteredData[i];

      player.score = 0;
      player.second_score = player[settings.colorBy];

      if (
        settings.colorBy === 'contract_expiry_year' &&
        player.second_score <= 0
      ) {
        player.second_score = 'N/D';
      }

      for (let j = 0; j < settings.rankBy.length; j++) {
        const indicator = settings.rankBy[j];
        player.score += +player[indicator] || 0;
      }
      player.score = parseFloat(
        Number(player.score / settings.rankBy.length).toFixed(2),
      );
    }

    filteredData = _.orderBy(
      filteredData,
      [p => +p.score, p => +p.second_score],
      ['desc', 'desc'],
    );
    const dataByPosition = _.groupBy(filteredData, 'position_first_id');
    const dataByPositionGroups = _.groupBy(filteredData, 'position_id');

    const meanByPositions = {};
    _.forEach(dataByPosition, (position, position_id) => {
      let vals = all_vals;
      if (+position_id === 10) {
        vals = goalkeeper_vals;
        return;
      }
      for (let i = 0; i < vals.length; i++) {
        const val = vals[i];
        if (!meanByPositions[position_id]) {
          meanByPositions[position_id] = {};
        }
        meanByPositions[position_id][`${val}_mean`] = _.meanBy(
          position,
          p => +p[val] || 0,
        );
      }
    });

    const meanByPositionGroups = {};
    _.forEach(dataByPositionGroups, (position, position_id) => {
      let vals = all_vals;
      if (+position_id === 1) {
        vals = goalkeeper_vals;
      }
      for (let i = 0; i < vals.length; i++) {
        const val = vals[i];
        if (!meanByPositionGroups[position_id]) {
          meanByPositionGroups[position_id] = {};
        }
        meanByPositionGroups[position_id][`${val}_mean`] = _.meanBy(
          position,
          p => +p[val] || 0,
        );
      }
    });

    return {
      dataByPosition,
      dataByPositionGroups,
      meanByPositions,
      meanByPositionGroups,
      data: filteredData,
      dataLength: filteredData.length,
    };
  },
);

export const getFilteredDataRanks = state => {
  return getPlayersFilteredDataRanks(state);
};

export const getFilteredData = (state, mode) => {
  switch (mode) {
    case ModeType.PLAYER:
      return getPlayersFilteredData(state);
    case ModeType.TEAM:
      return getTeamsFilteredData(state);
    case ModeType.MATCH:
      return getMatchesFilteredData(state);
    case ModeType.RANKINGS:
      return getPlayersFilteredDataRanks(state);
  }
};
