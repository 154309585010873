import React, { Component } from 'react';
import { connect } from 'react-redux';
import DebounceInput from 'react-debounce-input';
import Highlighter from 'react-highlight-words';
import { browserHistory, Link } from 'react-router';
import _ from 'lodash';
import { List } from 'react-virtualized';

import { removeDiacritics } from '../../../helper/';
import { getMatches } from '../../../selectors/search';
import Image from '../../ui/image';
import Icon from '../../ui/svg-icon';

import { Footer } from './components/Footer';
import { database } from '../../../constants/database';

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: '',
      filteredData: [],
      visible: false,
      focus: false,
      mode: 'player',
      width: document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.innerWidth,
      height: document.documentElement.clientHeight
        ? document.documentElement.clientHeight - 84
        : window.innerHeight - 84,
    };

    this.rowRenderer = this.rowRenderer.bind(this);
    this.filter = this.filter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentWillMount() {
    let mode =
      this.props.mode || localStorage.getItem('overview-mode') || 'player';
    mode = mode === 'change' ? 'player' : mode;

    this.setState({ mode });

    if (!this.props.mode || this.props.mode === 'change') {
      browserHistory.replace({ pathname: `/overview/${mode}` });
    }
  }

  componentDidMount() {
    let data;
    const { searchPlayers, searchTeams, searchMatches } = this.props;
    const { searchString, mode } = this.state;

    if (mode === 'player') data = searchPlayers;
    if (mode === 'team') data = searchTeams;
    if (mode === 'match') data = searchMatches;

    if (_.size(data)) {
      this.setState({
        filteredData: this.filter({ data, searchString }),
      });
    }

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleChange(event) {
    let data;
    const { searchPlayers, searchTeams, searchMatches } = this.props;
    const { mode } = this.state;

    if (mode === 'player') data = searchPlayers;
    if (mode === 'team') data = searchTeams;
    if (mode === 'match') data = searchMatches;

    this.setState({
      searchString: event.target.value,
      filteredData: this.filter({ data, searchString: event.target.value }),
      visible: true,
    });
  }

  onFocus(event) {
    this.setState({
      visible: !!event.target.value.length,
      focus: true,
    });

    /*setTimeout(() => {
      this.searchInput.focus();
    }, 0);*/
  }

  handleResize() {
    this.setState({
      width: document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.innerWidth,
      height: document.documentElement.clientHeight
        ? document.documentElement.clientHeight - 84
        : window.innerHeight - 84,
    });
  }

  onBlur(event) {
    this.setState({
      visible: !!event.target.value.length,
      focus: false,
    });
  }

  componentWillReceiveProps(nextState) {
    let data;
    const { mode } = nextState;
    const { searchPlayers, searchTeams, searchMatches } = this.props;

    if (mode === 'player') data = searchPlayers;
    if (mode === 'team') data = searchTeams;
    if (mode === 'match') data = searchMatches;

    if (mode !== this.state.mode) {
      this.setState({
        filteredData: this.filter({
          data,
          searchString: this.state.searchString,
          mode,
        }),
        mode,
      });
    }
  }

  changeMode(mode) {
    const { overview } = this.props;

    if (overview) {
      browserHistory.replace(`/overview/${mode}`);
    } else {
      let data;
      const { searchPlayers, searchTeams, searchMatches } = this.props;

      if (mode === 'player') data = searchPlayers;
      if (mode === 'team') data = searchTeams;
      if (mode === 'match') data = searchMatches;

      if (mode !== this.state.mode) {
        this.setState({
          filteredData: this.filter({
            data,
            searchString: this.state.searchString,
            mode,
          }),
          mode,
        });
      }
    }
  }

  hide(event) {
    event.preventDefault();

    this.setState({
      visible: false,
      searchString: '',
    });
  }

  rowRenderer({ index, style }) {
    const { filteredData, searchString, mode } = this.state;
    const { teams } = this.props.app;

    switch (mode) {
      case 'player':
        const player = filteredData[index];

        /*const comb = player.data ? player.data.replace(/ /g, '').split(',') : [];
        const v = comb[0] ? comb[0].split(':') : [];
        const link = `${player.player_id}/${v[1]}/${v[0]}`;*/

        return (
          <Link
            to={`/details/player/${player.player_id}`}
            onClick={() => {
              this.searchInput.blur();
              this.setState({ visible: false });
            }}
            className="search-link"
            style={style}
            key={index}
          >
            <div className="row">
              <span className="col-6">
                <Highlighter
                  highlightClassName="word_highlight"
                  searchWords={searchString.split(/\s+/)}
                  textToHighlight={`${player.first_name} ${player.last_name}`}
                />
              </span>
              <span className="col-6">
                {player.team_id ? (
                  <Image
                    src={`${database.assets}teams/${player.team_id}.jpg`}
                    aspectRatio={0.769}
                  />
                ) : null}
                {teams[player.team_id] ? teams[player.team_id].team_name : null}
              </span>
            </div>
          </Link>
        );
      case 'team':
        const team = filteredData[index];
        const leagueAndSeason = team.data
          .replace(/ /g, '')
          .split(',')[0]
          .split(':');

        return (
          <Link
            to={`/details/team/${team.id}/${leagueAndSeason[1]}/${leagueAndSeason[0]}/summary`}
            onClick={() => {
              this.searchInput.blur();
              this.setState({ visible: false });
            }}
            className="search-link"
            style={style}
            key={index}
          >
            <div className="row">
              <span className="col-12">
                {team.id ? (
                  <Image
                    src={`${database.assets}teams/${team.id}.jpg`}
                    aspectRatio={0.769}
                  />
                ) : null}
                <Highlighter
                  highlightClassName="word_highlight"
                  searchWords={searchString.split(/\s+/)}
                  textToHighlight={team.team_name}
                />
              </span>
            </div>
          </Link>
        );
      case 'match':
        const match = filteredData[index];

        return (
          <Link
            to={`/details/match/${match.match_id}/${match.league_id}/${match.season_id}/summary`}
            onClick={() => {
              this.searchInput.blur();
              this.setState({ visible: false });
            }}
            className="search-link"
            style={style}
            key={index}
          >
            <div className="row">
              <span className="col-5 text-right">
                <Image
                  src={`${database.assets}teams/${match.team_id_home}.jpg`}
                  aspectRatio={0.769}
                />
                <Highlighter
                  highlightClassName="word_highlight"
                  searchWords={searchString.split(/\s+/)}
                  textToHighlight={teams[match.team_id_home].team_name}
                />
              </span>
              <span className="col-2 text-center">
                {match.homegoals} : {match.awaygoals}
              </span>
              <span className="col-5">
                <Image
                  src={`${database.assets}teams/${match.team_id_away}.jpg`}
                  aspectRatio={0.769}
                />
                <Highlighter
                  highlightClassName="word_highlight"
                  searchWords={searchString.split(/\s+/)}
                  textToHighlight={teams[match.team_id_away].team_name}
                />
              </span>
            </div>
          </Link>
        );
    }
  }

  filter({ data, searchString, mode }) {
    mode = mode || this.state.mode;

    const searchWords = searchString
      .split(' ')
      .filter(word => word && word.length)
      .map(word => removeDiacritics(word));

    switch (mode) {
      case 'player':
        return _.filter(data, (player, i) => {
          const finedWords = searchWords.filter(
            word => player.searchString.indexOf(word.toLowerCase()) >= 0,
          );
          return finedWords.length === searchWords.length;
        });
      case 'team':
        return _.filter(data, team => {
          const finedWords = searchWords.filter(
            word => team.searchString.indexOf(word.toLowerCase()) >= 0,
          );
          return finedWords.length === searchWords.length;
        });
      case 'match':
        return _.filter(data, match => {
          const finedWords = searchWords.filter(
            word => match.searchString.indexOf(word.toLowerCase()) >= 0,
          );
          return finedWords.length === searchWords.length;
        });
    }
  }

  render() {
    const { overview, rowHeight } = this.props;

    const { visible, filteredData, mode, searchString, focus, width, height } =
      this.state;

    return (
      <div>
        <div
          className={`search mobile ${overview ? 'search--overview' : ''} ${
            !visible ? 'search--hidden' : ''
          } ${!focus && !visible ? 'search--center' : ''}`}
        >
          <div className="search-logo">
            <Icon name="logo" />
            <div className="logo-text">Scoutpanel</div>
          </div>

          <div className="header-container">
            <div className="icon-search" />
            <input
              ref={input => {
                this.searchInput = input;
              }}
              value={searchString}
              /*debounceTimeout={500}*/
              onChange={this.handleChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              placeholder="Search..."
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
            />
            {visible ? (
              <div className="header__actionButtons">
                <div className="icon-close" onClick={this.hide.bind(this)} />
              </div>
            ) : null}
          </div>

          <div className="nav-container">
            <div className="nav row">
              <a
                onClick={this.changeMode.bind(this, 'player')}
                className={`col-4 callAction ${
                  mode === 'player' ? 'active' : ''
                }`}
              >
                Players
              </a>
              <a
                onClick={this.changeMode.bind(this, 'team')}
                className={`col-4 callAction ${
                  mode === 'team' ? 'active' : ''
                }`}
              >
                Teams
              </a>
              <a
                onClick={this.changeMode.bind(this, 'match')}
                className={`col-4 callAction ${
                  mode === 'match' ? 'active' : ''
                }`}
              >
                Matches
              </a>
            </div>
          </div>

          <div className="content-container" ref="content_container">
            <div className="container">
              <List
                width={width}
                height={height}
                rowCount={filteredData.length}
                rowHeight={rowHeight}
                rowRenderer={this.rowRenderer}
                noRowsRenderer={() => (
                  <div className="search-not-found">No results found</div>
                )}
              />
            </div>
          </div>
        </div>

        <Footer {...{ mode }} />
      </div>
    );
  }
}

Search.defaultProps = {
  rowHeight: 44,
};

export default connect(state => ({
  app: state.app,
  searchPlayers: state.app.playersListAll,
  searchTeams: state.app.teamsListAll,
  searchMatches: getMatches(state),
}))(Search);
