import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import './details.scss';

import Player from './player';
import Team from './team';
import Match from './match';
import NotFound from './not-found';

import { loadDataDetails, loadMatchDetails } from '../../actions/details';
import { loading, loadPlayerDataField } from '../../actions/app';
import { database } from '../../constants/database';

class Details extends Component {
  static propTypes = {
    router: PropTypes.object,
    params: PropTypes.object,
    location: PropTypes.object,
    details: PropTypes.object,
    app: PropTypes.object,
    loadDataDetails: PropTypes.func,
    loadMatchDetails: PropTypes.func,
    loading: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      height: document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.innerHeight,
    };

    this.renderMode = this.renderMode.bind(this);
    this.windowResize = this.windowResize.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    const { params, location } = this.props;

    this.loadData(params, location.query);
    window.addEventListener('resize', this.windowResize);
  }

  componentWillReceiveProps(nextProps) {
    const { params, location } = nextProps;

    if (
      !_.isEqual(_.omit(params, ['tab']), _.omit(this.props.params, ['tab'])) ||
      !_.isEqual(location.query, this.props.location.query)
    ) {
      this.loadData(params, location.query);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResize);
  }

  windowResize() {
    this.setState({
      height: document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.innerHeight,
    });
  }

  loadData(
    { mode, id, league = null, season = null, start = null, end = null },
    {
      formation = '0',
      position = '0', // Do not make a number
    },
  ) {
    const { loading } = this.props;
    const { teams } = this.props.app;

    let query = '';

    let seasonReformated = season ? [season] : [start, end];

    //Receive data for player here

    const loadDataWrapper = playerData => {
      if (
        (mode === 'player' && !playerData) ||
        (mode === 'team' && !_.get(teams, `${id}.data`, null))
      ) {
        loading(false);
        return;
      }

      let leaguesAndSeasons;

      switch (mode) {
        case 'player':
          leaguesAndSeasons = playerData ? playerData.split(',') : [];
          if (+position) query += `?position=${position}`;
          break;
        case 'team':
          leaguesAndSeasons = teams[id].data.split(',');
          if (+formation) query += `?formation=${formation}`;
          break;
        case 'match':
          break;
        default:
          break;
      }

      if ((mode === 'player' || mode === 'team') && !start && !end) {
        const leaguesAndSeasonsArray = leaguesAndSeasons.map(string => ({
          season_id: +string.split(':')[0],
          league_id: +string.split(':')[1],
        }));

        if (!+season && leaguesAndSeasonsArray.length) {
          browserHistory.replace(
            `/details/${mode}/${id}/${leaguesAndSeasonsArray[0].league_id}/${leaguesAndSeasonsArray[0].season_id}/summary${query}`,
          );
          return;
        } else {
          const s = _.find(leaguesAndSeasonsArray, { season_id: +season });
          const sl = _.find(leaguesAndSeasonsArray, {
            season_id: +season,
            league_id: +league,
          });
          const leagueAndSeason = leaguesAndSeasons[0].split(':');

          if (sl) {
            league = sl.league_id;
            seasonReformated = [sl.season_id];
          } else if (s) {
            league = s.league_id;
            seasonReformated = [s.season_id];
          } else {
            league = leagueAndSeason[1];
            seasonReformated = [leagueAndSeason[0]];
          }

          if (!sl) {
            browserHistory.replace(
              `/details/${mode}/${id}/${league}/${seasonReformated.join(
                '/',
              )}/summary${query}`,
            );

            return;
          }
        }
      }

      switch (mode) {
        case 'match':
          this.props
            .loadMatchDetails({
              id,
              league,
              season: seasonReformated,
              mode,
            })
            .then(() => {
              this.props.app.loaded && loading(false);
            })
            .catch(error => {
              console.error(error);
              this.setState({
                error: true,
              });
              loading(false);
              alert(`No data available ${database.dbDetail}${id}/${season}`);
            });
          break;
        default:
          this.props
            .loadDataDetails(
              id,
              league,
              seasonReformated,
              formation,
              position,
              mode,
            )
            .then(() => {
              if (this.props.app.loaded) {
                loading(false);
              }
            })
            .catch(error => {
              console.error(error);
              this.setState({
                error: true,
              });
              loading(false);
              alert(
                `No data available ${
                  database.dbDetail
                }${id}/${seasonReformated.join('/')}`,
              );
            });
      }
    };

    if (mode === 'team' && !league && !season) {
      let { standard_league } = teams[id];

      if (standard_league) {
        const [league, season] = standard_league.split(':');
        browserHistory.replace(`/details/team/${id}/${league}/${season}/`);
        return;
      }
    }

    if (mode === 'player') {
      loading(true);
      this.props.loadPlayerDataField(id).then(result => {
        const { data, standard_league } = _.get(
          result,
          'payload.data.data',
          '',
        );

        if (!league && !season) {
          if (standard_league) {
            const [league, season] = standard_league.split(':');
            browserHistory.replace(
              `/details/player/${id}/${league}/${season}/`,
            );
            return;
          }
        }

        loadDataWrapper(data);
      });
    } else {
      loading(true);
      loadDataWrapper();
    }
  }

  renderMode() {
    const { mode, id, tab } = this.props.params;
    const { details } = this.props;
    const { error } = this.state;
    const { playersMetaData, teams, loading } = this.props.app;
    const { information } = details;

    if (loading) return null;
    let data = information.data;

    if (
      (!_.size(data) && mode !== 'match') ||
      data === '[]' ||
      data === '' ||
      error
    ) {
      return <NotFound {...{ mode }} />;
    }

    switch (mode) {
      case 'player':
        return <Player {...{ id, tab, details }} />;
      case 'team':
        return <Team {...{ tab, details }} />;
      case 'match':
        return <Match {...{ tab, details }} />;
      default:
        return null;
    }
  }

  render() {
    const { mode } = this.props.params;
    const { height } = this.state;

    return (
      <div className={`details details--${mode}`}>
        <Scrollbars style={{ height }} autoHide>
          {this.renderMode()}
        </Scrollbars>
      </div>
    );
  }
}

export default connect(
  state => ({
    details: state.details,
    app: state.app,
  }),
  { loadDataDetails, loadMatchDetails, loading, loadPlayerDataField },
)(Details);
