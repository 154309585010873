import React, { useEffect } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import './filters.scss';

import { ModeType } from '../../../../types/Dict';
import { PlayerFilters } from './PlayerFilters';
import { TeamFilters } from './TeamFilters';
import { MatchFilters } from './MatchFilters';

interface Props {
  mode: ModeType;
}

export function OverviewFilters(props: Props) {
  const { mode } = props;

  switch (mode) {
    case ModeType.PLAYER:
    case ModeType.RANKINGS:
      return <PlayerFilters />;
    case ModeType.TEAM:
      return <TeamFilters />;
    case ModeType.MATCH:
      return <MatchFilters />;
  }

  return null;
}
