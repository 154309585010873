import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import _ from 'lodash';
import { ColorScale, PolarChart } from 'd3-react-touch';
import moment from 'moment';

import './summary.scss';

import Similar from './summary/similar';
import Position from './summary/position';
import NoData from '../layouts/no-data';
import NoAccess from '../no-access';

import lang from '../../../lang';
import Icon from '../../ui/svg-icon';
import Image from '../../ui/image';

import BarChartGroup from '../modules/bar-chart-group';
import CompetitionLevel from '../../competition-level';
import { DataItem, DataList, DataRank } from '../../ui/data-item';
import { BENCHMARK_COLOR, COLOR_SCALE } from '../../../constants';
import { database } from '../../../constants/database';

import {
  createBarChartsBenchmark,
  createPolarCharts,
  getGetOrdinal,
  getPlayerPerformanceFromAnalyse,
  getPlayerPerformanceText,
} from '../../../helper';

class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPopoverOpen: false,
      hoveredIndicator: null,
    };

    this.renderBarCharts = this.renderBarCharts.bind(this);
    this.renderProfile = this.renderProfile.bind(this);
    this.renderIndicators = this.renderIndicators.bind(this);
    this.renderSimilar = this.renderSimilar.bind(this);
    this.renderSkillText = this.renderSkillText.bind(this);
  }

  renderBarCharts() {
    const { playersMetaData } = this.props.app;
    const {
      information,
      mean,
      epiRating,
      current_season,
      current_league,
      current_position,
      minCondition,
      performance,
    } = this.props.details;

    const playerCanBeBenchmark = +performance.mins >= +minCondition;

    const bars_vals = ['season_rating', 'stability', 'defence', 'offence'];

    const analyze = getPlayerPerformanceFromAnalyse(
      epiRating,
      information,
      bars_vals,
      playerCanBeBenchmark,
    );

    const renderBenchmarkName = id =>
      playersMetaData[id] && typeof playersMetaData[id].last_name === 'string'
        ? playersMetaData[id].last_name.slice(0, 8)
        : '';
    const bars = createBarChartsBenchmark(
      {
        ...analyze,
        ...mean,
      },
      bars_vals,
      true,
      playerCanBeBenchmark,
    );
    const width = 120;
    const height = 50;
    const margin = {
      top: 13,
      right: 0,
      bottom: 1,
      left: 0,
    };
    let animationDelayCounter = 0;

    const renderGroup = group =>
      group.map((bar, b) => {
        animationDelayCounter++;

        return (
          <div className="col-6" key={b}>
            <BarChartGroup
              onMouseEnter={this.onSkillMouseEnter}
              onMouseLeave={this.onSkillMouseLeave}
              data={bar}
              width={width}
              height={height}
              margin={margin}
              indicator={bar.indicator}
              link={`/details/player/:id/${current_league}/${current_season.join(
                '/',
              )}/summary${
                current_position ? `?position=${current_position}` : ''
              }`}
              name={information.last_name}
              renderBenchmarkName={renderBenchmarkName}
              benchmarkName={bar.benchmark_name}
              animationDelay={1000 + animationDelayCounter * 200}
            />
          </div>
        );
      });

    return _.chunk(bars, 2).map((group, g) => (
      <div className="row first_el" key={g}>
        {renderGroup(group)}
      </div>
    ));
  }

  renderProfile() {
    const {
      performance_current,
      position,
      current_season,
      id,
      information,
      epiRating,
      current_position,
      isAccessGranted,
    } = this.props.details;
    const { leagues, teams, positions } = this.props.app;

    const url = performance_current.team_id
      ? `${database.assets}teams/${performance_current.team_id}.jpg`
      : '';
    const urlComp = performance_current.league_id
      ? `${database.assets}leagues/${performance_current.league_id}.png`
      : '';
    const season =
      current_season.length === 1 ? (
        <span>{current_season[0]}</span>
      ) : (
        current_season.map((seasonId, key) => (
          <span key={key}>
            {moment(seasonId, 'YYYY-MM-DD').format('D MMM ’YY')}{' '}
            {key === 0 && current_season.length > 1 ? ' –' : null}
          </span>
        ))
      );

    let query = '';
    if (+current_position) {
      query += `?position=${current_position}`;
    }

    return (
      <div className="content_group summary_profile">
        <div className="h4 background">
          <span>{lang.profile}</span>
        </div>

        <div className="container">
          <div className="row-new">
            <div className="col-new-6">
              <Position
                id={id}
                performance={_.find(epiRating, { player_id: +id }) || {}}
                position={position}
                positions={positions}
              />
            </div>

            <div className="col-new-6 ml-auto">
              <DataList className="row-new">
                <DataItem className="col-new-5" desc={lang.team_name}>
                  <Link
                    to={`/details/team/${performance_current.team_id}/summary`}
                    className="data-text"
                  >
                    {performance_current.team_id ? (
                      <Image src={url} aspectRatio={0.769} />
                    ) : (
                      ''
                    )}{' '}
                    {_.get(
                      teams,
                      `${performance_current.team_id}.team_name`,
                      '',
                    )}
                  </Link>
                </DataItem>
                <DataItem className="col-new-7" desc={lang.league}>
                  <span className="data-text">
                    <CompetitionLevel
                      league_id={performance_current.league_id}
                      showInfo
                    >
                      {urlComp ? (
                        <Image src={urlComp} aspectRatio={0.769} />
                      ) : (
                        ''
                      )}{' '}
                      {_.get(
                        leagues,
                        `${performance_current.league_id}.name`,
                        '',
                      )}
                    </CompetitionLevel>
                  </span>
                </DataItem>
                <DataItem
                  className="col-new-3 profile-dates"
                  text={season}
                  desc={lang[current_season.length > 1 ? 'dates' : 'season']}
                />
                <DataItem className="col-new-1_5" desc={lang.games}>
                  {isAccessGranted ? (
                    <Link
                      to={`/details/player/${id}/${
                        performance_current.league_id
                      }/${current_season.join('/')}/history${query}`}
                      className="data-text"
                    >
                      {performance_current.games}
                    </Link>
                  ) : (
                    performance_current.games
                  )}
                </DataItem>
                <DataItem
                  className="col-new-1_5"
                  text={performance_current.mins}
                  desc={lang.mins}
                />
                <DataItem
                  className="col-new-1_5"
                  text={performance_current.goals}
                  desc={lang.goals}
                />
                <DataItem
                  className="col-new-1_5"
                  text={performance_current.assists}
                  desc={lang.assists}
                />
                <DataItem
                  className="col-new-1_5"
                  text={performance_current.yellow}
                  desc={lang.yellow_cards}
                />
                <DataItem
                  className="col-new-1_5"
                  text={performance_current.red}
                  desc={lang.red_cards}
                />
                <DataItem className="col-new-12" desc="External links">
                  <div className="external_links">
                    <a
                      href={`https://sp7f.com/?url=${encodeURIComponent(
                        database.youtubeSearch + information.youtube_link,
                      )}`}
                      target="_blank"
                      className="details-youtube-action"
                    >
                      <Icon name="youtube" width="29px" height="22px" />
                    </a>
                    {information.tm_link ? (
                      <a
                        href={`https://sp7f.com/?url=${encodeURIComponent(
                          database.transfermarkt + information.tm_link,
                        )}`}
                        target="_blank"
                        className="details-transfermarkt-action"
                      />
                    ) : null}
                    <a
                      href={`https://sp7f.com/?url=${encodeURIComponent(
                        `${database.googleNews + information.first_name} ${
                          information.last_name
                        }${
                          information.team_name && information.team_name.length
                            ? ` ${information.team_name}`
                            : ''
                        }&source=lnms&tbm=nws`,
                      )}`}
                      className="details-google-news-action"
                      target="_blank"
                    >
                      <img src="/img/google_news.svg" />
                    </a>
                  </div>
                </DataItem>
              </DataList>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSkillText(type, indicator) {
    const betterReadable = d =>
      d.replace('Off.', 'Offence').replace('Def.', 'Defence');

    return (
      <span
        className={`data_point ${type} indicator_${indicator}`}
        data-tooltip-id="Indicator"
        data-tooltip-content={indicator}
      >
        {betterReadable(lang[indicator])}
      </span>
    );
  }

  renderIndicators() {
    const { radar } = this.props;
    const { leagues, countries } = this.props.app;
    const {
      performance_current,
      position,
      mean,
      information,
      epiRating,
      performance,
      minCondition,
      isAccessGranted,
    } = this.props.details;

    const playerCanBeBenchmark = +performance.mins >= +minCondition;

    const goalkeeper = position[0] && +position[0].position_id === 10;

    let radars_vals_against = [
      'tackle_defence',
      'tackle_offence',
      'intercept_defence',
      'intercept_offence',
      'blocked',
      'save',
      'aerial_defence',
      'aerial_offence',
    ];
    if (goalkeeper)
      radars_vals_against = [
        'intercept_defence',
        'save',
        'claim',
        'keeper_throw',
        'pass_long',
        'pass_short',
        'reception',
        'delivery',
      ];
    const radars_against = createPolarCharts(
      {
        ...getPlayerPerformanceFromAnalyse(
          epiRating,
          information,
          radars_vals_against,
        ),
        ...mean,
      },
      radars_vals_against,
    );

    const radars_vals_on = [
      'take_on',
      'crossing',
      'pass_short',
      'pass_long',
      'reception',
      'delivery',
      'scoring',
      'scoring_effort',
    ];
    const radars_onball = createPolarCharts(
      {
        ...getPlayerPerformanceFromAnalyse(
          epiRating,
          information,
          radars_vals_on,
        ),
        ...mean,
      },
      radars_vals_on,
    );

    let indicatorsAll = [].concat.apply(
      [],
      [['season_rating'], radars_vals_against, radars_vals_on],
    );
    const playerData = getPlayerPerformanceText(
      epiRating,
      information,
      indicatorsAll,
      mean,
      performance_current,
    );

    const league_id = _.get(
      this.props.details,
      `performance_current.league_id`,
      null,
    );
    const league = league_id !== null && leagues[league_id];
    const country_id = _.get(league, 'country_id', null);
    const country = country_id !== null && countries[country_id];

    return !isAccessGranted ? (
      <div className="content_group summary_data no-access">
        <div className="h4 background">
          <Icon name="ranking-winner" width="33px" height="28px" />
          <span>{lang.ownstats}</span>
        </div>

        <div className="summary-text">
          <div className="row">
            <div className="col-10">
              {information.last_name && playerData.season_rating ? (
                <div>
                  <span>
                    {information.last_name ? information.last_name : 'N/D'}
                  </span>
                  &nbsp;
                  <span>{lang.epi_text_summary_part_1}</span>&nbsp;
                  <span className="data_point">
                    <Icon name="icon-lock" />
                  </span>
                  &nbsp;
                  <span>{lang.epi_text_summary_part_2}</span>&nbsp;
                  <span className="data_point">
                    <Icon name="icon-lock" />
                  </span>
                  &nbsp;
                  <span>{lang.epi_text_summary_part_3}</span>&nbsp;
                  <span className="data_point">
                    <Icon name="icon-lock" />
                  </span>
                  &nbsp;
                  <span>{lang.epi_text_summary_part_4}</span>.
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <NoAccess league={league} country={country} />
      </div>
    ) : (
      <div className="content_group summary_data">
        <div className="h4 background">
          <Icon name="ranking-winner" width="33px" height="28px" />
          <span>{lang.ownstats}</span>
        </div>

        <div className="summary-text">
          <div className="row">
            <div className="col-10">
              {information.last_name && playerData.season_rating ? (
                <div>
                  <span>
                    {information.last_name ? information.last_name : 'N/D'}
                  </span>
                  &nbsp;
                  <span>{lang.epi_text_summary_part_1}</span>&nbsp;
                  <span className="data_point">
                    {playerData.season_rating
                      ? playerData.season_rating
                      : 'N/D'}
                  </span>
                  &nbsp;
                  <span>{lang.epi_text_summary_part_2}</span>&nbsp;
                  <span className="data_point">
                    {playerData.season_rating_rank
                      ? playerData.season_rating_rank +
                        getGetOrdinal(+playerData.season_rating_rank)
                      : 'N/D'}
                  </span>
                  &nbsp;
                  <span>{lang.epi_text_summary_part_3}</span>&nbsp;
                  <span className="data_point">
                    {+performance.mins ? +performance.mins : 'N/D'}
                  </span>
                  &nbsp;
                  <span>{lang.epi_text_summary_part_4}</span>.
                </div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              {playerData.strengths && playerData.strengths.length > 0 ? (
                <div>
                  <span>{`${lang.epi_text_swot_part_1} `}</span>
                  <Icon
                    name="plus-circle"
                    width="13px"
                    height="13px"
                    className="strengths"
                  />
                  <span className="strengths">{`${
                    playerData.strengths.length > 1
                      ? lang.epi_text_swot_part_2_1
                      : lang.epi_text_swot_part_2_2
                  } `}</span>
                  <span>{`${
                    playerData.strengths.length > 1
                      ? lang.epi_text_swot_part_3
                      : lang.epi_text_swot_part_4
                  } `}</span>
                  {playerData.strengths.length > 0
                    ? this.renderSkillText(
                        'strengths',
                        playerData.strengths[0].indicator,
                      )
                    : null}
                  {playerData.strengths.length > 2
                    ? ', '
                    : playerData.strengths.length === 2
                    ? ` ${lang.epi_text_swot_part_5} `
                    : null}
                  {playerData.strengths.length > 1
                    ? this.renderSkillText(
                        'strengths',
                        playerData.strengths[1].indicator,
                      )
                    : null}
                  <span>
                    {playerData.strengths.length > 2
                      ? ` ${lang.epi_text_swot_part_5} `
                      : null}
                  </span>
                  {playerData.strengths.length > 2
                    ? this.renderSkillText(
                        'strengths',
                        playerData.strengths[2].indicator,
                      )
                    : null}
                  .
                </div>
              ) : null}

              {playerData.weaknesses && playerData.weaknesses.length > 0 ? (
                <div>
                  <span>{`${lang.epi_text_swot_part_1} `}</span>
                  <Icon
                    name="minus-circle"
                    width="13px"
                    height="13px"
                    className="weaknesses"
                  />
                  <span className="weaknesses">{`${
                    playerData.weaknesses.length > 1
                      ? lang.epi_text_swot_part_6_1
                      : lang.epi_text_swot_part_6_2
                  } `}</span>
                  <span>{`${
                    playerData.weaknesses.length > 1
                      ? lang.epi_text_swot_part_3
                      : lang.epi_text_swot_part_4
                  } `}</span>
                  {playerData.weaknesses.length > 0
                    ? this.renderSkillText(
                        'weaknesses',
                        playerData.weaknesses[0].indicator,
                      )
                    : null}
                  {playerData.weaknesses.length > 2
                    ? ', '
                    : playerData.weaknesses.length === 2
                    ? ` ${lang.epi_text_swot_part_5} `
                    : null}
                  {playerData.weaknesses.length > 1
                    ? this.renderSkillText(
                        'weaknesses',
                        playerData.weaknesses[1].indicator,
                      )
                    : null}
                  <span>
                    {playerData.weaknesses.length > 2
                      ? ` ${lang.epi_text_swot_part_5} `
                      : null}
                  </span>
                  {playerData.weaknesses.length > 2
                    ? this.renderSkillText(
                        'weaknesses',
                        playerData.weaknesses[2].indicator,
                      )
                    : null}
                  .
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-4 charts_bar">
            <h5>
              <div className="icon-rating" />
              {lang.ratingoverall}
            </h5>
            {this.renderBarCharts()}

            <div className="row">
              <DataRank
                className="col-6"
                title={lang.player_league}
                rank={performance_current.rank_league}
                desc={`of ${
                  performance_current.rank_league_max || 'N/D'
                } players`}
              />
              <DataRank
                className="col-6"
                title={lang.player_team}
                rank={performance_current.rank_team}
                desc={`of ${
                  performance_current.rank_team_max || 'N/D'
                } players`}
              />
            </div>
          </div>

          <div className="col-4 charts_polar charts_polar_1">
            <h5>
              <div className="icon-against" />
              {lang.againstball}
            </h5>
            <div className="chart_group radar radar_1">
              <PolarChart
                {...radar}
                animation
                animationDelay={1000}
                classNames={radars_against.classes}
                tooltips={radars_against.tooltips}
                data={radars_against.items}
                xDomain={radars_against.xDomain}
                yDomain={radars_against.yDomain}
                colors={COLOR_SCALE}
                interpolationType="linear-closed"
              />
            </div>
          </div>
          {!goalkeeper ? (
            <div className="col-4 charts_polar">
              <h5>
                <div className="icon-on" />
                {lang.onball}
              </h5>
              <div
                className={`chart_group radar radar_2 ${
                  goalkeeper ? 'hide' : ''
                }`}
              >
                <PolarChart
                  {...radar}
                  animation
                  animationDelay={1400}
                  classNames={radars_onball.classes}
                  tooltips={radars_onball.tooltips}
                  data={radars_onball.items}
                  xDomain={radars_onball.xDomain}
                  yDomain={radars_onball.yDomain}
                  colors={COLOR_SCALE}
                  interpolationType="linear-closed"
                />
              </div>
            </div>
          ) : null}
        </div>

        <div className="row">
          <ColorScale
            domain={radars_against.yDomain}
            colors={COLOR_SCALE}
            benchmarkColor={BENCHMARK_COLOR}
          />
          {playerCanBeBenchmark ? null : (
            <div className="theoretical-ranks">{`* Theoretical rank, because the player has played less than ${minCondition} minutes.`}</div>
          )}
        </div>
      </div>
    );
  }

  renderSimilar() {
    const { similar, current_season, current_league, id } = this.props.details;

    const similar_immediate = !_.isEmpty(similar)
      ? similar.filter(datum => +datum.type === 2)
      : [];

    return (
      <div className="content_group summary_similar">
        <div className="row">
          <div className="h4 background">
            <span>{lang.similarplayers}</span>
            <span className="description">{lang.values30games}</span>
          </div>
        </div>
        <Similar
          id={id}
          compare={id ? `${id}-${current_league}-${current_season}` : null}
          similar={similar_immediate}
          assets={database.assets}
        />

        <div className="chart-legend">
          <div className="chart-legend-list">
            <div className="chart-legend-list-row">
              <div className="chart-legend-list-item">
                <div className="legend-label-col">
                  {lang.similarity_index_short} = {lang.similarity_index}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { epiRating, isAccessGranted } = this.props.details;

    return (
      <div className="summary">
        {this.renderProfile()}
        {epiRating.length || !isAccessGranted ? (
          this.renderIndicators()
        ) : (
          <NoData />
        )}
        {this.renderSimilar()}
      </div>
    );
  }
}

Summary.defaultProps = {
  radar: {
    width: 345,
    height: 260,
    margin: {
      top: 20,
      right: 14,
      bottom: 12,
      left: 16,
    },
  },
};

export default connect(state => ({
  details: state.details,
  app: state.app,
}))(Summary);
