import React, { Component } from 'react';
import PropTypes from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { connect } from 'react-redux';

import Slider from '../slider';

import './date-range-dropdown.scss';

const moment = extendMoment(Moment);

class DateRangeDropDown extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.array,
    range: PropTypes.object,
    interval: PropTypes.string,
    dateSlider: PropTypes.bool,
    shortcuts: PropTypes.array,
    optionsSliceCount: PropTypes.number,
    optionHeight: PropTypes.number,
  };

  static defaultProps = {
    range: {
      min: 0,
      max: 24,
    },
    interval: 'month',
    optionsSliceCount: 2,
    optionHeight: 38,
  };

  constructor(props) {
    super(props);

    const { min, max } = props.range;

    this.state = {
      isOpen: false,
      sliderValue: [min, max],
      showAll: false,
    };

    this.renderMenu = this.renderMenu.bind(this);
    this.renderControl = this.renderControl.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.renderRange = this.renderRange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.initValues = this.initValues.bind(this);
    this.renderShortcuts = this.renderShortcuts.bind(this);
  }

  componentDidMount() {
    this.initValues();
  }

  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;

    if (value && this.props.value && value[0] !== this.props.value[0]) {
      this.initValues(nextProps);
    }
  }

  initValues(nextProps) {
    const props = nextProps || this.props;

    const { value, interval, range } = props;
    const { min, max } = range;

    if (value.length === 2) {
      const startDate = moment().subtract(max, interval);

      const monthsRange = Array.from(
        moment.rangeFromInterval(interval, max, startDate).by(interval),
      );

      const valuesStart = monthsRange.map(m =>
        m.startOf('month').format('YYYY-MM-DD'),
      );
      const valuesEnd = monthsRange.map(m =>
        m.endOf('month').format('YYYY-MM-DD'),
      );

      const startMonthIndex = _.indexOf(valuesStart, value[0]);
      const endMonthIndex = _.indexOf(valuesEnd, value[1]);

      if (
        startMonthIndex &&
        endMonthIndex &&
        startMonthIndex > -1 &&
        endMonthIndex > -1
      ) {
        this.setState({
          sliderValue: [startMonthIndex, endMonthIndex],
        });
      }
    } else {
      this.setState({
        sliderValue: [min, max],
      });
    }
  }

  handleClickOutside(event) {
    this.toggle(event, false);
  }

  toggle(event, isOpen) {
    this.setState({
      isOpen: typeof isOpen === 'boolean' ? isOpen : !this.state.isOpen,
    });
  }

  renderControl() {
    const { value, options, shortcuts } = this.props;

    const getValueText = ([start, end]) => {
      if (end) {
        for (let i = 0, l = shortcuts.length; i < l; i++) {
          if (
            moment().subtract(shortcuts[i].id, 'month').format('YYYY-MM-DD') ===
            start
          ) {
            return shortcuts[i].name;
          }
        }

        return [
          moment(start, 'YYYY-MM-DD').format('MMM ’YY'),
          moment(end, 'YYYY-MM-DD').format('MMM ’YY'),
        ].join(' – ');
      }

      return _.chain(options)
        .find(o => +o.value === +start)
        .get('label', 'Choose season')
        .value();
    };

    return (
      <div className="dropdown-control" onClick={this.toggle}>
        {getValueText(value)}
      </div>
    );
  }

  renderShortcuts() {
    const { shortcuts, onChange, value } = this.props;

    const endDate = moment().format('YYYY-MM-DD');

    return shortcuts.map(shortcut => {
      if (shortcut.id === 0) return null;

      const startDate = moment()
        .subtract(shortcut.id, 'month')
        .format('YYYY-MM-DD');

      return (
        <div
          className={`option ${
            startDate === value[0] && endDate === value[1] ? 'active' : ''
          }`}
          key={shortcut.id}
          onClick={() => {
            this.setState({ isOpen: false });
            onChange([startDate, endDate]);
          }}
        >
          {shortcut.name}
        </div>
      );
    });
  }

  renderOptions() {
    const { options, onChange, value } = this.props;

    return _.orderBy(options, 'value', 'desc').map(option => (
      <div
        className={`option ${value[0] === option.value ? 'active' : ''}`}
        key={option.value}
        onClick={() => {
          this.setState({ isOpen: false });
          onChange([option.value]);
        }}
      >
        {option.label}
      </div>
    ));
  }

  renderRange() {
    const { sliderValue } = this.state;
    const { onChange, range, interval } = this.props;
    const { min, max } = range;

    const startDate = moment().subtract(max, interval);

    const monthsRange = Array.from(
      moment.rangeFromInterval(interval, max, startDate).by(interval),
    );
    const labels = monthsRange.map(m => m.format('MMM ’YY'));

    return (
      <Slider
        defaultValue={[sliderValue[0]]}
        value={[sliderValue[0]]}
        min={min}
        max={max}
        step={1}
        minDistance={1}
        renderValue={(value, index) =>
          index === 1 ? labels[sliderValue[1]] : labels[value]
        }
        onChanged={value => {
          this.setState({ isOpen: false });
          onChange([
            monthsRange[value[0]].startOf('month').format('YYYY-MM-DD'),
            monthsRange[sliderValue[1]].endOf('month').format('YYYY-MM-DD'),
          ]);
        }}
        onChange={value => {
          this.setState({
            sliderValue: [value[0], sliderValue[1]],
          });
        }}
      />
    );
  }

  renderMenu() {
    const { dateSlider, optionsSliceCount, options, value, optionHeight } =
      this.props;
    const { isOpen, showAll } = this.state;

    const activeIndex =
      _.findIndex(_.orderBy(options, 'value', 'desc'), { value: value[0] }) + 1;

    const slice =
      options.length > optionsSliceCount &&
      !showAll &&
      activeIndex <= optionsSliceCount;

    if (!isOpen) return null;

    if (!dateSlider) {
      return (
        <div className="dropdown-menu">
          <div className="dropdown-label">Seasons</div>
          <div
            className="menu-options"
            style={{
              maxHeight:
                (slice ? optionsSliceCount : options.length) * optionHeight,
            }}
          >
            {this.renderOptions()}
          </div>
          <div
            className={`show-all ${slice ? 'visible' : ''}`}
            onClick={() => {
              this.setState({ showAll: true });
            }}
          >
            <span>+ Show all</span>
          </div>
        </div>
      );
    }

    return (
      <div className="dropdown-menu">
        <div className="dropdown-label">Seasons</div>
        <div
          className="menu-options"
          style={{
            maxHeight:
              (slice ? optionsSliceCount : options.length) * optionHeight,
          }}
        >
          {this.renderOptions()}
        </div>
        <div
          className={`show-all ${slice ? 'visible' : ''}`}
          onClick={() => {
            this.setState({ showAll: true });
          }}
        >
          <span>+ Show all</span>
        </div>

        <div className="dropdown-label">Custom range</div>
        <div className="menu-range slider-on-white">
          <div className="menu-options">{this.renderShortcuts()}</div>
          {this.renderRange()}
        </div>
      </div>
    );
  }

  render() {
    const { isOpen } = this.state;

    return (
      <div className={`date-range-dropdown ${isOpen ? 'is-open' : ''}`}>
        {this.renderControl()}
        {this.renderMenu()}
      </div>
    );
  }
}

export default connect(state => ({
  shortcuts: state.app.seasons.filter(({ id }) => id < 1000),
}))(enhanceWithClickOutside(DateRangeDropDown));
