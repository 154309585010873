import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PolarChart } from 'd3-react-touch';
import _ from 'lodash';

import NoAccess from '../../../../details/no-access';

import './card-charts.scss';

import { createPolarCharts } from '../../../../../helper';
import { COLOR_SCALE } from '../../../../../constants';

export default class CardChart extends Component {
  static propTypes = {
    num: PropTypes.number,
    margin: PropTypes.object,
    item: PropTypes.object,
  };

  static defaultProps = {
    margin: {
      top: 14,
      right: 5,
      bottom: 10,
      left: 5,
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      width: 384,
    };

    this.updateChartSize = this.updateChartSize.bind(this);
    this.renderChart = this.renderChart.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateChartSize);
    this.updateChartSize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateChartSize);
  }

  updateChartSize() {
    if (!this.container) return;

    const { margin } = this.props;

    this.setState({
      width: this.container.offsetWidth - margin.left - margin.right,
    });
  }

  renderChart(axes) {
    if (!axes.length) return null;

    const { margin, item } = this.props;
    const { width } = this.state;

    const height = width / 1.5;

    // const playerPerformance = getPlayerPerformanceFromAnalyse()
    const chartData = createPolarCharts(item.data, axes);

    return (
      <PolarChart
        animation
        animationDelay={1000}
        height={height}
        width={width}
        margin={margin}
        classNames={chartData.classes}
        data={chartData.items}
        tooltips={chartData.tooltips}
        xDomain={chartData.xDomain}
        yDomain={chartData.yDomain}
        colors={COLOR_SCALE}
        interpolationType="linear-closed"
      />
    );
  }

  render() {
    const { num, item, isAccessGranted, league, country } = this.props;

    const goalkeeper = _.get(item, 'data.position_id_first', null) === 10;

    let againstAxes = [];
    let onBallAxes = [];

    if (goalkeeper) {
      againstAxes = [
        'intercept_defence',
        'save',
        'claim',
        'keeper_throw',
        'pass_long',
        'pass_short',
        'reception',
        'delivery',
      ];
    } else {
      againstAxes = [
        'tackle_defence',
        'tackle_offence',
        'intercept_defence',
        'intercept_offence',
        'blocked',
        'save',
        'aerial_defence',
        'aerial_offence',
      ];
      onBallAxes = [
        'take_on',
        'crossing',
        'pass_short',
        'pass_long',
        'reception',
        'delivery',
        'scoring',
        'scoring_effort',
      ];
    }

    return (
      <div
        className={`card-charts card-charts--index-${num}`}
        ref={container => {
          this.container = container;
        }}
      >
        {isAccessGranted === false ? (
          <NoAccess league={league} country={country} />
        ) : null}
        {isAccessGranted === true ? this.renderChart(againstAxes) : null}
        {isAccessGranted === true ? this.renderChart(onBallAxes) : null}
      </div>
    );
  }
}
