import './progress.scss';

import React, { Component } from 'react';

import DataItem from '../custom';

export default class Progress extends Component {
  render() {
    const { progress, desc, text, className, children } = this.props;

    if (!progress) return null;

    return (
      <DataItem
        desc={desc}
        text={text}
        className={`data-progress ${className || ''}`}
      >
        {children}
        <div className="progress-bar">
          <span className="progress-value" style={{ width: `${progress}%` }} />
        </div>
      </DataItem>
    );
  }
}
