import React from 'react';
import _ from 'lodash';
import Icon from '../ui/svg-icon';

// Hey, I have interest on league xxx in country yyy.
export default props => {
  const { league, country } = props;

  const league_name = _.get(league, 'name', '');
  const country_name = _.get(country, 'country', '');

  let text = '';
  if (league_name || country_name) {
    text = `Hey, I have interest on league ${league_name} in country ${
      country_name || country.id
    }.`;
  }

  const subject = encodeURI('Blocked profile');
  text = encodeURI(text);
  return (
    <div className="paywall">
      <div className="paywall-content">
        <h2>Read more now</h2>
        <p>
          Get access to this and more content of this competition. <br />
          Contact our team to see how we can help.
        </p>
        <a
          href={`mailto:support@scoutpanel.com?subject=${subject}&body=${text}`}
        >
          <Icon name="icon-unlock" />
          Get in touch to unlock
        </a>
      </div>
    </div>
  );
};
