import React, { Component } from 'react';
import { connect } from 'react-redux';
import Player from './player';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';

function calculateHeight(el) {
  return window.innerHeight - el.getBoundingClientRect().top - 20;
}

class PlayersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      el: null,
      sort: {
        key: 'position_name',
        dir: 'asc',
      },
    };
    this.onResize = this.onResize.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.renderTriangle = this.renderTriangle.bind(this);
  }

  componentDidMount() {
    const el = document.querySelector('.shadow-team__players-scroll-container');
    const height = calculateHeight(el);
    this.setState({ height, el });
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    const height = calculateHeight(this.state.el);
    this.setState({ height });
  }

  changeSort(nextKey) {
    const { dir, key } = this.state.sort;
    let nextDir = 'desc';

    if (key === nextKey) {
      nextDir = dir === 'asc' ? 'desc' : 'asc';
    }

    this.setState({
      sort: {
        key: nextKey,
        dir: nextDir,
      },
    });
  }

  renderPlayerRow(player) {
    return (
      <Player
        key={player.player_id}
        player={player}
        setPlayer={this.props.setPlayer}
        team={this.props.team}
      />
    );
  }

  renderTriangle(prop) {
    const { key, dir } = this.state.sort;

    if (key === prop) {
      return <span className={`tri ${dir}`} />;
    }

    return null;
  }

  render() {
    const { players, userId } = this.props;
    const { height } = this.state;

    if (!players.length)
      return <div className="shadow-team__players-scroll-container"></div>;

    let playersList = players.map(p => {
      return {
        ...p,
        season_rating: +p.season_rating,
        offence: +p.offence || 0,
        defence: +p.defence || 0,
        season_mins: +p.season_mins || 0,
      };
    });

    if (userId) {
      playersList = players.filter(p => +p.user_id === +userId);
    }

    const { key, dir } = this.state.sort;
    playersList = _.orderBy(playersList, key, dir);

    return (
      <div className="shadow-team__players-scroll-container">
        <Scrollbars style={{ height }} autoHide>
          <table className="shadow-team__players-table">
            <colgroup>
              <col className="col__rating" />
              <col className="col__img" />
              <col className="col__player" />
              <col className="col__pos" />
              <col className="col__val" />
              <col className="col__val" />
              <col className="col__mins" />
              <col className="col__drag" />
            </colgroup>
            <thead>
              <tr>
                <th
                  onClick={this.changeSort.bind(this, 'season_rating')}
                  className={`${
                    key === 'season_rating' ? `active ${dir}` : ''
                  }`}
                >
                  Rat {this.renderTriangle('season_rating')}
                </th>
                <th
                  onClick={this.changeSort.bind(this, 'last_name')}
                  colSpan="2"
                  className={`${key === 'last_name' ? `active ${dir}` : ''}`}
                >
                  Player {this.renderTriangle('last_name')}
                </th>
                <th
                  onClick={this.changeSort.bind(this, 'position_name')}
                  className={`${
                    key === 'position_name' ? `active ${dir}` : ''
                  }`}
                >
                  Pos {this.renderTriangle('position_name')}
                </th>
                <th
                  onClick={this.changeSort.bind(this, 'defence')}
                  className={`${key === 'defence' ? `active ${dir}` : ''}`}
                >
                  Def {this.renderTriangle('defence')}
                </th>
                <th
                  onClick={this.changeSort.bind(this, 'offence')}
                  className={`${key === 'offence' ? `active ${dir}` : ''}`}
                >
                  Off {this.renderTriangle('offence')}
                </th>
                <th
                  onClick={this.changeSort.bind(this, 'season_mins')}
                  className={`${key === 'season_mins' ? `active ${dir}` : ''}`}
                >
                  Mins {this.renderTriangle('season_mins')}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {playersList.map((p, i) => this.renderPlayerRow(p, i))}
            </tbody>
          </table>
        </Scrollbars>
      </div>
    );
  }
}

export default connect(state => ({
  app: state.app,
}))(PlayersList);
