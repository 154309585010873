import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';

import { loading } from '../../actions/app';
import { resetFilters, lassoSubSelection } from '../../actions/overview';
import lang from '../../lang';

import { OverviewFilters } from './layouts/filters';
import { ListType } from '../../reducers/overview/types';
import { ModeType } from '../../types/Dict';

class Overview extends Component {
  constructor(props) {
    super(props);

    this._resetFilters = this._resetFilters.bind(this);
  }

  _resetFilters() {
    const {
      mode,
      resetFilters,
      setOverviewState,
      lassoSubSelection,
      overview,
    } = this.props;

    if (overview[mode].scatterplot.lassoSelection) {
      setOverviewState({ lasso: null });
      lassoSubSelection(null);
    }

    resetFilters(mode);
  }

  render() {
    const { overview, filteredData, clientWidth, clientHeight, mode } =
      this.props;

    const { seasons, teams, positions } = this.props.app;
    const { lassoSelection } = this.props.overview[mode].scatterplot;
    const { filterComponents } = overview[mode];
    const listLoaded =
      filterComponents.list[ListType.LEAGUE_ID].data.length > 0 &&
      seasons.length > 0 &&
      _.size(teams) > 0 &&
      _.size(positions);

    let filterContent = '';

    if (listLoaded) {
      filterContent = <OverviewFilters mode={mode} />;
    }

    return (
      <div style={{ width: parseInt(clientWidth * 0.2425, 10) }}>
        <div className="filterView_header">{lang[`${mode}_plural`]}</div>
        <div className="filterView_subheader">
          {mode !== ModeType.MATCH ? (
            <div className="total">
              <span className="total_number">
                {lassoSelection
                  ? lassoSelection.length
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  : filteredData.length
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </span>
              <span className="total_label">{lang[`${mode}_plural`]}</span>
            </div>
          ) : (
            <div className="total">&nbsp;</div>
          )}
          <div className="reset btn" onClick={this._resetFilters}>
            {lang.resetfilter}
          </div>
        </div>
        <div className="filterView_filter">
          <Scrollbars style={{ height: `${clientHeight - 102}px` }} autoHide>
            {filterContent}
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    overview: state.overview,
    app: state.app,
  }),
  {
    loading,
    resetFilters,
    lassoSubSelection,
  },
)(Overview);
