import { connect } from 'react-redux';
import React, { Component } from 'react';
import enchanceClickOutside from 'react-click-outside';
import Modal from 'react-modal';
import Creatable from 'react-select/lib/Creatable';
import TextareaAutosize from 'react-autosize-textarea';
import lang from '../../../../../lang';

import { addToFavorite, loading } from '../../../../../actions/app';
import {
  createNote,
  createNoteTag,
  deleteNote,
  fetchNotes,
  fetchNoteTags,
  selectNote,
  updateNote,
  updateNotesDictionaries,
} from '../../../../../actions/notes';
import Icon from '../../../../ui/svg-icon';

import './notes-mobile.scss';
import moment from 'moment/moment';
import _ from 'lodash';

class NotesMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selectedNote: null,
    };
  }

  componentDidMount() {
    const { notes } = this.props.notes;

    if (!notes.length) {
      this.props.loading(true);
      Promise.all([this.props.fetchNotes(), this.props.fetchNoteTags()]).then(
        () => {
          this.props.loading(false);
        },
      );
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.notes.selectedNote !== newProps.notes.selectedNote) {
      this.setState({ selectedNote: newProps.notes.selectedNote });
    }

    if (this.props.notes.tags !== newProps.notes.tags) {
      this.prepareTagsDictionaries(newProps);
    }
  }

  prepareTagsDictionaries(props) {
    const { tags } = props.notes;
    const tagsByKey = _.groupBy(tags, 'id');
    const tagsOptions = tags.map(t => ({ value: t.id, label: t.tag }));

    this.props.updateNotesDictionaries({
      tagsByKey,
      tagsOptions,
    });
  }

  handleClickOutside(e) {
    this.setState({ isOpen: false });
  }

  createNote() {
    const { user, player_id, favorites } = this.props;

    this.props.createNote(user, player_id);
  }

  render() {
    const { player_id } = this.props;
    const { notes } = this.props.notes;
    const notesList = notes.filter(n => +n.player_id === +player_id) || [];
    const { isOpen, selectedNote } = this.state;

    return (
      <div
        className="notes-mobile"
        onClick={() => {
          this.setState({ isOpen: !this.state.isOpen });
        }}
      >
        <Icon name="notes" />
        {isOpen ? (
          <div className="notes-mobile__wrapper">
            {this.renderNotesList(notesList)}
            <button
              className="notes-mobile__create"
              onClick={() => {
                this.createNote();
              }}
            >
              + Add a new note
            </button>
          </div>
        ) : null}
        {selectedNote ? this.renderForm() : null}
      </div>
    );
  }

  renderNotesList(list = []) {
    if (!list.length) return null;

    return (
      <ul className="notes-mobile__list">
        {list.map(note => {
          const createDate = note.updatedAt || note.createdAt;
          const date = createDate
            ? moment(createDate).format('YYYY-MM-DD - HH:mm')
            : '';
          return (
            <li
              className="notes-mobile__list-item"
              key={note.id}
              onClick={() => {
                this.props.selectNote(note);
              }}
            >
              <p className="title">{note.title}</p>
              <p className="author">
                {note.user.lastName}, {date}
              </p>
            </li>
          );
        })}
      </ul>
    );
  }

  renderForm() {
    const { selectedNote } = this.state;
    const { tagsOptions } = this.props.notes.dictionaries;

    return (
      <Modal
        over
        isOpen={!!selectedNote}
        style={{
          overlay: {
            zIndex: 2147483648,
          },
          content: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'fixed',
          },
        }}
      >
        <div className="notes-mobile__form-wrapper">
          <div className="notes-mobile__form">
            <TextareaAutosize
              className="notes-mobile__form-title"
              placeholder={lang.note_title_placeholder}
              rows={1}
              maxRows={3}
              value={selectedNote.title || ''}
              onChange={e => {
                this.setState({
                  selectedNote: { ...selectedNote, title: e.target.value },
                });
              }}
            />
            <TextareaAutosize
              className="notes-mobile__form-text"
              placeholder={lang.note_text_placeholder}
              value={selectedNote.note || ''}
              rows={6}
              onChange={e => {
                this.setState({
                  selectedNote: { ...selectedNote, note: e.target.value },
                });
              }}
            />

            <div className="notes-mobile__tags">
              <Creatable
                value={selectedNote.tags.map(t => ({
                  label: t.tag,
                  value: t.id,
                }))}
                className="note-tag-select"
                placeholder="Add a tag"
                promptTextCreator={value => `Create tag #${value}`}
                onNewOptionClick={value => {
                  this.props
                    .createNoteTag({ tag: value.label })
                    .then(action => {
                      const { data } = action.payload;
                      const v = [...selectedNote.tags, { ...data }];
                      this.props.updateNote({ ...selectedNote, tags: v });
                    });
                }}
                options={tagsOptions}
                multi
                searchable
                onChange={val => {
                  const v = [...val];
                  this.props.updateNote({
                    ...selectedNote,
                    tags: v.map(t => ({ id: t.value, tag: t.label })),
                  });
                }}
              />
            </div>

            <div className="notes-mobile__form-buttons">
              <button
                className="cancel-button"
                onClick={() => {
                  if (!selectedNote.title && !selectedNote.text) {
                    this.props.deleteNote(selectedNote.id);
                  }
                  this.props.selectNote(null);
                  this.setState({ selectedNote: null });
                }}
              >
                Cancel
              </button>
              <button
                className="save-button"
                onClick={() => {
                  if (!selectedNote.title && !selectedNote.text) {
                    this.props.deleteNote(selectedNote.id);
                  } else {
                    this.props.updateNote(this.state.selectedNote);
                  }
                  this.props.selectNote(null);
                  this.setState({ selectedNote: null });
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(
  state => ({
    app: state.app,
    user: state.auth.user,
    notes: state.notes,
    favorites: state.app.favorites.player,
  }),
  {
    loading,
    fetchNotes,
    selectNote,
    updateNote,
    createNote,
    addToFavorite,
    deleteNote,
    fetchNoteTags,
    updateNotesDictionaries,
    createNoteTag,
  },
)(enchanceClickOutside(NotesMobile));
