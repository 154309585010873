import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  onChangeApiKey: (apikey: string | null) => void;
  apikey: string | null;
}

export function ApiKey(props: Props) {
  const { onChangeApiKey, apikey } = props;

  const checkboxHandler = () => {
    onChangeApiKey(apikey ? null : uuidv4());
  };

  return (
    <Wrapper>
      <Sidebar>
        <div className={`country-option checkbox ${apikey ? 'active' : ''}`}>
          <input
            id="api-key-checkbox"
            type="checkbox"
            onChange={checkboxHandler}
            checked={!!apikey}
          />
          <label htmlFor="api-key-checkbox">API Access</label>
        </div>
      </Sidebar>
      <Content>
        {apikey ? (
          <input
            value={apikey || ''}
            readOnly
            type="text"
            placeholder="API Key"
          />
        ) : null}
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
`;

const Sidebar = styled.div`
  width: 300px;
  padding: 15px 20px;
  border-right: 1px solid #dfe3e6;
`;

const Content = styled.div`
  flex: auto;
  padding: 10px 20px;

  input[type='text'] {
    margin: 0 !important;
    width: 100% !important;
  }
`;
