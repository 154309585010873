import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-autosize-textarea';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import enchanceClickOutside from 'react-click-outside';

import Image from '../../../../ui/image';
import {
  createNoteTag,
  deleteNote,
  selectNote,
  setNoteMode,
  updateNote,
} from '../../../../../actions/notes';
import lang from '../../../../../lang';

import './note-form.scss';
import moment from 'moment';

class NotesForm extends Component {
  constructor(props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onEditDone = this.onEditDone.bind(this);
  }

  handleClickOutside(e) {
    this.onEditDone();
  }

  componentDidMount() {
    const { notes, updateNote } = this.props;
    const { mode, filter, tags, selectedNote } = notes;

    if (mode === 'create' && selectedNote.id && filter.tag_id) {
      const currentTag = tags.filter(tag => +tag.id === +filter.tag_id);

      if (currentTag && currentTag.length) {
        updateNote({
          ...selectedNote,
          tags: [
            {
              id: currentTag[0].id,
              tag: currentTag[0].tag,
            },
          ],
        });
      }
    }
  }

  onEditDone() {
    const { selectNote } = this.props;
    const { selectedNote } = this.props.notes;

    if (selectedNote && !selectedNote.title && !selectedNote.note) {
      this.props.setNoteMode(null);
      this.props.deleteNote(selectedNote.id);
    } else {
      selectNote(selectedNote);
    }
  }

  renderSelect() {
    const { playersOptions } = this.props.notes.dictionaries;
    const { selectedNote } = this.props.notes;

    return (
      <Select
        className="note-player-select"
        placeholder="Select player..."
        value={selectedNote.player_id}
        options={playersOptions}
        onChange={val => {
          this.props.updateNote({ ...selectedNote, player_id: val.value });
        }}
        optionRenderer={opt => {
          return (
            <span>
              <span className="player-card">
                <span className="player-img">
                  <span className="player-photo">
                    <Image src={opt.player_img} aspectRatio={0.96} />
                  </span>
                  <span className="team-logo">
                    <Image src={opt.team_img} aspectRatio={0.769} />
                  </span>
                </span>
                <span className="player-text">
                  <span className="player-name">{opt.label}</span>
                  <span className="player-team">{opt.team}</span>
                </span>
              </span>
            </span>
          );
        }}
        valueRenderer={val => {
          return (
            <span>
              <span className="player-card">
                <span className="player-img">
                  <span className="player-photo">
                    <Image src={val.player_img} aspectRatio={0.96} />
                  </span>
                  <span className="team-logo">
                    <Image src={val.team_img} aspectRatio={0.769} />
                  </span>
                </span>
                <span className="player-text">
                  <span className="player-name">{val.label}</span>
                  <span className="player-team">{val.team}</span>
                </span>
              </span>
            </span>
          );
        }}
      />
    );
  }

  render() {
    const { tagsOptions } = this.props.notes.dictionaries;
    const { selectedNote } = this.props.notes;
    const { player_id } = this.props;

    const createDate = selectedNote.updatedAt || selectedNote.createdAt;
    const date = createDate
      ? moment(createDate).format('YYYY-MM-DD - HH:mm')
      : '';

    // <div className="note-content__meta">By {`${selectedNote.user.firstName} ${selectedNote.user.lastName}`}, { date }</div>

    return (
      <form className="note-form">
        {!player_id ? (
          <div className="note-form__player">
            {this.renderSelect()}
            <button className="note-form__done" onClick={this.onEditDone}>
              {lang.done}
            </button>
          </div>
        ) : (
          <div>
            <button className="note-form__done" onClick={this.onEditDone}>
              {lang.done}
            </button>
          </div>
        )}

        <TextareaAutosize
          autoFocus
          className="note-form__title"
          placeholder={lang.note_title_placeholder}
          maxRows={3}
          value={selectedNote.title || ''}
          onChange={e => {
            this.props.updateNote({ ...selectedNote, title: e.target.value });
          }}
        />
        <TextareaAutosize
          className="note-form__text"
          placeholder={lang.note_text_placeholder}
          value={selectedNote.note || ''}
          rows={6}
          onChange={e => {
            this.props.updateNote({ ...selectedNote, note: e.target.value });
          }}
        />

        <div className="note-form__tags">
          <Creatable
            value={selectedNote.tags.map(t => ({ label: t.tag, value: t.id }))}
            className="note-tag-select"
            placeholder="Add a tag"
            promptTextCreator={value => `Create tag #${value}`}
            onNewOptionClick={value => {
              this.props.createNoteTag({ tag: value.label }).then(action => {
                const { data } = action.payload;
                const v = [...selectedNote.tags, { ...data }];
                this.props.updateNote({ ...selectedNote, tags: v });
              });
            }}
            options={tagsOptions}
            multi
            searchable
            onChange={val => {
              const v = [...val];
              this.props.updateNote({
                ...selectedNote,
                tags: v.map(t => ({ id: t.value, tag: t.label })),
              });
            }}
          />
        </div>
      </form>
    );
  }
}

export default connect(state => ({ notes: state.notes }), {
  createNoteTag,
  updateNote,
  selectNote,
  setNoteMode,
  deleteNote,
})(enchanceClickOutside(NotesForm));
