import React, { Component } from 'react';
import _ from 'lodash';
import { Tooltip } from 'd3-react-touch';
import moment from 'moment';
import * as d3_scale from 'd3-scale';
import { Scrollbars } from 'react-custom-scrollbars';

import './heatmap.scss';

import Image from '../../ui/image';
import { database } from '../../../constants/database';

let scrolling = false;

export default class HeatMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltip: {
        id: null,
        x: 0,
        y: 0,
        child: null,
      },
    };

    this._renderTeams = this._renderTeams.bind(this);
    this._deSelectItem = this._deSelectItem.bind(this);
    this._clickHandler = this._clickHandler.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { height, width, activePointData } = nextProps;
    const { scrollbar } = this.refs;
    const scrollHeight = scrollbar.getClientHeight();
    const scrollTop = scrollbar.getScrollTop();
    const old_match_id = _.get(this.props.activePointData, 'match_id', NaN);
    const new_match_id = _.get(nextProps.activePointData, 'match_id', NaN);

    if (activePointData && old_match_id !== new_match_id) {
      const elem = this.refs[`heatmap-${new_match_id}`];
      const offset = elem.getBoundingClientRect();

      const tooltip = {
        id: new_match_id,
        screenWidth: width,
        screenHeight: height,
      };

      if (offset.top < 0 || offset.top > scrollHeight) {
        scrolling = true;
        scrollbar.scrollTop(scrollTop + offset.top - scrollHeight / 2);
        this.setState({
          tooltip: {
            ...tooltip,
            x:
              offset.left < width / 2
                ? offset.left + elem.offsetWidth
                : offset.left,
            y: scrollHeight / 2,
          },
        });
      } else {
        this.setState({
          tooltip: {
            ...tooltip,
            x:
              offset.left < width / 2
                ? offset.left + elem.offsetWidth
                : offset.left,
            y:
              offset.top < height / 2
                ? offset.top + elem.offsetHeight
                : offset.top,
          },
        });
      }
    }
  }

  _clickHandler(id, event) {
    const { width, height, activePointData, onPointSelect } = this.props;
    const { tooltip } = this.state;
    const elem = event.target;
    const offset = elem.getBoundingClientRect();

    if (activePointData) {
      this._deSelectItem();
      return false;
    }

    onPointSelect({
      mode: 'match',
      match_id: id,
    });

    this.setState({
      tooltip: {
        id,
        x:
          offset.left < width / 2
            ? offset.left + elem.offsetWidth
            : offset.left,
        y:
          offset.top < height / 2 ? offset.top + elem.offsetHeight : offset.top,
        screenWidth: width,
        screenHeight: height,
      },
    });
  }

  _deSelectItem() {
    const { onPointSelect, activePointData } = this.props;

    if (!scrolling && activePointData) {
      onPointSelect(null);
      this.setState({
        tooltip: {
          id: null,
          x: 0,
          y: 0,
          child: null,
        },
      });
    } else {
      scrolling = false;
    }
  }

  _renderTeams() {
    const options = {
      minWidth: 136,
      ratio: 0.618,
      groupNameHeight: 39,
      detailViewHeight: 84,
    };
    const {
      data = [],
      teams = {},
      regions = {},
      sDomain,
      sVal,
      width,
      height,
      activePointData,
    } = this.props;

    const active_id = _.get(activePointData, 'match_id', null);
    const heatMapWidth = Math.floor(width - 10);

    if (!data.length || !_.size(teams) || !_.size(regions) || !sDomain || !sVal)
      return null;

    const colorScale = d3_scale
      .scaleSequential()
      .domain([sDomain[0] - 0.001, sDomain[1] + 0.001 + sDomain[1] / 10])
      .interpolator(d3_scale.interpolateViridis);
    const byGroups = _.groupBy(data, (match, m) =>
      moment(match.date).format('MMMM DD YYYY'),
    );
    const sortGroups = _.orderBy(
      _.map(byGroups, (group, name) => ({
        name,
        timestamp: moment(name, 'MMMM DD YYYY').format('X'),
      })),
      group => group.timestamp,
      ['desc'],
    );

    function generateWidth(teamWidth) {
      const colsLength = heatMapWidth / teamWidth;
      const teamRows =
        data.length / colsLength + (_.size(byGroups) === 3 ? 2 : 1);
      const teamHeight = (heatMapWidth / colsLength) * options.ratio;
      const groupNames =
        _.size(byGroups) > 2 ? 2 : _.size(byGroups) * options.groupNameHeight;

      if (teamHeight * teamRows + groupNames + teamHeight < height) {
        return generateWidth(teamWidth + 1);
      }
      return teamWidth - 1;
    }

    const teamWidth = generateWidth(options.minWidth);
    const rowLength = Math.ceil(heatMapWidth / teamWidth);
    const round = 100 / rowLength;
    const byGroupsSize = _.fill(Array(_.size(byGroups)), 100);

    const renderGroup = (group, groupWidth) => {
      const detailed = teamWidth * options.ratio >= options.detailViewHeight;

      return _.orderBy(
        group,
        match => (match[sVal] ? +match[sVal] : 0),
        'desc',
      ).map(match => (
        <div
          style={{
            backgroundColor: colorScale(match[sVal]),
            width: `${100 / (groupWidth / round)}%`,
          }}
          key={match.match_id}
          className={`match ${active_id == match.match_id ? 'active' : ''} ${
            detailed ? 'detail' : ''
          }`}
          onClick={e => this._clickHandler(match.match_id, e)}
          ref={`heatmap-${match.match_id}`}
        >
          <div className="align">
            <div className="home">
              {match.team_id_home ? (
                <Image
                  src={`${database.assets}teams/${match.team_id_home}.jpg`}
                  aspectRatio={0.769}
                />
              ) : null}
              <div className="team-name">
                {_.get(teams, `${match.team_id_home}.abk`, null)}
              </div>
            </div>
            <div className="goals">
              {match.homegoals} : {match.awaygoals}
            </div>
            <div className="away">
              {match.team_id_away ? (
                <Image
                  src={`${database.assets}teams/${match.team_id_away}.jpg`}
                  aspectRatio={0.769}
                />
              ) : null}
              <div className="team-name">
                {_.get(teams, `${match.team_id_away}.abk`, null)}
              </div>
            </div>
          </div>
        </div>
      ));
    };

    return sortGroups
      .filter(group => byGroups[group.name])
      .map((group, g) => {
        return (
          <div className="group" key={g}>
            <div className="group-delay">
              <div className="group-name">{group.name}</div>
              <div className="group-list">
                {renderGroup(byGroups[group.name], byGroupsSize[g])}
              </div>
            </div>
          </div>
        );
      });
  }

  _renderTooltip() {
    const { tooltip } = this.state;
    const { tooltipContent, filteredData, activePointData } = this.props;

    if (!activePointData) return null;

    return (
      <Tooltip
        style={{ left: tooltip.x, top: tooltip.y }}
        filteredData={filteredData}
      >
        {tooltipContent}
      </Tooltip>
    );
  }

  render() {
    const { height, width } = this.props;
    const heatMapWidth = Math.floor(width + 30);

    return (
      <div className="heatmap matches" style={{ width: heatMapWidth }}>
        <Scrollbars
          style={{ height }}
          autoHide
          onScrollStart={this._deSelectItem}
          ref="scrollbar"
        >
          {this._renderTeams()}
          {this._renderTooltip()}
        </Scrollbars>
      </div>
    );
  }
}
