import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import ReactModal from 'react-modal';
import { browserHistory } from 'react-router';

import 'react-virtualized/styles.css';
import './App.scss';

import lang from '../lang';

import {
  fetchDataForOverview,
  loadData,
  loading,
  loadQuote,
} from '../actions/app';
import { dataErrorModal } from '../actions/details';
import { logout, setPrivacy } from '../actions/auth';
import LoadScreen from '../components/ui/load-screen';

import { DEFAULT_SEASON } from '../constants/index';

import Confirm from '../components/confirm';
import PageLoader from '../components/page-loader';
import { getGroupBy, getSeason } from '../helper';

import { NotificationContainer } from '../components/notifications/';

class App extends Component {
  static propTypes = {
    app: PropTypes.object,
    auth: PropTypes.object,
    children: PropTypes.element,
    dataErrorModal: PropTypes.func,
    details: PropTypes.object,
    loadData: PropTypes.func,
    loadQuote: PropTypes.func,
    location: PropTypes.object,
    params: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };

    this.getData = this.getData.bind(this);
  }

  componentWillMount() {
    const { mode } = this.props.params;

    mode && localStorage.setItem('overview-mode', mode);
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.auth.user &&
      nextProps.auth.token &&
      !nextProps.app.loaded &&
      !nextState.loading &&
      !nextState.error
    ) {
      if (!this.state.loading) {
        this.setState({ loading: true });
        this.getData();
      }
    }

    if (this.props.auth.user && nextProps.location.pathname === '/') {
      browserHistory.replace('/home');
    }

    if (this.props.auth.user && nextProps.location !== this.props.location) {
      window.previousLocation = this.props.location;
    }
  }

  getData() {
    const { pathname } = this.props.location;

    const isOverview =
      pathname.split('/')[1] === 'overview' ||
      pathname.split('/')[1] ===
        'compare' /*|| pathname.split('/')[1] === 'search'*/ ||
      pathname.split('/')[1] === 'favorites';
    const isDetails = pathname.split('/')[1] === 'details';
    const { user } = this.props.auth;

    this.props.loadQuote().finally(() => {
      this.props
        .loadData(DEFAULT_SEASON, 'desktop', 'position_2', !isDetails)
        .then(() => {
          if (isOverview) {
            // preset - localstorage - global store

            const mode =
              isOverview && this.props.params.mode
                ? this.props.params.mode
                : localStorage.getItem('overview-mode');
            let savedFilter = localStorage.getItem(`overview_filter_player`);
            let groupBy = 'position_2';
            let season = DEFAULT_SEASON;
            if (savedFilter) {
              savedFilter = JSON.parse(savedFilter);
              groupBy = getGroupBy(savedFilter);
              season = getSeason(savedFilter, mode);
            }
            // 2 - group
            // 1 - overall
            // 3 - detailed

            this.props
              .fetchDataForOverview(season, 'desktop', groupBy)
              .then(() => {
                window.Intercom('boot', {
                  app_id: 'vrt25ga0',
                  email: user.email,
                  group: user.group.name,
                  role: user.role.name,
                  name: `${user.firstName} ${user.lastName}`,
                  user_id: user.id,
                  just_registered: false,
                });
                this.setState({ loading: false, error: false });
              });
          } else {
            this.setState({ loading: false, error: false });
          }

          const text = (dismiss, onCheck, isChecked) => {
            const checked = isChecked ? 'checked' : '';
            return (
              <div className="privacy-confirm">
                <h2 className="h2">GDPR Consent Requirement</h2>

                <p>
                  To help comply with the GDPR consent requirements, please
                  confirm that you accept our privacy policy, would like to make
                  use of your Scoutpanel account and receive content from us.{' '}
                </p>
                <p>
                  Our main concern is that your data is safe and secure. For
                  this purpose, we use analytics software with care and only for
                  optimizing user-behavior. We do not store visited profiles,
                  all favorites and notes are stored in a personal database,
                  which can only be accessed by you and your team members.{' '}
                  <a
                    href="https://intercom.help/mm_scoutpanel/en/articles/3671712-data-security-for-scoutpanel"
                    target="_blank"
                  >
                    Read more…
                  </a>
                </p>
                <p className="with-checkbox">
                  <span className={`checkbox ${checked}`} onClick={onCheck}>
                    <span className="icon-check" />
                  </span>
                  By checking this box, I confirm having read, understood and
                  accepted the{' '}
                  <a
                    href="https://scoutpad.com/en/privacy-policy-2/"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
              </div>
            );
          };

          const { user } = this.props.auth;

          if (!user.acceptPrivacy) {
            Confirm(text, {
              context: this.context,
              withCheckbox: true,
              cancelText: 'Disagree',
              confirmText: 'Agree and proceed',
              hideCancel: true,
            }).then(
              () => {
                this.props.setPrivacy(true);
              },
              () => {
                this.props.setPrivacy(false).then(() => {
                  this.props.logout();
                  document.location = '/';
                });
              },
            );
          }
        })
        .catch(error => {
          console.error(error);
          this.setState({ loading: false, error: true });
        });
    });
  }

  render() {
    const { details, dataErrorModal, loader } = this.props;
    const { load_quote } = this.props.app;
    const { pathname } = this.props.location;

    return (
      <div className="app">
        <div
          className={`loader-small ${
            this.props.app.loading ? 'page-opacity on' : 'page-opacity'
          }`}
        >
          <div className="loader-container">
            <div className="loader" />
            {this.props.app.loading_message ? (
              <div className="loader-message">
                {lang[this.props.app.loading_message]}
              </div>
            ) : null}
          </div>
          <div className="loader-bg" />
        </div>

        <LoadScreen
          loading={this.state.loading}
          error={this.state.error}
          load_quote={load_quote}
        />

        <div className={`error ${this.state.error ? 'show' : 'hide'}`}>
          <div className="error-container">
            <div className="error-centered">
              <h1>
                Houston, we have a Problem.
                <br />
                Please make sure that you have a working internet connection.
              </h1>
            </div>
          </div>
        </div>

        {this.props.app.loaded &&
        this.props.auth.user &&
        !this.state.loading ? (
          <div>
            <CSSTransitionGroup
              transitionName="modal"
              transitionEnter
              transitionEnterTimeout={500}
              transitionLeave
              transitionLeaveTimeout={500}
              transitionAppear={false}
            >
              <div
                key={
                  pathname.split('/')[1] === 'overview' ? 'overview' : 'modal'
                }
              >
                {this.props.children}
              </div>
            </CSSTransitionGroup>
            <NotificationContainer leaveTimeout={400} enterTimeout={0} />
          </div>
        ) : null}

        {pathname.indexOf('auth') !== -1 ? this.props.children : null}

        <svg width="0" height="0">
          <defs>
            <clipPath id="clipPolygon" clipPathUnits="objectBoundingBox">
              <polygon points="0 0, 1 0, 0.75 1, 0 1" />
            </clipPath>
          </defs>
          <defs>
            <clipPath id="clipPolygonRight" clipPathUnits="objectBoundingBox">
              <polygon points="0.25 0, 1 0, 1 1, 0 1" />
            </clipPath>
          </defs>
        </svg>

        <ReactModal
          isOpen={details.dataModalError}
          className="modal-alert"
          overlayClassName="modal-alert-overlay"
          closeTimeoutMS={300}
        >
          <a
            className="icon-close"
            onClick={e => {
              e.preventDefault();
              dataErrorModal(false);
            }}
          />
          <h2>{`No data for this ${details.dataModalErrorMode}`}</h2>
          <p>
            <a href="mailto:support@scoutpanel.com">Contact support</a>
          </p>
        </ReactModal>

        <PageLoader />
      </div>
    );
  }
}

export default connect(
  state => ({
    app: state.app,
    details: state.details,
    auth: state.auth,
    loader: state.loader,
  }),
  {
    loadData,
    loading,
    loadQuote,
    dataErrorModal,
    setPrivacy,
    logout,
    fetchDataForOverview,
  },
)(App);
