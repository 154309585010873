import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { NotificationManager } from '../../../../notifications';

import Icon from '../../../../ui/svg-icon';

import './quick-copy.scss';

class QuickCopy extends Component {
  render() {
    const { item, app } = this.props;

    if (!item.isAccessGranted) return null;

    const epi =
      Array.isArray(item.epiRating) &&
      item.epiRating.find(p => +p.player_id === +item.id);
    const { information } = item;
    const position_id = +item.current_position || item.performance.position_id;
    const season = Array.isArray(item.current_season)
      ? item.current_season.join('/')
      : item.current_season;
    const { mins } = item.performance;
    const league_id = item.current_league;
    const { height, weight, team_id, age } = information || {};

    const hostname_array = String(window.location.href).split('/');
    const hostname = `${hostname_array[0]}//${hostname_array[2]}`;

    const url = `${hostname}/details/player/${item.id}/${league_id}/${season}${
      item.current_position ? `?position=${position_id}` : ''
    }`; // document.location.href; //http://localhost:8090/details/player/89692/461/2019/summary?position=2
    const name = `${_.get(information, 'first_name', '')} ${_.get(
      information,
      'last_name',
      '',
    )}`;
    const league = _.get(app, `leagues[${league_id}]`, '');
    const team = _.get(app, `teams[${team_id}]`, '');
    const position = _.get(app, `positions[${position_id}]`, '');

    const text = `${name ? `${name}, ` : ''}${age ? `${age} yrs, ` : ''}${
      height ? `${height} cm, ` : ''
    }${weight ? `${weight} kg, ` : ''}${
      position ? `${position.position_name}, ` : ''
    }${mins ? `${mins} mins, ` : ''}${
      epi ? `RAT: ${epi.season_rating}, ` : ''
    }${epi ? `STA: ${epi.stability}, ` : ''}${
      epi ? `OFF: ${epi.offence}, ` : ''
    }${epi ? `DEF: ${epi.defence}, ` : ''}${season ? `${season}, ` : ''}${
      league ? `${league.name}, ` : ''
    }${team ? team.short_club_name : ''} - ${url}`;

    return (
      <div
        className="quick-copy"
        onClick={e => {
          const copyText = document.getElementById('PlayerInfoBox');
          copyText.select();
          copyText.setSelectionRange(0, 99999);
          document.execCommand('copy');
          copyText.blur();

          NotificationManager.create({
            message: 'Player profile copied to clipboard',
            id: 'id',
            timeOut: 6000,
            type: 'success',
          });

          this.props.onAfterCopy && this.props.onAfterCopy();
        }}
      >
        <Icon name="copy-link" />
        <input id="PlayerInfoBox" defaultValue={text} readOnly />
      </div>
    );
  }
}

QuickCopy.propTypes = {
  item: PropTypes.object,
};

export default connect(
  state => ({
    app: state.app,
  }),
  {},
)(QuickCopy);
