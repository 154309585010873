import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Slider from '../../../../ui/slider';
import { IndicatorType } from '../../../../../reducers/overview/types';
import { selectSliderFilter } from '../../../../../reducers/overview/selectors';
import { ModeType } from '../../../../../types/Dict';
import { RootState } from '../../../../../types';
import {
  changeSliderVal,
  changeSliderHelperValue,
} from '../../../../../actions/overview';
import lang from '../../../../../lang';
import { CheckboxField } from '../fields/CheckboxField';

interface Props {
  sliderName?: IndicatorType;
  mode: ModeType;
}

const defaultProps = {
  sliderName: IndicatorType.CONTRACT_EXPIRY,
};

export function ContractExpirationFilter(props: Props & typeof defaultProps) {
  const { mode, sliderName } = props;
  const dispatch = useDispatch();
  const slider = useSelector((state: RootState) =>
    selectSliderFilter(state, { mode, sliderName }),
  );

  if (!slider) return null;

  const { minMax, step, data, include_missing_data } = slider;
  const minDistance = 0;

  const onChanged = value => {
    dispatch(changeSliderVal(sliderName, value, mode));
  };

  const onMissingDataChange = (name, value) => {
    dispatch(
      changeSliderHelperValue(
        sliderName,
        {
          include_missing_data: value,
        },
        mode,
      ),
    );
  };

  return (
    <div className="filters__slider">
      <h2>{lang[sliderName]}</h2>
      <Slider
        value={data}
        min={minMax[0]}
        max={minMax[1]}
        step={step}
        minDistance={minDistance}
        onChanged={onChanged}
      />
      <div className="filters__slider__checkbox">
        <CheckboxField
          checked={!!include_missing_data}
          onChange={onMissingDataChange}
          name={sliderName}
        >
          {lang.include_missing_data}
        </CheckboxField>
      </div>
    </div>
  );
}

ContractExpirationFilter.defaultProps = defaultProps;
