import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { DataItem } from '../../../../ui/data-item';
import DateRangeDropdown from '../../../../ui/date-range-dropdown';

import lang from '../../../../../lang';

class SeasonSelect extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    current_season: PropTypes.array,
    seasons: PropTypes.array,
    data: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.changeSeason = this.changeSeason.bind(this);
  }

  changeSeason(value) {
    this.props.onChange(value);
  }

  render() {
    const { current_season, seasons, data = '', mode } = this.props;

    const seasonsById = _.keyBy(seasons, 'id');

    const seasonIds = _.chain(data)
      .replace(/ /g, '')
      .split(',')
      .map(slp => +slp.substr(0, slp.indexOf(':')))
      .uniq()
      .filter(seasonId => seasonsById[seasonId])
      .orderBy(
        seasonId => (seasonId > 2000 ? seasonId : 10000 - seasonId),
        'desc',
      )
      .value();

    const options = seasonIds.map(seasonId => ({
      value: seasonId,
      label: seasonsById[seasonId].name,
    }));

    return (
      <DataItem desc={lang.season} reverse>
        <div className="data-text">
          <DateRangeDropdown
            options={options}
            value={current_season}
            onChange={this.changeSeason}
            dateSlider={mode === 'player'}
          />
        </div>
      </DataItem>
    );
  }
}

export default connect(state => ({
  current_season: state.details.current_season,
  data: state.details.information.data,
  seasons: state.app.seasons,
  mode: state.details.mode,
  details: state.details,
}))(SeasonSelect);
