import axios from 'axios';

import * as types from '../constants';

export function updateUser({ userId, body }) {
  const request = axios.put(`users/${userId}`, body);

  return {
    type: types.UPDATE_USER,
    payload: request,
    meta: { userId },
  };
}

export function deleteUser({ userId }) {
  const request = axios.delete(`users/${userId}`);

  return {
    type: types.DELETE_USER,
    payload: request,
    meta: { userId },
  };
}

export function getUsers() {
  const request = axios.get('users');

  return {
    type: types.GET_USERS,
    payload: request,
  };
}

export function addUser({ user }) {
  const request = axios.post(`users/register`, { user });

  return {
    type: types.ADD_USER,
    payload: request,
  };
}
