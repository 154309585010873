import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router';

import lang from '../../../../../lang';

import Positions from './positions';
import Image from '../../../../ui/image';
import { database } from '../../../../../constants/database';

class Lineup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      side: 'home',
    };

    this.renderBenchList = this.renderBenchList.bind(this);
    this.renderSubsitutionsList = this.renderSubsitutionsList.bind(this);
  }

  renderBenchList({ teamId }) {
    const { information = {} } = this.props.details;
    const { positions, playersMetaData } = this.props.app;
    let squads;

    if (!_.size(information)) return null;

    try {
      squads = JSON.parse(information.squads);
    } catch (error) {
      console.info(`squads JSON in match ${information.match_id} is not valid`);
      console.error(error);
      return null;
    }

    const playersBench = squads[teamId].bench.map(player => ({
      ...player,
      ...playersMetaData[player.player_id],
    }));

    return _.sortBy(playersBench, player => +player.position_id)
      .filter(player => player.last_name)
      .map((player, i) => (
        <tr key={i}>
          <td>
            <Link
              to={`/details/player/${player.player_id}/summary`}
              className="jersey-number"
            >
              {player.last_name}
            </Link>
          </td>
          <td
            className={`td-position ${
              !player.position_id || !positions[player.position_id]
                ? 'not_defined'
                : ''
            }`}
          >
            {player.position_id && positions[player.position_id]
              ? positions[player.position_id].position_name
              : 'N/D'}
          </td>
        </tr>
      ));
  }

  renderSubsitutionsList({ teamId, playerStatsById }) {
    const { information = {} } = this.props.details;
    const { playersMetaData } = this.props.app;
    let substitutions;

    if (!_.size(information)) return null;

    try {
      substitutions = JSON.parse(information.substitutions);
    } catch (error) {
      console.info(
        `substitutions JSON in match ${information.match_id} is not valid`,
      );
      console.error(error);
      return null;
    }

    const substitutionsByMins = _.groupBy(
      substitutions[teamId].map(player => ({
        ...player,
        ...playersMetaData[player.player_id],
      })),
      'min',
    );

    return _.map(substitutionsByMins, (substitutions, min) => (
      <tr key={min}>
        <td className="td-min">{min}`</td>
        <td>
          {substitutions
            .filter(
              substitution =>
                substitution.type === 'out' && substitution.last_name,
            )
            .map((substitution, i) => {
              const player = _.get(
                playerStatsById,
                `${substitution.player_id}`,
                {},
              );

              return (
                <div
                  className="substitution substitution-out"
                  key={`${min}${i}${substitution.type}`}
                >
                  <Link
                    to={`/details/player/${substitution.player_id}/summary`}
                    className={`jersey-number ${
                      +teamId === +information.team_id_home ? 'home' : 'away'
                    }`}
                  >
                    <span className="number">
                      {player && player.mins > 20
                        ? _.get(
                            playerStatsById,
                            `${substitution.player_id}.rating`,
                            'N/D',
                          )
                        : 'N/D'}
                    </span>
                    {substitution.last_name}
                  </Link>
                </div>
              );
            })}
        </td>
        <td>
          {substitutions
            .filter(substitution => substitution.type === 'in')
            .map((substitution, i) => {
              const player = playerStatsById[substitution.player_id];

              return (
                <div
                  className="substitution substitution-in"
                  key={`${min}${i}${substitution.type}`}
                >
                  <Link
                    to={`/details/player/${substitution.player_id}/summary`}
                    className={`jersey-number ${
                      teamId == information.team_id_home ? 'home' : 'away'
                    }`}
                  >
                    <span className="number">
                      {player && player.mins > 20
                        ? _.get(
                            playerStatsById,
                            `${substitution.player_id}.rating`,
                            'N/D',
                          )
                        : 'N/D'}
                    </span>
                    {substitution.last_name}
                  </Link>
                </div>
              );
            })}
        </td>
      </tr>
    ));
  }

  render() {
    const { information = {}, playerStats } = this.props.details;
    const { positions, playersMetaData, teams, formations } = this.props.app;
    const { side } = this.state;

    let squads;

    if (
      !_.size(information) ||
      !information.team_id_home ||
      !information.squads ||
      !information.squads.length
    )
      return null;

    try {
      squads = JSON.parse(information.squads);
    } catch (error) {
      console.info(
        `substitutions JSON in match ${information.match_id} is not valid`,
      );
      console.error(error);
      return null;
    }

    const playerStatsById = _.keyBy(playerStats, 'player_id');
    const playersLineUpHome = squads[information.team_id_home].line_up.map(
      player => ({
        ...player,
        ..._.get(playersMetaData, `${player.player_id}`, {}),
        ..._.get(positions, `${player.position_id_detail}`, {}),
        rating:
          _.get(playerStatsById, `${player.player_id}.mins`) > 20
            ? _.get(playerStatsById, `${player.player_id}.rating`, 'N/D')
            : 'N/D',
      }),
    );
    const playersLineUpAway = squads[information.team_id_away].line_up.map(
      player => ({
        ...player,
        ..._.get(playersMetaData, `${player.player_id}`, {}),
        ..._.get(positions, `${player.position_id_detail}`, {}),
        rating:
          _.get(playerStatsById, `${player.player_id}.mins`) > 20
            ? _.get(playerStatsById, `${player.player_id}.rating`, 'N/D')
            : 'N/D',
      }),
    );

    return (
      <div>
        <div className="nav-container">
          <div className="nav row">
            <a
              className={`col-6 callAction ${side === 'home' ? 'active' : ''}`}
              onClick={e => {
                this.setState({ side: 'home' });
              }}
            >
              {_.get(teams, `${information.team_id_home}.team_name`)}
            </a>
            <a
              className={`col-6 callAction ${side === 'away' ? 'active' : ''}`}
              onClick={e => {
                this.setState({ side: 'away' });
              }}
            >
              {_.get(teams, `${information.team_id_away}.team_name`)}
            </a>
          </div>
        </div>
        <div className="content-container">
          <div className="match-lineups">
            <div className="h4 team-name">
              <span>
                <Image
                  src={`${database.assets}teams/${
                    information[`team_id_${side}`]
                  }.jpg`}
                  aspectRatio={0.769}
                />
                {teams[information[`team_id_${side}`]].team_name}
              </span>
              <span className="description">
                {lang.lineup}{' '}
                {_.get(
                  formations,
                  `${_.get(
                    squads,
                    `${information.team_id_home}.formation_id`,
                  )}.name`,
                  null,
                )}
              </span>
            </div>
            <Positions
              positions={{
                home: side === 'home' ? playersLineUpHome : [],
                away: side === 'away' ? playersLineUpAway : [],
              }}
              information={information}
            />
            <div className="bench">
              <div className="bench-title">{lang.bench}</div>
              <table className="bench-table">
                <tbody>
                  {this.renderBenchList({
                    teamId: information[`team_id_${side}`],
                  })}
                </tbody>
              </table>
            </div>
            <div className="subsitutions">
              <div className="subsitutions-title">{lang.subsitutions}</div>
              <table className="subsitutions-table">
                <tbody>
                  {this.renderSubsitutionsList({
                    teamId: information[`team_id_${side}`],
                    playerStatsById,
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  app: state.app,
  details: state.details,
}))(Lineup);
