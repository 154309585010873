import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import _ from 'lodash';
import * as d3 from 'd3';

import './analyse.scss';
import lang from '../../../lang';
import { getColorFromCustomScale } from '../../../helper';
import { SECOND_SCORE_COLORSCALE } from '../../../constants';

class SquadAnalyse extends Component {
  constructor(props) {
    super(props);

    this._renderAnalyse = this._renderAnalyse.bind(this);

    this.state = {
      rows: [
        ['season_rating', 'offence', 'defence'],
        [
          'tackle_offence',
          'tackle_defence',
          'intercept_offence',
          'intercept_defence',
        ],
        ['blocked', 'save', 'aerial_offence', 'aerial_defence'],
        ['take_on', 'crossing', 'pass_short', 'pass_long'],
        ['reception', 'delivery', 'scoring', 'scoring_effort'],
      ],
    };
  }

  _renderAnalyse() {
    const { squad_analyse, current_league, current_season } =
      this.props.details;

    const { rows } = this.state;
    const { positions } = this.props.app;

    if (!squad_analyse || !squad_analyse.length) return null;

    const minsDomain = d3.extent(
      squad_analyse,
      ({ season_mins }) => +season_mins,
    );

    const renderHeader = cols =>
      cols.map(col => (
        <div key={`header-${col}`} className="col-3">
          <div className="col-header-name">{lang[col]}</div>
        </div>
      ));

    const filterPlayers = squad_analyse.filter(
      player =>
        player.player_id &&
        +player.league_id === +current_league &&
        player.season_rating,
    );
    const playerPositions = _.chain(filterPlayers)
      .map(player => player.position_id)
      .uniq()
      .sortBy()
      .value();

    const renderPlayers = (players, col) =>
      _.orderBy(
        players,
        player => (player[col] ? +player[col] : 0),
        'desc',
      ).map(player => {
        const value = parseFloat(player[col]) || 0;
        const mins = +player.season_mins || 0;
        const color = getColorFromCustomScale(
          mins,
          minsDomain,
          SECOND_SCORE_COLORSCALE,
        );
        const style = !_.isNull(value) ? { backgroundColor: color } : {};
        const style_border = !_.isNull(value)
          ? { borderBottomColor: color }
          : {};

        return (
          <div
            key={`${player.player_id}-${player.position_id}-${col}`}
            className={`indicator ${!player[col] ? 'gray' : ''}`}
            style={style_border}
          >
            <div
              className="indicator-percent"
              style={{ width: `${value * 10}%`, ...style }}
            />
            <Link
              className={`name ${
                player[col]
                  ? parseFloat(player[col]) < 5
                    ? 'dark'
                    : 'bright'
                  : 'gray'
              }`}
              to={`/details/player/${player.player_id}/${current_league}/${current_season}/summary`}
            >
              {player.short_name}
            </Link>
            <div className="indicator-value dark">{parseInt(mins)}</div>
            <div className={`indicator-value ${player[col] ? 'dark' : 'gray'}`}>
              {player[col] ? parseFloat(player[col]).toFixed(2) : 'N/D'}
            </div>
          </div>
        );
      });

    const renderBodyCol = (col, key) =>
      playerPositions.map((position, i) => (
        <div className="group-position" key={`body-${col}-${position}`}>
          {key === 0 ? (
            <div className="position-name">
              {_.get(positions, `${position}.position_name`, '')}
            </div>
          ) : null}
          {renderPlayers(
            _.clone(filterPlayers).filter(
              player => player.position_id === position,
            ),
            col,
          )}
        </div>
      ));

    const renderCols = cols =>
      cols.map((col, i) => (
        <div key={`body-${col}`} className="col-3">
          <div className="group-body-header">
            <div className="indicator-title">{lang.mins}</div>
            <div className="indicator-title">{lang[col]}</div>
          </div>
          {renderBodyCol(col, i)}
        </div>
      ));

    return rows.map((row, i) => (
      <div className="group" key={i}>
        <div className="group-header">
          <div className="row">
            {row.map(col => (
              <div key={`'line-'${col}`} className="col-3 middle-line">
                <div className="margin">
                  <div className="line" />
                </div>
              </div>
            ))}
          </div>
          <div className="row">{renderHeader(row)}</div>
        </div>
        <div className="group-body">
          <div className="row">{renderCols(row, i)}</div>
        </div>
      </div>
    ));
  }

  render() {
    const { isAccessGranted, squad_analyse } = this.props.details;
    const minsDomain = d3.extent(
      squad_analyse,
      ({ season_mins }) => +season_mins,
    );

    return (
      <div className={`${!isAccessGranted ? 'blurred' : ''}`}>
        <div className="h4">
          <span>ANALYSE ALL PLAYERS BY INDICATOR</span>
        </div>
        <div className="player-ranking__list-legend">
          <span className="legend">{lang.mins}</span>
          <span className="second_score">{minsDomain[0]}</span>
          {SECOND_SCORE_COLORSCALE.map(c => (
            <span className="color" key={c} style={{ backgroundColor: c }} />
          ))}
          <span className="second_score">{minsDomain[1]}</span>
        </div>
        <div className="squad analyse">{this._renderAnalyse()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    details: state.details,
    app: state.app,
    overview: state.overview,
  };
}

/* function mapDispatchToProps(dispatch) {
 return bindActionCreators({...DetailsActions, loading}, dispatch)
 } */
export default connect(mapStateToProps /* , mapDispatchToProps */)(
  SquadAnalyse,
);
