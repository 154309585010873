import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import axios from 'axios';

import './header.scss';

import { reset } from '../../../actions/compare';

// Load actions, own fcts and components
import lang from '../../../lang';
import { database } from '../../../constants/database';

class Header extends Component {
  _reset(ids, item, event) {
    const { reset } = this.props;
    const mode = localStorage.getItem('overview-mode') || this.props.mode;

    const returnTo = localStorage.getItem('returnTo');
    const closeLink = returnTo === 'welcome' ? '/home' : `/overview/${mode}`;

    reset();
    browserHistory.push(closeLink);
  }

  _generatePdfLink() {
    const { data } = this.props.compare;

    const link = data
      .filter(compare => compare.league && compare.player_id && compare.season)
      .map(compare => {
        const result = {
          id: compare.player_id,
          seasonId: compare.season,
          leagueId: compare.league,
        };
        if (compare.formation) result.formationId = compare.formation;
        if (compare.position) result.positionId = compare.position;

        return result;
      });

    return JSON.stringify(link);
  }

  pdfLink(url, event) {
    event.preventDefault();
    const pdfWindow = window.open('', '', '');

    axios.get('/file/token').then(result => {
      const { token } = result.data;
      if (token) {
        pdfWindow.location = `${url}?token=${token}`;
      }
    });
  }

  render() {
    const { mode } = this.props;

    return (
      <div className="header-container">
        <div className="container">
          <div className="row header">
            <div className="col-4">
              <div className="icon-compare" />
              <div className="title">{lang.compare}</div>
            </div>
            <div className="col-4">&nbsp;</div>
            <div className="col-4 header__actionButtons">
              <div className="btn" onClick={this._reset.bind(this)}>
                <div className="icon-close" />
              </div>
              {mode ? (
                <a
                  onClick={this.pdfLink.bind(
                    this,
                    `${
                      database.pdf.compare
                    }${mode}/${this._generatePdfLink()}/Compare`,
                  )}
                  className="download"
                >
                  <span className="icon-pdf">
                    <svg role="img">
                      <use xlinkHref="/img/sprite.symbol.v106.svg#download" />
                    </svg>
                  </span>
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    app: state.app,
    compare: state.compare,
    overview: state.overview,
  }),
  { reset },
)(Header);
