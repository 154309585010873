import React from 'react';

import { TextSwitcherField } from '../fields/TextSwitcherField';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../types';
import { selectSliderFilter } from '../../../../../reducers/overview/selectors';
import {
  changeSliderVal,
  changeSliderHelperValue,
  changeSliderMinMax,
} from '../../../../../actions/overview';
import Slider from '../../../../ui/slider';
import { IndicatorType } from '../../../../../reducers/overview/types';
import { ModeType } from '../../../../../types/Dict';
import { player as playerInitialValues } from '../../../../../reducers/overview/state/player';

const currentYear = new Date().getFullYear();

interface Props {
  sliderName?: IndicatorType;
  mode: ModeType;
}

const defaultProps = {
  sliderName: IndicatorType.SEASON_AGE,
};

export function SeasonAgeFilter(props: Props & typeof defaultProps) {
  const { mode, sliderName } = props;
  const dispatch = useDispatch();
  const slider = useSelector((state: RootState) =>
    selectSliderFilter(state, { mode, sliderName }),
  );

  if (!slider) return null;
  const { step, data, isByYear } = slider;
  const minMax =
    playerInitialValues.filterComponents.slider[sliderName]!.minMax;
  const yearMin = currentYear - minMax[1];
  const yearMax = currentYear - minMax[0];
  const minDistance = isByYear ? 0 : 1;
  const min = isByYear ? yearMin : minMax[0];
  const max = isByYear ? yearMax : minMax[1];

  const ageOptions = [
    {
      value: false,
      label: 'age',
      data: [min, max],
    },
    {
      value: true,
      label: 'year born',
      data: [yearMin, yearMax],
    },
  ];

  const renderValue = value => {
    if (isByYear) return value;
    if (value === max) return '>' + (value - 1);
    if (value === min) return '<' + (value + 1);
    return value;
  };

  const onSliderChanged = value => {
    dispatch(changeSliderVal(sliderName, value, mode));
  };

  const onSwitcherChange = option => {
    dispatch(
      changeSliderHelperValue(sliderName, { isByYear: option.value }, mode),
    );
    dispatch(
      changeSliderMinMax({
        name: sliderName,
        min: option.data[0],
        max: option.data[1],
      }),
    );
    onSliderChanged(option.data);
  };

  return (
    <div className="filters__slider">
      <h2>
        Filter by{' '}
        <TextSwitcherField
          options={ageOptions}
          value={ageOptions.find(option => option.value === isByYear)}
          onChange={onSwitcherChange}
        />
      </h2>
      <Slider
        value={data}
        min={slider.minMax[0]}
        max={slider.minMax[1]}
        step={step}
        minDistance={minDistance}
        onChanged={onSliderChanged}
        renderValue={renderValue}
      />
    </div>
  );
}

SeasonAgeFilter.defaultProps = defaultProps;
