import React, { useState } from 'react';
import { ListField } from '../fields/ListField';

import { ModeType } from '../../../../../types/Dict';
import {
  FlattenListItem,
  IndicatorType,
  ListType,
} from '../../../../../reducers/overview/types';
import { CompetitionSlider } from './CompetitionSlider';
import { selectListFilter } from '../../../../../reducers/overview/selectors';
import { selectCollapseList } from '../slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../types';
import { ListItem } from '../fields/ListField/ListItem';
import {
  addToFavoriteLeaguesList,
  changeListActive,
  removeFromFavoriteLeaguesList,
  setDomesticLeagues,
} from '../../../../../actions/overview';
import {
  addFavoriteLeague,
  deleteFavoriteLeague,
} from '../../../../../actions/favorite-leagues';
import { database } from '../../../../../constants/database';
import { Collapse } from '../Collapse';

interface Props {
  mode: ModeType;
}

export function LeaguesFilter(props: Props) {
  const { mode } = props;
  const dispatch = useDispatch();
  const [favoriteOpen, setFavoriteOpen] = useState<boolean>(true);

  const collapseList = useSelector((state: RootState) =>
    selectCollapseList(state, { mode, listName: ListType.LEAGUE_ID }),
  );
  const favoriteFilterList =
    useSelector((state: RootState) =>
      selectListFilter(state, { mode, listName: ListType.FAVORITE_LEAGUES }),
    )!.data || [];

  const favoriteListTitleHeight = 48;
  const favoriteListHeight =
    ((favoriteFilterList.length > 4 ? 4 : favoriteFilterList.length) + 1) * 36;
  const competitionLevelHeight = 70;
  const titleHeight = 30;
  const searchHeight = 38;
  const leaguesHeight =
    (collapseList?.height || 0) -
    favoriteListTitleHeight -
    favoriteListHeight -
    competitionLevelHeight -
    titleHeight -
    searchHeight;

  const getImageSource = (item: FlattenListItem) => {
    if (item.type !== 'item' || item.id === 'DOMESTICS') return undefined;
    return `${database.assets}leagues/${item.id}.png`;
  };

  const itemRenderer = listName => (item: FlattenListItem) => {
    const isFavorite = !!favoriteFilterList.find(
      option => option.id === item.id,
    );

    const onFavoriteChange = (active, { item }: { item: FlattenListItem }) => {
      if (active) {
        dispatch(addToFavoriteLeaguesList({ mode, listName, item }));
        dispatch(addFavoriteLeague(item.id));
      } else {
        dispatch(removeFromFavoriteLeaguesList({ mode, listName, item }));
        dispatch(deleteFavoriteLeague(item.id));
      }
    };

    const onItemClick = (item: FlattenListItem) => {
      if (item.id === 'DOMESTICS') {
        dispatch(setDomesticLeagues(mode));
        dispatch(changeListActive({ mode, listName, active: true, item }));
      } else {
        dispatch(
          changeListActive({ mode, listName, active: !item.active, item }),
        );
      }
    };

    return (
      <ListItem
        mode={mode}
        listName={listName}
        item={item}
        getImageSource={getImageSource}
        onItemClick={onItemClick}
      >
        {item.type === 'item' && item.id !== 'DOMESTICS' ? (
          <ListItem.Favorite active={isFavorite} onChange={onFavoriteChange} />
        ) : null}
      </ListItem>
    );
  };

  return (
    <div className="filter_list_long">
      <div className="filters__slider filters__slider--competition_level">
        <h2>Competition level</h2>
        <CompetitionSlider
          sliderName={IndicatorType.COMPETITION_LEVEL}
          mode={mode}
        />
      </div>
      <Collapse
        open={favoriteOpen}
        key="favorite_leagues"
        icon="icon-collapse"
        height={favoriteListHeight}
        mode={mode}
        name={ListType.FAVORITE_LEAGUES}
        onToggle={setFavoriteOpen}
      >
        <ListField
          mode={mode}
          height={favoriteListHeight}
          listName={ListType.FAVORITE_LEAGUES}
          itemRenderer={itemRenderer(ListType.FAVORITE_LEAGUES)}
        />
      </Collapse>

      <h3 className="list__subtitle">All leagues</h3>
      <ListField
        mode={mode}
        height={leaguesHeight}
        listName={ListType.LEAGUE_ID}
        withSearch
        itemRenderer={itemRenderer(ListType.LEAGUE_ID)}
      />
    </div>
  );
}
