import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import _ from 'lodash';

import { getColorFromScale } from '../../../helper';

import './analyse.scss';

import Image from '../../ui/image';
import Slider from '../../ui/slider';
import lang from '../../../lang';
import { database } from '../../../constants/database';

import { changeLeagueComparisonMatchdays } from '../../../actions/details';

class SquadAnalyse extends Component {
  constructor(props) {
    super(props);

    this._renderAnalyse = this._renderAnalyse.bind(this);
    this.setMatchdayRange = this.setMatchdayRange.bind(this);

    this.state = {
      range: [1, 38],
      value: [1, 38],
      rows: [
        ['season_rating', 'offence', 'defence', 'stability'],
        [
          'tackle_offence',
          'tackle_defence',
          'intercept_offence',
          'intercept_defence',
        ],
        ['blocked', 'save', 'aerial_offence', 'aerial_defence'],
        ['take_on', 'crossing', 'pass_short', 'pass_long'],
        ['corner', 'delivery', 'scoring', 'scoring_effort'],
      ],
    };
  }

  componentDidMount() {
    this.setMatchdayRange(this.props.details.matchdays_range);
  }

  componentWillReceiveProps(nextProps) {
    this.setMatchdayRange(nextProps.details.matchdays_range);
  }

  setMatchdayRange(matchdays_range) {
    if (
      matchdays_range &&
      (this.state.range[0] !== +matchdays_range.min ||
        this.state.range[1] !== +matchdays_range.max)
    ) {
      this.setState({
        range: [+matchdays_range.min, +matchdays_range.max],
        value: [+matchdays_range.min, +matchdays_range.max],
      });
    }
  }

  _renderAnalyse() {
    const { league_comparison, current_league, current_season, id, mean } =
      this.props.details;
    const { rows } = this.state;

    if (!league_comparison || !league_comparison.length) return null;

    const filterteams = _.filter(
      league_comparison,
      team => team.team_id && team.league_id == current_league,
    );

    const renderHeader = cols =>
      cols.map(col => (
        <div key={`header-${col}`} className="col-3">
          {lang[col]}
        </div>
      ));

    const renderteamIndicators = (team, row, col, i, team_index) => {
      let value = _.isNull(team[col]) ? null : parseFloat(team[col]);
      value && (value = value < 10 ? value : 10);
      value && (value = value > 0 ? value : 0);

      const meanValue = filterteams[0] ? parseFloat(mean[`${col}_mean`]) : 0;

      const color = getColorFromScale(value, meanValue);
      const style = !_.isNull(value) ? { backgroundColor: color } : {};
      const style_border = !_.isNull(value) ? { borderBottomColor: color } : {};

      return (
        <div
          key={`${team.team_id}-${col}-${i}`}
          className={`row ${team.team_id == id ? 'highlight' : ''}`}
        >
          <div
            className={`indicator ${_.isNull(value) ? 'gray' : ''} `}
            style={style_border}
          >
            <div
              className="indicator-percent"
              style={{ width: `${value * 10 || 0}%`, ...style }}
            />
            <span className="indicator-index">{team_index + 1}</span>
            <Link
              className={`name ${
                _.isNull(value) ? 'gray' : value < 3 ? 'bright' : 'dark'
              }`}
              to={`/details/team/${team.team_id}/${current_league}/${current_season}/summary`}
            >
              <Image
                src={
                  team.team_id
                    ? `${database.assets}teams/${team.team_id}.jpg`
                    : ''
                }
                aspectRatio={0.768}
              />{' '}
              {team.short_club_name}
            </Link>
            <div
              className={`indicator-value ${_.isNull(value) ? 'gray' : 'dark'}`}
            >
              {_.isNull(value) ? 'N/D' : value.toFixed(2)}
            </div>
          </div>
        </div>
      );
    };

    return rows.map((row, i) => (
      <div className="group" key={i}>
        <div className="group-header">
          <div className="row">
            {row.map(col => (
              <div key={`'line-'${col}`} className="col-3 middle-line">
                <div className="margin">
                  <div
                    className="line"
                    style={{
                      marginLeft: `${
                        filterteams[0]
                          ? parseFloat(mean[`${col}_mean`]) * 10
                          : 0
                      }%`,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="row">{renderHeader(row)}</div>
        </div>
        <div className="group-body">
          <div className="group-position row">
            {row.map(col => (
              <div key={`'row-'${col}`} className={`col-3 ${col}`}>
                {_.chain(_.clone(filterteams))
                  .orderBy(
                    team => (_.isNull(team[col]) ? -1 : +team[col]),
                    'desc',
                  )
                  .value()
                  .map((team, team_index) =>
                    renderteamIndicators(team, row, col, i, team_index),
                  )}
              </div>
            ))}
          </div>
        </div>
      </div>
    ));
  }

  _changeMatchdays(value) {
    const { current_season, current_formation, current_league, id } =
      this.props.details;
    if (this.state.value !== value) {
      this.props.changeLeagueComparisonMatchdays({
        range: value,
        season_id: current_season,
        formation_id: current_formation || 0,
        league_id: current_league,
        team_id: id,
      });
      this.setState({
        value,
      });
    }
  }

  render() {
    const { value, range } = this.state;
    const { isAccessGranted } = this.props.details;

    return (
      <div className={`${!isAccessGranted ? 'blurred' : ''}`}>
        <div className="analyse-slider pull-right">
          <div className="slider-on-white">
            <label>Filter by Matchdays</label>
            <Slider
              {...{
                defaultValue: range,
                value,
                min: range[0],
                max: range[1],
                step: 1,
                minDistance: 1,
                onChanged: this._changeMatchdays.bind(this),
              }}
            />
          </div>
        </div>
        <div className="h4">
          <span>ANALYSE ALL TEAMS IN THE LEAGUE BY INDICATOR</span>
          <span className="description">
            <span className="green_block">GREEN</span> FOR VALUES OVER LEAGUE
            AVERAGE
          </span>
        </div>
        <div className="league analyse">{this._renderAnalyse()}</div>
      </div>
    );
  }
}

export default connect(
  state => ({
    details: state.details,
    overview: state.overview,
  }),
  { changeLeagueComparisonMatchdays },
)(SquadAnalyse);
