import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Cookies from 'js-cookie';
import axios from 'axios';

import Select from '../../ui/select';
import Lang from '../../../lang';
import Icon from '../../ui/svg-icon';
import Confirm from '../../confirm';

import eventTracker from '../../../helper/event-tracker';
/*import { getFilteredDataRanks } from '../../../selectors';*/
import { loading } from '../../../actions/app';
import * as OverviewActions from '../../../actions/overview';
import {
  changeOverviewSettingsColor,
  changeOverviewSettingsRank,
} from '../../../actions/overview-rankings';
import PlayersRank from './players_rank';

import '../../ui/tooltip.scss';

import './players_positions_rankings.scss';
import { ListType } from '../../../reducers/overview/types';
import { ModeType } from '../../../types/Dict';

class OverviewPlayersPositionsRankings extends Component {
  static propTypes = {
    changeLayout: PropTypes.func,
    loadData: PropTypes.func,
    updateFiltersPositionsList: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      containerWidth: null,
      selectedPositions: {},
      exportLoading: false,
      report_name: '',
    };

    this._getClientSize = this._getClientSize.bind(this);
    this._windowResize = this._windowResize.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.updatePositionSelection = this.updatePositionSelection.bind(this);
  }

  componentDidMount() {
    if (this.containerRef) {
      this.setState({
        containerWidth: this.containerRef.getBoundingClientRect().width,
      });
    }

    const rankBy = Cookies.get('overview_rankBy');
    const colorBy = Cookies.get('overview_colorBy');

    if (rankBy) {
      this.props.changeOverviewSettingsRank(JSON.parse(rankBy));
    }
    if (colorBy) {
      this.props.changeOverviewSettingsColor(JSON.parse(colorBy));
    }
  }

  _getClientSize() {
    const navigationWidth = 62;
    const clientWidth =
      (document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.innerWidth) - navigationWidth;
    const clientHeight = document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.innerHeight;

    return { clientWidth, clientHeight };
  }

  _windowResize() {
    if (this.containerRef) {
      this.setState({
        containerWidth: this.containerRef.getBoundingClientRect().width,
      });
    }
  }

  exportPDF() {
    const { filteredData, settings, app, overview } = this.props;
    const { selectedPositions } = this.state;

    const upperPositions = _(app.positions)
      .filter(p => p.upper_position_id === 0)
      .value();
    const position_first_id =
      overview[ModeType.RANKINGS].filterComponents.list[
        ListType.POSITION_FIRST_ID
      ];
    const presetList =
      overview[ModeType.RANKINGS].filterComponents.list[ListType.PRESET_ID];
    const split_mode = _.get(position_first_id, 'mode', null);
    const preset = presetList.data.find(p => p.active === true);

    const finalData = {
      settings,
      selectedPositions, // uper : detailed, else 0 (all)
      split_mode,
      upper_positions: {},
      preset: preset ? preset.name : null,
      report_name: null,
      filename: null,
    };

    /* choose selectedPosition or 0 */

    /* collect all the data and reduce data set */
    upperPositions.map(upper_position => {
      const upper_position_data = _.get(
        filteredData,
        `dataByPositionGroups[${upper_position.id}]`,
        [],
      );

      if (!upper_position_data.length) {
        // hide empty positions
        return null;
      }

      const selectedPos = selectedPositions[upper_position.id] || 0;
      const posData =
        selectedPos === 0
          ? upper_position_data
          : _.get(filteredData, `dataByPosition[${selectedPos}]`, []);
      let second_score_min;
      const second_score_max = +(_.maxBy(posData, p => +p[settings.colorBy]) ||
        {})[settings.colorBy];
      if (settings.colorBy !== 'contract_expiry_year') {
        second_score_min = +(_.minBy(posData, p => +p[settings.colorBy]) || {})[
          settings.colorBy
        ];
      } else {
        second_score_min = +(_.minBy(
          posData.filter(p => p.contract_expiry_year > 0),
          p => +p[settings.colorBy],
        ) || {})[settings.colorBy];
      }

      finalData.upper_positions[upper_position.id] = {
        upper_position,
        data: posData.slice(0, 20),
        domain: [second_score_min, second_score_max],
      };
    });

    this.setState({ exportLoading: true });

    const text = (cancel, onCheck, isChecked, onInputChange, value) => {
      return (
        <div className="report-name-confirm">
          <h2 className="h2">Enter report name, if you want</h2>
          <input
            className="inp"
            type="text"
            name="report_name"
            value={value}
            onChange={onInputChange}
            placeholder="Enter report name"
          />
        </div>
      );
    };

    Confirm(text, {
      context: this,
      hideCancel: true,
      confirmText: 'Continue',
      report_name: this.state.report_name,
      onClose: () => {
        this.setState({ exportLoading: false });
        return true;
      },
    })
      .then(({ inputValue }) => {
        if (inputValue) {
          finalData.report_name = inputValue;
          finalData.filename = inputValue
            .replace(/[^a-z0-9]/gi, '_')
            .toLowerCase();
        }

        return axios.get('/file/token').then(result => {
          const { token } = result.data;
          axios
            .post(`/pdf/rankings/?token=${token}`, finalData, {
              responseType: 'arraybuffer',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
              },
            })
            .then(response => {
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' }),
              );
              const link = document.createElement('a');
              link.href = url;
              link.download = finalData.filename
                ? `${finalData.filename}.pdf`
                : 'player-rankings.pdf';
              link.click();
              setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(url);
                this.setState({ exportLoading: false });
              }, 100);
            });
        });
      })
      .catch(err => {
        alert(err.message);
      });
  }

  renderRanks(up) {
    const { filteredData, app, overview, settings } = this.props;
    const { containerWidth } = this.state;
    const upper_position = up;
    const positions = _(app.positions)
      .filter(p => p.upper_position_id === up.id)
      .value();

    const position_first_id =
      overview[ModeType.RANKINGS].filterComponents.list[
        ListType.POSITION_FIRST_ID
      ];
    const split_mode = _.get(position_first_id, 'mode', null);

    const data = {};
    const mean = {};

    const upper_position_data = _.get(
      filteredData,
      `dataByPositionGroups[${upper_position.id}]`,
      [],
    );
    const upper_position_mean = _.get(
      filteredData,
      `meanByPositionGroups[${upper_position.id}]`,
      {},
    );

    if (!upper_position_data.length) {
      // hide empty positions
      return null;
    }

    if (upper_position_data.length) {
      data[0] = upper_position_data;
      mean[0] = upper_position_mean;
      if (positions.length > 1) {
        for (let i = 0; i < positions.length; i++) {
          const pos = positions[i];
          data[pos.id] = _.get(filteredData, `dataByPosition[${pos.id}]`, []);
          mean[pos.id] = _.get(filteredData, `meanByPositions[${pos.id}]`, {});
        }
      }
    }

    return (
      <div className="player-rankings__rank-container" key={up.id}>
        <PlayersRank
          split_mode={split_mode}
          upperPosition={up}
          positions={positions}
          data={data}
          containerWidth={containerWidth}
          settings={settings}
          mean={mean}
          updatePositionSelection={this.updatePositionSelection}
        />
      </div>
    );
  }

  updatePositionSelection(position, sub_position) {
    this.setState({
      selectedPositions: {
        ...this.state.selectedPositions,
        [position]: sub_position,
      },
    });
  }

  renderFilters() {
    const {
      settings,
      changeOverviewSettingsRank,
      changeOverviewSettingsColor,
    } = this.props;

    const rankOptions = settings.indicatorsForRank.map(i => ({
      value: i,
      label: Lang[i],
    }));
    const valueRenderer = value => <span>{Lang[value.value]}</span>;
    const rankValues = settings.rankBy.map(i => ({ value: i, label: Lang[i] }));

    const colorOptions = settings.indicatorsForColor.map(i => ({
      value: i,
      label: Lang[i],
    }));
    const colorValue = {
      value: settings.colorBy,
      label: Lang[settings.colorBy],
    };

    return (
      <div className="player-rankings__filters">
        <div className="rank-filter">
          <label htmlFor="">
            Customize Rating
            <Icon
              name="info"
              data-tooltip-content="If several indicators are selected, the average value is shown"
              data-type="light"
              data-place="bottom"
              data-effect="solid"
              data-class="ranking-nav-tooltip"
            />
          </label>
          <Select
            options={rankOptions}
            value={rankValues}
            onChange={value => {
              changeOverviewSettingsRank(value.map(v => v.value));
              eventTracker.trackEvent(
                'Rankings',
                'Select indicator',
                value.length,
              );
            }}
            placeholder="Choose indicator to rank players"
            multi
            clearable={false}
            searchable
            valueRenderer={valueRenderer}
          />
        </div>
        <div className="color-filter">
          <label htmlFor="">Color</label>
          <Select
            options={colorOptions}
            value={colorValue}
            onChange={value => {
              changeOverviewSettingsColor(value.value);
              eventTracker.trackEvent('Rankings', 'Change color indicator');
            }}
            placeholder="Choose indicator to color players"
            clearable={false}
            searchable
            valueRenderer={valueRenderer}
          />
        </div>
        <div className="export">
          {this.state.exportLoading ? (
            <div className="inline-loader-container">
              <div className="loader-inline" />
              Preparing PDF...
            </div>
          ) : (
            <button className="btn-export" onClick={this.exportPDF}>
              <Icon name="download" className="pdf-export-icon" />
              Download PDF
            </button>
          )}
        </div>
        <ReactTooltip />
      </div>
    );
  }

  render() {
    const { app } = this.props;

    const { clientHeight } = this._getClientSize();
    const upperPositions = _(app.positions)
      .filter(p => p.upper_position_id === 0)
      .value();

    return (
      <Scrollbars style={{ height: `${clientHeight}px` }} autoHide>
        <div id="TooltipForRankingsContainer" />
        {this.renderFilters()}
        <div
          className="player-rankings__flex-container"
          ref={el => {
            this.containerRef = el;
          }}
        >
          {upperPositions.map(up => this.renderRanks(up))}
        </div>
      </Scrollbars>
    );
  }
}

export default connect(
  state => ({
    app: state.app,
    overview: state.overview,
    settings: state.overviewRankings.settings,
  }),
  {
    loading,
    ...OverviewActions,
    changeOverviewSettingsRank,
    changeOverviewSettingsColor,
  },
)(OverviewPlayersPositionsRankings);
