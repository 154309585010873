import React, { Component } from 'react';
import { Link } from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import './similar.scss';

// Load actions, own fcts and components
import lang from '../../../../lang';
import { DataItem } from '../../../ui/data-item';
import Image from '../../../ui/image';
import { createPaywallData } from '../../../../helper';

const SimilarItem = ({ id, assets }) => {
  const url = id ? `${assets}players/${id}.jpg` : '';

  return (
    <div>
      <Image src={url} aspectRatio={0.96} />
    </div>
  );
};

class Similar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sliced: true,
    };

    this.renderSimilar = this.renderSimilar.bind(this);
    this.toggleSlice = this.toggleSlice.bind(this);
  }

  toggleSlice(event) {
    event && event.preventDefault();

    const { sliced } = this.state;

    this.setState({ sliced: !sliced });

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  renderSimilar() {
    const { sliced } = this.state;
    const { similar, assets, toggleable, compare, sortBy, user } = this.props;

    const leagues = user.leagues || [];

    const similar_active = similar.map(s => {
      const isActive = leagues.includes(+s.league_id);
      return {
        ...s,
        isActive,
      };
    });
    const similar_sorted = _.orderBy(
      similar_active,
      sortBy ? ['isActive', sortBy] : ['isActive', 'score'],
      ['desc', 'desc'],
    );

    const renderPlayer = (player, key) => {
      const emblemUrl = player.team_id
        ? `${assets}teams/${player.team_id}.jpg`
        : '';

      const last6month = [
        moment().subtract(6, 'month').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
      ];

      const content = (
        <div className="row">
          <div className="col-4">
            <div className="playerProfile">
              <SimilarItem id={player.player_id} assets={assets} />
            </div>
            <div className="teamLogo">
              <Image src={emblemUrl} aspectRatio={0.769} />
            </div>
          </div>

          <div className="col-8">
            <div className="player_content">
              <span className="player_name">{player.short_name}</span>

              <div className="row indicators_group">
                <DataItem
                  className="col-4"
                  text={parseInt(player.age, 10)}
                  desc={lang.age.substring(0, 3)}
                />
                <DataItem
                  className="col-4"
                  text={player.preferred_foot || 'N/D'}
                  desc={lang.foot}
                />
                <DataItem
                  className="col-4"
                  text={createPaywallData(
                    player.isActive,
                    player.season_rating,
                  )}
                  desc={lang.rating.substring(0, 3)}
                />
              </div>

              <div className="row indicators_group">
                <DataItem
                  className="col-4"
                  text={player.season_mins}
                  desc={lang.mins.substring(0, 3)}
                />
                <DataItem
                  className="col-8"
                  text={createPaywallData(
                    player.isActive,
                    `${player.score_offence || 0} / ${
                      player.score_defence || 0
                    }`,
                  )}
                  desc={`${lang.offence.substring(
                    0,
                    3,
                  )} / ${lang.defence.substring(0, 3)} ${
                    lang.similarity_index_short
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
      );

      return (
        <div className="col-2_5 similar-player" key={key}>
          <Link
            className={`similar-link`}
            to={`/details/player/${`${player.player_id}/${
              player.league_id
            }/${last6month.join('/')}`}/summary`}
          >
            {content}
          </Link>
          {compare && player.isActive ? (
            <Link
              className="icon-compare"
              to={`/compare/player/${compare}/${`${player.player_id}-${player.league_id}-${last6month}`}`}
            />
          ) : null}
        </div>
      );
    };

    if (toggleable && similar_sorted.length > 5) {
      if (sliced) {
        return (
          <div className="row similar">
            {_.map(similar_sorted.slice(0, 4), (player, key) =>
              renderPlayer(player, key),
            )}
            <a
              className="col-2_5 similar-link"
              href="#"
              onClick={this.toggleSlice}
            >
              <span className="similar-toggle">
                <span>+</span>
                Show more
              </span>
            </a>
          </div>
        );
      }
      return _.map(
        _.chunk(similar_sorted.slice(0, 29), 5),
        (group, g, groups) => (
          <div className="row similar" key={g}>
            {_.map(group, (player, key) => {
              if (group.length === 5 && groups.length - 1 === g && key === 4)
                return null;
              return renderPlayer(player, key);
            })}
            {groups.length - 1 === g ? (
              <a
                className="col-2_5 similar-link"
                href="#"
                onClick={this.toggleSlice}
              >
                <span className="similar-toggle">
                  <span>–</span>
                  Show less
                </span>
              </a>
            ) : null}
          </div>
        ),
      );
    }
    return (
      <div className="row similar">
        {_.map(similar_sorted.slice(0, 5), (player, key) =>
          renderPlayer(player, key),
        )}
      </div>
    );
  }

  render() {
    const { mins, similar } = this.props;

    if (mins < 300 && !similar.length) {
      return (
        <div className="no-alternatives">
          No alternatives due to playing time under 300 mins
        </div>
      );
    }

    return <div>{this.renderSimilar()}</div>;
  }
}

export default connect(state => ({
  mins: _.get(state, 'details.performance.mins', null),
  user: state.auth.user,
}))(Similar);
