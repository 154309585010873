import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { AutoSizer } from 'react-virtualized';
import { batch, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Switch from 'react-switch';

import './competition-level-overview.scss';

import Select from '../../ui/select';
import Slider from '../../ui/slider';
import CompetitionLevelChart from './compeition-level-chart';
import { changeFilter } from '../../../actions/competition-level';
import { getLeagueOptions } from '../../../selectors/options';
import { getFilteredOverviewLeagues } from '../../../selectors/competition-level';
import Image from '../../ui/image';
import { database } from '../../../constants/database';

const CompetitionLevelOverview = props => {
  const { isOpen, onClose } = props;

  const dispatch = useDispatch();
  const [regionExpandedOptions, setRegionExpandedOptions] = useState([]);
  const [countryExpandedOptions, setCountryExpandedOptions] = useState([]);
  const { regions, countries, leagues } = useSelector(state => state.app);
  const {
    firstLeagueId,
    leagueIds,
    countryIds,
    regionIds,
    division,
    rating,
    stability,
    dynamicZoom,
  } = useSelector(state => state.competitionLevel.filter);
  const overviewLeagues = useSelector(
    state => state.competitionLevel.overviewLeagues,
  );
  const userLeague = useSelector(state => state.competitionLevel.userLeague);

  const leagueOptions = useSelector(getLeagueOptions);
  const points = useSelector(getFilteredOverviewLeagues);

  const leagueOptionsWithRegion = _.map(
    leagueOptions,
    ({ value, label, data }) => {
      const countryName = countries[data.country_id]?.country;

      return {
        value,
        label: [label, countryName].join(', '),
      };
    },
  );

  const leaguesByCountry = _.groupBy(
    leagueOptions.filter(({ data }) => data.country_id),
    ({ data }) => data.country_id,
  );
  const countriesByName = _.orderBy(Object.values(countries), 'country');
  const countriesByRegion = _.groupBy(
    countriesByName.filter(
      ({ id, region_id }) => region_id && leaguesByCountry[id],
    ),
    'region_id',
  );
  const countryOptions = _.orderBy(
    _.map(countriesByRegion, (regionCountries, regionId) => {
      const countryOptions = _.map(regionCountries, ({ id, country }) => ({
        label: country,
        value: +id,
        type: 'country',
        checked: countryIds.indexOf(+id) !== -1,
        expanded: countryExpandedOptions.indexOf(+id) !== -1,
        children: leaguesByCountry[id].map(option => ({
          ...option,
          checked:
            countryIds.indexOf(+id) !== -1 ||
            leagueIds.indexOf(option.value) !== -1,
          type: 'league',
        })),
      }));

      return {
        label: regions[regionId]?.name,
        value: +regionId,
        type: 'region',
        children: countryOptions,
        checked: regionIds.indexOf(+regionId) !== -1,
        expanded: regionExpandedOptions.indexOf(+regionId) !== -1,
      };
    }),
    'label',
  );

  const onChangeFilter = (name, option) => {
    dispatch(changeFilter(name, option?.value));
  };

  const onChangeCountry = (currentNode, selectedNodes) => {
    const leagueValues = [];
    let countryValues = [];
    const regionValues = [];

    for (let i = 0, l = selectedNodes.length; i < l; i++) {
      const option = selectedNodes[i];

      if (option.type === 'league') {
        leagueValues.push(option.value);
      }
      if (option.type === 'country') {
        countryValues.push(option.value);
      } else {
        regionValues.push(option.value);
        countryValues = [
          ...countryValues,
          ...Object.values(countries)
            .filter(({ region_id }) => region_id === option.value)
            .map(({ id }) => id),
        ];
      }
    }

    batch(() => {
      dispatch(changeFilter('leagueIds', leagueValues));
      dispatch(changeFilter('countryIds', countryValues));
      dispatch(changeFilter('regionIds', regionValues));
    });
  };

  const onNodeToggle = ({ value, expanded, type }) => {
    if (type === 'region') {
      setRegionExpandedOptions(
        expanded
          ? [...regionExpandedOptions, value]
          : regionExpandedOptions.filter(id => id !== value),
      );
    } else if (type === 'country') {
      setCountryExpandedOptions(
        expanded
          ? [...countryExpandedOptions, value]
          : countryExpandedOptions.filter(id => id !== value),
      );
    }
  };

  const getPointImageUrl = point => {
    if (!point) return null;
    const { country_id } = point;
    const countryCode = countries[country_id]?.abbreviation;

    return `${database.assets}countries/${countryCode}.jpg`;
  };

  const getPointFallbackImageUrl = point => {
    if (!point) return null;
    return `${database.assets}leagues/${point.id}.png`;
  };

  const getPointLabelText = (point, { isHint }) => {
    const { id, country_id, name } = point;

    if (!isHint) return name;

    const countryName = countries[country_id]?.country;
    const divisionLevel = leagues[id]?.divisionlevel;
    const divisionText =
      divisionLevel && divisionLevel !== 99 && `${divisionLevel} Division`;

    return [point.name, countryName, divisionText]
      .filter(text => text)
      .join(', ');
  };

  const overviewLeaguesById = _.keyBy(overviewLeagues, 'id');
  const firstLeague = overviewLeaguesById[firstLeagueId];
  const firstLeagueName = firstLeague?.name || '';
  const firstLeagueRating = +firstLeague?.rating?.toFixed(1) || '';
  const firstLeagueLogo = `${database.assets}leagues/${firstLeague?.id}.png`;

  const userLeagueName = userLeague?.name || '';
  const userLeagueRating = +userLeague?.rating?.toFixed(1);
  const userLeagueLogo = `${database.assets}leagues/${userLeague?.id}.png`;
  const showComparison =
    userLeague && userLeagueRating && firstLeagueRating !== userLeagueRating;

  const differenceText = showComparison ? (
    <>
      {'Competition level of '}
      <nobr>
        <Image src={firstLeagueLogo} aspectRatio={0.769} />
        {` ${firstLeagueName} (${firstLeagueRating})`}
      </nobr>
      {' is '}
      <b>{`${Math.abs(
        (userLeagueRating - firstLeagueRating).toFixed(1),
      )} points ${
        firstLeagueRating < userLeagueRating ? 'lower' : 'higher'
      }`}</b>
      {' on average than '}
      <nobr>
        <Image src={userLeagueLogo} aspectRatio={0.769} />
        {` ${userLeagueName} (${userLeagueRating})`}
      </nobr>
    </>
  ) : (
    <>
      {'Competition level of '}
      <nobr>
        <Image src={firstLeagueLogo} aspectRatio={0.769} />
        {` ${firstLeagueName} (${firstLeagueRating})`}
      </nobr>
    </>
  );

  return (
    <ReactModal
      isOpen={isOpen}
      className="competition-level-modal"
      overlayClassName="competition-level-modal-overlay"
    >
      <div className="competition-level-overview">
        <aside className="competition-level-sidebar">
          <form action="">
            <article className="competition-filter-group">
              <fieldset>
                <label className="fieldset-label">Competition</label>
                <Select
                  name="firstLeagueId"
                  options={leagueOptionsWithRegion}
                  onChange={value => onChangeFilter('firstLeagueId', value)}
                  value={_.find(leagueOptions, { value: firstLeagueId })}
                />
              </fieldset>
              <fieldset>
                <div className="fieldset-name">Compare to</div>
                <label className="fieldset-label">
                  Countries & Competition
                </label>
                <DropdownTreeSelect
                  data={countryOptions}
                  texts={{ placeholder: 'Select...' }}
                  onChange={onChangeCountry}
                  onNodeToggle={onNodeToggle}
                />
              </fieldset>
            </article>
            <article className="competition-filter-group">
              <fieldset>
                <label className="fieldset-label">Division</label>
                <Slider
                  value={division}
                  min={1}
                  max={5}
                  step={1}
                  minDistance={0}
                  onChanged={value => onChangeFilter('division', { value })}
                  renderValue={(value, index) =>
                    index === 1 && value === 5 ? '5+' : value
                  }
                />
              </fieldset>
              <fieldset>
                <label className="fieldset-label">Rating</label>
                <Slider
                  value={rating}
                  min={0}
                  max={10}
                  step={0.25}
                  onChanged={value => onChangeFilter('rating', { value })}
                />
              </fieldset>
              <fieldset>
                <label className="fieldset-label">Stability</label>
                <Slider
                  value={stability}
                  min={0}
                  max={10}
                  step={0.25}
                  onChanged={value => onChangeFilter('stability', { value })}
                />
              </fieldset>
              <fieldset>
                <Switch
                  className={`switch ${dynamicZoom ? 'active' : ''}`}
                  checked={dynamicZoom}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  handleDiameter={24}
                  width={40}
                  height={28}
                  offColor="#01BA8C"
                  onColor="#76D4BD"
                  onChange={value => onChangeFilter('dynamicZoom', { value })}
                />
                <label className="fieldset-label">Dynamic zoom</label>
              </fieldset>
            </article>
          </form>
          {firstLeagueId && userLeagueRating ? (
            <div className="difference-text">{differenceText}</div>
          ) : null}
          <div className="sidebar-link">
            <div className="link-desc">Read more about it:</div>
            <a
              href="https://matchmetrics.com/insights/how-can-i-compare-players-across-competitions-and-countries/"
              target="_blank"
              rel="noreferrer"
            >
              How can I compare players across competitions and countries?
            </a>
          </div>
        </aside>
        <div className="competition-level-content">
          <div className="competition-level-title">
            Explore competition level by matchmetrics
          </div>
          <button type="button" className="overview-close" onClick={onClose}>
            <div className="icon-close" />
          </button>
          <div className="competition-level-chart-container">
            <AutoSizer>
              {({ width, height }) => (
                <CompetitionLevelChart
                  width={width}
                  height={height}
                  points={points}
                  getPointImageUrl={getPointImageUrl}
                  getPointFallbackImageUrl={getPointFallbackImageUrl}
                  getPointLabelText={getPointLabelText}
                  useDynamicZoom={dynamicZoom}
                />
              )}
            </AutoSizer>
          </div>
        </div>
        <ReactTooltip place="bottom" type="light" className="label-popup" />
      </div>
    </ReactModal>
  );
};

CompetitionLevelOverview.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CompetitionLevelOverview;
