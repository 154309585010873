import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import { push } from 'react-router-redux';

import Icon from '../../ui/svg-icon';

import {
  closeShadowTab,
  copyShadowTeam,
  createShadowTeam,
  deleteShadowTeam,
  fetchShadowTeams,
  openShadowTab,
  updateShadowTeam,
} from '../../../actions/shadow-teams';

class Overview extends Component {
  constructor(props) {
    super(props);

    let onlyMyTeams = localStorage.getItem('shadowOnlyMyTeams');
    if (onlyMyTeams === null) {
      onlyMyTeams = true;
    }

    if (onlyMyTeams === 'false') {
      onlyMyTeams = false;
    }

    this.state = {
      onlyMyTeams,
      create: {
        formation: null,
        name: '',
        preset: 0,
        errors: {
          formation: false,
          name: false,
        },
      },
      edit: {
        id: null,
        formation: null,
        name: '',
        players: null,
        user_id: null,
        user: null,
        errors: {
          formation: false,
          name: false,
        },
      },
      createMode: false,
      editMode: false,
    };

    this.changeCreateValue = this.changeCreateValue.bind(this);
    this.createTeam = this.createTeam.bind(this);
    this.editTeam = this.editTeam.bind(this);
    this.resetEditMode = this.resetEditMode.bind(this);
    this.updateTeam = this.updateTeam.bind(this);
  }

  validate(type) {
    const object = _.cloneDeep(this.state[type]);
    let isValid = true;

    if (!object.name) {
      isValid = false;
      object.errors.name = true;
    }

    if (!object.formation) {
      isValid = false;
      object.errors.formation = true;
    }

    const newState = {};
    newState[type] = object;
    this.setState(newState);
    return isValid;
  }

  createTeam() {
    if (!this.validate('create')) {
      return;
    }

    this.props
      .createShadowTeam({
        name: this.state.create.name,
        formation: this.state.create.formation.value,
      })
      .then(result => {
        const { id } = result.payload.data;
        this.props.openShadowTab(id, this.props.user.id);
        this.props.push(`/favorites/shadow-teams/${id}`);
      });

    this.setState({
      createMode: false,
      create: {
        formationId: null,
        name: '',
        preset: 0,
        errors: {
          formation: false,
          name: false,
        },
      },
    });
  }

  updateTeam() {
    if (!this.validate('edit')) {
      return;
    }

    this.props.updateShadowTeam({
      name: this.state.edit.name,
      formation_id: this.state.edit.formation.value,
      players: this.state.edit.players || null,
      preset: this.state.edit.preset || 0,
      user_id: this.state.edit.user_id,
      user: this.state.edit.user,
      id: this.state.edit.id,
    });

    this.setState({
      editMode: false,
      edit: {
        id: null,
        formation: null,
        name: '',
        players: null,
        preset: null,
        user_id: null,
        user: null,
        errors: {
          formation: false,
          name: false,
        },
      },
    });
  }

  deleteTeam(id) {
    if (window.confirm('Are you sure want to delete this team?')) {
      this.props.deleteShadowTeam(id);
      this.props.closeShadowTab(id, this.props.user.id);
    }
  }

  copyTeam(id) {
    this.props.copyShadowTeam(id).then(result => {
      const { id } = result.payload.data;
      this.props.openShadowTab(id, this.props.user.id);
      this.props.push(`/favorites/shadow-teams/${id}`);
    });
  }

  editTeam(team) {
    this.setState({
      createMode: false,
      editMode: true,
      edit: {
        id: team.id,
        formation: {
          value: team.formation_id,
          label: this.getFormationName(team.formation_id),
        },
        name: team.name,
        players: team.players,
        user_id: team.user_id,
        preset: team.preset,
        user: team.user,
        errors: {
          formation: false,
          name: false,
        },
      },
    });
  }

  toggleCreateMode() {
    this.setState({ createMode: !this.state.createMode });
  }

  resetEditMode() {
    this.setState({
      editMode: false,
      edit: {
        id: null,
        formation: null,
        name: '',
        players: null,
        user: null,
        user_id: null,
        errors: {
          formation: false,
          name: false,
        },
      },
    });
  }

  getFormationName(formation_id) {
    const { formations } = this.props;
    return formations[formation_id].name;
  }

  changeCreateValue(value, input) {
    const create = _.cloneDeep(this.state.create);
    create[input] = value;
    create.errors[input] = false;

    this.setState({ create });
  }

  changeEditValue(value, input) {
    const edit = _.cloneDeep(this.state.edit);
    edit[input] = value;
    edit.errors[input] = false;

    this.setState({ edit });
  }

  renderCreateRow() {
    const { name, formation, errors } = this.state.create;
    const formationsOptions = [];

    _.forIn(this.props.formations, val => {
      if (val.id != 0 && val.id != 1) {
        formationsOptions.push({
          value: +val.id,
          label: val.name,
          s: val.name.replace(/-/gi, ''),
        });
      }
      return true;
    });

    return (
      <tr>
        <td>
          <input
            className={`${errors.name ? 'invalid' : ''}`}
            type="text"
            onChange={event =>
              this.changeCreateValue(event.target.value, 'name')
            }
            value={name}
            placeholder="Team name"
          />
        </td>
        <td colSpan="2">
          <Select
            options={formationsOptions}
            className={`${
              errors.formation
                ? 'styled-react-select invalid'
                : 'styled-react-select'
            }`}
            onChange={option => {
              if (!option) return;
              this.changeCreateValue(option, 'formation');
            }}
            filterOptions={(opts, f) =>
              opts.filter(
                o => o.label.indexOf(f) !== -1 || o.s.indexOf(f) !== -1,
              )
            }
            value={formation}
            placeholder="Formation"
          />
        </td>
        <td colSpan="2" className="shadow-team__edit-row-controls">
          <a className="green-btn" href="#" onClick={this.createTeam}>
            Add
          </a>
          <a
            className="red-btn"
            href="#"
            onClick={() => {
              this.setState({ createMode: false });
            }}
          >
            Cancel
          </a>
        </td>
        <td />
      </tr>
    );
  }

  renderCreateLink() {
    return (
      <span
        className="create-team"
        onClick={() => this.setState({ createMode: true })}
      >
        <Icon name="plus" className="create-icon" />
        Create a new shadow team
      </span>
    );
  }

  renderTeamRow(team, i) {
    const { editMode, edit, onlyMyTeams } = this.state;

    if (editMode && edit.id === team.id) {
      const { formation, name, errors, id } = edit;
      const formationsOptions = [];
      _.forIn(this.props.formations, val => {
        if (val.id != 0 && val.id != 1) {
          formationsOptions.push({
            value: +val.id,
            label: val.name,
            s: val.name.replace(/-/gi, ''),
          });
        }
        return true;
      });

      return (
        <tr key={`${team.id}_${i}`}>
          <td>
            <input
              className={`${errors.name ? 'invalid' : ''}`}
              type="text"
              onChange={event =>
                this.changeEditValue(event.target.value, 'name')
              }
              value={name}
              placeholder="Team name"
            />
          </td>
          <td colSpan="2">
            <Select
              options={formationsOptions}
              className={`${
                errors.formation
                  ? 'styled-react-select invalid'
                  : 'styled-react-select'
              }`}
              onChange={option => {
                if (!option) return;
                this.changeEditValue(option, 'formation');
              }}
              filterOptions={(opts, f) =>
                opts.filter(
                  o => o.label.indexOf(f) !== -1 || o.s.indexOf(f) !== -1,
                )
              }
              value={formation}
              placeholder="Formation"
            />
          </td>
          <td className="shadow-team__edit-row-controls" colSpan="2">
            <a className="green-btn" href="#" onClick={this.updateTeam}>
              Save
            </a>
            <a className="red-btn" href="#" onClick={this.resetEditMode}>
              Cancel
            </a>
          </td>
          <td />
        </tr>
      );
    }

    const { user } = this.props;

    return (
      <tr key={`${team.id}_${i}`}>
        <td>
          <Link
            to={`/favorites/shadow-teams/${team.id}`}
            onClick={() => {
              this.props.openShadowTab(team.id, user.id);
            }}
          >
            {team.name}
          </Link>
          {user.role.name !== 'User' && !onlyMyTeams ? (
            <span className="team-author">
              <br />
              by {`${team.user.firstName} ${team.user.lastName}`}
            </span>
          ) : null}
        </td>
        <td>{this.getFormationName(team.formation_id)}</td>
        <td>{moment(team.createdAt).format('YYYY-MM-DD')}</td>
        <td>{moment(team.updatedAt).format('YYYY-MM-DD')}</td>
        <td>
          <span className="shadow-team__table-controls">
            <span
              className="control-btn"
              onClick={() => {
                this.editTeam(team);
              }}
            >
              <Icon name="edit" />
            </span>
            <span className="control-btn">
              <Icon
                name="copy"
                onClick={() => {
                  this.copyTeam(team.id);
                }}
              />
            </span>
            <span
              className="control-btn"
              onClick={() => {
                this.deleteTeam(team.id);
              }}
            >
              <Icon name="delete" />
            </span>
          </span>
        </td>
        <td />
      </tr>
    );
  }

  renderTeamsTable() {
    const { shadowTeams, user } = this.props;
    const { onlyMyTeams } = this.state;

    const shadowTeamsFiltered = onlyMyTeams
      ? shadowTeams.filter(st => +st.user_id === +user.id)
      : shadowTeams;

    return (
      <table className="shadow-team__table">
        <colgroup>
          <col className="col__name" />
          <col className="col__formation" />
          <col className="col__date" />
          <col className="col__date" />
          <col className="col__controls" />
        </colgroup>
        <thead>
          <tr>
            <th>Name</th>
            <th>Formation</th>
            <th>Date created</th>
            <th>Date updated</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {shadowTeamsFiltered.map((team, i) => this.renderTeamRow(team, i))}
          {this.state.createMode ? this.renderCreateRow() : null}
        </tbody>
      </table>
    );
  }

  render() {
    const { shadowTeams, user } = this.props;

    const { onlyMyTeams } = this.state;
    const shadowTeamsFiltered = onlyMyTeams
      ? shadowTeams.filter(st => +st.user_id === +user.id)
      : shadowTeams;

    return (
      <div>
        <div className="h4">
          <span>All {shadowTeamsFiltered.length} shadow teams</span>
          <span className="description">
            {+user.role.id === 2 || +user.role.id === 3 ? (
              <div className="group-check">
                <label
                  onClick={() => {
                    localStorage.setItem(
                      'shadowOnlyMyTeams',
                      !this.state.onlyMyTeams,
                    );
                    this.setState({ onlyMyTeams: !this.state.onlyMyTeams });
                  }}
                  className="checked"
                >
                  <span className="checkbox">
                    {!this.state.onlyMyTeams ? (
                      <span className="icon-check" />
                    ) : null}
                  </span>
                  Show group teams, too.
                </label>
              </div>
            ) : null}
          </span>
        </div>

        {this.renderTeamsTable()}

        {!this.state.createMode ? this.renderCreateLink() : null}
      </div>
    );
  }
}

export default connect(
  state => {
    return {
      formations: state.app.formations,
      shadowTeams: state.shadowTeams,
      user: state.auth.user,
    };
  },
  {
    fetchShadowTeams,
    copyShadowTeam,
    deleteShadowTeam,
    updateShadowTeam,
    createShadowTeam,
    openShadowTab,
    closeShadowTab,
    push,
  },
)(Overview);
