import React from 'react';
import Icon from '../../../../ui/svg-icon';

interface Props {
  option: {
    label: string;
    value: any;
  };
  active: boolean;
  onChange: (option) => void;
}

export function Option(props: Props) {
  const { option, active, onChange } = props;
  const { label } = option;

  const onClick = () => {
    onChange(option);
  };

  return (
    <a
      href="#"
      className={`switch ${active ? 'active' : ''}`}
      onClick={onClick}
    >
      {active ? (
        <Icon name="overview-radio-checked" className="of-radio-checked" />
      ) : (
        <Icon name="overview-radio-unchecked" className="of-radio-unchecked" />
      )}
      {label}
    </a>
  );
}
