import axios from 'axios';

import {
  ADD_FILTER_PRESET,
  FETCH_FILTER_PRESETS,
  REMOVE_FILTER_PRESET,
  SET_FILTER_PRESET,
} from '../constants/index';
import eventTracker from '../helper/event-tracker';

export const fetchFilterPreset = () => {
  const request = axios.get('/presets/');

  return {
    type: FETCH_FILTER_PRESETS,
    payload: request,
  };
};

export const createFilterPreset = preset => {
  const request = axios.post('/presets/', {
    preset: { ...preset, preset: JSON.stringify(preset.preset) },
  });

  eventTracker.trackEvent('Overview Filter', 'Create preset');
  return {
    type: ADD_FILTER_PRESET,
    payload: request,
  };
};

export const deleteFilterPreset = (id, mode) => {
  axios.delete(`/presets/${id}`);

  eventTracker.trackEvent('Overview Filter', 'Remove preset');
  return {
    type: REMOVE_FILTER_PRESET,
    payload: id,
    mode,
  };
};

export const setFilterPreset = preset => {
  eventTracker.trackEvent('Overview filter', 'Use preset');
  return {
    type: SET_FILTER_PRESET,
    payload: preset,
  };
};
