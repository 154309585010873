import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Image from '../../ui/image';
import { DataItem } from '../../ui/data-item';
import lang from '../../../lang';

import { setActivePointData } from '../../../actions/overview';
import { database } from '../../../constants/database';

class Tooltip extends Component {
  static propTypes = {
    setActivePointData: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    const { setActivePointData } = this.props;

    setActivePointData({
      mode: 'team',
      data: null,
    });
  }

  render() {
    const { activePointData, filteredData } = this.props;
    const { teams, leagues } = this.props;
    const activeTeam = activePointData.team;

    if (!activeTeam) return null;

    const { team_id, league_id } = activeTeam;

    const team = teams[team_id];
    const league = leagues[league_id];

    const url = team_id ? `${database.assets}teams/${team_id}.jpg` : '';
    const emblemUrl = league_id
      ? `${database.assets}leagues/${league_id}.png`
      : '';

    const team_name = team
      ? team.team_name
          .split(' ')
          .map(text => (text.length > 12 ? `${text.slice(0, 12)}.` : text))
          .join(' ')
      : '';

    const item = filteredData.find(
      i => i.league_id == league_id && i.team_id == team_id,
    );

    if (!item) return null;

    return (
      <div>
        <div className="row">
          <div className="col-5 noSpacing">
            <Image src={url} aspectRatio={0.769} backgroundColor="white" />
          </div>

          <div className="col-7">
            <div className="tooltip__content_header">
              <div className="tooltip__content_header-container">
                <div className="btn" onClick={() => this.onClose()}>
                  <div className="icon-close" />
                </div>
              </div>
            </div>

            <div className="row tooltip__content_name">
              <div className="lastname">{team_name}</div>
              <div className="team">
                {league ? <Image src={emblemUrl} aspectRatio={0.769} /> : ''}{' '}
                {league ? league.name : ''}
              </div>
            </div>

            <div className="row tooltip__content_stats">
              <DataItem
                reverse
                text={item.season_rating}
                desc={lang.rating.substring(0, 3)}
              />
              <DataItem
                reverse
                text={item.defence}
                desc={lang.defence.substring(0, 3)}
              />
              <DataItem
                reverse
                text={item.offence}
                desc={lang.offence.substring(0, 3)}
              />
            </div>
          </div>
        </div>

        <div className="row tooltip__content_actionbar">
          <Link
            to={`/details/team/${`${team_id}/${item.league_id}/${item.season_id}`}/summary`}
            className="col-6 callAction"
            onClick={this.onClose}
          >
            <span className="icon-more" />
            {lang.details}
          </Link>
          <Link
            to={`/compare/team/${`${team_id}-${item.league_id}-${item.season_id}`}`}
            className="col-6 callAction"
            onClick={this.onClose}
          >
            <span className="icon-compare" />
            {lang.compare}
          </Link>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    teams: state.app.teams,
    leagues: state.app.leagues,
    positions: state.app.positions,
    season_current: state.app.season_current,
    playersMetaData: state.app.playersMetaData,
    filterComponents: state.overview.player,
    favorites: state.app.favorites,
    activePointData: state.overview.activePointData,
  }),
  { setActivePointData },
)(Tooltip);
