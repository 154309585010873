import React, { SyntheticEvent, useCallback, useState } from 'react';

interface Props {
  src: string;
  fallback: string;
  dataTip: string;
}

export const Image = React.memo((props: Props) => {
  const { src, fallback, dataTip } = props;
  const [useFallback, setUseFallback] = useState(false);

  const width = 46;
  const height = 28;

  const onImageError = useCallback((event: SyntheticEvent<SVGImageElement>) => {
    setUseFallback(true);
  }, []);

  return (
    <image
      stroke="2"
      href={useFallback ? fallback : src}
      x={0}
      y={0}
      width={width}
      height={height}
      transform={`translate(-${width / 2}, -${height / 2})`}
      data-tooltip-content={dataTip}
      onError={onImageError}
    />
  );
});
