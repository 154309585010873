import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { ScatterPlot } from 'd3-react-touch';

import './history.scss';

import lang from '../../../lang';
import Image from '../../ui/image';
import { DataItem } from '../../ui/data-item';
import NoData from '../layouts/no-data';
import Icon from '../../ui/svg-icon';
import SeasonTable from './history/seasons_table';
import { database } from '../../../constants/database';

import { parseMatchGoals } from '../../../helper/goals';

const moment = extendMoment(Moment);

class History extends Component {
  static propTypes = {
    details: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.renderLineChart = this.renderLineChart.bind(this);
    this.renderCareer = this.renderCareer.bind(this);
  }

  renderLineChart() {
    const {
      current_season,
      current_league,
      history_details,
      current_position,
    } = this.props.details;
    const { leagues, teams, positions, seasons } = this.props.app;

    const width =
      (document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.innerWidth) * 0.95;

    const timeline_vals = ['rating', 'defence', 'offence', 'mean'];

    const timeline = _.orderBy(
      history_details,
      matchday => {
        return moment(matchday.date, 'YYYY-MM-DD');
      },
      'asc',
    );

    const team_name = !_.isEmpty(timeline)
      ? _.get(teams, `${timeline[0].team_id}.team_name`, '')
      : '';
    const league_name = !_.isEmpty(timeline)
      ? _.get(leagues, `${timeline[0].league_id}.name`, '')
      : '';
    const league = _.get(leagues, current_league, {});

    const season_name =
      current_season.length === 1
        ? current_season[0]
        : current_season
            .map(seasonId =>
              _.get(
                lang,
                `_${seasonId}`,
                moment(seasonId, 'YYYY-MM-DD').format('D MMM ’YY'),
              ),
            )
            .join(' – ');

    const differenceBetweenDates = moment(current_season[1]).diff(
      current_season[0],
      'months',
    );
    let xAxisLength = 0;
    let newScatterData = [];

    switch (+current_season) {
      case 1000:
        xAxisLength = 5;
        break;
      case 1001:
        xAxisLength = 10;
        break;
      case 1002:
        xAxisLength = 20;
        break;
      default:
        xAxisLength = _.get(leagues, `${current_league}.matchday`, 1);
    }

    if (current_season.length === 2 && differenceBetweenDates > 12) {
      let months = Array.from(
        moment
          .range(current_season[0], moment().format('YYYY-MM-DD'))
          .by('month'),
      ).map(m => m.format('YYYY-MM'));
      const historyByMonths = _.groupBy(history_details, r => {
        return Moment(r.date).format('YYYY-MM');
      });

      months = months.filter(
        m => historyByMonths[m] && historyByMonths[m].length,
      );

      newScatterData = _.chain(months)
        .map(month => {
          return timeline_vals.map((indicator, indicatorIndex) => {
            const monthLabel = moment(`${month}-01`).format('MMM ’YY');

            if (!historyByMonths[month]) {
              return {
                x: `${monthLabel}`,
                y: indicator === 'mean' ? 5 : undefined,
                c: indicatorIndex,
                link: (
                  <Link
                    to="#"
                    className="inactive-link"
                    style={{ opacity: 0.5 }}
                  />
                ),
              };
            }

            const mins = _.sumBy(historyByMonths[month], 'mins');
            const games = _.sumBy(historyByMonths[month], 'games');
            const indicatorAvg =
              indicator === 'mean'
                ? 5
                : _.meanBy(historyByMonths[month], indicator);

            return {
              x: `${monthLabel}, ${games} ${
                games > 1 ? 'matches' : 'match'
              }, ${mins}'`,
              y: indicatorAvg,
              c: indicatorIndex,
              r: indicator === 'mean' ? 0 : 2,
              link: (
                <Link
                  to="#"
                  className="inactive-link"
                  style={{ opacity: 0.5 }}
                />
              ),
            };
          });
        })
        .flatten()
        .value();
    } else {
      let ordered_details = history_details.map(hd => ({
        ...hd,
        date: new Date(hd.date),
      }));
      ordered_details = _.orderBy(ordered_details, 'date', 'asc');

      const historyByMatchDay = _.keyBy(ordered_details, 'matchday');
      let byMD = true;

      if (ordered_details.length > xAxisLength) {
        xAxisLength = ordered_details.length;
        byMD = false;
      }

      newScatterData = _.chain(xAxisLength)
        .range()
        .map(matchIndex => {
          const matchday = matchIndex + 1;
          let match;

          if (byMD) {
            match = historyByMatchDay[matchday];
          } else {
            match = ordered_details[matchIndex];
          }

          if (!match) {
            return timeline_vals.map((indicator, indicatorIndex) => ({
              x: `${matchday}`,
              y: indicator === 'mean' ? 5 : undefined,
              c: indicatorIndex,
              link: (
                <Link
                  to="#"
                  className="inactive-link"
                  style={{ opacity: 0.5 }}
                />
              ),
              matchday,
            }));
          }

          const { mins, match_id, league_id, season_id, position_id_detail } =
            match;
          const { homegoals, awaygoals, fullText } = parseMatchGoals(match);

          const place = match.place === 0 ? 'H' : 'A';
          const teamName = _.get(
            teams,
            `${match.opponent_id}.short_club_name`,
            '',
          );
          const positionName = _.get(
            positions,
            `${match.position_id_detail}.position_name`,
            '',
          );

          const goalsClassName =
            homegoals === awaygoals
              ? 'blue'
              : place === 'H'
              ? homegoals > awaygoals
                ? 'green'
                : 'red'
              : homegoals > awaygoals
              ? 'red'
              : 'green';

          const goals =
            (homegoals || +homegoals === 0) &&
            (awaygoals || +awaygoals === 0) ? (
              <tspan className={goalsClassName}>{fullText}</tspan>
            ) : null;

          const linkOpacity =
            (+current_position && +current_position !== +position_id_detail) ||
            !+position_id_detail
              ? '.5'
              : '1';
          const link = (
            <Link
              style={{ opacity: linkOpacity }}
              to={`/details/match/${match_id}/${league_id}/${season_id}/summary`}
            />
          );

          let title = `${
            byMD ? matchday : match.matchday + 1
          }. ${place} – ${teamName}, ${positionName} ${mins}'${
            goals ? ', ' : ''
          }`;

          if (league.format_id !== 1) {
            title = `${Moment(match.date).format(
              'YYYY-MM-DD',
            )}. ${place} – ${teamName}, ${positionName} ${mins}'${
              goals ? ', ' : ''
            }`;
          }

          return timeline_vals.map((indicator, indicatorIndex) => ({
            x: title,
            y:
              indicator === 'mean'
                ? 5
                : typeof match[indicator] !== 'number'
                ? undefined
                : match[indicator],
            c: indicatorIndex,
            r: indicator === 'mean' ? 0 : 2,
            link,
            goals,
            matchday,
          }));
        })
        .flatten()
        .orderBy(['c', 'matchday'], ['asc', 'asc'])
        .value();
    }

    return (
      <div>
        <h3>
          {season_name}, {league_name}, {team_name}
        </h3>

        <div className="legend">
          <DataItem className="rating" text desc={lang.rating} />
          <DataItem className="defence" text desc={lang.defence} />
          <DataItem className="offence" text desc={lang.offence} />
          <DataItem className="mean" text desc={lang.rating_mean} />
        </div>

        <ScatterPlot
          yDomain={[0, 10.1]}
          rDomain={[0, 2]}
          cDomain={[0, 1, 2, 3]}
          margin={{
            top: 30,
            right: 120,
            bottom: 180,
            left: 40,
          }}
          id="id"
          width={width}
          xVal="x"
          yVal="y"
          cVal="c"
          rVal="r"
          cRange={['#1D9879', '#9f51c0', '#efa32e', '#d9d9d9']}
          rRange={[0, 4]}
          data={newScatterData}
          height={380}
          yGrid={false}
          xScale="ordinal"
          xTicksRotate
          yticks={[10]}
          yDomainNice={false}
          connectDots
          zooming={false}
          connectDotsInterpolationType="monotone"
          clicking={false}
          checkXDomainWhenRender={false}
        />

        <div className="axis_label">
          <div className="axisLeft">{lang.rating}</div>
        </div>
      </div>
    );
  }

  renderCareer() {
    const { history } = this.props.details;
    const { leagues, teams, seasons } = this.props.app;

    const history_seasons = _.chain(history)
      .cloneDeep()
      .filter(datum => datum.season_id > 2000)
      .orderBy(
        [h => h.season_id, h => leagues[h.league_id].format_id],
        ['desc', 'asc'],
      )
      .value();

    return history_seasons.map((row, i) => {
      const url = row.team_id
        ? `${database.assets}teams/${row.team_id}.jpg`
        : '';
      const urlComp = row.league_id
        ? `${database.assets}leagues/${row.league_id}.png`
        : '';
      const {
        team_id,
        league_id,
        games,
        mins,
        goals,
        assists,
        rating,
        yellow,
        red,
      } = row;

      const team_name = _.get(teams, `${team_id}.team_name`, '');
      const league_name = _.get(leagues, `${league_id}.name`, '');

      const season = _.find(seasons, { id: row.season_id });

      return (
        <div className="bar_chart_group row" key={i}>
          <DataItem
            className="col-1_5"
            text={_.get(season, 'name', '–')}
            desc={lang.season}
          />
          <DataItem className="col-2_5" desc={lang.team_name}>
            <Link to={`/details/team/${team_id}/summary`} className="data-text">
              {url ? <Image src={url} aspectRatio={0.769} /> : ''} {team_name}
            </Link>
          </DataItem>
          <DataItem className="col-2" desc={lang.league}>
            <span className="data-text">
              {urlComp ? <Image src={urlComp} aspectRatio={0.769} /> : ''}{' '}
              {league_name}
            </span>
          </DataItem>
          <DataItem className="col-0_75" text={games} desc={lang.games} />
          <DataItem className="col-0_75" text={mins} desc={lang.mins} />
          <DataItem className="col-0_75" text={goals} desc={lang.goals} />
          <DataItem className="col-0_75" text={assists} desc={lang.assists} />
          <DataItem
            className="col-0_75"
            text={yellow}
            desc={lang.yellow_cards}
          />
          <DataItem className="col-0_75" text={red} desc={lang.red_cards} />
          <DataItem className="col-0_75" text={rating} desc={lang.rating} />
        </div>
      );
    });
  }

  render() {
    const { history_details, history, isAccessGranted } = this.props.details;

    return (
      <div className={`history ${!isAccessGranted ? 'blurred' : ''}`}>
        {history_details.length ? (
          <div
            className="content_group history_overtime"
            ref="history_overtime"
          >
            <div className="h4">
              <Icon name="ranking-winner" width="33px" height="28px" />
              <span>{lang.ownstatsovertime}</span>
              <span className="description">{lang.history_description}</span>
            </div>
            {this.renderLineChart()}
          </div>
        ) : (
          <NoData />
        )}

        <SeasonTable updateHeight={this.props.updateHeight} />
      </div>
    );
  }
}

export default connect(state => ({
  details: state.details,
  app: state.app,
  overview: state.overview,
}))(History);
