import React from 'react';
import lang from '../../../../../lang';
import { CheckboxField } from '../fields/CheckboxField';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../types';
import { selectSwitcherFilter } from '../../../../../reducers/overview/selectors';
import { ModeType } from '../../../../../types/Dict';
import { SwitcherType } from '../../../../../reducers/overview/types';
import { toggleFilterSwitcher } from '../../../../../actions/overview';

interface Props {
  mode: ModeType;
}

export function TransferredFilter(props: Props) {
  const { mode } = props;
  const dispatch = useDispatch();
  const switcherName = SwitcherType.HIDE_TRANSFERRED;
  const transferredSwitcher = useSelector((state: RootState) =>
    selectSwitcherFilter(state, { mode, switcherName }),
  );

  const onChange = (name, checked) => {
    dispatch(toggleFilterSwitcher({ switcherName, value: checked, mode }));
  };

  return (
    <CheckboxField
      name="hidetransfered"
      checked={transferredSwitcher?.data || false}
      onChange={onChange}
    >
      {lang['at_current_club']}
    </CheckboxField>
  );
}
