import './footer.scss';

import React from 'react';
import { useDispatch } from 'react-redux';
import { browserHistory, Link } from 'react-router';

import Icon from '../../../../ui/svg-icon';

import { logout } from '../../../../../actions/auth';
import { ModeType } from '../../../../../types/Dict';

interface Props {
  mode: ModeType;
}

export function Footer(props: Props) {
  const { mode } = props;
  const dispatch = useDispatch();

  const onLogout = event => {
    event.preventDefault();

    dispatch(logout());
    browserHistory.push('/auth/login');
  };

  return (
    <div className="footer-container">
      <div className="container">
        <a />
        <a />
        <Link to={`/overview/${mode}`}>
          <Icon name="logo" width="23px" height="32px" />
        </Link>
        <a className="footer-photo" />
        <a onClick={onLogout} className="footer-logout">
          <Icon name="logout" width="19px" height="20px" />
        </a>
      </div>
    </div>
  );
}
