import {
  IndicatorType,
  ListType,
  ModeState,
  PlayerListsId,
  PlayerSlidersId,
  SwitcherType,
} from '../types';

const nowYear = new Date().getFullYear();

export const player: ModeState = {
  layouts: {
    contentWidth: null,
    visualHeight: null,
    tableHeight: 0,
    sidebarShow: true,
    tableVisible: 'half',
    initialTableShow: true,
  },
  scatterplot: {
    xVal: IndicatorType.DEFENCE,
    yVal: IndicatorType.OFFENCE,
    rVal: IndicatorType.SEASON_MINS,
    cVal: IndicatorType.SEASON_RATING,
    cRange: [
      '#530154',
      '#462973',
      '#433d80',
      '#435284',
      '#3f6687',
      '#367b8a',
      '#278e8c',
      '#3aa183',
      '#50b276',
      '#69c364',
    ],
    xDomain: null,
    yDomain: null,
    rDomain: null,
    cDomain: null,
    lassoSelection: null,
    legendList: [
      IndicatorType.SEASON_RATING,
      IndicatorType.OFFENCE,
      IndicatorType.DEFENCE,
      IndicatorType.STABILITY,
      IndicatorType.CONTRIBUTION_DEFENCE,
      IndicatorType.CONTRIBUTION_OFFENCE,
      IndicatorType.CONTRIBUTION_PASS,
      IndicatorType.TACKLE_OFFENCE,
      IndicatorType.TACKLE_DEFENCE,
      IndicatorType.INTERCEPT_OFFENCE,
      IndicatorType.INTERCEPT_DEFENCE,
      IndicatorType.BLOCKED,
      IndicatorType.SAVE,
      IndicatorType.AERIAL_OFFENCE,
      IndicatorType.AERIAL_DEFENCE,
      IndicatorType.TAKE_ON,
      IndicatorType.CROSSING,
      IndicatorType.PASS_SHORT,
      IndicatorType.PASS_LONG,
      IndicatorType.RECEPTION,
      IndicatorType.DELIVERY,
      IndicatorType.SCORING,
      IndicatorType.SCORING_EFFORT,
      IndicatorType.SEASON_MINS,
      IndicatorType.SEASON_AGE,
      IndicatorType.MARKET_VALUE,
      IndicatorType.CONTRACT_EXPIRY_YEAR,
    ],
  },
  table: {
    sortBy: IndicatorType.SEASON_RATING,
    sortDirection: 'DESC',
    sortedData: null,
    xVal: IndicatorType.DEFENCE,
    yVal: IndicatorType.OFFENCE,
    rVal: IndicatorType.SEASON_MINS,
    cVal: IndicatorType.SEASON_RATING,
  },
  filterComponents: {
    filterLoaded: false,
    preset: false,
    changeFilter: {
      slider: [IndicatorType.SEASON_MINS, IndicatorType.CONTRACT_EXPIRY], // filter data for 250 min minutes at load
      list: [
        ListType.LEAGUE_ID,
        ListType.POSITION_FIRST_ID,
        ListType.FORMATION_ID,
      ], // filter out international leagues
    },
    switcher: {
      [SwitcherType.FAVORITES]: { name: SwitcherType.FAVORITES, data: false },
      [SwitcherType.HIDE_TRANSFERRED]: {
        name: SwitcherType.HIDE_TRANSFERRED,
        data: false,
        hidden: true,
      }, // hide switch to appear on top
    },
    slider: {
      [IndicatorType.SEASON_AGE]: {
        id: PlayerSlidersId[IndicatorType.SEASON_AGE],
        name: IndicatorType.SEASON_AGE,
        data: [14, 36],
        minMax: [14, 36],
        step: 1,
        isByYear: false,
      },
      [IndicatorType.SEASON_MINS]: {
        id: PlayerSlidersId[IndicatorType.SEASON_MINS],
        name: IndicatorType.SEASON_MINS,
        data: [250, 4500],
        minMax: [20, 4500],
        step: 1,
      },
      [IndicatorType.CONTRACT_EXPIRY]: {
        id: PlayerSlidersId[IndicatorType.CONTRACT_EXPIRY],
        name: IndicatorType.CONTRACT_EXPIRY,
        data: [nowYear - 1, nowYear + 6],
        minMax: [nowYear - 1, nowYear + 6],
        step: 1,
        include_missing_data: true,
      },
      [IndicatorType.MARKET_VALUE]: {
        id: PlayerSlidersId[IndicatorType.MARKET_VALUE],
        name: IndicatorType.MARKET_VALUE,
        data: [0, 180],
        minMax: [0, 180],
        step: 1,
      },
      [IndicatorType.SEASON_RATING]: {
        id: PlayerSlidersId[IndicatorType.SEASON_RATING],
        name: IndicatorType.SEASON_RATING,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.STABILITY]: {
        id: PlayerSlidersId[IndicatorType.STABILITY],
        name: IndicatorType.STABILITY,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.DEFENCE]: {
        id: PlayerSlidersId[IndicatorType.DEFENCE],
        name: IndicatorType.DEFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.CONTRIBUTION_DEFENCE]: {
        id: PlayerSlidersId[IndicatorType.CONTRIBUTION_DEFENCE],
        name: IndicatorType.CONTRIBUTION_DEFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.CONTRIBUTION_OFFENCE]: {
        id: PlayerSlidersId[IndicatorType.CONTRIBUTION_OFFENCE],
        name: IndicatorType.CONTRIBUTION_OFFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.CONTRIBUTION_PASS]: {
        id: PlayerSlidersId[IndicatorType.CONTRIBUTION_PASS],
        name: IndicatorType.CONTRIBUTION_PASS,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.OFFENCE]: {
        id: PlayerSlidersId[IndicatorType.OFFENCE],
        name: IndicatorType.OFFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.TACKLE_DEFENCE]: {
        id: PlayerSlidersId[IndicatorType.TACKLE_DEFENCE],
        name: IndicatorType.TACKLE_DEFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.TACKLE_OFFENCE]: {
        id: PlayerSlidersId[IndicatorType.TACKLE_OFFENCE],
        name: IndicatorType.TACKLE_OFFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.INTERCEPT_DEFENCE]: {
        id: PlayerSlidersId[IndicatorType.INTERCEPT_DEFENCE],
        name: IndicatorType.INTERCEPT_DEFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.INTERCEPT_OFFENCE]: {
        id: PlayerSlidersId[IndicatorType.INTERCEPT_OFFENCE],
        name: IndicatorType.INTERCEPT_OFFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.BLOCKED]: {
        id: PlayerSlidersId[IndicatorType.BLOCKED],
        name: IndicatorType.BLOCKED,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.SAVE]: {
        id: PlayerSlidersId[IndicatorType.SAVE],
        name: IndicatorType.SAVE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.AERIAL_OFFENCE]: {
        id: PlayerSlidersId[IndicatorType.AERIAL_OFFENCE],
        name: IndicatorType.AERIAL_OFFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.AERIAL_DEFENCE]: {
        id: PlayerSlidersId[IndicatorType.AERIAL_DEFENCE],
        name: IndicatorType.AERIAL_DEFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.TAKE_ON]: {
        id: PlayerSlidersId[IndicatorType.TAKE_ON],
        name: IndicatorType.TAKE_ON,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.CROSSING]: {
        id: PlayerSlidersId[IndicatorType.CROSSING],
        name: IndicatorType.CROSSING,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.PASS_SHORT]: {
        id: PlayerSlidersId[IndicatorType.PASS_SHORT],
        name: IndicatorType.PASS_SHORT,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.PASS_LONG]: {
        id: PlayerSlidersId[IndicatorType.PASS_LONG],
        name: IndicatorType.PASS_LONG,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.RECEPTION]: {
        id: PlayerSlidersId[IndicatorType.RECEPTION],
        name: IndicatorType.RECEPTION,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.DELIVERY]: {
        id: PlayerSlidersId[IndicatorType.DELIVERY],
        name: IndicatorType.DELIVERY,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.SCORING]: {
        id: PlayerSlidersId[IndicatorType.SCORING],
        name: IndicatorType.SCORING,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.SCORING_EFFORT]: {
        id: PlayerSlidersId[IndicatorType.SCORING_EFFORT],
        name: IndicatorType.SCORING_EFFORT,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.SEASON_ID]: {
        id: PlayerSlidersId[IndicatorType.SEASON_ID],
        name: IndicatorType.SEASON_ID,
        data: [0, 24],
        minMax: [0, 24],
        step: 1,
      },
      [IndicatorType.COMPETITION_LEVEL]: {
        id: PlayerSlidersId[IndicatorType.COMPETITION_LEVEL],
        name: IndicatorType.COMPETITION_LEVEL,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.25,
      },
    },
    list: {
      [ListType.SEASON_ID]: {
        id: PlayerListsId[ListType.SEASON_ID],
        name: ListType.SEASON_ID,
        data: [],
        splitted: false,
      },
      [ListType.LEAGUE_ID]: {
        id: PlayerListsId[ListType.LEAGUE_ID],
        name: ListType.LEAGUE_ID,
        data: [],
      },
      [ListType.TEAM_ID]: {
        id: PlayerListsId[ListType.TEAM_ID],
        name: ListType.TEAM_ID,
        data: [],
      },
      [ListType.POSITION_FIRST_ID]: {
        id: PlayerListsId[ListType.POSITION_FIRST_ID],
        name: ListType.POSITION_FIRST_ID,
        data: [],
        mode: 2,
      },
      [ListType.COUNTRY_ID]: {
        id: PlayerListsId[ListType.COUNTRY_ID],
        name: ListType.COUNTRY_ID,
        data: [],
      },
      [ListType.PRESET_ID]: {
        id: PlayerListsId[ListType.PRESET_ID],
        name: ListType.PRESET_ID,
        data: [],
      },
      [ListType.FORMATION_ID]: {
        id: PlayerListsId[ListType.FORMATION_ID],
        name: ListType.FORMATION_ID,
        data: [],
      },
      [ListType.PREFERRED_FOOT]: {
        id: PlayerListsId[ListType.PREFERRED_FOOT],
        name: ListType.PREFERRED_FOOT,
        data: [
          {
            id: 'ALL',
            name: 'All',
            active: true,
            type: 'group',
            open: true,
            items: [
              {
                name: 'Left',
                id: 'left',
                active: true,
                type: 'item',
                items: [],
              },
              {
                name: 'Right',
                id: 'right',
                active: true,
                type: 'item',
                items: [],
              },
              {
                name: 'None',
                id: 'both',
                active: true,
                type: 'item',
                items: [],
              },
              {
                name: 'Unknown',
                id: '-',
                active: true,
                type: 'item',
                items: [],
              },
            ],
          },
        ],
      },
      [ListType.FAVORITE_LEAGUES]: {
        id: PlayerListsId[ListType.FAVORITE_LEAGUES],
        name: ListType.FAVORITE_LEAGUES,
        data: [],
      },
    },
  },
};
