import React from 'react';
import styled from 'styled-components/macro';

import Icon from '../../../../../ui/svg-icon';
import { ifProp } from 'styled-tools';

interface Props {
  checked: boolean;
  children: any;
  onChange: (name: string, value: boolean) => void;
  name: string;
}

export function CheckboxField(props: Props) {
  const { checked, children, onChange, name } = props;

  const onClick = () => {
    onChange(name, !checked);
  };

  return (
    <Wrapper
      checked={checked}
      onClick={onClick}
      className={`checkbox-field checkbox-field--${name}`}
    >
      {checked ? (
        <Icon name="overview-checkbox" />
      ) : (
        <Icon name="overview-checkbox-unchecked" />
      )}
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ checked: boolean }>`
  font-size: 13px;
  color: #fff;
  padding: 8px 10px 9px 28px;
  border-bottom: 1px solid var(--black_light);
  background-color: darken(var(--dark-sidebar-primary), 2%);
  cursor: pointer;
  opacity: 0.9;
  font-weight: ${ifProp('checked', 500, 400)};

  &:hover {
    background: var(--neongreen);
    opacity: 1;
  }

  svg {
    width: 18px;
    height: 18px;
    vertical-align: top;
    display: inline-block;
    margin-right: 4px;
    position: relative;
    top: 1px;
  }
`;
