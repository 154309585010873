import axios from 'axios';
import Papa from 'papaparse';
import { store } from '../index';
import * as types from '../constants';
import { BASE_URL, ENDPOINTS as API_ENDPOINTS } from '../constants/api';
import eventTracker from '../helper/event-tracker';
import { database } from '../constants/database';

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
  'token',
)}`;

let total = 0;

const cancelTokens = {
  position_1: null,
  position_2: null,
  position_3: null,
  formation_position_1: null,
  formation_position_2: null,
  formation_position_3: null,
};

const transformResponse = [data => data];

const csvParsingWorker =
  ({ groupBy, keyBy = null, worker = false } = {}) =>
  result =>
    new Promise((resolve, reject) => {
      let resultAsObject = {};
      let resultAsArray = [];

      Papa.parse(result.data, {
        header: true,
        skipEmptyLines: true,
        worker,
        dynamicTyping: true,
        step: (results, parser) => {
          if (keyBy) {
            resultAsObject[results.data[keyBy]] = results.data;
          } else {
            resultAsArray.push(results.data);
          }
        },
        complete: () => {
          if (keyBy) {
            resolve({ data: resultAsObject });
          } else {
            resolve({ data: resultAsArray });
          }
          groupBy && cancelTokens[groupBy]();

          resultAsObject = null;
          resultAsArray = null;
        },
      });
    });

export function startWelcomeLoad() {
  return {
    type: types.START_WELCOME_LOAD,
  };
}

export function loadProgressUpdate(message, total) {
  return result => {
    store.dispatch({
      type: types.LOAD_DATA_PROGRESS,
      payload: { message, total },
    });

    return result;
  };
}

export function loadData(currentSeason, device, playersGroupBy, withOverview) {
  let request;
  total = withOverview ? 9 : 4;

  if (device === 'mobile') {
    request = Promise.all([
      axios.get(API_ENDPOINTS.DECODE).then(loadProgressUpdate('decode', total)),
      axios
        .get(API_ENDPOINTS.FAVORITE)
        .then(loadProgressUpdate('favorite', total)),
      Promise.resolve({ data: [] }).then(loadProgressUpdate('presets', total)),
      Promise.resolve({ data: [] }).then(
        loadProgressUpdate('favorite_leagues', total),
      ),
    ]);
  } else {
    request = Promise.all([
      axios.get(API_ENDPOINTS.DECODE).then(loadProgressUpdate('decode', total)),
      axios
        .get(API_ENDPOINTS.FAVORITE)
        .then(loadProgressUpdate('favorite', total)),
      axios
        .get(API_ENDPOINTS.PRESETS)
        .then(loadProgressUpdate('preset', total)),
      axios
        .get(API_ENDPOINTS.FAVORITE_LEAGUES)
        .then(loadProgressUpdate('favorite_leagues', total)),
    ]);
  }

  return {
    type: types.LOAD_DATA,
    payload: request,
    meta: {
      currentSeason,
      device,
      playersGroupBy,
    },
  };
}

export function fetchDataForWelcome({ limit }) {
  const request = axios(`${API_ENDPOINTS.WELCOME}${limit}`);

  return {
    type: types.FETCH_DATA_FOR_WELCOME,
    payload: request,
  };
}

export function fetchDataForOverview(
  currentSeason,
  device,
  playersGroupBy,
  onlyOverview,
) {
  let request = null;

  if (onlyOverview) {
    total = 5;
  }

  let teamSeason = types.DEFAULT_SEASON;

  if (typeof currentSeason === 'number' && currentSeason !== 0) {
    teamSeason = currentSeason;
  }

  if (device === 'mobile') {
    request = Promise.all([
      axios(API_ENDPOINTS.PLAYER.META_MOBILE, { transformResponse })
        .then(csvParsingWorker({ keyBy: 'player_id' }))
        .then(loadProgressUpdate('meta', total)),
      Promise.resolve({ data: [] }).then(
        loadProgressUpdate('players_performacne', total),
      ),
      Promise.resolve({ data: [] }).then(
        loadProgressUpdate('teams_perforamnce', total),
      ),
      Promise.resolve({ data: [] }).then(
        loadProgressUpdate('matches_performance', total),
      ),
      axios
        .get(API_ENDPOINTS.MATCH.LASTS)
        .then(loadProgressUpdate('matches', total)),
    ]);
  } else {
    request = Promise.all([
      axios
        .get(API_ENDPOINTS.PLAYER.META, { transformResponse })
        .then(csvParsingWorker({ keyBy: 'player_id' }))
        .then(loadProgressUpdate('meta', total)),
      axios
        .get(
          API_ENDPOINTS.PLAYER.PERFORMANCE.replace(
            '{season}',
            currentSeason,
          ).replace('{groupBy}', playersGroupBy),
          { transformResponse },
        )
        .then(result => csvParsingWorker({ groupBy: playersGroupBy })(result))
        .then(loadProgressUpdate('players_performacne', total)),
      axios
        .get(API_ENDPOINTS.TEAM.PERFORMANCE.replace('{season}', teamSeason), {
          transformResponse,
        })
        .then(csvParsingWorker())
        .then(loadProgressUpdate('teams_perforamnce', total)),
      axios
        .get(API_ENDPOINTS.MATCH.PERFORMANCE)
        .then(loadProgressUpdate('matches_performance', total)),
      axios
        .get(API_ENDPOINTS.MATCH.LASTS)
        .then(loadProgressUpdate('matches', total)),
    ]);
  }

  return {
    type: types.FETCH_DATA_FOR_OVERVIEW,
    payload: request,
    meta: {
      currentSeason,
      teamSeason,
      device,
      playersGroupBy,
    },
  };
}

export function loadPlayersPerformanceData({ season_id, playersGroupBy }) {
  const request = axios
    .get(
      `${database.dbPlayersPerformanceSeason}${playersGroupBy}/${season_id}`,
      {
        transformResponse,
      },
    )
    .then(csvParsingWorker({ groupBy: playersGroupBy }));

  return {
    type: types.LOAD_PLAYERS_PERFORMANCE,
    payload: request,
    meta: {
      season_id,
      playersGroupBy,
    },
  };
}

export function loadTeamsPerformanceData({ season_id }) {
  const request = axios
    .get(database.dbTeamsPerformanceSeason + season_id, {
      transformResponse,
    })
    .then(csvParsingWorker());

  return {
    type: types.LOAD_TEAMS_PERFORMANCE,
    payload: request,
    meta: { season_id },
  };
}

export function changePlayersGroupBy({ playersGroupBy }) {
  return {
    type: types.CHANGE_PLAYERS_GROUP_BY,
    payload: Promise.resolve([]),
    meta: {
      playersGroupBy,
    },
  };
}

export function loading(status, message) {
  return {
    type: types.LOADING,
    status,
    message,
  };
}

export function overviewLoading(status) {
  return {
    type: types.OVERVIEW_LOADING,
    status,
  };
}

export function filterData(data) {
  return {
    type: types.FILTER_DATA,
    data,
  };
}

export function addToFavorite({ id, mode, category }) {
  const request = Promise.all([
    axios.put(`${database.favorites}/${mode}/${id}/${category}`),
    Promise.resolve(id),
    Promise.resolve(mode),
    Promise.resolve(category),
  ]);

  eventTracker.trackEvent('Watchlist', 'Add player', category);

  return {
    type: types.ADD_TO_FAVORITE,
    payload: request,
  };
}

export function removeFromFavorite({ id, mode }) {
  const request = Promise.all([
    axios.delete(`${database.favorites}/${mode}/${id}`),
    Promise.resolve(id),
    Promise.resolve(mode),
  ]);

  eventTracker.trackEvent('Watchlist', 'Remove player');

  return {
    type: types.REMOVE_FROM_FAVORITE,
    payload: request,
  };
}

export function loadQuote() {
  const request = axios.get(`${database.quote}`);

  return {
    type: types.LOAD_QUOTE,
    payload: request,
  };
}

export function loadPlayerDataField(playerId) {
  const request = axios.get(`${database.dbLoadPlayerDataField}${playerId}`);

  return {
    type: types.LOAD_PLAYER_DATA_FIELD,
    payload: request,
  };
}
