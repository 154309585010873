import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

interface Option {
  label: string;
  value: number | string | boolean;
  data?: any;
}

interface Props {
  options: Option[];
  value: Option | undefined;
  onChange: (Option) => void;
}

export function TextSwitcherField(props: Props) {
  const { options, value, onChange } = props;

  return (
    <>
      {options
        .map((option, index) => (
          <Item
            key={index}
            active={value?.value === option.value}
            onClick={() => {
              onChange(option);
            }}
          >
            {option.label}
          </Item>
        ))
        .reduce((list: any[], item, index) => {
          list.push(item);
          index + 1 < options.length && list.push(' / ');
          return list;
        }, [])}
    </>
  );
}

const Item = styled.span<{ active: boolean }>`
  cursor: pointer;
  text-decoration: ${ifProp('active', 'none', 'underline')};
`;
