import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHover, useLayer } from 'react-laag';
import { batch, useDispatch, useSelector } from 'react-redux';

import './competition-level.scss';

import Icon from '../ui/svg-icon';
import CompetitionLevelHint from './competition-level-hint';
import CompetitionLevelOverview from './competition-level-overview';

import {
  changeFilter,
  getLeague,
  getOverviewLeagues,
  getUserLeague,
} from '../../actions/competition-level';
import { addLoader, deleteLoader } from '../../actions/loader';

const CompetitionLevel = props => {
  const { league_id, children, showInfo } = props;

  const dispatch = useDispatch();
  const [windowIsOpen, changeWindowOpenState] = useState(false);
  const selectedLeague = useSelector(
    state => state.competitionLevel.selectedLeague,
  );
  const userLeague = useSelector(state => state.competitionLevel.userLeague);

  const [isOpen, hoverProps] = useHover({ delayEnter: 0, delayLeave: 100 });
  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    overflowContainer: true,
    auto: true,
    snap: true,
    placement: 'bottom-center',
    possiblePlacements: ['top-center'],
    triggerOffset: 12,
    containerOffset: 16,
    arrowOffset: 10,
  });

  useEffect(() => {
    Promise.all([
      dispatch(getUserLeague()),
      league_id && dispatch(getLeague(league_id)),
    ]);
  }, [league_id]);

  const onWindowOpen = () => {
    changeWindowOpenState(true);

    const countries = [selectedLeague.country_id];
    userLeague && countries.push(userLeague.country_id);

    batch(() => {
      dispatch(addLoader('competition-level'));
      selectedLeague &&
        dispatch(changeFilter('firstLeagueId', selectedLeague.id));
      selectedLeague.country_id &&
        dispatch(changeFilter('countryIds', [...countries]));
      dispatch(getOverviewLeagues()).finally(() => {
        dispatch(deleteLoader('competition-level'));
      });
    });
  };

  const onWindowClose = () => {
    changeWindowOpenState(false);
  };

  return (
    <>
      <button
        type="button"
        className="competition-level-window-trigger"
        onClick={onWindowOpen}
      >
        {children}
        {selectedLeague?.rating && showInfo ? (
          <span
            className="competition-level-hint-trigger"
            {...triggerProps}
            {...hoverProps}
          >
            <Icon name="info" />
          </span>
        ) : null}
      </button>
      {selectedLeague?.rating &&
        isOpen &&
        renderLayer(
          <CompetitionLevelHint
            league_id={league_id}
            {...layerProps}
            {...hoverProps}
          />,
        )}
      <CompetitionLevelOverview isOpen={windowIsOpen} onClose={onWindowClose} />
    </>
  );
};

CompetitionLevel.propTypes = {
  league_id: PropTypes.number.isRequired,
  children: PropTypes.any,
  showInfo: PropTypes.bool,
};

export default CompetitionLevel;
