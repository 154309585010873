import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router';

import lang from '../../../lang';

class PlayerStats extends Component {
  constructor(props) {
    super(props);

    this._renderAnalyse = this._renderAnalyse.bind(this);

    this.state = {
      rows: [
        ['rating', 'offence', 'defence'],
        [
          'tackle_offence',
          'tackle_defence',
          'intercept_offence',
          'intercept_defence',
        ],
        ['blocked', 'save', 'aerial_offence', 'aerial_defence'],
        ['take_on', 'crossing', 'pass_short', 'pass_long'],
        ['reception', 'delivery', 'scoring', 'scoring_effort'],
      ],
    };
  }

  _renderAnalyse() {
    const { playerStats = [], information = {}, players } = this.props.details;
    const { rows } = this.state;
    const { positions } = this.props.app;

    if (!playerStats.length || !_.size(information)) return null;

    const filterPlayers = playerStats.filter(
      player => player.rating || player.rating == 0,
    );
    const playerPositions = _.chain(filterPlayers)
      .map(player => player.position_id)
      .uniq()
      .sortBy()
      .value();

    const renderHeader = cols =>
      cols.map(col => (
        <div key={`header-${col}`} className="col-3">
          <div className="col-header-name">{lang[col]}</div>
        </div>
      ));

    const renderPlayers = (plrs, col) =>
      _.orderBy(plrs, player => (player[col] ? +player[col] : 0), 'desc').map(
        player => (
          <div
            key={`${player.player_id}-${player.position_id}-${col}`}
            className={`indicator ${
              player[col]
                ? information.team_id_home == player.team_id
                  ? 'blue'
                  : 'yellow'
                : 'gray'
            }`}
          >
            <div
              className="indicator-percent"
              style={{ width: `${parseFloat(player[col]) * 10 || 0}%` }}
            />
            <Link
              className={`name ${
                player[col]
                  ? parseFloat(player[col]) < 5
                    ? 'dark'
                    : 'bright'
                  : 'gray'
              }`}
              to={`/details/player/${player.player_id}/summary`}
            >
              {_.get(players, `${player.player_id}.short_name`, null)}
            </Link>
            <div className={`indicator-value ${player[col] ? 'dark' : 'gray'}`}>
              {!Number.isNaN(+player[col]) && player[col] !== null
                ? parseFloat(player[col]).toFixed(2)
                : 'N/D'}
            </div>
          </div>
        ),
      );

    const renderBodyCol = (col, key) =>
      playerPositions.map((position, i) => (
        <div className="group-position" key={`body-${col}-${position}`}>
          {key === 0 ? (
            <div className="position-name">
              {_.get(positions, `${position}.position_name`, '')}
            </div>
          ) : null}
          {renderPlayers(
            _.clone(filterPlayers).filter(
              player => player.position_id === position,
            ),
            col,
          )}
        </div>
      ));

    const renderCols = cols =>
      cols.map((col, i) => (
        <div key={`body-${col}`} className="col-3">
          {renderBodyCol(col, i)}
        </div>
      ));

    return rows.map((row, i) => (
      <div className="group" key={i}>
        <div className="group-header">
          <div className="row">
            {row.map(col => (
              <div key={`'line-'${col}`} className="col-3 middle-line">
                <div className="margin">
                  <div className="line" />
                </div>
              </div>
            ))}
          </div>
          <div className="row">{renderHeader(row)}</div>
        </div>
        <div className="group-body">
          <div className="row">{renderCols(row, i)}</div>
        </div>
      </div>
    ));
  }

  render() {
    return (
      <div>
        <div className="h4">
          <span>{lang.compareAllPlayers}</span>
          <span className="description">
            {lang.rating_0_10}, {lang.sortedByTopGroupValues}
          </span>
        </div>
        <div className="squad analyse match">{this._renderAnalyse()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    details: state.details,
    app: state.app,
    overview: state.overview,
  };
}

export default connect(mapStateToProps)(PlayerStats);
