import React from 'react';
import { IndexRoute, Route } from 'react-router';
import _ from 'lodash';

import App from '../containers/App';

import Search from '../components/search';
import overview from '../components/overview';
import details from '../components/details';
import compare from '../components/compare';
import favorites from '../components/favorites/favorites';
import shadowTeams from '../components/favorites/shadow';
import shadowTeam from '../components/favorites/team';
import shadowOverview from '../components/favorites/overview';
import favoriteSection from '../components/favorites/index';
import Welcome from '../components/welcome';

import notes from '../components/favorites/notes';

import error404 from '../components/404';
import auth from '../components/auth';
import account from '../components/account';
import general from '../components/account/general';
import users from '../components/account/users';
import groups from '../components/account/groups/index';
import info from '../components/info';
import help from '../components/info/help';
import privacy from '../components/info/privacy';

import { checkAuth } from '../actions/auth';
import { dataErrorModal } from '../actions/details';
import eventTracker from '../helper/event-tracker';
import { ModeType } from '../types/Dict';

export default function desktop(store) {
  const setBodyClassName = location => {
    const page = location.pathname.split('/')[1];

    document.body.className = document.body.className.replace('auth-page', '');
    document.body.className = document.body.className.replace(
      'overview-page',
      '',
    );
    document.body.className = document.body.className.replace(
      'welcome-page',
      '',
    );

    if (page === 'overview') {
      document.body.classList.add('overview-page');
    } else if (page === 'home') {
      document.body.classList.add('welcome-page');
    } else if (page === 'auth') {
      document.body.classList.add('auth-page');
    }
  };

  const onEnter = ({ location }) => {
    const pathName = document.location.pathname;

    if (pathName.split('/')[1] === 'home') {
      localStorage.setItem('returnTo', 'welcome');
    }

    if (pathName.split('/')[1] === 'overview') {
      if (pathName.split('/')[2] !== 'rankings') {
        localStorage.setItem('returnTo', 'overview');
      } else {
        localStorage.setItem('returnTo', 'overview/rankings');
      }
    }

    setBodyClassName(location);
    eventTracker.trackPageView();
    store.dispatch(checkAuth());
  };

  const onChange = (prevState, nextState, replace) => {
    const { mode } = nextState.params;
    setBodyClassName(nextState.location);

    const pathName = nextState.location.pathname;

    if (pathName.split('/')[1] === 'home') {
      localStorage.setItem('returnTo', 'welcome');
    }

    if (pathName.split('/')[1] === 'overview') {
      if (pathName.split('/')[2] !== 'rankings') {
        localStorage.setItem('returnTo', 'overview');
      } else {
        localStorage.setItem('returnTo', 'overview/rankings');
      }
    }

    eventTracker.trackPageView();

    if (
      nextState.location.pathname.split('/')[1] === 'details' &&
      [ModeType.PLAYER, ModeType.TEAM].indexOf(mode) !== -1
    ) {
      const reduxState = store.getState();
      const id = nextState.params.id.split('/')[0];

      if (
        (mode === ModeType.PLAYER &&
          _.size(reduxState.app.playersMetaData) &&
          _.isEmpty(
            _.get(reduxState, `app.playersMetaData[${id}].standard_league`),
          )) ||
        (mode === ModeType.TEAM &&
          _.size(reduxState.app.teams) &&
          _.isEmpty(_.get(reduxState, `app.teams[${id}].data`)))
      ) {
        store.dispatch(dataErrorModal(true, mode));
        replace(prevState.location.pathname);
      }
    }
  };

  return (
    <Route onEnter={onEnter} path="/" component={App} onChange={onChange}>
      <Route path="/home" component={Welcome} />

      <Route path="/overview" exact component={overview} />
      {/*<Route path="/overview/rankings" component={playersPositionRankings} />*/}
      <Route path="/overview/:mode" component={overview} />

      <Route path="/details/:mode/:id" component={details} />
      <Route path="/details/:mode/:id/:tab" component={details} />
      <Route path="/details/:mode/:id/:league/:season" component={details} />
      <Route
        path="/details/:mode/:id/:league/:season/:tab"
        component={details}
      />
      <Route
        path="/details/:mode/:id/:league/:start/:end"
        component={details}
      />
      <Route
        path="/details/:mode/:id/:league/:start/:end/:tab"
        component={details}
      />
      <Route path="/compare/:mode" component={compare} />
      <Route path="/compare/:mode/:id" component={compare} />
      <Route path="/compare/:mode/:id/:second" component={compare} />

      <Route path="/search/:mode" component={Search} />

      <Route path="/favorites/" components={favoriteSection}>
        <Route path="/favorites/shadow-teams/" component={shadowTeams}>
          <IndexRoute component={shadowOverview} />
          <Route path="/favorites/shadow-teams/:team" component={shadowTeam} />
        </Route>
        <Route path="/favorites/notes/" component={notes} />
        <Route path="/favorites/:mode" component={favorites} />
      </Route>

      <Route path="/auth/:type" component={auth} />

      <Route path="/account" component={account}>
        <Route path="/account/general" component={general} />
        <Route path="/account/users" component={users} />
        <Route path="/account/groups" component={groups} />
      </Route>

      <Route path="/info/" component={info}>
        <Route path="/info/help" component={help} />
        <Route path="/info/privacy" component={privacy} />
      </Route>

      <Route path="*" component={error404} />
    </Route>
  );
}
