import _ from 'lodash';
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { AutoSizer, Column, SortIndicator, Table } from 'react-virtualized';
import { connect } from 'react-redux';
import Papa from 'papaparse';

import './table.scss';

import lang from '../../../lang';
import Image from '../../ui/image';
import Icon from '../../ui/svg-icon';

import { setActivePointData } from '../../../actions/overview';
import { database } from '../../../constants/database';

class OverviewTable extends Component {
  static propTypes = {
    activeMode: PropTypes.string,
    filteredData: PropTypes.array,
    formations: PropTypes.object,
    fullscreen: PropTypes.bool,
    height: PropTypes.number,
    idName: PropTypes.string,
    lassoSelection: PropTypes.any, // @todo set correct proptype
    leagues: PropTypes.object,
    positions: PropTypes.object,
    rows: PropTypes.array,
    showItem: PropTypes.func,
    sortBy: PropTypes.string,
    sortedData: PropTypes.array,
    sortDirection: PropTypes.oneOf(['ASC', 'DESC']),
    sortTable: PropTypes.func,
    teams: PropTypes.object,
    visualHeight: PropTypes.number,
    width: PropTypes.number,
    setActivePointData: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.headerRenderer = this.headerRenderer.bind(this);
    this.noRowsRenderer = this.noRowsRenderer.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.sort = this.sort.bind(this);
    this.cellRenderer = this.cellRenderer.bind(this);
    this.rowClassName = this.rowClassName.bind(this);
    this.renderCsvLink = this.renderCsvLink.bind(this);
  }

  componentWillReceiveProps = nextProps => {
    if (
      this.props.filteredData.length !== nextProps.filteredData.length ||
      (this.props.lassoSelection != null && nextProps.lassoSelection == null) ||
      this.props.activeMode !== nextProps.activeMode
    ) {
      this.sort(
        nextProps.sortBy,
        nextProps.sortDirection,
        nextProps.filteredData,
        nextProps.activeMode,
      );
    }

    if (
      this.props.activeMode &&
      this.props.lassoSelection != nextProps.lassoSelection &&
      nextProps.lassoSelection != null
    ) {
      this.sort(
        nextProps.sortBy,
        nextProps.sortDirection,
        nextProps.lassoSelection,
        nextProps.activeMode,
      );
    }
  };

  shouldComponentUpdate = nextProps => {
    return (
      nextProps.height !== this.props.height ||
      nextProps.width !== this.props.width ||
      nextProps.filteredData !== this.props.filteredData ||
      nextProps.sortedData !== this.props.sortedData ||
      !_.isEqual(nextProps.rows, this.props.rows) ||
      nextProps.sortBy !== this.props.sortBy ||
      nextProps.sortDirection !== this.props.sortDirection ||
      this.props.lassoSelection != nextProps.lassoSelection
    );
  };

  onRowClick({ rowData }) {
    const {
      fullscreen,
      visualHeight,
      idName,
      activeMode,
      rows,
      app,
      setActivePointData,
    } = this.props;

    const { season_current } = app;

    if (+visualHeight === 0 || fullscreen) {
      const splittedPosition = rows.indexOf('position_first_id') > -1;

      browserHistory.push(
        `/details/${activeMode}/${rowData[idName]}/${rowData.league_id}/${
          season_current[activeMode]
        }/summary${
          splittedPosition ? `?position=${rowData.position_first_id}` : ''
        }`,
      );
    } else {
      setActivePointData({
        mode: activeMode,
        data: rowData,
        table: true,
      });
    }
  }

  noRowsRenderer() {
    return <div className="noRows">No data available</div>;
  }

  rowClassName({ index }) {
    if (index < 0) {
      return 'headerRow';
    }
    return index % 2 === 0 ? 'evenRow' : 'oddRow';
  }

  sort(sortBy, sortDirection, data, mode) {
    const { filteredData, leagues, teams, positions, lassoSelection } =
      this.props;

    const { playersMetaData } = this.props.app;
    const data_raw =
      typeof data !== 'undefined' ? data : lassoSelection || filteredData;

    const sortedData_ =
      data_raw.length > 0
        ? _.orderBy(
            data_raw,
            [
              p => {
                let sortVal = null;
                if (sortBy === 'last_name') {
                  sortVal = _.get(
                    playersMetaData,
                    `[${p.player_id}].last_name`,
                    '',
                  );
                } else if (sortBy === 'league_id') {
                  sortVal = _.get(leagues, `${p.league_id}.name`);
                } else if (sortBy === 'team_id') {
                  sortVal = _.get(teams, `${p.team_id}.short_club_name`);
                } else if (sortBy === 'team_id_home') {
                  sortVal = _.get(teams, `${p.team_id_home}.short_club_name`);
                } else if (sortBy === 'team_id_away') {
                  sortVal = _.get(teams, `${p.team_id_away}.short_club_name`);
                } else if (sortBy === 'position_id') {
                  sortVal = _.get(positions, `${p.position_id}.position_name`);
                } else if (sortBy === 'season_mins' || sortBy === 'seasonAge') {
                  sortVal = parseInt(p[sortBy], 10);
                } else if (
                  sortBy === 'season_rating' ||
                  sortBy === 'defence' ||
                  sortBy === 'offence'
                ) {
                  sortVal = parseFloat(p[sortBy]);
                } else if (sortBy === 'goals') {
                  sortVal = parseInt(p.homegoals, 10);
                } else if (sortBy === 'marketvalue') {
                  sortVal = parseFloat(p.marketvalue);
                }
                return sortVal || p[sortBy];
              },
            ],
            [sortDirection.toLowerCase()],
          )
        : data_raw;

    this.props.sortTable(sortBy, sortDirection, sortedData_, mode);
  }

  headerRenderer({ dataKey, label, sortBy, sortDirection }) {
    return (
      <div className={sortBy === dataKey ? 'sortActive' : ''}>
        {label}
        {sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
      </div>
    );
  }

  cellRenderer({ cellData, dataKey, rowData, isCSV = false, rowIndex }) {
    const { leagues, teams, positions, formations } = this.props;
    const { playersMetaData } = this.props.app;

    if (!cellData && +cellData !== 0 && dataKey !== 'last_name') {
      return '';
    }

    if (['seasonAge', 'marketvalue'].indexOf(dataKey) > -1) {
      return +cellData && +cellData > 0 ? cellData : 'N/D';
    }

    if (['team_id', 'team_id_home', 'team_id_away'].indexOf(dataKey) > -1) {
      const url = `${database.assets}teams/${rowData[dataKey]}.jpg`;
      const teamName = _.get(teams, `${cellData}.short_club_name`, '');

      if (isCSV) return teamName;

      return (
        <span>
          <Image src={url} aspectRatio={0.769} /> {teamName}
        </span>
      );
    }

    switch (dataKey) {
      case 'league_id': {
        return _.get(leagues, `${cellData}.name`, '');
      }
      case 'position_id': {
        return _.get(
          positions,
          `${cellData}.${isCSV ? 'description' : 'position_name'}`,
          cellData,
        );
      }
      case 'position_first_id': {
        return _.get(
          positions,
          `${cellData}.${isCSV ? 'description' : 'position_name'}`,
          cellData,
        );
      }
      case 'last_name': {
        if (isCSV)
          return (
            _.get(playersMetaData, `${rowData.player_id}.last_name`, '') || ''
          );

        const firstName =
          _.get(playersMetaData, `${rowData.player_id}.first_name`, '') || '';
        const lastName =
          _.get(playersMetaData, `${rowData.player_id}.last_name`, '') || '';

        return `${firstName} ${lastName}`;
      }
      case 'first_name': {
        return (
          _.get(playersMetaData, `${rowData.player_id}.first_name`, '') || ''
        );
      }
      case 'formation_id': {
        if (cellData && typeof cellData === 'object') {
          return cellData
            .map(formation => _.get(formations, `${formation}.name`, null))
            .filter(formation => formation)
            .join(', ');
        }

        return _.get(formations, `${cellData}.name`, cellData);
      }
      case 'goals': {
        return `${rowData.homegoals} : ${rowData.awaygoals}`;
      }
      default:
        return cellData;
    }
  }

  renderCsvLink() {
    const { sortedData, activeMode, rows } = this.props;

    const columns = rows.filter(
      colName => ['preview', 'csv'].indexOf(colName) === -1,
    );
    const keyReplace = key =>
      key.replace('_id', '').replace('seasonAge', 'age');

    if (activeMode === 'player') {
      columns.unshift('first_name');
    }

    const exportData = _.chain(sortedData)
      .slice(0, 30)
      .map(data => ({
        ...data,
        first_name: '',
        last_name: '',
      }))
      .map(data =>
        _.mapValues(data, (cellData, dataKey, rowData) =>
          this.cellRenderer({
            cellData,
            dataKey,
            rowData,
            isCSV: true,
          }),
        ),
      )
      .map(data => _.pick(data, columns))
      .map(data => _.mapKeys(data, (value, key) => keyReplace(key)))
      .value();

    const csv = Papa.unparse(exportData, {
      header: true,
      columns: columns.map(keyReplace),
    });

    return (
      <a
        className="csv-export"
        href={`data:text/plain;charset=utf-8,${encodeURIComponent(csv)}`}
        download={`scoutpanel_${activeMode}_export.csv`}
        title="Download data, max. 30"
      >
        <Icon name="download" />
      </a>
    );
  }

  render() {
    const {
      height,
      rows,
      filteredData,
      sortDirection,
      sortBy,
      sortedData,
      activeMode,
    } = this.props;
    const sortedData_ = sortedData || filteredData;

    return (
      <div className="fade-out">
        {this.renderCsvLink()}
        <AutoSizer disableHeight>
          {({ width }) => (
            <Table
              headerClassName="headerColumn"
              headerHeight={40}
              height={height}
              width={width}
              noRowsRenderer={this.noRowsRenderer}
              rowClassName={this.rowClassName}
              rowHeight={37}
              rowGetter={({ index }) => sortedData_[index]}
              sortBy={sortBy}
              sortDirection={sortDirection}
              onRowClick={this.onRowClick}
              rowCount={sortedData_.length}
              sort={({ sortBy, sortDirection }) => {
                this.sort(sortBy, sortDirection, undefined, activeMode);
              }}
            >
              {rows.map(
                (item, i) => (
                  <Column
                    key={i}
                    label={lang[item]}
                    dataKey={item}
                    cellRenderer={this.cellRenderer}
                    disableSort={false}
                    headerRenderer={this.headerRenderer}
                    width={
                      item === 'csv'
                        ? 24
                        : item === 'last_name'
                        ? width * 0.146
                        : item === 'team_id' || item === 'league_id'
                        ? width * 0.13
                        : item === 'seasonAge' || item === 'preview'
                        ? width * 0.057
                        : item === 'team_id_home' || item === 'team_id_away'
                        ? width * 0.2
                        : width * 0.104
                    }
                  />
                ),
                this,
              )}
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default connect(
  state => ({
    overview: state.overview,
    app: state.app,
  }),
  { setActivePointData },
)(OverviewTable);
