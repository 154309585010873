import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import d3 from 'd3';

import * as OverviewActions from '../../../actions/overview';
import {
  changeScatterplotLegend,
  changeScatterplotDomain,
} from '../../../actions/overview';
import Tooltip from './tooltip';

import OverviewLegend from '../layouts/legend';
import HeatMap from './heatmap';

class Overview extends Component {
  static propTypes = {
    setOverviewState: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.updateLegend = this.updateLegend.bind(this);
    this.onPointSelect = this.onPointSelect.bind(this);
  }

  componentWillMount() {
    this.updateLegend({});
  }

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.app.loaded &&
      this.props.overview.team.scatterplot.xDomain == null
    ) {
      this.resizeScatterplot(nextProps);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.filteredData.length !== prevProps.filteredData.length) {
      this.updateLegend({});
    }
  }

  resizeScatterplot(nextProps) {
    const { activeMode } = nextProps;
    const { xVal, yVal, cVal } = nextProps.overview[activeMode].scatterplot;

    const xDomain = d3.extent(nextProps.filteredData, d => +d[xVal]);
    const yDomain = d3.extent(nextProps.filteredData, d => +d[yVal]);
    const cDomain = d3.extent(nextProps.filteredData, d => +d[cVal]);

    this.props.changeScatterplotDomain({
      xDomain,
      yDomain,
      cDomain,
      mode: activeMode,
    });
  }

  updateLegend(e) {
    const data = this.props.filteredData;
    let { xVal, yVal, cVal, sVal, xDomain, yDomain, cDomain } =
      this.props.overview.team.scatterplot;

    switch (e.id) {
      case 'x':
        xVal = e.val;
        xDomain = d3.extent(data, d => +d[e.val]);
        break;
      case 'y':
        yVal = e.val;
        yDomain = d3.extent(data, d => +d[e.val]);
        break;
      case 'c':
        cVal = e.val;
        cDomain = d3.extent(data, d => +d[e.val]);
        break;
      case 's':
        sVal = e.val;
        break;
    }

    if (e.id !== 'x') xDomain = d3.extent(data, d => +d[xVal]);
    if (e.id !== 'y') yDomain = d3.extent(data, d => +d[yVal]);
    if (e.id !== 'c') cDomain = d3.extent(data, d => +d[cVal]);

    // reset selection, close popup if scale has been changed
    this.props.setOverviewState({ selectItem: undefined, tooltipOpen: false });

    // change scatterplot
    this.props.changeScatterplotLegend({
      xVal,
      yVal,
      cVal,
      sVal,
      xDomain,
      yDomain,
      cDomain,
      mode: 'team',
    });
  }

  onPointSelect(data) {
    const { setActivePointData } = this.props;

    setActivePointData({
      mode: 'team',
      data,
    });
  }

  render() {
    const { teams, regions, regionGroups } = this.props.app;
    const {
      filteredData,
      toggleLegend,
      clientWidth,
      clientHeight,
      tooltipOpen,
      activeMode,
    } = this.props;
    const {
      rDomain,
      cDomain,
      cVal,
      sVal,
      cRange,
      legend,
      legendList,
      sortLegendList,
    } = this.props.overview.team.scatterplot;
    const { contentWidth, visualHeight, tableHeight } =
      this.props.overview[activeMode].layouts;

    const groups = _.map(regionGroups, ({ id, name }) => ({
      name,
      regions: _.filter(regions, ({ group_id }) => group_id === id).map(
        region => region.id,
      ),
    }));

    const activePointData = this.props.overview.activePointData.team;
    const tooltipContent = <Tooltip filteredData={filteredData} />;

    return (
      <div
        className="overview_scatterplot"
        style={{
          bottom: `${
            clientHeight - tableHeight + (visualHeight > 200 ? 60 : 0)
          }px`,
          opacity: tableHeight === clientHeight ? 0 : 1,
          visibility: tableHeight === clientHeight ? 'hidden' : 'visible',
        }}
      >
        <HeatMap
          teams={teams}
          regions={regions}
          rDomain={rDomain}
          cDomain={cDomain}
          cVal={cVal}
          sVal={sVal}
          groups={groups}
          width={contentWidth ? contentWidth - 30 : 0}
          height={
            visualHeight ? visualHeight - (clientWidth <= 1024 ? 10 : 30) : 0
          }
          data={filteredData}
          cRange={['Viridis']}
          cScale="sequential"
          rScale="sqrt"
          onPointSelect={this.onPointSelect}
          activePointData={activePointData}
          showTooltip
          tooltipContent={tooltipContent}
        />

        <OverviewLegend
          onChange={this.updateLegend}
          cVal={cVal}
          sVal={sVal}
          cDomain={cDomain}
          cRange={cRange}
          rDomain={rDomain}
          legend={legend}
          toggleLegend={toggleLegend}
          tooltipOpen={tooltipOpen}
          legendList={legendList}
          sortLegendList={sortLegendList}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    overview: state.overview,
    app: state.app,
  }),
  { ...OverviewActions, changeScatterplotLegend, changeScatterplotDomain },
)(Overview);
