import React, { Component } from 'react';
import { DragLayer } from 'react-dnd';

class PlayerPreview extends Component {
  getPreviewStyles() {
    const { clientOffset } = this.props;
    return {
      transform: clientOffset
        ? `translate(${clientOffset.x - 19}px, ${
            clientOffset.y - 19
          }px) scale(1.2)`
        : '',
    };
  }

  render() {
    const { item, isDragging } = this.props;

    if (!isDragging) {
      return null;
    }

    const { player } = item;

    return (
      <div
        className={`player-mark player-mark__with-player preview`}
        style={this.getPreviewStyles()}
      >
        <span className="rating">{player.season_rating}</span>
        <span className="name">{player.short_name}</span>
      </div>
    );
  }
}

export default DragLayer(monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  clientOffset: monitor.getClientOffset(),
  isDragging: monitor.isDragging(),
}))(PlayerPreview);
