import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import './welcome.scss';

import Navigation from '../overview/layouts/navigation';
import lang from '../../lang/index';
import { Link } from 'react-router';
import Icon from '../ui/svg-icon';

import {
  fetchDataForOverview,
  fetchDataForWelcome,
  overviewLoading,
  startWelcomeLoad,
} from '../../actions/app';
import { DEFAULT_SEASON } from '../../constants';
import { Scrollbars } from 'react-custom-scrollbars';
import eventTracker from '../../helper/event-tracker';
import { getGroupBy, getSeason } from '../../helper';
import TopPlayersList from './top-players-list';
import SidePanel from './side-panel';
import CompetitionLevel from '../competition-level';

class Welcome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.innerHeight,
      isPresetLoading: false,
      presetPage: 0,
      visiblePresets: 2,
    };

    this.windowResize = this.windowResize.bind(this);
    this.nextPreset = this.nextPreset.bind(this);
    this.prevPreset = this.prevPreset.bind(this);
  }

  componentDidMount() {
    localStorage.setItem('overview-mode', 'player');
    this.props.startWelcomeLoad();
    this.props.fetchDataForWelcome({ limit: 10 });
    this.windowResize();
    window.addEventListener('resize', this.windowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResize);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.app.welcomeLoaded === false &&
      nextProps.app.welcomeLoaded === true
    ) {
      let savedFilter = localStorage.getItem(`overview_filter_player`);
      let groupBy = 'position_2';
      let season = DEFAULT_SEASON;

      if (savedFilter) {
        savedFilter = JSON.parse(savedFilter);
        groupBy = getGroupBy(savedFilter);
        season = getSeason(savedFilter, 'player');
      }

      if (!nextProps.app.overviewLoaded) {
        this.props.overviewLoading(true);
        this.props
          .fetchDataForOverview(season, this.props.app.device, groupBy, true)
          .finally(() => {
            this.props.overviewLoading(false);
          });
      }
    }
  }

  windowResize() {
    const { visiblePresets, presetPage } = this.state;
    const height = document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.innerHeight;
    const width = document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.innerWidth;

    let newVisiblePresets = width < 1920 ? 1 : 2;
    let newPresetPage = null;

    if (newVisiblePresets !== visiblePresets) {
      newPresetPage = 0;
    }

    this.setState({
      height,
      visiblePresets:
        newVisiblePresets !== visiblePresets
          ? newVisiblePresets
          : visiblePresets,
      presetPage: !_.isNull(newPresetPage) ? newPresetPage : presetPage,
    });
  }

  _getClientSize() {
    const navigationWidth = 62;
    const clientWidth =
      (document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.innerWidth) - navigationWidth;
    const clientHeight = document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.innerHeight;

    return { clientWidth, clientHeight };
  }

  nextPreset() {
    const { presetPage } = this.state;

    this.setState({
      presetPage: presetPage + 1,
    });
  }

  prevPreset() {
    const { presetPage } = this.state;

    this.setState({
      presetPage: presetPage - 1,
    });
  }

  render() {
    const { height, presetPage, visiblePresets } = this.state;
    const mode = this.props.mode || localStorage.getItem('overview-mode');
    const { clientWidth, clientHeight } = this._getClientSize();

    const { welcomeLoaded } = this.props.app;

    if (!welcomeLoaded) {
      return (
        <div className="overview welcome">
          <div className="row">
            <div
              className={`loader-small ${
                !welcomeLoaded ? 'page-opacity on' : 'page-opacity'
              }`}
            >
              <div className="loader-container">
                <div className="loader" />
              </div>
              <div className="loader-message">Loading data...</div>
              <div className="loader-bg" />
            </div>
          </div>
        </div>
      );
    }

    const { welcome } = this.props;
    const { presets = [], preset_players = [] } = welcome;
    const favoritePlayers = _.get(welcome, `risingFavorites`, []);
    const risingPlayers = _.get(welcome, `rising_players`, []);

    return (
      <div className="overview welcome">
        <div className="row">
          <SidePanel height={height} />
          <div className="welcome__screen">
            <Scrollbars style={{ height }} autoHide>
              <div className="wrapper">
                <div className="welcome_search">
                  <Link
                    className="wc-search"
                    to="/search/player/"
                    onClick={() => {
                      eventTracker.trackEvent('Welcome', 'Go to search');
                    }}
                  >
                    <Icon name="wc-search" className="wc-search-icon" />
                    {lang['welcome_search']}
                  </Link>
                  <Link
                    to="/compare/player/"
                    className="btn-stroked outlinedbutton btn-compare"
                  >
                    <span className="icon-compare" />
                    Compare players
                  </Link>
                </div>

                <div className="welcome__tops">
                  {favoritePlayers.length ? (
                    <div className="welcome__tops__column">
                      <TopPlayersList
                        heading={
                          <div className="top-players-list__welcome-heading">
                            <div className="heading__title">My favourites</div>
                            <Icon name="welcome-favorite" />
                          </div>
                        }
                        players={favoritePlayers}
                      />
                      <div className="top-controls">
                        <Link
                          className="btn-stroked outlinedbutton"
                          to={`/favorites/player/`}
                          onClick={() => {
                            eventTracker.trackEvent(
                              'Welcome',
                              'Go to favorites',
                              '',
                            );
                          }}
                        >
                          <Icon name="favorite" />
                          Show all my favourites
                        </Link>
                      </div>
                    </div>
                  ) : null}
                  <div className="welcome__tops__column">
                    <TopPlayersList
                      heading="Top Risers of the Last 3 Months"
                      index={1}
                      players={risingPlayers}
                    />
                  </div>
                  {presets
                    .slice(presetPage, presetPage + visiblePresets)
                    .map((preset, index) => {
                      const preset_name = preset?.name || '';
                      const preset_description = preset?.description || '';
                      const players = _.filter(
                        preset_players,
                        ({ preset_id }) => preset_id === preset.id,
                      );

                      return (
                        <div
                          className="welcome__tops__column"
                          key={`${preset?.id}-${index}`}
                        >
                          <TopPlayersList
                            heading={preset_name}
                            description={`Preset: ${preset_description}`}
                            index={2}
                            players={players}
                            type="presets"
                          />
                          <div className="top-controls">
                            <Link
                              className="btn-stroked"
                              to={`/overview/player/`}
                              onClick={() => {
                                eventTracker.trackEvent(
                                  'Welcome',
                                  'Go to preset',
                                );
                                localStorage.setItem(
                                  'preset',
                                  JSON.stringify({
                                    ...preset,
                                    id: `mm-${preset.id}`,
                                    mode: 'player',
                                  }),
                                );
                              }}
                            >
                              <Icon name="info" />
                              view more
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  <div className="presets-control">
                    <button
                      disabled={presetPage === 0}
                      type="button"
                      className="prev-button"
                      onClick={this.prevPreset}
                    >
                      <Icon name="chevron-right" />
                    </button>
                    <button
                      disabled={
                        presetPage * visiblePresets + 1 >= presets.length
                      }
                      type="button"
                      className="next-button"
                      onClick={this.nextPreset}
                    >
                      <Icon name="chevron-right" />
                    </button>
                  </div>
                </div>

                <div className="welcome-banners">
                  <Link
                    className="welcome__banner"
                    to="/overview/player/"
                    onClick={() => {
                      eventTracker.trackEvent('Welcome', 'Go to overview', '');
                    }}
                    style={{
                      backgroundImage: 'url(/img/welcome-banner-1.png)',
                      backgroundPosition: 'bottom right',
                    }}
                  >
                    <span className="welcome__banner-wrapper">
                      <span className="b_title">Find the player you need</span>
                      <span className="b_button">
                        <Icon name="wc-explore" />
                        Explore all Players
                      </span>
                    </span>
                  </Link>
                  <a
                    href="https://matchmetrics.com/insights/"
                    target="_blank"
                    className="welcome__banner"
                    onClick={() => {
                      eventTracker.trackEvent('Welcome', 'Go to Insights', '');
                    }}
                    style={{
                      backgroundImage: 'url(/img/welcome-banner-2.png)',
                      backgroundPosition: 'center',
                    }}
                  >
                    <span className="welcome__banner-wrapper">
                      <span className="b_title">Stories from our team</span>
                      <span className="b_button">
                        <Icon name="target-blank" />
                        Go to Insights
                      </span>
                    </span>
                  </a>
                  <Link
                    className="welcome__banner"
                    to="/overview/rankings/"
                    onClick={() => {
                      eventTracker.trackEvent('Welcome', 'Go to Ranking', '');
                    }}
                  >
                    <span
                      className="welcome__banner-wrapper"
                      style={{
                        backgroundImage: 'url(/img/welcome-banner-3.png)',
                        backgroundSize: '111px auto',
                        backgroundPosition: '92% 90%',
                      }}
                    >
                      <span className="b_title">Compare players</span>
                      <span className="b_button">
                        <Icon name="wc-rankings" />
                        Go to Ranking
                      </span>
                    </span>
                  </Link>
                  <CompetitionLevel league_id={welcome.league_id}>
                    <div
                      className="welcome__banner"
                      onClick={() => {
                        eventTracker.trackEvent(
                          'Welcome',
                          'Explore competition level',
                          '',
                        );
                      }}
                    >
                      <span
                        className="welcome__banner-wrapper"
                        style={{
                          backgroundImage: 'url(/img/welcome-banner-4.png)',
                          backgroundPosition: '57% bottom',
                          backgroundSize: 'auto 90%',
                        }}
                      >
                        <span className="b_title">
                          Compare players
                          <br />
                          across competitions
                          <br />
                          and countries
                        </span>
                        <span className="b_button">
                          Explore competition level
                        </span>
                      </span>
                      <span className="welcome__banner__new">NEW</span>
                    </div>
                  </CompetitionLevel>
                  <a
                    href="https://datawizard.matchmetrics.com/"
                    target="_blank"
                    className="welcome__banner"
                    onClick={() => {
                      eventTracker.trackEvent(
                        'Welcome',
                        'Go to Datawizard',
                        '',
                      );
                    }}
                    style={{
                      backgroundImage: 'url(/img/welcome-banner-5.png)',
                    }}
                  >
                    <span className="welcome__banner-wrapper">
                      <span className="b_title">
                        Create animated visualizations for comparing players and
                        teams
                      </span>
                      <span className="b_button">
                        <Icon name="target-blank" />
                        Go to Datawizard
                      </span>
                      <span className="welcome__banner__new">NEW</span>
                    </span>
                  </a>
                </div>
              </div>
            </Scrollbars>
          </div>
        </div>
        <Navigation
          clientWidth={clientWidth}
          clientHeight={clientHeight}
          activeMode={mode}
          {...{ clientWidth, clientHeight, activeMode: mode }}
          setOverviewState={this._setOverviewState}
        />
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    app: state.app,
    progress: state.app.load_progress,
    auth: state.auth,
    welcome: state.welcome,
  }),
  {
    fetchDataForWelcome,
    fetchDataForOverview,
    overviewLoading,
    startWelcomeLoad,
  },
)(Welcome);
