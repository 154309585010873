import { store } from '../index';
import _ from 'lodash';

const { _paq } = window;

const setUserId = id => {
  let userId = id;
  if (!userId) {
    userId = _.get(store.getState(), 'auth.user.id', null);
  }
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    if (userId !== null && _paq) {
      _paq.push(['setUserId', userId]);
    }
  }
};

const eventTracker = {
  trackUserId: id => {
    setUserId(id);
  },
  resetUserId: () => {
    window.Intercom('shutdown');
    if (_paq) {
      _paq.push(['resetUserId']);
    }
  },
  trackEvent: (category, name = '', value = '') => {
    const state = store && store.getState();
    const groupId = _.get(state, 'auth.user.group.id', null);

    if (process.env.REACT_APP_ENVIRONMENT === 'production' && groupId !== 1) {
      if (_paq) {
        _paq.push(['trackEvent', category, name, value]);
      }
    } else {
      console.info('event fake register: ', { category, name, value });
    }
  },

  trackPageView: event => {
    /*const state = store && store.getState();
    const groupId = _.get(state, 'auth.user.group.id', null);

    if (process.env.REACT_APP_ENVIRONMENT === 'production' && groupId !== 1) {
      const userId = _.get(store.getState(), 'auth.user.id', null);
      if (userId) {
        window.Intercom('update', {
          user_id: userId,
        });
      }
      if (_paq) {
        _paq.push(['trackPageView']);
      }
    } else {
      console.info('trackPageView');
    }*/
  },
};

export default eventTracker;
