import React, { Component } from 'react';

import './no-data.scss';

import Icon from '../../../ui/svg-icon';

export default class NoData extends Component {
  static propTypes = {};

  render() {
    return (
      <div className="no-data">
        <div className="no-data-align">
          <Icon name="no-data" />
          <div className="no-data-title">No data for the chosen period!</div>
          Unfortunately, there is no data for this period available.
          <br />
          Please select a larger timeframe.
        </div>
      </div>
    );
  }
}
