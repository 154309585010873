import { ROOT_URL } from '../store/initialState';

export const database = {
  root: ROOT_URL,
  desktop: {
    dbPlayersMeta: `${ROOT_URL}/player/meta/all/`,
  },
  mobile: {
    dbPlayersMeta: `${ROOT_URL}/player/mobile/meta/all/`,
  },
  dbPlayersPerformanceSeason: `${ROOT_URL}/player/performance/all/`,
  dbDecode: `${ROOT_URL}/decoding/all/`,
  dbDetail: `${ROOT_URL}/player/`,
  dbTeam: `${ROOT_URL}/team/`,
  dbMatch: `${ROOT_URL}/match/`,
  dbLoadPlayerDataField: `${ROOT_URL}/player/data-field/`,
  assets: 'https://static.sp7f.com/v2/',
  pdf: {
    details: `${ROOT_URL}/pdf/details/`, // ${ROOT_URL}/pdf/details/:mode/:userId/:sesionId/:leagueId/Details
    compare: `${ROOT_URL}/pdf/compare/`, // ${ROOT_URL}/pdf/compare/:mode/:query/Compare, query = [{playerId, sessionId, leagueId}, ...]
    favorites: `${ROOT_URL}/pdf/favorites/`, // ${ROOT_URL}/pdf/favorites/:mode/:playersId
  },
  csv: {
    player: `${ROOT_URL}/csv/players/`,
    team: `${ROOT_URL}/csv/teams/`,
    match: `${ROOT_URL}/csv/matches/`,
  },
  groups: {
    getGroups: `${ROOT_URL}/group/`,
    addGroup: `${ROOT_URL}/group/`,
    deleteGroup: `${ROOT_URL}/group/`,
    updateGroup: `${ROOT_URL}/group/`,
  },
  userTypes: {
    getTypes: `${ROOT_URL}/user-types/`,
  },
  dbTeamsPerformanceSeason: `${ROOT_URL}/team/performance/all/`,
  dbMatchesPerformance: `${ROOT_URL}/match/performance/all/`,
  dbMatchesLasts: `${ROOT_URL}/match/lasts/`,
  favorites: `${ROOT_URL}/favorite`, // ${ROOT_URL}/favorite/:mode/:id,
  league_comparision: `${ROOT_URL}/team/league_comparison`,
  quote: `${ROOT_URL}/quote/`,
  transfermarkt: 'https://www.transfermarkt.com',
  youtubeSearch: 'https://www.youtube.com/results?search_query=',
  googleNews: 'https://www.google.com/search?q=',
};
