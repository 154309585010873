import React, { Component } from 'react';
import { Link } from 'react-router';

import './nav.scss';

export default class Nav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabs: [
        {
          name: 'All favorites',
          link: '/favorites/player',
        },
        {
          name: 'Shadow teams',
          link: '/favorites/shadow-teams/',
        },
        {
          name: 'Notes',
          link: '/favorites/notes/',
        },
      ],
    };
  }

  renderTab(tab, i) {
    return (
      <Link
        key={i}
        className="col-2 callAction"
        activeClassName="active"
        to={tab.link}
      >
        {tab.name}
      </Link>
    );
  }

  render() {
    return (
      <div className="nav-container">
        <div className="container">
          <div className="nav row">
            {this.state.tabs.map((tab, i) => this.renderTab(tab, i))}
          </div>
        </div>
      </div>
    );
  }
}
