import React, { Component } from 'react';
import PropTypes from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import Icon from '../svg-icon';

import './table-filter.scss';

class TableFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
    };

    this.toggle = this.toggle.bind(this);
    this.renderValue = this.renderValue.bind(this);
  }

  handleClickOutside() {
    this.toggle(false);
  }

  toggle(isOpened) {
    this.setState({ isOpened });
  }

  renderValue(value) {
    const { onChange, name, values } = this.props;

    const isChecked =
      Array.isArray(values) && values.indexOf(value.value) !== -1;

    return (
      <span
        className="table-filter-list__value"
        key={value.value}
        onClick={() => onChange(value.value, name)}
      >
        <span className={`value-checkbox ${isChecked ? 'active' : ''}`}>
          {isChecked ? <span className="icon-check" /> : null}
        </span>
        {value.label}
      </span>
    );
  }

  render() {
    const { isOpened } = this.state;
    const { list, values } = this.props;

    if (!list || !list.length || list.length === 1) {
      return null;
    }

    return (
      <span className="table-filter">
        <Icon
          name="table-filter-action"
          className={`table-filter-action ${
            Array.isArray(values) && values.length ? 'active' : ''
          }`}
          onClick={() => this.toggle(!isOpened)}
        />
        {isOpened ? (
          <span className="table-filter-list">
            {list.map(this.renderValue)}
          </span>
        ) : null}
      </span>
    );
  }
}

TableFilter.propTypes = {
  values: PropTypes.array,
  list: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default enhanceWithClickOutside(TableFilter);
