import './rank.scss';

import React, { Component } from 'react';

import { getGetOrdinal } from '../../../../helper';

import DataItem from '../custom';

export default class Rank extends Component {
  render() {
    const { rank, desc, title, className } = this.props;
    return (
      <DataItem desc={desc} className={`data-rank ${className || ''}`}>
        <h3>{title}</h3>
        <div className="rank-value">
          {rank ? parseInt(rank) : 'N/D'}
          <span className="ordinal">{rank ? getGetOrdinal(rank) : ''}</span>
        </div>
      </DataItem>
    );
  }
}
