import axios from 'axios';

export const GET_LEAGUE = 'COMPETITION_LEVEL:GET_LEAGUE';
export const GET_USER_LEAGUE = 'COMPETITION_LEVEL:GET_USER_LEAGUE';
export const CHANGE_FILTER = 'COMPETITION_LEVEL:CHANGE_FILTER';
export const GET_OVERVIEW_LEAGUES = 'COMPETITION_LEVEL:GET_OVERVIEW_LEAGUES';

export function getLeague(league_id) {
  const request = axios.get(`/leagues/${league_id}`);

  return {
    type: GET_LEAGUE,
    payload: request,
  };
}

export function getUserLeague() {
  const request = axios.get('/leagues/user');

  return {
    type: GET_USER_LEAGUE,
    payload: request,
  };
}

export function changeFilter(name, value) {
  return {
    type: CHANGE_FILTER,
    name,
    value,
  };
}

export function getOverviewLeagues() {
  const request = axios.get('/leagues/competition-level');

  return {
    type: GET_OVERVIEW_LEAGUES,
    payload: request,
  };
}
