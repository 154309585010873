import './navigation.scss';

import React, { Component } from 'react';
import { IndexLink, Link } from 'react-router';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import { bindActionCreators } from 'redux';
import Icon from '../../ui/svg-icon';

import * as OverviewActions from '../../../actions/overview';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this._toggleFilter = this._toggleFilter.bind(this);
  }

  _getActiveGroupClassName(mode) {
    return mode === this.props.activeMode &&
      document.location.pathname !== '/home' &&
      document.location.pathname !== '/overview/rankings/'
      ? 'active'
      : '';
  }

  _toggleFilter() {
    const { clientWidth, activeMode } = this.props;
    const { sidebarShow } = this.props.overview[activeMode].layouts;

    this.props.changeLayout({
      contentWidth: !sidebarShow ? clientWidth * 0.758 : clientWidth,
      tableDragged: 0,
      sidebarShow: !sidebarShow,
      mode: activeMode,
    });

    if (this.props.overview.player.scatterplot.lassoSelection) {
      this.props.setOverviewState({ lasso: !this.state.lasso });
      this.props.lassoSubSelection(null);
    } else this.props.setOverviewState({ selectItem: undefined });
  }

  render() {
    const { activeMode } = this.props;
    const { sidebarShow } = activeMode
      ? this.props.overview[activeMode].layouts
      : false;

    return (
      <div className="overview-navigation">
        <Scrollbars autoHide>
          <div className="nav-container">
            <div>
              <IndexLink to="/home" className="logo" activeClassName="active">
                <Icon
                  className="icon-home"
                  name="wc-home"
                  width="24px"
                  height="23px"
                />
                <Icon
                  className="icon-home__active"
                  name="wc-home-active"
                  width="24px"
                  height="23px"
                />
              </IndexLink>
            </div>
            <div className="controls controls--top">
              <div className="controls-group">
                <Link to={`/search/${activeMode}/`}>
                  <span className="icon-search" />
                </Link>
              </div>
              <div
                className={`controls-group ${this._getActiveGroupClassName(
                  'player',
                )}`}
              >
                <Link to="/overview/player/" activeClassName="active">
                  <Icon
                    className="fill-icon"
                    name="player"
                    width="21px"
                    height="21px"
                  />
                </Link>
                <Link to="/favorites/player/">
                  <Icon
                    className="fill-icon"
                    name="favorite"
                    width="21px"
                    height="21px"
                  />
                </Link>
                <Link to="/compare/player/">
                  <span className="icon-compare" />
                </Link>
              </div>
              <div className="controls-group">
                <Link
                  to="/overview/rankings/"
                  className="rankings"
                  activeClassName="active"
                >
                  <Icon
                    className="fill-icon"
                    name="wc-rankings"
                    width="21px"
                    height="21px"
                  />
                </Link>
              </div>
              <div
                className={`controls-group ${this._getActiveGroupClassName(
                  'team',
                )}`}
              >
                <Link to="/overview/team/" activeClassName="active">
                  <Icon
                    className="fill-icon"
                    name="team"
                    width="24px"
                    height="19px"
                  />
                </Link>
                <Link to="/compare/team/">
                  <span className="icon-compare" />
                </Link>
              </div>
              <div
                className={`controls-group ${this._getActiveGroupClassName(
                  'match',
                )}`}
              >
                <Link to="/overview/match/" activeClassName="active">
                  <span className="text">
                    <Icon
                      className="fill-icon match"
                      name="match"
                      width="24px"
                      height="24px"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="controls controls--bottom">
              <span className="btn help">
                <Icon name="question-circle-big" />
              </span>
              <Link to="/account/general" className="settings">
                <Icon name="settings" width="20px" height="20px" />
              </Link>
              <a
                onClick={this._toggleFilter}
                className={`filter-toggle ${sidebarShow ? '' : 'rotate'}`}
              >
                <span className="icon-hide" />
              </a>
              <a href="#intercom" className="intercom" />
            </div>
            <div id="mask" />
          </div>
        </Scrollbars>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    overview: state.overview,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      ...OverviewActions,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
