import React from 'react';
import { ModeType } from '../../../../types/Dict';
import { IndicatorType, ListType } from '../../../../reducers/overview/types';
import { CollapseGroupType } from './slice/types';
import { Collapse } from './Collapse';
import { SeasonFilter } from './SeasonFilter';
import { LeaguesFilter } from './LeaguesFilter';
import { ListField } from './fields/ListField';
import { FormationFilter } from './FormationFilter';
import { SliderField } from './fields/SliderField';

export function TeamFilters() {
  const mode = ModeType.TEAM;

  return (
    <div className="filters">
      <Collapse name={CollapseGroupType.CORE} type="group" mode={mode}>
        <SeasonFilter mode={mode} />
        <Collapse name={ListType.LEAGUE_ID} type="list" mode={mode}>
          <LeaguesFilter mode={mode} />
        </Collapse>
        <Collapse name={ListType.TEAM_ID} type="list" mode={mode}>
          <ListField mode={mode} listName={ListType.TEAM_ID} withSearch />
        </Collapse>
        <Collapse name={ListType.FORMATION_ID} type="list" mode={mode}>
          <FormationFilter mode={mode} />
        </Collapse>
      </Collapse>
      <Collapse name={CollapseGroupType.OVERALL} type="group" mode={mode}>
        <SliderField mode={mode} sliderName={IndicatorType.SEASON_RATING} />
        <SliderField mode={mode} sliderName={IndicatorType.STABILITY} />
        <SliderField mode={mode} sliderName={IndicatorType.DEFENCE} />
        <SliderField mode={mode} sliderName={IndicatorType.OFFENCE} />
      </Collapse>
      <Collapse name={CollapseGroupType.AGAINST_BALL} type="group" mode={mode}>
        <SliderField mode={mode} sliderName={IndicatorType.TACKLE_DEFENCE} />
        <SliderField mode={mode} sliderName={IndicatorType.TACKLE_OFFENCE} />
        <SliderField mode={mode} sliderName={IndicatorType.INTERCEPT_DEFENCE} />
        <SliderField mode={mode} sliderName={IndicatorType.INTERCEPT_OFFENCE} />
        <SliderField mode={mode} sliderName={IndicatorType.BLOCKED} />
        <SliderField mode={mode} sliderName={IndicatorType.SAVE} />
        <SliderField mode={mode} sliderName={IndicatorType.AERIAL_OFFENCE} />
        <SliderField mode={mode} sliderName={IndicatorType.AERIAL_DEFENCE} />
      </Collapse>
      <Collapse name={CollapseGroupType.ON_BALL} type="group" mode={mode}>
        <SliderField mode={mode} sliderName={IndicatorType.TAKE_ON} />
        <SliderField mode={mode} sliderName={IndicatorType.CROSSING} />
        <SliderField mode={mode} sliderName={IndicatorType.PASS_SHORT} />
        <SliderField mode={mode} sliderName={IndicatorType.PASS_LONG} />
        <SliderField mode={mode} sliderName={IndicatorType.RECEPTION} />
        <SliderField mode={mode} sliderName={IndicatorType.DELIVERY} />
        <SliderField mode={mode} sliderName={IndicatorType.SCORING} />
        <SliderField mode={mode} sliderName={IndicatorType.SCORING_EFFORT} />
      </Collapse>
    </div>
  );
}
