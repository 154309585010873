import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import Confirm from '../../../../confirm';
import { RootState } from '../../../../../types';
import { selectFilterComponents } from '../../../../../reducers/overview/selectors';
import { selectOverviewRankings } from '../../../../../reducers/overview-rankings/selectors';
import { IndicatorType } from '../../../../../types/Indicators';
import { ModeType } from '../../../../../types/Dict';
import {
  ListType,
  MatchListsId,
  PlayerListsId,
  PlayerSlidersId,
  TeamListsId,
  TeamSlidersId,
} from '../../../../../reducers/overview/types';
import { createFilterPreset } from '../../../../../actions/filter-presets';

interface Props {
  mode: ModeType;
}

function DialogContent(cancel, onCheck, isChecked, onInputChange, value) {
  return (
    <div className="preset-prompt__body">
      <input
        type="text"
        name="preset_name"
        placeholder="Enter preset name"
        value={value}
        onChange={onInputChange}
        autoFocus
      />
    </div>
  );
}

export function NewPreset(props: Props) {
  const { mode } = props;
  const dispatch = useDispatch();
  const filterComponents = useSelector((state: RootState) =>
    selectFilterComponents(state, { mode }),
  );
  const overviewRankings = useSelector(selectOverviewRankings);

  const addFilterPreset = presetName => {
    const additionalOptionsName = [
      'include_missing_data',
      'splitted',
      'mode',
      'isDomestic',
      'isByYear',
    ];

    let filterLine = {};
    let filterSlider = {};
    let filterSwitcher = {};
    const additionalOptions = {};

    let changedList = _.pickBy(filterComponents.list, (list, listName) => {
      return filterComponents.changeFilter.list.indexOf(listName) !== -1;
    });

    for (const filterName in changedList) {
      const additional = _.pick(changedList[filterName], additionalOptionsName);

      if (_.size(additional)) {
        additionalOptions[filterName] = additional;
      }

      filterLine[filterName] = changedList[filterName].data.map(firstLevel => {
        if (!firstLevel.items) return firstLevel;

        return firstLevel.items.map(secondLevel => {
          if (!secondLevel.items) return secondLevel;

          return secondLevel.items.map(thirdLevel => {
            if (!thirdLevel.items) return thirdLevel;
            return thirdLevel.items.map(fourthLevel => fourthLevel);
          });
        });
      });

      filterLine[filterName] = _.flattenDeep(filterLine[filterName]);
      filterLine[filterName] = filterLine[filterName]
        .filter(line => line.active)
        .map(line => line.id);
    }

    for (let sliderName in filterComponents.slider) {
      const slider = filterComponents.slider[sliderName];
      const additional = _.pick(slider, additionalOptionsName);

      if (_.size(additional)) {
        additionalOptions[sliderName] = additional;
      }

      if (
        sliderName !== IndicatorType.SEASON_ID &&
        (slider.data[0] !== slider.minMax[0] ||
          slider.data[1] !== slider.minMax[1])
      ) {
        filterSlider[sliderName] = slider.data;
      }
    }

    for (const switcherName in filterComponents.switcher) {
      const switcher = filterComponents.switcher[switcherName];

      if (switcher.data) {
        const additional = _.pick(switcher, additionalOptionsName);

        if (_.size(additional)) {
          additionalOptions[switcherName] = additional;
        }

        filterSwitcher[switcherName] = switcher.data;
      }
    }

    const preset = {
      list: _.map(filterLine, (data, name) => ({
        data,
        name,
        ...additionalOptions[name],
      })),
      slider: _.map(filterSlider, (data, name) => ({
        data,
        name,
        ...additionalOptions[name],
      })),
      switcher: _.map(filterSwitcher, (data, name) => ({
        data,
        name,
        ...additionalOptions[name],
      })),
      changeFilter: {
        list: filterComponents.changeFilter.list.map((listName: ListType) => {
          switch (mode) {
            case ModeType.PLAYER:
              return PlayerListsId[listName];
            case ModeType.TEAM:
              return TeamListsId[listName];
            case ModeType.MATCH:
              return MatchListsId[listName];
          }
        }),
        slider: filterComponents.changeFilter.slider.map(
          (sliderName: IndicatorType) => {
            switch (mode) {
              case ModeType.PLAYER:
                return PlayerSlidersId[sliderName];
              case ModeType.TEAM:
                return TeamSlidersId[sliderName];
            }
          },
        ),
      },
      rankingsSettings: {
        rankBy: overviewRankings.settings.rankBy,
        colorBy: overviewRankings.settings.colorBy,
      },
    };

    dispatch(createFilterPreset({ mode, preset, name: presetName }));
  };

  const onClick = async event => {
    event.preventDefault();

    let { inputValue } = await Confirm(DialogContent, {
      /*context: this,*/
      cancelText: 'Cancel',
      confirmText: 'Save',
      className: 'preset-prompt',
    });

    if (!inputValue) inputValue = 'Untitled preset';
    addFilterPreset(inputValue);
  };

  return (
    <div className="filters__list_item level-1" onClick={onClick}>
      <span className="icon-plus" />
      Save filter as preset
    </div>
  );
}
