import React, { Component } from 'react';
import Icon from '../../svg-icon';
import Image from '../../image';

export default class DataItem extends Component {
  render() {
    const {
      desc,
      children,
      reverse,
      text,
      className,
      isAccessGranted,
      tip,
      image,
      aspectRatio,
      imageClass,
    } = this.props;

    if (!text && text !== 0 && !children && isAccessGranted !== false)
      return null;

    return (
      <div
        className={`data-item ${reverse ? 'reverse' : ''} ${className || ''}`}
        data-tooltip-content={tip || ''}
        data-tooltip-id="TooltipHelp"
        data-type="light"
      >
        {isAccessGranted === false ? <Icon name="icon-lock" /> : null}
        {text || text === 0 ? (
          <div className="data-text">
            {image ? (
              <Image
                className={imageClass}
                aspectRatio={aspectRatio}
                src={image}
              />
            ) : null}
            {text}
          </div>
        ) : null}
        {children}
        {desc || desc === 0 ? <div className="data-desc">{desc}</div> : null}
      </div>
    );
  }
}
