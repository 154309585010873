// reducers are modifiying! the state of the app
// are pure functions: (previousState, action) => newState
// pure means: Given the same arguments, it should calculate the next state and return it.
// No surprises. No splayer_ide effects. No API calls. No mutations. Just a calculation.
// define which action triggers which function and result in what state
import _ from 'lodash';
import * as types from '../constants';

import {
  getPerformanceFromAnalyse,
  getPlayerPerformanceFromAnalyse,
} from '../helper/index';

import globalInitialState from '../store/initialState.ts';

// actually write the reducer
export default details(
  {},

  {
    [types.RESET_COMPARE](state, action) {
      const data_ = state.data.concat();

      // init loading, reset all
      data_.map((d, i) => {
        data_[i].player_id = null;
        data_[i].league = null;
        data_[i].season = [];
        data_[i].data = {};
      });
      return {
        ...state,
        data: data_,
      };
    },

    [types.CHANGE_EDIT](state, action) {
      const data_ = state.data.concat();

      data_[action.num].edit = action.edit;

      return {
        ...state,
        data: data_,
      };
    },

    [types.ADD_COMPARE](state, action) {
      const [compare, id, season, league, formation, position, num, mode] =
        action.payload;

      if (!_.get(compare, 'data.data', null)) {
        alert(
          'There was an error with the data, please contact the team via the feedback button in the bottom right corner.',
        );
        return state;
      }

      const data_ = state.data.concat();

      const {
        mean,
        leagueAnalysis,
        performance,
        epiRating,
        realStats,
        league_comparison_full,
        isAccessGranted,
      } = compare.data.data;

      const indicators = [
        'season_rating',
        'stability',
        'defence',
        'offence',
        'games',
        'season_mins',
        'goals',
        'assists',
        'intercept_defence',
        'save',
        'claim',
        'keeper_throw',
        'pass_long',
        'pass_short',
        'reception',
        'delivery',
        'tackle_defence',
        'tackle_offence',
        'intercept_defence',
        'intercept_offence',
        'blocked',
        'save',
        'aerial_offence',
        'aerial_defence',
        'take_on',
        'crossing',
        'pass_short',
        'pass_long',
        'reception',
        'delivery',
        'scoring',
        'scoring_effort',
        'real_tackles',
        'real_tackles_pc_',
        'real_aerial_duels',
        'real_aerial_duels_pc_',
        'real_clearances',
        'real_interception',
        'real_blocks',
        'real_free_kicks',
        'real_corners',
        'real_dribblings',
        'real_dribblings_pc_',
        'real_shots',
        'real_in_box_shots',
        'real_goal_conversion',
        'real_key_passes',
        'real_passes',
        'real_pass_pc_',
        'real_crosses',
        'real_long_balls',
        'real_through_balls',
        'real_chances_created',
        'take_on',
        'crossing',
        'pass_short',
        'pass_long',
        'corner',
        'delivery',
        'scoring',
        'scoring_effort',
      ];

      let data = {};

      if (mode === 'team') {
        data_[num].team_id = id;
        data = {
          ...getPerformanceFromAnalyse(leagueAnalysis, { id }, indicators),
          ...mean,
        };
      } else {
        try {
          const epiRatingByPlayerId = _.keyBy(epiRating, 'player_id');
          const realStatsByPlayerId = _.keyBy(realStats, 'player_id');

          const epiWithReal = _.map(
            epiRatingByPlayerId,
            (indicators, player_id) => ({
              ...indicators,
              ...realStatsByPlayerId[player_id],
            }),
          );

          data_[num].player_id = id;
          data_[num].team_id = performance.team_id;
          data = {
            ...getPlayerPerformanceFromAnalyse(
              epiWithReal,
              { player_id: id },
              indicators,
            ),
            ...mean,
            season_mins: performance.mins,
            games: performance.games,
            goals: performance.goals,
            assists: performance.assists,
          };
        } catch (error) {
          console.error(error);
        }
      }

      data_[num].player_id = id;
      data_[num].league = league;
      data_[num].season = season;
      data_[num].formation = formation;
      data_[num].position = position;
      data_[num].data = data;
      data_[num].edit = false;
      data_[num].isAccessGranted = isAccessGranted;

      return {
        ...state,
        data: data_,
      };
    },

    [types.REMOVE_COMPARE](state, action) {
      const data_ = state.data.concat();

      // data_.map(function(d,i) {
      //   if(d.id == action.num) {
      const i = action.num;

      // step up with other stuff
      if (i == 2) {
        data_[2] = _.clone(data_[3]);
        data_[3].player_id = null;
        data_[3].league = null;
        data_[3].season = null;
        data_[3].data = {};
        data_[3].edit = true;
      } else if (i == 1) {
        data_[1] = _.cloneDeep(data_[2]);
        data_[2] = _.cloneDeep(data_[3]);
        data_[3].player_id = null;
        data_[3].league = null;
        data_[3].season = null;
        data_[3].data = {};
        data_[3].edit = true;
      } else if (i == 0) {
        data_[0] = _.cloneDeep(data_[1]);
        data_[1] = _.cloneDeep(data_[2]);
        data_[2] = _.cloneDeep(data_[3]);
        data_[3].player_id = null;
        data_[3].league = null;
        data_[3].season = null;
        data_[3].data = {};
        data_[3].edit = true;
      }

      data_[i].player_id = null;
      data_[i].league = null;
      data_[i].season = null;
      data_[i].data = {};
      data_[i].edit = true;

      return {
        ...state,
        data: data_,
      };
    },

    [types.AUTH_LOGOUT](state, action) {
      return { ...state, ...globalInitialState.compare };
    },
  },
);

// reducer =  takes any kind of action - along with the current state - and invokes the core function that matches the action
function details(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}
