import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import SwipeableViews from 'react-swipeable-views';
import moment from 'moment';
import _ from 'lodash';

import NavBar from './layouts/header/nav-bar';
import MatchHeader from './match/header';
import Summary from './match/summary';
import PlayerStats from './match/playerStats';
import LineUps from './match/lineups';
import TeamCompare from './match/team-compare';

class Match extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabs: ['summary', 'lineups', 'teamCompare', 'playerStats'],
      index: 0,
    };

    this.onChangeIndex = this.onChangeIndex.bind(this);
    this.resizeHandler = this.resizeHandler.bind(this);
  }

  componentWillMount() {
    const { tab } = this.props;
    const { tabs } = this.state;
    const index = tabs.indexOf(tab);

    index > -1 && this.setState({ index });
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);
  }

  componentWillUpdate(nextProps) {
    const { tabs, index } = this.state;
    const nextTab = nextProps.tab;
    const nextIndex = tabs.indexOf(nextTab);

    nextIndex > -1 &&
      index !== nextIndex &&
      this.setState({ index: nextIndex });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  onChangeIndex(nextIndex) {
    const { index } = this.state;
    const tab = this.state.tabs[nextIndex];
    const { id, current_season, current_league, mode } = this.props.details;

    index !== nextIndex &&
      browserHistory.push(
        `/details/${mode}/${id}/${current_league}/${current_season}/${tab}`,
      );
  }

  resizeHandler() {
    this.updateHeight && this.updateHeight();
  }

  render() {
    const { tab, details } = this.props;
    const { information, matchDays, isAccessGranted } = details;
    const { onChangeIndex } = this;
    const { index } = this.state;

    const date = _.get(information, 'date', null);

    if (new Date(date) > new Date()) {
      return (
        <div>
          <MatchHeader
            {...{ information, matchDays, isAccessGranted }}
            isFuture={true}
          />
          <div>
            <div className="summary summary--match">
              <div className="content_group summary_profile">
                <h2 className="future-match">
                  This match will start on {moment(date).format('YYYY-MM-DD')}.
                  Come back after match.
                </h2>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <MatchHeader {...{ information, matchDays, isAccessGranted }} />

        <NavBar {...{ tab }} />

        <SwipeableViews
          style={{ width: '100%' }}
          animateHeight
          enableMouseEvents={false}
          ignoreNativeScroll
          index={index}
          onChangeIndex={onChangeIndex}
          action={actions => {
            this.updateHeight = actions.updateHeight;
          }}
        >
          <div>
            <div
              className={`content-container ${
                !isAccessGranted ? 'blurred' : ''
              }`}
            >
              <Summary />
            </div>
          </div>

          <div>
            <div
              className={`content-container ${
                !isAccessGranted ? 'blurred' : ''
              }`}
            >
              <LineUps />
            </div>
          </div>

          <div>
            <div
              className={`content-container ${
                !isAccessGranted ? 'blurred' : ''
              }`}
            >
              <TeamCompare />
            </div>
          </div>

          <div>
            <div
              className={`content-container ${
                !isAccessGranted ? 'blurred' : ''
              }`}
            >
              <PlayerStats />
            </div>
          </div>
        </SwipeableViews>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    details: state.details,
    app: state.app,
    overview: state.overview,
  };
}

export default connect(mapStateToProps)(Match);
