import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Icon from '../../../../ui/svg-icon';
import { ListType } from '../../../../../reducers/overview/types';
import { CollapseGroupType } from '../slice/types';
import { selectListTitle } from '../../../../../reducers/overview/selectors';
import { selectCollapseGroup, selectCollapseList } from '../slice/selectors';
import { ModeType } from '../../../../../types/Dict';
import { RootState } from '../../../../../types';
import { useOverviewFilterSlice } from '../slice';

interface CommonProps {
  open?: boolean;
  icon?: string;
  children: any;
  height?: number;
  mode: ModeType;
  onToggle?: (open: boolean) => void;
  title?: string;
}

type ConditionalProps =
  | {
      name: CollapseGroupType;
      type: 'group';
    }
  | {
      name: ListType;
      type: 'list';
    }
  | {
      name?;
      type?;
    };

type Props = CommonProps & ConditionalProps;

export function Collapse(props: Props) {
  const { name, open, type, height, icon, children, mode, onToggle } = props;
  const dispatch = useDispatch();
  const { actions } = useOverviewFilterSlice();

  let title = useSelector((state: RootState) =>
    selectListTitle(state, { mode, listName: name as ListType }),
  );
  const collapseList = useSelector((state: RootState) =>
    selectCollapseList(state, { mode, listName: name as ListType }),
  );
  const collapseGroup = useSelector((state: RootState) =>
    selectCollapseGroup(state, { mode, groupName: name as CollapseGroupType }),
  );

  let isOpen: boolean = open || false;
  let maxHeight: number | undefined = height || undefined;
  let iconName: string | null = icon || null;

  if (type === 'list' && collapseList) {
    maxHeight = collapseList?.height;
    isOpen = collapseList?.open;
    iconName = 'icon-collapse';
  } else if (type === 'group' && collapseGroup) {
    maxHeight = collapseGroup?.height;
    isOpen = collapseGroup?.open;
    iconName = collapseGroup?.icon;
  }

  const style = { maxHeight: isOpen ? maxHeight : 0 };

  const onClick = () => {
    /*if (name === ListType.TEAM_ID && !open) {
      document.getElementById('searchinput')?.focus();
    }*/
    if (onToggle) {
      onToggle(!isOpen);
    } else {
      if (type === 'group') {
        dispatch(actions.toggleGroupCollapse({ groupName: name, mode }));
      } else if (type === 'list') {
        dispatch(actions.toggleListCollapse({ listName: name, mode }));
      }
    }
  };

  return (
    <div className={`section ${name}`}>
      {props.title || title || iconName ? (
        <button
          className={`${type === 'group' ? 'h1' : 'h2'} ${
            isOpen ? 'open' : ''
          }`}
          onClick={onClick}
        >
          {iconName !== 'icon-collapse' ? (
            <div className={`${iconName} ${isOpen ? 'open' : ''} `} />
          ) : null}
          {props.title || title}
          <Icon
            name="overview-expand"
            className={`list-icon-collapse ${isOpen ? 'open' : ''} `}
          />
        </button>
      ) : null}
      <div className={`sectionContent ${isOpen ? 'open' : ''}`} style={style}>
        {children}
      </div>
    </div>
  );
}
