import './login.scss';

import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';

import { resetPassword } from '../../actions/auth';
import { loading } from '../../actions/app';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      successEmail: false,
      successPassword: false,
      errorEmail: false,
      errorToken: false,
      changePassword: {
        newPassword: '',
        repeatPassword: '',
        error: false,
      },
    };

    this._submitEmail = this._submitEmail.bind(this);
    this._submitPassword = this._submitPassword.bind(this);
    this._renderAuthContainer = this._renderAuthContainer.bind(this);
  }

  _submitEmail(event) {
    event.preventDefault();
    let email = this.refs.email.value;

    this.props.loading(true);

    this.props
      .resetPassword({ email })
      .then(res => {
        if (res.payload.data.success) {
          this.setState({ successEmail: true });
        } else {
          this.setState({ errorEmail: res.payload.data.message });
        }
        this.props.loading(false);
      })
      .catch(error => {
        console.error(error);
        this.props.loading(false);
      });
  }

  _submitPassword(event) {
    event.preventDefault();

    const { changePassword } = this.state;

    if (
      changePassword.newPassword.length < 6 ||
      changePassword.repeatPassword < 6
    ) {
      this.setState({
        changePassword: {
          ...changePassword,
          error: 'Please enter at least 6 characters.',
        },
      });
    } else if (changePassword.newPassword !== changePassword.repeatPassword) {
      this.setState({
        changePassword: {
          ...changePassword,
          error: 'Please enter the same password',
        },
      });
    } else {
      this.setState({
        changePassword: { ...changePassword, error: false },
        successPassword: true,
      });
      this.props
        .resetPassword({
          password: changePassword.newPassword,
          token: this.props.to,
        })
        .then(res => {
          if (res.payload.data.success) {
            this.setState({
              changePassword: { ...changePassword, error: false },
              successPassword: true,
            });
          } else {
            this.setState({
              changePassword: {
                ...changePassword,
                error: res.payload.data.message,
              },
              successPassword: false,
            });
          }
        });
    }
  }

  _onInputPassword(value, input) {
    let changePassword = _.cloneDeep(this.state.changePassword);
    changePassword[input] = value;
    changePassword['error'] = false;

    this.setState({ changePassword });
  }

  _renderAuthContainer() {
    if (this.state.errorToken) {
      return (
        <div className="auth-container">
          <div className="auth-title">That link isn’t working.</div>
          <p className="auth-text">
            Password reset links expire after 24 hours if unused.
          </p>
        </div>
      );
    }
    if (this.state.successPassword) {
      return (
        <div className="auth-container">
          <div className="auth-title">Password updated</div>
          <p className="auth-text">
            Your password has been updated successfully.
          </p>
        </div>
      );
    } else if (this.props.to) {
      return (
        <div className="auth-container">
          <div className="auth-title">Password Reset</div>
          <p className="auth-text">Enter your new password for your account.</p>
          <form className="auth-login" onSubmit={this._submitPassword}>
            <input
              type="password"
              value={this.state.changePassword.newPassword}
              onChange={event =>
                this._onInputPassword(event.target.value, 'newPassword')
              }
              placeholder="New password"
            />
            <input
              type="password"
              value={this.state.changePassword.repeatPassword}
              onChange={event =>
                this._onInputPassword(event.target.value, 'repeatPassword')
              }
              placeholder="Confirm password"
            />
            <div
              className={`error-message ${
                this.state.changePassword.error ? 'visible' : ''
              }`}
            >
              {this.state.changePassword.error}
            </div>
            <button type="submit">Change password</button>
          </form>
        </div>
      );
    } else if (this.state.successEmail) {
      return (
        <div className="auth-container">
          <div className="auth-title">Email sent!</div>
          <p className="auth-text">
            Check your inbox for instructions from us on how to reset your
            password.
          </p>
        </div>
      );
    } else {
      return (
        <div className="auth-container">
          <div className="auth-title">Password Reset</div>
          <p className="auth-text">
            To reset your password, enter the email address you use to sign in
            to <b>scoutpanel.com</b>.
          </p>
          <form className="auth-login" onSubmit={this._submitEmail}>
            <input type="email" ref="email" placeholder="E-mail" required />
            <div
              className={`error-message ${
                this.state.errorEmail ? 'visible' : ''
              }`}
            >
              {this.state.errorEmail}
            </div>
            <button type="submit">GET RESET LINK</button>
          </form>
        </div>
      );
    }
  }

  componentWillMount() {
    if (this.props.to) {
      this.props
        .resetPassword({ token: this.props.to })
        .then(res => {
          if (!res.payload.data.success) {
            this.setState({ errorToken: true });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  render() {
    return (
      <div className="auth-align">
        {this._renderAuthContainer()}
        <div className="auth-helpers">
          <Link to="/auth/login" className="pull-left">
            Sign in
          </Link>
          <a className="pull-right" href="mailto:support@scoutpanel.com">
            Contact support
          </a>
        </div>
      </div>
    );
  }
}

export default connect(
  state => {
    return {
      signInError: state.auth.signInError,
    };
  },
  {
    resetPassword,
    loading,
  },
)(Login);
