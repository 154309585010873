import axios from 'axios';

import * as types from '../constants';
import eventTracker from '../helper/event-tracker';

import { database } from '../constants/database';

export function removeItem(num) {
  return {
    type: types.REMOVE_COMPARE,
    num,
  };
}

export function addItem({
  id,
  season,
  league,
  formation,
  position,
  num,
  mode,
}) {
  const request = Promise.all([
    axios.get(
      `${
        mode === 'team' ? database.dbTeam : database.dbDetail
      }${id}/${season.join('/')}/${league}${
        formation ? `?formation=${formation}` : ''
      }${position ? `?position=${position}` : ''}`,
    ),
    Promise.resolve(id),
    Promise.resolve(season),
    Promise.resolve(league),
    Promise.resolve(formation),
    Promise.resolve(position),
    Promise.resolve(num || 0),
    Promise.resolve(mode),
  ]);

  eventTracker.trackEvent('Compare', `${mode} added to compare`, mode);

  return {
    type: types.ADD_COMPARE,
    payload: request,
  };
}

export function changeEdit(num, status) {
  return {
    type: types.CHANGE_EDIT,
    edit: status,
    num,
  };
}

export function reset() {
  return {
    type: types.RESET_COMPARE,
  };
}
