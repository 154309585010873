import axios from 'axios';
import { FETCH_SEARCH, RESET_SEARCH, START_FETCH_SEARCH } from '../constants';

let canCancel = false;

export function fetchSearch(mode, needle, page = 0) {
  if (canCancel && page === 0) canCancel.cancel();

  const CancelToken = axios.CancelToken.source();
  canCancel = CancelToken;
  const request = axios.get('/search', {
    cancelToken: CancelToken.token,
    params: {
      mode,
      needle,
      page,
    },
  });

  return {
    type: FETCH_SEARCH,
    payload: request,
  };
}

export function resetSearch() {
  return {
    type: RESET_SEARCH,
  };
}

export function startSearch(mode) {
  return {
    type: START_FETCH_SEARCH,
    payload: mode,
  };
}
