import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { removeDiacritics } from '../../../helper';

import './groups.scss';

import Group from './group';

import { addGroup, deleteGroup, updateGroup } from '../../../actions/groups';

class Groups extends Component {
  static propTypes = {
    addGroup: PropTypes.func,
    updateGroup: PropTypes.func,
    deleteGroup: PropTypes.func,
    auth: PropTypes.object,
    groups: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.renderGroupsList = this.renderGroupsList.bind(this);
    this.save = this.save.bind(this);
    this.add = this.add.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.changeSearch = this.changeSearch.bind(this);

    this.state = {
      search: '',
    };
  }

  componentWillMount() {
    const { auth } = this.props;

    if (auth.user.role.name !== 'Admin') {
      browserHistory.replace('/account/general');
    }
  }

  save(group) {
    this.props.updateGroup(group);
  }

  add(group) {
    this.props.addGroup(group);
  }

  deleteGroup(id) {
    this.props.deleteGroup(id);
  }

  renderGroupsList() {
    const { groups } = this.props;
    const { search } = this.state;

    if (!groups) return null;

    return _.sortBy(groups, ['id'])
      .filter(group => {
        if (search.length) {
          const searchWords = search
            .split(' ')
            .filter(word => word && word.length)
            .map(word => removeDiacritics(word));
          const finedWords = searchWords.filter(
            word =>
              removeDiacritics(`${group.name}`)
                .toLowerCase()
                .trim()
                .indexOf(word.toLowerCase()) >= 0,
          );
          if (finedWords.length !== searchWords.length) return false;
        }
        return true;
      })
      .map(group => (
        <Group
          key={group.id}
          group={group}
          onDelete={this.deleteGroup}
          onSave={this.save}
          search={search}
        />
      ));
  }

  changeSearch(e) {
    this.setState({ search: e.target.value });
  }

  render() {
    const { search } = this.props;

    return (
      <div className="groups">
        <div className="users-list-search">
          <input
            type="text"
            placeholder="Search by group name"
            value={search}
            onChange={this.changeSearch}
          />
        </div>
        <table className="groups-list">
          <thead>
            <tr>
              <th width="50">ID</th>
              <th>Group</th>
              <th>Team</th>
              <th>Team Color</th>
              <th>Number of leagues</th>
              <th>Number of users</th>
              <th>API</th>
              <th width="122" />
            </tr>
          </thead>
          <Group onDelete={this.deleteGroup} onAdd={this.add} />
          {this.renderGroupsList()}
        </table>
      </div>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    groups: state.groups,
  }),
  {
    addGroup,
    deleteGroup,
    updateGroup,
  },
)(Groups);
