import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { ColorScale } from 'd3-react-touch';
import PropTypes from 'prop-types';

import './content.scss';

import { addItem, changeEdit, removeItem } from '../../../actions/compare';

import { BENCHMARK_COLOR, COLOR_SCALE } from '../../../constants/index';

// Load sub-components
import CompareAdd from './content/add';
import CompareCard from './content/card';
import CompareDetails from './content/details';
import { loadPlayerDataField } from '../../../actions/app';

class CompareSummary extends Component {
  static propTypes = {
    app: PropTypes.object,
    compare: PropTypes.object,
    overview: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.selectItem = this.selectItem.bind(this);
    this.renderCompareAdd = this.renderCompareAdd.bind(this);
  }

  componentDidMount = () => {
    const { id, second } = this.props;

    if (id) this.selectItem(id, 0);
    if (second) this.selectItem(second, 1);
  };

  selectItem(id_, num) {
    const { addItem, mode } = this.props;

    const itemActive = _.split(id_, '-');

    const id = parseInt(itemActive[0], 10);
    const league = itemActive[1] ? parseInt(itemActive[1], 10) : null;

    let season = [];
    let position = 0;
    let formation = 0;

    if (itemActive.length > 4) {
      const commaIndex = id_.indexOf(',');

      season = itemActive[2]
        ? id_.slice(commaIndex - 10, commaIndex + 11).split(',')
        : [];
      position =
        mode === 'player' ? parseInt(id_.slice(commaIndex + 12), 10) : null;
      formation =
        mode === 'team' ? parseInt(id_.slice(commaIndex + 12), 10) : null;
    } else {
      season = itemActive[2] ? [+itemActive[2]] : [];
      position =
        mode === 'player' && itemActive[3] ? parseInt(itemActive[3], 10) : null;
      formation =
        mode === 'team' && itemActive[3] ? parseInt(itemActive[3], 10) : null;
    }

    // get data from server
    addItem({
      id,
      league,
      season,
      num,
      formation,
      position,
      mode,
    }).catch(error => {
      console.error(error);
    });
  }

  renderCompareAdd(
    count,
    selected_id = null,
    selected_season = null,
    selected_league = null,
    selected_formation = null,
    selected_position = null,
  ) {
    const { select } = this.props.compare;
    const {
      seasons,
      leagues,
      countries,
      playersListAll,
      teamsListAll,
      formations,
      positions,
    } = this.props.app;
    const {
      addItem,
      id,
      mode,
      compare,
      loadPlayerDataField,
      user,
      removeItem,
    } = this.props;
    const { data } = compare;

    return (
      <CompareAdd
        {...{
          select,
          id,
          addItem,
          count,
          seasons,
          leagues,
          formations,
          positions,
          countries,
          playersListAll,
          teamsListAll,
          selected_id,
          selected_season,
          selected_league,
          selected_formation,
          selected_position,
          data,
          mode,
          loadPlayerDataField,
          user,
          removeItem,
        }}
      />
    );
  }

  render() {
    const { data } = this.props.compare;

    const { seasons, leagues, teams, positions, formations, playersMetaData } =
      this.props.app;
    const { removeItem, changeEdit, id, mode, addItem } = this.props;

    const leagueSeasonPositonList =
      this.props.overview.player.filterComponents.list;

    // show/or hide add card based on amout of selected players
    let count = 0;
    const that = this;

    data.map((d, i) => {
      if (!_.isEmpty(d.data) || d.isAccessGranted === false) ++count;
    });

    return (
      <div className="content-container">
        <div className="container">
          <div className="content">
            <div className="row compare_content_cards">
              {data.map((d, i) => {
                if (
                  (!_.isEmpty(d.data) || d.isAccessGranted === false) &&
                  !d.edit
                ) {
                  return (
                    <div
                      className={`col-3 compare_content_card card card_${i}`}
                      key={i}
                    >
                      <CompareCard
                        item={d}
                        num={i}
                        {...{
                          id,
                          removeItem,
                          changeEdit,
                          data,
                          leagueSeasonPositonList,
                          seasons,
                          leagues,
                          teams,
                          positions,
                          formations,
                          playersMetaData,
                          mode,
                          addItem,
                        }}
                      />
                    </div>
                  );
                }
                if (!_.isEmpty(d.data) && !!d.edit) {
                  return (
                    <div
                      className="col-3 compare_content_card_add card"
                      key={i}
                    >
                      {that.renderCompareAdd(
                        i,
                        d.player_id,
                        d.season,
                        d.league,
                        d.formation,
                        d.position,
                      )}
                    </div>
                  );
                }
              })}

              {count < 4 ? (
                <div className="col-3 compare_content_card_add card">
                  {this.renderCompareAdd(count)}
                </div>
              ) : null}
            </div>
            {count > 0 ? (
              <div className="compare-legend">
                <ColorScale
                  domain={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                  colors={COLOR_SCALE}
                  benchmarkColor={BENCHMARK_COLOR}
                />
                <div className="mean-description">
                  {mode === 'team'
                    ? 'Mean values are formation- & league-specific'
                    : 'Mean values are position- & league-specific'}
                </div>
              </div>
            ) : null}
            <CompareDetails data={data} mode={mode} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    app: state.app,
    compare: state.compare,
    overview: state.overview,
    user: state.auth.user,
  }),
  {
    addItem,
    changeEdit,
    removeItem,
    loadPlayerDataField,
  },
)(CompareSummary);
