const PLAYER = Object.freeze({
  META: '/player/meta/all',
  META_MOBILE: '/player/mobile/meta/all',
  PERFORMANCE: '/player/performance/all/{groupBy}/{season}',
});

const MATCH = Object.freeze({
  LASTS: '/match/lasts',
  PERFORMANCE: '/match/performance/all',
});

const TEAM = Object.freeze({
  PERFORMANCE: '/team/performance/all/{season}',
});

export const BASE_URL =
  process.env.REACT_APP_API_URL || 'https://api.scoutpanel.com';

export const ENDPOINTS = Object.freeze({
  WELCOME: '/welcome/',
  WELCOME_PRESET_REFRESH: '/welcome/refresh',
  DECODE: '/decoding/all',
  FAVORITE: '/favorite',
  PRESETS: '/presets',
  FAVORITE_LEAGUES: '/favorite-leagues',
  MATCH,
  PLAYER,
  TEAM,
});
