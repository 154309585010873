import * as types from '../constants';
import _ from 'lodash';

import globalInitialState from '../store/initialState.ts';

// actually write the reducer
export default details(
  {},

  {
    [types.LOAD_DATA_DETAILS](state, action) {
      const [
        details,
        id,
        req_league,
        current_season,
        current_formation,
        current_position,
        mode,
      ] = action.payload;

      if (details.data.error) {
        console.error(details.data.message);
        alert('Sorry, an error occurred.');
        return state;
      }

      const performance = details.data.data.performance,
        history = details.data.data.history || [],
        history_details = details.data.data.history_details || [],
        similar = details.data.data.similar,
        position = details.data.data.position,
        information = details.data.data.information || {},
        squad_list = details.data.data.squad_list || [],
        squad_analyse = details.data.data.squad_analyse || [],
        league_table = details.data.data.league_table || [],
        lineups = details.data.data.lineups || [],
        league_analyse = details.data.data.league_analyse || [],
        matchdays_range = details.data.data.matchdays_range
          ? details.data.data.matchdays_range[0]
          : {},
        mean = details.data.data.mean || {},
        actual_performance = details.data.data.actual_performance || null,
        league_comparison = details.data.data.league_comparison || [],
        league_comparison_full = details.data.data.league_comparison || [],
        epiRating = details.data.data.epiRating || [],
        realStats = details.data.data.realStats || [],
        minCondition = details.data.data.minCondition || 250,
        isAccessGranted = details.data.data.isAccessGranted;

      return {
        ...state,
        id,
        performance,
        performance_current: performance,
        history,
        history_details,
        similar,
        position,
        information,
        current_season,
        current_league: req_league,
        current_formation,
        current_position,
        squad_list,
        squad_analyse,
        league_table,
        lineups,
        league_analyse,
        league_comparison,
        league_comparison_full,
        matchdays_range,
        mean,
        actual_performance,
        mode,
        epiRating,
        realStats,
        minCondition,
        isAccessGranted,
      };
    },

    [types.LOAD_MATCH_DETAILS](state, action) {
      const [details, id, current_season, current_league, mode] =
        action.payload;
      const information = details.data.data.information || {};
      const {
        playerStats,
        performance,
        matchDay,
        matchDays,
        players,
        isAccessGranted,
      } = details.data.data || [];

      return {
        ...state,
        id,
        current_season,
        current_league,
        information,
        playerStats,
        performance,
        matchDay,
        matchDays,
        players: players && players.length ? _.keyBy(players, 'player_id') : {},
        mode,
        isAccessGranted,
      };
    },

    [types.CHANGE_SEASON](state, action) {
      return {
        ...state,
        current_season: action.season_id,
        current_formation: 0,
        current_position: 0,
      };
    },

    [types.CHANGE_FORMATION](state, action) {
      return {
        ...state,
        current_formation: action.current_formation,
      };
    },

    [types.CHANGE_POSITION](state, action) {
      return {
        ...state,
        current_position: action.current_position,
      };
    },

    [types.CHANGE_LEAGUE](state, action) {
      return {
        ...state,
        current_league: action.league_id,
        current_formation: 0,
        current_position: 0,
      };
    },

    [types.AUTH_LOGOUT](state, action) {
      return { ...state, ...globalInitialState.details };
    },

    [types.CHANGE_LEAGUE_COMPARISON_MATCHDAYS](state, action) {
      const league_comparison = action.payload.data.data.error
        ? state.league_comparison
        : action.payload.data.data;
      return { ...state, league_comparison };
    },

    [types.DATA_ERROR_MODAL](state, action) {
      return {
        ...state,
        dataModalError: action.status,
        dataModalErrorMode:
          action.status && action.mode ? action.mode : state.dataModalErrorMode,
      };
    },
  },
);

// reducer =  takes any kind of action - along with the current state - and invokes the core function that matches the action
function details(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}
