import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router';
import { BarChart } from 'd3-react-touch';
import Icon from '../../ui/svg-icon';

import './team-compare.scss';

import Image from '../../ui/image';

import lang from '../../../lang';
import { createBarChartsCompare } from '../../../helper/';
import { database } from '../../../constants/database';

class TeamCompare extends Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.innerWidth,
      indicators: [
        'tackle_offence',
        'tackle_defence',
        'intercept_offence',
        'intercept_defence',
        'blocked',
        'save',
        'aerial_offence',
        'aerial_defence',
        'take_on',
        'crossing',
        'pass_short',
        'pass_long',
        'corner',
        'delivery',
        'scoring',
        'scoring_effort',
      ],
    };

    this.renderBars = this.renderBars.bind(this);
    this._resizeHandler = this._resizeHandler.bind(this);
  }

  componentDidMount() {
    this._resizeHandler();
    window.addEventListener('resize', this._resizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeHandler);
  }

  _resizeHandler() {
    this.setState({
      windowWidth: document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.innerWidth,
    });
  }

  renderBars() {
    const { performance = [], information = {} } = this.props.details;
    const { teams } = this.props.app;
    const { indicators, windowWidth } = this.state;
    const width = windowWidth >= 1280 ? 280 : 220,
      height = 68,
      margin = {
        top: 13,
        right: 0,
        bottom: 1,
        left: 0,
      };
    let counter = 0;

    if (!performance.length || !_.size(information)) return null;

    const data = [
      _.find(performance, { team_id: information.team_id_home }),
      _.find(performance, { team_id: information.team_id_away }),
    ];

    const renderGroup = group =>
      group.map((bar, b) => {
        counter++;

        return (
          <div className="col-3" key={b}>
            <div className="bar-align">
              <h3 className="bar-title">{lang[bar.name]}</h3>
              <BarChart
                {...{
                  data: bar.values,
                  classNames: bar.classes,
                  width,
                  height,
                  margin,
                  animation: true,
                  animationDelay: counter * 50,
                  topBarAccessor_fir: d => d.y,
                  showZero: true,
                }}
              />
              <Link
                className="abk-home"
                to={`/details/team/${information.team_id_home}/summary`}
              >
                {_.get(teams, `${information.team_id_home}.abk`, null)}
              </Link>
              <Link
                className="abk-away"
                to={`/details/team/${information.team_id_away}/summary`}
              >
                {_.get(teams, `${information.team_id_away}.abk`, null)}
              </Link>
            </div>
          </div>
        );
      });

    return _.chunk(createBarChartsCompare(data, indicators), 4).map(
      (group, g) => (
        <div className="row row-bars" key={g}>
          {renderGroup(group)}
        </div>
      ),
    );
  }

  render() {
    const { information = {} } = this.props.details;

    if (!_.size(information) || !information.team_id_home) return null;

    return (
      <div className="team-compare">
        <div className="content_group">
          <div className="row">
            <div className="col-6">
              <div className="h4">
                <Icon name="ranking-winner" width="33px" height="28px" />
                <span>{lang.ownstats}</span>
                <span className="description">
                  {lang.performance_description}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="chart-legend">
                <div className="chart-legend-list">
                  <div className="chart-legend-list-row">
                    <div className="chart-legend-list-item">
                      <div className="legend-color-col">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: '#2980B9' }}
                        />
                      </div>
                      <div className="legend-image-col">
                        {information.team_id_home ? (
                          <Image
                            src={`${database.assets}teams/${information.team_id_home}.jpg`}
                            aspectRatio={0.769}
                          />
                        ) : null}
                      </div>
                      <div className="legend-label-col">{lang.thisMatch}</div>
                    </div>
                    <div className="chart-legend-list-item">
                      <div className="legend-color-col">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: '#2980B9', opacity: '.5' }}
                        />
                      </div>
                      <div className="legend-label-col">{lang.seasonValue}</div>
                    </div>
                    <div className="chart-legend-list-item">
                      <div className="legend-color-col">
                        <div className="legend-color legend-style-mean" />
                      </div>
                      <div className="legend-label-col">
                        {lang.averageLeague}
                      </div>
                    </div>
                  </div>
                  <div className="chart-legend-list-row">
                    <div className="chart-legend-list-item">
                      <div className="legend-color-col">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: '#F39C12' }}
                        />
                      </div>
                      <div className="legend-image-col">
                        {information.team_id_away ? (
                          <Image
                            src={`${database.assets}teams/${information.team_id_away}.jpg`}
                            aspectRatio={0.769}
                          />
                        ) : null}
                      </div>
                      <div className="legend-label-col">{lang.thisMatch}</div>
                    </div>
                    <div className="chart-legend-list-item">
                      <div className="legend-color-col">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: '#F39C12', opacity: '.5' }}
                        />
                      </div>
                      <div className="legend-label-col">{lang.seasonValue}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.renderBars()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    details: state.details,
    app: state.app,
    overview: state.overview,
  };
}

export default connect(mapStateToProps)(TeamCompare);
