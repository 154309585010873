import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  AUTH_LOGIN_REQUEST_ERROR,
  AUTH_LOGIN_REQUEST_SUCCESS,
  AUTH_LOGOUT,
} from '../../constants';
import { AuthState } from './types';
import eventTracker from '../../helper/event-tracker';

const initialState: AuthState = {
  user: null,
  token: localStorage.getItem('token'),
  signInError: null,
};

export default createReducer(initialState, {
  [AUTH_LOGIN_REQUEST_SUCCESS]: (state, action: PayloadAction<any>) => {
    const { token, user } = action.payload;

    if (token) {
      localStorage.setItem('token', token);
      axios.defaults.headers.Authorization = `Bearer ${token}`;
      state.token = token;
    }
    state.signInError = null;
    state.user = user;

    eventTracker.trackUserId(user.id);
    eventTracker.trackPageView();
  },
  [AUTH_LOGIN_REQUEST_ERROR]: (state, action: PayloadAction<any>) => {
    state.signInError = action.payload;
  },
  [AUTH_LOGOUT]: state => {
    state.user = initialState.user;
    state.token = initialState.token;
    eventTracker.resetUserId();
  },
});
