import React from 'react';
import { Route } from 'react-router';
import _ from 'lodash';

import Mobile from '../containers/Mobile';
import Details from '../components/details/mobile/';

import Auth from '../components/auth';
import { checkAuth } from '../actions/auth';
import { dataErrorModal } from '../actions/details';
import error404 from '../components/404';
import eventTracker from '../helper/event-tracker';

export default function (store) {
  const setBodyClassName = location => {
    const page = location.pathname.split('/')[1];

    document.body.className = document.body.className.replace('auth-page', '');
    document.body.className = document.body.className.replace(
      'overview-page',
      '',
    );

    if (page === 'overview') {
      document.body.classList.add('overview-page');
    } else if (page === 'auth') {
      document.body.classList.add('auth-page');
    }
  };

  const onEnter = ({ location }) => {
    setBodyClassName(location);
    eventTracker.trackPageView();
    store.dispatch(checkAuth());
  };

  const onChange = (prevState, nextState, replace) => {
    const { mode } = nextState.params;
    setBodyClassName(nextState.location);

    eventTracker.trackPageView();

    if (
      nextState.location.pathname.split('/')[1] === 'details' &&
      ['player', 'team'].indexOf(mode) > -1
    ) {
      const reduxState = store.getState();
      const id = nextState.params.id.split('/')[0];
      if (
        /*(mode === 'player' && _.size(reduxState.app.playersMetaData) && _.isEmpty(_.get(reduxState, `app.playersMetaData.${id}.data`))) ||*/
        mode === 'team' &&
        _.size(reduxState.app.teams) &&
        _.isEmpty(_.get(reduxState, `app.teams.${id}.data`))
      ) {
        store.dispatch(dataErrorModal(true, mode));
        replace(prevState.location.pathname);
      }
    }
  };

  return (
    <Route onEnter={onEnter} path="/" component={Mobile} onChange={onChange}>
      <Route path="/overview" />
      <Route path="/overview/:mode" />

      <Route path="/details/:mode/:id" component={Details} />
      <Route path="/details/:mode/:id/:tab" component={Details} />
      <Route path="/details/:mode/:id/:league/:season" component={Details} />
      <Route
        path="/details/:mode/:id/:league/:season/:tab"
        component={Details}
      />
      <Route
        path="/details/:mode/:id/:league/:start/:end"
        component={Details}
      />
      <Route
        path="/details/:mode/:id/:league/:start/:end/:tab"
        component={Details}
      />

      <Route path="/auth/:type" component={Auth} />

      <Route path="*" component={error404} />
    </Route>
  );
}
