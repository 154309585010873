import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import {
  getColorFromScaleSimple,
  renderNumberValue,
  renderRatingValue,
} from '../../../../helper';

import { NO_RATING_UNDER_MINS } from '../../../../constants';

import Image from '../../../ui/image';
import Icon from '../../../ui/svg-icon';
import Loader from '../../../ui/loading-icon';

import SeasonDetails from './season_details';

class SeasonRow extends Component {
  static propTypes = {
    details: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.openDetails = this.openDetails.bind(this);

    this.state = {
      isOpen: false,
      isLoaded: false,
      historyData: null,
    };
  }

  openDetails() {
    this.setState({ isOpen: !this.state.isOpen });
    const { row } = this.props;

    axios
      .get(
        `/player/history/${row.player_id}/${row.season_id}/${row.league_id}/${row.team_id}`,
      )
      .then(data => {
        this.setState({
          isLoaded: true,
          historyData: data.data,
        });
        setTimeout(() => {
          this.props.updateHeight && this.props.updateHeight();
        }, 250);
      });
  }

  render() {
    const { row, app } = this.props;
    const { isOpen, isLoaded, historyData } = this.state;

    const league = _.get(app.leagues, row.league_id, {});
    const team = _.get(app.teams, `[${row.team_id}].abk`, 'Unknown team');
    const position = _.get(
      app.positions,
      `[${row.position_id}].position_name`,
      'N/D',
    );

    const rating = renderRatingValue(row.rating, row.rating, row.mins);
    const offence = renderRatingValue(row.offence, row.rating, row.mins);
    const defence = renderRatingValue(row.defence, row.rating, row.mins);

    const ratingNotDefined = rating === 'N/D';
    const offenceNotDefined = offence === 'N/D';
    const defenceNotDefined = defence === 'N/D';

    return (
      <tbody
        className={`history_seasons-table--row ${isOpen ? 'open' : 'closed'}`}
      >
        <tr className="history_seasons-table__item" onClick={this.openDetails}>
          <td className="history_seasons-table__collapse centered">
            <Icon
              name="icon-down"
              className="history_seasons-table__collapse-icon"
            />
          </td>
          <td className="centered">{row.season_id}</td>
          <td>
            <span className="nowrap">
              <Image
                src={`https://static.sp7f.com/v2/leagues/${row.league_id}.png`}
              />
              {league.name || 'Unknown league'}
            </span>
          </td>
          <td>
            <Link to={`/details/team/${row.team_id}/`}>
              <span className="nowrap">
                <Image
                  src={`https://static.sp7f.com/v2/teams/${row.team_id}.jpg`}
                />
                {team}
              </span>
            </Link>
          </td>
          <td>{position}</td>
          <td data-tooltip-content="Matches" className="centered">
            {renderNumberValue(row.games)}
          </td>
          <td data-tooltip-content="Goals" className="centered">
            {renderNumberValue(row.goals)}
          </td>
          <td data-tooltip-content="Assists" className="centered">
            {renderNumberValue(row.assists)}
          </td>
          <td data-tooltip-content="Mins" className="centered">
            {renderNumberValue(row.mins, v => `${v}’`)}
          </td>
          <td data-tooltip-content="Mins %" className="centered">
            {renderNumberValue(
              row.mins / row.teamminutes,
              v => `${Math.round(v * 100)}%`,
            )}
          </td>
          <td data-tooltip-content="Substitutions In" className="centered">
            {renderNumberValue(row.substitution_ins, v => `${v}`)}
          </td>
          <td data-tooltip-content="Substitutions Out" className="centered">
            {renderNumberValue(row.substitution_outs, v => `${v}`)}
          </td>
          <td
            data-tooltip-content={
              row.mins < NO_RATING_UNDER_MINS || ratingNotDefined
                ? 'Rating. Not rated due to playing time under 20 mins.'
                : null
            }
            className={`centered borderless ${
              ratingNotDefined ? 'not_defined' : 'rating'
            }`}
            style={{
              backgroundColor: `${
                ratingNotDefined ? '#fff' : getColorFromScaleSimple(row.rating)
              }`,
            }}
          >
            {!ratingNotDefined && (
              <Icon name="ranking-winner" className="icon-epi" />
            )}
            {rating}
          </td>
          <td
            data-tooltip-content={
              row.mins < NO_RATING_UNDER_MINS || offenceNotDefined
                ? 'Offence. Not rated due to playing time under 20 mins.'
                : null
            }
            className={`centered borderless ${
              offenceNotDefined ? 'not_defined' : ''
            }`}
            style={{
              backgroundColor: `${
                offenceNotDefined
                  ? '#fff'
                  : getColorFromScaleSimple(row.offence)
              }`,
            }}
          >
            {offence}
          </td>
          <td
            data-tooltip-content={
              row.mins < NO_RATING_UNDER_MINS || defenceNotDefined
                ? 'Defence. Not rated due to playing time under 20 mins.'
                : null
            }
            className={`centered borderless ${
              defenceNotDefined ? 'not_defined' : ''
            }`}
            style={{
              backgroundColor: `${
                defenceNotDefined
                  ? '#fff'
                  : getColorFromScaleSimple(row.defence)
              }`,
            }}
          >
            {defence}
          </td>
          <td className="history_seasons-table__details centered borderless">
            <Link
              to={`/details/player/${row.player_id}/${row.league_id}/${row.season_id}/history`}
            >
              <Icon
                name="info-button"
                className="history_seasons-table__details-icon"
              />
              Details
            </Link>
          </td>
        </tr>
        {isOpen ? (
          <tr>
            <td colSpan="16">
              {isLoaded ? (
                <SeasonDetails data={historyData} league={league} />
              ) : (
                <Loader />
              )}
            </td>
          </tr>
        ) : null}
        <tr className="history_seasons-table__empty">
          <td colSpan="16" />
        </tr>
      </tbody>
    );
  }
}

export default connect(state => ({
  app: state.app,
}))(SeasonRow);
