export enum IndicatorType {
  ALPHABETICALLY = 'alphabetically',
  SEASON_RATING = 'season_rating',
  OFFENCE = 'offence',
  DEFENCE = 'defence',
  STABILITY = 'stability',
  CONTRIBUTION_DEFENCE = 'contribution_defence',
  CONTRIBUTION_OFFENCE = 'contribution_offence',
  CONTRIBUTION_PASS = 'contribution_pass',
  TACKLE_OFFENCE = 'tackle_offence',
  TACKLE_DEFENCE = 'tackle_defence',
  INTERCEPT_OFFENCE = 'intercept_offence',
  INTERCEPT_DEFENCE = 'intercept_defence',
  BLOCKED = 'blocked',
  SAVE = 'save',
  AERIAL_OFFENCE = 'aerial_offence',
  AERIAL_DEFENCE = 'aerial_defence',
  TAKE_ON = 'take_on',
  CROSSING = 'crossing',
  PASS_SHORT = 'pass_short',
  PASS_LONG = 'pass_long',
  RECEPTION = 'reception',
  DELIVERY = 'delivery',
  SCORING = 'scoring',
  SCORING_EFFORT = 'scoring_effort',
  SEASON_MINS = 'season_mins',
  SEASON_AGE = 'seasonAge',
  MARKET_VALUE = 'marketvalue',
  CONTRACT_EXPIRY_YEAR = 'contract_expiry_year',
  CONTRACT_EXPIRY = 'contract_expiry',
  SEASON_ID = 'season_id',
  COMPETITION_LEVEL = 'competition_level',
  RATING = 'rating',
}
