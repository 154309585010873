import {
  CHANGE_OVERVIEW_RANKINGS_SETTINGS_COLOR,
  CHANGE_OVERVIEW_RANKINGS_SETTINGS_RANK,
} from '../constants';

export function changeOverviewSettingsRank(rankBy) {
  return {
    type: CHANGE_OVERVIEW_RANKINGS_SETTINGS_RANK,
    payload: rankBy,
  };
}

export function changeOverviewSettingsColor(colorBy) {
  return {
    type: CHANGE_OVERVIEW_RANKINGS_SETTINGS_COLOR,
    payload: colorBy,
  };
}
