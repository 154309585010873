import React from 'react';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

import Icon from '../../../../../ui/svg-icon';
import Slider from '../../../../../ui/slider';
import { ModeType } from '../../../../../../types/Dict';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../types';
import { selectSliderFilter } from '../../../../../../reducers/overview/selectors';
import { IndicatorType } from '../../../../../../types/Indicators';
import { changeSliderVal } from '../../../../../../actions/overview';
import { selectCurrentSeason } from '../../../../../../reducers/app/selectors';

const moment = extendMoment(Moment);

interface Props {
  mode: ModeType;
  loadSeasonData: (season: string) => void;
}

export function SeasonSlider(props: Props) {
  const { mode, loadSeasonData } = props;
  const dispatch = useDispatch();

  const seasonSlider = useSelector((state: RootState) =>
    selectSliderFilter(state, { mode, sliderName: IndicatorType.SEASON_ID }),
  );
  const currentSeason = useSelector((state: RootState) =>
    selectCurrentSeason(state, { mode }),
  );

  if (mode !== ModeType.PLAYER) return null;
  if (!seasonSlider) return null;

  const { minMax, data, step } = seasonSlider;
  const interval = 'month';
  const count = seasonSlider.minMax[1];
  const date = moment().subtract(count, interval);
  const range = Array.from(
    moment.rangeFromInterval(interval, count, date).by(interval),
  ).map(m => m.format('MMM’YY'));

  const active = `${currentSeason}`.indexOf('/') !== -1;

  const renderValue = (value, index) => {
    if (index === 1) return range[minMax[1]];
    return range[value];
  };

  const onChanged = value => {
    const startDate = moment()
      .subtract(minMax[1] - value[0], 'month')
      .startOf('month')
      .format('YYYY-MM-DD');
    const endDate = moment()
      .subtract(minMax[0], 'month')
      .endOf('month')
      .format('YYYY-MM-DD');

    dispatch(
      changeSliderVal(IndicatorType.SEASON_ID, [value[0], data[1]], mode),
    );
    loadSeasonData(`${startDate}/${endDate}`);
  };

  return (
    <div className="filters__slider filters__slider--season_id">
      <h2>
        {active ? (
          <Icon name="overview-radio-checked" className="of-radio-checked" />
        ) : (
          <Icon
            name="overview-radio-unchecked"
            className="of-radio-unchecked"
          />
        )}
        Custom date range
      </h2>
      <Slider
        value={[data[0]]}
        min={minMax[0]}
        max={minMax[1]}
        step={step}
        minDistance={1}
        renderValue={renderValue}
        onChanged={onChanged}
      />
    </div>
  );
}
