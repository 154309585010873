import React, { Component } from 'react';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import { connect } from 'react-redux';
import DebounceInput from 'react-debounce-input';
import Highlighter from 'react-highlight-words';
import { browserHistory, Link } from 'react-router';
import _ from 'lodash';
import { List } from 'react-virtualized/dist/commonjs/List';
import moment from 'moment';
import { fetchSearch, resetSearch, startSearch } from '../../actions/search';

import './search.scss';

import Image from '../ui/image';

import { removeDiacritics } from '../../helper';
import { getMatches } from '../../selectors/search';
import { database } from '../../constants/database';

class Search extends Component {
  static defaultProps = {
    ...Component.defaultProps,
    rowHeight: 46,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchString: '',
      focusIndex: 0,
    };

    this.rowRenderer = this.rowRenderer.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { mode } = this.props.params;
    const { searchString } = this.state;
    this.props.resetSearch();
    this.props.startSearch(mode);
    this.props.fetchSearch(mode, searchString);

    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  handleChange(event) {
    const { mode } = this.props.params;

    this.props.resetSearch();
    this.props.startSearch(mode);
    this.props.fetchSearch(mode, event.target.value);
    this.setState({
      searchString: event.target.value,
      focusIndex: 0,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { mode } = nextProps.params;

    if (this.props.params.mode !== mode) {
      nextProps.resetSearch();
      this.props.startSearch(mode);
      nextProps.fetchSearch(mode, this.state.searchString);

      this.setState({
        focusIndex: 0,
      });
    }
  }

  onKeyDown(event) {
    let { focusIndex } = this.state;
    const { mode } = this.props.params;
    const { search } = this.props;
    let link;

    if (event.keyCode === 13) {
      switch (mode) {
        case 'player':
          link = `${search.list[focusIndex].player_id}`;

          browserHistory.push(`/details/${mode}/${link}`);
          break;
        case 'team':
          link = `${search.list[focusIndex].id}`;

          browserHistory.push(`/details/${mode}/${link}`);
          break;
        case 'match':
          browserHistory.push(
            `/details/match/${search.list[focusIndex].match_id}/${search.list[focusIndex].league_id}/${search.list[focusIndex].season_id}/summary`,
          );
          break;
      }
    }

    if (event.keyCode === 40) {
      // down
      focusIndex++;
      focusIndex = focusIndex === search.list.length ? 0 : focusIndex;
    }

    if (event.keyCode === 38) {
      // up
      focusIndex = (focusIndex === 0 ? search.list.length : focusIndex) - 1;
    }

    this.setState({ focusIndex });
  }

  listMouseEnter(focusIndex) {
    this.setState({ focusIndex });
  }

  rowRenderer({ index }) {
    const { searchString } = this.state;
    const { search } = this.props;
    const { mode } = this.props.params;
    const { teams, leagues } = this.props.app;

    switch (mode) {
      case 'player':
        const player = search.list[index];
        const link = `${player.player_id}`;

        return (
          <Link to={`/details/player/${link}/`} className="search-link">
            <div className="row">
              <span className="col-6">
                <Highlighter
                  highlightClassName="word_highlight"
                  searchWords={searchString.split(/\s+/)}
                  textToHighlight={`${player.first_name} ${player.last_name}`}
                  sanitize={text => removeDiacritics(text)}
                />
              </span>
              <span className="col-6">
                {player.team_id ? (
                  <Image
                    src={`${database.assets}teams/${player.team_id}.jpg`}
                    aspectRatio={0.769}
                  />
                ) : null}
                <Highlighter
                  highlightClassName="word_highlight"
                  searchWords={searchString.split(/\s+/)}
                  textToHighlight={_.get(
                    teams,
                    `${player.team_id}.team_name`,
                    '',
                  )}
                  sanitize={text => removeDiacritics(text)}
                />
              </span>
            </div>
          </Link>
        );
      case 'team':
        const team = search.list[index];
        return (
          <Link to={`/details/team/${team.id}/`} className="search-link">
            <div className="row">
              <span className="col-12">
                {team.id ? (
                  <Image
                    src={`${database.assets}teams/${team.id}.jpg`}
                    aspectRatio={0.769}
                  />
                ) : null}
                <Highlighter
                  highlightClassName="word_highlight"
                  searchWords={searchString.split(/\s+/)}
                  textToHighlight={team.team_name}
                />
              </span>
            </div>
          </Link>
        );
      case 'match':
        const match = search.list[index];
        const homeTeamName = _.get(
          teams,
          `${match.team_id_home}.team_name`,
          'Unknown',
        );
        const awayTeamName = _.get(
          teams,
          `${match.team_id_away}.team_name`,
          'Unknown',
        );

        return (
          <Link
            to={`/details/match/${match.match_id}/${match.league_id}/${match.season_id}/summary`}
            className="search-link"
          >
            <div className="row">
              <span className="col-3 text-right">
                <Image
                  src={`${database.assets}teams/${match.team_id_home}.jpg`}
                  aspectRatio={0.769}
                />
                <Highlighter
                  highlightClassName="word_highlight"
                  searchWords={searchString.split(/\s+/)}
                  textToHighlight={homeTeamName}
                />
              </span>
              <span className="col-1 text-center">
                {match.homegoals} : {match.awaygoals}
              </span>
              <span className="col-3">
                <Image
                  src={`${database.assets}teams/${match.team_id_away}.jpg`}
                  aspectRatio={0.769}
                />
                <Highlighter
                  highlightClassName="word_highlight"
                  searchWords={searchString.split(/\s+/)}
                  textToHighlight={awayTeamName}
                />
              </span>
              <span className="col-2">
                <Image
                  src={`${database.assets}leagues/${match.league_id}.png`}
                  aspectRatio={0.769}
                />
                <small>{_.get(leagues, `${match.league_id}.name`)}</small>
              </span>
              <span className="col-3">
                <small>
                  {match.matchday}. Matchday,{' '}
                  {moment(match.date, 'YYYY-MM-DD HH:mm:ss').format(
                    'YYYY-MM-DD',
                  )}
                </small>
              </span>
            </div>
          </Link>
        );
    }
  }

  render() {
    const { search } = this.props;
    const mode =
      this.props.params.mode || localStorage.getItem('overview-mode');

    const { focusIndex } = this.state;
    const { rowHeight } = this.props;
    const width = document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.innerWidth;
    const height = document.documentElement.clientHeight
      ? document.documentElement.clientHeight - 114
      : window.innerHeight - 114;

    const returnTo = localStorage.getItem('returnTo');
    const closeLink = returnTo === 'welcome' ? '/home' : `/overview/${mode}`;

    // infinite scroll
    const hasNextPage = search.count > search.list.length;
    const isRowLoaded = ({ index }) =>
      !hasNextPage || index < search.list.length;
    const loadMoreRows = search.loading
      ? () => {}
      : () => {
          this.props.fetchSearch(
            mode,
            this.state.searchString,
            +search.page + 1,
          );
        };
    const rowCount = hasNextPage ? search.list.length + 1 : search.list.length;

    const Row = ({ index, style }) => {
      if (!isRowLoaded({ index })) {
        return (
          <div style={style} className="loading-message" key={index}>
            Loading...
          </div>
        );
      }
      return (
        <div
          onMouseEnter={this.listMouseEnter.bind(this, index)}
          key={index}
          className={`${focusIndex === index ? 'highlight' : ''}`}
          style={style}
        >
          {this.rowRenderer({ index })}
        </div>
      );
    };

    return (
      <div className="search">
        <div className="header-container">
          <div className="container">
            <div className="icon-search" />
            <DebounceInput
              id="searchinput"
              autoFocus
              debounceTimeout={300}
              onChange={this.handleChange}
              placeholder="Search..."
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
            />
            <div className="header__actionButtons">
              <Link to={closeLink}>
                <div className="icon-close" />
              </Link>
            </div>
          </div>
        </div>

        <div className="nav-container">
          <div className="nav row">
            <Link
              to="/search/player/"
              activeClassName="active"
              className="col-4 callAction"
            >
              Players
            </Link>
            <Link
              to="/search/team/"
              activeClassName="active"
              className="col-4 callAction"
            >
              Teams
            </Link>
            <Link
              to="/search/match/"
              activeClassName="active"
              className="col-4 callAction"
            >
              Matches
            </Link>
          </div>
        </div>

        <div className="content-container" ref="content_container">
          <div className="container">
            <InfiniteLoader
              isRowLoaded={isRowLoaded}
              loadMoreRows={loadMoreRows}
              rowCount={search.count}
            >
              {({ onRowsRendered, registerChild }) => (
                <List
                  ref={registerChild}
                  onRowsRendered={onRowsRendered}
                  scrollToIndex={focusIndex}
                  width={width}
                  height={height}
                  rowHeight={rowHeight}
                  rowCount={rowCount}
                  rowRenderer={Row}
                  noRowsRenderer={() =>
                    search.loading ? (
                      <div className="search-not-found">Loading...</div>
                    ) : (
                      <div className="search-not-found">No results found</div>
                    )
                  }
                />
              )}
            </InfiniteLoader>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    app: state.app,
    searchPlayers: state.app.playersListAll,
    searchTeams: state.app.teamsListAll,
    searchMatches: getMatches(state),
    search: state.search,
  }),
  { fetchSearch, resetSearch, startSearch },
)(Search);
