import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import './seasons_table.scss';

import lang from '../../../../lang';
import Icon from '../../../ui/svg-icon';
import TableFilter from '../../../ui/table-filter';

import SeasonRow from './season_row';

class SeasonsTable extends Component {
  static propTypes = {
    details: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      sortBy: null,
      filterBy: {},
    };

    this.renderSeasonRow = this.renderSeasonRow.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
  }

  renderSeasonRow(row, i) {
    return (
      <SeasonRow key={i} row={row} updateHeight={this.props.updateHeight} />
    );
  }

  changeFilter(value, filterName) {
    let filterValues = this.state.filterBy[filterName];
    if (!Array.isArray(filterValues)) filterValues = [];

    let valueIndex = filterValues.indexOf(value);

    if (valueIndex === -1) {
      filterValues = [...filterValues, value];
    } else {
      filterValues = [
        ...filterValues.slice(0, valueIndex),
        ...filterValues.slice(valueIndex + 1),
      ];
    }

    this.setState({
      filterBy: {
        ...this.state.filterBy,
        [filterName]: filterValues,
      },
    });
  }

  render() {
    const { app } = this.props;
    const { history } = this.props.details;
    const { filterBy } = this.state;

    const orderedHistory = _.orderBy(
      history,
      [
        'season_id',
        r => {
          return _.get(app.leagues, `[${r.league_id}].format_id`, 0);
        },
      ],
      ['desc', 'asc'],
    );

    let leageueOptions = _.uniqBy(orderedHistory, 'league_id').map(l => ({
      value: l.league_id,
      label: _.get(app.leagues, `[${l.league_id}].name`, lang['unknown']),
    }));
    leageueOptions = _.sortBy(leageueOptions, ['label']);

    let teamOptions = _.uniqBy(orderedHistory, 'team_id').map(l => ({
      value: l.team_id,
      label: _.get(
        app.teams,
        `[${l.team_id}].short_club_name`,
        lang['unknown'],
      ),
    }));
    teamOptions = _.sortBy(teamOptions, ['label']);

    let positionOptions = _.uniqBy(orderedHistory, 'position_id')
      .map(l => ({
        value: l.position_id,
        label: _.get(
          app.positions,
          `[${l.position_id}].description`,
          lang['unknown'],
        ),
      }))
      .filter(v => v.value !== null);
    positionOptions = _.sortBy(positionOptions, ['label']);

    let seasonOptions = _.uniqBy(orderedHistory, 'season_id').map(l => ({
      value: l.season_id,
      label: l.season_id,
    }));
    seasonOptions = _.sortBy(seasonOptions, ['label']);

    let filteredHistory = [...orderedHistory];

    for (let key in filterBy) {
      if (!filterBy.hasOwnProperty(key)) continue;
      const values = filterBy[key];
      if (Array.isArray(values) && values.length) {
        filteredHistory = orderedHistory.filter(row => {
          return values.indexOf(row[key]) !== -1;
        });
      }
    }

    return (
      <div className={`content_group history_seasons`}>
        <div className="h4 background">
          <span>{lang.season_history}</span>
        </div>
        <div className="history_seasons-table">
          <table>
            <thead>
              <tr>
                <th className="centered"></th>
                <th className="centered">
                  Season
                  <TableFilter
                    list={seasonOptions}
                    values={filterBy.season_id}
                    onChange={this.changeFilter}
                    name="season_id"
                  />
                </th>
                <th>
                  League
                  <TableFilter
                    list={leageueOptions}
                    values={filterBy.league_id}
                    onChange={this.changeFilter}
                    name="league_id"
                  />
                </th>
                <th>
                  Team
                  <TableFilter
                    list={teamOptions}
                    values={filterBy.team_id}
                    onChange={this.changeFilter}
                    name="team_id"
                  />
                </th>
                <th>
                  Pos
                  <TableFilter
                    list={positionOptions}
                    values={filterBy.position_id}
                    onChange={this.changeFilter}
                    name="position_id"
                  />
                </th>
                <th data-tooltip-content="Matches" className="centered">
                  <Icon className="header-icon matches-icon" name="match" />
                </th>
                <th data-tooltip-content="Goals" className="centered">
                  <Icon className="header-icon goals-icon" name="goal" />
                </th>
                <th data-tooltip-content="Assists" className="centered">
                  <Icon className="header-icon assists-icon" name="assist" />
                </th>
                <th data-tooltip-content="Mins" className="centered">
                  <Icon className="header-icon time-icon" name="time" />
                </th>
                <th data-tooltip-content="Mins %" className="centered">
                  <Icon className="header-icon time-icon" name="time-percent" />
                </th>
                <th
                  data-tooltip-content="Substitutions In"
                  className="centered"
                >
                  <Icon className="header-icon time-icon" name="subin" />
                </th>
                <th
                  data-tooltip-content="Substitutions Out"
                  className="centered"
                >
                  <Icon className="header-icon time-icon" name="subout" />
                </th>
                <th data-tooltip-content="Rating" className="centered epi">
                  RAT
                </th>
                <th data-tooltip-content="Offence" className="centered epi">
                  OFF
                </th>
                <th data-tooltip-content="Defence" className="centered epi">
                  DEF
                </th>
                <th className="centered"></th>
              </tr>
            </thead>
            {filteredHistory.map((row, i) => this.renderSeasonRow(row, i))}
          </table>
          <ReactTooltip place="right" type="light" className="label-popup" />
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  details: state.details,
  app: state.app,
  overview: state.overview,
}))(SeasonsTable);
