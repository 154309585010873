import {
  AUTH_LOGOUT,
  COPY_SHADOW_TEAM,
  CREATE_SHADOW_TEAM,
  DELETE_SHADOW_TEAM,
  FETCH_SHADOW_TEAMS,
  UPDATE_SHADOW_TEAM,
} from '../constants';

const initState = [];
export default function shadowTeamReducer(state = initState, action) {
  switch (action.type) {
    case FETCH_SHADOW_TEAMS:
      const teams_fetch =
        action.payload.data && action.payload.data.length
          ? action.payload.data.map(team => {
              team.players = team.players ? JSON.parse(team.players) : null;
              return team;
            })
          : [];
      return teams_fetch;
    case UPDATE_SHADOW_TEAM:
      const team_update = action.payload.data;
      const { id } = team_update;
      const index = state.findIndex(t => t.id === id);
      return [...state.slice(0, index), team_update, ...state.slice(index + 1)];
    case DELETE_SHADOW_TEAM:
      const teams_delete =
        action.payload.data && action.payload.data.length
          ? action.payload.data.map(team => {
              team.players = team.players ? JSON.parse(team.players) : null;
              return team;
            })
          : [];
      return teams_delete;
    case COPY_SHADOW_TEAM:
      const team_copy = action.payload.data;
      team_copy.players = team_copy.players
        ? JSON.parse(team_copy.players)
        : null;
      return [...state, team_copy];
    case CREATE_SHADOW_TEAM:
      const created_team = action.payload.data;
      created_team.players = created_team.players
        ? JSON.parse(created_team.players)
        : null;
      return [...state, created_team];
    case AUTH_LOGOUT:
      return initState;
    default:
      return state;
  }
}
