import React from 'react';

interface Props {
  name: string;
}

const Icon: React.FC<Props & React.SVGProps<SVGSVGElement>> = props => {
  const { name } = props;

  return (
    <svg {...props} role="img">
      <use xlinkHref={`#${name}`} />
    </svg>
  );
};

export default Icon;
