import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { getFilteredData } from '../../selectors';
import {
  fetchDataForOverview,
  loading,
  overviewLoading,
} from '../../actions/app';
import {
  changeLayout,
  loadData,
  updateFiltersPositionsList,
  changeMaxOfSliderFilter,
  changeSliderMinMax,
} from '../../actions/overview';
import { setFilterPreset } from '../../actions/filter-presets';
import * as helpers from '../../helper/';
import { getGroupBy, getSeason } from '../../helper/';

import MatchChart from './match/chart';
import PlayerChart from './player/chart';
import TeamChart from './team/chart';
import Rankings from './rankings';
import Navigation from './layouts/navigation';
import Filter from './filter';
import Table from './table';
import Change from './change';

import './overview.scss';
import '../ui/tooltip.scss';

import { ListType } from '../../reducers/overview/types';
import { ModeType } from '../../types/Dict';
import { AppState } from '../../reducers/app/types';
import { RootState } from '../../types';

function getProgressMessage(progress) {
  switch (progress.message) {
    case 'favorite':
      return 'Loading your favorites...';
    case 'decode':
      return 'Loading data...';
    case 'preset':
      return 'Loading your presets...';
    case 'matches_performance':
      return 'Loading all matches...';
    case 'teams_perforamnce':
      return 'Loading team data...';
    case 'matches':
      return 'Loading new matches...';
    case 'meta':
      return 'Loading all players...';
    default:
      return 'Loading...';
  }
}

function getClientSize() {
  const navigationWidth = 62;
  const clientWidth =
    (document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.innerWidth) - navigationWidth;
  const clientHeight = document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.innerHeight;

  return { clientWidth, clientHeight };
}

interface Props {
  changeLayout: (...any) => any;
  loadData: (...any) => any;
  updateFiltersPositionsList: (...any) => any;
  mode: ModeType | 'change';
  setFilterPreset: (...any) => any;
  app: AppState;
  overviewLoading: (...any) => any;
  fetchDataForOverview: (...any) => any;
  changeMaxOfSliderFilter: (...any) => any;
  changeSliderMinMax: (...any) => any;
  overview: any;
  auth: any;
  filteredData: any;
  meanByPositionGroups: any;
  progress: any;
}

interface State {
  selectItem: any;
  tooltipOpen: any;
}

class Overview extends Component<Props, State> {
  static propTypes = {
    changeLayout: PropTypes.func,
    loadData: PropTypes.func,
    updateFiltersPositionsList: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectItem: undefined,
      tooltipOpen: false,
    };

    this.loadPerformance = this.loadPerformance.bind(this);
    this._windowResize = this._windowResize.bind(this);
    this._generateFilterlist = this._generateFilterlist.bind(this);
    this._changePositionSplitMode = this._changePositionSplitMode.bind(this);
    this._initLayout = this._initLayout.bind(this);
    this._setOverviewState = this._setOverviewState.bind(this);
    this._renderMode = this._renderMode.bind(this);
    this.updateMinsMaxValue = this.updateMinsMaxValue.bind(this);
    this.updateMinMaxAgeValue = this.updateMinMaxAgeValue.bind(this);
  }

  componentWillMount() {
    const mode = this.props.mode || localStorage.getItem('overview-mode');

    const { href } = window.location;

    if (
      !this.props.mode &&
      href.indexOf('shadow-teams') === -1 &&
      href.indexOf('notes') === -1 &&
      href.indexOf('account') === -1 &&
      href.indexOf('home') === -1
    ) {
      browserHistory.replace({
        pathname: `/overview/${mode}`,
      });
    }

    this._initLayout(mode);
  }

  componentDidMount() {
    const { setFilterPreset, app } = this.props;
    const {
      overviewLoaded,
      overviewLoading,
      playersPerformanceData,
      playersMetaData,
    } = app;

    if (!overviewLoaded && !overviewLoading) {
      this.loadPerformance();
    } else {
      const preset = localStorage.getItem('preset');
      if (preset) {
        localStorage.removeItem('preset');
        setTimeout(() => {
          setFilterPreset(JSON.parse(preset));
        }, 500);
      }
    }

    window.addEventListener('resize', this._windowResize);
    if (_.size(playersPerformanceData)) {
      this.updateMinsMaxValue(playersPerformanceData);
    }

    if (_.size(playersMetaData)) {
      this.updateMinMaxAgeValue(playersMetaData);
    }
  }

  loadPerformance(filter?, cb?) {
    const {
      overviewLoading,
      mode,
      fetchDataForOverview,
      app,
      setFilterPreset,
    } = this.props;
    const { device, season_current } = app;

    overviewLoading(true);

    const preset = localStorage.getItem('preset');
    let savedFilter = filter || localStorage.getItem(`overview_filter_player`);
    let groupBy = 'position_2';
    let season = season_current[mode || ModeType.PLAYER];

    if (savedFilter) {
      savedFilter = JSON.parse(savedFilter);
      groupBy = getGroupBy(savedFilter);
      season = getSeason(savedFilter, mode || ModeType.PLAYER);
    }

    if (preset) {
      groupBy = getGroupBy(preset);
    }

    fetchDataForOverview(season, device, groupBy, true).then(() => {
      if (preset) {
        localStorage.removeItem('preset');
        setFilterPreset(JSON.parse(preset));
        cb && cb();
      }
      overviewLoading(false);
    });
  }

  componentWillReceiveProps(nextProps) {
    const mode = nextProps.params.mode || localStorage.getItem('overview-mode');

    if (
      [
        ModeType.PLAYER,
        ModeType.TEAM,
        ModeType.MATCH,
        ModeType.RANKINGS,
      ].indexOf(mode) !== -1
    ) {
      if (
        (nextProps.app.countries.length > 0 &&
          nextProps.overview[mode].filterComponents.list[ListType.LEAGUE_ID]
            .data.length <= 0) ||
        _.size(nextProps.app.leagues) !==
          nextProps.overview[mode].filterComponents.leaguesLength ||
        this.props.auth.user.group.leagues !== nextProps.auth.user.group.leagues
      ) {
        const position_first_id =
          nextProps.overview[mode].filterComponents.list[
            ListType.POSITION_FIRST_ID
          ];
        const position_split_mode = _.get(position_first_id, 'mode', null);

        this._generateFilterlist(
          mode,
          nextProps.app.season_current[mode],
          position_split_mode,
        );
      }

      if (
        nextProps.overview[mode].layouts.contentWidth == null ||
        nextProps.overview[mode].layouts.visualHeight == null
      ) {
        this._initLayout(mode);
      }

      if (mode !== this.props.mode) {
        localStorage.setItem('overview-mode', mode);
      }
    }

    if (mode === ModeType.PLAYER || mode === ModeType.RANKINGS) {
      if (
        this.props.overview[mode].filterComponents.list.position_first_id
          .mode !==
        nextProps.overview[mode].filterComponents.list.position_first_id.mode
      ) {
        const position_split_mode =
          nextProps.overview[mode].filterComponents.list.position_first_id.mode;
        this._changePositionSplitMode(
          mode,
          nextProps.app.season_current[mode],
          position_split_mode,
        );
      }
    }

    if (
      (mode === ModeType.PLAYER || mode === ModeType.RANKINGS) &&
      _.size(nextProps.app.playersPerformanceData) !==
        _.size(this.props.app.playersPerformanceData)
    ) {
      this.updateMinsMaxValue(nextProps.app.playersPerformanceData);
    }

    if (
      (mode === ModeType.PLAYER || mode === ModeType.RANKINGS) &&
      _.size(nextProps.app.playersMetaData) &&
      nextProps.app.playersMetaData !== this.props.app.playersMetaData
    ) {
      this.updateMinMaxAgeValue(nextProps.app.playersMetaData);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._windowResize);
  }

  updateMinsMaxValue(players) {
    const { changeMaxOfSliderFilter } = this.props;

    const maxSeasonMin =
      _.maxBy(players, ({ season_mins }) => +season_mins)?.season_mins || 4500;

    changeMaxOfSliderFilter({
      name: 'season_mins',
      max: maxSeasonMin,
    });
  }

  updateMinMaxAgeValue(players) {
    const { changeSliderMinMax } = this.props;

    const ages = _.chain(players)
      .map(p => +p.seasonAge)
      .compact()
      .value();
    let min = _.min(ages) - 1;
    let max = _.max(ages) - 1;

    if (max > 36) {
      max = 36;
    }
    if (min < 14) {
      min = 14;
    }

    changeSliderMinMax({
      name: 'seasonAge',
      min,
      max,
    });
  }

  _windowResize() {
    const mode = this.props.mode || localStorage.getItem('overview-mode');
    if (mode && mode !== 'change') {
      const { sidebarShow, tableHeight, visualHeight } =
        this.props.overview[mode].layouts;
      const { clientWidth, clientHeight } = getClientSize();
      const legendHeight = 50;
      const marginTop = 20;

      let contentWidth;
      let newVisualHeight;
      let newTableHeight;

      contentWidth = sidebarShow ? clientWidth * 0.758 : clientWidth;

      if (tableHeight === 0) {
        newTableHeight = 0;
        newVisualHeight = clientHeight - marginTop - legendHeight;
      } else if (visualHeight === 0) {
        newTableHeight = clientHeight;
        newVisualHeight = 0;
      } else {
        newVisualHeight = clientHeight * 0.618 - marginTop;
        newTableHeight = clientHeight - newVisualHeight - legendHeight;
      }

      this.props.changeLayout({
        contentWidth,
        visualHeight: newVisualHeight,
        tableHeight: newTableHeight,
        mode,
      });
    }
  }

  _changePositionSplitMode(mode, season_current, position_split_mode) {
    const { updateFiltersPositionsList, app, auth } = this.props;
    const { countries, teams, formations, positions, seasons, regions } = app;

    const userLeagues = _.get(auth, 'user.leagues', null);
    let leagues = userLeagues ? _.pick(app.leagues, userLeagues) : app.leagues;
    const user_leagues = auth?.user?.group?.leagues || [];

    const list = helpers.generateFilterlist({
      countries,
      leagues,
      teams,
      formations,
      positions,
      seasons,
      regions,
      season_current,
      user_leagues,
      mode,
      position_split_mode,
    });

    updateFiltersPositionsList({
      positions: list.positions,
    });
  }

  _generateFilterlist(mode, season_current, position_split_mode) {
    const {
      countries,
      teams,
      formations,
      positions,
      seasons,
      regions,
      playersMetaData,
      presets,
      favoriteLeagues,
    } = this.props.app;
    const userLeagues = _.get(this.props.auth, 'user.leagues', null);

    let leagues = userLeagues
      ? _.pick(this.props.app.leagues, userLeagues)
      : this.props.app.leagues;

    const user_leagues = this.props.auth.user.group.leagues;
    const list = helpers.generateFilterlist({
      countries,
      leagues,
      teams,
      formations,
      positions,
      seasons,
      regions,
      season_current,
      user_leagues,
      mode,
      position_split_mode,
    });

    const maxMarketValue = _.chain(playersMetaData)
      .values()
      .maxBy(player => +player.marketvalue)
      .get('marketvalue')
      .value();

    this.props.loadData({
      seasons: mode !== ModeType.MATCH ? list.seasons : null,
      leagues: [list.leagues],
      teams: [list.teams],
      formations: [list.formations],
      positions:
        [ModeType.PLAYER, ModeType.RANKINGS].indexOf(mode) !== -1
          ? [list.positions]
          : null,
      countries:
        [ModeType.PLAYER, ModeType.RANKINGS].indexOf(mode) !== -1
          ? [list.countries]
          : null,
      leaguesLength: _.size(this.props.app.leagues),
      maxMarketValue,
      presets,
      favoriteLeagues,
      mode,
    });
  }

  _initLayout(nextMode) {
    const mode = nextMode || localStorage.getItem('overview-mode');
    if (
      mode &&
      [
        ModeType.PLAYER,
        ModeType.TEAM,
        ModeType.MATCH,
        ModeType.RANKINGS,
      ].indexOf(mode) >= 0 &&
      mode !== 'change'
    ) {
      const { clientWidth, clientHeight } = getClientSize();
      const legendHeight = 50;
      const marginTop = 20;
      const { contentWidth, visualHeight, tableHeight, initialTableShow } =
        this.props.overview[mode].layouts;

      const newContentWidth =
        contentWidth == null ? clientWidth * 0.758 : contentWidth;
      const newVisualHeight =
        visualHeight == null
          ? initialTableShow
            ? clientHeight * 0.618 - marginTop
            : clientHeight - 90
          : visualHeight;
      const newTableHeight =
        tableHeight === 0
          ? clientHeight - newVisualHeight - legendHeight
          : tableHeight;

      this.props.changeLayout({
        contentWidth: newContentWidth,
        visualHeight: newVisualHeight,
        tableHeight: initialTableShow ? newTableHeight : 0,
        tableDragged: 0,
        mode,
      });
    }
  }

  _setOverviewState(state) {
    this.setState(state);
  }

  _renderMode() {
    const mode = this.props.mode || localStorage.getItem('overview-mode');
    const { clientWidth, clientHeight } = getClientSize();
    const { filteredData, meanByPositionGroups } = this.props;
    const { selectItem, tooltipOpen } = this.state;

    switch (mode) {
      case ModeType.PLAYER:
        return (
          <PlayerChart
            clientHeight={clientHeight}
            tooltipOpen={tooltipOpen}
            filteredData={filteredData.data}
            meanByPositionGroups={meanByPositionGroups}
            setOverviewState={this._setOverviewState}
          />
        );
      case ModeType.TEAM:
        return (
          <TeamChart
            clientWidth={clientWidth}
            clientHeight={clientHeight}
            tooltipOpen={tooltipOpen}
            filteredData={filteredData.data}
            activeMode={mode}
            setOverviewState={this._setOverviewState}
          />
        );
      case ModeType.MATCH:
        return (
          <MatchChart
            clientWidth={clientWidth}
            clientHeight={clientHeight}
            selectItem={selectItem}
            tooltipOpen={tooltipOpen}
            filteredData={filteredData.data}
            activeMode={mode}
            setOverviewState={this._setOverviewState}
          />
        );
      case ModeType.RANKINGS:
        return <Rankings filteredData={filteredData} />;
    }
  }

  render() {
    const mode = this.props.mode || localStorage.getItem('overview-mode');
    const { filteredData, overview, app, progress } = this.props;
    const { clientWidth, clientHeight } = getClientSize();

    if (
      !mode ||
      [
        ModeType.PLAYER,
        ModeType.TEAM,
        ModeType.MATCH,
        ModeType.RANKINGS,
      ].indexOf(mode as ModeType) === -1 ||
      mode === 'change'
    )
      return <Change />;

    /*TODO: Move to function*/
    const message = getProgressMessage(progress);

    if (app.overviewLoading) {
      return (
        <div className="overview">
          <div className="row">
            <div
              className={`loader-small page-opacity ${
                app.overviewLoading ? 'on' : ''
              }`}
            >
              <div className="loader-container">
                <div className="loader" />
              </div>
              <div className="loader-message">{message}</div>
              <div className="loader-bg" />
            </div>
          </div>
          <Navigation
            clientWidth={clientWidth}
            clientHeight={clientHeight}
            activeMode={mode}
            setOverviewState={this._setOverviewState}
          />
        </div>
      );
    }

    const { sidebarShow } = overview[mode].layouts;

    return (
      <div
        className={`overview ${
          mode === ModeType.RANKINGS ? 'player-rankings' : ''
        }`}
      >
        <div className="row">
          <div
            className={
              sidebarShow
                ? 'col-9 noSpacing dataView '
                : 'col-12 noSpacing dataView'
            }
          >
            {this._renderMode()}
            {mode !== ModeType.RANKINGS ? (
              <Table
                clientWidth={clientWidth}
                clientHeight={clientHeight}
                filteredData={filteredData.data}
                activeMode={mode}
                setOverviewState={this._setOverviewState}
              />
            ) : null}
          </div>

          <div
            className={`filterView ${
              sidebarShow ? 'slideOut' : 'slideIn'
            } animation`}
          >
            <Filter
              clientWidth={clientWidth}
              clientHeight={clientHeight}
              filteredData={filteredData.data}
              mode={mode}
              setOverviewState={this._setOverviewState}
            />
          </div>
        </div>

        <Navigation
          clientWidth={clientWidth}
          clientHeight={clientHeight}
          activeMode={mode}
          setOverviewState={this._setOverviewState}
        />
      </div>
    );
  }
}

export default connect(
  (state: RootState, props: { params: { mode: ModeType } }) => {
    const filteredData = getFilteredData(
      state,
      props.params?.mode || localStorage.getItem('overview-mode'),
    );

    return {
      app: state.app,
      auth: state.auth,
      overview: state.overview,
      ownProps: props,
      filteredData,
      meanByPositionGroups: filteredData?.meanByPositionGroups,
      progress: state.app.load_progress,
      mode: props.params.mode,
    };
  },
  {
    setFilterPreset,
    fetchDataForOverview,
    loading,
    overviewLoading,
    updateFiltersPositionsList,
    loadData,
    changeMaxOfSliderFilter,
    changeLayout,
    changeSliderMinMax,
  },
)(Overview);
