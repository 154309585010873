import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SvgIcon from './svg-icon';
import './loading.scss';

class LoadScreen extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.bool,
    load_quote: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { loading, error, load_quote, progress } = this.props;

    let message = '';

    /*TODO: Move to function*/
    switch (progress.message) {
      case 'favorite':
        message = 'Loading your favorites...';
        break;
      case 'decode':
        message = 'Loading data...';
        break;
      case 'preset':
        message = 'Loading your presets...';
        break;
      case 'matches_performance':
        message = 'Loading matches...';
        break;
      case 'teams_perforamnce':
        message = 'Loading teams...';
        break;
      case 'matches':
        message = 'Loading new matches...';
        break;
      case 'meta':
        message = 'Loading all players...';
        break;
      default:
        message = "Let's go...";
        break;
    }

    const progressBar =
      progress.total !== 0 ? (
        <div className="progress">
          <div className="progressbar">
            <div
              className="meter"
              style={{ width: `${(progress.current / progress.total) * 100}%` }}
            />
          </div>
        </div>
      ) : (
        <div className="progress" />
      );

    return (
      <div
        className={`loading page-transition page-left ${
          !loading || error ? 'off' : 'no-transition on'
        }`}
      >
        <div className="loading-container">
          {progressBar}
          <div className="logo-centered">
            <div className="logo-icon">
              <SvgIcon name="logo" />
            </div>
            <div className="logo-text">Scoutpanel</div>
            <div className="message-centered">
              {progress.total === 0 && progress.loaded === false
                ? 'Loading'
                : message}
            </div>
          </div>
        </div>
        <div className="loader-container">
          <div className="loader" />
          {load_quote ? (
            <div className="loader-quote">
              <div className="container">
                <div className="text">
                  <span className="quotemarker">“</span>
                  {load_quote.content.replace(/´/g, '’')}
                  <span className="quotemarker">”</span>
                </div>
                <div className="author">{load_quote.author}</div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  progress: state.app.load_progress,
}))(LoadScreen);
