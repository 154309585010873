import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router';
import * as d3_scale from 'd3-scale';
import * as d3_array from 'd3-array';

import * as helper from '../../../../../helper/';

export default class Position extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: null,
      height: null,
      padding: 0,
      minSize: 30,
    };

    this._resizeHandler = this._resizeHandler.bind(this);
    this._renderCanvas = this._renderCanvas.bind(this);
    this._renderLinks = this._renderLinks.bind(this);
  }

  componentDidMount() {
    this._resizeHandler();
    window.addEventListener('resize', this._resizeHandler);
  }

  componentDidUpdate() {
    this._renderCanvas();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeHandler);
  }

  _resizeHandler() {
    const { positions } = this.refs;
    const width = parseInt(positions.offsetWidth);
    const height = parseInt(width * 1.5);

    this.setState({ width, height });
    this._renderCanvas();
  }

  _renderCanvas() {
    const { width, height, padding } = this.state;
    const ctx = this.refs.matchCanvas.refs.canvas.getContext('2d');

    if (width && height) {
      const x0 = padding;
      const y0 = padding;
      const x1 = width - padding * 2;
      const y1 = height - padding * 2;

      ctx.clearRect(0, 0, width, height);

      ctx.strokeStyle = '#c2c6ca';
      ctx.lineWidth = '1';

      ctx.beginPath();
      ctx.rect(x0, y0, x1, y1);
      ctx.rect(x1 / 4 + padding, y0, x1 / 2, y1 / 6);
      ctx.rect(x1 - x1 / 2 - x1 / 8 + padding, y0, x1 / 4, y1 / 16);
      ctx.rect(x1 / 4 + padding, y1 - y1 / 6 + padding, x1 / 2, y1 / 6);
      ctx.rect(
        x1 - x1 / 2 - x1 / 8 + padding,
        y1 - y1 / 16 + padding,
        x1 / 4,
        y1 / 16,
      );
      ctx.stroke();

      ctx.beginPath();
      ctx.arc(x0 + x1 / 2, y0 + y1 / 2, x1 / 12, 0, 2 * Math.PI);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(x0, y0 + y1 / 2);
      ctx.lineTo(x0 + x1, y0 + y1 / 2);
      ctx.stroke();
    }
  }

  _renderLinks() {
    const { positions = {}, information = {} } = this.props;
    const { height, width, padding, minSize } = this.state;
    let substitutions;
    let cards;

    if (!_.size(positions) || !_.size(information)) return null;

    try {
      JSON.parse(information.substitutions);
    } catch (error) {
      console.info(
        `substitutions JSON in match ${information.match_id} is not valid`,
      );
      console.error(error);
      substitutions = [];
    }
    !_.isArray(substitutions) &&
      (substitutions = JSON.parse(information.substitutions));

    try {
      JSON.parse(information.cards);
    } catch (error) {
      console.info(
        `substitutions JSON in match ${information.match_id} is not valid`,
      );
      console.error(error);
      cards = [];
    }
    !_.isArray(cards) && (cards = JSON.parse(information.cards));

    const renderPlayerSubstitution = ({ align, player }) => {
      const substitution = _.find(
        substitutions[information[`team_id_${align}`]],
        { player_id: player.player_id },
      );

      if (!substitution) return null;
      return (
        <span className={`substitution substitution-${substitution.type} `} />
      );
    };

    const renderPlayerCard = ({ player }) => {
      const card = _.findLast(cards, { player_id: +player.player_id });

      const getCardType = number => {
        if (+number === 31) {
          return 'yellow';
        }
        if (+number === 32) {
          return 'yellow-second';
        }
        if (+number === 33) {
          return 'red';
        }
      };

      if (!card) return null;
      return <span className={`card ${getCardType(card.card)}`} />;
    };

    const sizeScale = d3_scale
      .scaleLog()
      .domain(
        d3_array.extent(
          [].concat(
            positions.away.map(d => {
              return +d.rating ? +d.rating : 1;
            }),
            positions.home.map(d => {
              return +d.rating ? +d.rating : 1;
            }),
          ),
        ),
      )
      .range([1, 8]);

    return _.map(positions, (players, align) =>
      _.orderBy(players, 'position_id', 'desc').map(player => {
        const pos = player.position_name;
        let sliceLength = 8;

        if (!pos) return null;

        const factor = width > 600 ? (width - 600) / 400 + 1 : 0;
        const addSize = +player.rating ? Math.round(+player.rating) : 0;
        const addValue = addSize >= 1 ? sizeScale(addSize) : 0;
        const size =
          minSize +
          Math.round(
            (addValue != 0 ? addValue : 0) *
              (addValue != 0 && factor != 0 ? factor : 1),
          );
        const { x, y } = helper.getPosition(pos);
        const positionX = (width - padding * 4) * x - addSize / 2;
        const positionY = (height - padding * 2) * y - addSize / 2;

        if (width < 760) sliceLength = 7;
        if (width < 700) sliceLength = 6;
        if (width < 560) sliceLength = 5;

        return (
          <Link
            className={`position ${pos} ${align}`}
            key={player.player_id}
            style={{ left: padding * 2 + positionX, top: padding + positionY }}
            to={`/details/player/${player.player_id}/summary`}
          >
            <div
              className="jersey_num"
              style={{
                width: size,
                height: size,
                marginLeft: -size / 2,
                marginTop: -size / 2,
                lineHeight: `${size - 2}px`,
              }}
            >
              {player.rating}
              {renderPlayerSubstitution({ align, player })}
              {renderPlayerCard({ player })}
            </div>
            <div className="position_name">
              {player.last_name && player.last_name.length > sliceLength + 1
                ? `${player.last_name.slice(0, sliceLength)}.`
                : player.last_name}
            </div>
          </Link>
        );
      }),
    );
  }

  render() {
    const { height, width } = this.state;

    return (
      <div className="team-positions position" ref="positions">
        <div className="canvas">
          <Canvas {...{ width, height }} ref="matchCanvas" />
          {this._renderLinks()}
        </div>
      </div>
    );
  }
}

class Canvas extends Component {
  _retinaCheck = () => {
    if (typeof this.refs.matchCanvas === 'undefined' || this.props.width == 0)
      return;
    const canvas = this.refs.matchCanvas;
    const ctx = canvas.getContext('2d');

    if (window.devicePixelRatio > 1) {
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      canvas.width = canvasWidth * window.devicePixelRatio;
      canvas.height = canvasHeight * window.devicePixelRatio;
      canvas.style.width = `${canvasWidth}px`;
      canvas.style.height = `${canvasHeight}px`;

      ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
    }
  };

  componentDidMount() {
    this._retinaCheck();
  }

  render() {
    const { width, height } = this.props;
    return (
      <canvas
        ref="canvas"
        width={width}
        height={height}
        style={{ pointerEvents: 'none' }}
      />
    );
  }
}
