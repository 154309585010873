import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router';
import * as d3_scale from 'd3-scale';
import * as d3_array from 'd3-array';

import './position.scss';

import * as helper from '../../../../helper/';
import { changeFormation } from '../../../../actions/details';

import { DataList, DataProgress } from '../../../ui/data-item';

class Position extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 182 * 1.4,
      height: 272 * 1.4,
      minSize: 30,
    };

    this._renderLineUpsList = this._renderLineUpsList.bind(this);
    this._renderCanvas = this._renderCanvas.bind(this);
    this._renderLinks = this._renderLinks.bind(this);
  }

  _renderLineUpsList() {
    const { formations, lineups, details, changeFormation } = this.props;
    const { id, current_league, current_season, current_formation } = details;

    const lineups_ = lineups.length > 5 ? lineups.slice(0, 5) : lineups;

    return lineups_.map((l, i) => {
      return l.formation_id > 1 ? (
        <DataProgress
          key={l.formation_id}
          desc={`TOP ${i + 1} LINEUP`}
          progress={l.fraction_pc_}
          className={`position-item ${
            current_formation &&
            current_formation !== 0 &&
            +l.formation_id === +current_formation
              ? 'active'
              : ''
          }`}
        >
          <Link
            onClick={() => {
              changeFormation(`${l.formation_id}`);
            }}
            className="data-text"
            activeClassName="active"
            to={`/details/team/${id}/${current_league}/${current_season}/summary?formation=${l.formation_id}`}
          >
            {_.get(formations, `${l.formation_id}.name`, '')}
          </Link>
        </DataProgress>
      ) : (
        ''
      );
    });
  }

  _renderCanvas() {
    const { width, height } = this.state;

    const ctx = this.refs.squadCanvas.refs.canvas.getContext('2d');

    const fieldHeight = height - 40;
    const fieldWidth = fieldHeight / 1.5;
    const centerY = height / 2;
    const fieldPaddingX = 5;
    const fieldPaddingY = centerY - fieldHeight / 2;

    ctx.clearRect(0, 0, width, height);

    ctx.strokeStyle = '#c2c6ca';
    ctx.lineWidth = '1';
    ctx.beginPath();
    ctx.rect(fieldPaddingX, fieldPaddingY, fieldWidth, fieldHeight);
    ctx.rect(
      fieldPaddingX + fieldWidth / 4,
      fieldPaddingY,
      fieldWidth / 2,
      fieldHeight / 6,
    );
    ctx.rect(
      fieldPaddingX + fieldWidth / 2 - fieldWidth / 8,
      fieldPaddingY,
      fieldWidth / 4,
      fieldHeight / 16,
    );
    ctx.rect(
      fieldPaddingX + fieldWidth / 4,
      fieldHeight - fieldHeight / 6 + fieldPaddingY,
      fieldWidth / 2,
      fieldHeight / 6,
    );
    ctx.rect(
      fieldPaddingX + fieldWidth / 2 - fieldWidth / 8,
      fieldHeight - fieldHeight / 16 + fieldPaddingY,
      fieldWidth / 4,
      fieldHeight / 16,
    );
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(
      fieldPaddingX + fieldWidth / 2,
      fieldPaddingY + fieldHeight / 2,
      fieldHeight / 12,
      0,
      2 * Math.PI,
    );
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(fieldPaddingX, fieldPaddingY + fieldHeight / 2);
    ctx.lineTo(fieldPaddingX + fieldWidth, fieldPaddingY + fieldHeight / 2);
    ctx.stroke();
  }

  _renderLinks() {
    const { positions } = this.props;
    const { height, minSize } = this.state;

    const fieldHeight = height - 40;
    const fieldWidth = fieldHeight / 1.5;
    const centerY = height / 2;
    const fieldPaddingX = 5;
    const fieldPaddingY = centerY - fieldHeight / 2;

    if (!positions || !positions.length) return null;
    const sizeScale = d3_scale
      .scaleLog()
      .domain(
        d3_array.extent(positions, d => {
          return +d.rating ? +d.rating : 1;
        }),
      )
      .range([1, 9]);

    return positions.map((position, i) => {
      const pos = position.position_name;

      if (!pos) return null;

      const addSize = +position.rating ? +position.rating : 0;
      const size = minSize + (addSize != 0 ? sizeScale(addSize) : 0);

      // const size = minSize + addSize;
      const { x, y } = helper.getPosition(pos);
      const positionX = fieldWidth * x - addSize / 2;
      const positionY = fieldHeight * y - addSize / 2;

      return (
        <Link
          className={`position ${pos}`}
          key={i}
          style={{
            left: fieldPaddingX + positionX,
            top: fieldPaddingY + positionY,
          }}
          to={`/details/player/${position.player_id}/${position.league_id}/${position.season_id}/summary?position=${position.position_id_detail}`}
        >
          <div
            className="jersey_num"
            style={{
              width: size,
              height: size,
              marginTop: -size / 2,
              marginLeft: -size / 2,
              lineHeight: `${size - 2}px`,
            }}
          >
            {position.rating ? parseFloat(position.rating.toFixed(1)) : 'N/D'}
          </div>
          <div className="position_name">
            {position.last_name && position.last_name.length > 9
              ? `${position.last_name.slice(0, 8).trim()}.`
              : position.last_name}
          </div>
        </Link>
      );
    });
  }

  componentDidMount() {
    this._renderCanvas();
  }

  componentDidUpdate() {
    this._renderCanvas();
  }

  render() {
    const { width, height } = this.state;
    const { current_formation } = this.props.details;

    return (
      <div className="team-positions row position">
        <div className="col-7 canvas">
          <Canvas {...{ width, height }} ref="squadCanvas" />
          {this._renderLinks()}
        </div>
        <div className="col-1">&nbsp;</div>
        <div
          className={`col-4 line-ups-list ${
            current_formation && +current_formation !== 0 ? 'has-selected' : ''
          }`}
        >
          <DataList>{this._renderLineUpsList()}</DataList>
        </div>
      </div>
    );
  }
}

class Canvas extends Component {
  _retinaCheck = () => {
    if (typeof this.refs.canvas === 'undefined' || this.props.width == 0)
      return;
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext('2d');

    if (window.devicePixelRatio > 1) {
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      canvas.width = canvasWidth * window.devicePixelRatio;
      canvas.height = canvasHeight * window.devicePixelRatio;
      canvas.style.width = `${canvasWidth}px`;
      canvas.style.height = `${canvasHeight}px`;

      ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
    }
  };

  componentDidMount() {
    this._retinaCheck();
  }

  render() {
    const { width, height } = this.props;
    return (
      <canvas
        ref="canvas"
        width={width}
        height={height}
        style={{ pointerEvents: 'none' }}
      />
    );
  }
}

export default connect(
  state => ({
    details: state.details,
  }),
  { changeFormation },
)(Position);
