import './header.scss';

import React, { Component } from 'react';

import TopBar from './header/top-bar.js';
import NavBar from './header/nav-bar.js';

export default class Header extends Component {
  render() {
    const { tab, sort } = this.props;

    return (
      <div className="header-container">
        <TopBar {...{ tab, sort }} />
        <NavBar {...{ tab }} />
      </div>
    );
  }
}
