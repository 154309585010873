import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  FlattenListItem,
  IndicatorType,
  LinkedList,
  ListType,
  NestingIndex,
  OverviewState,
} from './types';

import { changeListActive, generateFlattenList, loadPreset } from './utils';
import lang from '../../lang';
import * as types from '../../constants';
import { ModeType } from '../../types/Dict';
import { initialState } from './state';
import { list } from 'postcss';

export default createReducer(initialState, {
  [types.CHANGE_SLIDER_MIN_MAX]: (state, action: any) => {
    const { name, min, max } = action;
    const { data } = state.player.filterComponents.slider[name];

    if (name === IndicatorType.SEASON_AGE && data[0] === 14 && data[1] === 36) {
      state.player.filterComponents.slider[name].data = [min, max];
    }

    state.player.filterComponents.slider[name].minMax = [min, max];
  },
  [types.CHANGE_MAX_OF_SLIDER_FILTER]: (state, action: any) => {
    const { name, max } = action;
    let { minMax, data } = state.player.filterComponents.slider[name];

    if (name === IndicatorType.SEASON_MINS) {
      if (max < data[1]) {
        if (max > 4500) {
          data[1] = max;
        } else {
          data[1] = 4500;
        }

        if (data[0] > max) {
          data[0] = 250;
        }
      } else if (data[1] === minMax[1] && max > minMax[1]) {
        data = [data[0], max];
      }

      if (max > 4500) {
        minMax = [minMax[0], max];
      } else {
        minMax = [minMax[0], 4500];
      }

      state.player.filterComponents.slider[name].minMax = minMax;
      state.player.filterComponents.slider[name].data = data;
    } else {
      state.player.filterComponents.slider[name].minMax = [
        minMax[0],
        +max && +max > 10 ? +max : 10,
      ];
      state.player.filterComponents.slider[name].data = [
        data[0],
        minMax[1] === data[1] && +max > 10 ? +max : data[1],
      ];
    }
  },
  [types.LOAD_DATA_OVERVIEW]: (state, action) => {
    const {
      seasons,
      leagues,
      teams,
      formations,
      positions,
      countries,
      leaguesLength,
      maxMarketValue,
      mode,
      presets,
      favoriteLeagues,
    } = action;

    formations[0].items = [
      ...formations[0].items,
      { id: 0, name: 'None', active: true },
    ];

    const filterComponents = { ...state[mode].filterComponents };

    filterComponents.list = _.mapValues(
      filterComponents.list,
      (list, listName: ListType) => {
        switch (listName) {
          case ListType.SEASON_ID:
            return { ...list, data: seasons };
          case ListType.LEAGUE_ID:
            return { ...list, data: leagues };
          case ListType.TEAM_ID:
            return { ...list, data: teams };
          case ListType.FORMATION_ID:
            return { ...list, data: formations, splitted: false };
          case ListType.COUNTRY_ID:
            return { ...list, data: countries };
          case ListType.POSITION_FIRST_ID:
            return { ...list, data: positions, mode: 2 };
          case ListType.PRESET_ID:
            return {
              ...list,
              data: presets
                .filter(p => p.mode === mode)
                .map(p => ({ ...p, active: false })),
            };
          default:
            return list;
        }
      },
    );

    const flattenLeagues = generateFlattenList(leagues, true);
    const flattenLeagueItems = flattenLeagues.filter(
      ({ type }) => type === 'item',
    );
    const flattenLeagueItemsById = _.keyBy(flattenLeagueItems, 'id');

    filterComponents.list[ListType.FAVORITE_LEAGUES].data = [
      {
        id: 'DOMESTICS',
        name: lang.domestic_leagues,
        active: true,
        type: 'item',
      },
    ];
    for (let i = 0, l = favoriteLeagues.length; i < l; i++) {
      const id = favoriteLeagues[i];
      const { name, active, nestingIndex } = flattenLeagueItemsById[id];

      filterComponents.list[ListType.FAVORITE_LEAGUES].data.push({
        id,
        name,
        active,
        type: 'item',
        linkedList: {
          listName: ListType.LEAGUE_ID,
          nestingIndex,
        },
      });

      switch (nestingIndex.length) {
        case 3: {
          filterComponents.list[ListType.LEAGUE_ID].data[nestingIndex[0]].items[
            nestingIndex[1]
          ].items[nestingIndex[2]].linkedList = {
            listName: ListType.FAVORITE_LEAGUES,
            nestingIndex: [i + 1],
          };
          break;
        }
        case 4: {
          filterComponents.list[ListType.LEAGUE_ID].data[nestingIndex[0]].items[
            nestingIndex[1]
          ].items[nestingIndex[2]].items[nestingIndex[3]].linkedList = {
            listName: ListType.FAVORITE_LEAGUES,
            nestingIndex: [i + 1],
          };
          break;
        }
      }
    }

    if (filterComponents.slider[IndicatorType.MARKET_VALUE]) {
      filterComponents.slider[IndicatorType.MARKET_VALUE].minMax = [
        0,
        maxMarketValue ? +maxMarketValue : 200,
      ];
      filterComponents.slider[IndicatorType.MARKET_VALUE].data = [
        0,
        maxMarketValue ? +maxMarketValue : 200,
      ];
    }

    filterComponents.filterLoaded = true;
    filterComponents.leaguesLength = leaguesLength;

    // load saved filter from local storage
    let savedFilterFromStorage = localStorage.getItem(
      `overview_filter_${mode}`,
    );

    if (savedFilterFromStorage) {
      const savedFilter = JSON.parse(savedFilterFromStorage);

      const preset = savedFilter.filterState;
      const scatterplot = savedFilter.scatterplotState;
      const filterComponents_ = _.clone(filterComponents);
      const filterComponentsWithPresetDate = loadPreset(
        preset,
        filterComponents_,
        false,
        mode,
      );

      state[mode].scatterplot = scatterplot;
      state[mode].filterComponents = filterComponentsWithPresetDate;
    } else {
      state[mode].filterComponents = filterComponents;
    }
  },
  [types.SET_FILTER_PRESET]: (state, action: PayloadAction<any>) => {
    const { mode, id } = action.payload;
    const preset = JSON.parse(action.payload.preset);

    state[mode].filterComponents.list[ListType.PRESET_ID].data = state[
      mode
    ].filterComponents.list[ListType.PRESET_ID].data.map(item => ({
      ...item,
      active: item.id === id,
    }));

    if (preset.rankingsSettings) {
      delete preset.rankingsSettings;
    }

    const filterComponents = loadPreset(
      preset,
      state[mode].filterComponents,
      false,
      mode,
    );

    state.changeNum = state.changeNum + 1;
    state[mode].filterComponents = filterComponents;
  },
  [types.ADD_FILTER_PRESET]: (state, action: PayloadAction<any>) => {
    const { mode } = action.payload.data;
    const preset = action.payload.data;

    if (!state[mode].filterComponents.list[ListType.PRESET_ID].data) {
      state[mode].filterComponents.list[ListType.PRESET_ID].data = [];
    }
    state[mode].filterComponents.list[ListType.PRESET_ID].data.push(preset);
  },
  [types.REMOVE_FILTER_PRESET]: (state, action) => {
    const id = action.payload;
    const { mode } = action;

    state[mode].filterComponents.list.preset_id.data = state[
      mode
    ].filterComponents.list.preset_id.data.filter(preset => preset.id !== id);
  },
  [types.SELECTION_LASSO]: (state, action) => {
    state[action.mode].scatterplot.lassoSelection = action.ids;
  },
  [types.CHANGE_SCATTERPLOT_DOMAIN]: (state, action) => {
    state[action.mode].scatterplot = {
      ...state[action.mode].scatterplot,
      xDomain: action.xDomain,
      yDomain: action.yDomain,
      rDomain: action.rDomain,
      cDomain: action.cDomain,
      sDomain: action.sDomain,
    };
  },
  [types.CHANGE_SCATTERPLOT_LEGEND]: (state, action) => {
    const scatterplot_ = state[action.mode].scatterplot;
    const table_ = state[action.mode].table;

    scatterplot_.xVal = action.xVal;
    scatterplot_.yVal = action.yVal;
    scatterplot_.rVal = action.rVal;
    scatterplot_.cVal = action.cVal;
    scatterplot_.sVal = action.sVal;
    scatterplot_.xDomain = action.xDomain;
    scatterplot_.yDomain = action.yDomain;
    scatterplot_.rDomain = action.rDomain;
    scatterplot_.cDomain = action.cDomain;

    table_.xVal =
      [action.yVal, action.rVal, action.cVal, action.sVal].indexOf(
        action.xVal,
      ) > -1
        ? table_.xVal
        : action.xVal;
    table_.yVal =
      [action.xVal, action.rVal, action.cVal, action.sVal].indexOf(
        action.yVal,
      ) > -1
        ? table_.yVal
        : action.yVal;
    table_.rVal =
      [action.xVal, action.yVal, action.cVal, action.sVal].indexOf(
        action.rVal,
      ) > -1
        ? table_.rVal
        : action.rVal;
    table_.cVal =
      [action.xVal, action.yVal, action.rVal, action.sVal].indexOf(
        action.cVal,
      ) > -1
        ? table_.cVal
        : action.cVal;
    table_.sVal =
      action.sVal === lang.alphabetically ||
      [action.xVal, action.yVal, action.rVal, action.cVal].indexOf(
        action.sVal,
      ) > -1
        ? table_.sVal
        : action.sVal;

    state.changeNum = state.changeNum + 1;
    state[action.mode].scatterplot = scatterplot_;
    state[action.mode].table = table_;
  },
  [types.RESET_FILTERS]: (state, action) => {
    localStorage.removeItem(`overview_filter_${action.mode}`);
    const { mode } = action;

    state.changeNum = initialState.changeNum;
    state[mode].filterComponents = initialState[mode].filterComponents;
    state[mode].scatterplot = initialState[mode].scatterplot;
  },
  [types.CHANGE_POSITION_SPLIT_MODE]: (state, action) => {
    if (state.player.filterComponents.list[ListType.POSITION_FIRST_ID]) {
      state.player.filterComponents.list[ListType.POSITION_FIRST_ID]!.mode =
        action.mode;
    }
  },
  [types.SORT_TABLE]: (state, action) => {
    const { sortBy, sortDirection, sortedData } = action;

    state[action.mode].table = {
      ...state[action.mode].table,
      sortBy,
      sortDirection,
      sortedData,
    };
  },
  [types.CHANGE_SCATTERPLOT_WIDTH]: (state, action) => {
    state[action.mode].layouts = {
      ...state[action.mode].layouts,
      ...action,
    };
  },
  [types.CHANGE_SLIDER_VAL]: (state, action) => {
    const { mode, name, val } = action;

    state[mode].filterComponents.slider[name].data = val;

    if (state[mode].filterComponents.changeFilter.slider.indexOf(name) === -1) {
      if (
        !_.isEqual(
          state[mode].filterComponents.slider[name].data,
          state[mode].filterComponents.slider[name].minMax,
        ) ||
        name === IndicatorType.CONTRACT_EXPIRY
      ) {
        state[mode].filterComponents.changeFilter.slider.push(name);
      }
    } else if (
      _.isEqual(
        state[mode].filterComponents.slider[name].data,
        state[mode].filterComponents.slider[name].minMax,
      ) &&
      name !== IndicatorType.CONTRACT_EXPIRY
    ) {
      state[mode].filterComponents.changeFilter.slider.filter(
        filterName => filterName !== name,
      );
    }

    state.changeNum = state.changeNum + 1;
    state.showTooltip = initialState.showTooltip;
  },
  [types.CHANGE_SLIDER_HELPER_VAL]: (state, action) => {
    const { mode, name, val } = action;

    state[mode].filterComponents.slider[name] = {
      ...state[mode].filterComponents.slider[name],
      ...val,
    };

    state.changeNum = state.changeNum + 1;
    state.showTooltip = initialState.showTooltip;
  },
  [types.CHANGE_LIST_HELPER_VAL]: (state, action) => {
    state.changeNum = state.changeNum + 1;
    state.showTooltip = initialState.showTooltip;
    state[action.mode].filterComponents.list[action.name] = {
      ...state[action.mode].filterComponents.list[action.name],
      ...action.val,
    };
  },
  [types.SET_DOMESTIC_LEAGUES]: (state, action) => {
    const listNext =
      state[action.mode].filterComponents.list[ListType.LEAGUE_ID];

    state[action.mode].filterComponents.list[
      ListType.FAVORITE_LEAGUES
    ].data[0].active = true;

    const getActive = (region, format_id): boolean => {
      if (region.name.toLowerCase() !== 'international') {
        return format_id === 1 || format_id === 6;
      }
      return action.mode === 'match';
    };

    const leaguesActive: number[] = [];

    state[action.mode].filterComponents.list[ListType.LEAGUE_ID].data[0].items =
      state[action.mode].filterComponents.list[ListType.LEAGUE_ID].data[0].items
        .map(region => ({
          ...region,
          items: region.items
            .map(country => {
              if (!country.items) {
                const active = getActive(region, country.format_id);
                if (active) leaguesActive.push(country.id);
                return {
                  ...country,
                  active: getActive(region, country.format_id),
                };
              }

              return {
                ...country,
                items: country.items.map(league => {
                  const active = getActive(region, league.format_id);
                  if (active) leaguesActive.push(league.id);
                  return {
                    ...league,
                    active,
                  };
                }),
              };
            })
            .map(country => ({
              ...country,
              active:
                country.items &&
                country.items.length &&
                country.items.filter(league => league.active === false)
                  .length === 0,
            })),
        }))
        .map(region => ({
          ...region,
          active:
            region.items.filter(country => country.active === false).length ===
            0,
        }));

    state[action.mode].filterComponents.list[ListType.FAVORITE_LEAGUES].data =
      state[action.mode].filterComponents.list[
        ListType.FAVORITE_LEAGUES
      ].data.map(option => ({
        ...option,
        active: leaguesActive.indexOf(option.id) !== -1,
      }));

    listNext.data[0].active = false;

    state.changeNum = state.changeNum + 1;
    state.showTooltip = initialState.showTooltip;
  },
  [types.AUTH_LOGOUT]: (state, action) => {
    state = initialState;
  },

  /*[types.TOGGLE_FILTER_COLLAPSE]: (state, action) => {
    const { keyName, typeName, mode } = action;
    const filterComponents_ = state[mode].filterComponents;

    switch (typeName) {
      case 'group': {
        filterComponents_.collapse.groups = {
          ...filterComponents_.collapse.groups,
          [keyName]: {
            ...filterComponents_.collapse.groups[keyName],
            open: !filterComponents_.collapse.groups[keyName].open,
          },
        };
        break;
      }
      case 'list': {
        filterComponents_.collapse.lists = {
          ..._.chain(filterComponents_.collapse.lists)
            .map((list, name) => ({
              ...list,
              open: false,
              name,
            }))
            .keyBy('name')
            .value(),
          [keyName]: {
            ...filterComponents_.collapse.lists[keyName],
            open: !filterComponents_.collapse.lists[keyName].open,
          },
        };
        break;
      }
    }

    state[action.mode].filterComponents = filterComponents_;
  },*/
  [types.TOGGLE_FILTER_SWITCHER]: (state, action) => {
    const { switcherName, value, mode } = action;

    state[mode].filterComponents.switcher[switcherName].data = value;
  },
  [types.SET_ACTIVE_POINT_DATA]: (state, action) => {
    const { mode, data, table } = action;

    state.activePointData[mode] = data;
    state.activePointData.table = table;
  },

  [types.OVERVIEW.CHANGE_LIST_OPEN]: (
    state: OverviewState,
    action: PayloadAction<{
      mode: ModeType;
      listName: ListType;
      nestingIndex: NestingIndex;
      open: boolean;
    }>,
  ) => {
    const { mode, listName, nestingIndex, open } = action.payload;

    switch (nestingIndex.length) {
      case 1: {
        break;
      }
      case 2: {
        state[mode].filterComponents.list[listName]!.data[
          nestingIndex[0]
        ].items[nestingIndex[1]].open = open;
        break;
      }
      case 3: {
        state[mode].filterComponents.list[listName]!.data[
          nestingIndex[0]
        ].items[nestingIndex[1]].items[nestingIndex[2]].open = open;
        break;
      }
    }
  },

  [types.OVERVIEW.CHANGE_LIST_ACTIVE]: (
    state: OverviewState,
    action: PayloadAction<{
      mode: ModeType;
      listName: ListType;
      active: boolean;
      item: FlattenListItem;
    }>,
  ) => {
    let { mode, listName, active, item } = action.payload;
    const { nestingIndex } = item;

    changeListActive(
      state[mode].filterComponents.list[listName]!.data,
      nestingIndex,
      active,
    );

    if (item?.linkedList) {
      changeListActive(
        state[mode].filterComponents.list[item.linkedList.listName]!.data,
        item.linkedList.nestingIndex,
        active,
      );
    }

    if (listName === ListType.LEAGUE_ID && item.id === 'ALL') {
      state[mode].filterComponents.list[ListType.FAVORITE_LEAGUES]!.data =
        state[mode].filterComponents.list[ListType.FAVORITE_LEAGUES]!.data.map(
          option => ({
            ...option,
            active,
          }),
        );
    }

    if (
      (listName === ListType.FAVORITE_LEAGUES && item.nestingIndex[0] !== 0) ||
      listName === ListType.LEAGUE_ID
    ) {
      changeListActive(
        state[mode].filterComponents.list[ListType.FAVORITE_LEAGUES]!.data,
        [0],
        false,
      );
    }

    if (
      state[mode].filterComponents.changeFilter.list.indexOf(listName) === -1
    ) {
      state[mode].filterComponents.changeFilter.list.push(listName);
    }
  },

  [types.OVERVIEW.ADD_TO_FAVORITE_LEAGUES_LIST]: (
    state: OverviewState,
    action: PayloadAction<{
      mode: ModeType;
      listName: ListType;
      item: FlattenListItem;
    }>,
  ) => {
    const { mode, listName, item } = action.payload;
    const { id, name, active, nestingIndex } = item;

    if (listName === ListType.LEAGUE_ID) {
      state[mode].filterComponents.list[ListType.FAVORITE_LEAGUES]!.data.push({
        id,
        name,
        active,
        type: 'item',
        linkedList: {
          listName: ListType.LEAGUE_ID,
          nestingIndex,
        },
        items: [],
      });

      const linkedList: LinkedList = {
        listName: ListType.FAVORITE_LEAGUES,
        nestingIndex: [
          state[mode].filterComponents.list[ListType.FAVORITE_LEAGUES]!.data
            .length - 1,
        ],
      };
      switch (nestingIndex.length) {
        case 1: {
          break;
        }
        case 2: {
          state[mode].filterComponents.list[ListType.LEAGUE_ID]!.data[
            nestingIndex[0]
          ].items[nestingIndex[1]].linkedList = linkedList;
          break;
        }
        case 3: {
          state[mode].filterComponents.list[ListType.LEAGUE_ID]!.data[
            nestingIndex[0]
          ].items[nestingIndex[1]].items[nestingIndex[2]].linkedList =
            linkedList;
          break;
        }
      }
    }
  },

  [types.OVERVIEW.REMOVE_FROM_FAVORITE_LEAGUES_LIST]: (
    state: OverviewState,
    action: PayloadAction<{
      mode: ModeType;
      listName: ListType;
      item: FlattenListItem;
    }>,
  ) => {
    const { mode, item } = action.payload;
    const { id } = item;

    state[mode].filterComponents.list[ListType.FAVORITE_LEAGUES]!.data = state[
      mode
    ].filterComponents.list[ListType.FAVORITE_LEAGUES]!.data.filter(
      option => option.id !== id,
    );
  },
});
