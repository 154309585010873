import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';
import { ListType } from '../../../../../reducers/overview/types';
import { ModeType } from '../../../../../types/Dict';
import { OverviewFilterState } from './types';
import { CollapseGroupType, CollapseGroup, CollapseList } from './types';

const selectSlice = (state: RootState): OverviewFilterState =>
  state.overviewFilter || initialState;

const selectMode = (state, props: { mode: ModeType }): ModeType => props.mode;
const selectGroupName = (
  state,
  props: { groupName: CollapseGroupType },
): CollapseGroupType => props.groupName;
const selectListName = (state, props: { listName: ListType }): ListType =>
  props.listName;

export const selectCollapseGroup = createSelector(
  [selectSlice, selectMode, selectGroupName],
  (filter, mode, groupName): CollapseGroup | undefined => {
    return filter[mode].collapse.groups[groupName];
  },
);

export const selectCollapseList = createSelector(
  [selectSlice, selectMode, selectListName],
  (filter, mode, listName): CollapseList | undefined => {
    return filter[mode].collapse.lists[listName];
  },
);
