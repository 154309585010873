import axios from 'axios';

import {
  ADD_GROUP,
  DELETE_GROUP,
  GET_GROUPS,
  UPDATE_GROUP,
} from '../constants';

import { database } from '../constants/database';

export function getGroups() {
  const request = axios.get(database.groups.getGroups);

  return {
    type: GET_GROUPS,
    payload: request,
  };
}

export function addGroup({
  name,
  leagues,
  countries,
  seasons,
  team_id,
  team_color,
  apikey,
}) {
  const request = axios.post(`${database.groups.addGroup}${name}`, {
    leagues,
    countries,
    seasons,
    team_id,
    team_color,
    apikey,
  });

  return {
    type: ADD_GROUP,
    payload: request,
  };
}

export function deleteGroup(id) {
  const request = axios.delete(`${database.groups.deleteGroup}${id}`);

  return {
    type: DELETE_GROUP,
    payload: request,
  };
}

export function updateGroup({
  id,
  name,
  leagues,
  countries,
  seasons,
  team_id,
  team_color,
  apikey,
}) {
  const request = axios.put(`${database.groups.deleteGroup}${id}`, {
    name,
    leagues,
    countries,
    seasons,
    team_id,
    team_color,
    apikey,
  });

  return {
    type: UPDATE_GROUP,
    payload: request,
  };
}
