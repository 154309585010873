import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import moment from 'moment';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import axios from 'axios';

import FavoriteCategory from '../ui/favorite-category';
import Nav from './nav';
import Icon from '../ui/svg-icon';
import lang from '../../lang';
import Image from '../ui/image';
import Select from '../ui/select';
import Slider from '../ui/slider';
import Chart from './chart/';
import { database } from '../../constants/database';

import './favorites.scss';
import {
  changeCategory,
  changeFilter,
  getFavorites,
} from '../../actions/favorites';
import {
  loading,
  loadPlayerDataField,
  removeFromFavorite,
} from '../../actions/app';
import { FAVORITE_CATEGORIES } from '../../constants';

const getWindowHeight = () =>
  document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.innerHeight;

class Favorites extends Component {
  constructor(props) {
    super(props);

    let onlyMyPlayers = localStorage.getItem('favoritesOnlyMyPlayers');

    if (onlyMyPlayers === null) {
      onlyMyPlayers = true;
    }

    if (onlyMyPlayers === 'false') {
      onlyMyPlayers = false;
    }

    this.state = {
      height: getWindowHeight(),
      onlyMyPlayers,
      columns: [
        { title: '#', order: false },
        { title: 'Category', order: true, mainKey: 'category' },
        { title: 'Added', order: true, mainKey: 'last_modified' },
        { title: 'Player', order: true, mainKey: 'first_name' },
        { title: 'Age', order: true, mainKey: 'age' },
        { title: 'Foot', order: true, mainKey: 'preferred_foot' },
        { title: 'Pos', order: true, mainKey: 'position_id' },
        { title: 'Team', order: true, mainKey: 'team_name' },
        { title: 'Contract ends', order: true, mainKey: 'contract_expiry' },
        { title: 'Mins', order: true, mainKey: 'season_mins' },
        { title: 'Goals', order: true, mainKey: 'goals' },
        { title: 'Assists', order: true, mainKey: 'assists' },
        { title: 'Season rat.', order: true, mainKey: 'season_rating' },
        { title: 'Last month rat.', order: true, mainKey: 'rating_last_month' },
        { title: 'Last 3 month', order: false },
        { title: 'PDF', order: false },
      ],
      orderBy: {
        title: 'Added',
        mainKey: 'last_modified',
        direction: 'desc',
      },
    };

    this.handleResize = this.handleResize.bind(this);
    this.renderTableHeader = this.renderTableHeader.bind(this);
    this.renderTableBody = this.renderTableBody.bind(this);
    this.renderColumns = this.renderColumns.bind(this);
    this.renderConfirmTooltip = this.renderConfirmTooltip.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.pdfLink = this.pdfLink.bind(this);
  }

  showTooltip(id) {
    const { tooltip } = this.state;

    this.setState({ tooltip: +id !== +tooltip ? +id : null });
  }

  _orderBy(order) {
    const { orderBy, columns } = this.state;
    const can = _.find(columns, { title: order.title }).order;

    if (!can) return;

    if (order.title === orderBy.title) {
      this.setState({
        orderBy: {
          ...orderBy,
          direction: orderBy.direction === 'asc' ? 'desc' : 'asc',
        },
      });
    } else {
      this.setState({
        orderBy: {
          ...order,
          direction: orderBy.direction,
        },
      });
    }
  }

  componentDidMount() {
    this.forceUpdate();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(event) {
    this.setState({ height: getWindowHeight() });
  }

  changeCategory(player_id, user_id, category) {
    const { mode } = this.props.params;
    if (category) {
      this.props.changeCategory({ player_id, user_id, value: category });
    } else {
      this.props.removeFromFavorite({ id: player_id, mode }).then(() => {
        this.props.getFavorites({ mode }).then(() => {
          this.props.loading(false);
        });
      });
      this.setState({ tooltip: null });
    }
  }

  renderTableHeader() {
    const { orderBy } = this.state;

    return this.state.columns.map(column => (
      <td key={column.title}>
        {column.order ? (
          <a
            className={`${
              column.title === orderBy.title
                ? `active ${orderBy.direction}`
                : ''
            }`}
            onClick={this._orderBy.bind(this, column)}
          >
            <span>{column.title}</span>
          </a>
        ) : (
          <span>{column.title}</span>
        )}
      </td>
    ));
  }

  renderConfirmTooltip(id) {
    const { mode } = this.props.params;

    const removeFromFavorites = () => {
      this.props.loading(true);

      this.props.removeFromFavorite({ id, mode }).then(() => {
        this.props.getFavorites({ mode }).then(() => {
          this.props.loading(false);
        });
      });

      this.setState({ tooltip: null });
    };

    const cancel = () => {
      this.setState({ tooltip: null });
    };

    return (
      <span className="favorite-confirm">
        <span className="confirm-title">
          Do you really want to remove
          <br />
          this player from your favorites?
        </span>
        <a
          onClick={removeFromFavorites}
          className="confirm confirm-yes"
          href="#"
        >
          Yes
        </a>
        <a onClick={cancel} className="confirm confirm-no" href="#">
          No
        </a>
      </span>
    );
  }

  renderColumns(row) {
    const { tooltip } = this.state;
    const { mode } = this.props.params;
    const { positions, teams } = this.props.app;
    const { first_name, last_name } = row;
    const history = this.props.favorites[mode].history;
    let image;

    //history = _.groupBy(history, 'player_id');

    const renderCell = column => {
      switch (column.title) {
        case '#':
          return (
            <span className="favorite-actions">
              <a
                href="#"
                onClick={this.showTooltip.bind(this, row[`${mode}_id`])}
              >
                <Icon
                  name="favorite"
                  className={`icon-favorite category-${row.category} ${
                    +row[`${mode}_id `] === +tooltip ? 'active' : null
                  }`}
                  width="20px"
                  height="20px"
                />
              </a>
              <CSSTransitionGroup
                transitionName="transition"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
              >
                {+row[`${mode}_id`] === +tooltip
                  ? this.renderConfirmTooltip(row[`${mode}_id`])
                  : null}
              </CSSTransitionGroup>
            </span>
          );
        case 'Category':
          return (
            <FavoriteCategory
              className={`category-${row.category}`}
              category={row.category}
              id={row.player_id}
              user_id={row.user_id}
              onChange={this.changeCategory}
            />
          );
        case 'Added':
          return moment(row[column.mainKey]).format('YYYY-MM-DD');
        case 'Player':
          image = row.player_id
            ? `${database.assets}players/${row.player_id}.jpg`
            : '';
          return (
            <Link
              className="player-link"
              to={`/details/player/${row.player_id}/`}
            >
              <Image src={image} aspectRatio={0.768} />
              {first_name} {last_name}
            </Link>
          );
        case 'Pos':
          const position = positions[row[column.mainKey]];
          return position ? position.description : 'N/D';
        case 'Team':
          const team = teams[row.team_id];
          image = row.team_id
            ? `${database.assets}teams/${row.team_id}.jpg`
            : '';
          return team && team.team_name ? (
            <Link
              className="team-link"
              to={`/details/team/${row.team_id}/summary`}
            >
              <Image src={image} aspectRatio={0.768} />
              {team.team_name}
            </Link>
          ) : (
            'N/D'
          );
        case 'Contract ends':
          return +row[column.mainKey] !== 1900 ? row[column.mainKey] : 'N/D';
        case 'Season rat.':
          if (!row[column.mainKey]) return 'N/D';

          return (
            <Link
              to={`/details/player/${row.player_id}/${row.league_id}/${row.season_id}/summary`}
            >
              {row[column.mainKey] || 'N/D'}
            </Link>
          );
        case 'Last month rat.':
          if (!row.rating_last_month) return 'N/D';

          return (
            <Link
              to={`/details/player/${row.player_id}/${row.league_id}/${moment()
                .subtract(1, 'months')
                .format('YYYY-MM-DD')}/${moment().format(
                'YYYY-MM-DD',
              )}/summary`}
              className="last-rating"
            >
              {row.rating_last_month || 'N/D'}
            </Link>
          );
        case 'Last 3 month':
          return (
            <Link
              to={`/details/player/${row.player_id}/${row.league_id}/${moment()
                .subtract(3, 'months')
                .format('YYYY-MM-DD')}/${moment().format(
                'YYYY-MM-DD',
              )}/history`}
              className="history-rating"
            >
              <Chart added={row.last_modified} data={history[row.player_id]} />
            </Link>
          );
        case 'PDF':
          return (
            <a
              onClick={e =>
                this.pdfLink(
                  e,
                  `${database.pdf.details}${mode}/${row.player_id}/${row.season_id}/${row.league_id}/Details`,
                  row.player_id,
                )
              }
              className="download"
            >
              <Icon name="download" width="20px" height="20px" />
            </a>
          );
        case 'Assists':
          if (row.season_mins > 0) {
            return row[column.mainKey] || 0;
          }
          return row[column.mainKey] || 'N/D';

        case 'Goals':
          if (row.season_mins > 0) {
            return row[column.mainKey] || 0;
          }
          return row[column.mainKey] || 'N/D';

        default:
          return row[column.mainKey] || 'N/D';
      }
    };

    return this.state.columns.map(column => (
      <td
        className={`${renderCell(column) === 'N/D' ? 'not_defined' : ''}`}
        key={column.title}
      >
        {renderCell(column)}
      </td>
    ));
  }

  renderTableBody() {
    const { favorites } = this.props;
    const { mode } = this.props.params;
    const { onlyMyPlayers } = this.state;
    const userId = this.props.user.id;
    const { playersMetaData, teams } = this.props.app;
    let list = favorites[mode].list.slice();
    const { orderBy } = this.state;

    const orderSquad = player => {
      switch (orderBy.mainKey) {
        case 'team_name':
          const team = teams[player.team_id];
          return team && team.team_name ? team.team_name : 'N/D';
          break;
        case 'rating_last_month':
          if (player[orderBy.mainKey] === 0) return 0;
          return player[orderBy.mainKey] || -1;
          break;
        default:
          return player[orderBy.mainKey];
      }
    };

    if (!list || !list.length) return null;

    if (onlyMyPlayers) {
      list = list.filter(fav => {
        return +fav.user_id === +userId;
      });
    }

    list = _.orderBy(list, ({ user_id }) => (+user_id === +userId ? 0 : 1));
    list = _.uniqBy(list, 'player_id');
    list = list.map(favorite => ({
      ...favorite,
      ...playersMetaData[favorite[`${mode}_id`]],
    }));

    list = _.orderBy(list, [orderSquad], [orderBy.direction])
      .filter(
        player =>
          !favorites.filter.position.length ||
          _.find(favorites.filter.position, { value: +player.position_id }),
      )
      .filter(
        player =>
          !favorites.filter.category.length ||
          _.find(favorites.filter.category, { value: +player.category }),
      )
      .filter(
        player =>
          !favorites.filter.age.length ||
          (+player.age >= favorites.filter.age[0] &&
            +player.age <= favorites.filter.age[1]),
      );

    return list.map(row => (
      <tr key={`${row.user_id} ${row.player_id}`}>{this.renderColumns(row)}</tr>
    ));
  }

  exportPDF(event) {
    event.preventDefault();

    const orderSquad = player => {
      switch (orderBy.mainKey) {
        case 'team_name':
          const team = teams[player.team_id];
          return team && team.team_name ? team.team_name : 'N/D';
          break;
        case 'rating_last_month':
          if (player[orderBy.mainKey] === 0) return 0;
          return player[orderBy.mainKey] || -1;
          break;
        default:
          return player[orderBy.mainKey];
      }
    };

    const { favorites, user } = this.props;
    const { mode } = this.props.params;
    const { playersMetaData, teams } = this.props.app;
    const { orderBy, onlyMyPlayers } = this.state;

    const playersId = _.chain(
      favorites[mode].list.slice().map(favorite => ({
        ...favorite,
        ...playersMetaData[favorite[`${mode}_id`]],
      })),
    )
      .orderBy([orderSquad], [orderBy.direction])
      .filter(player => {
        if (onlyMyPlayers) {
          return +player.user_id === +user.id;
        }
        return true;
      })
      .filter(
        player =>
          !favorites.filter.position.length ||
          _.find(favorites.filter.position, { value: +player.position_id }),
      )
      .filter(
        player =>
          !favorites.filter.category.length ||
          _.find(favorites.filter.category, { value: +player.category }),
      )
      .filter(
        player =>
          !favorites.filter.age.length ||
          (+player.age >= favorites.filter.age[0] &&
            +player.age <= favorites.filter.age[1]),
      )
      .map(row => row.player_id)
      .value();

    const pdfWindow = window.open('', '', '');

    axios.get('/file/token').then(result => {
      const { token } = result.data;
      if (token) {
        pdfWindow.location = `${database.pdf.favorites}${mode}/${playersId}?token=${token}`;
      }
    });
  }

  pdfLink(event, url, player_id) {
    event.preventDefault();
    const pdfWindow = window.open('', '', '');

    axios.get('/file/token').then(result => {
      const { token } = result.data;
      if (token) {
        if (!player_id) {
          pdfWindow.location = `${url}?token=${token}`;
        } else {
          this.props.loadPlayerDataField(player_id).then(rs => {
            const { standard_league } = _.get(rs, 'payload.data.data', '');
            if (standard_league) {
              const [league, season] = standard_league.split(':');
              const { mode } = this.props.params;
              pdfWindow.location = `${database.pdf.details}${mode}/${player_id}/${season}/${league}/Details?token=${token}`;
            }
          });
        }
      }
    });
  }

  render() {
    const { favorites, params, app, changeFilter, user } = this.props;

    const { mode } = params;
    const { height } = this.state;
    const { playersMetaData, positions, supportsTouch } = app;

    const closeMode = localStorage.getItem('overview-mode') || mode;
    const returnTo = localStorage.getItem('returnTo');
    const closeLink =
      returnTo === 'welcome' ? '/home' : `/overview/${closeMode}`;

    const favoritePositions = _.chain(favorites[mode].list)
      .map(player => _.get(playersMetaData, `${player.player_id}.position_id`))
      .map(position_id => _.get(positions, position_id, {}))
      .uniq()
      .sortBy('id')
      .value();

    const options = {
      position: favoritePositions
        .map(position => ({
          value: position.id,
          label: position.description,
        }))
        .filter(({ label }) => label),
      categories: FAVORITE_CATEGORIES.map(cat => ({
        value: cat.val,
        label: cat.label,
      })),
    };

    const valueRenderer = value => positions[value.value].position_name;

    const positionChange = values => {
      changeFilter({ name: 'position', values });
    };

    const categoryChange = values => {
      changeFilter({ name: 'category', values });
    };

    const ageChange = values => {
      changeFilter({ name: 'age', values });
    };

    return (
      <div className="favorites">
        <Scrollbars style={{ height }} autoHide>
          <div className="header-container">
            <div className="container">
              <div className="row">
                <div className="col-4">
                  <div className="info-logo">
                    <Icon name="favorite" width="20px" height="20px" />
                    <span>{lang.favorite_players}</span>
                  </div>
                </div>
                <div className="col-4">&nbsp;</div>
                <div className="col-4">
                  <div className="btn pull-right">
                    <Link to={closeLink} className="icon-close" />
                  </div>
                  <div className="btn pull-right">
                    <a onClick={this.exportPDF.bind(this)} className="icon-pdf">
                      <svg role="img">
                        <use xlinkHref="/img/sprite.symbol.v106.svg#download" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Nav />

          <div className="content-container">
            <div className="container">
              <div className="favorites-filter pull-right">
                <div className="slider-on-white">
                  <label htmlFor="">Filter by Age</label>
                  <Slider
                    {...{
                      value: !_.isNaN(favorites.filter.age)
                        ? favorites.filter.age
                        : 0,
                      min: !_.isNaN(favorites.filter.age_range[0])
                        ? favorites.filter.age_range[0]
                        : 0,
                      max: !_.isNaN(favorites.filter.age_range[1])
                        ? favorites.filter.age_range[1]
                        : 0,
                      step: 1,
                      minDistance: 1,
                      [supportsTouch == true ? 'onChanged' : 'onChange']:
                        ageChange,
                    }}
                  />
                </div>

                <div className="position-filter">
                  <label htmlFor="">Filter by Position</label>
                  <Select
                    options={options.position}
                    value={favorites.filter.position}
                    onChange={positionChange}
                    placeholder="Select a position"
                    multi
                    searchable={false}
                    clearable={false}
                    valueRenderer={valueRenderer}
                  />
                </div>

                <div className="position-filter">
                  <label htmlFor="">Filter by Category</label>
                  <Select
                    options={options.categories}
                    value={favorites.filter.category}
                    onChange={categoryChange}
                    placeholder="Select a category"
                    multi
                    searchable={false}
                    clearable={false}
                  />
                </div>
              </div>

              <div className="h4">
                <span>Your Favorite Players</span>
                <span className="description">
                  {+user.role.id === 2 || +user.role.id === 3 ? (
                    <div className="group-check">
                      <label
                        onClick={() => {
                          const newState = !this.state.onlyMyPlayers;
                          localStorage.setItem(
                            'favoritesOnlyMyPlayers',
                            newState,
                          );
                          this.setState({ onlyMyPlayers: newState });
                        }}
                        className="checked"
                      >
                        <span className="checkbox">
                          {!this.state.onlyMyPlayers ? (
                            <span className="icon-check" />
                          ) : null}
                        </span>
                        Show group favorites, too.
                      </label>
                    </div>
                  ) : null}
                </span>
              </div>

              <table className="content_group favorites-list">
                <thead>
                  <tr>{this.renderTableHeader()}</tr>
                </thead>
                <tbody>{this.renderTableBody()}</tbody>
              </table>
            </div>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default connect(
  state => ({
    app: state.app,
    favorites: state.favorites,
    user: state.auth.user,
  }),
  {
    getFavorites,
    loading,
    removeFromFavorite,
    changeFilter,
    changeCategory,
    loadPlayerDataField,
  },
)(Favorites);
