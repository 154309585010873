import React, { Component } from 'react';
import { Link } from 'react-router';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

// Load actions, own fcts and components
import lang from '../../../../lang';
import Image from '../../../ui/image';
import { DataItem } from '../../../ui/data-item';
import { loadPlayerDataField } from '../../../../actions/app';

import CardCharts from './card-charts';
import BtnLoader from '../../../ui/btn-loader';
import { database } from '../../../../constants/database';

class CompareCard extends Component {
  static propTypes = {
    changeEdit: PropTypes.func,
    removeItem: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: '',
      date_data: '',
      loading: false,
    };

    this.removeItem = this.removeItem.bind(this);
    this.editItem = this.editItem.bind(this);
    this.compareLastSeasons = this.compareLastSeasons.bind(this);
  }

  componentDidMount() {
    if (this.props.mode === 'player') {
      this.props.loadPlayerDataField(this.props.item.player_id).then(result => {
        this.setState({ ...result.payload.data.data });
      });
    }
  }

  removeItem(num) {
    return () => {
      this.props.removeItem(num);
    };
  }

  editItem(num) {
    return () => {
      this.props.changeEdit(num, true);
    };
  }

  compareLastSeasons() {
    if (!window.confirm('This will clear other selected players. Proceed?')) {
      return;
    }

    const { data } = this.state;
    const { mode, addItem, removeItem, item } = this.props;

    if (!item) {
      return alert('Please select player');
    }

    if (!data) {
      return alert('No data for this player');
    }

    this.setState({
      loading: true,
    });

    let dataArray = data
      .split(',')
      .map(v => {
        let item = v.split(':');
        return {
          season_id: +item[0],
          league_id: +item[1],
          position_id: +item[2],
        };
      })
      .filter(v => v.season_id <= item.season[0] && v.league_id == item.league);

    dataArray = _.uniqBy(dataArray, v => v.season_id);
    dataArray = _.sortBy(dataArray, ['season_id']);
    dataArray = _.reverse(dataArray);
    const id = item.player_id;

    if (!dataArray.length) {
      return alert('No data for this player / league');
    }

    const requests = [];
    dataArray.slice(0, 4).map((value, i) => {
      removeItem(i);
      requests.push(
        addItem({
          id,
          league: value.league_id,
          season: [value.season_id],
          formation: 0,
          position: 0,
          num: i,
          mode,
        }),
      );
    });

    Promise.all(requests).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    const {
      item,
      leagues,
      teams,
      positions,
      formations,
      num,
      playersMetaData,
      mode,
    } = this.props;

    const countries = this.props.app.countries;

    const { data } = item;

    const urlComp = item.league
      ? `${database.assets}leagues/${item.league}.png`
      : null;

    const getSeasonText = ([start, end]) => {
      if (end) {
        return [
          moment(start, 'YYYY-MM-DD').format('MMM ’YY'),
          moment(end, 'YYYY-MM-DD').format('MMM ’YY'),
        ].join(' – ');
      }

      return start;
    };

    // get league and team names
    const league_name = _.get(leagues, `${item.league}.name`, '');
    const season_name = item.season ? getSeasonText(item.season) : '';
    const formation_name = _.get(formations, `${item.formation || 0}.name`, '');

    const league_id = item.league;
    const league = league_id !== null && leagues[league_id];
    const country_id = _.get(league, 'country_id', null);
    const country = country_id !== null && countries[country_id];

    // get name, this can also be done in a sql call through the api
    const { player_id, team_id } = item;
    const params = {};

    switch (mode) {
      case 'team':
        params.id = team_id;
        params.last_name = _.get(teams, `${team_id}.team_name`, '');
        params.url = team_id ? `${database.assets}${mode}s/${team_id}.jpg` : '';
        params.team_name = _.get(teams, `${team_id}.team_name`, '');
        params.emblemUrl = item.team_id
          ? `${database.assets}teams/${item.team_id}.jpg`
          : '';

        break;

      default:
        try {
          const n = playersMetaData[player_id];
          const last_name = n.last_name || '';

          params.id = player_id;
          params.first_name = n.first_name || '';
          params.last_name =
            last_name && last_name.length > 17
              ? `${last_name.substring(0, 17)}.`
              : last_name;
          params.url = player_id
            ? `${database.assets}${mode}s/${player_id}.jpg`
            : '';
          params.team_id = item.team_id;
          params.team_name = _.get(teams, `${params.team_id}.team_name`, '');
          params.emblemUrl = n.team_id
            ? `${database.assets}teams/${params.team_id}.jpg`
            : '';

          const bdMomemnt = moment(n.birth_date) || null;

          if (bdMomemnt) {
            params.birth_date = `${bdMomemnt.format(
              'YYYY-MM-DD',
            )} (${moment().diff(bdMomemnt, 'years')})`;
            console.log(params.birth_date);
          } else {
            params.birth_date = 'N/D';
          }

          const data = _.get(this.state, 'data', '');

          if (!+item.position) {
            const seasonCombinations = _.chain(data)
              .split(',')
              .map(slp => {
                const slpA = slp.split(':');
                const result = {};
                if (slp[0]) result.season_id = slpA[0];
                if (slp[1]) result.league_id = +slpA[1];
                if (slp[2]) result.position_id = +slpA[2];
                return result;
              })
              .orderBy('season_id', 'desc')
              .value();

            const dateCombinations = _.chain(n.date_data)
              .split(',')
              .map(slp => {
                const slpA = slp.split(':');
                const result = {};
                if (slp[0]) result.season_id = slpA[0];
                if (slp[1]) result.league_id = +slpA[1];
                if (slp[2]) result.position_id = +slpA[2];
                return result;
              })
              .value();

            const combinations = [...seasonCombinations, ...dateCombinations];

            const positionsFiltered = _.chain(combinations)
              .filter(slf => {
                if (+slf.league_id !== +item.league) {
                  return false;
                } else if (
                  item.season.length === 1 &&
                  +slf.season_id === +item.season[0]
                ) {
                  return true;
                } else if (item.season.length === 2) {
                  if (
                    slf.season_id.indexOf('-') === -1 &&
                    +slf.season_id >=
                      +item.season[0].substr(0, item.season[0].indexOf('-')) &&
                    +slf.season_id <=
                      +item.season[1].substr(0, item.season[1].indexOf('-'))
                  ) {
                    return true;
                  } else if (
                    slf.season_id.indexOf('-') > -1 &&
                    new Date(`${slf.season_id}-31`) >=
                      new Date(item.season[0]) &&
                    new Date(`${slf.season_id}-01`) <= new Date(item.season[1])
                  ) {
                    return true;
                  }
                }

                return false;
              })
              .map(slf => slf.position_id)
              .uniq()
              .map(position_id =>
                _.get(positions, `${position_id}.position_name`, ''),
              )
              .join(', ')
              .value();

            params.position_name = `All (${positionsFiltered})`;
          } else {
            params.position_name = _.get(
              positions,
              `${item.position}.position_name`,
              '',
            );
          }
        } catch (err) {
          console.error(err.message);
        }
    }

    // not needed for now but maybe later <DataItem className="col-2_5" reverse text={data.alignment} desc={lang.alignment.substring(0, 3)} />
    const loading = false;

    return (
      <div>
        <div className={`content__data ${mode}`}>
          <div className="row">
            <div className="col-3_5 noSpacing">
              <Image
                src={params.url}
                aspectRatio={0.768}
                backgroundColor={mode === 'team' ? 'white' : ''}
              />
            </div>

            <div className="col-8_5">
              <div className="content__data_header">
                <div className="content__data_header-container">
                  <Link
                    className="btn"
                    to={`/details/${mode}/${`${params.id}/${
                      item.league
                    }/${item.season.join('/')}`}/summary${
                      item.formation ? `?formation=${item.formation}` : ''
                    }${item.position ? `?position=${item.position}` : ''}`}
                  >
                    <div className="icon-more" />
                  </Link>

                  <div className="btn" onClick={this.editItem(num)}>
                    <div className="icon-edit" />
                  </div>

                  <div className="btn" onClick={this.removeItem(num)}>
                    <div className="icon-close" />
                  </div>
                </div>
              </div>

              <div className="row content__data_name">
                <div className="firstname">{params.first_name}</div>
                <div className="lastname">{params.last_name}</div>
                <div className="age">{params.birth_date}</div>
                {mode === 'player' ? (
                  <div className="pos">
                    <span className="team">
                      {params.emblemUrl ? (
                        <Image src={params.emblemUrl} aspectRatio={0.769} />
                      ) : null}{' '}
                      {params.team_name ? `${params.team_name}, ` : ''}
                    </span>
                    <span className="position">{params.position_name}</span>
                  </div>
                ) : null}
                {mode === 'team' ? (
                  <div className="pos">
                    <span className="position">{formation_name}</span>
                  </div>
                ) : null}
                <div className="place">
                  <span className="league">
                    {urlComp ? (
                      <Image src={urlComp} aspectRatio={0.769} />
                    ) : null}{' '}
                    {league_name},{' '}
                  </span>
                  &nbsp;
                  <span className="season">{season_name}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="row firstLine">
            <div className="col-1_5">&nbsp;</div>
            <DataItem
              className="col-2_5"
              reverse
              text={data.season_rating}
              desc={lang.season_rating.substring(0, 3)}
              isAccessGranted={item.isAccessGranted}
            />
            <DataItem
              className="col-2_5"
              reverse
              text={data.stability}
              desc={lang.stability.substring(0, 3)}
              isAccessGranted={item.isAccessGranted}
            />
            <DataItem
              className="col-2_5"
              reverse
              text={data.defence}
              desc={lang.defence.substring(0, 3)}
              isAccessGranted={item.isAccessGranted}
            />
            <DataItem
              className="col-2_5"
              reverse
              text={data.offence}
              desc={lang.offence.substring(0, 3)}
              isAccessGranted={item.isAccessGranted}
            />
          </div>

          <div className="row lastLine">
            <div className="col-1_5">&nbsp;</div>
            <DataItem
              className="col-2_5"
              reverse
              text={data.games}
              desc={lang.games.substring(0, 3)}
            />
            <DataItem
              className="col-2_5"
              reverse
              text={data.season_mins}
              desc={lang.season_mins.substring(0, 3)}
            />
            <DataItem
              className="col-2_5"
              reverse
              text={data.goals}
              desc={lang.goals.substring(0, 3)}
            />
            <DataItem
              className="col-2_5"
              reverse
              text={data.assists}
              desc={lang.assists.substring(0, 3)}
            />
          </div>

          {mode === 'player' ? (
            <div
              className={`content__item_add_actionbar ${
                loading ? 'is-loading' : ''
              }`}
            >
              <div
                className="btn callAction last_seasons"
                onClick={this.compareLastSeasons}
              >
                <span className="icon-compare" />
                Compare last seasons
                <BtnLoader />
              </div>
            </div>
          ) : null}
        </div>
        <CardCharts
          num={num}
          item={item}
          isAccessGranted={item.isAccessGranted}
          league={league}
          country={country}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    app: state.app,
  }),
  { loadPlayerDataField },
)(CompareCard);
