import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import Icon from '../ui/svg-icon';
import OverviewTable from './layouts/table';

import {
  sortTable,
  lassoSubSelection,
  changeLayout,
} from '../../actions/overview';

class Overview extends Component {
  static defaultProps = {
    ...Component.defaultProps,
    ids: {
      player: 'player_id',
      team: 'team_id',
      match: 'match_id',
    },
  };

  constructor(props) {
    super(props);

    this.renderResizeHandle = this.renderResizeHandle.bind(this);
  }

  toggleTable(to) {
    const legendHeight = 50;
    const marginTop = 20;
    const { clientHeight, activeMode } = this.props;
    const { tableVisible } = this.props.overview[activeMode].layouts;

    switch (tableVisible) {
      case 'half':
        if (to === 'up') {
          this.props.changeLayout({
            visualHeight: 0,
            tableHeight: clientHeight,
            tableVisible: 'visible',
            mode: activeMode,
          });
        } else if (to === 'down') {
          this.props.changeLayout({
            visualHeight: clientHeight - 90,
            tableHeight: 0,
            tableVisible: 'hidden',
            mode: activeMode,
          });
        }
        break;
      default: {
        const newVisualHeight = clientHeight * 0.618 - marginTop;
        const newTableHeight = clientHeight - newVisualHeight - legendHeight;
        this.props.changeLayout({
          visualHeight: newVisualHeight,
          tableHeight: newTableHeight,
          tableVisible: 'half',
          mode: activeMode,
        });
      }
    }

    if (this.props.overview[activeMode].scatterplot.lassoSelection) {
      this.props.lassoSubSelection(null);
    }

    this.props.setOverviewState({ selectItem: undefined });
  }

  renderResizeHandle() {
    const { activeMode } = this.props;
    const { tableVisible } = this.props.overview[activeMode].layouts;

    return (
      <div className={`resize_handle ${tableVisible}`}>
        <div
          className="resize-action action-up"
          onClick={this.toggleTable.bind(this, 'up')}
        >
          <Icon name="arrow-up" width="11" height="12" />
        </div>
        <div
          className="resize-action action-down"
          onClick={this.toggleTable.bind(this, 'down')}
        >
          <Icon name="arrow-down" width="11" height="12" />
        </div>
      </div>
    );
  }

  render() {
    const { filteredData, sortTable, clientHeight, activeMode, ids } =
      this.props;
    const { table, filterComponents } = this.props.overview[activeMode];
    const { leagues, teams, positions, loaded, formations } = this.props.app;
    const {
      tableHeight: height,
      contentWidth: width,
      visualHeight,
    } = this.props.overview[activeMode].layouts;
    const { lassoSelection } = this.props.overview[activeMode].scatterplot;
    const { xVal, yVal, rVal, cVal, sVal, sortDirection, sortBy, sortedData } =
      table;
    const idName = ids[activeMode];
    let rows;
    let showItem;

    switch (activeMode) {
      case 'player':
        showItem = d =>
          this.props.setOverviewState({ selectItem: d, tooltipOpen: true });

        const filterFormationValue = _.find(filterComponents.list, {
          name: 'formation_id',
        });
        const filterPositionValue = _.find(filterComponents.list, {
          name: 'position_first_id',
        });
        const splittedFormation = filterFormationValue
          ? filterFormationValue.splitted
          : false;
        const splittedPosition = filterPositionValue
          ? filterPositionValue.splitted
          : false;

        rows = [
          'preview',
          'last_name',
          'seasonAge',
          'team_id',
          'league_id',
          splittedPosition ? 'position_first_id' : 'position_id',
          splittedFormation ? 'formation_id' : null,
          xVal,
          yVal,
          rVal,
          cVal,
          'csv',
        ].filter(row => row);
        break;
      case 'team':
        showItem = d =>
          this.props.setOverviewState({ selectItem: d, tooltipOpen: true });
        rows = [
          'preview',
          'team_id',
          'league_id',
          'formation_id',
          'games',
          xVal,
          yVal,
          sVal,
          cVal,
          'csv',
        ];
        break;
      case 'match':
        rows = [
          'preview',
          'team_id_home',
          'team_id_away',
          'goals',
          'league_id',
          xVal,
          yVal,
          sVal,
          'csv',
        ];
        showItem = d =>
          this.props.setOverviewState({ selectItem: d[0], tooltipOpen: true });
        break;
    }

    return (
      <div
        className="overview_table"
        style={{ top: `${clientHeight - height}px` }}
      >
        {this.renderResizeHandle()}

        <OverviewTable
          {...{
            filteredData,
            sortTable,
            visualHeight,
            activeMode,
            sortDirection,
            sortedData,
            sortBy,
            leagues,
            teams,
            loaded,
            positions,
            lassoSelection,
            idName,
            formations,
            rows,
            height,
            width,
            showItem,
          }}
          fullscreen={height === clientHeight}
        />
      </div>
    );
  }
}

Overview.propTypes = {
  activeMode: PropTypes.string,
  app: PropTypes.object,
  changeLayout: PropTypes.func.isRequired,
  clientHeight: PropTypes.number,
  filteredData: PropTypes.array,
  ids: PropTypes.shape({
    match: PropTypes.string,
    player: PropTypes.string,
    team: PropTypes.string,
  }),
  lassoSubSelection: PropTypes.func,
  overview: PropTypes.shape({
    changeNum: PropTypes.number,
    match: PropTypes.object,
    player: PropTypes.object,
    showTooltip: PropTypes.object,
    sortedDataList: PropTypes.array,
    team: PropTypes.object,
  }),
  setOverviewState: PropTypes.func.isRequired,
  sortTable: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    overview: state.overview,
    app: state.app,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sortTable,
      lassoSubSelection,
      changeLayout,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
