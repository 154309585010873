import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import lang from '../../../../../lang';
import { SwitcherType } from '../../../../../reducers/overview/types';
import { ModeType } from '../../../../../types/Dict';
import { RootState } from '../../../../../types';
import { selectSwitcherFilter } from '../../../../../reducers/overview/selectors';
import { Option } from './Option';
import { toggleFilterSwitcher } from '../../../../../actions/overview';

interface Props {
  mode: ModeType;
}

const defaultProps = {
  switcherName: SwitcherType.FAVORITES,
};

const options = [
  {
    label: lang.all_players,
    value: false,
  },
  {
    label: lang.favorite_players_short,
    value: true,
  },
];

export function FavoritesFilter(props: Props & typeof defaultProps) {
  const { mode, switcherName } = props;
  const dispatch = useDispatch();

  const favoritesSwitcher = useSelector((state: RootState) =>
    selectSwitcherFilter(state, { switcherName: SwitcherType.FAVORITES, mode }),
  );

  const onChange = option => {
    dispatch(toggleFilterSwitcher({ switcherName, value: option.value, mode }));
  };

  return (
    <div className="switcher" key={switcherName}>
      {options.map((option, index) => (
        <Option
          key={index}
          option={option}
          active={option.value === favoritesSwitcher?.data}
          onChange={onChange}
        />
      ))}
    </div>
  );
}

FavoritesFilter.defaultProps = defaultProps;
