import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { browserHistory, Link } from 'react-router';
import Dropdown from 'react-dropdown';
import axios from 'axios';

import Image from '../../../ui/image';
import { DataItem } from '../../../ui/data-item';
import lang from '../../../../lang';
import { parseMatchGoals } from '../../../../helper/goals';
import { database } from '../../../../constants/database';

class MatchHeader extends Component {
  constructor(props) {
    super(props);

    this.changeMatchDay = this.changeMatchDay.bind(this);
  }

  changeMatchDay(event) {
    const { matchDays } = this.props;
    const matchday = _.find(matchDays, { matchday: event.value });

    matchday &&
      browserHistory.push(
        `/details/match/${matchday.match_id}/${matchday.league_id}/${matchday.season_id}/summary`,
      );
  }

  pdfLink(url, event) {
    event.preventDefault();
    const pdfWindow = window.open('', '', '');

    axios.get('/file/token').then(result => {
      const { token } = result.data;
      if (token) {
        pdfWindow.location = `${url}?token=${token}`;
      }
    });
  }

  render() {
    const { information, app, matchDays } = this.props;
    const { teams, leagues, countries } = app;
    const { id, current_league, current_season, mode } = this.props.details;

    const { homegoals, awaygoals, infoText } = parseMatchGoals(information);

    if (!_.size(information) || !information.team_id_home) {
      return (
        <div className="header-container">
          <div className="bar-container">
            <div className="match-header">
              <div className="row header" />
            </div>
          </div>
        </div>
      );
    }

    const MatchDayOptions = _.chain(matchDays)
      .map(day => ({ label: day.matchday, value: day.matchday }))
      .orderBy('value', 'desc')
      .value();

    const MatchDayValue = _.find(MatchDayOptions, {
      label: information.matchday,
    });

    return (
      <div className="header-container">
        <div className="bar-container">
          <div className="match-header">
            <div className="header">
              <div className="match-header-main">
                <Link
                  to={`/details/team/${information.team_id_home}/${information.league_id}/${information.season_id}/summary`}
                  className="team team-home"
                >
                  <div className="team-info">
                    <span className="team-name">
                      <span className="align">
                        {_.get(teams, `${information.team_id_home}.team_name`)}
                      </span>
                    </span>
                    {information.rank_home &&
                    _.get(
                      leagues,
                      `${information.league_id}.country_id`,
                      undefined,
                    ) != 0 ? (
                      <span className="place">
                        Place {information.rank_home}
                      </span>
                    ) : (
                      <span className="place">&nbsp;</span>
                    )}
                    <span className="team-legend-color" />
                  </div>
                  <Image
                    src={`${database.assets}teams/${information.team_id_home}.jpg`}
                    aspectRatio={0.769}
                  />
                </Link>
                <div className="goals">
                  <div className="time">
                    {information.playing_time ? information.playing_time : 90}`
                  </div>
                  <div className="big">
                    {homegoals == 99 ? '–' : homegoals}:
                    {awaygoals == 99 ? '–' : awaygoals}
                  </div>
                  <div className="small">
                    {information.homegoals_ht == 99
                      ? '–'
                      : information.homegoals_ht}{' '}
                    :{' '}
                    {information.awaygoals_ht == 99
                      ? '–'
                      : information.awaygoals_ht}
                  </div>
                  <div className="goals-info">{infoText}</div>
                </div>
                <Link
                  to={`/details/team/${information.team_id_away}/${information.league_id}/${information.season_id}/summary`}
                  className="team team-away"
                >
                  <Image
                    src={`${database.assets}teams/${information.team_id_away}.jpg`}
                    aspectRatio={0.769}
                  />
                  <div className="team-info">
                    <span className="team-name">
                      <span className="align">
                        {_.get(teams, `${information.team_id_away}.team_name`)}
                      </span>
                    </span>
                    {information.rank_away &&
                    _.get(
                      leagues,
                      `${information.league_id}.country_id`,
                      undefined,
                    ) != 0 ? (
                      <span className="place">
                        Place {information.rank_away}
                      </span>
                    ) : (
                      <span className="place">&nbsp;</span>
                    )}
                    <span className="team-legend-color" />
                  </div>
                </Link>
              </div>

              <div className="header__actionButtons_container primary">
                <a
                  target="_blank"
                  onClick={this.pdfLink.bind(
                    this,
                    `${database.pdf.details}${mode}/${id}/${current_season}/${current_league}/Details`,
                  )}
                  href="#"
                >
                  <span className="icon-pdf">
                    <svg role="img">
                      <use xlinkHref="/img/sprite.symbol.v106.svg#download" />
                    </svg>
                  </span>
                </a>
              </div>

              <div className="header__profile_detail">
                <DataItem desc={lang.league} reverse>
                  <div className="data-text">
                    <Image
                      src={`${database.assets}leagues/${information.league_id}.png`}
                      aspectRatio={0.769}
                    />
                    {leagues[information.league_id].name}
                    {_.get(
                      countries,
                      `${[leagues[information.league_id].country_id]}.country`,
                      null,
                    )}
                  </div>
                </DataItem>
                <DataItem desc={lang.stadium} reverse>
                  <span
                    className="data-text"
                    dangerouslySetInnerHTML={{ __html: information.stadium }}
                  />
                </DataItem>
                <DataItem
                  desc={lang.time}
                  text={moment(information.date).format(
                    'ddd, DD MMM YYYY HH:mm',
                  )}
                  reverse
                />
                <DataItem
                  className="overflow-visible"
                  desc={lang.matchday}
                  reverse
                >
                  <div className="data-text">
                    <Dropdown
                      options={MatchDayOptions}
                      onChange={this.changeMatchDay}
                      value={MatchDayValue}
                      placeholder=" "
                    />
                  </div>
                </DataItem>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    app: state.app,
    details: state.details,
  };
}

export default connect(mapStateToProps)(MatchHeader);
