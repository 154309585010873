import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';
import { FAVORITE_CATEGORIES } from '../../constants';
import enhanceWithClickOutside from 'react-click-outside';
import Icon from './svg-icon';

import './category-selector.scss';
import lang from '../../lang';

class FavoriteAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleList = this.toggleList.bind(this);
  }

  toggleList() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleClickOutside() {
    this.setState({
      isOpen: false,
    });
  }

  renderList = () => {
    const rowHeight = 39,
      listWidth = 150;

    const list = [
      { val: null, label: lang.not_a_favorite },
      ...FAVORITE_CATEGORIES,
    ];

    const rowRender = ({ index, style }) => (
      <div
        role="button"
        key={index}
        style={style}
        className={`list-item category-${list[index].val}`}
        onClick={() => {
          this.props.onChange(
            this.props.id,
            this.props.user_id,
            list[index].val,
          );
          this.toggleList();
        }}
      >
        <Icon name="favorite" />
        {list[index].label}
      </div>
    );

    return (
      <List
        key="val"
        width={listWidth}
        height={list.length * rowHeight}
        rowCount={list.length}
        rowHeight={rowHeight}
        rowRenderer={rowRender}
      />
    );
  };

  render() {
    const category = this.props.category || 0;
    const { label } = FAVORITE_CATEGORIES.find(
      c => +c.val === (+category || 0),
    );

    return (
      <div className={`category-selector ${this.props.className}`}>
        <div className="label" onClick={this.toggleList}>
          {label}
        </div>
        {this.state.isOpen ? (
          <div className="list">{this.renderList()}</div>
        ) : null}
      </div>
    );
  }
}

FavoriteAdd.propTypes = {
  category: PropTypes.number,
  id: PropTypes.any,
  user_id: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default enhanceWithClickOutside(FavoriteAdd);
