import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { connect } from 'react-redux';
import { changeFilter, getFavorites } from '../../actions/favorites';
import { loading } from '../../actions/app';

class FavoriteSection extends Component {
  static propTypes = {
    children: PropTypes.any,
    changeFilter: PropTypes.func,
    loading: PropTypes.func,
    params: PropTypes.object,
    getFavorites: PropTypes.func,
    app: PropTypes.object,
  };

  componentDidMount() {
    const { app, getFavorites, changeFilter, loading } = this.props;

    const mode =
      this.props.params.mode ||
      localStorage.getItem('overview-mode') ||
      'player';
    const { playersMetaData } = app;

    loading(true);

    if (app.overviewLoaded) {
      getFavorites({ mode }).then(data => {
        const list = data.payload[0].data.list.map(favorite => ({
          ...favorite,
          ...playersMetaData[favorite[`${mode}_id`]],
        }));
        const ageRange = [
          +_.get(_.minBy(list, 'age'), 'age', 0),
          +_.get(_.maxBy(list, 'age'), 'age', 0),
        ];

        changeFilter({ name: 'age_range', values: ageRange });
        changeFilter({ name: 'age', values: ageRange });

        loading(false);
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { app, getFavorites, changeFilter, loading } = this.props;
    const nextApp = nextProps.app;

    if (app.overviewLoaded === false && nextApp.overviewLoaded === true) {
      const mode =
        this.props.params.mode ||
        localStorage.getItem('overview-mode') ||
        'player';
      const { playersMetaData } = nextApp;

      getFavorites({ mode }).then(data => {
        const list = data.payload[0].data.list.map(favorite => ({
          ...favorite,
          ...playersMetaData[favorite[`${mode}_id`]],
        }));
        const ageRange = [
          +_.get(_.minBy(list, 'age'), 'age', 0),
          +_.get(_.maxBy(list, 'age'), 'age', 0),
        ];

        changeFilter({ name: 'age_range', values: ageRange });
        changeFilter({ name: 'age', values: ageRange });

        loading(false);
      });
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default connect(
  state => ({
    app: state.app,
    favorites: state.favorites,
    shadowTeams: state.shadowTeams,
  }),
  {
    getFavorites,
    loading,
    changeFilter,
  },
)(FavoriteSection);
