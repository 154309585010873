import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import './competition-level-hint.scss';

import Icon from '../../ui/svg-icon';
import Image from '../../ui/image';
import { database } from '../../../constants/database';

const CompetitionLevelHint = forwardRef((props, ref) => {
  const { selectedLeague, userLeague } = useSelector(
    state => state.competitionLevel,
  );

  const selectedLeagueName = selectedLeague?.name || '';
  const selectedLeagueRating = +selectedLeague?.rating?.toFixed(1) || '';
  const selectedLeagueLogo = `${database.assets}leagues/${selectedLeague.id}.png`;

  const userLeagueName = userLeague?.name || '';
  const userLeagueRating = +userLeague?.rating?.toFixed(1);
  const userLeagueLogo = `${database.assets}leagues/${userLeague?.id}.png`;
  const showComparison =
    userLeague && userLeagueRating && selectedLeagueRating !== userLeagueRating;

  const text = showComparison ? (
    <>
      <nobr>
        {'Competition level of '}
        <Image src={selectedLeagueLogo} aspectRatio={0.769} />
        {` ${selectedLeagueName} (${selectedLeagueRating})`}
      </nobr>
      <br />
      <nobr>
        {' is '}
        <b>{`${Math.abs(
          (userLeagueRating - selectedLeagueRating).toFixed(1),
        )} points ${
          selectedLeagueRating < userLeagueRating ? 'lower' : 'higher'
        }`}</b>
        {' on average than '}
      </nobr>
      <nobr>
        <Image src={userLeagueLogo} aspectRatio={0.769} />
        {` ${userLeagueName} (${userLeagueRating})`}
      </nobr>
    </>
  ) : (
    <nobr>
      {'Competition level of '}
      <Image src={selectedLeagueLogo} aspectRatio={0.769} />
      {` ${selectedLeagueName} (${selectedLeagueRating})`}
    </nobr>
  );

  return (
    <div className="competition-level-hint" {...props} ref={ref}>
      {showComparison ? (
        <div
          className={`hint-icon ${
            selectedLeagueRating < userLeagueRating ? 'lower' : 'higher'
          }`}
        >
          <Icon name="competition-level-hint" />
        </div>
      ) : null}
      <div className="hint-content">
        <div className="hint-primary-text">{text}</div>
        <div className="hint-secondary-text">
          VIEW MORE BY CLICKING ON COMPETITION
        </div>
      </div>
    </div>
  );
});

export default CompetitionLevelHint;
