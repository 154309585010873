import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../types';
import { ModeType } from '../../types/Dict';
import {
  IndicatorType,
  ListType,
  OverviewState,
  Slider,
  List,
  SwitcherType,
  Switcher,
  FilterComponents,
} from './types';
import { generateFlattenList } from './utils';
import lang from '../../lang';

const selectOverview = (state: RootState): OverviewState => state.overview;
const selectMode = (state, props: { mode: ModeType }): ModeType => props.mode;
const selectListName = (state, props: { listName: ListType }): ListType =>
  props.listName;
const selectIgnoreOpen = (state, props: { ignoreOpen?: boolean }): boolean =>
  props.ignoreOpen || false;
const selectSliderName = (
  state,
  props: { sliderName: IndicatorType },
): IndicatorType => props.sliderName;
const selectSwitcherName = (
  state,
  props: { switcherName: SwitcherType },
): SwitcherType => props.switcherName;

export const selectFlattenListFilter = createSelector(
  [selectOverview, selectMode, selectListName, selectIgnoreOpen],
  (overview, mode, listName, ignoreOpen = false) => {
    if (!overview[mode]?.filterComponents.list[listName]) return [];

    return generateFlattenList(
      overview[mode].filterComponents.list[listName]!.data,
      ignoreOpen,
      listName,
    );
  },
);

export const selectListTitle = createSelector(
  [
    (state, props) =>
      selectFlattenListFilter(state, { ...props, ignoreOpen: true }),
    selectMode,
    selectListName,
    selectOverview,
  ],
  (flattenList, mode, listName, overview) => {
    if (listName === ListType.FAVORITE_LEAGUES) {
      return 'My favorite leagues';
    }

    if (listName === ListType.PRESET_ID) {
      return (
        overview[mode].filterComponents.list[listName]!.data.find(
          ({ active }) => active,
        )?.name || 'No filter presets'
      );
    }

    if (flattenList[0]?.active && flattenList[0].hasChild) {
      switch (listName) {
        case ListType.LEAGUE_ID:
          return 'All leagues';
        case ListType.TEAM_ID:
          return 'All teams';
        case ListType.POSITION_FIRST_ID:
          return 'All positions';
        case ListType.COUNTRY_ID:
          return 'All countries';
        case ListType.FORMATION_ID:
          return 'All formations';
        case ListType.PREFERRED_FOOT:
          return 'All foots';
      }
    }

    if (listName === ListType.LEAGUE_ID) {
      const isDomestics = overview[mode].filterComponents.list[
        ListType.FAVORITE_LEAGUES
      ]!.data.find(({ id }) => id === 'DOMESTICS')?.active;

      if (isDomestics) return 'Domestic leagues';
    }

    const activeOptions = flattenList.filter(
      ({ active, type }) => active && type === 'item',
    );

    if (activeOptions.length === 1) {
      return activeOptions[0].name;
    } else if (activeOptions.length > 1) {
      switch (listName) {
        /*case ListType.SEASON_ID: {
          break;
        }*/
        case ListType.LEAGUE_ID:
          return `${activeOptions.length} leagues`;
        case ListType.TEAM_ID:
          return `${activeOptions.length} teams`;
        case ListType.POSITION_FIRST_ID:
          return `${activeOptions.length} positions`;
        case ListType.COUNTRY_ID:
          return `${activeOptions.length} countries`;
        /*case ListType.PRESET_ID: {
          break;
        }*/
        case ListType.FORMATION_ID:
          return `${activeOptions.length} formations`;
        case ListType.PREFERRED_FOOT:
          return `All foots`;
      }
    }

    return lang[listName] || '';
  },
);

export const selectSliderFilter = createSelector(
  [selectOverview, selectMode, selectSliderName],
  (overview, mode, sliderName): Slider | undefined => {
    return overview[mode].filterComponents.slider[sliderName];
  },
);

export const selectListFilter = createSelector(
  [selectOverview, selectMode, selectListName],
  (overview, mode, listName): List | undefined => {
    return overview[mode].filterComponents.list[listName];
  },
);

export const selectSwitcherFilter = createSelector(
  [selectOverview, selectMode, selectSwitcherName],
  (overview, mode, switcherName): Switcher | undefined => {
    return overview[mode].filterComponents.switcher[switcherName];
  },
);

export const selectFilterComponents = createSelector(
  [selectOverview, selectMode],
  (overview, mode): FilterComponents => {
    return overview[mode].filterComponents;
  },
);
