import VirtualizedSelect from 'react-virtualized-select';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';

import './users.scss';
import './datepicker.scss';
import './select.scss';

import Add from './users/add';
import User from './users/user';

import { getUsers, deleteUser, updateUser, addUser } from '../../actions/users';
import { removeDiacritics } from '../../helper';

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: null,
      filter: null,
      search: '',
    };

    this.addNewUser = this.addNewUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.setEditMode = this.setEditMode.bind(this);
    this.editUser = this.editUser.bind(this);
    this.activateUser = this.activateUser.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
  }

  componentWillMount() {
    if (this.props.auth.user.role.name !== 'Admin') {
      browserHistory.replace('/account/general');
    }
  }

  setEditMode(id) {
    this.setState({ edit: id });
  }

  changeFilter(id) {
    this.setState({ filter: id });
  }

  changeSearch(event) {
    this.setState({
      search: event.target.value,
    });
  }

  addNewUser(user) {
    this.props.addUser({ user });
  }

  activateUser({ user }) {
    const {
      id,
      firstName,
      lastName,
      email,
      groupId,
      active,
      roleId,
      expireDate,
    } = user;

    this.props.updateUser({
      userId: id,
      body: {
        firstName,
        lastName,
        email,
        groupId,
        active,
        roleId,
        expireDate,
      },
    });
  }

  deleteUser(id) {
    this.props.deleteUser({ userId: id });
  }

  editUser(user) {
    const {
      id,
      firstName,
      lastName,
      email,
      groupId,
      expireDate,
      roleId,
      locale,
      type,
    } = user;
    const emailDuplicate = _.find(this.props.users, { email });

    if (emailDuplicate && emailDuplicate.id !== id) {
      return 'email';
    }
    this.props.updateUser({
      userId: id,
      body: {
        firstName,
        lastName,
        email,
        groupId,
        expireDate,
        roleId,
        locale,
        type,
      },
    });

    /*.then(() => {
        this.props.dispatch(accountApi.actions.users());
      });*/

    return false;
  }

  renderUsersList() {
    const { groups, users, roles, locales, types } = this.props;
    const { filter, search } = this.state;

    const searchWords = search
      .split(' ')
      .filter(word => word && word.length)
      .map(word => removeDiacritics(word));

    const filteredUsers = users.filter(user => {
      if (filter && user.groupId !== filter) return false;
      if (search.length) {
        const finedWords = searchWords.filter(
          word =>
            removeDiacritics(`${user.firstName} ${user.lastName}`)
              .toLowerCase()
              .trim()
              .indexOf(word.toLowerCase()) >= 0,
        );

        if (finedWords.length !== searchWords.length) return false;
      }

      return true;
    });

    if (!filteredUsers || !filteredUsers.length) return null;

    return filteredUsers.map(user => {
      return (
        <User
          roles={roles}
          groups={groups}
          user={user}
          key={user.id}
          delete={this.deleteUser}
          editMode={this.state.edit === user.id}
          setEdit={this.setEditMode}
          edit={this.editUser}
          activate={this.activateUser}
          searchString={search}
          locales={locales}
          types={types}
        />
      );
    });
  }

  render() {
    const { filter, search } = this.state;
    const { roles, groups, locales, types } = this.props;

    const groupOptions = groups.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    return (
      <div>
        <div className="users-list-filter">
          <VirtualizedSelect
            options={[{ value: null, label: 'All groups' }, ...groupOptions]}
            onChange={value => {
              this.changeFilter(value.value);
            }}
            value={filter}
            clearable={false}
            placeholder="Filter users by group..."
          />
        </div>
        <div className="users-list-search">
          <input
            type="text"
            placeholder="Search by username"
            value={search}
            onChange={this.changeSearch}
          />
        </div>
        <Add
          roles={roles}
          groups={groups}
          locales={locales}
          types={types}
          create={this.addNewUser}
          initialValues={{
            active: true,
            groupId: filter,
            locale: 'en',
            type: 1,
          }}
        />
        <table className="users-list">
          <thead>
            <tr>
              <th width="60px">ID</th>
              <th width="11%">First name</th>
              <th width="11%">Last name</th>
              <th width="11%">Email</th>
              <th width="11%">Expire date</th>
              <th width="11%">Group</th>
              <th width="11%">Role</th>
              <th width="11%">Locale</th>
              <th width="11%">Type</th>
              <th />
            </tr>
          </thead>
          <tbody>{this.renderUsersList()}</tbody>
        </table>
      </div>
    );
  }
}

User.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  locales: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
};

User.defaultProps = {
  locales: [
    {
      id: 'en',
      name: 'English',
    },
    {
      id: 'de',
      name: 'German',
    },
  ],
};

export default connect(
  state => ({
    auth: state.auth,
    user: state.user,
    users: state.users,
    roles: state.roles,
    groups: state.groups,
    types: state.userTypes,
  }),
  { getUsers, deleteUser, updateUser, addUser },
)(Users);
