import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { DragDropContext } from 'react-dnd';
import TouchBackend from 'react-dnd-touch-backend';
import Select from 'react-select';
import PropTypes from 'prop-types';

import PlayerList from './players';
import PlayerPreview from './preview';
import PlayerPopup from './popup';
import Position from './position';

import {
  findBestPlayerForPosition,
  findBestPlayerForUpperPosition,
  getPosition,
} from '../../../helper';
import { updateShadowTeam } from '../../../actions/shadow-teams';
import { loading } from '../../../actions/app';

import './shadow-team.scss';

class ShadowTeam extends Component {
  static propTypes = {
    app: PropTypes.object,
    user: PropTypes.object,
    team: PropTypes.object,
    updateShadowTeam: PropTypes.func,
    loading: PropTypes.func,
  };

  constructor(props) {
    super(props);

    let onlyMyPlayers = localStorage.getItem('shadowOnlyMyPlayers');
    if (onlyMyPlayers === null) {
      onlyMyPlayers = true;
    }

    if (onlyMyPlayers === 'false') {
      onlyMyPlayers = false;
    }

    this.state = {
      onlyMyPlayers,
      playersListAll: [],
      name: props.team.name,
      players: props.team.players || {},
      popup: {
        show: false,
      },
      formationPresets: [
        {
          label: 'Custom',
          value: 0,
        },
        {
          label: 'Best U21',
          value: 1,
        },
        {
          label: 'Best rating',
          value: 2,
        },
        {
          label: 'Best offence',
          value: 3,
        },
        {
          label: 'Best defence',
          value: 4,
        },
      ],
    };

    this.changeFormation = this.changeFormation.bind(this);
    this.setPlayer = this.setPlayer.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.changePreset = this.changePreset.bind(this);
  }

  componentDidMount() {
    const { positions, playersMetaData } = this.props.app;

    let playersListAll = this.props.players.map(player => {
      const { player_id } = player;
      let updPlayer = { ...player };

      if (playersMetaData[player_id]) {
        updPlayer = { ...updPlayer, ...playersMetaData[player_id] };
      }
      updPlayer.position_name = _.get(
        positions,
        `${updPlayer.position_id}.position_name`,
        null,
      );
      updPlayer.position_id = +updPlayer.position_id;
      updPlayer.season_rating = +updPlayer.season_rating || 0;

      return updPlayer;
    });

    playersListAll = _.orderBy(
      playersListAll,
      ['position_id', 'season_rating'],
      ['asc', 'desc'],
    );

    this.setState({ playersListAll });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.team.id !== nextProps.team.id) {
      this.setState({
        name: nextProps.team.name,
        players: nextProps.team.players || {},
        popup: {
          show: false,
        },
      });
    }

    if (nextProps.players !== this.props.players) {
      const { positions, playersMetaData } = this.props.app;

      let playersListAll = this.props.players.map(player => {
        const { player_id } = player;
        let updPlayer = { ...player };

        if (playersMetaData[player_id]) {
          updPlayer = { ...updPlayer, ...playersMetaData[player_id] };
        }
        updPlayer.position_name = _.get(
          positions,
          `${updPlayer.position_id}.position_name`,
          null,
        );
        updPlayer.position_id = +updPlayer.position_id;
        updPlayer.season_rating = +updPlayer.season_rating || 0;

        return updPlayer;
      });

      playersListAll = _.orderBy(
        playersListAll,
        ['position_id', 'season_rating'],
        ['asc', 'desc'],
      );

      this.setState({ playersListAll });
    }
  }

  changeFormation(formation) {
    this.props.updateShadowTeam({
      ...this.props.team,
      formation_id: formation.value,
      preset: 0,
    });
  }

  changeName(name) {
    this.props.updateShadowTeam({ ...this.props.team, name });
    this.setState({ name });
  }

  showPopup(player, position, coords) {
    setTimeout(() => {
      this.setState({
        popup: {
          show: true,
          player,
          position,
          coords,
        },
      });
    }, 150);
  }

  closePopup() {
    this.setState({
      popup: { show: false },
    });
  }

  setPlayer(newPositionId, newPlayerId, oldPositionId, oldPlayerId) {
    const players = _.cloneDeep(this.state.players);
    players[newPositionId] = +newPlayerId;

    if (oldPositionId) {
      players[oldPositionId] = +oldPlayerId;
    }
    this.setState({ players });
    this.props.updateShadowTeam({ ...this.props.team, players, preset: 0 });
  }

  changePreset(preset) {
    if (preset.value === 0) {
      this.props.updateShadowTeam({ ...this.props.team, preset: preset.value });
      return;
    }

    const { playersListAll } = this.state;
    const { team, formationPosition, positions } = this.props;

    const currentPlayers = this.state.players;
    let isEmpty = true;

    for (const key in currentPlayers) {
      if (currentPlayers[key] !== null) {
        isEmpty = false;
      }
    }

    if (
      !isEmpty &&
      !window.confirm('Do you really want to change the current formation?')
    ) {
      return;
    }

    let playersList = this.state.onlyMyPlayers
      ? playersListAll.filter(p => +this.props.user.id === +p.user_id)
      : [...playersListAll];
    let players = {};
    const currentFormationsPositions = formationPosition[team.formation_id];

    // strict position
    for (let i = 0; i < currentFormationsPositions.length; i++) {
      const tempPositionId = currentFormationsPositions[i].position_id;
      const pos = positions[tempPositionId];
      const upperPos = positions[pos.upper_position_id];
      const res = findBestPlayerForPosition(
        playersList,
        players,
        pos,
        upperPos,
        preset.value,
      );
      playersList = res.list;
      players = res.players;
    }

    // by upper position
    for (let i = 0; i < currentFormationsPositions.length; i++) {
      const tempPositionId = currentFormationsPositions[i].position_id;
      const pos = positions[tempPositionId];
      const upperPos = positions[pos.upper_position_id];

      if (!players[+pos.id]) {
        const res = findBestPlayerForUpperPosition(
          playersList,
          players,
          pos,
          upperPos,
          preset.value,
        );
        playersList = res.list;
        players = res.players;
      }
    }

    if (_.isEmpty(players)) {
      alert('No players fulfilling this category to fill up the lineup');
      return;
    }

    this.props.updateShadowTeam({
      ...this.props.team,
      players,
      preset: preset.value,
    });
    this.setState({ players });
  }

  renderSettings() {
    const { team, formations } = this.props;

    const { formationPresets } = this.state;

    const formationsOptions = [];
    const errors = false;

    _.forIn(formations, val => {
      if (val.id != 0 && val.id != 1) {
        formationsOptions.push({
          value: +val.id,
          label: val.name,
          s: val.name.replace(/-/gi, ''),
        });
      }
      return true;
    });

    return (
      <div className="shadow-team__settings clearfix">
        <div className="shadow-team__name">
          <label>Name</label>
          <div className="input">
            <input
              className={`${errors.name ? 'invalid' : ''}`}
              type="text"
              onChange={event => this.changeName(event.target.value)}
              value={this.state.name}
              placeholder="Team name"
            />
          </div>
        </div>
        <div className="shadow-team__formation">
          <label>Formation</label>
          <div className="input">
            <Select
              options={formationsOptions}
              className={`${
                errors.formation
                  ? 'styled-react-select invalid'
                  : 'styled-react-select'
              }`}
              onChange={option => {
                if (!option) return;
                this.changeFormation(option);
              }}
              value={formationsOptions.find(
                f => +f.value === +team.formation_id,
              )}
              filterOptions={(opts, f) =>
                opts.filter(
                  o => o.label.indexOf(f) !== -1 || o.s.indexOf(f) !== -1,
                )
              }
              placeholder="Formation"
            />
          </div>
        </div>
        <div className="shadow-team__autofill">
          <label>Autofill</label>
          <Select
            options={formationPresets}
            className="styled-react-select"
            onChange={option => {
              if (!option) return;
              this.changePreset(option);
            }}
            value={formationPresets.find(p => +p.value === team.preset) || 0}
            placeholder="Formation"
          />
        </div>
      </div>
    );
  }

  renderFormation() {
    const { team, formations, formationPosition, positions } = this.props;
    const { players } = this.state;
    const current = formationPosition[team.formation_id];

    if (!current || !current.length) return null;

    return current.map(p => {
      const position = positions[p.position_id];

      if (!position) {
        return null;
      }
      const { position_name } = position;
      const coords = getPosition(position_name);
      const player_id = (players && players[position.id]) || null;
      const player = this.state.playersListAll.find(
        p => player_id == p.player_id,
      );

      return (
        <Position
          player={player}
          position={position}
          coords={coords}
          key={p.id}
          setPlayer={this.setPlayer}
          showPopup={this.showPopup.bind(this, player, position, coords)}
        />
      );
    });
  }

  renderPopup() {
    const { app, team, user } = this.props;
    return (
      <PlayerPopup
        data={this.state.popup}
        closePopup={this.closePopup}
        teams={app.teams}
        leagues={app.leagues}
        setPlayer={this.setPlayer}
        teamPlayers={this.state.players}
        playersList={this.state.playersListAll}
        userId={this.state.onlyMyPlayers ? user.id : null}
      />
    );
  }

  render() {
    const { user } = this.props;

    return (
      <div className="row shadow-team__content">
        <div className="shadow-team__players-container">
          <h2 className="heading lined">
            <span>Players</span>
          </h2>
          {+user.role.id === 2 || +user.role.id === 3 ? (
            <div className="group-check">
              <label
                onClick={() => {
                  localStorage.setItem(
                    'shadowOnlyMyPlayers',
                    !this.state.onlyMyPlayers,
                  );
                  this.setState({ onlyMyPlayers: !this.state.onlyMyPlayers });
                }}
                className="checked"
              >
                <span className="checkbox">
                  {!this.state.onlyMyPlayers ? (
                    <span className="icon-check" />
                  ) : null}
                </span>
                Show group players, too.
              </label>
            </div>
          ) : null}
          <PlayerList
            players={this.state.playersListAll}
            team={this.state.players}
            setPlayer={this.setPlayer}
            userId={this.state.onlyMyPlayers ? user.id : null}
          />
        </div>
        <div className="shadow-team__lineup-container">
          <h2 className="heading lined">
            <span>Line up</span>
          </h2>
          {this.renderSettings()}
          <div className="shadow-team__lineup">
            <div className="shadow-team__lineup-content">
              <span className="home" />
              <span className="away" />
              {this.renderFormation()}
              {this.state.popup.show ? this.renderPopup() : null}
            </div>
          </div>
        </div>
        <PlayerPreview />
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    app: state.app,
    user: state.auth.user,
    players: state.favorites.player.list,
    team: {
      ...state.shadowTeams.find(team => +team.id === +ownProps.params.team),
    },
    formations: state.app.formations,
    positions: state.app.positions,
    formationPosition: state.app.formationPosition,
  }),
  { updateShadowTeam, loading },
)(
  DragDropContext(
    TouchBackend({
      enableMouseEvents: true,
      delayTouchStart: 0,
      delayMouseStart: 0,
    }),
  )(ShadowTeam),
);
