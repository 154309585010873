import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Scrollbars } from 'react-custom-scrollbars';
import { push, replace } from 'react-router-redux';
import _ from 'lodash';

import Nav from './nav';
import Icon from '../ui/svg-icon';
import lang from '../../lang';

import { getFavorites } from '../../actions/favorites';
import { loading } from '../../actions/app';
import {
  closeShadowTab,
  createShadowTeam,
  fetchShadowTabs,
  fetchShadowTeams,
  openShadowTab,
} from '../../actions/shadow-teams';

import './shadow.scss';
import './styled-react-select.scss';

const getWindowHeight = (minus = 0) =>
  document.documentElement.clientHeight
    ? document.documentElement.clientHeight - minus
    : window.innerHeight - minus;

class Favorites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: getWindowHeight(),
      isMoreOpen: false,
    };

    this.handleCloseTab = this.handleCloseTab.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    this.props.loading(true);

    if (this.props.app.overviewLoaded) {
      Promise.all([
        this.props.fetchShadowTeams(),
        this.props.getFavorites({ mode: 'player' }),
        this.props.fetchShadowTabs(this.props.user.id),
      ]).then(() => {
        this.props.loading(false);
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { app } = this.props;
    const nextApp = nextProps.app;

    if (app.overviewLoaded === false && nextApp.overviewLoaded === true) {
      Promise.all([
        this.props.fetchShadowTeams(),
        this.props.getFavorites({ mode: 'player' }),
        this.props.fetchShadowTabs(this.props.user.id),
      ]).then(() => {
        this.props.loading(false);
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({ height: getWindowHeight() });
  }

  createTeam() {
    this.props
      .createShadowTeam({ name: 'Untitled', formation: 2 })
      .then(result => {
        const { id } = result.payload.data;
        this.props.openShadowTab(id, this.props.user.id);
        this.props.push(`/favorites/shadow-teams/${id}`);
      });
  }

  renderSubNav() {
    const shadowTabs = _.uniq(this.props.shadowTabs);

    if (shadowTabs.length < 5) {
      return (
        <div className="nav-container-white">
          <div className="container">
            <div className="nav row">
              <Link
                className="col-2 callAction"
                activeClassName="active"
                onlyActiveOnIndex
                to="/favorites/shadow-teams/"
              >
                Overview
              </Link>

              {shadowTabs.map(tab => this.renderSubNavItem(tab))}

              <span
                onClick={this.createTeam.bind(this)}
                className="col-2 callAction"
              >
                <Icon name="plus" className="create-icon" />
                Create team
              </span>
            </div>
          </div>
        </div>
      );
    }

    const firstPart = shadowTabs.slice(0, 4);
    const secondPart = shadowTabs.slice(4);

    return (
      <div className="nav-container-white">
        <div className="container">
          <div className="nav row">
            <Link
              className="col-2 callAction"
              activeClassName="active"
              onlyActiveOnIndex
              to="/favorites/shadow-teams/"
            >
              Overview
            </Link>
            {firstPart.map(tab => this.renderSubNavItem(tab))}
            <span
              className={`col-2 callAction more ${
                this.state.isMoreOpen ? 'open' : ''
              }`}
              onClick={this.toggleDropdown}
            >
              More <Icon name="sort" className="more-icon" />
              {this.state.isMoreOpen ? (
                <span className="dropdown">
                  {secondPart.map(tab => this.renderSubNavItem(tab, true))}
                  <span
                    onClick={this.createTeam.bind(this)}
                    className="callAction"
                  >
                    <Icon name="plus" className="create-icon" />
                    Create team
                  </span>
                </span>
              ) : null}
            </span>
          </div>
        </div>
      </div>
    );
  }

  toggleDropdown() {
    this.setState({
      isMoreOpen: !this.state.isMoreOpen,
    });
  }

  handleCloseTab(id, e) {
    if (+id === +this.props.currentTeam) {
      this.props.replace('/favorites/shadow-teams/');
    }
    this.props.closeShadowTab(id, this.props.user.id);
  }

  renderSubNavItem(id, isInMore) {
    const item = this.props.shadowTeams.find(t => t.id == id);
    const cls = !isInMore ? 'col-2 callAction' : 'callAction';

    return (
      <Link
        className={cls}
        activeClassName="active"
        to={`/favorites/shadow-teams/${id}`}
        key={id}
      >
        <span
          className="close-icon"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            this.handleCloseTab(id);
          }}
        >
          <Icon name="close" />
        </span>
        {item ? item.name : 'untitled'}
      </Link>
    );
  }

  render() {
    const { height } = this.state;
    const { mode } = this.props.params;
    const { shadowTeams } = this.props;

    if (!shadowTeams) {
      return null;
    }

    return (
      <div className="favorites shadow-team">
        <Scrollbars style={{ height }} autoHide>
          <div className="header-container">
            <div className="container">
              <div className="row">
                <div className="col-4">
                  <div className="info-logo">
                    <Icon name="favorite" width="20px" height="20px" />
                    <span>{lang.favorite_players}</span>
                  </div>
                </div>
                <div className="col-4">&nbsp;</div>
                <div className="col-4">
                  <div className="btn pull-right">
                    <Link to={`/overview/player`} className="icon-close" />
                  </div>
                  <div className="btn pull-right">
                    {/* <a onClick={ this.exportPDF.bind(this) } className="icon-pdf">
                      <svg role="img"><use xlinkHref={`/img/sprite.symbol.v106.svg#download`}/></svg>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Nav />
          {this.renderSubNav()}
          <div className="content-container">
            <div className="container shadow-container">
              {this.props.children}
            </div>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    app: state.app,
    favorites: state.favorites,
    shadowTeams: state.shadowTeams,
    shadowTabs: state.shadowTabs,
    currentTeam: ownProps.params.team,
    user: state.auth.user,
  }),
  {
    getFavorites,
    loading,
    fetchShadowTeams,
    createShadowTeam,
    fetchShadowTabs,
    closeShadowTab,
    openShadowTab,
    replace,
    push,
  },
)(Favorites);
