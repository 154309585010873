import React, { Component } from 'react';

export default class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      image: document.createElement('img'),
    };

    this.handleImageLoaded = this.handleImageLoaded.bind(this);
  }

  componentDidMount() {
    const { image } = this.state;

    image.addEventListener('load', this.handleImageLoaded);
    image.src = this.props.src;
  }

  componentWillUnmount() {
    const { image } = this.state;
    image.removeEventListener('load', this.handleImageLoaded);
  }

  handleImageLoaded = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { backgroundColor = 'transparent' } = this.props;
    const divStyles = {
      position: 'relative',
      paddingBottom: `${Math.round(100 / Number(this.props.aspectRatio))}%`,
    };

    const imageStyles = {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      backgroundImage: `url(${this.props.src})`,
      opacity: this.state.loaded ? 100 : 0,
      transition: this.props.transition || 'opacity 0.6s ease',
      backgroundColor,
    };

    return (
      <div className="pic">
        <div
          style={divStyles}
          className={`component-image${
            !this.state.loaded ? ' component-image--loading' : ''
          }`}
        />
        <div style={imageStyles} />
      </div>
    );
  }
}
