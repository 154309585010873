import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import {
  CHANGE_OVERVIEW_RANKINGS_SETTINGS_COLOR,
  CHANGE_OVERVIEW_RANKINGS_SETTINGS_RANK,
  SET_FILTER_PRESET,
} from '../../constants';
import { OverviewRankingsState } from './types';
import { IndicatorType } from '../../types/Indicators';

export const initialState: OverviewRankingsState = {
  settings: {
    rankBy: [IndicatorType.SEASON_RATING],
    colorBy: IndicatorType.SEASON_MINS,
    indicatorsForRank: [
      IndicatorType.SEASON_RATING,
      IndicatorType.STABILITY,
      IndicatorType.DEFENCE,
      IndicatorType.OFFENCE,
      IndicatorType.CONTRIBUTION_DEFENCE,
      IndicatorType.CONTRIBUTION_OFFENCE,
      IndicatorType.CONTRIBUTION_PASS,
      IndicatorType.TACKLE_DEFENCE,
      IndicatorType.TACKLE_OFFENCE,
      IndicatorType.INTERCEPT_DEFENCE,
      IndicatorType.INTERCEPT_OFFENCE,
      IndicatorType.BLOCKED,
      IndicatorType.SAVE,
      IndicatorType.AERIAL_OFFENCE,
      IndicatorType.AERIAL_DEFENCE,
      IndicatorType.TAKE_ON,
      IndicatorType.CROSSING,
      IndicatorType.PASS_SHORT,
      IndicatorType.PASS_LONG,
      IndicatorType.RECEPTION,
      IndicatorType.DELIVERY,
      IndicatorType.SCORING,
      IndicatorType.SCORING_EFFORT,
    ],
    indicatorsForColor: [
      IndicatorType.SEASON_AGE,
      IndicatorType.SEASON_MINS,
      IndicatorType.CONTRACT_EXPIRY_YEAR,
      IndicatorType.SEASON_RATING,
      IndicatorType.MARKET_VALUE,
      IndicatorType.STABILITY,
      IndicatorType.DEFENCE,
      IndicatorType.OFFENCE,
      IndicatorType.CONTRIBUTION_DEFENCE,
      IndicatorType.CONTRIBUTION_OFFENCE,
      IndicatorType.CONTRIBUTION_PASS,
      IndicatorType.TACKLE_DEFENCE,
      IndicatorType.TACKLE_OFFENCE,
      IndicatorType.INTERCEPT_DEFENCE,
      IndicatorType.INTERCEPT_OFFENCE,
      IndicatorType.BLOCKED,
      IndicatorType.SAVE,
      IndicatorType.AERIAL_OFFENCE,
      IndicatorType.AERIAL_DEFENCE,
      IndicatorType.TAKE_ON,
      IndicatorType.CROSSING,
      IndicatorType.PASS_SHORT,
      IndicatorType.PASS_LONG,
      IndicatorType.RECEPTION,
      IndicatorType.DELIVERY,
      IndicatorType.SCORING,
      IndicatorType.SCORING_EFFORT,
    ],
  },
};

export default createReducer(initialState, {
  [CHANGE_OVERVIEW_RANKINGS_SETTINGS_RANK]: (state, action) => {
    Cookies.set('overview_rankBy', JSON.stringify(action.payload));
    state.settings.rankBy = action.payload;
  },
  [CHANGE_OVERVIEW_RANKINGS_SETTINGS_COLOR]: (state, action) => {
    Cookies.set('overview_colorBy', JSON.stringify(action.payload));
    state.settings.colorBy = action.payload;
  },
  [SET_FILTER_PRESET]: (state, action) => {
    const preset = JSON.parse(action.payload.preset);

    if (preset.rankingsSettings) {
      state.settings.rankBy = preset.rankingsSettings.rankBy;
      state.settings.colorBy = preset.rankingsSettings.colorBy;
    }
  },
});
