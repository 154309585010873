import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';

import './account.scss';

import Icon from './ui/svg-icon';

import { getUsers } from '../actions/users';
import { getRoles } from '../actions/roles';
import { getGroups } from '../actions/groups';
import { getUserTypes } from '../actions/user-types';

const getWindowHeight = () =>
  document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.innerHeight;

class Account extends Component {
  static propTypes = {
    getRoles: PropTypes.func,
    getUsers: PropTypes.func,
    getGroups: PropTypes.func,
    getUserTypes: PropTypes.func,
    auth: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  };

  constructor(props) {
    super(props);

    this.state = {
      height: getWindowHeight(),
      tabs: [
        {
          name: 'General',
          link: '/account/general',
        },
        {
          name: 'Users',
          link: '/account/users',
        },
        {
          name: 'Groups',
          link: '/account/groups',
        },
      ],
    };

    this.renderTabs = this.renderTabs.bind(this);
    this.renderNav = this.renderNav.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentWillMount() {
    if (this.props.auth.user.role.name === 'Admin') {
      this.props.getRoles();
      this.props.getUsers();
      this.props.getGroups();
      this.props.getUserTypes();
    }
  }

  componentDidMount() {
    this.forceUpdate();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({ height: getWindowHeight() });
  }

  renderTabs() {
    return this.state.tabs.map((tab, i) => (
      <Link
        key={i}
        className="col-2 callAction"
        activeClassName="active"
        to={tab.link}
      >
        {tab.name}
      </Link>
    ));
  }

  renderNav() {
    if (this.props.auth.user.role.name !== 'Admin') return null;

    return (
      <div className="nav-container">
        <div className="container">
          <div className="nav row">{this.renderTabs()}</div>
        </div>
      </div>
    );
  }

  render() {
    const { height } = this.state;
    const mode = localStorage.getItem('overview-mode');

    const returnTo = localStorage.getItem('returnTo');
    const closeLink = returnTo === 'welcome' ? '/home' : `/overview/${mode}`;

    return (
      <div className="account">
        <Scrollbars style={{ height }} autoHide>
          <div className="header-container">
            <div className="container">
              <div className="row">
                <div className="col-4">
                  <div className="account-logo">
                    <Icon name="settings" />

                    <span>Account</span>
                  </div>
                </div>
                <div className="col-4">&nbsp;</div>
                <div className="col-4">
                  <div className="btn pull-right">
                    <Link to={closeLink} className="icon-close" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.renderNav()}

          <div className="content-container">
            <div className="container">{this.props.children}</div>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
  }),
  { getGroups, getUserTypes, getRoles, getUsers },
)(Account);
