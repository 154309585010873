import React, { Component } from 'react';
import _ from 'lodash';
import DebounceInput from 'react-debounce-input';
import { List } from 'react-virtualized';
import PropTypes from 'prop-types';

import Image from '../../../ui/image';
import DateRangeDropdown from '../../../ui/date-range-dropdown';

import { removeDiacritics } from '../../../../helper';
import BtnLoader from '../../../ui/btn-loader';
import { database } from '../../../../constants/database';

export default class CompareAdd extends Component {
  static propTypes = {
    addItem: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      itemActive: null,
      leagueActive: null,
      seasonActive: [],
      formationActive: null,
      positionActive: null,
      leaguesFiltered: [],
      seasonsFiltered: [],
      formationsFiltered: [],
      positionsFiltered: [],
      combinations: [],
      showItems: false,
      showLeagues: false,
      showSeasons: false,
      showFormations: false,
      showPositions: false,
      searchString: '',
      loading: false,
      playerSeasonsData: null,
    };

    this.addItem = this.addItem.bind(this);
    this.showList = this.showList.bind(this);
    this.updateSeason = this.updateSeason.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.compareLastSeasons = this.compareLastSeasons.bind(this);
  }

  componentDidMount() {
    const {
      selected_id,
      selected_league,
      selected_season,
      selected_formation,
      selected_position,
      playersListAll,
      teamsListAll,
      mode,
    } = this.props;

    if (selected_id) {
      let items;

      if (mode === 'team') items = teamsListAll.length > 0 ? teamsListAll : [];
      if (mode === 'player')
        items = playersListAll.length > 0 ? playersListAll : [];

      const item_ = items.filter(item => +item.value === +selected_id);
      const item = item_.length > 0 ? item_[0] : [];

      this.updateItem(
        item,
        selected_league,
        selected_season,
        selected_formation,
        selected_position,
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.count != nextProps.count) {
      this.setState({
        itemActive: null,
        leagueActive: null,
        seasonActive: [],
        formationActive: null,
        positionActive: null,
        leaguesFiltered: [],
        seasonsFiltered: [],
        formationsFiltered: [],
        positionsFiltered: [],
        combinations: [],
        showItems: false,
        showLeagues: false,
        showSeasons: false,
        showFormations: false,
        showPositions: false,
        searchString: '',
        playerSeasonsData: null,
      });
    }

    if (this.props.selected_id != nextProps.selected_id) {
      this.setState({
        itemActive: nextProps.selected_id,
        leagueActive: nextProps.selected_league,
        seasonActive: nextProps.selected_season,
        leaguesFiltered: [],
        seasonsFiltered: [],
        combinations: [],
        showItems: false,
        showLeagues: false,
        showSeasons: false,
        searchString: '',
        playerSeasonsData: null,
      });
    }
  }

  addItem() {
    const {
      itemActive,
      leagueActive,
      seasonActive,
      formationActive,
      positionActive,
    } = this.state;
    const { count, mode } = this.props;

    if (!itemActive) {
      alert('Please select a player/team first');
      return false;
    }

    const id = itemActive.value;
    const league = _.get(leagueActive, 'value', null);
    const season = seasonActive;
    const formation = _.get(formationActive, 'value', null);
    const position = _.get(positionActive, 'value', null);

    this.setState({
      loading: true,
    });

    const request = this.props.addItem({
      id,
      league,
      season,
      formation,
      position,
      num: count,
      mode,
    });

    request.then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  updateItem(
    itemActive,
    selected_league_,
    selected_season_,
    selected_formation_,
    selected_position_,
  ) {
    const { leagues, formations, positions, mode, loadPlayerDataField } =
      this.props;
    const seasons = _.keyBy(this.props.seasons, 'id');

    let { data, date_data } = itemActive;

    const updateFunction = (data, date_data, standard_league) => {
      const seasonCombinations = _.chain(data)
        .split(',')
        .map(slp => {
          const slpA = slp.split(':');
          const result = {};
          if (slp[0]) result.season_id = slpA[0];
          if (slp[1]) result.league_id = +slpA[1];
          if (slp[2] && mode === 'player') result.position_id = +slpA[2];
          if (slp[2] && mode === 'team') result.formation_id = +slpA[2];
          return result;
        })
        .filter(slp => _.size(slp))
        .orderBy('season_id', 'desc')
        .value();

      const dateCombinations = _.chain(date_data)
        .split(',')
        .map(slp => {
          const slpA = slp.split(':');
          const result = {};
          if (slp[0]) result.season_id = slpA[0];
          if (slp[1]) result.league_id = +slpA[1];
          if (slp[2] && mode === 'player') result.position_id = +slpA[2];
          if (slp[2] && mode === 'team') result.formation_id = +slpA[2];
          return result;
        })
        .filter(slp => _.size(slp))
        .value();

      const combinations = [...seasonCombinations, ...dateCombinations];
      let standardCombination = 0;

      if (standard_league) {
        const stand_comb = standard_league.split(',')[0];
        const stand_season = stand_comb.split(':')[1];
        const stand_league = stand_comb.split(':')[0];
        standardCombination = combinations.findIndex(
          v => +v.season_id === +stand_season && +v.league_id === +stand_league,
        );
        if (standardCombination < 0) standardCombination = 0;
      }

      const selected = {
        league_id:
          selected_league_ || combinations[standardCombination].league_id,
        season_id: selected_season_ || [
          +combinations[standardCombination].season_id,
        ],
        formation_id: selected_formation_ || 0,
        position_id: selected_position_ || 0,
      };

      const seasonsFiltered = _.chain(combinations)
        .map(slf => slf.season_id)
        .filter(season => season && season.indexOf('-') === -1)
        .uniq()
        .map(season_id => ({
          value: +season_id,
          label: _.get(seasons, `${season_id}.name`, ''),
        }))
        .sortBy('value')
        .value();

      const leaguesFiltered = _.chain(combinations)
        .filter(slf => {
          if (
            selected.season_id.length === 1 &&
            +slf.season_id === +selected.season_id[0]
          ) {
            return true;
          }
          if (selected.season_id.length === 2) {
            if (
              slf.season_id.indexOf('-') === -1 &&
              +slf.season_id >=
                +selected.season_id[0].substr(
                  0,
                  selected.season_id[0].indexOf('-'),
                ) &&
              +slf.season_id <=
                +selected.season_id[1].substr(
                  0,
                  selected.season_id[1].indexOf('-'),
                )
            ) {
              return true;
            }
            if (
              slf.season_id.indexOf('-') > -1 &&
              new Date(`${slf.season_id}-31`) >=
                new Date(selected.season_id[0]) &&
              new Date(`${slf.season_id}-01`) <= new Date(selected.season_id[1])
            ) {
              return true;
            }
          }

          return false;
        })
        .map(slf => slf.league_id)
        .filter(league_id => league_id)
        .uniq()
        .map(league_id => ({
          value: league_id,
          label: _.get(leagues, `${league_id}.name`, ''),
        }))
        .sortBy('value')
        .value();

      const formationsFiltered =
        mode === 'team'
          ? _.chain(combinations)
              .filter(slf => {
                if (+slf.league_id !== +leaguesFiltered[0].value) {
                  return false;
                }
                if (
                  selected.season_id.length === 1 &&
                  +slf.season_id === +selected.season_id[0]
                ) {
                  return true;
                }
                if (selected.season_id.length === 2) {
                  if (
                    slf.season_id.indexOf('-') === -1 &&
                    +slf.season_id >=
                      +selected.season_id[0].substr(
                        0,
                        selected.season_id[0].indexOf('-'),
                      ) &&
                    +slf.season_id <=
                      +selected.season_id[1].substr(
                        0,
                        selected.season_id[1].indexOf('-'),
                      )
                  ) {
                    return true;
                  }
                  if (
                    slf.season_id.indexOf('-') > -1 &&
                    new Date(`${slf.season_id}-31`) >=
                      new Date(selected.season_id[0]) &&
                    new Date(`${slf.season_id}-01`) <=
                      new Date(selected.season_id[1])
                  ) {
                    return true;
                  }
                }

                return false;
              })
              .map(slf => slf.formation_id)
              .uniq()
              .map(formation_id => ({
                value: formation_id,
                label: _.get(formations, `${formation_id}.name`, ''),
              }))
              .sortBy('value')
              .unshift({
                value: 0,
                label: _.get(formations, `${0}.name`, ''),
              })
              .value()
          : [];

      const positionsOptions =
        mode === 'player'
          ? _.chain(combinations)
              .filter(slf => {
                if (+slf.league_id !== +selected.league_id) {
                  return false;
                }
                if (
                  selected.season_id.length === 1 &&
                  +slf.season_id === +selected.season_id[0]
                ) {
                  return true;
                }
                if (selected.season_id.length === 2) {
                  if (
                    slf.season_id.indexOf('-') === -1 &&
                    +slf.season_id >=
                      +selected.season_id[0].substr(
                        0,
                        selected.season_id[0].indexOf('-'),
                      ) &&
                    +slf.season_id <=
                      +selected.season_id[1].substr(
                        0,
                        selected.season_id[1].indexOf('-'),
                      )
                  ) {
                    return true;
                  }
                  if (
                    slf.season_id.indexOf('-') > -1 &&
                    new Date(`${slf.season_id}-31`) >=
                      new Date(selected.season_id[0]) &&
                    new Date(`${slf.season_id}-01`) <=
                      new Date(selected.season_id[1])
                  ) {
                    return true;
                  }
                }

                return false;
              })
              .map(slf => slf.position_id)
              .uniq()
              .map(position_id => ({
                value: position_id,
                label: _.get(positions, `${position_id}.description`, ''),
                upper_id: _.get(
                  positions,
                  `${position_id}.upper_position_id`,
                  '',
                ),
                className: 'position_detail',
              }))
              .sortBy('value')
              .value()
          : [];

      if (positionsOptions.length > 1) {
        positionsOptions.unshift({
          value: 0,
          label: _.get(positions, `${0}.description`, ''),
        });
      } else if (!selected.position_id) {
        selected.position_id = _.get(positionsOptions, `[0].value`, null);
      }

      const positionGroups = _.chain(positionsOptions)
        .map(p => p.upper_id)
        .uniq()
        .value();

      let positionsFiltered =
        positionsOptions.length > 1
          ? [
              {
                value: 0,
                label: 'Overall',
                className: 'position_group',
              },
            ]
          : [];

      if (positionsOptions.length > 1) {
        positionGroups.map(pg => {
          positionsFiltered.push({
            value: pg,
            label: _.get(positions, `${pg}.description`, ''),
            className: 'position_group',
          });
          positionsOptions
            .filter(p => +p.upper_id === +pg)
            .map(p => {
              positionsFiltered.push(p);
            });

          return positionsOptions;
        });
      } else {
        positionsFiltered.push(positionsOptions[0]);
      }

      positionsFiltered = positionsFiltered.filter(
        p => p && p.value !== undefined,
      );

      const leagueActive = {
        value: selected.league_id,
        label: _.get(leagues, `${selected.league_id}.name`, ''),
      };
      const seasonActive = selected.season_id;
      const formationActive = {
        value: selected.formation_id,
        label: _.get(formations, `${selected.formation_id}.name`, ''),
      };
      const positionActive = {
        value: selected.position_id,
        label: _.get(positions, `${selected.position_id}.description`, ''),
      };

      this.setState({
        itemActive,
        leagueActive,
        seasonActive,
        formationActive,
        positionActive,
        leaguesFiltered,
        seasonsFiltered,
        formationsFiltered,
        positionsFiltered,
        combinations,
        showItems: false,
      });
    };

    if (mode === 'player') {
      loadPlayerDataField(itemActive.player_id).then(result => {
        data = result.payload.data.data.data;
        date_data = result.payload.data.data.date_data;
        const { standard_league } = result.payload.data.data;
        this.setState({ playerSeasonsData: result.payload.data.data.data });
        updateFunction(data, date_data, standard_league);
      });
    } else {
      updateFunction(data, date_data);
    }
  }

  updateLeague(leagueActive) {
    const { formations, positions, mode } = this.props;
    const { combinations, seasonsFiltered, positionActive, seasonActive } =
      this.state;

    const formationsFiltered =
      mode === 'team'
        ? _.chain(combinations)
            .filter(slf => {
              if (+slf.league_id !== +leagueActive.value) {
                return false;
              }
              if (
                seasonsFiltered.length === 1 &&
                +slf.season_id === +seasonsFiltered[0]
              ) {
                return true;
              }
              if (seasonsFiltered.length === 2) {
                if (
                  slf.season_id.indexOf('-') === -1 &&
                  +slf.season_id >=
                    +seasonsFiltered[0].substr(
                      0,
                      seasonsFiltered[0].indexOf('-'),
                    ) &&
                  +slf.season_id <=
                    +seasonsFiltered[1].substr(
                      0,
                      seasonsFiltered[1].indexOf('-'),
                    )
                ) {
                  return true;
                }
                if (
                  slf.season_id.indexOf('-') > -1 &&
                  new Date(`${slf.season_id}-31`) >=
                    new Date(seasonsFiltered[0]) &&
                  new Date(`${slf.season_id}-01`) <=
                    new Date(seasonsFiltered[1])
                ) {
                  return true;
                }
              }

              return false;
            })
            .map(slf => slf.formation_id)
            .uniq()
            .map(formation_id => ({
              value: formation_id,
              label: _.get(formations, `${formation_id}.name`, ''),
            }))
            .sortBy('value')
            .unshift({
              value: 0,
              label: _.get(formations, `${0}.name`, ''),
            })
            .value()
        : [];

    const positionsOptions =
      mode === 'player'
        ? _.chain(combinations)
            .filter(slf => {
              if (+slf.league_id !== +leagueActive.value) {
                return false;
              }
              if (
                seasonActive.length === 1 &&
                +slf.season_id === +seasonActive[0]
              ) {
                return true;
              }
              if (seasonActive.length === 2) {
                if (
                  slf.season_id.indexOf('-') === -1 &&
                  +slf.season_id >=
                    seasonActive[0].substr(0, seasonActive[0].indexOf('-')) &&
                  +slf.season_id <=
                    +seasonActive[1].substr(0, seasonActive[1].indexOf('-'))
                ) {
                  return true;
                }
                if (
                  slf.season_id.indexOf('-') > -1 &&
                  new Date(`${slf.season_id}-31`) >=
                    new Date(seasonActive[0]) &&
                  new Date(`${slf.season_id}-01`) <= new Date(seasonActive[1])
                ) {
                  return true;
                }
              }

              return false;
            })
            .map(slf => slf.position_id)
            .uniq()
            .map(position_id => ({
              value: position_id,
              label: _.get(positions, `${position_id}.description`, ''),
              upper_id: _.get(
                positions,
                `${position_id}.upper_position_id`,
                '',
              ),
              className: 'position_detail',
            }))
            .sortBy('value')
            .value()
        : [];

    let pActive = { value: 0, label: _.get(positions, '[0].description', '') };
    if (positionsOptions.length > 1) {
      positionsOptions.unshift({
        value: 0,
        label: _.get(positions, `${0}.description`, ''),
      });
    } else if (!positionActive.value) {
      pActive = positionsOptions[0];
    }

    const positionGroups = _.chain(positionsOptions)
      .map(p => p.upper_id)
      .uniq()
      .value();

    let positionsFiltered =
      positionsOptions.length > 1
        ? [
            {
              value: 0,
              label: 'Overall',
              className: 'position_group',
            },
          ]
        : [];

    if (positionsOptions.length > 1) {
      positionGroups.map(pg => {
        positionsFiltered.push({
          value: pg,
          label: _.get(positions, `${pg}.description`, ''),
          className: 'position_group',
        });
        positionsOptions
          .filter(p => +p.upper_id === +pg)
          .map(p => {
            positionsFiltered.push(p);
          });

        return positionsOptions;
      });
    } else {
      positionsFiltered.push(positionsOptions[0]);
    }

    positionsFiltered = positionsFiltered.filter(
      p => p && p.value !== undefined,
    );

    if (positionsFiltered.length) {
      pActive = positionsFiltered[0];
    }

    this.setState({
      leagueActive,
      formationsFiltered,
      positionsFiltered,
      formationActive: { value: 0, label: _.get(formations, '[0].name', '') },
      positionActive: pActive,
      showLeagues: false,
    });
  }

  updateSeason(seasonActive) {
    const { combinations, positionActive } = this.state;

    const { leagues, formations, positions, mode } = this.props;

    const leaguesFiltered = _.chain(combinations)
      .filter(slf => {
        if (seasonActive.length === 1 && +slf.season_id === +seasonActive[0]) {
          return true;
        }
        if (seasonActive.length === 2) {
          if (
            slf.season_id.indexOf('-') === -1 &&
            +slf.season_id >=
              +seasonActive[0].substr(0, seasonActive[0].indexOf('-')) &&
            +slf.season_id <=
              +seasonActive[1].substr(0, seasonActive[1].indexOf('-'))
          ) {
            return true;
          }
          if (
            slf.season_id.indexOf('-') > -1 &&
            new Date(`${slf.season_id}-31`) >= new Date(seasonActive[0]) &&
            new Date(`${slf.season_id}-01`) <= new Date(seasonActive[1])
          ) {
            return true;
          }
        }

        return false;
      })
      .map(slf => slf.league_id)
      .uniq()
      .map(league_id => ({
        value: league_id,
        label: leagues[league_id].name,
      }))
      .sortBy('value')
      .value();

    const formationsFiltered =
      mode === 'team'
        ? _.chain(combinations)
            .filter(slf => {
              if (!leaguesFiltered[0]) return false;

              if (+slf.league_id !== +leaguesFiltered[0].value) {
                return false;
              }
              if (
                seasonActive.length === 1 &&
                +slf.season_id === +seasonActive[0]
              ) {
                return true;
              }
              if (seasonActive.length === 2) {
                if (
                  typeof slf.season_id === 'number' &&
                  +slf.season_id >=
                    +seasonActive[0].substr(0, seasonActive[0].indexOf('-')) &&
                  +slf.season_id <=
                    +seasonActive[1].substr(0, seasonActive[1].indexOf('-'))
                ) {
                  return true;
                }
                if (
                  slf.season_id.indexOf('-') > -1 &&
                  new Date(`${slf.season_id}-31`) >=
                    new Date(seasonActive[0]) &&
                  new Date(`${slf.season_id}-01`) <= new Date(seasonActive[1])
                ) {
                  return true;
                }
              }

              return false;
            })
            .map(slf => slf.formation_id)
            .uniq()
            .map(formation_id => ({
              value: formation_id,
              label: _.get(formations, `${formation_id}.name`),
            }))
            .sortBy('value')
            .value()
        : [];

    const positionsOptions =
      mode === 'player'
        ? _.chain(combinations)
            .filter(slf => {
              if (+slf.league_id !== +leaguesFiltered[0].value) {
                return false;
              }
              if (
                seasonActive.length === 1 &&
                +slf.season_id === +seasonActive[0]
              ) {
                return true;
              }
              if (seasonActive.length === 2) {
                if (
                  slf.season_id.indexOf('-') === -1 &&
                  +slf.season_id >=
                    +seasonActive[0].substr(0, seasonActive[0].indexOf('-')) &&
                  +slf.season_id <=
                    +seasonActive[1].substr(0, seasonActive[1].indexOf('-'))
                ) {
                  return true;
                }
                if (
                  slf.season_id.indexOf('-') > -1 &&
                  new Date(`${slf.season_id}-31`) >=
                    new Date(seasonActive[0]) &&
                  new Date(`${slf.season_id}-01`) <= new Date(seasonActive[1])
                ) {
                  return true;
                }
              }

              return false;
            })
            .map(slf => slf.position_id)
            .uniq()
            .map(position_id => ({
              value: position_id,
              label: _.get(positions, `${position_id}.description`, ''),
              upper_id: _.get(
                positions,
                `${position_id}.upper_position_id`,
                '',
              ),
              className: 'position_detail',
            }))
            .filter(p => p.value !== undefined)
            .sortBy('value')
            .value()
        : [];

    let pActive = { value: 0, label: _.get(positions, '[0].description', '') };

    if (positionsOptions.length > 1) {
      positionsOptions.unshift({
        value: 0,
        label: _.get(positions, `${0}.description`, ''),
      });
    }

    const positionGroups = _.chain(positionsOptions)
      .map(p => p.upper_id)
      .uniq()
      .value();

    let positionsFiltered =
      positionsOptions.length > 1
        ? [
            {
              value: 0,
              label: 'Overall',
              className: 'position_group',
            },
          ]
        : [];

    if (positionsOptions.length > 1) {
      positionGroups.map(pg => {
        positionsFiltered.push({
          value: pg,
          label: _.get(positions, `${pg}.description`, ''),
          className: 'position_group',
        });
        positionsOptions
          .filter(p => +p.upper_id === +pg)
          .map(p => {
            positionsFiltered.push(p);
          });

        return positionsOptions;
      });
    } else {
      positionsFiltered.push(positionsOptions[0]);
    }

    positionsFiltered = positionsFiltered.filter(
      p => p && p.value !== undefined,
    );

    if (positionsFiltered.length) {
      pActive = positionsFiltered[0];
    }

    const leagueActive = leaguesFiltered.find(
      l => this.state.leagueActive && l.value === this.state.leagueActive.value,
    )
      ? this.state.leagueActive
      : leaguesFiltered[0];

    this.setState({
      seasonActive,
      leagueActive,
      formationActive: { value: 0, label: _.get(formations, '[0].name', '') },
      positionActive: pActive,
      leaguesFiltered,
      formationsFiltered,
      positionsFiltered,
      showSeasons: false,
    });
  }

  updateFormation(formationActive) {
    this.setState({
      formationActive,
      showFormations: false,
    });
  }

  updatePosition(positionActive) {
    this.setState({
      positionActive,
      showPositions: false,
    });
  }

  handleChange(event) {
    this.setState({
      searchString: event.target.value,
      showItems: true,
    });
  }

  showList(event) {
    event && event.stopPropagation();

    if (this.state.showItems) document.getElementById('searchinput').blur();
    this.setState({
      showItems: !this.state.showItems,
      showLeagues: false,
      showSeasons: false,
      showPositions: false,
    });
  }

  compareLastSeasons() {
    if (!window.confirm('This will clear other selected players. Proceed?')) {
      return;
    }

    const {
      itemActive,
      leagueActive,
      formationActive,
      positionActive,
      playerSeasonsData,
      seasonActive,
    } = this.state;
    const { mode, addItem, removeItem } = this.props;

    if (!itemActive) {
      return alert('Please select player');
    }

    if (!playerSeasonsData) {
      return alert('No data for this player');
    }

    this.setState({
      loading: true,
    });

    let dataArray = playerSeasonsData
      .split(',')
      .map(v => {
        let item = v.split(':');
        return { season_id: item[0], league_id: item[1], position_id: item[2] };
      })
      .filter(
        v => v.season_id <= seasonActive && v.league_id == leagueActive.value,
      );

    dataArray = _.uniqBy(dataArray, v => v.season_id);
    dataArray = _.sortBy(dataArray, ['season_id']);
    dataArray = _.reverse(dataArray);
    const id = itemActive.value;

    if (!dataArray.length) {
      return alert('No data for this player / league');
    }

    const requests = [];
    dataArray.slice(0, 4).map((value, i) => {
      const season = value.season_id;
      removeItem(i);
      requests.push(
        addItem({
          id,
          league: value.league_id,
          season: [value.season_id],
          formation: 0,
          position: 0,
          num: i,
          mode,
        }),
      );
    });

    Promise.all(requests).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    const { playersListAll, teamsListAll, mode, data, count, user } =
      this.props;

    const {
      leaguesFiltered,
      seasonsFiltered,
      formationsFiltered,
      positionsFiltered,
      itemActive,
      leagueActive,
      seasonActive,
      formationActive,
      positionActive,
      showItems,
      loading,
      playerSeasonsData,
    } = this.state;

    const leagues = leaguesFiltered.length > 0 ? leaguesFiltered : [];
    const seasons = seasonsFiltered.length > 0 ? seasonsFiltered : [];
    const formations = formationsFiltered.length > 0 ? formationsFiltered : [];
    const positions = positionsFiltered.length > 0 ? positionsFiltered : [];

    const rowHeight = 35;
    const listHeight = 500;
    const listWidth = 230;

    let items;
    let modePlaceholder;

    switch (mode) {
      case 'team':
        items = teamsListAll.length > 0 ? teamsListAll : [];
        modePlaceholder = 'Choose team';
        break;
      default:
        items = playersListAll.length > 0 ? playersListAll : [];
        modePlaceholder = 'Choose player';

        if (
          mode === 'player' &&
          !_.isEmpty(data[0].data) &&
          data[0].data.position_id_first === 10
        ) {
          modePlaceholder = 'Choose goalkeeper';
          items = items.filter(player => +player.position_id === 1);
        }
    }

    // search
    let searchString = this.state.searchString.toLowerCase().trim();
    searchString = removeDiacritics(searchString);
    if (searchString.length > 0) {
      // We are searching. Filter the results.

      items = items.filter(l => {
        const name = l.searchString;
        let s = -1;
        const s_ = searchString.split(' ');
        if (s_.length > 1)
          s = name.indexOf(s_[0]) >= 0 && name.indexOf(s_[1]) >= 0;
        else s = name.indexOf(searchString) >= 0;
        return s;
      });
    }

    const seasonsSorted = _.orderBy(seasonsFiltered, 'value', 'desc');

    return (
      <div className="content__item_add_content">
        <div className="content__items">
          <div
            className="itemsHeader"
            onClick={this.showList}
            id="searchheader"
          >
            <div
              className={`icon-dropdown ${this.state.showItems ? 'open' : ''}`}
            />
            <DebounceInput
              onClick={this.showList}
              type="text"
              debounceTimeout={300}
              value={
                showItems
                  ? this.state.searchString
                  : itemActive
                  ? itemActive.label
                  : this.state.searchString.length > 0
                  ? this.state.searchString
                  : ''
              }
              id="searchinput"
              onChange={this.handleChange}
              placeholder={modePlaceholder}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
            />
          </div>

          <div
            className={`compare__list itemsList ${
              !this.state.showItems ? 'hide' : 'show'
            }`}
          >
            <List
              key={count}
              width={listWidth}
              height={
                items.length * 35 > listHeight ? listHeight : items.length * 35
              }
              rowCount={items.length}
              rowHeight={rowHeight}
              rowRenderer={({ index, style }) => (
                <div
                  key={index}
                  style={style}
                  className="compare__list_item compare__list_item"
                  onClick={() => this.updateItem(items[index])}
                >
                  {mode === 'player' ? (
                    <Image
                      src={`${database.assets}teams/${items[index].team_id}.jpg`}
                      aspectRatio={0.769}
                    />
                  ) : null}
                  {items[index].label.length > 26
                    ? `${items[index].label.substring(0, 26)}.`
                    : items[index].label}
                </div>
              )}
            />
          </div>
        </div>

        <div className={`content__seasons ${!itemActive ? 'deactivate' : ''}`}>
          <DateRangeDropdown
            options={seasons}
            value={seasonActive}
            onChange={this.updateSeason}
            dateSlider={mode === 'player'}
          />
        </div>

        <div className={`content__leagues ${!itemActive ? 'deactivate' : ''}`}>
          <div
            className="leaguesHeader"
            onClick={() =>
              this.setState({
                showLeagues: !this.state.showLeagues,
                showItems: false,
                showSeasons: false,
                showFormations: false,
                showPositions: false,
              })
            }
          >
            <div
              className={`icon-dropdown ${
                this.state.showLeagues ? 'open' : ''
              }`}
            />
            <span>{leagueActive ? leagueActive.label : 'Choose league'}</span>
          </div>
          <div
            className={`compare__list leaguesList ${
              !this.state.showLeagues ? 'hide' : 'show'
            }`}
          >
            <List
              width={listWidth}
              height={leagues.length * rowHeight + 3}
              rowCount={leagues.length}
              rowHeight={rowHeight}
              rowRenderer={({ index, style }) => {
                return (
                  <div
                    key={index}
                    style={style}
                    className="compare__list_item compare__list_league"
                    onClick={() => this.updateLeague(leagues[index])}
                  >
                    {leagues[index].label}
                  </div>
                );
              }}
            />
          </div>
        </div>

        {mode === 'team' ? (
          <div
            className={`content__formations ${!itemActive ? 'deactivate' : ''}`}
          >
            <div
              className="formationsHeader"
              onClick={() =>
                this.setState({
                  showFormations: !this.state.showFormations,
                  showLeagues: false,
                  showItems: false,
                  showSeasons: false,
                  showPosition: false,
                })
              }
            >
              <div
                className={`icon-dropdown ${
                  this.state.showFormations ? 'open' : ''
                }`}
              />
              <span>
                {formationActive ? formationActive.label : 'Choose formation'}
              </span>
            </div>
            <div
              className={`compare__list formationsList ${
                !this.state.showFormations ? 'hide' : 'show'
              }`}
            >
              <List
                width={listWidth}
                height={formations.length * rowHeight + 3}
                rowCount={formations.length}
                rowHeight={rowHeight}
                rowRenderer={({ index, style }) => {
                  return (
                    <div
                      key={index}
                      style={style}
                      className="compare__list_item compare__list_formation"
                      onClick={() => this.updateFormation(formations[index])}
                    >
                      {formations[index].label}
                    </div>
                  );
                }}
              />
            </div>
          </div>
        ) : null}

        {mode === 'player' ? (
          <div
            className={`content__positions ${!itemActive ? 'deactivate' : ''}`}
          >
            <div
              className="positionsHeader"
              onClick={() =>
                this.setState({
                  showPositions: !this.state.showPositions,
                  showLeagues: false,
                  showItems: false,
                  showSeasons: false,
                  showPosition: false,
                })
              }
            >
              <div
                className={`icon-dropdown ${
                  this.state.showPositions ? 'open' : ''
                }`}
              />
              <span>
                {positionActive ? positionActive.label : 'Choose position'}
              </span>
            </div>
            <div
              className={`compare__list positionsList ${
                !this.state.showPositions ? 'hide' : 'show'
              }`}
            >
              <List
                width={listWidth}
                height={positions.length * rowHeight + 3}
                rowCount={positions.length}
                rowHeight={rowHeight}
                rowRenderer={({ index, style }) => {
                  return (
                    <div
                      key={index}
                      style={style}
                      className={`compare__list_item compare__list_position ${positions[index].className}`}
                      onClick={() => this.updatePosition(positions[index])}
                    >
                      {positions[index].label}
                    </div>
                  );
                }}
              />
            </div>
          </div>
        ) : null}

        <div
          className={`content__item_add_actionbar ${
            loading ? 'is-loading' : ''
          }`}
        >
          {mode === 'player' ? (
            <div
              className="btn callAction last_seasons"
              onClick={this.compareLastSeasons}
            >
              Compare <br /> past seasons
              <BtnLoader />
            </div>
          ) : null}
          <div className="btn callAction" onClick={this.addItem}>
            Add
            <BtnLoader />
          </div>
        </div>
      </div>
    );
  }
}
