import axios from 'axios';

import {
  ADD_FAVORITE_LEAGUE,
  FETCH_FAVORITE_LEAGUES,
  REMOVE_FAVORITE_LEAGUE,
} from '../constants/index';
import eventTracker from '../helper/event-tracker';

export const fetchFavoriteLeagues = () => {
  const request = axios.get('/favorite-leagues/');

  return {
    type: FETCH_FAVORITE_LEAGUES,
    payload: request,
  };
};

export const addFavoriteLeague = league_id => {
  axios.post('/favorite-leagues/', { league_id });

  eventTracker.trackEvent('Overview Filter', 'Add favorite league');
  return {
    type: ADD_FAVORITE_LEAGUE,
    payload: league_id,
  };
};

export const deleteFavoriteLeague = league_id => {
  axios.delete(`/favorite-leagues/${league_id}`);

  eventTracker.trackEvent('Overview Filter', 'Delete favorite league');
  return {
    type: REMOVE_FAVORITE_LEAGUE,
    payload: league_id,
  };
};
