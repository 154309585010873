import axios from 'axios';
//  defining actions, they do not dispatch to the store
import * as types from '../constants';
import eventTracker from '../helper/event-tracker';

import { database } from '../constants/database';

export function loadDataDetails(
  req_id,
  req_league,
  req_season,
  req_formation,
  req_position,
  mode,
  tab,
) {
  let query = '';
  if (mode === 'team' && req_formation) {
    query += `?formation=${req_formation}`;
  }
  if (mode === 'player' && req_position) {
    query += `?position=${req_position}`;
  }

  const request = Promise.all([
    axios.get(
      `${
        database[mode === 'player' ? 'dbDetail' : 'dbTeam']
      }details/${req_id}/${req_season.join('/')}/${req_league}${query}`,
    ),
    Promise.resolve(req_id),
    Promise.resolve(req_league),
    Promise.resolve(req_season),
    Promise.resolve(req_formation),
    Promise.resolve(req_position),
    Promise.resolve(mode),
  ]);

  return {
    type: types.LOAD_DATA_DETAILS,
    payload: request,
  };
}

export function loadMatchDetails({ id, season, league, mode }) {
  const request = Promise.all([
    axios.get(`${database.dbMatch}${id}/${season}/${league}`),
    Promise.resolve(id),
    Promise.resolve(season),
    Promise.resolve(league),
    Promise.resolve(mode),
  ]);

  return {
    type: types.LOAD_MATCH_DETAILS,
    payload: request,
  };
}

export function changeSeason(_season_id) {
  eventTracker.trackEvent('Details page', 'Change season');
  return {
    type: types.CHANGE_SEASON,
    season_id: _season_id,
  };
}

export function changeFormation(formation_id) {
  eventTracker.trackEvent('Details page', 'Change formation');
  return {
    type: types.CHANGE_FORMATION,
    current_formation: formation_id,
  };
}

export function changePosition(position_id) {
  eventTracker.trackEvent('Details page', 'Change position');
  return {
    type: types.CHANGE_POSITION,
    current_position: position_id,
  };
}

export function changeLeague(_league_id) {
  eventTracker.trackEvent('Details page', 'Change league');
  return {
    type: types.CHANGE_LEAGUE,
    league_id: _league_id,
  };
}

export function changeLeagueComparisonMatchdays({
  range,
  season_id,
  league_id,
  formation_id,
  team_id,
}) {
  const request = axios.post(`${database.league_comparision}`, {
    min: range[0],
    max: range[1],
    season_id,
    league_id,
    formation_id,
    team_id,
  });

  eventTracker.trackEvent(
    'Details page',
    'Change matchdays in league comparison',
  );
  return {
    type: types.CHANGE_LEAGUE_COMPARISON_MATCHDAYS,
    payload: request,
  };
}

export function dataErrorModal(status, mode) {
  return function (dispatch) {
    dispatch({
      type: types.DATA_ERROR_MODAL,
      status,
      mode,
    });
  };
}
