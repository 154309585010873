//  defining actions, they do not dispatch to the store
import * as types from '../constants';
import eventTracker from '../helper/event-tracker';
import { ModeType } from '../types/Dict';
import { FlattenListItem, ListType } from '../reducers/overview/types';

export function updateFiltersPositionsList({ positions }) {
  return {
    positions,
    type: types.UPDATE_OVERVIEW_POSITIONS_FILTER,
  };
}

export function loadData({ ...params }) {
  return {
    ...params,
    type: types.LOAD_DATA_OVERVIEW,
  };
}

export function setDomesticLeagues(mode) {
  return {
    type: types.SET_DOMESTIC_LEAGUES,
    mode,
  };
}

export function changePositionSplitMode(mode) {
  return {
    type: types.CHANGE_POSITION_SPLIT_MODE,
    mode,
  };
}

export function lassoSubSelection(ids, mode) {
  eventTracker.trackEvent('Overview', 'Scatterplot Lasso');
  return {
    type: types.SELECTION_LASSO,
    ids,
    mode,
  };
}

export function resetFilters(mode) {
  eventTracker.trackEvent('Overview Filter', 'Reset filter');
  return {
    type: types.RESET_FILTERS,
    mode,
  };
}

export function sortTable(sortBy, sortDirection, sortedData, mode) {
  eventTracker.trackEvent('Overview', 'Sort table');
  return {
    type: types.SORT_TABLE,
    sortBy,
    sortDirection,
    sortedData,
    mode,
  };
}

export function changeSliderVal(name, val, mode) {
  eventTracker.trackEvent(
    'Overview Filter',
    'Change slider',
    `${mode}: {${name}}`,
  );

  return {
    type: types.CHANGE_SLIDER_VAL,
    name,
    val,
    mode,
  };
}

export function changeSliderHelperValue(name, val, mode) {
  eventTracker.trackEvent(
    'Overview Filter',
    'Change slider helper',
    `${mode}: {${name}:${val}`,
  );
  return {
    type: types.CHANGE_SLIDER_HELPER_VAL,
    name,
    val,
    mode,
  };
}

export function changeListHelperValue(name, val, mode) {
  if (mode === 'player' && name === 'formation_id' && val === true) {
    eventTracker.trackEvent(
      'Overview Filter',
      'Separate performance by formation',
    );
  }

  return {
    type: types.CHANGE_LIST_HELPER_VAL,
    name,
    val,
    mode,
  };
}

export function changeScatterplotLegend({
  xVal,
  yVal,
  rVal,
  cVal,
  sVal,
  xDomain,
  yDomain,
  rDomain,
  cDomain,
  mode,
}) {
  eventTracker.trackEvent('Overview', 'Change scatterplot legend', mode);
  return {
    type: types.CHANGE_SCATTERPLOT_LEGEND,
    xVal,
    yVal,
    rVal,
    cVal,
    sVal,
    xDomain,
    yDomain,
    rDomain,
    cDomain,
    mode,
  };
}

export function changeScatterplotDomain({
  xDomain,
  yDomain,
  rDomain,
  cDomain,
  sDomain,
  mode,
}) {
  eventTracker.trackEvent('Overview', 'Change scatterplot domain', mode);
  return {
    type: types.CHANGE_SCATTERPLOT_DOMAIN,
    xDomain,
    yDomain,
    rDomain,
    cDomain,
    sDomain,
    mode,
  };
}

export function changeLayout(layouts) {
  return {
    ...layouts,
    type: types.CHANGE_SCATTERPLOT_WIDTH,
  };
}

/*export function toggleFilterCollapse({ keyName, typeName, mode }) {
  return {
    type: types.TOGGLE_FILTER_COLLAPSE,
    keyName,
    typeName,
    mode,
  };
}*/

export function toggleFilterSwitcher({ value, switcherName, mode }) {
  return {
    type: types.TOGGLE_FILTER_SWITCHER,
    switcherName,
    value,
    mode,
  };
}

export function changeMaxOfSliderFilter({ name, max }) {
  return {
    type: types.CHANGE_MAX_OF_SLIDER_FILTER,
    name,
    max: parseInt(max, 10),
  };
}

export function changeSliderMinMax({ name, min, max }) {
  return {
    type: types.CHANGE_SLIDER_MIN_MAX,
    name,
    min,
    max,
  };
}

export function setActivePointData({ mode, data, table }) {
  return {
    type: types.SET_ACTIVE_POINT_DATA,
    mode,
    data,
    table: table || false,
  };
}

export function changeListOpen({ mode, listName, nestingIndex, open }) {
  return {
    type: types.OVERVIEW.CHANGE_LIST_OPEN,
    payload: { mode, listName, nestingIndex, open },
  };
}

export function changeListActive({
  mode,
  listName,
  active,
  item,
}: {
  mode: ModeType;
  listName: ListType;
  active: boolean;
  item: FlattenListItem;
}) {
  return {
    type: types.OVERVIEW.CHANGE_LIST_ACTIVE,
    payload: { mode, listName, active, item },
  };
}

export function addToFavoriteLeaguesList({
  mode,
  listName,
  item,
}: {
  mode: ModeType;
  listName: ListType;
  item: FlattenListItem;
}) {
  return {
    type: types.OVERVIEW.ADD_TO_FAVORITE_LEAGUES_LIST,
    payload: { mode, listName, item },
  };
}

export function removeFromFavoriteLeaguesList({
  mode,
  listName,
  item,
}: {
  mode: ModeType;
  listName: ListType;
  item: FlattenListItem;
}) {
  return {
    type: types.OVERVIEW.REMOVE_FROM_FAVORITE_LEAGUES_LIST,
    payload: { mode, listName, item },
  };
}
