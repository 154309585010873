import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import {
  getColorFromScaleSimple,
  renderNumberValue,
  renderRatingValue,
} from '../../../../helper';

import { NO_RATING_UNDER_MINS } from '../../../../constants';
import Image from '../../../ui/image';
import Icon from '../../../ui/svg-icon';

import './season_details.scss';

class SeasonDetails extends Component {
  static propTypes = {
    details: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
  }

  renderRow(row, i) {
    const { league, app } = this.props;
    const { teams, positions, formations } = app;
    let homeTeam;
    let awayTeam = null;
    const goals = `${row.homegoals} : ${row.awaygoals}`;
    let goalsClass = '';

    const formationName = _.get(
      formations,
      `[${row.formation_id}].name`,
      'N/D',
    );

    if (row.place === 0) {
      homeTeam = teams[row.team_id];
      awayTeam = teams[row.opponent_id];
      if (row.homegoals > row.awaygoals) {
        goalsClass = 'green';
      } else if (row.homegoals < row.awaygoals) {
        goalsClass = 'red';
      }
    } else {
      homeTeam = teams[row.opponent_id];
      awayTeam = teams[row.team_id];
      if (row.homegoals < row.awaygoals) {
        goalsClass = 'green';
      } else if (row.homegoals > row.awaygoals) {
        goalsClass = 'red';
      }
    }

    let isSuspended = false;

    if (row.red > 0) isSuspended = 'red';
    if (row.yellow >= 2) isSuspended = 'yellow';

    const position = positions[row.position_id_detail];

    const rating = renderRatingValue(row.rating, row.rating, row.mins);
    const offence = renderRatingValue(row.offence, row.rating, row.mins);
    const defence = renderRatingValue(row.defence, row.rating, row.mins);

    const ratingNotDefined = rating === 'N/D';
    const offenceNotDefined = offence === 'N/D';
    const defenceNotDefined = defence === 'N/D';

    if (
      isSuspended === 'red' ||
      isSuspended === 'yellow' ||
      isSuspended === 'bench'
    ) {
      return (
        <tr key={i} className={`${isSuspended}-row`}>
          <td className="centered" />
          {[1, 6].indexOf(league.format_id) !== -1 ? (
            <td className="centered">{row.matchday || ''}</td>
          ) : null}
          <td>{moment(row.date).format('YYYY-MM-DD')}</td>
          <td>
            <Link
              to={`/details/team/${homeTeam.id}/${row.league_id}/${row.season_id}`}
            >
              <span className="nowrap">
                <Image
                  src={`https://static.sp7f.com/v2/teams/${homeTeam.id}.jpg`}
                />
                {homeTeam.abk}
              </span>
            </Link>
          </td>
          <td>
            <Link
              to={`/details/team/${awayTeam.id}/${row.league_id}/${row.season_id}`}
            >
              <span className="nowrap">
                <Image
                  src={`https://static.sp7f.com/v2/teams/${awayTeam.id}.jpg`}
                />
                {awayTeam.abk}
              </span>
            </Link>
          </td>
          <td>
            <span className={goalsClass}> {goals} </span>
          </td>
          <td>{position.position_name}</td>
          <td colSpan={10} className="card-text centered">
            {isSuspended === 'red' ? <span>Red card suspension</span> : null}
            {isSuspended === 'yellow' ? (
              <span>Yellow card suspension</span>
            ) : null}
            {isSuspended === 'bench' ? <span>On the bench</span> : null}
          </td>
          <td className="centered borderless">
            <Link
              to={`/details/match/${row.match_id}/${row.league_id}/${row.season_id}`}
            >
              View match
            </Link>
          </td>
        </tr>
      );
    }

    return (
      <tr key={i}>
        <td className="centered" />
        {[1, 6].indexOf(league.format_id) !== -1 ? (
          <td className="centered">{row.matchday || ''}</td>
        ) : null}
        <td>{moment(row.date).format('YYYY-MM-DD')}</td>
        <td>
          <Link
            to={`/details/team/${homeTeam.id}/${row.league_id}/${row.season_id}`}
          >
            <span className="nowrap">
              <Image
                src={`https://static.sp7f.com/v2/teams/${homeTeam.id}.jpg`}
              />
              {homeTeam.abk}
            </span>
          </Link>
        </td>
        <td>
          <Link
            to={`/details/team/${awayTeam.id}/${row.league_id}/${row.season_id}`}
          >
            <span className="nowrap">
              <Image
                src={`https://static.sp7f.com/v2/teams/${awayTeam.id}.jpg`}
              />
              {awayTeam.abk}
            </span>
          </Link>
        </td>
        <td>
          <span className={goalsClass}> {goals} </span>
        </td>
        <td>{position.position_name}</td>
        <td>{formationName}</td>
        <td data-tooltip-content="Goals" className="centered">
          {renderNumberValue(row.goals)}
        </td>
        <td data-tooltip-content="Assists" className="centered">
          {renderNumberValue(row.assists)}
        </td>
        <td data-tooltip-content="Mins" className="centered">
          {renderNumberValue(row.mins, v => `${v}’`)}
        </td>
        <td data-tooltip-content="Mins %" className="centered">
          {renderNumberValue(
            row.mins / row.playing_time,
            v => `${Math.round(v * 100)}%`,
          )}
        </td>
        <td data-tooltip-content="In" className="centered">
          {renderNumberValue(row.substitution_in, v =>
            v === 0 ? '' : `${v}’`,
          )}
        </td>
        <td data-tooltip-content="Out" className="centered">
          {renderNumberValue(row.substitution_out, v =>
            v == row.playing_time ? '-' : `${v}’`,
          )}
        </td>
        <td
          data-tooltip-content={
            row.mins < NO_RATING_UNDER_MINS || ratingNotDefined
              ? 'Rating. Not rated due to playing time under 20 mins.'
              : null
          }
          className={`centered borderless ${
            ratingNotDefined ? 'not_defined' : 'rating'
          }`}
          style={{
            backgroundColor: `${
              ratingNotDefined ? '#fff' : getColorFromScaleSimple(row.rating)
            }`,
          }}
        >
          {!ratingNotDefined && (
            <Icon name="ranking-winner" className="icon-epi" />
          )}
          {rating}
        </td>
        <td
          data-tooltip-content={
            row.mins < NO_RATING_UNDER_MINS || offenceNotDefined
              ? 'Offence. Not rated due to playing time under 20 mins.'
              : null
          }
          className={`centered borderless ${
            offenceNotDefined ? 'not_defined' : ''
          }`}
          style={{
            backgroundColor: `${
              offenceNotDefined ? '#fff' : getColorFromScaleSimple(row.offence)
            }`,
          }}
        >
          {offence}
        </td>
        <td
          data-tooltip-content={
            row.mins < NO_RATING_UNDER_MINS || defenceNotDefined
              ? 'Defence. Not rated due to playing time under 20 mins.'
              : null
          }
          className={`centered borderless ${
            defenceNotDefined ? 'not_defined' : ''
          }`}
          style={{
            backgroundColor: `${
              defenceNotDefined ? '#fff' : getColorFromScaleSimple(row.defence)
            }`,
          }}
        >
          {defence}
        </td>
        <td className="centered borderless">
          <Link
            to={`/details/match/${row.match_id}/${row.league_id}/${row.season_id}`}
          >
            View match
          </Link>
        </td>
      </tr>
    );
  }

  render() {
    const { data, league } = this.props;

    let orderedData = _.orderBy(data, ['date'], ['asc']);

    return (
      <div className="history-season">
        <table>
          <tr>
            <th />
            {[1, 6].indexOf(league.format_id) !== -1 ? <th>Day</th> : null}
            <th>Date</th>
            <th>Home</th>
            <th>Away</th>
            <th>Result</th>
            <th>Pos.</th>
            <th>Lineup</th>
            <th data-tooltip-content="Goals" className="centered">
              <Icon className="header-icon goals-icon" name="goal" />
            </th>
            <th data-tooltip-content="Assists" className="centered">
              <Icon className="header-icon assists-icon" name="assist" />
            </th>
            <th data-tooltip-content="Mins" className="centered">
              <Icon className="header-icon time-icon" name="time" />
            </th>
            <th data-tooltip-content="Mins %" className="centered">
              <Icon className="header-icon time-icon" name="time-percent" />
            </th>
            <th data-tooltip-content="In" className="centered">
              <Icon className="header-icon time-icon" name="subin" />
            </th>
            <th data-tooltip-content="Out" className="centered">
              <Icon className="header-icon time-icon" name="subout" />
            </th>
            <th data-tooltip-content="Rating" className="centered epi">
              RAT
            </th>
            <th data-tooltip-content="Offence" className="centered epi">
              OFF
            </th>
            <th data-tooltip-content="Defence" className="centered epi">
              DEF
            </th>
            <th />
          </tr>
          <tbody>{orderedData.map((row, i) => this.renderRow(row, i))}</tbody>
        </table>
        <ReactTooltip place="right" type="light" className="label-popup" />
      </div>
    );
  }
}

export default connect(state => ({
  app: state.app,
}))(SeasonDetails);
