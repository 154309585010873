import { createSelector } from 'reselect';
import _orderBy from 'lodash/orderBy';
import _map from 'lodash/map';

const getLeagues = state => state.app.leagues;
const getDivisions = () => [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
  { id: 99, name: 'Other' },
];

const getOptions = (data, map) => {
  const options = _map(data, map);
  return _orderBy(options, 'label');
};

export const getLeagueOptions = createSelector([getLeagues], leagues =>
  getOptions(leagues, league => ({
    value: league.id,
    label: league.name,
    data: league,
  })),
);

export const getDivisionOptions = createSelector([getDivisions], divisions =>
  getOptions(divisions, division => ({
    value: division.id,
    label: division.name,
    data: division,
  })),
);
