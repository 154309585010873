import React, { Component } from 'react';

class Privacy extends Component {
  render() {
    return (
      <div className="privacy-page">
        <h1>Privacy Policy</h1>
        <br />
        <p>
          <b>Your personal information is in safe hands with matchmetrics</b>
        </p>
        <p>
          At matchmetrics we’ve always taken data protection very seriously.
          <br />
          We want you to know you can trust us to respect your privacy and keep
          your personal information safe.
        </p>
        <p>
          Please find all information about our privacy police{' '}
          <a href="https://scoutpanel.com/privacy-policy/" target="_blank">
            here
          </a>
          . <br />
          If you have any questions about privacy, please feel free to write us
          an e-mail at{' '}
          <a href="mailto:privacy@matchmetrics.com">privacy@matchmetrics.com</a>
          .
        </p>
      </div>
    );
  }
}

export default Privacy;
