import React from 'react';

import { SeasonList } from './SeasonList';
import { SeasonSlider } from './SeasonSlider';
import { ModeType } from '../../../../../types/Dict';
import {
  loading,
  loadPlayersPerformanceData,
  loadTeamsPerformanceData,
} from '../../../../../actions/app';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../types';
import {
  selectListFilter,
  selectSliderFilter,
} from '../../../../../reducers/overview/selectors';
import { ListType } from '../../../../../reducers/overview/types';
import { Collapse } from '../Collapse';
import { selectCurrentSeason } from '../../../../../reducers/app/selectors';
import { IndicatorType } from '../../../../../types/Indicators';
import moment from 'moment/moment';

interface Props {
  mode: ModeType;
}

export function SeasonFilter(props: Props) {
  const { mode } = props;
  const dispatch = useDispatch();

  const positionList = useSelector((state: RootState) =>
    selectListFilter(state, { mode, listName: ListType.POSITION_FIRST_ID }),
  );
  const formationList = useSelector((state: RootState) =>
    selectListFilter(state, { mode, listName: ListType.FORMATION_ID }),
  );
  const currentSeason = useSelector((state: RootState) =>
    selectCurrentSeason(state, { mode }),
  );

  const currentSeasonString = `${currentSeason}`;
  const title =
    currentSeasonString.indexOf('/') > -1
      ? `${moment(currentSeasonString.split('/')[0]).format(
          'MMM’YY',
        )} – ${moment(currentSeasonString.split('/')[1]).format('MMM’YY')}`
      : `Season ${currentSeasonString}`;

  const loadSeasonData = (season_id: string) => {
    let playersGroupBy: string | null = null;

    dispatch(loading(true, 'loading_players_performance'));
    if (mode === ModeType.PLAYER) {
      const splittedPosition = positionList?.mode || 2;
      const splittedFormation = formationList?.splitted || false;

      if (splittedPosition && splittedFormation) {
        playersGroupBy = `formation_position_${splittedPosition}`;
      } else if (splittedPosition) {
        playersGroupBy = `position_${splittedPosition}`;
      }

      dispatch(loadPlayersPerformanceData({ season_id, playersGroupBy }));
    } else if (mode === ModeType.TEAM) {
      dispatch(loadTeamsPerformanceData({ season_id }));
    }
  };

  return (
    <Collapse name={ListType.SEASON_ID} type="list" mode={mode} title={title}>
      <div className="filter_list_long">
        <SeasonList mode={mode} loadSeasonData={loadSeasonData} />
        <SeasonSlider mode={mode} loadSeasonData={loadSeasonData} />
      </div>
    </Collapse>
  );
}
