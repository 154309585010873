import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dropdown from 'react-dropdown';
import Datepicker from 'react-datepicker/dist/react-datepicker';
import moment from 'moment';
import { Field, reduxForm } from 'redux-form';

const validate = values => {
  const errors = {};

  const validateEmail = email => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  if (!validateEmail(values.email)) errors.email = 'Required';

  if (!values.firstName || !values.firstName.length)
    errors.firstName = 'Required';
  if (!values.lastName || !values.lastName.length) errors.lastName = 'Required';

  if (!values.groupId) errors.groupId = 'Required';
  if (!values.roleId) errors.groupId = 'Required';

  return errors;
};

const InputField = ({ input, meta: { touched, error }, placeholder }) => (
  <input
    {...input}
    className={`${touched && error ? 'invalid' : ''}`}
    placeholder={placeholder}
  />
);

const DropDownField = ({
  input: { onChange, value, ...input },
  meta: { touched, error },
  placeholder,
  options,
}) => {
  const valueAsOption = _.find(options, { value });

  return (
    <div className={`${value || value === false ? 'selected' : ''}`}>
      <Dropdown
        className={`${touched && error ? 'invalid' : ''}`}
        options={options}
        onChange={option => {
          onChange(option.value);
        }}
        value={valueAsOption}
        placeholder={placeholder}
        {...input}
      />
    </div>
  );
};

const DatepickerField = ({
  input: { onChange, value },
  meta: { touched, error },
  placeholder,
}) => {
  const selectedMomentDate = moment(value, 'YYYY-MM-DD');

  return (
    <Datepicker
      className={`${touched && error ? 'invalid' : ''}`}
      onChange={date => {
        const dateValue = moment(date);

        if (dateValue.isValid()) {
          onChange(dateValue.format('YYYY-MM-DD'));
        } else {
          onChange(date);
        }
      }}
      dateFormat="yyyy-MM-dd"
      selected={
        selectedMomentDate.isValid()
          ? selectedMomentDate.toDate()
          : value && value.length
          ? value
          : null
      }
      placeholderText={placeholder}
      isClearable
      autoFocus={false}
    />
  );
};

class Create extends Component {
  static propTypes = {
    roles: PropTypes.array.isRequired,
    groups: PropTypes.array.isRequired,
    locales: PropTypes.array.isRequired,
    types: PropTypes.array.isRequired,
    create: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      createMode: false,
      groups: [],
    };

    this.toggleCreateMode = this.toggleCreateMode.bind(this);
    this.create = this.create.bind(this);
  }

  toggleCreateMode(event) {
    event && event.preventDefault();

    this.setState(state => ({
      createMode: !state.createMode,
    }));
  }

  create(user) {
    const { create, reset } = this.props;

    create(user);

    this.setState({
      createMode: false,
    });

    reset();
  }

  render() {
    const { handleSubmit } = this.props;
    const { createMode } = this.state;

    const groups = this.props.groups.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    const roles = this.props.roles.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    const locales = this.props.locales.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    const types = this.props.types.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    if (!createMode) {
      return (
        <div className="create-container">
          <div className="users-list text-right">
            <a className="green-btn" href="#" onClick={this.toggleCreateMode}>
              + Add new user
            </a>
          </div>
        </div>
      );
    }

    return (
      <form
        className="create-container create-container__open"
        onSubmit={handleSubmit(this.create)}
      >
        <table className="users-list">
          <tbody>
            <tr className="edit">
              <td width="11%">
                <Field
                  name="firstName"
                  component={InputField}
                  type="text"
                  placeholder="First name"
                  autoFocus
                />
              </td>
              <td width="11%">
                <Field
                  name="lastName"
                  component={InputField}
                  type="text"
                  placeholder="Last name"
                />
              </td>
              <td width="11%">
                <Field
                  name="email"
                  component={InputField}
                  type="email"
                  placeholder="Email"
                />
              </td>
              <td width="11%">
                <Field
                  name="expireDate"
                  component={DatepickerField}
                  placeholder="Expire date"
                />
              </td>
              <td width="11%">
                <Field
                  name="groupId"
                  component={DropDownField}
                  options={groups}
                  placeholder="Group"
                />
              </td>
              <td width="11%">
                <Field
                  name="roleId"
                  component={DropDownField}
                  options={roles}
                  placeholder="Role"
                />
              </td>
              <td width="11%">
                <Field
                  name="locale"
                  component={DropDownField}
                  options={locales}
                  placeholder="Locale"
                />
              </td>
              <td width="11%">
                <Field
                  name="type"
                  component={DropDownField}
                  options={types}
                  placeholder="Account type"
                />
              </td>
              <td className="controls-col">
                <button type="submit" className="green-btn">
                  Add
                </button>
                <a className="red-btn" href="#" onClick={this.toggleCreateMode}>
                  Cancel
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  }
}

export default reduxForm({
  form: 'user-create',
  enableReinitialize: true,
  validate,
})(Create);
