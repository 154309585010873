import axios from 'axios';
import _ from 'lodash';

import {
  CREATE_NOTE,
  CREATE_NOTE_TAG,
  DELETE_NOTE,
  FETCH_NOTE_TAGS,
  FETCH_NOTES,
  SELECT_NOTE,
  SET_NOTE_MODE,
  UPDATE_NOTE,
  UPDATE_NOTE_DICTIONARIES,
  UPDATE_NOTE_FILTER,
} from '../constants';
import eventTracker from '../helper/event-tracker';

export function fetchNoteTags() {
  const request = axios.get('/note-tags/');

  return {
    type: FETCH_NOTE_TAGS,
    payload: request,
  };
}

export function createNoteTag(tag) {
  const request = axios.post('/note-tags/', tag);

  eventTracker.trackEvent('Notes', 'Create tag');
  return {
    type: CREATE_NOTE_TAG,
    payload: request,
  };
}

export function updateNotesDictionaries(lists) {
  return {
    type: UPDATE_NOTE_DICTIONARIES,
    payload: lists,
  };
}

export function fetchNotes() {
  const request = axios.get('/notes');

  return {
    type: FETCH_NOTES,
    payload: request,
  };
}

export function updateNotesFilter(filter) {
  return {
    type: UPDATE_NOTE_FILTER,
    payload: filter,
  };
}

export function selectNote(note) {
  return {
    type: SELECT_NOTE,
    payload: note,
  };
}

export function createNote(user, player_id) {
  const request = axios.post('/notes', { player_id });

  eventTracker.trackEvent('Notes', 'Create note');
  return {
    type: CREATE_NOTE,
    payload: request,
  };
}

export function deleteNote(id) {
  const request = axios.delete(`/notes/${id}`);

  eventTracker.trackEvent('Notes', 'Delete note');
  return {
    type: DELETE_NOTE,
    payload: id,
  };
}

const throttledRequest = _.debounce((id, note) => {
  const request = axios.post(`notes/${note.id}`, note);
}, 1000);

export function updateNote(note) {
  throttledRequest(note.id, note);
  return {
    type: UPDATE_NOTE,
    payload: note,
  };
}

export function setNoteMode(mode) {
  return {
    type: SET_NOTE_MODE,
    payload: mode,
  };
}
