import './info.scss';

import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router';

import Icon from '../ui/svg-icon';

import Help from './help.js';
import Privacy from './privacy.js';

const getWindowHeight = () =>
  document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.innerHeight;

class Info extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: getWindowHeight(),
      tabs: [
        {
          name: 'Help',
          link: '/info/help',
        },
        {
          name: 'Privacy',
          link: '/info/privacy',
        },
      ],
    };

    this._renderTabs = this._renderTabs.bind(this);
    this._renderNav = this._renderNav.bind(this);
    this._handleResize = this._handleResize.bind(this);
    // this._getContent = this._getContent.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
    window.addEventListener('resize', this._handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleResize);
  }

  _handleResize(event) {
    this.setState({ height: getWindowHeight() });
  }

  _renderTabs() {
    return this.state.tabs.map((tab, i) => (
      <Link
        key={i}
        className="col-2 callAction"
        activeClassName="active"
        to={tab.link}
      >
        {tab.name}
      </Link>
    ));
  }

  _renderNav() {
    return (
      <div className="nav-container">
        <div className="container">
          <div className="nav row">{this._renderTabs()}</div>
        </div>
      </div>
    );
  }

  render() {
    const { height } = this.state;
    const mode = localStorage.getItem('overview-mode');

    return (
      <div className="info">
        <Scrollbars style={{ height }} autoHide={true}>
          <div className="header-container">
            <div className="container">
              <div className="row">
                <div className="col-4">
                  <div className="info-logo">
                    <Icon name="help" />
                    <span>Information</span>
                  </div>
                </div>
                <div className="col-4">&nbsp;</div>
                <div className="col-4">
                  <div className="btn pull-right">
                    <Link to={`/overview/${mode}`} className="icon-close" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this._renderNav()}

          <div className="content-container">
            <div className="container">{this.props.children}</div>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default Info;
