import React, { Component } from 'react';
import { Link } from 'react-router';
import { DragSource } from 'react-dnd';
import _ from 'lodash';

import Icon from '../../ui/svg-icon';

class Player extends Component {
  render() {
    const { player, team, connectDragSource, isDragging } = this.props;

    let cls = '';
    cls = isDragging ? 'choosen-one' : '';
    _.forOwn(team, value => {
      if (value == player.player_id) {
        cls = 'choosen-one';
      }
    });

    return (
      <tr className={`${cls}`}>
        <td>
          <span className="roundedRat">{player.season_rating}</span>
          {/* className="fill" */}
        </td>
        <td className="col__img">
          <Link
            to={`/details/player/${player.player_id}/${player.league_id}/${player.season_id}/summary`}
          >
            <img
              src={`https://static.sp7f.com/v2/players/${player.player_id}.jpg`}
              alt={player.short_name}
            />
          </Link>
        </td>
        <td>
          <Link
            to={`/details/player/${player.player_id}/${player.league_id}/${player.season_id}/summary`}
          >
            {player.short_name}
          </Link>
        </td>
        <td>
          <span className="pos__name">{player.position_name}</span>
        </td>
        <td>{player.defence || 'N/D'}</td>
        <td>{player.offence || 'N/D'}</td>
        <td>{player.season_mins || 'N/D'}</td>
        <td>
          {connectDragSource(
            <span className="drag-handle">
              <Icon name="drag-handle" className="drag-handle-icon" />
            </span>,
          )}
        </td>
      </tr>
    );
  }
}

export default DragSource(
  'player',
  {
    beginDrag(props) {
      return {
        player: props.player,
        position: props.position,
      };
    },
    endDrag(props, monitor, component) {
      if (!monitor.didDrop()) {
        return;
      }

      const dropResult = monitor.getDropResult();
      const { position } = dropResult;
      const { player } = props;

      props.setPlayer(position.id, player.player_id);
    },
  },
  (connect, monitor) => ({
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    canDrag: monitor.canDrag(),
  }),
)(Player);
