import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  FlattenListItem,
  ListType,
} from '../../../../../reducers/overview/types';
import { ModeType } from '../../../../../types/Dict';
import { ListField } from '../fields/ListField';
import { NewPreset } from './NewPreset';
import { ListItem } from '../fields/ListField/ListItem';
import { resetFilters } from '../../../../../actions/overview';
import { RootState } from '../../../../../types';
import { selectFilterComponents } from '../../../../../reducers/overview/selectors';
import { selectCollapseList } from '../slice/selectors';
import { getGroupBy } from '../../../../../helper';
import {
  deleteFilterPreset,
  setFilterPreset,
} from '../../../../../actions/filter-presets';
import {
  loading,
  loadPlayersPerformanceData,
} from '../../../../../actions/app';

interface Props {
  listName?: ListType;
  mode: ModeType;
}

const defaultProps = {
  listName: ListType.PRESET_ID,
};

export function PresetFilter(props: Props & typeof defaultProps) {
  const { listName, mode } = props;
  const dispatch = useDispatch();
  const filterComponents = useSelector((state: RootState) =>
    selectFilterComponents(state, { mode }),
  );
  const listCollapse = useSelector((state: RootState) =>
    selectCollapseList(state, { mode, listName }),
  );
  const currentSeason = useSelector(
    (state: RootState) => state.app.season_current,
  );

  const onSelectPreset = (item: FlattenListItem) => {
    const groupByOld = getGroupBy({ filterState: filterComponents });
    const groupByNew = getGroupBy({
      filterState: JSON.parse(item!.preset || ''),
    });

    dispatch(loading(true, 'loading_players_performance'));
    dispatch(resetFilters(mode));
    setTimeout(() => {
      dispatch(setFilterPreset(item));
      if (mode === ModeType.PLAYER && groupByOld !== groupByNew) {
        dispatch(loading(true, 'loading_players_performance'));
        dispatch(
          loadPlayersPerformanceData({
            season_id: currentSeason[mode],
            playersGroupBy: groupByNew,
          }),
        );
      } else {
        dispatch(loading(false));
      }
    }, 500);
  };

  const onDeletePreset = (item: FlattenListItem) => {
    if (window.confirm('Do you really want to remove this preset?')) {
      dispatch(deleteFilterPreset(item.id, mode));
    }
  };

  const itemRenderer = (item: FlattenListItem) => {
    return (
      <ListItem
        mode={mode}
        listName={listName}
        item={item}
        onItemClick={onSelectPreset}
      >
        <ListItem.Cross onClick={onDeletePreset} />
      </ListItem>
    );
  };

  return (
    <div className="filter_list_long">
      <NewPreset mode={mode} />
      <ListField
        mode={mode}
        listName={listName}
        itemRenderer={itemRenderer}
        height={(listCollapse?.height || 0) - 36}
      />
    </div>
  );
}

PresetFilter.defaultProps = defaultProps;
