import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import { PolarChart } from 'd3-react-touch';

import FavoriteStar from '../../ui/favorite-star-with-category';
import Image from '../../ui/image';
import { DataItem } from '../../ui/data-item';
import lang from '../../../lang';

import { createPolarCharts } from '../../../helper';
import { COLOR_SCALE } from '../../../constants';
import QuickCopy from '../../details/layouts/header/quick-copy';

import { setActivePointData } from '../../../actions/overview';
import eventTracker from '../../../helper/event-tracker';
import Icon from '../../ui/svg-icon';
import { database } from '../../../constants/database';

class Tooltip extends Component {
  static propTypes = {
    setActivePointData: PropTypes.func,
    meanByPositionGroups: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    const { setActivePointData } = this.props;

    setActivePointData({
      mode: 'player',
      data: null,
    });
  }

  render() {
    const {
      activePointData,
      teams,
      positions,
      season_current,
      playersMetaData,
      filterComponents,
      favorites,
      meanByPositionGroups,
      countriesByAbbr,
    } = this.props;
    const data = activePointData.player;

    if (!data) return null;

    const filterPositionValue = _.find(filterComponents.list, {
      name: 'position_first_id',
    });
    const splittedPosition = filterPositionValue
      ? filterPositionValue.mode
      : false;

    const goalkeeper = data.position_id === 1 || data.position_id === 10;
    let radars_vals_against = [
      'tackle_defence',
      'tackle_offence',
      'intercept_defence',
      'intercept_offence',
      'blocked',
      'save',
      'aerial_defence',
      'aerial_offence',
    ];
    let radars_vals_against_short_names = ['', '', '', '', '', '', '', '']; //['TDF', 'TOF', 'IDF', 'IOF', 'BPA', 'BSH', 'ADF', 'AOF'];
    if (goalkeeper)
      radars_vals_against = [
        'intercept_defence',
        'save',
        'claim',
        'keeper_throw',
        'pass_long',
        'pass_short',
        'reception',
        'delivery',
      ];
    if (goalkeeper)
      radars_vals_against_short_names = ['', '', '', '', '', '', '', ''];
    const radars_against = createPolarCharts(
      { ...data, ...meanByPositionGroups[data.position_id] },
      radars_vals_against,
      null,
      false,
      radars_vals_against_short_names,
    );

    const radars_vals_on = [
      'take_on',
      'crossing',
      'pass_short',
      'pass_long',
      'reception',
      'delivery',
      'scoring',
      'scoring_effort',
    ];
    const radars_vals_on_short_names = ['', '', '', '', '', '', '', ''];
    const radars_onball = createPolarCharts(
      { ...data, ...meanByPositionGroups[data.position_id] },
      radars_vals_on,
      null,
      false,
      radars_vals_on_short_names,
    );

    const favorite = _.find(
      favorites.player,
      favorite => +favorite.player_id === +data.player_id,
    );

    const url = data.player_id
      ? `${database.assets}players/${data.player_id}.jpg`
      : '';
    const emblemUrl = !_.isEmpty(data)
      ? `${database.assets}teams/${data.team_id}.jpg`
      : '';

    let posLink = '';
    let currentPos = 0;

    if (+splittedPosition === 2) {
      posLink = `?position=${data.position_id}`;
      currentPos = data.position_id;
    }
    if (+splittedPosition === 3) {
      posLink = `?position=${data.position_first_id}`;
      currentPos = data.position_first_id;
    }

    const player = playersMetaData[data.player_id];

    const nationality = _.get(
      countriesByAbbr,
      `[${player?.country_id}].country`,
      'N/D',
    );
    const contract_expire_age =
      data.contract_expiry_year > 0 ? 2000 + data.contract_expiry_year : 'N/D';
    const marketvalue = data.marketvalue > 0 ? `${data.marketvalue}` : 'N/D';
    const flag = `https://static.sp7f.com/v2/countries/${player?.country_id}.jpg`;

    const radar = {
      width: 150,
      height: 150,
      margin: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
      },
    };

    return (
      <div>
        <div className="row tooltip__content">
          <div className="col-6 noSpacing">
            <Image src={url} aspectRatio={1} />
          </div>

          <div className="col-6">
            <div className="tooltip__content_header clearfix">
              <div className="tooltip__content_header-container">
                <div className="copy-url">
                  <QuickCopy
                    item={{
                      id: data.player_id,
                      isAccessGranted: true,
                      information: player,
                      epiRating: [data],
                      performance: { ...data, mins: data.season_mins },
                      current_league: data.league_id,
                      current_season: data.season_id,
                      current_position: currentPos,
                    }}
                    onAfterCopy={() => {
                      eventTracker.trackEvent(
                        'Overview',
                        'Copy player link / tooltip',
                      );
                    }}
                  />
                </div>
                <FavoriteStar
                  className="btn_star"
                  favorite={favorite}
                  item={data}
                  mode="player"
                />
                <div className="btn" onClick={this.onClose}>
                  <div className="icon-close" />
                </div>
              </div>
            </div>

            <div className="row tooltip__content_name">
              <div className="firstname">
                {playersMetaData[data.player_id]
                  ? playersMetaData[data.player_id]?.first_name?.substring(
                      0,
                      19,
                    )
                  : null}
              </div>
              <div className="lastname">
                {playersMetaData[data.player_id]
                  ? playersMetaData[data.player_id]?.last_name?.substring(0, 15)
                  : null}
              </div>
              <div className="country">
                <Image
                  src={flag}
                  className="country-flag"
                  aspectRatio={125 / 75}
                />
                {nationality}
              </div>
              <div className="team">
                {data.team_id ? (
                  <Image src={emblemUrl} aspectRatio={0.769} />
                ) : null}{' '}
                {_.get(teams, `${data.team_id}.team_name`, '')}
                ,
                <br />
                {_.get(positions, `${data.position_id}.description`, '')}
              </div>
            </div>
          </div>
        </div>

        <div className="grey-container">
          <div className="tooltip__content_stats-grey">
            <div className="r">
              <DataItem
                reverse
                text={data.season_rating}
                desc={lang.rating.substring(0, 3)}
                tip="Season rating"
              />
              <DataItem
                reverse
                text={data.defence}
                desc={lang.defence.substring(0, 3)}
                tip="Defence"
              />
              <DataItem
                reverse
                text={data.offence}
                desc={lang.offence.substring(0, 3)}
                tip="Offence"
              />
              <div className="divider" />
              <DataItem
                reverse
                text={parseInt(data.season_mins)}
                desc={lang.mins.substring(0, 3)}
                tip="Season mins"
              />
              <DataItem
                reverse
                text={contract_expire_age}
                desc={'CTR'}
                tip="Contract until"
              />
              <DataItem
                reverse
                text={marketvalue}
                desc={'VAL'}
                tip="Value Mio. €"
              />
            </div>
          </div>
          <div className="tooltip__charts">
            <div className="polar-chart">
              <span
                className="polar-chart-info"
                data-tooltip-content="Polar charts mean values are shown for filtered set of players"
                data-type="light"
                data-tooltip-id="TooltipHelp"
              >
                <Icon name="info" />
              </span>
              <div className="icon-against" />
              <PolarChart
                {...radar}
                animation={false}
                animationDelay={0}
                tooltips={radars_against.tooltips}
                classNames={radars_against.classes}
                data={radars_against.items}
                xDomain={radars_against.xDomain}
                yDomain={radars_against.yDomain}
                colors={COLOR_SCALE}
                interpolationType="linear-closed"
              />
            </div>

            {!goalkeeper ? (
              <div className="polar-chart">
                <div className="icon-on" />
                <PolarChart
                  {...radar}
                  animation={false}
                  animationDelay={0}
                  classNames={radars_onball.classes}
                  tooltips={radars_onball.tooltips}
                  data={radars_onball.items}
                  xDomain={radars_onball.xDomain}
                  yDomain={radars_onball.yDomain}
                  colors={COLOR_SCALE}
                  interpolationType="linear-closed"
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="row tooltip__content_actionbar">
          <Link
            to={`/details/player/${`${data.player_id}/${data.league_id}/${season_current.player}`}/summary${posLink}`}
            className="col-6 callAction"
          >
            <span className="icon-more" />
            {lang.details}
          </Link>
          <Link
            to={`/compare/player/${`${data.player_id}-${
              data.league_id
            }-${`${season_current.player}`.replace('/', ',')}${posLink}`}`}
            className="col-6 callAction"
          >
            <span className="icon-compare" />
            {lang.compare}
          </Link>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    teams: state.app.teams,
    positions: state.app.positions,
    season_current: state.app.season_current,
    playersMetaData: state.app.playersMetaData,
    filterComponents: state.overview.player.filterComponents,
    favorites: state.app.favorites,
    activePointData: state.overview.activePointData,
    countriesByAbbr: state.app.countriesByAbbr,
  }),
  { setActivePointData },
)(Tooltip);
