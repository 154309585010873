import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BarChart } from 'd3-react-touch';
import Icon from '../../../ui/svg-icon';

// Load actions, own fcts and components
import lang from '../../../../lang';
import { createCompareBarCharts } from '../../../../helper/';

class CompareDetails extends Component {
  static propTypes = {
    playersMetaData: PropTypes.object,
    teams: PropTypes.object,
    mode: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.renderEpiStats = this.renderEpiStats.bind(this);
    this.renderBarChart = this.renderBarChart.bind(this);
  }

  renderEpiStats({ bars }) {
    let animationDelayCounter = 0;

    const renderGroup = group =>
      group.map((stat, s) => {
        animationDelayCounter++;

        return (
          <div key={s} className="col-3 chart_group">
            {this.renderBarChart(stat)}
          </div>
        );
      });

    return _.chunk(bars, 4).map((group, g) => (
      <div className="row bar_chart_group" key={g}>
        {renderGroup(group)}
      </div>
    ));
  }

  renderBarChart(data) {
    const { mode, playersMetaData, teams } = this.props;

    const width = 115;
    const height = 50;
    const margin = {
      top: 13,
      right: 0,
      bottom: 1,
      left: 0,
    };

    const getAbr = () => {
      if (mode === 'team') {
        return data.teams_id.map((team_id, index) => (
          <div key={`${team_id}-${index}`} className="player-name-abr">
            <span>{_.get(teams, `${team_id}.abk`, '').slice(0, 3)}</span>
          </div>
        ));
      }

      return data.players_id.map((player_id, index) => (
        <div key={`${player_id}-${index}`} className="player-name-abr">
          <span>
            {_.get(playersMetaData, `${player_id}.last_name`, '').slice(0, 3)}
          </span>
        </div>
      ));
    };

    return (
      <div className="center">
        <h3>{data.name}</h3>
        <BarChart
          data={data.values}
          width={width}
          height={height}
          classNames={data.classes}
          topBarAccessor_fir={d => d.y}
          topBarAccessor_sec={d => (d.desc != '0th' ? d.desc : '')}
          margin={margin}
          showZero
        />
        <div className="players-name">{getAbr()}</div>
      </div>
    );
  }

  render() {
    const { data, mode } = this.props;

    const goalkeeper =
      !_.isEmpty(data[0].data) && +data[0].data.position_id_first === 10;

    let radars_vals_against = [
      'tackle_defence',
      'tackle_offence',
      'intercept_defence',
      'intercept_offence',
      'blocked',
      'save',
      'aerial_offence',
      'aerial_defence',
    ];
    let radars_vals_on = [
      'take_on',
      'crossing',
      'pass_short',
      'pass_long',
      'reception',
      'delivery',
      'scoring',
      'scoring_effort',
    ];
    const bars_against_indi = [
      'real_tackles',
      'real_tackles_pc_',
      'real_aerial_duels',
      'real_aerial_duels_pc_',
      'real_clearances',
      'real_interception',
      'real_blocks',
    ];
    const bars_onball_indi = [
      'real_free_kicks',
      'real_corners',
      'real_dribblings',
      'real_dribblings_pc_',
      'real_shots',
      'real_in_box_shots',
      'real_goal_conversion',
    ];
    const bars_pass_indi = [
      'real_key_passes',
      'real_passes',
      'real_pass_pc_',
      'real_crosses',
      'real_long_balls',
      'real_through_balls',
      'real_chances_created',
    ];

    if (goalkeeper) {
      radars_vals_against = [
        'intercept_defence',
        'save',
        'claim',
        'keeper_throw',
        'pass_long',
        'pass_short',
        'reception',
        'delivery',
      ];
    }
    if (mode === 'team') {
      radars_vals_on = [
        'take_on',
        'crossing',
        'pass_short',
        'pass_long',
        'corner',
        'delivery',
        'scoring',
        'scoring_effort',
      ];
    }

    const bars_own_against = createCompareBarCharts(data, radars_vals_against);
    const bars_own_onball = createCompareBarCharts(data, radars_vals_on);

    const bars_against = createCompareBarCharts(data, bars_against_indi);
    const bars_onball = createCompareBarCharts(data, bars_onball_indi);
    const bars_pass = createCompareBarCharts(data, bars_pass_indi);

    return (
      <div className="content_more">
        <div className="content_group performance_ownstats">
          <div className="row">
            <div className="h4">
              <Icon name="ranking-winner" width="33px" height="28px" />
              <span>{lang.ownstats}</span>
              <span className="description">
                Total seasonal 0-10 rating per 90 minutes
              </span>
            </div>
          </div>

          {goalkeeper ? (
            <div className="row">
              <div className="col-12 chart_group">
                {this.renderEpiStats({ bars: bars_own_against })}
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-6 chart_group radar">
                {this.renderEpiStats({ bars: bars_own_against })}
              </div>
              <div className="col-6 chart_group radar">
                {this.renderEpiStats({ bars: bars_own_onball })}
              </div>
            </div>
          )}
        </div>

        <div className="content_group performance_optastats">
          <div className="row">
            <div className="h4">
              <span>{lang.optastats}</span>
              <span className="description">Total values per 90 minutes</span>
            </div>
          </div>

          <div className="row bar_chart_group against">
            <h1 className="line">{lang.againstball}</h1>
            {bars_against.map((d, i) => {
              return (
                <div key={i} className="col-1_5 chart_group">
                  {this.renderBarChart(d)}
                </div>
              );
            })}
          </div>

          <div className="row bar_chart_group onball">
            <h1 className="line">{lang.onball}</h1>
            {bars_onball.map((d, i) => {
              return (
                <div key={i} className="col-1_5 chart_group">
                  {this.renderBarChart(d)}
                </div>
              );
            })}
          </div>

          <div className="row bar_chart_group passing">
            <h1 className="line">{lang.passing}</h1>
            {bars_pass.map((d, i) => {
              return (
                <div key={i} className="col-1_5 chart_group">
                  {this.renderBarChart(d)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  playersMetaData: state.app.playersMetaData,
  teams: state.app.teams,
}))(CompareDetails);
