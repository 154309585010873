import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';

import './general.scss';

import { logout } from '../../actions/auth';
import { updateUser } from '../../actions/users';

class General extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changePassword: {
        newPassword: '',
        repeatPassword: '',
        error: false,
        success: false,
      },
    };

    this.logout = this.logout.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  onInputPassword(value, input) {
    const changePassword = _.cloneDeep(this.state.changePassword);
    changePassword[input] = value;

    this.setState({ changePassword });
  }

  logout(event) {
    event.preventDefault();

    this.props.logout();
    browserHistory.push('/auth/login');
  }

  changePassword(event) {
    event.preventDefault();

    const { changePassword } = this.state;

    if (
      changePassword.newPassword.length < 6 ||
      changePassword.repeatPassword < 6
    ) {
      this.setState({
        changePassword: {
          ...changePassword,
          error: 'Please enter at least 6 characters.',
        },
      });
    } else if (changePassword.newPassword !== changePassword.repeatPassword) {
      this.setState({
        changePassword: {
          ...changePassword,
          error: 'Please enter the same password',
        },
      });
    } else {
      this.setState({
        changePassword: { ...changePassword, error: false, success: false },
      });

      this.props
        .updateUser({
          userId: this.props.user.id,
          body: {
            password: changePassword.newPassword,
          },
        })
        .then(() => {
          this.setState({
            changePassword: {
              newPassword: '',
              repeatPassword: '',
              error: false,
              success: 'Your password has been updated successfully.',
            },
          });
        })
        .catch(() => {
          this.setState({
            changePassword: {
              ...changePassword,
              error: 'Sorry, an error occurred',
            },
          });
        });

      /*this.props.dispatch(
        accountApi.actions.user.put(
          {
            id: this.props.user.id,
          },
          {
            body: JSON.stringify({
              password: changePassword.newPassword,
            }),
          },
          (err, res) => {
            if (err) {
              this.setState({
                changePassword: {
                  ...changePassword,
                  error: 'Sorry, an error occurred',
                },
              });
            } else {
              this.setState({
                changePassword: {
                  newPassword: '',
                  repeatPassword: '',
                  error: false,
                  success: 'Your password has been updated successfully.',
                },
              });
            }
          },
        ),
      );*/
    }
  }

  render() {
    const { user } = this.props;
    const canChangePassword = +user.id !== 263;

    return (
      <div className="row">
        {canChangePassword ? (
          <div className="account-left-col">
            <div className="account-change-password">
              <div className="title">
                Change
                <br />
                password
              </div>
              <form className="password-form" onSubmit={this.changePassword}>
                <input
                  type="password"
                  value={this.state.changePassword.newPassword}
                  onChange={event =>
                    this.onInputPassword(event.target.value, 'newPassword')
                  }
                  placeholder="New password"
                />
                <input
                  type="password"
                  value={this.state.changePassword.repeatPassword}
                  onChange={event =>
                    this.onInputPassword(event.target.value, 'repeatPassword')
                  }
                  placeholder="Confirm password"
                />
                <div
                  className={`error-message ${
                    this.state.changePassword.error ? 'visible' : ''
                  }`}
                >
                  {this.state.changePassword.error}
                </div>
                <div
                  className={`success-message ${
                    this.state.changePassword.success ? 'visible' : ''
                  }`}
                >
                  {this.state.changePassword.success}
                </div>
                <button type="submit">Save</button>
              </form>
            </div>
          </div>
        ) : null}

        <div className="account-right-col">
          <a className="log-out-btn" onClick={this.logout}>
            Log out
          </a>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    user: state.auth.user,
  }),
  { logout, updateUser },
)(General);
