import React from 'react';
import PropTypes from 'prop-types';

const Grid = props => {
  const { xScale, yScale, height, width } = props;
  const verticalGrid = xScale.ticks().map(tick => xScale(tick));
  const horizontalGrid = yScale.ticks().map(tick => yScale(tick));

  return (
    <g className="grid">
      {verticalGrid.map((c, i) => (
        <line key={`x-${i}`} x1={c} x2={c} y1={0} y2={height} />
      ))}
      {horizontalGrid.map((c, i) => (
        <line key={`y-${i}`} x1={0} x2={width} y1={c} y2={c} />
      ))}
    </g>
  );
};

Grid.propTypes = {
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default Grid;
