export const parseMatchGoals = match => {
  if (!match) {
    return {
      isExtraTimeGoals: 0,
      isPenaltyGoals: 0,
      homegoals: 0,
      awaygoals: 0,
      infoText: '',
      fullText: '',
    };
  }

  const isExtraTimeGoals = match.homegoals_et || match.awaygoals_et;
  const isPenaltyGoals = match.homegoals_p || match.awaygoals_p;

  let homegoals = isExtraTimeGoals ? match.homegoals_et : match.homegoals;
  let awaygoals = isExtraTimeGoals ? match.awaygoals_et : match.awaygoals;

  if (isPenaltyGoals) {
    homegoals += match.homegoals_p;
    awaygoals += match.awaygoals_p;
  }

  const infoText = isPenaltyGoals ? 'on pens' : isExtraTimeGoals ? 'AET' : '';
  const fullText = `${homegoals} : ${awaygoals} ${infoText}`;

  return {
    isExtraTimeGoals,
    isPenaltyGoals,
    homegoals,
    awaygoals,
    infoText,
    fullText,
  };
};
