import React from 'react';
import { Status } from '../../../../../../../reducers/overview/types';
import Icon from '../../../../../../ui/svg-icon';

interface Props {
  status: Status;
}

export function CheckedIcon(props: Props) {
  const { status } = props;

  switch (status) {
    case 'checked':
      return <Icon name="overview-checkbox" className="of-cb-checked" />;
    case 'unchecked':
      return (
        <Icon name="overview-checkbox-unchecked" className="of-cb-unchecked" />
      );
    case 'indeterminate':
      return (
        <Icon
          name="overview-checkbox-intermediate"
          className="of-cb-intermediate"
        />
      );
  }

  return null;
}
