import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import _ from 'lodash';
import { ColorScale, PolarChart } from 'd3-react-touch';

import Image from '../../../ui/image';
import { DataItem, DataList, DataRank } from '../../../ui/data-item';
import Icon from '../../../ui/svg-icon';
import Position from './summary/position';
import BarChartGroup from '../../modules/bar-chart-group';
import Header from '../header';
import NoData from '../../layouts/no-data';
import { database } from '../../../../constants/database';

import * as DetailsActions from '../../../../actions/details';
import {
  addToFavorite,
  loading,
  removeFromFavorite,
} from '../../../../actions/app';
import lang from '../../../../lang';
import {
  createBarChartsBenchmark,
  createPolarCharts,
  getGetOrdinal,
  getPlayerPerformanceFromAnalyse,
  getPlayerPerformanceText,
} from '../../../../helper/';
import { BENCHMARK_COLOR, COLOR_SCALE } from '../../../../constants';

class Player extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPopoverOpen: false,
      hoveredIndicator: null,
    };

    this.renderBarCharts = this.renderBarCharts.bind(this);
    this.renderProfile = this.renderProfile.bind(this);
    this.renderSkillText = this.renderSkillText.bind(this);
  }

  renderBarCharts() {
    const { playersMetaData } = this.props.app;
    const {
      information,
      mean,
      epiRating,
      minCondition,
      performance,
      current_season,
      current_league,
      current_position,
    } = this.props.details;
    const bars_vals = ['season_rating', 'stability', 'defence', 'offence'];

    const playerCanBeBenchmark = +performance.mins >= +minCondition;

    const renderBenchmarkName = id =>
      playersMetaData[id] && typeof playersMetaData[id].last_name === 'string'
        ? playersMetaData[id].last_name.slice(0, 8)
        : '';
    const bars = createBarChartsBenchmark(
      {
        ...getPlayerPerformanceFromAnalyse(
          epiRating,
          information,
          bars_vals,
          playerCanBeBenchmark,
        ),
        ...mean,
      },
      bars_vals,
      true,
      playerCanBeBenchmark,
    );
    const width = 120,
      height = 50,
      margin = {
        top: 13,
        right: 0,
        bottom: 1,
        left: 0,
      };
    let animationDelayCounter = 0;

    const renderGroup = group =>
      group.map((bar, b) => {
        animationDelayCounter++;

        return (
          <div className="col-6" key={b}>
            <BarChartGroup
              {...{
                data: bar,
                width,
                height,
                margin,
                indicator: bar.indicator,
                link: `/details/player/:id/${current_league}/${current_season.join(
                  '/',
                )}/summary${
                  current_position ? `?position=${current_position}` : ''
                }`,
                name: information.last_name,
                renderBenchmarkName,
                animationDelay: 1000 + animationDelayCounter * 200,
              }}
            />
          </div>
        );
      });

    return _.chunk(bars, 2).map((group, g) => (
      <div className="row first_el" key={g}>
        {renderGroup(group)}
      </div>
    ));
  }

  renderRows() {
    const {
      mean,
      information,
      realStats,
      minCondition,
      performance,
      current_season,
      current_league,
      current_position,
    } = this.props.details;
    const { playersMetaData } = this.props.app;

    const playerCanBeBenchmark = +performance.mins >= +minCondition;

    const bars_against_indi = [
      'real_tackles',
      'real_tackles_pc_',
      'real_aerial_duels',
      'real_aerial_duels_pc_',
      'real_clearances',
      'real_interception',
      'real_blocks',
    ];
    const bars_against = createBarChartsBenchmark(
      {
        ...getPlayerPerformanceFromAnalyse(
          realStats,
          information,
          bars_against_indi,
          playerCanBeBenchmark,
        ),
        ...mean,
      },
      bars_against_indi,
      true,
      playerCanBeBenchmark,
    );

    const bars_onball_indi = [
      'real_free_kicks',
      'real_corners',
      'real_dribblings',
      'real_dribblings_pc_',
      'real_shots',
      'real_in_box_shots',
      'real_goal_conversion',
    ];
    const bars_onball = createBarChartsBenchmark(
      {
        ...getPlayerPerformanceFromAnalyse(
          realStats,
          information,
          bars_onball_indi,
          playerCanBeBenchmark,
        ),
        ...mean,
      },
      bars_onball_indi,
      true,
      playerCanBeBenchmark,
    );

    const bars_pass_indi = [
      'real_key_passes',
      'real_passes',
      'real_pass_pc_',
      'real_crosses',
      'real_long_balls',
      'real_through_balls',
      'real_chances_created',
    ];
    const bars_pass = createBarChartsBenchmark(
      {
        ...getPlayerPerformanceFromAnalyse(
          realStats,
          information,
          bars_pass_indi,
          playerCanBeBenchmark,
        ),
        ...mean,
      },
      bars_pass_indi,
      true,
      playerCanBeBenchmark,
    );

    const width = 115,
      height = 50,
      margin = {
        top: 13,
        right: 0,
        bottom: 1,
        left: 0,
      };
    let animationDelayCounter = 0;

    const rows = [
      {
        title: lang.againstball,
        bars: bars_against,
      },
      {
        title: lang.onball,
        bars: bars_onball,
      },
      {
        title: lang.passing,
        bars: bars_pass,
      },
    ];

    const renderBenchmarkName = id =>
      playersMetaData[id] && typeof playersMetaData[id].last_name === 'string'
        ? playersMetaData[id].last_name.slice(0, 8)
        : '';

    return rows.map((row, i) => (
      <div className="bar_chart_group" key={i}>
        <h1 className="line">{row.title}</h1>
        {_.chunk(row.bars, 2).map((group, g) => (
          <div className="row first_el" key={g}>
            {group.map((bar, i) => {
              animationDelayCounter++;
              return (
                <div key={i} className="col-6">
                  <div className="center">
                    <BarChartGroup
                      {...{
                        data: bar,
                        width,
                        height,
                        margin,
                        link: `/details/player/:id/${current_league}/${current_season.join(
                          '/',
                        )}/summary${
                          current_position
                            ? `?position=${current_position}`
                            : ''
                        }`,
                        name: information.last_name,
                        renderBenchmarkName,
                        animationDelay: 1000 + animationDelayCounter * 200,
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    ));
  }

  renderProfile() {
    const {
      id,
      current_league,
      current_season,
      performance_current,
      information,
      league_analyse,
      actual_performance,
    } = this.props.details;
    const { leagues } = this.props.app;

    const { history, position } = this.props.details;
    const { teams, positions } = this.props.app;

    let history_recent = !_.isEmpty(history)
      ? _.filter(_.cloneDeep(history), o => {
          return o.season_id == current_season && o.league_id == current_league;
        })
      : {};
    history_recent = !_.isEmpty(history_recent) ? history_recent[0] : {};
    history_recent.team_name = !_.isEmpty(history_recent)
      ? _.get(teams, `${history_recent.team_id}.team_name`, '')
      : '';
    history_recent.league_name = !_.isEmpty(history_recent)
      ? _.get(leagues, `${history_recent.league_id}.name`, '')
      : '';

    const url = history_recent.team_id
      ? `${database.assets}teams/${history_recent.team_id}.jpg`
      : '';
    const urlComp = history_recent.league_id
      ? `${database.assets}leagues/${history_recent.league_id}.png`
      : '';
    const team_name = history_recent.team_name;

    return (
      <div className="content_group summary_profile">
        <div className="h4 background">
          <span>{lang.profile}</span>
        </div>

        <Position
          id={id}
          performance={_.find(league_analyse, { player_id: +id }) || {}}
          position={position}
          positions={positions}
          actual_performance={actual_performance}
        />

        <DataList className="row-new">
          <DataItem className="col-new-6" desc={lang.team_name}>
            <Link
              to={`/details/team/${history_recent.team_id}/summary`}
              className="data-text"
            >
              {history_recent.team_id ? (
                <Image src={url} aspectRatio={0.769} />
              ) : (
                ''
              )}{' '}
              <span>{team_name}</span>
            </Link>
          </DataItem>
          <DataItem className="col-new-6" desc={lang.league}>
            <span className="data-text">
              {urlComp ? <Image src={urlComp} aspectRatio={0.769} /> : ''}{' '}
              {history_recent.league_name ? history_recent.league_name : ''}
            </span>
          </DataItem>
          <DataItem
            className="col-new-3"
            text={performance_current.games}
            desc={lang.games}
          />
          <DataItem
            className="col-new-3"
            text={performance_current.mins}
            desc={lang.mins}
          />
          <DataItem
            className="col-new-3"
            text={performance_current.goals}
            desc={lang.goals}
          />
          <DataItem
            className="col-new-3"
            text={performance_current.assists}
            desc={lang.assists}
          />
          <DataItem className="col-new-12" desc="External links">
            <div className="external_links">
              <a
                href={`${database.youtubeSearch}${information.youtube_link}`}
                target="_blank"
                className="details-youtube-action"
              >
                <Icon name="youtube" width="29px" height="22px" />
              </a>
              {information.tm_link ? (
                <a
                  href={`${database.transfermarkt}${information.tm_link}`}
                  target="_blank"
                  className="details-transfermarkt-action"
                />
              ) : null}
              <a
                href={`http://sp7f.com/?url=${encodeURIComponent(
                  `${database.googleNews + information.first_name} ${
                    information.last_name
                  }${
                    information.team_name && information.team_name.length
                      ? ` ${information.team_name}`
                      : ''
                  }&source=lnms&tbm=nws`,
                )}`}
                className="details-google-news-action"
                target="_blank"
              >
                <img src="/public/img/google_news.svg" />
              </a>
            </div>
          </DataItem>
        </DataList>
      </div>
    );
  }

  renderSkillText(type, indicator) {
    const { isPopoverOpen } = this.state;
    const betterReadable = d =>
      d.replace('Off.', 'Offence').replace('Def.', 'Defence');
    return (
      <span
        className={`data_point ${type} indicator_${indicator}`}
        data-tooltip-content={indicator}
        data-tooltip-id="Indicator"
      >
        {betterReadable(lang[indicator])}
      </span>
    );
  }

  render() {
    const {
      current_league,
      current_season,
      performance_current,
      information,
      mean,
      epiRating,
      performance,
      minCondition,
    } = this.props.details;
    const { seasons, leagues } = this.props.app;
    const { isPopoverOpen, hoveredIndicator } = this.state;

    const playerCanBeBenchmark = +performance.mins >= +minCondition;

    const season_active = current_season || performance_current.season_id;

    const comb =
      information && information.data
        ? information.data.replace(/ /g, '').split(',')
        : [];

    let season_available = [parseInt(season_active, 10)];
    let league_available = [];
    _.each(comb, d => {
      const v = d.split(':');
      let y = v[0],
        l = v[1];
      const found = _.findIndex(season_available, o => o == y);
      if (found == -1) season_available.push(parseInt(y, 10));
      if (y == season_active) league_available.push(parseInt(l, 10));
    });

    season_available = _.sortBy(season_available);
    league_available = _.sortBy(league_available);

    const season_options = [];
    let season_defaultOption = null;
    _.each(season_available, d => {
      let season_active_label = seasons.filter(s => s.id == d);
      season_active_label = _.get(season_active_label, '[0].name', '');
      season_options.push({ value: d, label: season_active_label });

      if (season_active == d)
        season_defaultOption = season_options[season_options.length - 1];
    });

    const league_options = [];
    let league_defaultOption = null;
    _.each(league_available, d => {
      league_options.push({
        value: d,
        label: leagues[d] ? _.get(leagues, `${d}.name`, '') : '',
      });
      if (current_league == d)
        league_defaultOption = league_options[league_options.length - 1];
    });

    const { position } = this.props.details;

    const goalkeeper = position[0] && +position[0].position_id === 10;

    let radars_vals_against = [
      'tackle_defence',
      'tackle_offence',
      'intercept_defence',
      'intercept_offence',
      'blocked',
      'save',
      'aerial_defence',
      'aerial_offence',
    ];
    if (goalkeeper)
      radars_vals_against = [
        'intercept_defence',
        'save',
        'claim',
        'keeper_throw',
        'pass_long',
        'pass_short',
        'reception',
        'delivery',
      ];
    const radars_against = createPolarCharts(
      {
        ...getPlayerPerformanceFromAnalyse(
          epiRating,
          information,
          radars_vals_against,
          playerCanBeBenchmark,
        ),
        ...mean,
      },
      radars_vals_against,
      true,
      playerCanBeBenchmark,
    );

    const radars_vals_on = [
      'take_on',
      'crossing',
      'pass_short',
      'pass_long',
      'reception',
      'delivery',
      'scoring',
      'scoring_effort',
    ];
    const radars_onball = createPolarCharts(
      {
        ...getPlayerPerformanceFromAnalyse(
          epiRating,
          information,
          radars_vals_on,
          playerCanBeBenchmark,
        ),
        ...mean,
      },
      radars_vals_on,
      true,
      playerCanBeBenchmark,
    );

    // getting data for bar charts
    // --------------------------------
    let radar_width = 290,
      radar_height = 250,
      radar_margin = {
        top: 30,
        right: 30,
        bottom: 17,
        left: 30,
      };

    let indicatorsAll = [].concat.apply(
      [],
      [['season_rating'], radars_vals_against, radars_vals_on],
    );
    const playerData = getPlayerPerformanceText(
      epiRating,
      information,
      indicatorsAll,
      mean,
    );

    if (!epiRating || !epiRating.length) {
      return (
        <div>
          <Header />
          <div className="content-container">
            <div className="summary">
              {this.renderProfile()}
              <NoData />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Header />
        <div className="content-container">
          <div className="summary">
            {this.renderProfile()}

            <div className="content_group summary_data">
              <div className="h4 background">
                <Icon name="ranking-winner" width="33px" height="28px" />
                <span>{lang.ownstats}</span>
              </div>

              <div className="summary-text">
                <div className="row">
                  <div className="col-12">
                    {information.last_name && playerData.season_rating ? (
                      <div>
                        <span>
                          {information.last_name
                            ? information.last_name
                            : 'N/D'}
                        </span>
                        &nbsp;
                        <span>{lang.epi_text_summary_part_1}</span>&nbsp;
                        <span className="data_point">
                          {playerData.season_rating
                            ? playerData.season_rating
                            : 'N/D'}
                        </span>
                        &nbsp;
                        <span>{lang.epi_text_summary_part_2}</span>&nbsp;
                        <span className="data_point">
                          {playerData.season_rating_rank
                            ? playerData.season_rating_rank +
                              getGetOrdinal(+playerData.season_rating_rank)
                            : 'N/D'}
                        </span>
                        &nbsp;
                        <span>{lang.epi_text_summary_part_3}</span>&nbsp;
                        <span className="data_point">
                          {+performance.mins ? +performance.mins : 'N/D'}
                        </span>
                        &nbsp;
                        <span>{lang.epi_text_summary_part_4}</span>.
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-10">
                    {playerData.strengths && playerData.strengths.length > 0 ? (
                      <div>
                        <span>{`${lang.epi_text_swot_part_1} `}</span>
                        <Icon
                          name="plus-circle"
                          width="13px"
                          height="13px"
                          className="strengths"
                        />
                        <span className="strengths">{`${
                          playerData.strengths.length > 1
                            ? lang.epi_text_swot_part_2_1
                            : lang.epi_text_swot_part_2_2
                        } `}</span>
                        <span>{`${
                          playerData.strengths.length > 1
                            ? lang.epi_text_swot_part_3
                            : lang.epi_text_swot_part_4
                        } `}</span>
                        {playerData.strengths.length > 0
                          ? this.renderSkillText(
                              'strengths',
                              playerData.strengths[0].indicator,
                            )
                          : null}
                        {playerData.strengths.length > 2
                          ? ', '
                          : playerData.strengths.length === 2
                          ? ` ${lang.epi_text_swot_part_5} `
                          : null}
                        {playerData.strengths.length > 1
                          ? this.renderSkillText(
                              'strengths',
                              playerData.strengths[1].indicator,
                            )
                          : null}
                        <span>
                          {playerData.strengths.length > 2
                            ? ` ${lang.epi_text_swot_part_5} `
                            : null}
                        </span>
                        {playerData.strengths.length > 2
                          ? this.renderSkillText(
                              'strengths',
                              playerData.strengths[2].indicator,
                            )
                          : null}
                        .
                      </div>
                    ) : null}

                    {playerData.weaknesses &&
                    playerData.weaknesses.length > 0 ? (
                      <div>
                        <span>{`${lang.epi_text_swot_part_1} `}</span>
                        <Icon
                          name="minus-circle"
                          width="13px"
                          height="13px"
                          className="weaknesses"
                        />
                        <span className="weaknesses">{`${
                          playerData.weaknesses.length > 1
                            ? lang.epi_text_swot_part_6_1
                            : lang.epi_text_swot_part_6_2
                        } `}</span>
                        <span>{`${
                          playerData.weaknesses.length > 1
                            ? lang.epi_text_swot_part_3
                            : lang.epi_text_swot_part_4
                        } `}</span>
                        {playerData.weaknesses.length > 0
                          ? this.renderSkillText(
                              'weaknesses',
                              playerData.weaknesses[0].indicator,
                            )
                          : null}
                        {playerData.weaknesses.length > 2
                          ? ', '
                          : playerData.weaknesses.length === 2
                          ? ` ${lang.epi_text_swot_part_5} `
                          : null}
                        {playerData.weaknesses.length > 1
                          ? this.renderSkillText(
                              'weaknesses',
                              playerData.weaknesses[1].indicator,
                            )
                          : null}
                        <span>
                          {playerData.weaknesses.length > 2
                            ? ` ${lang.epi_text_swot_part_5} `
                            : null}
                        </span>
                        {playerData.weaknesses.length > 2
                          ? this.renderSkillText(
                              'weaknesses',
                              playerData.weaknesses[2].indicator,
                            )
                          : null}
                        .
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="charts_bar">
                {this.renderBarCharts()}
                <div className="row first_el">
                  <div className="col-12">
                    <div className="chart-legend">
                      <div className="chart-legend-list">
                        <div className="chart-legend-list-row">
                          <div className="chart-legend-list-item">
                            <ColorScale
                              domain={radars_onball.yDomain}
                              colors={COLOR_SCALE}
                              benchmarkColor={BENCHMARK_COLOR}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {playerCanBeBenchmark ? null : (
                    <div className="theoretical-ranks">{`* Theoretical rank, because the player has played less than ${minCondition} minutes.`}</div>
                  )}
                </div>

                <div className="row">
                  <DataRank
                    className="col-6"
                    title={lang.player_league}
                    rank={performance_current.rank_league}
                    desc={`of ${performance_current.rank_league_max} players`}
                  />
                  <DataRank
                    className="col-6"
                    title={lang.player_team}
                    rank={performance_current.rank_team}
                    desc={`of ${performance_current.rank_team_max} players`}
                  />
                </div>
              </div>

              <div className="charts_radar">
                <div className="chart_group radar radar_1">
                  <PolarChart
                    width={radar_width}
                    animation
                    animationDelay={1000}
                    height={radar_height}
                    margin={radar_margin}
                    tooltips={radars_against.tooltips}
                    classNames={radars_against.classes}
                    data={radars_against.items}
                    Domain={radars_against.xDomain}
                    colors={COLOR_SCALE}
                    interpolationType="linear-closed"
                  />
                </div>
                <div
                  className={`chart_group radar radar_2 ${
                    goalkeeper ? 'hide' : ''
                  }`}
                >
                  <PolarChart
                    width={radar_width}
                    animation
                    animationDelay={1400}
                    height={radar_height}
                    margin={radar_margin}
                    tooltips={radars_onball.tooltips}
                    classNames={radars_onball.classes}
                    data={radars_onball.items}
                    xDomain={radars_onball.xDomain}
                    colors={COLOR_SCALE}
                    interpolationType="linear-closed"
                  />
                </div>
              </div>
            </div>

            <div className="content_group">
              <div className="row">
                <div className="h4 background">
                  <span>{lang.optastats}</span>
                  <span className="description">Values per 90 minutes</span>
                </div>
              </div>

              {this.renderRows()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    details: state.details,
    app: state.app,
    overview: state.overview,
  }),
  {
    ...DetailsActions,
    loading,
    addToFavorite,
    removeFromFavorite,
  },
)(Player);
