import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import d3 from 'd3';
import moment from 'moment';

import Tooltip from './tooltip';
import OverviewLegend from '../layouts/legend';
import { ScatterPlot } from './ScatterPlot';

import {
  lassoSubSelection,
  changeScatterplotLegend,
  changeScatterplotDomain,
  setActivePointData,
} from '../../../actions/overview';

class Overview extends Component {
  static propTypes = {
    setActivePointData: PropTypes.func,
    meanByPositionGroups: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      lasso: false,
    };

    this.updateLegend = this.updateLegend.bind(this);
    this.renderLassoSelection = this.renderLassoSelection.bind(this);
    this.onPointSelect = this.onPointSelect.bind(this);
  }

  componentWillMount() {
    this.updateLegend({});
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.app.loaded &&
      this.props.overview.player.scatterplot.xDomain === null
    ) {
      this.resizeScatterplot(nextProps);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.filteredData.length !== prevProps.filteredData.length) {
      this.updateLegend({});
    }
  }

  resizeScatterplot(nextProps) {
    const { changeScatterplotDomain } = nextProps;
    const { xVal, yVal, rVal, cVal } = nextProps.overview.player.scatterplot;

    const xDomain = d3.extent(nextProps.filteredData, d => +d[xVal]);
    const yDomain = d3.extent(nextProps.filteredData, d => +d[yVal]);
    const rDomain = d3.extent(nextProps.filteredData, d => +d[rVal]);
    const cDomain = d3.extent(nextProps.filteredData, d => +d[cVal]);

    changeScatterplotDomain({
      xDomain,
      yDomain,
      rDomain,
      cDomain,
      mode: 'player',
    });
  }

  updateLegend(e) {
    const { filteredData: data } = this.props;
    let { xVal, yVal, rVal, cVal, xDomain, yDomain, rDomain, cDomain } =
      this.props.overview.player.scatterplot;

    const nowYear = parseInt(moment().year(), 10);
    const contractDomain = [nowYear - 2000, nowYear + 6 - 2000];

    switch (e.id) {
      case 'x':
        xVal = e.val;
        xDomain =
          e.val !== 'contract_expiry_year'
            ? d3.extent(data, d => +d[e.val])
            : contractDomain;
        break;
      case 'y':
        yVal = e.val;
        yDomain =
          e.val !== 'contract_expiry_year'
            ? d3.extent(data, d => +d[e.val])
            : contractDomain;
        break;
      case 'r':
        rVal = e.val;
        rDomain =
          e.val !== 'contract_expiry_year'
            ? d3.extent(data, d => +d[e.val])
            : contractDomain;
        break;
      case 'c':
        cVal = e.val;
        cDomain =
          e.val !== 'contract_expiry_year'
            ? d3.extent(data, d => +d[e.val])
            : contractDomain;
        break;
    }

    if (e.id !== 'x')
      xDomain =
        xVal !== 'contract_expiry_year'
          ? d3.extent(data, d => +d[xVal])
          : contractDomain;
    if (e.id !== 'y')
      yDomain =
        yVal !== 'contract_expiry_year'
          ? d3.extent(data, d => +d[yVal])
          : contractDomain;
    if (e.id !== 'r')
      rDomain =
        rVal !== 'contract_expiry_year'
          ? d3.extent(data, d => +d[rVal])
          : contractDomain;
    if (e.id !== 'c')
      cDomain =
        cVal !== 'contract_expiry_year'
          ? d3.extent(data, d => +d[cVal])
          : contractDomain;

    // reset selection, close popup if scale has been changed
    this.props.setOverviewState({ selectItem: undefined, tooltipOpen: false });

    // change scatterplot
    this.props.changeScatterplotLegend({
      xVal,
      yVal,
      rVal,
      cVal,
      xDomain,
      yDomain,
      rDomain,
      cDomain,
      mode: 'player',
    });
  }

  renderLassoSelection(ids) {
    this.props.lassoSubSelection(ids, 'player');
  }

  onPointSelect(data) {
    const { setActivePointData } = this.props;

    setActivePointData({
      mode: 'player',
      data,
      table: false,
    });
  }

  render() {
    const {
      filteredData,
      toggleLegend,
      clientHeight,
      tooltipOpen,
      meanByPositionGroups,
    } = this.props;
    const { lasso } = this.state;

    const activePointData = this.props.overview.activePointData.player;
    const isTable = this.props.overview.activePointData.table;

    if (!filteredData) return null;

    const {
      xDomain,
      yDomain,
      rDomain,
      cDomain,
      xVal,
      yVal,
      rVal,
      cVal,
      cRange,
      legend,
      legendList,
    } = this.props.overview.player.scatterplot;
    const favorites = this.props.app.favorites.player;

    const { contentWidth, visualHeight, tableHeight } =
      this.props.overview.player.layouts;
    const legendClassNames = {
      yVal: 'content_legendLeft',
      xVal: 'content_legendX',
      cVal: 'content_legendCol',
      rVal: 'content_legendRad',
    };

    const maxItems = isTable ? 0 : 100;

    return (
      <div
        className="overview_scatterplot"
        style={{
          bottom: `${
            clientHeight - tableHeight + (visualHeight > 200 ? 60 : 0)
          }px`,
          opacity: tableHeight === clientHeight ? 0 : 1,
          visibility: tableHeight === clientHeight ? 'hidden' : 'visible',
        }}
      >
        <div className="scatterplot_container">
          <ScatterPlot
            xDomain={[0, Math.max.apply(null, [10, xDomain ? xDomain[1] : 10])]}
            yDomain={[0, Math.max.apply(null, [10, yDomain ? yDomain[1] : 10])]}
            rDomain={rDomain}
            cDomain={cDomain}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 65,
            }}
            rRange={[3, 11]}
            cStrokeWidth="0.1"
            cStrokeColor="#8daac8"
            rSelectedColor="#ffffff"
            width={contentWidth ? contentWidth - 30 : 0}
            height={visualHeight ? visualHeight - 20 : 0}
            data={filteredData}
            xVal={xVal}
            yVal={yVal}
            rVal={rVal}
            cVal={cVal}
            cRange={['Viridis']}
            cScale="sequential"
            rScale="sqrt"
            showTooltipOnHover={maxItems}
            showTooltip
            showLasso={lasso}
            lassoSelection={d => this.renderLassoSelection(d)}
            onPointSelect={this.onPointSelect}
            activePointData={activePointData}
            tooltipContent={
              <Tooltip meanByPositionGroups={meanByPositionGroups} />
            }
          />

          <OverviewLegend
            onChange={this.updateLegend}
            legendClassNames={legendClassNames}
            xVal={xVal}
            yVal={yVal}
            rVal={rVal}
            cVal={cVal}
            cDomain={cDomain}
            cRange={cRange}
            rDomain={rDomain}
            legend={legend}
            toggleLegend={toggleLegend}
            tooltipOpen={tooltipOpen}
            legendList={legendList}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    overview: state.overview,
    app: state.app,
  }),
  {
    lassoSubSelection,
    changeScatterplotLegend,
    changeScatterplotDomain,
    setActivePointData,
  },
)(Overview);
