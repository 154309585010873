import React, { ChangeEvent, ReactNode, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { List } from 'react-virtualized';
import _ from 'lodash';
import { AutoSizer } from 'react-virtualized';

import { RootState } from '../../../../../../types';
import { ModeType } from '../../../../../../types/Dict';
import {
  FlattenListItem,
  ListType,
} from '../../../../../../reducers/overview/types';
import { ListItem } from './ListItem';
import Icon from '../../../../../ui/svg-icon';
import lang from '../../../../../../lang';
import { removeDiacritics } from '../../../../../../helper';
import { selectFlattenListFilter } from '../../../../../../reducers/overview/selectors';
import { selectCollapseList } from '../../slice/selectors';
import { FavoriteProps } from '../../LeaguesFilter/types';

interface CommonProps {
  mode: ModeType;
  height?: number;
  listName: ListType;
  withSearch?: boolean;
  itemRenderer?: (item: FlattenListItem) => ReactNode;
}

type Props = CommonProps & FavoriteProps;

export const ListField = (props: Props) => {
  const { listName, withSearch = false, mode, itemRenderer, height } = props;
  const [searchValue, setSearchValue] = useState('');

  let flattenOptions = useSelector((state: RootState) =>
    selectFlattenListFilter(state, {
      listName,
      mode,
      ignoreOpen: !!searchValue,
    }),
  );
  const collapseList = useSelector((state: RootState) =>
    selectCollapseList(state, { mode, listName }),
  );

  if (searchValue) {
    flattenOptions = flattenOptions.filter(option => {
      if (option.type === 'group') return false;
      const name = removeDiacritics(option.name.toLowerCase());
      const searchString = searchValue.toLowerCase();
      const searchWords = searchString.split(' ');

      if (searchWords.length > 1) {
        return (
          name.indexOf(searchWords[0]) >= 0 && name.indexOf(searchWords[1]) >= 0
        );
      }

      return name.indexOf(searchString) !== -1;
    });

    flattenOptions = _.orderBy(flattenOptions, 'name');
  }

  const rowRenderer = ({ index, style }) => {
    const item = flattenOptions[index];

    return (
      <div key={`${index}-${item.id}`} style={style}>
        {itemRenderer ? (
          itemRenderer(item)
        ) : (
          <ListItem mode={mode} listName={listName} item={item} />
        )}
      </div>
    );
  };

  const onSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }, []);

  return (
    <div className="filter_list_long">
      {withSearch ? (
        <div className="search-input-container">
          <Icon name="overview-search" className="of-search" />
          <input
            type="text"
            value={searchValue}
            id="searchinput"
            placeholder={lang[`search_${listName}`]}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={onSearchChange}
          />
        </div>
      ) : null}

      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            width={width}
            height={height || collapseList?.height || 0}
            rowCount={flattenOptions.length}
            rowHeight={36}
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </div>
  );
};
