/*
 * Style for 404, should never be visible
 */

import React, { Component } from 'react';

class Error404 extends Component {
  render() {
    return (
      <div className=" error">
        <div className="error-container">
          <div className="error-centered">
            <h1 className="page-title">Page not found</h1>
            <br />
            <p className="lead">
              Sorry, we've misplaced that URL or it's pointing to something that
              does not exist.
              <br />
              Please contact support@scoutpanel.com
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Error404;
