import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router';

import { ScatterPlot } from 'd3-react-touch';
import lang from '../../../lang';
import Image from '../../ui/image';
import { DataItem } from '../../ui/data-item';
import Icon from '../../ui/svg-icon';

import { parseMatchGoals } from '../../../helper/goals';
import { database } from '../../../constants/database';

class History extends Component {
  renderLineChart() {
    const { current_season, current_league, history_details } =
      this.props.details;
    const { leagues, teams } = this.props.app;

    const lines = [];
    let values = [];
    let valuesMean = [];

    const league_format = _.get(leagues, `[${current_league}].format_id`, null);
    let x_axis = 'numeric';

    const competition_formats = [2, 3, 4, 5, 7];
    let timeline = history_details.filter(
      datum =>
        +current_league === +datum.league_id &&
        +current_season === +datum.season_id,
    );

    if (competition_formats.includes(+league_format)) {
      x_axis = 'dates';
      timeline = timeline.map(datum => {
        return {
          ...datum,
          matchday: moment(datum.date).toDate(),
        };
      });
    }

    timeline = _.orderBy(timeline, ['matchday'], ['asc']);
    const timeline_vals = ['rating', 'defence', 'offence'];

    const current_league_data = !_.isEmpty(timeline)
      ? leagues[current_league]
      : null;

    // get total amount of matchdays, manually if last 5 matches etc
    let matchday;
    if (x_axis === 'numeric') {
      if (current_season > 2000)
        matchday = _.get(current_league_data, 'matchday', 1);
      else
        matchday =
          +current_season === 1000
            ? 5
            : +current_season === 1001
            ? 10
            : +current_season === 1002
            ? 20
            : 1;
    } else if (current_season > 2000) matchday = timeline.length;
    else
      matchday =
        +current_season === 1000
          ? 5
          : +current_season === 1001
          ? 10
          : +current_season === 1002
          ? 20
          : 1;

    timeline_vals.map((indicator, j) => {
      values = [];

      /* TODO: REFACTOR THIS SHAME */
      if (matchday) {
        for (let i = 1; i <= matchday; i++) {
          let index = -1;
          if (x_axis === 'numeric') {
            _.each(timeline, (data, idx) => {
              if (_.isEqual(parseInt(data.matchday, 10), parseInt(i, 10))) {
                index = idx;
              }
            });
          } else {
            index = i - 1;
          }

          const match = timeline[index];

          if (!match) {
            continue;
          }

          const { homegoals, awaygoals, fullText } = parseMatchGoals(match);
          const place = match.place === 0 ? 'H' : 'A';

          const goalsClassName =
            homegoals === awaygoals
              ? 'blue'
              : place === 'H'
              ? homegoals > awaygoals
                ? 'green'
                : 'red'
              : homegoals > awaygoals
              ? 'red'
              : 'green';

          const goals =
            (homegoals || +homegoals === 0) &&
            (awaygoals || +awaygoals === 0) ? (
              <tspan className={goalsClassName}>{fullText}</tspan>
            ) : null;

          if (index > -1) {
            const team_name = !_.isEmpty(timeline)
              ? _.get(
                  teams,
                  `${timeline[index].opponent_id}.short_club_name`,
                  '',
                )
              : '';

            const x_label =
              x_axis === 'numeric'
                ? `${parseInt(
                    timeline[index].matchday,
                    10,
                  )}. ${place} - ${team_name}${goals ? ', ' : ''}`
                : `${moment(timeline[index].matchday).format(
                    'DD.MM',
                  )}. ${place} - ${team_name}${goals ? ', ' : ''}`;

            values.push({
              x: x_label,
              y: timeline[index][indicator],
              link: (
                <Link
                  to={`/details/match/${timeline[index].match_id}/${timeline[index].league_id}/${timeline[index].season_id}/summary`}
                />
              ),
              goals,
            });
          } else {
            values.push({ x: `${i}`, y: undefined });
          }
        }
      }

      lines.push({
        name: '',
        class: timeline_vals[j],
        values,
      });
    });

    // add average values
    if (matchday) {
      valuesMean = [];
      lines[0].values.map((d, j) => {
        valuesMean.push({ x: d.x, y: 5 });
      });

      lines.push({
        name: lang.rating_mean,
        class: 'rating_mean',
        values: valuesMean,
      });
    }

    // // draw average underneath
    // lines.unshift( lines[ lines.length-1 ] )
    // lines.pop()

    // create data for scatterplot
    let scatterData = [];
    lines.map((d, j) => {
      d.values.map((e, g) => {
        scatterData.push({
          x: e.x,
          y: e.y,
          c: j,
          r: j != 3 ? 2 : 0,
          link: e.link,
          goals: e.goals,
        });
      });
    });

    scatterData = _.orderBy(scatterData, ['c', 'r'], ['asc', 'asc']);

    let width = document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.innerWidth;
    width -= width * 0.1;

    // get league and team names
    const team_name = !_.isEmpty(timeline)
      ? _.get(teams, `${timeline[0].team_id}.team_name`, '')
      : '';
    const league_name = !_.isEmpty(timeline)
      ? _.get(leagues, `${timeline[0].league_id}.name`, '')
      : '';
    const season_name =
      current_season.length === 1
        ? current_season[0]
        : current_season
            .map(seasonId =>
              _.get(
                lang,
                `_${seasonId}`,
                moment(seasonId, 'YYYY-MM-DD').format('D MMM ’YY'),
              ),
            )
            .join(' – ');

    return (
      <div>
        <h3>
          {season_name}, {league_name}, {team_name}
        </h3>

        <div className="legend">
          <DataItem
            className="rating"
            text={timeline.length > 0 ? timeline[0].rating : ''}
            desc={lang.rating}
          />
          <DataItem
            className="defence"
            text={timeline.length > 0 ? timeline[0].defence : ''}
            desc={lang.defence}
          />
          <DataItem
            className="offence"
            text={timeline.length > 0 ? timeline[0].offence : ''}
            desc={lang.offence}
          />
          <DataItem className="mean" text={5} desc={lang.rating_mean} />
        </div>

        <ScatterPlot
          yDomain={[0, 10.1]}
          rDomain={[0, 2]}
          cDomain={[0, 1, 2, 3]}
          margin={{
            top: 30,
            right: 130,
            bottom: 135,
            left: 50,
          }}
          id="id"
          width={width}
          xVal="x"
          yVal="y"
          cVal="c"
          rVal="r"
          cRange={['#1D9879', '#9f51c0', '#efa32e', '#d9d9d9']}
          rRange={[0, 4]}
          data={scatterData}
          height={420}
          yGrid={false}
          xScale="ordinal"
          xTicksRotate
          yticks={[10]}
          yDomainNice={false}
          connectDots
          zooming={false}
          connectDotsInterpolationType="monotone"
          clicking={false}
        />

        <div className="axis_label">
          <div className="axisLeft">{lang.rating}</div>
        </div>
      </div>
    );
  }

  render() {
    const { history, isAccessGranted } = this.props.details;
    const { leagues, teams, seasons } = this.props.app;

    // get only data for real season years and not last x matches
    let history_seasons = _.cloneDeep(history).filter(
      datum => datum.season_id > 2000,
    );
    history_seasons = _.orderBy(
      history_seasons,
      [o => o.season_id, o => leagues[o.league_id].format_id],
      ['desc', 'asc'],
    );

    return (
      <div className={`history ${!isAccessGranted ? 'blurred' : ''}`}>
        <div className="content_group history_overtime" ref="history_overtime">
          <div className="row">
            <div className="h4">
              <Icon name="ranking-winner" width="33px" height="28px" />
              <span>{lang.ownstatsovertime}</span>
            </div>
          </div>
          {this.renderLineChart()}
        </div>

        <div className="content_group history_career">
          <div className="row">
            <div className="h4 background">
              <span>{lang.career}</span>
            </div>
          </div>

          {history_seasons.map((row, i) => {
            const urlComp = row.league_id
              ? `${database.assets}leagues/${row.league_id}.png`
              : '';

            // get names
            row.team_name = _.get(teams, `${row.team_id}.team_name`);
            row.league_name = _.get(leagues, `${row.league_id}.name`);

            const season = _.find(seasons, { id: row.season_id });

            return (
              <div className="bar_chart_group row" key={i}>
                <DataItem
                  className="col-3"
                  text={_.get(season, 'name', '–')}
                  desc={lang.season}
                />
                <DataItem className="col-3" desc={lang.league}>
                  <span className="data-text">
                    {urlComp ? <Image src={urlComp} aspectRatio={0.769} /> : ''}{' '}
                    {row.league_name ? row.league_name : ''}
                  </span>
                </DataItem>
                <DataItem className="col-1" text={row.games} desc={lang.game} />
                <DataItem
                  className="col-1"
                  text={row.goals}
                  desc={lang.goals}
                />
                <DataItem
                  className="col-2"
                  text={row.conceded_goals}
                  desc={lang.conceded_goals}
                />
                <DataItem
                  className="col-1"
                  text={row.rating}
                  desc={lang.rating}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    details: state.details,
    app: state.app,
    overview: state.overview,
  };
}

export default connect(mapStateToProps)(History);
