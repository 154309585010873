export interface Season {
  id: number;
  name: string;
}

export interface Country {
  abbreviation: string;
  country: string;
  id: number;
  region_id: number;
}

export interface FormationPosition {
  formation_id: number;
  id: number;
  position_id: number;
}

export interface Formation {
  id: number;
  name: string;
}

export interface LeagueFormat {
  id: number;
  name: string;
}

export interface League {
  completness: null;
  country_id: number;
  divisionlevel: number;
  format_id: number;
  id: number;
  matchday: number;
  name: string;
  rating: null;
}

export interface Position {
  description: string;
  id: number;
  position_name: string;
  upper_position_id: null;
}

export interface RegionGroup {
  id: number;
  name: string;
}

export interface Region {
  group_id: number;
  id: number;
  name: string;
}

export interface Preset {
  createdAt: null;
  id: number;
  mode: ModeType;
  name: string;
  preset: string;
  standard_id: number;
  updatedAt: string | null;
  user_id: number;
}

export enum ModeType {
  PLAYER = 'player',
  TEAM = 'team',
  MATCH = 'match',
  RANKINGS = 'rankings',
}
