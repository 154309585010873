import './league-table.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import _ from 'lodash';

import Image from '../../../ui/image';
import { database } from '../../../../constants/database';

class LeagueTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { title: '#', mainKey: 'rank' },
        { title: 'Club', mainKey: 'team_id' },
        { title: 'Matches', mainKey: 'games' },
        { title: 'Goals', mainKey: 'goals' },
        { title: 'Diff', mainKey: 'goal_difference' },
        { title: 'Points', mainKey: 'points' },
      ],
      orderBy: {
        title: '#',
        mainKey: 'rank',
        direction: 'asc',
      },
    };
  }

  _orderBy(order) {
    const { orderBy } = this.state;

    if (order.title === orderBy.title) {
      this.setState({
        orderBy: {
          ...orderBy,
          direction: orderBy.direction === 'asc' ? 'desc' : 'asc',
        },
      });
    } else {
      this.setState({
        orderBy: {
          ...order,
          direction: orderBy.direction,
        },
      });
    }
  }

  _renderTableHeader() {
    const { orderBy } = this.state;

    return this.state.columns.map(column => (
      <td key={column.title}>
        <a
          className={`${
            column.title === orderBy.title ? `active ${orderBy.direction}` : ''
          }`}
          onClick={this._orderBy.bind(this, column)}
        >
          <span>{column.title}</span>
        </a>
      </td>
    ));
  }

  _renderColumns(row) {
    const { teams } = this.props.app;
    const { current_league, current_season } = this.props.details;
    let image;

    const renderCell = column => {
      switch (column.title) {
        case 'Club':
          image = row[column.mainKey]
            ? `${database.assets}teams/${row[column.mainKey]}.jpg`
            : '';
          return (
            <Link
              className="team-link"
              to={`/details/team/${
                row[column.mainKey]
              }/${current_league}/${current_season}/summary`}
            >
              <Image src={image} aspectRatio={0.768} />
              {_.get(teams, `${row[column.mainKey]}.team_name`)}
            </Link>
          );

        case 'Goals':
          return `${row[column.mainKey]}:${row.conceded_goals}`;

        default:
          return row[column.mainKey] || 0;
      }
    };

    return this.state.columns.map(column => (
      <td key={column.title}>{renderCell(column)}</td>
    ));
  }

  _renderTableBody() {
    const { teams } = this.props.app;
    const { league_table, current_league } = this.props.details;
    const { orderBy } = this.state;

    const filterLeagueTable = team =>
      team.team_id && +team.league_id === +current_league;
    const orderLeagueTable = team => {
      switch (orderBy.title) {
        case 'Club':
          return _.get(teams, `${team.team_id}.team_name`, 'Unknown');
        default:
          return team[orderBy.mainKey];
      }
    };

    if (!league_table || !league_table.length) return null;

    return _.orderBy(
      league_table.filter(filterLeagueTable),
      [orderLeagueTable],
      [orderBy.direction],
    ).map(row => (
      <tr
        key={row.team_id}
        className={`${row.team_id === row.target_team_id ? 'highlight' : ''}`}
      >
        {this._renderColumns(row)}
      </tr>
    ));
  }

  render() {
    return (
      <table className="league-table">
        <thead>
          <tr>{this._renderTableHeader()}</tr>
        </thead>
        <tbody>{this._renderTableBody()}</tbody>
      </table>
    );
  }
}

function mapStateToProps(state) {
  return {
    details: state.details,
    app: state.app,
    overview: state.overview,
  };
}

export default connect(mapStateToProps)(LeagueTable);
