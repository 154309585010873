// Import Style
import './legend.scss';

import React, { Component } from 'react';

// Load actions, own fcts
import lang from '../../../lang';

// Load 3rd party libs and components
import { List } from 'react-virtualized';

export default class OverviewLegend extends Component {
  constructor(props, context) {
    super(props, context);

    this.updateScatterplot = this.updateScatterplot.bind(this);
    this.renderList = this.renderList.bind(this);
    this.state = {
      showXItems: false,
      showYItems: false,
      showRItems: false,
      showCItems: false,
      showSItems: false,
    };
  }

  componentWillReceiveProps = nextProps => {
    // close all windows if a player has been selected
    if (this.props.tooltipOpen != nextProps.tooltipOpen) {
      this.setState({
        showXItems: false,
        showYItems: false,
        showRItems: false,
        showCItems: false,
        showSItems: false,
      });
    }
  };

  updateScatterplot = (newValue, legendID) => {
    this.setState({
      showXItems: false,
      showYItems: false,
      showRItems: false,
      showCItems: false,
      showSItems: false,
    });

    this.props.onChange({ id: legendID, val: newValue });
  };

  renderList = legendID => {
    const rowHeight = 42;
    const listHeight = 350;
    const listWidth = 150;

    const items =
      legendID === 's' ? this.props.sortLegendList : this.props.legendList;

    const rowRender = ({ index, style }) => (
      <div
        key={index}
        style={style}
        className="legend__list_item"
        onClick={() => this.updateScatterplot(items[index], legendID)}
      >
        {lang[items[index]]}
      </div>
    );

    return (
      <List
        key={legendID}
        width={listWidth}
        height={items.length > 10 ? listHeight : items.length * rowHeight}
        rowCount={items.length}
        rowHeight={rowHeight}
        rowRenderer={rowRender}
      />
    );
  };

  render() {
    const {
      xVal,
      yVal,
      rVal,
      cVal,
      sVal,
      cDomain,
      rDomain,
      legend,
      legendClassNames,
      sLabelBefore,
      sLabelAfter,
    } = this.props;
    const { showXItems, showYItems, showRItems, showCItems, showSItems } =
      this.state;

    const getClassName = val =>
      legendClassNames
        ? legendClassNames[val] || 'content-legend-inline'
        : 'content-legend-inline';

    return (
      <div className="legend">
        {sVal ? (
          <div className={getClassName('sVal')}>
            <div
              className="legendXHeader legendHeader"
              onClick={() =>
                this.setState({
                  showSItems: !showSItems,
                  showYItems: false,
                  showXItems: false,
                  showCItems: false,
                  showRItems: false,
                })
              }
            >
              <div className={`icon-dropdown ${showSItems ? 'open' : ''}`} />
              {sLabelBefore || lang.sort} {lang[sVal]} {sLabelAfter || ''}
            </div>
            <div
              className={`legend__list ${
                showSItems == false ? 'hide' : 'show'
              }`}
            >
              {this.renderList('s')}
            </div>
          </div>
        ) : null}

        {yVal ? (
          <div className={getClassName('yVal')}>
            <div
              className="legendYHeader legendHeader"
              onClick={() =>
                this.setState({
                  showYItems: !showYItems,
                  showXItems: false,
                  showRItems: false,
                  showCItems: false,
                })
              }
            >
              <div className={`icon-dropdown ${showYItems ? 'open' : ''}`} />
              {lang[yVal]}
            </div>
            <div className={`legend__list ${showYItems ? 'show' : 'hide'}`}>
              {this.renderList('y')}
            </div>
          </div>
        ) : null}

        {xVal ? (
          <div className={getClassName('xVal')}>
            <div
              className="legendXHeader legendHeader"
              onClick={() =>
                this.setState({
                  showXItems: !showXItems,
                  showYItems: false,
                  showRItems: false,
                  showCItems: false,
                })
              }
            >
              <div className={`icon-dropdown ${showXItems ? 'open' : ''}`} />
              {lang[xVal]}
            </div>
            <div className={`legend__list ${showXItems ? 'show' : 'hide'}`}>
              {this.renderList('x')}
            </div>
          </div>
        ) : null}

        {cVal ? (
          <div className={getClassName('cVal')}>
            <div
              className="legendCHeader legendHeader"
              onClick={() =>
                this.setState({
                  showCItems: !showCItems,
                  showYItems: false,
                  showRItems: false,
                  showXItems: false,
                })
              }
            >
              <div className={`icon-dropdown ${showCItems ? 'open' : ''}`} />
              {lang[cVal]}
            </div>
            <div className="legend_desc">
              <div className="legend_domain">{cDomain ? cDomain[0] : ''}</div>
              <div
                className="legend_bar"
                style={{
                  background:
                    'linear-gradient(to left, #F4E664 , #63A188, #436986, #3A1356)',
                }}
              />
              <div className="legend_domain">{cDomain ? cDomain[1] : ''}</div>
            </div>
            <div className={`legend__list ${showCItems ? 'show' : 'hide'}`}>
              {this.renderList('c')}
            </div>
          </div>
        ) : null}

        {rVal ? (
          <div className={getClassName('rVal')}>
            <div
              className="legendRHeader legendHeader"
              onClick={() =>
                this.setState({
                  showRItems: !showRItems,
                  showYItems: false,
                  showXItems: false,
                  showCItems: false,
                })
              }
            >
              <div className={`icon-dropdown ${showRItems ? 'open' : ''}`} />
              {lang[rVal]}
            </div>
            <div className="legend_desc">
              <div className="legend_domain">{rDomain ? rDomain[0] : ''}</div>
              <div className="legend_circle" />
              <div className="legend_domain">{rDomain ? rDomain[1] : ''}</div>
            </div>
            <div className={`legend__list ${showRItems ? 'show' : 'hide'}`}>
              {this.renderList('r')}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
