import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Portal } from 'react-portal';

import Image from '../../../ui/image';
import { getColorFromCustomScale } from '../../../../helper';
import { SECOND_SCORE_COLORSCALE } from '../../../../constants';
import Icon from '../../../ui/svg-icon';

import './player.scss';

import FavoriteStarWithCategory from '../../../ui/favorite-star-with-category';
import Popup from '../popup';
import eventTracker from '../../../../helper/event-tracker';
import { ModeType } from '../../../../types/Dict';

class Player extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false, // Popup state,
      top: null,
      left: null,
      width: null,
      enteredRow: false,
      enteredPopup: false,
    };

    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);
    this.mouseEnterPopup = this.mouseEnterPopup.bind(this);
    this.mouseLeavePopup = this.mouseLeavePopup.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  mouseEnter() {
    const position = this.playerRef.getBoundingClientRect();
    this.setState({
      isHovered: true,
      top: position.top,
      left: position.left,
      width: position.width,
      enteredRow: true,
    });
  }

  mouseLeave() {
    this.setState({ enteredRow: false, isHovered: false });
  }

  mouseEnterPopup() {
    this.setState({ enteredPopup: true, isHovered: true });
  }

  mouseLeavePopup() {
    this.setState({ enteredPopup: false, isHovered: false });
  }

  onClose() {
    this.setState({ enteredPopup: false, isHovered: false });
  }

  renderPopup() {
    const { player, containerWidth, selectedPosition, mean, split_mode } =
      this.props;
    const { top, left, width } = this.state;

    let x;
    let y;
    let side;

    if (left <= containerWidth / 2) {
      side = 'right';
      x = left + width - 1;
      y = top;
    } else {
      side = 'left';
      x = left - 359;
      y = top;
    }

    return (
      <Portal
        node={
          document && document.getElementById('TooltipForRankingsContainer')
        }
      >
        <Popup
          player={player}
          top={y}
          left={x}
          side={side}
          mouseEnterPopup={this.mouseEnterPopup}
          mouseLeavePopup={this.mouseLeavePopup}
          onClose={this.onClose}
          selectedPosition={selectedPosition}
          mean={mean}
          split_mode={split_mode}
        />
      </Portal>
    );
  }

  render() {
    const { player, app, index, domain } = this.props;
    const { isHovered, enteredRow, enteredPopup } = this.state;
    const { playersMetaData, favorites } = app;
    const favoritePlayers = favorites.player || [];
    const { player_id } = player;
    const meta = playersMetaData[player_id];
    const name = _.get(meta, 'short_name', 'Unknown player');
    const { team_id } = player;
    const team_image = `https://static.sp7f.com/v2/teams/${team_id}.jpg`;
    const { score } = player;
    const { second_score } = player;

    const color = getColorFromCustomScale(
      +player.second_score,
      domain,
      SECOND_SCORE_COLORSCALE,
    );

    const favorite = favoritePlayers.find(p => +p.player_id === +player_id);
    const isFav = !!favorite;

    let favoriteComponent = isFav ? (
      <Icon name="favorite" className="favorite-icon" />
    ) : null;
    if (isHovered) {
      favoriteComponent = (
        <FavoriteStarWithCategory
          className="btn_star"
          favorite={favorite}
          item={player}
          mode={ModeType.RANKINGS}
          onAfterAdd={() => {
            eventTracker.trackEvent('Rankings', 'Add player to watchlist');
          }}
        />
      );
    }

    return (
      <div
        className={`player-ranking__player ${isHovered ? 'hover' : ''}`}
        style={{ zIndex: 20 - index }}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
        ref={ref => (this.playerRef = ref)}
      >
        <div
          className="player-ranking__rating-bar"
          style={{
            width: +score ? `${+score * 10}%` : 0,
            backgroundColor: second_score !== 'N/D' ? color : '#e6e6e6',
          }}
        />
        {isHovered ? <div className="hover-backdrop" /> : null}
        <div className="player-ranking__player-content">
          <div className="player-ranking__player-index">{index + 1}</div>
          <div className="player-ranking__player-name">
            <Image src={team_image} />
            {name}
            {favoriteComponent}
          </div>
          <div className="player-ranking__player-rating">{score}</div>
          <div className="player-ranking__player-mins">{second_score}</div>
        </div>
        {enteredRow || enteredPopup ? this.renderPopup() : null}
      </div>
    );
  }
}

Player.propTypes = {
  player: PropTypes.object,
  app: PropTypes.object,
  index: PropTypes.number,
  domain: PropTypes.array,
  containerWidth: PropTypes.number,
  selectedPosition: PropTypes.number,
  mean: PropTypes.object,
  split_mode: PropTypes.number,
};

export default connect(
  state => ({
    app: state.app,
  }),
  {},
)(Player);
