import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';
import enhanceWithClickOutside from 'react-click-outside';
import { connect } from 'react-redux';

import Icon from './svg-icon';
import { FAVORITE_CATEGORIES } from '../../constants';
import { addToFavorite, removeFromFavorite } from '../../actions/app';
import { changeCategory } from '../../actions/favorites';
import lang from '../../lang';

import './star-category-selector.scss';

class FavoriteStar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleList = this.toggleList.bind(this);
  }

  toggleList() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleClickOutside() {
    this.setState({
      isOpen: false,
    });
  }

  renderList = () => {
    const rowHeight = 39;
    const listWidth = 150;

    const { favorite, item } = this.props;

    let list = [];

    if (favorite) {
      list = [
        { val: null, label: lang.not_a_favorite },
        ...FAVORITE_CATEGORIES,
      ];
    } else {
      list = [...FAVORITE_CATEGORIES];
    }

    const rowRender = ({ index, style }) => (
      <div
        role="button"
        key={index}
        style={style}
        className={`list-item category-${list[index].val}`}
        onClick={() => {
          if (list[index].val !== null) {
            if (favorite) {
              this.props.changeCategory({
                player_id: item.player_id,
                user_id: favorite.user_id,
                value: list[index].val,
              });
            } else {
              this.props.addToFavorite({
                id: item.player_id,
                mode: 'player',
                category: list[index].val,
              });
              this.props.onAfterAdd && this.props.onAfterAdd();
            }
          } else {
            this.props.removeFromFavorite({
              id: item.player_id,
              mode: 'player',
            });
          }
          this.toggleList();
        }}
      >
        <Icon name="favorite" />
        {list[index].label}
      </div>
    );

    return (
      <List
        key="val"
        width={listWidth}
        height={list.length * rowHeight}
        rowCount={list.length}
        rowHeight={rowHeight}
        rowRenderer={rowRender}
      />
    );
  };

  render() {
    const { favorite } = this.props;

    return (
      <div
        className={`star-category-selector ${this.props.className} ${
          favorite ? `category-${favorite.category}` : ''
        }`}
      >
        <div role="button" className="label" onClick={this.toggleList}>
          <Icon
            className="icon-favorite"
            name="star-dropdown"
            width="27px"
            height="18px"
          />
        </div>
        {this.state.isOpen ? (
          <div className="list">{this.renderList()}</div>
        ) : null}
      </div>
    );
  }
}

FavoriteStar.propTypes = {
  category: PropTypes.number,
  className: PropTypes.string,
  id: PropTypes.any,
  user_id: PropTypes.any,
  favorite: PropTypes.any,
  mode: PropTypes.string,
  item: PropTypes.any,
  addToFavorite: PropTypes.func,
  removeFromFavorite: PropTypes.func,
  changeCategory: PropTypes.func,
  onAfterAdd: PropTypes.func,
};

export default connect(
  () => {
    return {};
  },
  { addToFavorite, removeFromFavorite, changeCategory },
)(enhanceWithClickOutside(FavoriteStar));
