import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ColorScale, PolarChart } from 'd3-react-touch';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import './performance.scss';

import BarChartGroup from '../modules/bar-chart-group';
import StandardStats from '../layouts/standard-stats';
import NoData from '../layouts/no-data';
import Icon from '../../ui/svg-icon';

import {
  createBarChartsBenchmark,
  createPolarCharts,
  getPlayerPerformanceFromAnalyse,
} from '../../../helper/';
import lang from '../../../lang';

import { BENCHMARK_COLOR, COLOR_SCALE } from '../../../constants';

class Performance extends Component {
  constructor(props) {
    super(props);

    this.renderBarCharts = this.renderBarCharts.bind(this);
  }

  renderBarCharts(bars, className, delayStart) {
    const { playersMetaData } = this.props.app;
    const { information, current_season, current_league, current_position } =
      this.props.details;

    const width = 115;
    const height = 50;
    const margin = {
      top: 13,
      right: 0,
      bottom: 1,
      left: 0,
    };

    const renderBenchmarkName = id =>
      typeof _.get(playersMetaData, `${id}.last_name`) === 'string'
        ? _.get(playersMetaData, `${id}.last_name`, '').slice(0, 8)
        : '';

    let animationDelayCounter = 0;

    return bars.map((bar, i) => {
      animationDelayCounter++;

      return (
        <div key={i} className={className}>
          <div className="center">
            <BarChartGroup
              data={bar}
              width={width}
              height={height}
              margin={margin}
              indicator={bar.indicator}
              link={`/details/player/:id/${current_league}/${current_season.join(
                '/',
              )}/summary${
                current_position ? `?position=${current_position}` : ''
              }`}
              name={information.last_name}
              benchmarkName={bar.benchmark_name}
              renderBenchmarkName={renderBenchmarkName}
              animationDelay={delayStart + animationDelayCounter * 200}
            />
          </div>
        </div>
      );
    });
  }

  render() {
    const {
      performance_current,
      position,
      mean,
      information,
      performance,
      minCondition,
      epiRating,
      realStats,
      isAccessGranted,
    } = this.props.details;
    let radars_against,
      radars_vals_against,
      bars_own_against,
      radars_vals_on,
      radars_onball,
      bars_own_against_ind,
      bars_own_onball_ind,
      bars_own_onball;

    const playerCanBeBenchmark = +performance.mins >= +minCondition;
    const goalkeeper = position[0] && +position[0].position_id === 10;

    radars_vals_on = [
      'take_on',
      'crossing',
      'pass_short',
      'pass_long',
      'reception',
      'delivery',
      'scoring',
      'scoring_effort',
    ];
    bars_own_onball_ind = [
      'take_on',
      'crossing',
      'pass_short',
      'pass_long',
      'reception',
      'delivery',
      'scoring',
      'scoring_effort',
    ];
    bars_own_against_ind = [
      'tackle_defence',
      'tackle_offence',
      'intercept_defence',
      'intercept_offence',
      'blocked',
      'save',
      'aerial_defence',
      'aerial_offence',
    ];

    if (goalkeeper) {
      radars_vals_against = [
        'intercept_defence',
        'save',
        'claim',
        'keeper_throw',
        'pass_long',
        'pass_short',
        'reception',
        'delivery',
      ];
      bars_own_against = createBarChartsBenchmark(
        {
          ...getPlayerPerformanceFromAnalyse(
            epiRating,
            information,
            radars_vals_against,
            playerCanBeBenchmark,
          ),
          ...mean,
        },
        radars_vals_against,
        true,
        playerCanBeBenchmark,
      );
      radars_against = createPolarCharts(
        {
          ...getPlayerPerformanceFromAnalyse(
            epiRating,
            information,
            radars_vals_against,
            playerCanBeBenchmark,
          ),
          ...mean,
        },
        radars_vals_against,
        true,
        playerCanBeBenchmark,
      );
    } else {
      radars_vals_against = [
        'tackle_defence',
        'tackle_offence',
        'intercept_defence',
        'intercept_offence',
        'blocked',
        'save',
        'aerial_defence',
        'aerial_offence',
      ];

      radars_against = createPolarCharts(
        {
          ...getPlayerPerformanceFromAnalyse(
            epiRating,
            information,
            radars_vals_against,
            playerCanBeBenchmark,
          ),
          ...mean,
        },
        radars_vals_against,
        true,
        playerCanBeBenchmark,
      );
      radars_onball = createPolarCharts(
        {
          ...getPlayerPerformanceFromAnalyse(
            epiRating,
            information,
            radars_vals_on,
            playerCanBeBenchmark,
          ),
          ...mean,
        },
        radars_vals_on,
        true,
        playerCanBeBenchmark,
      );
      bars_own_against = createBarChartsBenchmark(
        {
          ...getPlayerPerformanceFromAnalyse(
            epiRating,
            information,
            bars_own_against_ind,
            playerCanBeBenchmark,
          ),
          ...mean,
        },
        bars_own_against_ind,
        true,
        playerCanBeBenchmark,
      );
      bars_own_onball = createBarChartsBenchmark(
        {
          ...getPlayerPerformanceFromAnalyse(
            epiRating,
            information,
            bars_own_onball_ind,
            playerCanBeBenchmark,
          ),
          ...mean,
        },
        bars_own_onball_ind,
        true,
        playerCanBeBenchmark,
      );
    }

    let radar_height = 310,
      radar_margin = {
        top: 15,
        right: 20,
        bottom: 10,
        left: 20,
      };

    const performanceFromAnalyse = getPlayerPerformanceFromAnalyse(
      realStats,
      information,
      [
        'real_tackles',
        'real_tackles_pc_',
        'real_aerial_duels',
        'real_aerial_duels_pc_',
        'real_clearances',
        'real_interception',
        'real_blocks',
        'real_free_kicks',
        'real_corners',
        'real_dribblings',
        'real_dribblings_pc_',
        'real_shots',
        'real_in_box_shots',
        'real_goal_conversion',
        'real_key_passes',
        'real_passes',
        'real_pass_pc_',
        'real_crosses',
        'real_long_balls',
        'real_through_balls',
        'real_chances_created',
      ],
      playerCanBeBenchmark,
    );

    if (!epiRating.length) {
      return (
        <div className="performance">
          <NoData />
        </div>
      );
    }

    return (
      <div
        className={`performance ${
          goalkeeper ? 'performance__goalkeeper' : ''
        } ${!isAccessGranted ? 'blurred' : ''}`}
      >
        <div className="content_group performance_ownstats">
          <div className="row">
            <div className="h4">
              <Icon name="ranking-winner" width="33px" height="28px" />
              <span>{lang.ownstats}</span>
              <span className="description">
                {lang.performance_description}
              </span>
            </div>
          </div>

          {goalkeeper ? (
            <div className="row keeper">
              <div className="col-12">
                <PolarChart
                  animation
                  animationDelay={1000}
                  height={radar_height}
                  margin={radar_margin}
                  colors={COLOR_SCALE}
                  tooltips={radars_against.tooltips}
                  classNames={radars_against.classes}
                  data={radars_against.items}
                  xDomain={radars_against.xDomain}
                  interpolationType="linear-closed"
                />
              </div>
              {_.chunk(bars_own_against, 4).map((bars, i) => (
                <div className="bar_chart_group row" key={i}>
                  {this.renderBarCharts(bars, 'col-3')}
                </div>
              ))}
            </div>
          ) : performance_current.length != 1 ? (
            <div className="row">
              <div className="col-6 chart_group radar against ">
                <div className={`${goalkeeper ? 'hide' : ''}`}>
                  <PolarChart
                    animation
                    animationDelay={1000}
                    height={radar_height}
                    colors={COLOR_SCALE}
                    margin={radar_margin}
                    tooltips={radars_against.tooltips}
                    classNames={radars_against.classes}
                    data={radars_against.items}
                    xDomain={radars_against.xDomain}
                    interpolationType="linear-closed"
                  />
                </div>
                {_.chunk(bars_own_against, 4).map((bars, i) => (
                  <div className="bar_chart_group row" key={i}>
                    {this.renderBarCharts(bars, 'col-3', (i + 1) * 1000)}
                  </div>
                ))}
              </div>

              <div className={`divider_vertical ${goalkeeper ? 'hide' : ''}`} />

              <div className="col-6 chart_group radar on ">
                <div className={`${goalkeeper ? 'hide' : ''}`}>
                  <PolarChart
                    animation
                    animationDelay={1200}
                    height={radar_height}
                    colors={COLOR_SCALE}
                    margin={radar_margin}
                    tooltips={radars_onball.tooltips}
                    classNames={radars_onball.classes}
                    data={radars_onball.items}
                    xDomain={radars_onball.xDomain}
                    interpolationType="linear-closed"
                  />
                </div>
                {_.chunk(bars_own_onball, 4).map((bars, i) => (
                  <div className="bar_chart_group row" key={i}>
                    {this.renderBarCharts(bars, 'col-3', (i + 1) * 1000)}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ''
          )}

          <div className="row">
            <ColorScale
              domain={radars_against.yDomain}
              colors={COLOR_SCALE}
              benchmarkColor={BENCHMARK_COLOR}
            />
            {playerCanBeBenchmark ? null : (
              <div className="theoretical-ranks">{`* Theoretical rank, because the player has played less than ${minCondition} minutes.`}</div>
            )}
          </div>
        </div>

        <StandardStats
          performance={{ ...performanceFromAnalyse, ...mean }}
          renderBarCharts={this.renderBarCharts}
          playerCanBeBenchmark={playerCanBeBenchmark}
        />
      </div>
    );
  }
}

export default connect(state => ({
  details: state.details,
  app: state.app,
  overview: state.overview,
}))(Performance);
