// naming and translating of all text elements
// in case we want to support multilangual later

export default {
  summary: 'Summary',
  performance: 'Performance',
  history: 'History',
  search: 'Search',
  compare: 'Compare',
  details: 'Details',
  account: 'Account',
  resetfilter: 'Reset filters',
  squadList: 'Squad List',
  squadAnalyse: 'Squad Analysis',
  leagueAnalyse: 'League Comparison',

  nation: 'Nation',
  weight: 'Weight',
  height: 'Height',
  age: 'Age',
  seasonAge: 'Age',
  foot: 'Foot',
  coach: 'Coach',
  stadium: 'Stadium',
  deceased: 'Deceased †',

  performance_description: 'Total seasonal 0-10 rating per 90 minutes',
  history_description: "Ratings above 20' playing time",
  alternative_perspective_description: 'Age 15 – 21, Minimum 20% matches rated',
  alternative_backup_description: 'Age 28 – 33, Minimum 20% matches rated',
  alternative_immediate_description: 'Minimum 50% matches rated',

  alternatives_description:
    'Similar players with similarity index (SI) 0 - 100',
  alternatives_immediate: 'Immediate',
  alternatives_backup: 'Experience',
  alternatives_perspective: 'Perspective',

  profile: 'Profile',
  player: 'Player',
  team: 'Team',

  season: 'Season',
  season_id: 'Season',
  dates: 'Dates',
  preset_id: 'Preset',
  name: 'Name',
  last_name: 'Name',
  team_name: 'Team',
  team_id: 'Team',
  league: 'League',
  league_id: 'League',
  preferred_foot: 'Preferred foot',
  region: 'Nationality',
  region_id: 'Nationality',
  country_id: 'Nationality',
  game: 'Match',
  games: 'Matches',
  mins: 'Mins',
  season_mins: 'Mins',
  goals: 'Goals',
  yellow_cards: 'Yellow',
  red_cards: 'Red',
  position: 'Position',
  position_id: 'Position',
  position_first_id: 'Position',
  otherpos: 'Other Position',
  alignment: 'Alignment',
  onball: 'On the Ball',
  passing: 'Passing',
  assists: 'Assist',
  overall: 'Overall Rating',
  alocation: 'Allocation',
  formation_id: 'Formation',
  conceded_goals: 'Сonceded goals',
  contract_expiry: 'Contract ends',
  contract_expiry_year: 'Contract ends',
  marketvalue: 'Value (Mil. €)',
  contract: 'Contract',

  tackle: 'Tackle',
  tackle_defence: 'Tackle Def.',
  tackle_offence: 'Tackle Off.',
  blocked: 'Blocked Pass',
  delivery: 'Delivery',
  save: 'Blocked Shots',
  intercept_defence: 'Intercept Def.',
  intercept_offence: 'Intercept Off.',
  freekick_cross: 'Freekick Cross',
  aerial_offence: 'Aerial Off.',
  aerial_defence: 'Aerial Def.',
  take_on: 'Take-On',
  crossing: 'Crossing',
  pass_short: 'Pass Short',
  reception: 'Reception',
  pass_long: 'Pass Long',
  scoring: 'Finishing',
  scoring_effort: 'Shots',
  claim: 'Claim',
  corner: 'Corner',
  keeper_throw: 'Keeper Throw',
  similarity_index: 'Similarity Index 0 - 100',
  similarity_index_short: 'SI',

  real_tackles: 'Tackles',
  real_tackles_pc_: 'Tackles Success',
  real_aerial_duels: 'Aerial Duels',
  real_aerial_duels_pc_: 'Aerials Success',
  real_clearances: 'Clearances',
  real_ball_recovery: 'Ball Recovery',
  real_interception: 'Interception',
  real_blocks: 'Blocks',

  real_free_kicks: 'Free Kicks',
  real_corners: 'Corners',
  real_dribblings: 'Dribblings',
  real_dribblings_pc_: 'Dribblings Suc.',
  real_shots: 'Shots',
  real_in_box_shots: 'In-Box-Shots',
  real_goal_conversion: 'Goal Conversion',

  real_key_passes: 'Key Passes',
  real_passes: 'Passes',
  real_pass_pc_: 'Passes Success',
  real_crosses: 'Crosses',
  real_long_balls: 'Long Balls',
  real_through_balls: 'Through Balls',
  real_chances_created: 'Created Chances',

  peformance: 'Performance',
  alternatives: 'Alternatives',
  similarplayers: 'Immediate Alternatives',
  season_rating: 'Rating',
  mean_rating: 'Rating',
  rating_mean: 'Mean',
  rating: 'Rating',
  ratingoverall: 'Overall Rating',
  stability: 'Stability',
  defence: 'Defence',
  offence: 'Offence',
  contribution_defence: '% Defence',
  contribution_offence: '% Offence',
  contribution_pass: '% Pass',
  player_league: 'Player/League',
  player_team: 'Player/Team',
  team_league: 'Team/League',
  team_season: 'Team/Season',
  againstball: 'Off The Ball',

  strengths: 'Strengths',
  weaknesses: 'Weaknesses',
  style: 'Style',

  optastats: 'Standard Stats',
  ownstats: 'matchmetrics’ Effective Play Index (EPI)',
  ownstatsovertime: 'matchmetrics’ Effective Play Index (EPI) over time',

  epi_text_summary_part_1: 'has an Effective Play Rating of',
  epi_text_summary_part_2: 'and is ranked',
  epi_text_summary_part_3: 'on this position, based on',
  epi_text_summary_part_4: 'minutes',

  epi_text_swot_part_1: 'His main',
  epi_text_swot_part_2_1: 'strengths',
  epi_text_swot_part_2_2: 'strength',
  epi_text_swot_part_3: 'are',
  epi_text_swot_part_4: 'is',
  epi_text_swot_part_5: 'and',
  epi_text_swot_part_6_1: 'weaknesses',
  epi_text_swot_part_6_2: 'weakness',

  career: 'Career',

  moreoffensive: 'More Offensive',
  moredefensive: 'More Defensive',
  GK: 'Goalkeeper',
  CB: 'Center Back',
  B: 'Back',
  DM: 'Defensive Midfielder',
  CM: 'Central Midfielder',
  AM: 'Attacking Midfielder',
  M: 'Midfielder',
  CF: 'Central Forward',
  CBL: 'Center Back-Left',
  CBC: 'Center Back-Central',
  CBR: 'Center Back-Right',
  RB: 'Full-back-Right',
  RWB: 'Wing-back-Right',
  LB: 'Full-back-Left',
  LWB: 'Wing-back-Left',
  DML: 'Defensive Midfielder-Left',
  DMC: 'Defensive Midfielder-Central',
  DMR: 'Defensive Midfielder-Right',
  CML: 'Central Midfielder-Left',
  CMR: 'Central Midfielder-Right',
  AML: 'Attacking Midfielder-Left',
  AMR: 'Attacking Midfielder-Right',
  RM: 'Midfield-Right',
  RW: 'Rightwinger',
  LM: 'Midfield-Left',
  LW: 'Leftwinger',
  FWL: 'Center-Forward-Left',
  FWR: 'Center-Forward-Right',
  FWC: 'Center-Forward',

  matchday: 'Matchday',

  align1: 'Very Defensive',
  align2: 'Defensive',
  align3: 'Balanced',
  align4: 'Offensive',
  align5: 'Very Offensive',

  _season: season => `Season ${season}`,
  _1002: 'Last 20 games',
  _1001: 'Last 10 games',
  _1000: 'Last 5 games',

  alphabetically: 'alphabetically',
  sort: 'sort',

  time: 'Time',
  playerStats: 'Player stats',
  cards: 'Cards',
  thismatchValues: 'VALUES FOR THIS MATCH',
  lineups: 'Lineups',
  lineup: 'Lineup',
  teamCompare: 'Team compare',
  thisMatch: 'This match',
  seasonValue: 'Season value',
  averageLeague: 'Average for competition',
  bench: 'Bench',
  subsitutions: 'Substitution',
  team_id_home: 'Home team',
  team_id_away: 'Away team',
  sortAndColorByAverage: 'Sort and color by average',
  value: 'Value',
  bestPlayers: 'Best players',
  compareAllPlayers: 'COMPARE ALL PLAYERS',
  rating_0_10: 'RATING 0-10',
  sortedByTopGroupValues: 'SORTED BY TOP GROUP VALUES',
  match_plural: 'Matches',
  player_plural: 'Players',
  team_plural: 'Teams',
  rankings_plural: 'Player rankings',
  all_players: 'All players',
  favorite_players: 'Squad management',
  favorite_players_short: 'Favorites',

  performance_trend: 'Trend Last 5 Matches',
  include_missing_data: 'Incl. players with missing data',

  score: 'Score',
  score_defence: 'Score Defence',
  score_offence: 'Score Offence',

  values30games: 'Values of last 6 months',

  not_a_favorite: 'Not a favorite',
  none: 'No category',
  perspective: 'Perspective',
  focus: 'Focus',
  immediately: 'Immediately',

  unknown: 'Unknown',

  season_id_none: 'No season selected',
  league_id_none: 'No leagues selected',
  team_id_none: 'No teams selected',
  position_first_id_none: 'No positions selected',
  region_id_none: 'No nationalities selected',
  country_id_none: 'No nationalities selected',
  preset_id_none: 'No filter presets',
  formation_id_none: 'No formations selected',
  preferred_foot_none: 'No foot selected',

  note_text_placeholder: 'Let’s write something about this player…',
  note_title_placeholder: 'Title of a wonderful new note',
  notes: 'Notes',
  done: 'Done',

  show_more: 'Show more',
  show_less: 'Show less',

  tackle_offence_desc_short:
    'Ability to win the ball from an opposing player on the ground in positions of offensive importance.',
  tackle_defence_desc_short:
    'Ability to win the ball from an opposing player on the ground in positions of defensive importance.',
  intercept_defence_desc_short:
    "Ability to cutting out an opponent's pass in positions of offensive importance.",
  intercept_offence_desc_short:
    "Ability to cutting out an opponent's pass in positions of offensive importance.",
  aerial_offence_desc_short:
    'Volume and success in winning aerial duels in positions of offensive importance.',
  aerial_defence_desc_short:
    'Volume and success in winning aerial duels  in positions of defensive importance.',
  save_desc_short:
    'Volume, success and importance of blocking opposing shots at goal.',
  blocked_desc_short:
    'Volume, success and importance of blocking a pass close to the opponent.',
  take_on_desc_short:
    'Volume, success and importance of attempts to beat a defending player by dribbling past him.',
  crossing_desc_short: 'Ability to deliver crosses from wide positions.',
  pass_short_desc_short:
    'Volume, success and importance of player’s passes shorter than 32 meters.',
  pass_long_desc_short:
    'Volume, success and importance of player’s passes longer than 32 meters.',
  scoring_effort_desc_short: 'Sum and quality of the positions of goal shots.',
  scoring_desc_short:
    'Efficiency in front of goal, depending on the positions the shots have been taken from.',
  delivery_desc_short:
    'Ability to succesfully deliver the ball onto dangerous offensive positions.',
  reception_desc_short:
    'Sum of offensive quality of the positions where player has successfully received the ball.',
  season_rating_desc_short:
    'Average of single ratings for all games played. Sums up all actions of a player according to their importance.',
  stability_desc_short:
    'How consistently a player performs on his overall level.',
  defence_desc_short:
    'Sum and quality of all actions regarding their defensive importance.',
  offence_desc_short:
    'Sum and quality of all actions regarding their offensive importance.',
  competition_rank_desc_short:
    'Rank within the competition based on overall rating.',
  team_rank_desc_short: 'Rank within the team based on overall rating.',
  corner_desc_short: 'Volume, success and importance of corners.',
  keeper_throw_desc_short:
    'This describes the quality of build-up play by throwing the ball, for example after having caught a shot or picking up the ball.',
  claim_desc_short:
    'This describes the quantity and importance of positions where the goalkeeper successfully claimed crosses',

  search_team_id: 'Search a team...',
  search_league_id: 'Search a league...',

  welcome: 'Welcome',
  last_3_matches: 'Last 3 Match Reports',
  upcoming_opponents: 'Upcoming Opponents',
  top_players_lastmonth: 'Top Players Last Month',
  top_players_last3month: 'Top Players Last 3 Month',
  welcome_search: 'Search for Players, Teams and Matches..',
  at_current_club: 'At current club only',
  domestic_leagues: 'Domestic leagues',
  clear_all: 'Clear all',

  position_overall: 'Overall',
  position_group: 'Group',
  position_detail: 'Detail',

  position_overall_tip:
    'All performances, player categorized by his most common position',
  position_group_tip: 'Separate profiles according to positional groups',
  position_detail_tip: 'Separate profiles according to detailed positions',
  loading_players_performance: 'Loading players performance',
  season_history: 'Season history',

  lastMatchDay: 'Last matchday',
};
