import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import Select from 'react-select';
import ColorPicker from '../color-picker';
import { removeDiacritics } from '../../../../helper';
import { ApiKey } from './ApiKey';
import { Seasons } from './Seasons';

function perc2color(perc) {
  var r,
    g,
    b = 0;
  if (perc < 50) {
    r = 255;
    g = Math.round(5.1 * perc);
  } else {
    g = 255;
    r = Math.round(510 - 5.1 * perc);
  }
  var h = r * 0x10000 + g * 0x100 + b * 0x1;
  return '#' + ('000000' + h.toString(16)).slice(-6);
}

class Group extends Component {
  static propTypes = {
    group: PropTypes.object,
    leagues: PropTypes.object,
    countries: PropTypes.object,
    onSave: PropTypes.func,
    leaguesByCountryId: PropTypes.object,
    users: PropTypes.array,
    onAdd: PropTypes.func,
    leagueFormats: PropTypes.object,
    leaguesByFormatId: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      selectedLeagues: _.get(props, 'group.leagues', []),
      selectedCountries: _.get(props, 'group.countries', []),
      selectedSeasons: _.get(props, 'group.seasons', []),
      groupName: _.get(props, 'group.name', ''),
      groupTeam: _.get(props, 'group.team_id', null),
      groupColor: _.get(props, 'group.team_color', null),
      apikey: _.get(props, 'group.apikey', null),
      activeCountryId: null,
      countrySearchText: '',
      leagueSearchText: '',
    };

    this.onCancel = this.onCancel.bind(this);
    this.onEditOrAdd = this.onEditOrAdd.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.changeName = this.changeName.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.changeCountry = this.changeCountry.bind(this);
    this.changeLeague = this.changeLeague.bind(this);
    this.renderCountries = this.renderCountries.bind(this);
    this.renderLeagues = this.renderLeagues.bind(this);
    this.changeCountrySearch = this.changeCountrySearch.bind(this);
    this.changeLeagueSearch = this.changeLeagueSearch.bind(this);
    this.changeTeam = this.changeTeam.bind(this);
    this.changeColor = this.changeColor.bind(this);
    this.onChangeApiKey = this.onChangeApiKey.bind(this);
    this.onSeasonsChange = this.onSeasonsChange.bind(this);
  }

  onEditOrAdd(event) {
    event && event.preventDefault();

    this.setState({
      editMode: true,
    });
  }

  onCancel(event) {
    event && event.preventDefault();

    this.setState({
      editMode: false,
    });
  }

  onDelete(event) {
    event && event.preventDefault();

    const { group, onDelete } = this.props;

    if (group) {
      const { id } = group;

      onDelete(id);
    }
  }

  onSave() {
    const { group, onSave } = this.props;
    const {
      groupName: name,
      selectedLeagues: leagues,
      selectedCountries: countries,
      selectedSeasons: seasons,
      groupTeam: team_id,
      groupColor: team_color,
      apikey,
    } = this.state;
    const { id } = group;

    if (name && name.length) {
      onSave({
        id,
        name,
        leagues,
        countries,
        seasons,
        team_id,
        team_color,
        apikey,
      });

      this.setState({
        editMode: false,
      });
    } else {
      alert('Group name can not be empty');
    }
  }

  onAdd() {
    const { onAdd } = this.props;
    const {
      groupName: name,
      selectedLeagues: leagues,
      selectedCountries: countries,
      selectedSeasons: seasons,
      groupTeam: team_id,
      groupColor: team_color,
      apikey,
    } = this.state;

    if (name && name.length) {
      onAdd({ name, leagues, countries, seasons, team_id, team_color, apikey });

      this.setState({
        editMode: false,
      });
    } else {
      alert('Group name can not be empty');
    }
  }

  changeName(event) {
    this.setState({ groupName: event.target.value });
  }

  changeColor(color) {
    let hex = color ? color.hex : null;
    this.setState({ groupColor: hex });
  }

  changeTeam(team) {
    const team_id = team ? team.value : null;
    this.setState({ groupTeam: team_id });
  }

  toggleAll(event) {
    const leagues = _.keys(this.props.leagues).map(league => +league);
    const countries = _.keys(this.props.countries).map(country => +country);
    const { selectedLeagues } = this.state;

    this.setState({
      selectedLeagues: leagues.length === selectedLeagues.length ? [] : leagues,
      selectedCountries:
        leagues.length === selectedLeagues.length ? [] : countries,
    });
  }

  onChangeApiKey(apikey) {
    this.setState({ apikey });
  }

  onSeasonsChange(selectedSeasons) {
    this.setState({ selectedSeasons });
  }

  changeLeague(id) {
    const { leaguesByCountryId, leagues } = this.props;
    const { selectedLeagues, selectedCountries } = this.state;

    const leagueAvailable = selectedLeagues.indexOf(id) !== -1;
    const nextSelectedLeagues = leagueAvailable
      ? selectedLeagues.filter(league => league !== id)
      : [...selectedLeagues, id];

    const countryId = _.get(leagues, `${id}.country_id`, null);
    const countryLeaguesId = leaguesByCountryId[countryId].map(({ id }) => id);
    const needSelectCountry =
      _.intersection(countryLeaguesId, nextSelectedLeagues).length ===
      countryLeaguesId.length;

    this.setState({
      selectedLeagues: nextSelectedLeagues,
      selectedCountries:
        leagueAvailable || !needSelectCountry
          ? selectedCountries.filter(country => country !== countryId)
          : [...selectedCountries, countryId],
    });
  }

  changeCountry(id) {
    if (!id) return;

    const { leaguesByCountryId } = this.props;
    const { selectedLeagues, selectedCountries } = this.state;

    const countryLeaguesId = leaguesByCountryId[id].map(({ id }) => id);
    const countryIsSelected =
      _.intersection(countryLeaguesId, selectedLeagues).length ===
      countryLeaguesId.length;

    this.setState({
      selectedLeagues: _[countryIsSelected ? 'xor' : 'union'](
        selectedLeagues,
        countryLeaguesId,
      ),
      selectedCountries: _[countryIsSelected ? 'xor' : 'union'](
        selectedCountries,
        [id],
      ),
    });
  }

  changeCountrySearch(event) {
    this.setState({
      countrySearchText: event.target.value,
    });
  }

  changeLeagueSearch(event) {
    this.setState({
      leagueSearchText: event.target.value,
    });
  }

  renderCountries() {
    const {
      selectedLeagues,
      activeCountryId,
      countrySearchText,
      selectedCountries,
    } = this.state;
    const { countries, leaguesByCountryId } = this.props;

    const searchWords = countrySearchText
      .split(' ')
      .filter(word => word && word.length)
      .map(word => removeDiacritics(word));

    const countriesFiltered = _.chain(countries)
      .filter(({ country, id }) => {
        if (!leaguesByCountryId[id]) return false;

        if (countrySearchText.length) {
          const finedWords = searchWords.filter(
            word =>
              removeDiacritics(country)
                .toLowerCase()
                .trim()
                .indexOf(word.toLowerCase()) >= 0,
          );

          if (finedWords.length !== searchWords.length) return false;
        }

        return true;
      })
      .orderBy('country')
      .value();

    return countriesFiltered.map(country => {
      const countryLeaguesId = leaguesByCountryId[country.id].map(
        ({ id }) => id,
      );
      const selectedByCountryLength = _.intersection(
        countryLeaguesId,
        selectedLeagues,
      ).length;
      const countryIsSelected = selectedCountries.indexOf(country.id) !== -1;

      return (
        <div
          className={`country-option checkbox ${
            activeCountryId === country.id ? 'active' : ''
          }`}
          key={country.id}
          onClick={() => {
            this.setState({ activeCountryId: country.id });
          }}
        >
          <input
            type="checkbox"
            checked={countryIsSelected}
            onChange={this.changeCountry.bind(this, country.id)}
            ref={groupCheckbox =>
              groupCheckbox &&
              (groupCheckbox.indeterminate =
                !countryIsSelected && selectedByCountryLength)
            }
          />
          <Highlighter
            highlightClassName="word_highlight"
            searchWords={countrySearchText.split(/\s+/)}
            textToHighlight={country.country}
            sanitize={text => removeDiacritics(text)}
          />
        </div>
      );
    });
  }

  changeFormat(id) {
    if (!id) return;

    const { leaguesByFormatId } = this.props;
    const { selectedLeagues, activeCountryId } = this.state;

    const formatLeaguesId = leaguesByFormatId[id]
      .filter(
        ({ country_id }) => !activeCountryId || country_id === activeCountryId,
      )
      .map(({ id }) => id);
    const formatIsSelected =
      _.intersection(formatLeaguesId, selectedLeagues).length ===
      formatLeaguesId.length;

    this.setState({
      selectedLeagues: _[formatIsSelected ? 'xor' : 'union'](
        selectedLeagues,
        formatLeaguesId,
      ),
    });
  }

  renderLeagues() {
    const { selectedLeagues, activeCountryId, leagueSearchText } = this.state;
    const { leagues, leagueFormats, leaguesByFormatId } = this.props;

    const searchWords = leagueSearchText
      .split(' ')
      .filter(word => word && word.length)
      .map(word => removeDiacritics(word));

    if (!leagues) return null;

    const leaguesByFormat = _.groupBy(leagues, ({ format_id }) =>
      leagueFormats[format_id] ? format_id : null,
    );
    const formatsSorted = [
      ..._.sortBy(leagueFormats, 'name'),
      { id: null, name: 'No type' },
    ];

    return formatsSorted.map(({ id, name }) => {
      const leaguesFiltered = _.filter(
        leaguesByFormat[id],
        ({ country_id, name }) => {
          if (activeCountryId && country_id !== activeCountryId) return false;

          if (leagueSearchText.length) {
            const finedWords = searchWords.filter(
              word =>
                removeDiacritics(name)
                  .toLowerCase()
                  .trim()
                  .indexOf(word.toLowerCase()) >= 0,
            );

            if (finedWords.length !== searchWords.length) return false;
          }

          return true;
        },
      );

      if (!leaguesFiltered || !leaguesFiltered.length) return null;

      const leagueColumns = _.chunk(
        leaguesFiltered,
        Math.ceil(leaguesFiltered.length / 3),
      );

      const formatLeaguesId = leaguesByFormatId[id].map(({ id }) => id);
      const selectedByFormatLength = _.intersection(
        formatLeaguesId,
        selectedLeagues,
      ).length;
      const formatIsSelected =
        selectedByFormatLength === formatLeaguesId.length;

      return (
        <div className="format-group" key={id}>
          <div className="league-format">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={formatIsSelected}
                  onChange={this.changeFormat.bind(this, id)}
                  ref={groupCheckbox =>
                    groupCheckbox &&
                    (groupCheckbox.indeterminate =
                      !formatIsSelected && selectedByFormatLength)
                  }
                />
                <span>{name}</span>
              </label>
            </div>
          </div>
          <div className="row-new">
            {leagueColumns.map((column, index) => (
              <div className="col-new-4" key={index}>
                {column.map(league => {
                  const leagueChecked =
                    selectedLeagues.indexOf(+league.id) !== -1;

                  const perc = Math.floor(+league.completness * 100);

                  return (
                    <div className="checkbox" key={league.id}>
                      <label>
                        <input
                          type="checkbox"
                          checked={leagueChecked}
                          onChange={this.changeLeague.bind(this, league.id)}
                        />
                        <Highlighter
                          highlightClassName="word_highlight"
                          searchWords={leagueSearchText.split(/\s+/)}
                          textToHighlight={league.name}
                          sanitize={text => removeDiacritics(text)}
                        />
                        ,{' '}
                        <span className="completness">
                          <span
                            className="completness-progress"
                            style={{
                              background: perc2color(perc),
                              width: `${perc}%`,
                            }}
                          />
                          <span className="completness-value">{perc}%</span>
                        </span>
                      </label>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      );
    });
  }

  render() {
    const { group, leagues, users, teamsListAll, teams, search, seasons } =
      this.props;
    const {
      editMode,
      groupName,
      selectedLeagues,
      countrySearchText,
      leagueSearchText,
      activeCountryId,
      groupTeam,
      groupColor,
      apikey,
      selectedSeasons,
    } = this.state;

    const selectedAll = _.size(leagues) === selectedLeagues.length;
    const usersByGroup = _.groupBy(users, 'groupId');
    const searchString = search || '';

    const team_name = group
      ? _.get(teams, `[${group.team_id}].team_name`, 'No team')
      : '';
    if (!editMode) {
      return (
        <tbody>
          <tr>
            <td>
              <span className="align">{group && group.id}</span>
            </td>
            <td>
              <span className="align">
                <Highlighter
                  highlightClassName="word_highlight"
                  searchWords={searchString.split(/\s+/)}
                  textToHighlight={_.get(group, 'name', '')}
                  sanitize={text => removeDiacritics(text)}
                />
              </span>
            </td>
            <td>{team_name}</td>
            <td>
              {group && group.team_color ? (
                <div
                  className="team-color"
                  style={{ background: group.team_color }}
                ></div>
              ) : null}
            </td>
            <td>
              <span className="align">{group && selectedLeagues.length}</span>
            </td>
            <td>
              <span className="align">
                {group &&
                  (usersByGroup[group.id] ? usersByGroup[group.id].length : 0)}
              </span>
            </td>
            <td>
              <input type="checkbox" disabled checked={!!apikey} />
            </td>
            <td>
              {group ? (
                <a className="green-btn" href="#" onClick={this.onEditOrAdd}>
                  Edit
                </a>
              ) : null}
              {group ? (
                <a className="red-btn" href="#" onClick={this.onDelete}>
                  Delete
                </a>
              ) : null}
              {!group ? (
                <a className="green-btn" onClick={this.onEditOrAdd} href="#">
                  + Add group
                </a>
              ) : null}
            </td>
          </tr>
        </tbody>
      );
    }

    const options = [{ value: null, label: 'No team' }, ...teamsListAll];

    return (
      <tbody className="edit">
        <tr>
          {group ? (
            <td>
              <span className="align">{group && group.id}</span>
            </td>
          ) : null}
          <td colSpan={group ? 3 : 4}>
            <input
              value={groupName}
              onChange={this.changeName}
              type="text"
              placeholder="Group name"
            />
          </td>
          <td>
            {
              <Select
                options={options}
                placeholder="Select team..."
                value={options.find(t => t.value === groupTeam)}
                onChange={this.changeTeam}
              />
            }
          </td>
          <td colSpan={2}>
            {<ColorPicker color={groupColor} onChange={this.changeColor} />}
          </td>
          <td>
            {group ? (
              <a className="green-btn" href="#" onClick={this.onSave}>
                Save
              </a>
            ) : null}
            {!group ? (
              <a className="green-btn" href="#" onClick={this.onAdd}>
                Add
              </a>
            ) : null}
            <a className="red-btn" href="#" onClick={this.onCancel}>
              Cancel
            </a>
          </td>
        </tr>
        <tr>
          <td colSpan={8} className="group-options-container">
            <ApiKey onChangeApiKey={this.onChangeApiKey} apikey={apikey} />
          </td>
        </tr>
        {apikey ? (
          <tr>
            <td colSpan={8} className="group-options-container">
              <Seasons
                onChange={this.onSeasonsChange}
                dictionary={seasons}
                selected={selectedSeasons}
              />
            </td>
          </tr>
        ) : null}
        <tr>
          <td colSpan={8} className="group-options-container">
            <div className="group-options">
              <div className="group-countries">
                <div className="group-countries-filter">
                  <input
                    type="text"
                    placeholder="Filter by country"
                    value={countrySearchText}
                    onChange={this.changeCountrySearch}
                  />
                </div>
                <div className="group-countries-list">
                  <div
                    className={`country-option checkbox ${
                      activeCountryId === null ? 'active' : ''
                    }`}
                    onClick={() => {
                      this.setState({ activeCountryId: null });
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={this.toggleAll}
                      checked={selectedAll}
                      ref={allCheckbox =>
                        allCheckbox &&
                        (allCheckbox.indeterminate =
                          !selectedAll && selectedLeagues.length)
                      }
                    />
                    All
                  </div>
                  {this.renderCountries()}
                </div>
              </div>
              <div className="group-leagues">
                <div className="group-leagues-filter">
                  <input
                    type="text"
                    placeholder="Filter by league"
                    value={leagueSearchText}
                    onChange={this.changeLeagueSearch}
                  />
                </div>
                <div className="group-leagues-list">{this.renderLeagues()}</div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    );
  }
}

export default connect(
  state => ({
    leagues: state.app.leagues,
    teams: state.app.teams,
    teamsListAll: state.app.teamsListAll,
    leaguesByCountryId: _.groupBy(state.app.leagues, 'country_id'),
    leaguesByFormatId: _.groupBy(state.app.leagues, 'format_id'),
    countries: state.app.countries,
    users: state.users,
    seasons: state.app.seasons,
    leagueFormats: state.app.leagueFormats,
  }),
  {},
)(Group);
