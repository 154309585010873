import React from 'react';
import styled from 'styled-components';

interface Props {
  onChange: (seasons: number[]) => void;
  selected: number[];
  dictionary: { id: number; name: string }[];
}

export function Seasons(props: Props) {
  const { onChange, selected, dictionary } = props;

  const seasonOptions = dictionary.filter(({ id }) => id > 2000 && id < 3000);
  const otherOptions = dictionary.filter(({ id }) => id < 2000);

  const indeterminate =
    selected.length &&
    selected.length !== seasonOptions.length + otherOptions.length;

  const onSeasonChange = (id: number) => {
    if (selected.indexOf(id) !== -1) {
      onChange(selected.filter(seasonid => id !== seasonid));
    } else {
      onChange([...selected, id]);
    }
  };

  const onAllChange = () => {
    if (selected.length && !indeterminate) {
      onChange([]);
    } else {
      onChange([
        ...seasonOptions.map(({ id }) => id),
        ...otherOptions.map(({ id }) => id),
      ]);
    }
  };

  return (
    <Wrapper>
      <Sidebar>
        <div className={`country-option checkbox`}>
          <input
            id="season-all"
            type="checkbox"
            onChange={onAllChange}
            checked={!!(selected.length && !indeterminate)}
            ref={groupCheckbox =>
              // @ts-ignore
              groupCheckbox && (groupCheckbox.indeterminate = indeterminate)
            }
          />
          <label htmlFor="season-all">All seasons</label>
        </div>
      </Sidebar>
      <Content>
        <div className="row-new">
          <div className="col-new-4">
            {seasonOptions.map(({ id, name }) => (
              <div className="country-option checkbox">
                <input
                  id={`season-${id}`}
                  type="checkbox"
                  onChange={() => {
                    onSeasonChange(id);
                  }}
                  checked={selected.indexOf(id) !== -1}
                />
                <label htmlFor={`season-${id}`}>{name}</label>
              </div>
            ))}
          </div>
          <div className="col-new-4">
            {otherOptions.map(({ id, name }) => (
              <div className="country-option checkbox">
                <input
                  id={`season-${id}`}
                  type="checkbox"
                  onChange={() => {
                    onSeasonChange(id);
                  }}
                  checked={selected.indexOf(id) !== -1}
                />
                <label htmlFor={`season-${id}`}>{name}</label>
              </div>
            ))}
          </div>
        </div>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
`;

const Sidebar = styled.div`
  width: 300px;
  padding: 15px 20px;
  border-right: 1px solid #dfe3e6;
`;

const Content = styled.div`
  flex: auto;
  padding: 10px 20px;

  input[type='text'] {
    margin: 0 !important;
    width: 100% !important;
  }
`;
