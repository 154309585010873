import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { CollapseGroupType, OverviewFilterState } from './types';
import { ModeType } from '../../../../../types/Dict';
import { ListType } from '../../../../../reducers/overview/types';

export const initialState: OverviewFilterState = {
  [ModeType.PLAYER]: {
    collapse: {
      groups: {
        core: {
          icon: null,
          open: true,
        },
        profile: {
          icon: 'icon-profile',
          open: true,
          height: 890,
        },
        overall: {
          icon: 'icon-rating',
          open: true,
          height: 70 * 7,
        },
        againstball: {
          icon: 'icon-against',
          open: false,
          height: 70 * 8,
        },
        onball: {
          icon: 'icon-on',
          open: false,
          height: 70 * 8,
        },
      },
      lists: {
        [ListType.SEASON_ID]: {
          height: 354,
          open: false,
        },
        [ListType.LEAGUE_ID]: {
          height: 726,
          open: false,
        },
        [ListType.TEAM_ID]: {
          height: 505,
          open: false,
        },
        [ListType.POSITION_FIRST_ID]: {
          height: 460,
          open: false,
        },
        [ListType.FORMATION_ID]: {
          height: 460,
          open: false,
        },
        [ListType.COUNTRY_ID]: {
          height: 365,
          open: false,
        },
        [ListType.PRESET_ID]: {
          height: 200,
          open: false,
        },
        [ListType.PREFERRED_FOOT]: {
          height: 180,
          open: false,
        },
      },
    },
  },
  [ModeType.TEAM]: {
    collapse: {
      groups: {
        core: {
          icon: null,
          open: true,
        },
        overall: {
          icon: 'icon-rating',
          open: true,
          height: 280,
        },
        againstball: {
          icon: 'icon-against',
          open: false,
          height: 560,
        },
        onball: {
          icon: 'icon-on',
          open: false,
          height: 560,
        },
      },
      lists: {
        [ListType.SEASON_ID]: {
          height: 354,
          open: false,
        },
        [ListType.LEAGUE_ID]: {
          height: 726,
          open: false,
        },
        [ListType.TEAM_ID]: {
          height: 505,
          open: false,
        },
        [ListType.FORMATION_ID]: {
          height: 460,
          open: false,
        },
      },
    },
  },
  [ModeType.MATCH]: {
    collapse: {
      groups: {
        core: {
          icon: null,
          open: true,
        },
      },
      lists: {
        [ListType.LEAGUE_ID]: {
          height: 726,
          open: true,
        },
        [ListType.TEAM_ID]: {
          height: 505,
          open: false,
        },
      },
    },
  },
};

const slice = createSlice({
  name: 'overviewFilter',
  initialState,
  reducers: {
    toggleListCollapse(
      state,
      action: PayloadAction<{ mode: ModeType; listName: ListType }>,
    ) {
      const { mode, listName } = action.payload;

      Object.keys(state[mode].collapse.lists).forEach(key => {
        if (key === listName) {
          state[mode].collapse.lists[key].open =
            !state[mode].collapse.lists[key].open;
        } else {
          state[mode].collapse.lists[key].open = false;
        }
      });
    },
    toggleGroupCollapse(
      state,
      action: PayloadAction<{ mode: ModeType; groupName: CollapseGroupType }>,
    ) {
      const { mode, groupName } = action.payload;

      state[mode].collapse.groups[groupName].open =
        !state[mode].collapse.groups[groupName].open;
    },
  },
});

export const { actions: overviewFilterActions } = slice;

export const useOverviewFilterSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
