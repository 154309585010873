import _ from 'lodash';

import { GET_USER_TYPES } from '../constants';

const initState = [];

export default function (state = initState, action) {
  switch (action.type) {
    case GET_USER_TYPES:
      return _.get(action, 'payload.data.types', []);

    default:
      return state;
  }
}
