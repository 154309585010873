import axios from 'axios';

import { GET_USER_TYPES } from '../constants';

import { database } from '../constants/database';

export function getUserTypes() {
  const request = axios.get(database.userTypes.getTypes);

  return {
    type: GET_USER_TYPES,
    payload: request,
  };
}
