import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ColorScale, PolarChart } from 'd3-react-touch';
import Icon from '../../../ui/svg-icon';

// Load actions, own fcts and components
import lang from '../../../../lang';
import BarChartGroup from '../../modules/bar-chart-group';
import Header from '../header';
import { DataRank } from '../../../ui/data-item';

// Load 3rd party libs and components
import {
  createBarChartsBenchmark,
  createPolarCharts,
  getPerformanceFromAnalyse,
} from '../../../../helper';
import { BENCHMARK_COLOR, COLOR_SCALE } from '../../../../constants';

import LeagueTable from './summary/league-table';
import Position from './summary/position';

class Summary extends Component {
  constructor(props) {
    super(props);

    this._renderBarCharts = this._renderBarCharts.bind(this);
  }

  _renderBarCharts() {
    const {
      performance_current,
      mean,
      information,
      league_analyse,
      league_comparison,
    } = this.props.details;
    const { teams } = this.props.app;
    const bars_vals = ['season_rating', 'stability', 'defence', 'offence'];
    const bars = createBarChartsBenchmark(
      {
        ...getPerformanceFromAnalyse(league_comparison, information, bars_vals),
        ...mean,
      },
      bars_vals,
      true,
    );
    const width = 120;
    const height = 50;
    const margin = {
      top: 13,
      right: 0,
      bottom: 1,
      left: 0,
    };
    const renderBenchmarkName = id => (teams[id] ? teams[id].abk : '');
    let animationDelayCounter = 0;

    const renderGroup = group =>
      group.map((bar, b) => {
        animationDelayCounter++;
        return (
          <div className="col-6" key={b}>
            <BarChartGroup
              {...{
                data: bar,
                width,
                height,
                margin,
                link: '/details/team/:id/summary',
                name: information.abk,
                renderBenchmarkName,
                animationDelay: 1000 + animationDelayCounter * 200,
                showZero: true,
              }}
            />
          </div>
        );
      });

    return _.chunk(bars, 2).map((group, g) => (
      <div className="row first_el" key={g}>
        {renderGroup(group)}
      </div>
    ));
  }

  render() {
    const {
      performance_current,
      mean,
      position,
      current_season,
      current_league,
      current_formation,
      lineups,
      league_analyse,
      information,
      league_comparison,
    } = this.props.details;
    const { leagues, formations } = this.props.app;
    const radar_width = 290;
    const radar_height = 250;
    const radar_margin = {
      top: 30,
      right: 30,
      bottom: 10,
      left: 20,
    };
    const radars_vals_against = [
      'tackle_defence',
      'tackle_offence',
      'intercept_defence',
      'intercept_offence',
      'blocked',
      'save',
      'aerial_offence',
      'aerial_defence',
    ];
    const radars_vals_on = [
      'take_on',
      'crossing',
      'pass_short',
      'pass_long',
      'corner',
      'delivery',
      'scoring',
      'scoring_effort',
    ];

    const filteredPositions = position.filter(
      p =>
        p.season_id == current_season &&
        p.league_id == current_league &&
        p.player_id,
    );
    const radars_against = createPolarCharts(
      {
        ...getPerformanceFromAnalyse(
          league_comparison,
          information,
          radars_vals_against,
        ),
        ...mean,
      },
      radars_vals_against,
    );
    const radars_onball = createPolarCharts(
      {
        ...getPerformanceFromAnalyse(
          league_comparison,
          information,
          radars_vals_on,
        ),
        ...mean,
      },
      radars_vals_on,
    );
    const currentLeagueName = _.get(leagues, `${current_league}.name`);

    return (
      <div>
        <Header />
        <div className="content-container">
          <div className="summary">
            <div className="content_group">
              <div className="h4 background">
                <span>{currentLeagueName}</span>
              </div>
              <LeagueTable />
            </div>

            <div className="content_group">
              <div className="h4 background">
                <span>MOST COMMON LINEUP THIS SEASON</span>
              </div>
              <Position
                {...{ lineups, positions: filteredPositions, formations }}
              />
            </div>

            <div className="content_group summary_data">
              <div className="h4 background">
                <Icon name="ranking-winner" width="33px" height="28px" />
                <span>{lang.ownstats}</span>
              </div>

              <div className="charts_bar">
                {this._renderBarCharts()}
                <div className="row first_el">
                  <div className="col-12">
                    <div className="chart-legend">
                      <div className="chart-legend-list">
                        <div className="chart-legend-list-row">
                          <div className="chart-legend-list-item">
                            <ColorScale
                              domain={radars_onball.yDomain}
                              colors={COLOR_SCALE}
                              benchmarkColor={BENCHMARK_COLOR}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {performance_current ? (
                  <div className="row">
                    <DataRank
                      className="col-6"
                      title={lang.team_league}
                      rank={performance_current.rank_league}
                      desc={`of ${performance_current.rank_league_max} teams`}
                    />
                    <DataRank
                      className="col-6"
                      title={lang.team_season}
                      rank={performance_current.rank_season}
                      desc={`of ${performance_current.rank_season_max} teams`}
                    />
                  </div>
                ) : null}
              </div>

              <div className="charts_radar">
                <div className="chart_group radar radar_1">
                  <PolarChart
                    width={radar_width}
                    animation
                    animationDelay={1000}
                    height={radar_height}
                    margin={radar_margin}
                    tooltips={radars_against.tooltips}
                    classNames={radars_against.classes}
                    data={radars_against.items}
                    xDomain={radars_against.xDomain}
                    colors={COLOR_SCALE}
                    interpolationType="linear-closed"
                  />
                </div>
                <div className="chart_group radar radar_2">
                  <PolarChart
                    width={radar_width}
                    animation
                    animationDelay={1400}
                    height={radar_height}
                    margin={radar_margin}
                    tooltips={radars_onball.tooltips}
                    classNames={radars_onball.classes}
                    data={radars_onball.items}
                    xDomain={radars_onball.xDomain}
                    colors={COLOR_SCALE}
                    interpolationType="linear-closed"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    details: state.details,
    app: state.app,
    overview: state.overview,
  };
}

export default connect(mapStateToProps)(Summary);
