import React from 'react';

interface Props {
  option: {
    value: number;
    label: string;
    description: string;
  };
  onChange: (option) => void;
  active: boolean;
}

export function Tab(props: Props) {
  const { option, active, onChange } = props;
  const { value, label, description } = option;

  const onClick = () => {
    onChange(option);
  };

  return (
    <span
      key={value}
      data-tooltip-content={description}
      className={active ? 'active' : ''}
      onClick={onClick}
    >
      {label}
    </span>
  );
}
