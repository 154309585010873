import { PlayerPerformance } from '../../types/Player';
import { ModeType } from '../../types/Dict';

export type UniqItemId = 'ALL' | 'DOMESTICS';
export type ListItemId = number | string | UniqItemId;

export enum IndicatorType {
  ALPHABETICALLY = 'alphabetically',
  SEASON_RATING = 'season_rating',
  OFFENCE = 'offence',
  DEFENCE = 'defence',
  STABILITY = 'stability',
  CONTRIBUTION_DEFENCE = 'contribution_defence',
  CONTRIBUTION_OFFENCE = 'contribution_offence',
  CONTRIBUTION_PASS = 'contribution_pass',
  TACKLE_OFFENCE = 'tackle_offence',
  TACKLE_DEFENCE = 'tackle_defence',
  INTERCEPT_OFFENCE = 'intercept_offence',
  INTERCEPT_DEFENCE = 'intercept_defence',
  BLOCKED = 'blocked',
  SAVE = 'save',
  AERIAL_OFFENCE = 'aerial_offence',
  AERIAL_DEFENCE = 'aerial_defence',
  TAKE_ON = 'take_on',
  CROSSING = 'crossing',
  PASS_SHORT = 'pass_short',
  PASS_LONG = 'pass_long',
  RECEPTION = 'reception',
  DELIVERY = 'delivery',
  SCORING = 'scoring',
  SCORING_EFFORT = 'scoring_effort',
  SEASON_MINS = 'season_mins',
  SEASON_AGE = 'seasonAge',
  MARKET_VALUE = 'marketvalue',
  CONTRACT_EXPIRY_YEAR = 'contract_expiry_year',
  CONTRACT_EXPIRY = 'contract_expiry',
  SEASON_ID = 'season_id',
  COMPETITION_LEVEL = 'competition_level',
  RATING = 'rating',
}

type Color =
  | '#530154'
  | '#462973'
  | '#433d80'
  | '#435284'
  | '#3f6687'
  | '#367b8a'
  | '#278e8c'
  | '#3aa183'
  | '#50b276'
  | '#69c364'
  | '#ecd100'
  | '#c6cd19'
  | '#a3c92b'
  | '#84c43c'
  | '#68c04c'
  | '#4dbd5e'
  | '#31ba71'
  | '#00b886';

export interface Slider {
  id: number;
  name: IndicatorType;
  data: [number, number];
  minMax: [number, number];
  step: number;
  isByYear?: boolean;
  include_missing_data?: boolean;
}

type SortDirection = 'ASC' | 'DESC';
type TableVisible = 'half' | 'hidden';

type Domain = number[] | null;

export enum SwitcherType {
  FAVORITES = 'favorites',
  HIDE_TRANSFERRED = 'hidetransfered',
}

export interface Switcher {
  name: SwitcherType;
  data: boolean;
  hidden?: boolean;
}

export enum ListType {
  SEASON_ID = 'season_id',
  LEAGUE_ID = 'league_id',
  TEAM_ID = 'team_id',
  POSITION_FIRST_ID = 'position_first_id',
  COUNTRY_ID = 'country_id',
  PRESET_ID = 'preset_id',
  FORMATION_ID = 'formation_id',
  PREFERRED_FOOT = 'preferred_foot',
  FAVORITE_LEAGUES = 'favorite_leagues',
}

export type LinkedList = { listName: ListType; nestingIndex: NestingIndex };

export interface ListItem {
  name: string;
  id: ListItemId;
  active: boolean;
  open?: boolean;
  type: 'group' | 'item';
  items: ListItem[];
  linkedList?: LinkedList;
  preset?: string;
}

export interface List {
  id: number;
  name: ListType;
  data: ListItem[];
  splitted?: boolean;
  mode?: 1 | 2 | 3; // mode: 1 - Overall, 2 - Group, 3 - Detail
}

interface Layout {
  contentWidth: number | null;
  visualHeight: number | null;
  tableHeight: number;
  sidebarShow: boolean;
  tableVisible: TableVisible;
  initialTableShow: boolean;
}

interface Table {
  sortBy: IndicatorType;
  sortDirection: SortDirection;
  sortedData: null; //TODO
  xVal: IndicatorType;
  yVal: IndicatorType;
  rVal?: IndicatorType;
  cVal?: IndicatorType;
  sVal?: IndicatorType;
}

interface Scatterplot {
  xVal: IndicatorType;
  yVal: IndicatorType;
  rVal?: IndicatorType;
  cVal?: IndicatorType;
  sVal?: IndicatorType;
  xDomain: Domain | null;
  yDomain: Domain | null;
  rDomain?: Domain | null;
  cDomain: Domain | null;
  sDomain?: Domain | null; // TODO
  cRange: Color[];
  lassoSelection: any; // TODO
  legendList?: IndicatorType[];
  sortLegendList?: IndicatorType[];
}

export interface FilterComponents {
  filterLoaded: boolean;
  preset?: boolean;
  changeFilter: {
    slider: IndicatorType[];
    list: ListType[];
  };
  switcher: {
    [key in SwitcherType]?: Switcher;
  };
  slider: {
    [key in IndicatorType]?: Slider;
  };
  list: {
    [key in ListType]?: List;
  };
}

export interface ModeState {
  layouts: Layout;
  scatterplot: Scatterplot;
  table: Table;
  filterComponents: FilterComponents;
}

export interface OverviewState {
  changeNum: number;
  showTooltip: {
    id: number | null;
    x: number | null;
    y: number | null;
  };
  sortedDataList: []; // TODO
  [ModeType.PLAYER]: ModeState;
  [ModeType.TEAM]: ModeState;
  [ModeType.MATCH]: ModeState;
  [ModeType.RANKINGS]: ModeState;
  activePointData: {
    [ModeType.PLAYER]: PlayerPerformance | null;
    [ModeType.TEAM]: {
      league_id: number;
      team_id: number;
    } | null;
    [ModeType.MATCH]: {
      match_id: number;
    } | null;
    table?: boolean;
  };
}

export type Status = 'checked' | 'unchecked' | 'indeterminate';
export type HasChild = boolean;
export type NestingIndex = number[];

export interface FlattenListItem extends ListItem {
  name: string;
  id: ListItemId;
  active: boolean;
  open?: boolean;
  type: 'group' | 'item';
  status: Status;
  hasChild: HasChild;
  nestingIndex: NestingIndex;
  level: number;
  linkedList?: LinkedList;
}

export enum PlayerSlidersId {
  'seasonAge',
  'season_mins',
  'contract_expiry',
  'marketvalue',
  'season_rating',
  'stability',
  'defence',
  'contribution_defence',
  'contribution_offence',
  'contribution_pass',
  'offence',
  'tackle_defence',
  'tackle_offence',
  'intercept_defence',
  'intercept_offence',
  'blocked',
  'save',
  'aerial_offence',
  'aerial_defence',
  'take_on',
  'crossing',
  'pass_short',
  'pass_long',
  'reception',
  'delivery',
  'scoring',
  'scoring_effort',
  'season_id',
  'competition_level',
}

export enum TeamSlidersId {
  'season_rating' = 2,
  'stability',
  'defence',
  'offence',
  'tackle_defence',
  'tackle_offence',
  'intercept_defence',
  'intercept_offence',
  'blocked',
  'save',
  'aerial_offence',
  'aerial_defence',
  'take_on',
  'crossing',
  'pass_short',
  'pass_long',
  'reception',
  'delivery',
  'scoring',
  'scoring_effort',
  'competition_level',
}

export enum PlayerListsId {
  'season_id',
  'league_id',
  'team_id',
  'position_first_id',
  'country_id',
  'preset_id',
  'formation_id',
  'preferred_foot',
  'favorite_leagues',
}

export enum TeamListsId {
  'season_id',
  'league_id',
  'team_id',
  'formation_id',
  'favorite_leagues',
}

export enum MatchListsId {
  'league_id',
  'team_id',
  'favorite_leagues',
}
