import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import _ from 'lodash';
import moment from 'moment';

import Icon from '../../ui/svg-icon';

import './squad-list.scss';

import Image from '../../ui/image';
import { database } from '../../../constants/database';

class SquadList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { title: 'No', mainKey: 'jersey_num' },
        { title: 'Player', mainKey: 'first_name' },
        { title: 'Age', mainKey: 'age' },
        { title: 'Nation', mainKey: 'country_id' },
        { title: 'Height', mainKey: 'height' },
        { title: 'Foot', mainKey: 'preferred_foot' },
        { title: 'Pos', mainKey: 'position_id' },
        { title: 'Mins', mainKey: 'season_mins' },
        { title: '% Defence', mainKey: 'contribution_defence' },
        { title: '% Offence', mainKey: 'contribution_offence' },
        { title: '% Pass', mainKey: 'contribution_pass' },
      ],
      orderBy: {
        title: 'Pos',
        mainKey: 'position_id',
        direction: 'asc',
      },
    };
  }

  _orderBy(order) {
    const { orderBy } = this.state;

    if (order.title === orderBy.title) {
      this.setState({
        orderBy: {
          ...orderBy,
          direction: orderBy.direction === 'asc' ? 'desc' : 'asc',
        },
      });
    } else {
      this.setState({
        orderBy: {
          ...order,
          direction: 'desc',
        },
      });
    }
  }

  _renderTableHeader() {
    const { orderBy } = this.state;

    return this.state.columns.map(column => (
      <td key={column.title}>
        <a
          className={`${
            column.title === orderBy.title ? `active ${orderBy.direction}` : ''
          }`}
          onClick={this._orderBy.bind(this, column)}
        >
          <span>{column.title}</span>
        </a>
      </td>
    ));
  }

  _renderColumns(row) {
    const { positions } = this.props.app;
    const { current_league, current_season } = this.props.details;
    const { first_name, last_name, birth_date, age, height } = row;
    const isTransfered = +row.team_id !== +row.current_team;
    let image;

    const renderCell = column => {
      switch (column.title) {
        case 'Player':
          image = row.player_id
            ? `${database.assets}players/${row.player_id}.jpg`
            : '';

          return (
            <Link
              className="player-link"
              to={`/details/player/${row.player_id}/${current_league}/${current_season}/summary`}
            >
              <span className="player-image">
                <Image src={image} aspectRatio={0.768} />
                {isTransfered ? <Icon name="icon-transfered" /> : null}
              </span>
              {first_name} {last_name}
            </Link>
          );

        case 'Age':
          let ageString = `${
            birth_date ? `${moment(birth_date).format('MMM DD YYYY')}` : 'N/D'
          }`;
          ageString = age ? ageString + ', ' : ageString;
          ageString = age ? ageString + age : ageString;
          return ageString;

        case 'Nation':
          image = row[column.mainKey]
            ? `${database.assets}countries/${row[column.mainKey]}.jpg`
            : '';

          return (
            <span className="player-nation">
              <Image src={image} aspectRatio={125 / 75} />
            </span>
          );

        case 'Height':
          return `${
            height && parseFloat(height)
              ? `${parseFloat(height / 100).toFixed(2)} m`
              : 'N/D'
          }`;

        case 'Pos':
          return _.get(positions, `${row[column.mainKey]}.description`, 'N/D');

        default:
          return row[column.mainKey] || 'N/D';
      }
    };

    return this.state.columns.map(column => (
      <td
        className={`${
          column.title === 'Age'
            ? 'text-right age'
            : renderCell(column) === 'N/D'
            ? 'not_defined'
            : ''
        }`}
        key={column.title}
      >
        {renderCell(column)}
      </td>
    ));
  }

  _renderTableBody() {
    const { squad_analyse, current_league } = this.props.details;
    const { orderBy } = this.state;

    const filterSquad = player =>
      player.player_id && +player.league_id === +current_league;
    const orderSquad = player => {
      if (orderBy.direction === 'desc' && !player[orderBy.mainKey]) {
        return -1;
      } else if (orderBy.direction === 'asc' && !player[orderBy.mainKey]) {
        return Infinity;
      }

      return player[orderBy.mainKey];
    };
    const orderMins = player => {
      return !!player.season_mins && orderBy.mainKey === 'position_id' ? 1 : 0;
    };

    if (!squad_analyse || !squad_analyse.length) return null;

    return _.orderBy(
      squad_analyse
        .filter(filterSquad)
        .map(player => ({ ...player, season_mins: +player.season_mins })),
      [orderSquad, orderMins],
      [orderBy.direction, 'desc'],
    )
      .filter(filterSquad)
      .map(row => <tr key={row.player_id}>{this._renderColumns(row)}</tr>);
  }

  render() {
    const { squad_analyse, current_league, isAccessGranted } =
      this.props.details;
    const filterSquad = player =>
      player.player_id && +player.league_id === +current_league;

    return (
      <div className={`${!isAccessGranted ? 'blurred' : ''}`}>
        <div className="h4">
          <span>ALL {squad_analyse.filter(filterSquad).length} PLAYERS</span>
        </div>
        <table className="content_group squad-list">
          <thead>
            <tr>{this._renderTableHeader()}</tr>
          </thead>
          <tbody>{this._renderTableBody()}</tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    details: state.details,
    app: state.app,
    overview: state.overview,
  };
}

/* function mapDispatchToProps(dispatch) {
 return bindActionCreators({...DetailsActions, loading}, dispatch)
 } */
export default connect(mapStateToProps /* , mapDispatchToProps */)(SquadList);
