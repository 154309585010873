import './lineups.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router';

import Image from '../../ui/image';
import Positions from './lineups/positions';

import lang from '../../../lang';
import { database } from '../../../constants/database';

class LineUps extends Component {
  constructor(props) {
    super(props);

    this.renderBenchList = this.renderBenchList.bind(this);
    this.renderSubsitutionsList = this.renderSubsitutionsList.bind(this);
  }

  renderBenchList({ teamId }) {
    const { information = {}, players } = this.props.details;
    const { positions } = this.props.app;
    let squads;

    if (!_.size(information)) return null;

    try {
      squads = JSON.parse(information.squads);
    } catch (error) {
      console.info(`squads JSON in match ${information.match_id} is not valid`);
      console.error(error);
      return null;
    }

    const playersBench = squads[teamId].bench.map(player => ({
      ...player,
      ...players[player.player_id],
    }));

    return _.sortBy(playersBench, player => +player.position_id)
      .filter(player => player.last_name)
      .map((player, i) => (
        <tr key={i}>
          <td>
            <Link
              to={`/details/player/${player.player_id}/summary`}
              className="jersey-number"
            >
              {player.last_name}
            </Link>
          </td>
          <td
            className={`td-position ${
              !player.position_id || !positions[player.position_id]
                ? 'not_defined'
                : ''
            }`}
          >
            {_.get(positions, `${player.position_id}.position_name`, 'N/D')}
          </td>
        </tr>
      ));
  }

  renderSubsitutionsList({ teamId, playerStatsById }) {
    const { information = {}, players } = this.props.details;
    let substitutions;

    if (!_.size(information)) return null;

    try {
      substitutions = JSON.parse(information.substitutions);
    } catch (error) {
      console.info(
        `substitutions JSON in match ${information.match_id} is not valid`,
      );
      console.error(error);
      return null;
    }

    const substitutionsByMins = _.groupBy(
      substitutions[teamId].map(player => ({
        ...player,
        ...players[player.player_id],
      })),
      'min',
    );

    return _.map(substitutionsByMins, (substitutions, min) => (
      <tr key={min}>
        <td className="td-min">{min}`</td>
        <td>
          {substitutions
            .filter(
              substitution =>
                substitution.type === 'out' && substitution.last_name,
            )
            .map((substitution, i) => {
              const player = _.get(
                playerStatsById,
                `${substitution.player_id}`,
                {},
              );

              return (
                <div
                  className="substitution substitution-out"
                  key={`${min}${i}${substitution.type}`}
                >
                  <Link
                    to={`/details/player/${substitution.player_id}/summary`}
                    className={`jersey-number ${
                      teamId == information.team_id_home ? 'home' : 'away'
                    }`}
                  >
                    <span className="number">
                      {player.mins > 20
                        ? _.get(
                            playerStatsById,
                            `${substitution.player_id}.rating`,
                            'N/D',
                          )
                        : 'N/D'}
                    </span>
                    {substitution.last_name}
                  </Link>
                </div>
              );
            })}
        </td>
        <td>
          {substitutions
            .filter(substitution => substitution.type === 'in')
            .map((substitution, i) => {
              const player = _.get(
                playerStatsById,
                `${substitution.player_id}`,
                {},
              );

              return (
                <div
                  className="substitution substitution-in"
                  key={`${min}${i}${substitution.type}`}
                >
                  <Link
                    to={`/details/player/${substitution.player_id}/summary`}
                    className={`jersey-number ${
                      teamId == information.team_id_home ? 'home' : 'away'
                    }`}
                  >
                    <span className="number">
                      {player.mins > 20
                        ? _.get(
                            playerStatsById,
                            `${substitution.player_id}.rating`,
                            'N/D',
                          )
                        : 'N/D'}
                    </span>
                    {substitution.last_name}
                  </Link>
                </div>
              );
            })}
        </td>
      </tr>
    ));
  }

  render() {
    const { information = {}, playerStats, players } = this.props.details;
    const { teams, formations, positions } = this.props.app;
    let squads;

    if (
      !_.size(information) ||
      !information.team_id_home ||
      !information.squads ||
      !information.squads.length
    )
      return null;

    try {
      squads = JSON.parse(information.squads);
    } catch (error) {
      console.info(
        `substitutions JSON in match ${information.match_id} is not valid`,
      );
      console.error(error);
      return null;
    }

    const playerStatsById = _.keyBy(playerStats, 'player_id');
    const playersLineUpHome = squads[information.team_id_home].line_up.map(
      player => ({
        ...player,
        ..._.get(players, `${player.player_id}`, {}),
        ..._.get(positions, `${player.position_id_detail}`, {}),
        rating:
          _.get(playerStatsById, `${player.player_id}.mins`) > 20
            ? _.get(playerStatsById, `${player.player_id}.rating`, 'N/D')
            : 'N/D',
      }),
    );
    const playersLineUpAway = squads[information.team_id_away].line_up.map(
      player => ({
        ...player,
        ..._.get(players, `${player.player_id}`, {}),
        ..._.get(positions, `${player.position_id_detail}`, {}),
        rating:
          _.get(playerStatsById, `${player.player_id}.mins`) > 20
            ? _.get(playerStatsById, `${player.player_id}.rating`, 'N/D')
            : 'N/D',
      }),
    );

    return (
      <div className="match-lineups">
        <div className="content_group">
          <div className="row">
            <div className="col-3">
              <div className="col-10">
                <div className="bench">
                  <div className="h4 team-name">
                    <span>
                      <Image
                        src={`${database.assets}teams/${information.team_id_home}.jpg`}
                        aspectRatio={0.769}
                      />
                      {_.get(
                        teams,
                        `${information.team_id_home}.team_name`,
                        '',
                      )}
                    </span>
                    <span className="description">
                      {lang.lineup}{' '}
                      {_.get(
                        formations,
                        `${_.get(
                          squads,
                          `${information.team_id_home}.formation_id`,
                        )}.name`,
                        null,
                      )}
                    </span>
                  </div>
                  <div className="bench-title">{lang.bench}</div>
                  <table className="bench-table">
                    <tbody>
                      {this.renderBenchList({
                        teamId: information.team_id_home,
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-2">&nbsp;</div>
            </div>
            <div className="col-6">
              <Positions
                positions={{ home: playersLineUpHome, away: playersLineUpAway }}
                information={information}
              />
            </div>
            <div className="col-3">
              <div className="col-2">&nbsp;</div>
              <div className="col-10">
                <div className="h4 right team-name">
                  <span>
                    <Image
                      src={`${database.assets}teams/${information.team_id_away}.jpg`}
                      aspectRatio={0.769}
                    />
                    {_.get(
                      teams,
                      `${information.team_id_away}.team_name`,
                      null,
                    )}
                  </span>
                  <span className="description">
                    {lang.lineup}{' '}
                    {_.get(
                      formations,
                      `${_.get(
                        squads,
                        `${information.team_id_away}.formation_id`,
                      )}.name`,
                      null,
                    )}
                  </span>
                </div>
                <div className="bench">
                  <div className="bench-title">{lang.bench}</div>
                  <table className="bench-table">
                    <tbody>
                      {this.renderBenchList({
                        teamId: information.team_id_away,
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6 lineup-col">
              <div className="subsitutions">
                <div className="subsitutions-title">{lang.subsitutions}</div>
                <table className="subsitutions-table">
                  <tbody>
                    {this.renderSubsitutionsList({
                      teamId: information.team_id_home,
                      playerStatsById,
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-6 lineup-col">
              <div className="subsitutions">
                <div className="subsitutions-title">{lang.subsitutions}</div>
                <table className="subsitutions-table">
                  <tbody>
                    {this.renderSubsitutionsList({
                      teamId: information.team_id_away,
                      playerStatsById,
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    details: state.details,
    app: state.app,
    overview: state.overview,
  };
}

export default connect(mapStateToProps)(LineUps);
