import './login.scss';

import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { Link } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { sendLoginRequest } from '../../actions/auth';
import { RootState } from '../../types';

export function Login() {
  const dispatch = useDispatch();
  const signInError = useSelector((state: RootState) => state.auth.signInError);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const { email, password } = formData;

  const onFormSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      dispatch(sendLoginRequest({ email, password }));
    },
    [email, password],
  );

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    },
    [email, password],
  );

  return (
    <div className="auth-align">
      <div className="auth-container">
        <div className="auth-title">Welcome to Scoutpanel</div>
        <form className="auth-login" onSubmit={onFormSubmit}>
          <input
            name="email"
            type="email"
            placeholder="E-mail"
            value={email}
            required
            onChange={onInputChange}
          />
          <input
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            required
            onChange={onInputChange}
          />
          <div className={`error-message ${signInError ? 'visible' : ''}`}>
            {signInError === 'Your account inactive' ? (
              <span className="inactive">
                Your account is inactive please
                <br />
                <a href="mailto:support@scoutpanel.com" target="_blank">
                  ask support
                </a>
              </span>
            ) : (
              signInError
            )}
          </div>
          <button type="submit">LOG IN</button>
        </form>
      </div>
      <div className="auth-helpers">
        <Link to="/auth/reset" className="pull-left">
          Forgot password?
        </Link>
        <a className="pull-right" href="mailto:support@scoutpanel.com">
          Contact support
        </a>
      </div>
    </div>
  );
}
