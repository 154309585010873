import React, { Component } from 'react';

// Load actions, own fcts and components
import { createBarChartsBenchmark } from '../../../helper/';
import lang from '../../../lang';

export default class StandardStats extends Component {
  constructor(props) {
    super(props);

    this._renderRows = this._renderRows.bind(this);
  }

  _renderRows() {
    const { performance, renderBarCharts, playerCanBeBenchmark } = this.props;

    const bars_against_indi = [
      'real_tackles',
      'real_tackles_pc_',
      'real_aerial_duels',
      'real_aerial_duels_pc_',
      'real_clearances',
      'real_interception',
      'real_blocks',
    ];
    const bars_against = createBarChartsBenchmark(
      performance,
      bars_against_indi,
      true,
      playerCanBeBenchmark,
    );

    const bars_onball_indi = [
      'real_free_kicks',
      'real_corners',
      'real_dribblings',
      'real_dribblings_pc_',
      'real_shots',
      'real_in_box_shots',
      'real_goal_conversion',
    ];
    const bars_onball = createBarChartsBenchmark(
      performance,
      bars_onball_indi,
      true,
      playerCanBeBenchmark,
    );

    const bars_pass_indi = [
      'real_key_passes',
      'real_passes',
      'real_pass_pc_',
      'real_crosses',
      'real_long_balls',
      'real_through_balls',
      'real_chances_created',
    ];
    const bars_pass = createBarChartsBenchmark(
      performance,
      bars_pass_indi,
      true,
      playerCanBeBenchmark,
    );

    const rows = [
      {
        title: lang.againstball,
        bars: bars_against,
      },
      {
        title: lang.onball,
        bars: bars_onball,
      },
      {
        title: lang.passing,
        bars: bars_pass,
      },
    ];

    return rows.map((row, i) => (
      <div className="bar_chart_group row" key={i}>
        <h1 className="line">{row.title}</h1>
        {renderBarCharts(row.bars, 'col-1_5')}
      </div>
    ));
  }

  render() {
    return (
      <div className="content_group performance_optastats">
        <div className="row">
          <div className="h4">
            <span>{lang.optastats}</span>
            <span className="description">Total values per 90 minutes</span>
          </div>
        </div>

        {this._renderRows()}
      </div>
    );
  }
}
