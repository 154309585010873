import './nav-bar.scss';
import './../../../ui/dropdown.scss';

import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import Icon from '../../../ui/svg-icon';

// Load actions, own fcts and components
import lang from '../../../../lang';

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      player: [
        { name: lang.summary, to: 'summary' },
        { name: lang.performance, to: 'performance' },
        { name: lang.history, to: 'history' },
        { name: lang.notes, to: 'notes' },
        { name: lang.alternatives, to: 'alternatives' },
      ],
      team: [
        { name: lang.summary, to: 'summary' },
        { name: lang.squadList, to: 'squadList' },
        { name: lang.squadAnalyse, to: 'squadAnalyse' },
        { name: lang.performance, to: 'performance' },
        { name: lang.leagueAnalyse, to: 'leagueAnalyse' },
        { name: lang.history, to: 'history' },
      ],
      match: [
        { name: lang.summary, to: 'summary' },
        { name: lang.lineups, to: 'lineups' },
        { name: lang.teamCompare, to: 'teamCompare' },
        { name: lang.playerStats, to: 'playerStats' },
      ],
    };

    this.renderNavList = this.renderNavList.bind(this);
  }

  renderNavList() {
    const {
      id,
      current_season,
      current_league,
      current_formation,
      current_position,
      mode,
      isAccessGranted,
    } = this.props.details;
    const current = `${id}/${current_league}/${current_season.join('/')}`;

    let query = '';
    if (mode === 'team' && +current_formation) {
      query += `?formation=${current_formation}`;
    }
    if (mode === 'player' && +current_position) {
      query += `?position=${current_position}`;
    }

    if (!mode) return null;

    return this.state[mode].map(link => (
      <Link
        key={link.to}
        className={`col-2 callAction nav__${link.to} ${
          !isAccessGranted ? 'inactive' : ''
        }`}
        activeClassName="active"
        to={
          !isAccessGranted
            ? null
            : `/details/${mode}/${current}/${link.to}${query}`
        }
      >
        {!isAccessGranted ? <Icon name="icon-lock" /> : ''}
        {link.name}
      </Link>
    ));
  }

  render() {
    return (
      <div className="nav-container">
        <div className="nav row">{this.renderNavList()}</div>
      </div>
    );
  }
}

export default connect(state => ({
  details: state.details,
}))(NavBar);
