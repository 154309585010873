import { ADD_LOADER, DELETE_LOADER } from '../actions/loader';

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_LOADER: {
      const { name } = action;

      return [...state, name];
    }
    case DELETE_LOADER: {
      const { name } = action;

      return state.filter(load => load !== name);
    }
    default:
      return state;
  }
}
