import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../../../../types';
import {
  selectListFilter,
  selectSliderFilter,
} from '../../../../../../reducers/overview/selectors';
import { selectCollapseList } from '../../slice/selectors';
import { ListItem, ListType } from '../../../../../../reducers/overview/types';
import { RadioField } from '../../fields/RadioField';
import { ModeType } from '../../../../../../types/Dict';
import { changeSliderVal } from '../../../../../../actions/overview';
import { IndicatorType } from '../../../../../../types/Indicators';
import { selectCurrentSeason } from '../../../../../../reducers/app/selectors';

interface SeasonOption extends ListItem {
  index: number;
}

interface Props {
  mode: ModeType;
  loadSeasonData: (season: string) => void;
}

export function SeasonList(props: Props) {
  const { mode, loadSeasonData } = props;
  const [showAll, setShowAll] = useState<boolean>(false);
  const dispatch = useDispatch();

  const listName = ListType.SEASON_ID;
  const listCollapse = useSelector((state: RootState) =>
    selectCollapseList(state, { mode, listName }),
  );
  const seasonList = useSelector((state: RootState) =>
    selectListFilter(state, { mode, listName }),
  );
  const seasonMinsSlider = useSelector((state: RootState) =>
    selectSliderFilter(state, { mode, sliderName: IndicatorType.SEASON_MINS }),
  );
  const currentSeason = useSelector((state: RootState) =>
    selectCurrentSeason(state, { mode }),
  );

  let seasonOptions: SeasonOption[] = [];
  let monthOptions: SeasonOption[] = [];
  let otherOptions: SeasonOption[] = [];

  for (let i = 0, l = seasonList!.data.length; i < l; i++) {
    const option = seasonList!.data[i];

    if (option.id >= 1000) {
      seasonOptions.push({
        ...option,
        index: i,
        active: +currentSeason === option.id,
      });
    } else if (option.id < 1000 && option.id > 0) {
      monthOptions.push({
        ...option,
        index: i,
        active: +currentSeason === option.id,
      });
    } else if (mode === ModeType.PLAYER) {
      otherOptions.push({
        ...option,
        index: i,
        active: +currentSeason === option.id,
      });
    }
  }

  let height = listCollapse?.height || 0;

  switch (mode) {
    case ModeType.PLAYER:
      height = height - 72;
      break;
    case ModeType.TEAM:
      if (!showAll) {
        height = 3 * 36 + 28;
      }
      break;
  }

  const onChange = async (id, value) => {
    let seasonValue: string = `${id}`;

    if (id < 1000 && id > 0) {
      const startDate = moment().subtract(id, 'month').format('YYYY-MM-DD');
      const endDate = moment().format('YYYY-MM-DD');
      seasonValue = `${startDate}/${endDate}`;
    }

    loadSeasonData(seasonValue);
    dispatch(
      changeSliderVal(
        IndicatorType.SEASON_MINS,
        [id === 0 ? 20 : 250, seasonMinsSlider?.data[1]],
        mode,
      ),
    );
  };

  const getOptionElement = options =>
    options.map(({ id, name, active, index }) => (
      <RadioField name={id} onChange={onChange} checked={active} key={id}>
        {name}
      </RadioField>
    ));

  const toggleShowAll = () => {
    setShowAll(showAll => !showAll);
  };

  return (
    <div
      className="ReactVirtualized__Grid ReactVirtualized__List"
      style={{ height, overflow: 'hidden auto' }}
    >
      <div className="ReactVirtualized__Grid__innerScrollContainer">
        <div
          className="filter-options-collapse"
          style={{
            maxHeight: 36 * (showAll ? seasonOptions.length : 3),
          }}
        >
          {getOptionElement(seasonOptions)}
        </div>
        <div
          className={`show-all ${showAll ? '' : 'visible'}`}
          onClick={toggleShowAll}
        >
          <span>+ Show all</span>
        </div>
        <div className="filter-options-collapse">
          {getOptionElement(monthOptions)}
        </div>
        <div className="filter-options-collapse">
          {getOptionElement(otherOptions)}
        </div>
      </div>
    </div>
  );
}
