import React, { Component } from 'react';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { browserHistory } from 'react-router';

import Header from './layouts/header';

import Summary from './player/summary';
import Perfomance from './player/performance';
import History from './player/history';
import Alternatives from './player/alternatives';
import Notes from '../favorites/notes';

import lang from '../../lang';

const getWindowHeight = (minus = 0) =>
  document.documentElement.clientHeight
    ? document.documentElement.clientHeight - minus
    : window.innerHeight - minus;

class Player extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabs: ['summary', 'performance', 'history', 'notes', 'alternatives'],
      index: 0,
      sort: {
        alternatives: {
          label: lang.score,
          value: 'score',
        },
      },
    };

    this.onChangeIndex = this.onChangeIndex.bind(this);
    this.heightUpdate = this.heightUpdate.bind(this);
    this.changeSorting = this.changeSorting.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentWillMount() {
    const { tab } = this.props;
    const { tabs } = this.state;
    const index = tabs.indexOf(tab);
    index > -1 && this.setState({ index });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUpdate(nextProps) {
    const { tabs, index } = this.state;
    const nextTab = nextProps.tab;
    const nextIndex = tabs.indexOf(nextTab);

    nextIndex > -1 &&
      index !== nextIndex &&
      this.setState({ index: nextIndex });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onChangeIndex(nextIndex) {
    const { index } = this.state;
    const tab = this.state.tabs[nextIndex];
    const { id, current_season, current_league, mode } = this.props.details;

    index !== nextIndex &&
      browserHistory.push(
        `/details/${mode}/${id}/${current_league}/${current_season}/${tab}`,
      );
  }

  handleResize() {
    let minus = 0;

    const header = document.querySelector('.header-container');
    const nav = document.querySelector('.nav-container');
    const headerHeight = header ? header.offsetHeight : 0;
    const navHeight = nav ? nav.offsetHeight : 0;
    minus += headerHeight + navHeight + 15;
    const height = getWindowHeight(minus);

    this.setState({ height });
    this.updateHeight && this.updateHeight();
  }

  heightUpdate() {}

  changeSorting(name) {
    return value => {
      this.setState({
        sort: {
          ...this.state.sort,
          [name]: value,
        },
      });
    };
  }

  render() {
    const { onChangeIndex } = this;
    const { tab } = this.props;
    const { index, sort, height } = this.state;

    const { details } = this.props;
    const { isAccessGranted } = details;

    return (
      <div>
        <Header {...{ tab, sort }} />

        <SwipeableViews
          style={{ width: '100%' }}
          animateHeight
          enableMouseEvents={false}
          ignoreNativeScroll
          index={index}
          onChangeIndex={onChangeIndex}
          action={actions => {
            this.updateHeight = actions.updateHeight;
          }}
        >
          <div>
            <div className="content-container">
              <Summary />
            </div>
          </div>

          <div>
            <div className="content-container">
              <Perfomance />
            </div>
          </div>

          <div>
            <div className="content-container">
              <History updateHeight={this.updateHeight} />
            </div>
          </div>

          <div height={height}>
            <Notes player_id={details.id} inactive={!isAccessGranted} />
          </div>

          <div>
            <div className="content-container">
              <Alternatives
                sortValue={sort.alternatives}
                changeSorting={this.changeSorting('alternatives')}
              />
            </div>
          </div>
        </SwipeableViews>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    details: state.details,
    app: state.app,
    overview: state.overview,
  };
}

export default connect(mapStateToProps)(Player);
