// --------------------
// custom app functions
// --------------------
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import d3 from 'd3';
import Icon from '../components/ui/svg-icon';
import {
  BENCHMARK_COLOR,
  COLOR_SCALE,
  DEFAULT_SEASON,
  EPI_SKILLIMPORTANCE_STRENGTHS_BY_POS,
  EPI_SKILLIMPORTANCE_WEAKNESSES_BY_POS,
  NO_RATING_UNDER_MINS,
} from '../constants';
import lang from '../lang';
import { ModeType } from '../types/Dict';

export const getColorFromCustomScale = (
  value,
  domain,
  colors = COLOR_SCALE,
) => {
  const range = [...domain];
  const rgb_colors = colors.map(c => d3.rgb(c));

  const c = d3.scale.quantize().domain(range).range(rgb_colors);

  return c(value);
};

export const getColorFromScale = (
  value,
  mean,
  yDomain = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  color_scale,
) => {
  const avg = +mean;
  let range = [...yDomain];
  const rangedColors = color_scale ? [...color_scale] : [...COLOR_SCALE];
  const rangeStart = avg - 3;
  const step = 0.6;
  let start = rangeStart;

  if (isNumber(avg)) {
    range = [0];
    for (let j = 0; j <= 10; j++) {
      range.push(parseFloat(start.toFixed(2)));
      start += step;
    }

    range = [...range, 10];

    rangedColors.unshift(rangedColors[0]);
    rangedColors.push(rangedColors[rangedColors.length - 1]);
  }

  const c = d3.scale
    .linear()
    .domain(range)
    .range(rangedColors.map(c => d3.rgb(c)));

  return c(value);
};

export const getColorFromScaleSimple = value => {
  const c = d3.scale
    // .threshold()
    // .domain([2.5,4,6,7.5])
    // .range(['#ffa0b0', '#ffd8df', '#ffffff', '#d9f0e4', '#84cea7']);
    .linear()
    .domain([-2, 5, 12])
    .range(['#FF1A40', '#ffffff', '#44AB74'])
    .interpolate(d3.interpolateHsl);

  return c(value);
};

export const asymmetricDistribution = (
  min = 0,
  avg = 5,
  max = 10,
  steps = 5,
) => {
  // [min ... avg, avg ... max ];

  min = +min;
  avg = +avg;
  max = +max;
  steps = +steps;

  if (isNaN(min) || isNaN(max) || isNaN(avg) || isNaN(steps)) {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  }

  if (steps <= 0) {
    steps = 1;
  }

  const domain = [];

  const belowStep = Math.ceil(+((avg - min) / steps) * 100) / 100;
  const aboveStep = Math.ceil(+((max - avg) / steps) * 100) / 100;

  let current = min;

  if (belowStep > 0) {
    while (current < avg) {
      domain.push(current);
      current += belowStep;
    }
  }

  current = +avg;

  if (aboveStep > 0) {
    while (current < +max) {
      domain.push(current);
      current += aboveStep;
    }
  }

  domain.push(+max);

  return domain;
};

function isNumber(id) {
  return typeof id === 'number' && isFinite(id) && Math.round(id) === id;
}

// Create a radar chart with multi lines, e.g. for compare page
export const createRadarCharts = (data, radars_vals, highlighted) => {
  const isHighlighted = isNumber(highlighted);

  const lang_ = [];
  radars_vals.map((d, i) => {
    lang_.push(lang[d]);
  });
  let values = [];
  const items = [];

  // if only one item is defined make array out of it thus it can be iterated over
  if (Object.prototype.toString.call(data) != '[object Array]') {
    data = [{ data }];
  }

  // calculate radar for each data item
  data.map((item, j) => {
    if (!_.isEmpty(item.data)) {
      const values = [];
      radars_vals.map((indicator, i) => {
        const v = item.data[indicator] ? item.data[indicator] : 0;
        values.push({ name: lang_[i], value: v, indicator });
      });
      items.push({
        key: item.id,
        class: `item_${j} ${
          isHighlighted && j !== highlighted ? 'faded' : null
        }`,
        values,
      });
    }
  });

  // calc data for mean
  values = [];
  radars_vals.map((indicator, i) => {
    if (!_.isEmpty(data[0].data)) {
      const m = data[0].data[`${indicator}_mean`]
        ? data[0].data[`${indicator}_mean`]
        : 0;
      values.push({ name: lang_[i], value: m, indicator });
    }
  });
  if (!_.isEmpty(data[0].data)) {
    items.unshift({ key: 'mean', class: 'item_mean', values });
  }

  return {
    xDomain: lang_ || '',
    items,
  };
};

export const createPolarCharts = (
  data,
  radars_vals,
  highlighted,
  playerCanBeBenchmark,
  short_names,
) => {
  if (Object.keys(data).length === 0) {
    return {
      tooltips: [...radars_vals],
      xDomain: [],
      yDomain: [],
      items: [
        { values: [], key: 'mean' },
        { values: [], key: '' },
      ],
    };
  }

  const isHighlighted = isNumber(highlighted);

  const lang_ = [];
  if (!short_names) {
    radars_vals.map((d, i) => {
      lang_.push(lang[d]);
    });
  } else {
    radars_vals.map((d, i) => {
      lang_.push(short_names[i]);
    });
  }

  let values = [];
  const items = [];

  // if only one item is defined make array out of it thus it can be iterated over
  if (Object.prototype.toString.call(data) != '[object Array]') {
    data = [{ data }];
  }

  // calculate radar for each data item
  data.map((item, j) => {
    if (!_.isEmpty(item.data)) {
      const values = [];
      radars_vals.map((indicator, i) => {
        const v = item.data[indicator]
          ? item.data[indicator]
          : item.data[indicator] === null || item.data[indicator] === undefined
          ? null
          : 0;
        const mean = item.data[`${indicator}_mean`]
          ? item.data[`${indicator}_mean`]
          : item.data[`${indicator}_mean`] === null ||
            item.data[`${indicator}_mean`] === undefined
          ? null
          : 0;
        const benchmark =
          item.data[`${indicator}_benchmark_value`] ||
          +item.data[`${indicator}_benchmark_value`] === 0
            ? item.data[`${indicator}_benchmark_value`]
            : mean;

        let color = BENCHMARK_COLOR;

        if (+v > +benchmark) {
          color = _.last(COLOR_SCALE);
        } else if (+v !== +benchmark) {
          const yDomain = asymmetricDistribution(0, mean, benchmark);
          const colorScale = d3.scale
            .linear()
            .domain(yDomain)
            .range(COLOR_SCALE);
          color = colorScale(v);
        }

        values.push({
          name: lang_[i],
          value: v,
          benchmark,
          mean,
          color,
        });
      });
      items.push({
        key: item.id,
        class: `item_${j} ${isHighlighted && j !== highlighted ? 'faded' : ''}`,
        values,
      });
    }
  });

  // calc data for mean
  values = [];
  radars_vals.map((indicator, i) => {
    if (!_.isEmpty(data[0].data)) {
      const m = data[0].data[`${indicator}_mean`]
        ? data[0].data[`${indicator}_mean`]
        : 0;
      values.push({ name: lang_[i], value: m, indicator });
    }
  });
  if (!_.isEmpty(data[0].data)) {
    items.unshift({ key: 'mean', class: 'item_mean', values });
  }

  return {
    tooltips: [...radars_vals],
    xDomain: lang_ || '',
    yDomain: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    items,
  };
};

// Create a bar chart with multi input, e.g. for compare page
export const createBarCharts = (data, bars_vals) => {
  const lang_ = [];
  bars_vals.map((d, i) => {
    lang_.push(lang[d]);
  });
  const values = [];
  const bars = [];

  // calculate radar for each data item
  bars_vals.map((indicator, i) => {
    const values = [];
    const classes = [];

    data.map((item, j) => {
      if (!_.isEmpty(item.data)) {
        const v = item.data[indicator]
          ? item.data[indicator]
          : item.data[indicator] === null || item.data[indicator] === undefined
          ? null
          : 0;
        values.push({ x: item.id + i + j, y: v });
        classes.push(`item_${j}`);
      }
    });

    bars.push({
      name: lang_[i] ? lang_[i] : indicator,
      classes,
      values,
    });
  });
  return bars;
};

export const createCompareBarCharts = (data, bars_vals) => {
  const lang_ = [];
  bars_vals.map((d, i) => {
    lang_.push(lang[d]);
  });
  const values = [];
  const bars = [];

  // calculate radar for each data item
  bars_vals.map((indicator, i) => {
    const values = [];
    const classes = [];
    const players_id = [];
    const teams_id = [];
    data.map((item, j) => {
      if (!_.isEmpty(item.data)) {
        const v = item.data[indicator]
          ? item.data[indicator]
          : item.data[indicator] === null || item.data[indicator] === undefined
          ? null
          : 0;
        const contentType = indicator.includes('_pc_') ? 'PCT' : 'NUM';
        values.push({ x: item.id + i + j, y: v, contentType });
        classes.push(`item_${j}`);
        players_id.push(item.data.player_id);
        teams_id.push(item.data.team_id);
      }
    });
    bars.push({
      name: lang_[i] ? lang_[i] : indicator,
      classes,
      values,
      players_id,
      teams_id,
    });
  });

  return bars;
};

export const createBarChartValues = (data, bars_vals) => {
  const lang_ = bars_vals.map((d, i) => lang[d]);
  const values = [];
  const bars = [];

  bars_vals.map((indicator, i) => {
    const values = [];
    const classes = [];

    data.map((item, j) => {
      if (!_.isEmpty(item)) {
        const v = item[indicator]
          ? item[indicator]
          : item[indicator] === null || item[indicator] === undefined
          ? null
          : 0;
        values.push({ x: i + j, y: v });
        classes.push(`item_${j}`);
      }
    });
    bars.push({
      name: lang_[i] ? lang_[i] : indicator,
      classes,
      values,
    });
  });

  return bars;
};

// Create a bar chart, e.g. for detail page
export const createBarChartsBenchmark = (
  data,
  bars_vals,
  generateColor,
  playerCanBeBenchmark = true,
) => {
  const bars = [];
  const lang_ = [];
  bars_vals.map((d, i) => {
    lang_.push(lang[d]);
  });

  bars_vals.map((indicator, i) => {
    let v0 = 0;
    let v1 = 0;
    let v2 = 0;
    let rank = '';
    let class_status = '';
    let bn = '';
    let bid = '';
    const contentType = indicator.includes('_pc_') ? 'PCT' : 'NUM';

    if (!_.isEmpty(data)) {
      // value of item
      v0 = data[indicator]
        ? data[indicator]
        : data[indicator] === null || data[indicator] === undefined
        ? null
        : 0;
      const r = data[`${indicator}_rank`] ? data[`${indicator}_rank`] : 0;
      rank =
        r == null
          ? 'N/D'
          : `${parseInt(r, 10)}${getGetOrdinal(parseInt(r, 10))}${
              playerCanBeBenchmark ? '' : '*'
            }`;

      // value of mean value
      v1 =
        typeof data[`${indicator}_mean`] !== 'undefined'
          ? data[`${indicator}_mean`]
          : 0;

      // value of benchmark player
      v2 =
        typeof data[`${indicator}_benchmark_value`] !== 'undefined'
          ? data[`${indicator}_benchmark_value`]
          : 0;

      class_status = parseFloat(v0) >= parseFloat(v1) ? 'positive' : 'negative';
      bn =
        typeof data[`${indicator}_benchmark_name`] !== 'undefined'
          ? data[`${indicator}_benchmark_name`]
          : '';
      bid =
        typeof data[`${indicator}_benchmark_id`] !== 'undefined'
          ? data[`${indicator}_benchmark_id`]
          : '';
    }

    if (indicator === 'stability') {
      if (v0 !== null && v0 !== undefined) {
        v0 = v0 > 0 ? v0 : 0;
        v0 = v0 < 10 ? v0 : 10;
      }

      v1 = v1 > 0 ? v1 : 0;
      v2 = v2 > 0 ? v2 : 0;

      v1 = v1 < 10 ? v1 : 10;
      v2 = v2 < 10 ? v2 : 10;
    }

    const min = 0;
    const max = v2;
    const avg = v1;

    const value = v0;
    let color = null;

    if (generateColor) {
      color = null;
      if (+value > +max) {
        color = _.last(COLOR_SCALE);
      } else if (+value !== +max) {
        const yDomain = asymmetricDistribution(min, avg, max);
        const colors = COLOR_SCALE;
        const colorScale = d3.scale.linear().domain(yDomain).range(colors);
        color = colorScale(value);
      } else {
        color = BENCHMARK_COLOR;
      }
    }

    const name = data ? data.last_name : '';
    bars.push({
      name: lang_[i] ? lang_[i] : indicator,
      classes: [color, class_status, 'mean', 'benchmark'],
      values: [
        {
          x: name,
          y: v0,
          desc: rank,
          color,
          contentType,
        },
        {
          x: `${name}2`,
          y: v0,
          desc: 'none',
          color,
          contentType,
        },
        { x: 'mean', y: v1, contentType },
        { x: 'benchmark', y: v2, contentType },
      ],
      benchmark_name: bn,
      benchmark_id: bid,
      indicator,
    });
  });

  return bars;
};

export const createBarChartsCompare = (data, barsName) => {
  if (!data[0] || !data[1]) return { classes: [], values: [] };

  return barsName.map(name => ({
    classes: [
      'home_season',
      'home_match',
      'home_match',
      'mean',
      'away_match',
      'away_match',
      'away_season',
    ],
    values: [
      {
        y: data[0][`${name}_season`] ? data[0][`${name}_season`].toFixed(2) : 0,
        x: 0,
      },
      { y: data[0][`${name}`] ? data[0][`${name}`].toFixed(2) : 0, x: 1 },
      { y: data[0][`${name}`] ? data[0][`${name}`].toFixed(2) : 0, x: 2 },
      {
        y: data[0][`${name}_mean`] ? data[0][`${name}_mean`].toFixed(2) : 0,
        x: 3,
      },
      { y: data[1][`${name}`] ? data[1][`${name}`].toFixed(2) : 0, x: 4 },
      { y: data[1][`${name}`] ? data[1][`${name}`].toFixed(2) : 0, x: 5 },
      {
        y: data[1][`${name}_season`] ? data[1][`${name}_season`].toFixed(2) : 0,
        x: 6,
      },
    ],
    name,
  }));
};

// Check if url exists, not working3 yet
/*export const _isValidURL = (url, callback) => {
  const img = document.body.appendChild(document.createElement('img'));
  (img.width = 1), (img.height = 1), (img.style.display = 'none');
  img.onload = function () {
    callback(true);
  };
  img.onerror = function () {
    callback(false);
  };
  img.src = url;
};*/

// Ordinal Number (1st, 2nd, 3rd, 4th)
export function getGetOrdinal(n) {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
}

// Convert special characters, e.g. for search
export const removeDiacritics = str =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export function generateFilterlist({
  countries = {},
  leagues = {},
  user_leagues = [],
  teams = {},
  positions = {},
  formations = {},
  seasons = [],
  regions = {},
  season_current = null,
  mode,
  position_split_mode = 2,
}) {
  const list = {
    seasons: {
      id: 'ALL',
      name: 'All',
      active: true,
      open: true,
      items: [],
      type: 'group',
    },
    leagues: {
      id: 'ALL',
      name: 'All',
      active: false,
      open: true,
      items: [],
      type: 'group',
    },
    teams: {
      id: 'ALL',
      name: 'All',
      active: true,
      open: true,
      items: [],
      type: 'group',
    },
    positions: {
      id: 'ALL',
      name: 'All',
      active: true,
      open: true,
      items: [],
      type: 'group',
    },
    formations: {
      id: 'ALL',
      name: 'All',
      active: true,
      open: true,
      items: [],
      type: 'group',
    },
    countries: {
      id: 'ALL',
      name: 'All',
      active: true,
      open: true,
      items: [],
      type: 'group',
    },
    preferred_foot: {
      id: 'ALL',
      name: 'All',
      active: true,
      type: 'group',
      items: [
        { name: 'Left', id: 'left', active: true, type: 'item' },
        { name: 'Right', id: 'right', active: true, type: 'item' },
        { name: 'None', id: 'both', active: true, type: 'item' },
        { name: 'Unknown', id: '-', active: true, type: 'item' },
      ],
    },
  };

  list.countries.items = _.chain(regions)
    .map(region => {
      return {
        id: region.id,
        name: region.name,
        active: true,
        open: false,
        collapse: true,
        items: _.chain(countries)
          .filter(country => +country.region_id === region.id)
          .map(country => ({
            id: country.abbreviation,
            name: country.country,
            active: true,
          }))
          .sortBy('name')
          .value(),
      };
    })
    .filter(countries => countries.items.length)
    .sortBy('name')
    .value();

  // generate list for leagues
  // filter leagues list based on user group restriction
  let userGroupLeagues = _.chain(leagues)
    .filter((league, key) => user_leagues.indexOf(key) > -1)
    .values();
  userGroupLeagues = userGroupLeagues.length
    ? userGroupLeagues
    : _.values(leagues);

  const isActiveLeaguesLevel = (leagues, country) => {
    return (
      _.chain(leagues)
        .filter(
          league =>
            parseInt(country.id, 10) === parseInt(league.country_id, 10),
        )
        .filter(league => league.format_id !== 1 && league.format_id !== 6)
        .value().length === 0
    );
  };

  list.leagues.items = _.chain(regions)
    .map(region => {
      return {
        id: region.id,
        name: region.name,
        active: false,
        open: false,
        collapse: true,
        type: 'group',
        items: _.chain(countries)
          .filter(c => +c.region_id === +region.id)
          .map(country => {
            return {
              id: country.id,
              name: country.country,
              active: isActiveLeaguesLevel(userGroupLeagues, country),
              open: false,
              collapse: true,
              type: 'group',
              items: _.chain(userGroupLeagues)
                .filter(
                  league =>
                    parseInt(country.id, 10) ===
                    parseInt(league.country_id, 10),
                )
                .map(league => ({
                  id: league.id,
                  name: league.name,
                  active: league.format_id === 1 || league.format_id === 6,
                  divisionlevel: +league.divisionlevel,
                  country_id: country.id,
                  format_id: league.format_id,
                  type: 'item',
                }))
                .sortBy(['divisionlevel', 'name'])
                .value(),
            };
          })
          .filter(leagues => leagues.items.length)
          .sortBy(['name'])
          .map(league => {
            if (league.name.toLowerCase() !== 'international') {
              return league;
            }

            return {
              ...league,
              active: mode === ModeType.MATCH,
              open: false,
              collapse: true,
              items: league.items.map(item => ({
                ...item,
                active: mode === ModeType.MATCH,
              })),
            };
          })
          .sortBy(leagues.items, [
            item => (item.name.toLowerCase() === 'international' ? 1 : 2),
          ])
          .value(),
      };
    })
    .map(region => {
      return {
        ...region,
        active:
          region.items.filter(country => country.active === false).length === 0,
      };
    })
    .filter(region => region.items.length)
    // reduce countries if there is only one in region
    .map(region => {
      if (region.items.length === 1) {
        return {
          ...region,
          name: region.items[0].name,
          items: region.items[0].items,
        };
      }
      return { ...region };
    })
    .sortBy('name')
    .sortBy(item => {
      return item.name.toLowerCase() === 'international' ? 1 : 2;
    })
    .value();

  let userGroupTeams;

  if (mode === ModeType.TEAM) {
    userGroupTeams = _.chain(teams)
      .filter(
        (team, key) =>
          team.data &&
          team.data.indexOf(season_current) > -1 &&
          user_leagues.indexOf(team.league_id) > -1,
      )
      .value();
  } else {
    userGroupTeams = _.chain(teams)
      .filter(
        (team, key) => team.data && user_leagues.indexOf(team.league_id) > -1,
      )
      .value();
  }

  userGroupTeams = _.sortBy(
    userGroupTeams.length ? userGroupTeams : _.values(teams),
    'team_name',
  );

  list.teams.items = _.chain(countries)
    .map(country => {
      return {
        id: country.id,
        name: country.country,
        active: true,
        open: false,
        collapse: true,
        type: 'group',
        items: userGroupTeams
          .filter(
            team =>
              parseInt(country.id) === parseInt(team.country_id) && team.data,
          )
          .map(team => ({
            id: team.id,
            name: team.team_name,
            active: true,
            type: 'item',
          })),
      };
    })
    .filter(teams => teams.items.length)
    .sortBy(['name'])
    .value();

  // generate list for fractions
  list.formations.items = _.chain(formations)
    .filter(formation => formation.id !== 0)
    .map(formation => ({ ...formation, active: true, type: 'item' }))
    .sortBy(['name', formation => formation.name.replace('-', '').length])
    .value();

  // generate list for positions
  const position_group = _.filter(positions, o => o.upper_position_id == 0);
  const position_items = _.filter(positions, o => o.upper_position_id != 0);

  _.map(position_group, group => {
    const p_add = {
      id: group.id,
      name: group.description,
      active: true,
      open: false,
      collapse: true,
      items: [],
      type: 'item',
    };

    _.map(position_items, position => {
      if (_.isEqual(parseInt(group.id), parseInt(position.upper_position_id))) {
        p_add.items.push({
          id: position.id,
          name: position.description,
          active: true,
        });
      }
    });

    if (p_add.items && p_add.items.length > 0) list.positions.items.push(p_add);
  });

  // generate list for seasons
  let s_add = [];

  _.map(seasons, season => {
    if (
      ([ModeType.PLAYER, ModeType.RANKINGS].indexOf(mode) !== -1 &&
        (season.id > 2000 || season.id < 1000)) ||
      (mode === ModeType.TEAM && season.id >= 1000)
    ) {
      s_add.push({
        id: season.id,
        name: season.name,
        active: season_current === season.id,
      }); // lang[ '_' + season.id ] name_detailed: season.name,
    } else if (season.id >= 1000) {
      s_add.push({
        id: season.id,
        name: season.name,
        active: season_current === season.id,
      }); // lang[ '_' + season.id ] name_detailed: season.name,
    }
  });

  // sort data
  s_add = _.sortBy(s_add, num => {
    return num.id;
  }).reverse();
  if (s_add.length > 0) {
    list.seasons = s_add;
  } else list.seasons = [];

  return list;
}

export function getPosition(pos) {
  const firstChar = pos.charAt(0);
  const secondChar = pos.charAt(1);
  const thirdChar = pos.charAt(2);
  let positionX = 0;
  let positionY = 0;

  if (pos === 'GK') positionX = 1 / 2;
  else if (thirdChar !== '' && thirdChar !== 'B') {
    if (thirdChar === 'L') positionX = (1 / 6) * 1.9;
    else if (thirdChar === 'C') positionX = 1 / 2;
    else if (thirdChar === 'R') positionX = (1 / 6) * 4.1;
  } else if (firstChar === 'L') positionX = (1 / 6) * 0.6;
  else if (firstChar === 'R') positionX = (1 / 6) * 5.4;
  else if (firstChar === 'C') positionX = 1 / 2;

  if (pos === 'GK') positionY = 1 - (1 / 4) * 0.2;
  else if (firstChar + secondChar === 'CB') positionY = 1 - (1 / 4) * 0.8;
  else if (firstChar + secondChar === 'DM') positionY = 1 - (1 / 4) * 1.6;
  else if (firstChar + secondChar === 'CM') positionY = 1 - (1 / 2) * 1.1;
  else if (firstChar + secondChar === 'AM') positionY = 1 - (1 / 4) * 2.5;
  else if (firstChar + secondChar === 'FW') positionY = 1 - (1 / 4) * 3.3;
  else if (pos === 'LWB' || pos === 'RWB') positionY = 1 - (1 / 4) * 1.3;
  else if (secondChar === 'B') positionY = 1 - (1 / 4) * 0.9;
  else if (secondChar === 'M') positionY = 1 - (1 / 2) * 1.1;
  else if (secondChar === 'W') positionY = 1 - (1 / 4) * 3;

  return { x: positionX, y: positionY };
}

export function getPositionMatch(pos, align) {
  const { x: y, y: x } = getPosition(pos);

  switch (align) {
    case 'home':
      return { x: -(x - 1) / 2, y };
    case 'away':
      return { x: (x + 1) / 2, y: -(y - 1) };
  }
}

export function getPerformanceFromAnalyse(
  analyseData,
  information,
  indicators,
) {
  if (!analyseData || !analyseData.length || !indicators || !indicators.length)
    return {};

  const performanceFromAnalyse = {};
  const teamIndicators = _.find(analyseData, { team_id: +information.id });

  _.forEach(indicators, indicator => {
    const orderByValue = _.chain(analyseData)
      .filter(
        analyse =>
          (analyse.team_id !== information.id && +analyse.formation_id === 0) ||
          analyse.team_id === information.id,
      )
      .uniqBy('team_id')
      .orderBy(analyse => +analyse[indicator], 'desc')
      .value();

    const teamIndex = _.findIndex(orderByValue, { team_id: +information.id });

    const teamsIndicator = orderByValue.map(analyse => +analyse[indicator]);
    const teamRank =
      teamIndex !== -1
        ? teamsIndicator.indexOf(+orderByValue[teamIndex][indicator])
        : null;

    performanceFromAnalyse[indicator] =
      teamIndicators && teamIndicators[indicator]
        ? (+teamIndicators[indicator]).toFixed(2)
        : null;
    performanceFromAnalyse[`${indicator}_benchmark_id`] = orderByValue[0]
      ? orderByValue[0].team_id
      : null;
    performanceFromAnalyse[`${indicator}_benchmark_value`] =
      orderByValue[0] && orderByValue[0][indicator]
        ? (+orderByValue[0][indicator]).toFixed(2)
        : null;
    performanceFromAnalyse[`${indicator}_rank`] =
      teamRank || teamRank === 0 ? teamRank + 1 : null;
  });

  return {
    ...performanceFromAnalyse,
    team_id: information.id,
  };
}

export function getPlayerPerformanceFromAnalyse(
  analyseData,
  information,
  indicators,
  playerCanBeBenchmark = true,
) {
  if (!analyseData || !analyseData.length || !indicators || !indicators.length)
    return {};

  const performanceFromAnalyse = {};
  const playerIndicators = _.find(analyseData, {
    player_id: +information.player_id,
  });

  _.forEach(indicators, indicator => {
    const orderByValue = _.orderBy(
      analyseData,
      analyse => +analyse[indicator],
      'desc',
    );
    const playerIndex = _.findIndex(orderByValue, {
      player_id: +information.player_id,
    });

    const playersIndicator = orderByValue.map(analyse => +analyse[indicator]);

    const orderByValueForBenchmark = orderByValue.filter(indicator => {
      return !(
        !playerCanBeBenchmark && +indicator.player_id === +information.player_id
      );
    });

    performanceFromAnalyse[indicator] =
      playerIndicators && playerIndicators[indicator]
        ? +playerIndicators[indicator]
        : 0;
    performanceFromAnalyse[`${indicator}_benchmark_id`] =
      orderByValueForBenchmark[0]
        ? orderByValueForBenchmark[0].player_id
        : null;
    performanceFromAnalyse[`${indicator}_benchmark_value`] =
      orderByValueForBenchmark[0] && orderByValueForBenchmark[0][indicator]
        ? +orderByValueForBenchmark[0][indicator]
        : 0;
    performanceFromAnalyse[`${indicator}_benchmark_name`] =
      orderByValueForBenchmark[0] && orderByValueForBenchmark[0].last_name
        ? orderByValueForBenchmark[0].last_name
        : '';

    if (playerIndex === -1) return;

    const playerRank = playersIndicator.indexOf(
      +orderByValue[playerIndex][indicator],
    );
    performanceFromAnalyse[`${indicator}_rank`] =
      playerRank || playerRank === 0 ? playerRank + 1 : null;
  });

  return {
    ...performanceFromAnalyse,
    player_id: information.player_id,
    position_id_first: _.get(playerIndicators, 'position_id_first', 0),
  };
}

export function getPlayerPerformanceText(
  analyseData,
  information,
  indicators,
  mean,
  performance_current,
) {
  if (!analyseData || !analyseData.length || !indicators || !indicators.length)
    return {};

  const performanceFromAnalyse = {};
  const performanceFromAnalyseMean = [];

  const playerIndicators = _.find(analyseData, {
    player_id: +information.player_id,
  });
  const playerPosition = _.get(performance_current, 'position_id', 7); // TAKE BY DEFAULT Wing

  const playerSkillImportanceStrengths = _.get(
    EPI_SKILLIMPORTANCE_STRENGTHS_BY_POS,
    `[${playerPosition}].skills`,
    {},
  );
  const playerSkillImportanceWeaknesses = _.get(
    EPI_SKILLIMPORTANCE_WEAKNESSES_BY_POS,
    `[${playerPosition}].skills`,
    {},
  );
  const threshold = 0.2; // 20% +/-

  _.forEach(indicators, indicator => {
    const orderByValue = _.orderBy(
      analyseData,
      analyse => +analyse[indicator],
      'desc',
    );
    const playerIndex = _.findIndex(orderByValue, {
      player_id: +information.player_id,
    });

    const playersIndicator = orderByValue.map(analyse => +analyse[indicator]);

    performanceFromAnalyse[indicator] =
      playerIndicators && playerIndicators[indicator]
        ? +playerIndicators[indicator]
        : 0;
    if (playerIndex === -1) return;

    const playerRank = playersIndicator.indexOf(
      +orderByValue[playerIndex][indicator],
    );
    performanceFromAnalyse[`${indicator}_rank`] =
      playerRank || playerRank === 0 ? playerRank + 1 : null;

    const playerMean =
      typeof mean[`${indicator}_mean`] !== 'undefined'
        ? +mean[`${indicator}_mean`]
        : null;
    performanceFromAnalyse[`${indicator}_mean`] =
      ((playerMean || playerMean === 0) && performanceFromAnalyse[indicator]) ||
      performanceFromAnalyse[indicator] === 0
        ? performanceFromAnalyse[indicator] - playerMean
        : null;
    const playerMeanValue = {
      indicator,
      mean_diff: performanceFromAnalyse[`${indicator}_mean`],
      val: performanceFromAnalyse[indicator],
      val_mean: playerMean,
    };
    performanceFromAnalyseMean.push(playerMeanValue);
  });

  // take only strengths and weaknesses which are either important skills for this position or not 0 sorted by biggest difference to mean value
  performanceFromAnalyse.strengths = _.chain(performanceFromAnalyseMean)
    .filter(
      item =>
        item.mean_diff > threshold * item.val_mean &&
        !_.isEmpty(_.pick(playerSkillImportanceStrengths, item.indicator)) &&
        item.indicator !== 'season_rating',
    )
    // .sortBy(item => item.mean_diff * -1)
    .sortBy(item => playerSkillImportanceStrengths[item.indicator])
    .value();

  performanceFromAnalyse.weaknesses = _.chain(performanceFromAnalyseMean)
    .filter(
      item =>
        item.mean_diff < -(threshold * item.val_mean) &&
        !_.isEmpty(_.pick(playerSkillImportanceWeaknesses, item.indicator)) &&
        item.indicator !== 'season_rating',
    )
    // .sortBy(item => item.mean_diff)
    .sortBy(item => playerSkillImportanceWeaknesses[item.indicator])
    .value();

  performanceFromAnalyse.strengths = _.uniqBy(
    performanceFromAnalyse.strengths,
    i => i.indicator,
  );
  performanceFromAnalyse.weaknesses = _.uniqBy(
    performanceFromAnalyse.weaknesses,
    i => i.indicator,
  );

  return {
    ...performanceFromAnalyse,
  };
}

export function findBestPlayerForPosition(list, players, pos, upperPos, mode) {
  let sortedFilteredList = [];

  switch (mode) {
    case 1:
      sortedFilteredList = list
        .filter(p => +p.age < 22 && +pos.id === +p.position_first_id)
        .sort((a, b) => +b.season_rating - +a.season_rating);
      if (sortedFilteredList[0]) {
        players[+pos.id] = +sortedFilteredList[0].player_id;
        const index = list.findIndex(
          p => +p.player_id === +sortedFilteredList[0].player_id,
        );
        if (index !== -1) {
          list = [...list.slice(0, index), ...list.slice(index + 1)];
        }
      }
      return { players, list };
    case 2:
      sortedFilteredList = list
        .filter(p => +pos.id === +p.position_first_id)
        .sort((a, b) => +b.season_rating - +a.season_rating);
      if (sortedFilteredList[0]) {
        players[+pos.id] = +sortedFilteredList[0].player_id;
        const index = list.findIndex(
          p => +p.player_id === +sortedFilteredList[0].player_id,
        );
        if (index !== -1) {
          list = [...list.slice(0, index), ...list.slice(index + 1)];
        }
      }
      return { players, list };
    case 3:
      sortedFilteredList = list
        .filter(p => +pos.id === +p.position_first_id)
        .sort((a, b) => +b.offence - +a.offence);
      if (sortedFilteredList[0]) {
        players[+pos.id] = +sortedFilteredList[0].player_id;
        const index = list.findIndex(
          p => +p.player_id === +sortedFilteredList[0].player_id,
        );
        if (index !== -1) {
          list = [...list.slice(0, index), ...list.slice(index + 1)];
        }
      }
      return { players, list };
    case 4:
      sortedFilteredList = list
        .filter(p => +pos.id === +p.position_first_id)
        .sort((a, b) => +b.defence - +a.defence);
      if (sortedFilteredList[0]) {
        players[+pos.id] = +sortedFilteredList[0].player_id;
        const index = list.findIndex(
          p => +p.player_id === +sortedFilteredList[0].player_id,
        );
        if (index !== -1) {
          list = [...list.slice(0, index), ...list.slice(index + 1)];
        }
      }
      return { players, list };
    default:
      return { players, list };
  }
}

export function findBestPlayerForUpperPosition(
  list,
  players,
  pos,
  upperPos,
  mode,
) {
  let sortedFilteredList = [];

  switch (mode) {
    case 1:
      sortedFilteredList = list
        .filter(p => +p.age < 22 && +upperPos.id === +p.position_id)
        .sort((a, b) => +b.season_rating - +a.season_rating);

      if (sortedFilteredList[0]) {
        players[+pos.id] = +sortedFilteredList[0].player_id;
        const index = list.findIndex(
          p => +p.player_id === +sortedFilteredList[0].player_id,
        );
        if (index !== -1) {
          list = [...list.slice(0, index), ...list.slice(index + 1)];
        }
      }
      return { players, list };
      break;
    case 2:
      sortedFilteredList = list
        .filter(p => +upperPos.id === +p.position_id)
        .sort((a, b) => +b.season_rating - +a.season_rating);

      if (sortedFilteredList[0]) {
        players[+pos.id] = +sortedFilteredList[0].player_id;
        const index = list.findIndex(
          p => +p.player_id === +sortedFilteredList[0].player_id,
        );
        if (index !== -1) {
          list = [...list.slice(0, index), ...list.slice(index + 1)];
        }
      }
      return { players, list };
    case 3:
      sortedFilteredList = list
        .filter(p => +upperPos.id === +p.position_id)
        .sort((a, b) => +b.offence - +a.offence);

      if (sortedFilteredList[0]) {
        players[+pos.id] = +sortedFilteredList[0].player_id;
        const index = list.findIndex(
          p => +p.player_id === +sortedFilteredList[0].player_id,
        );
        if (index !== -1) {
          list = [...list.slice(0, index), ...list.slice(index + 1)];
        }
      }
      return { players, list };
    case 4:
      sortedFilteredList = list
        .filter(p => +upperPos.id === +p.position_id)
        .sort((a, b) => +b.defence - +a.defence);

      if (sortedFilteredList[0]) {
        players[+pos.id] = +sortedFilteredList[0].player_id;
        const index = list.findIndex(
          p => +p.player_id === +sortedFilteredList[0].player_id,
        );
        if (index !== -1) {
          list = [...list.slice(0, index), ...list.slice(index + 1)];
        }
      }
      return { players, list };
    default:
      return { players, list };
  }
}

export function marketValueMarks(maxRange) {
  const result = [];

  if (maxRange > 0) {
    for (let i = 0, l = 2; i < l; i += 0.25) {
      result.push(i);
    }
  }

  if (maxRange > 2) {
    for (let i = 2, l = 10; i < l; i += 0.5) {
      result.push(i);
    }
  }

  if (maxRange > 10) {
    for (let i = 10, l = 50; i < l; i += 1) {
      result.push(i);
    }
  }

  if (maxRange > 50) {
    for (let i = 50, l = 100; i <= l; i += 5) {
      result.push(i);
    }
  }

  if (maxRange > 100) {
    result.push(maxRange);
  }

  return {
    getFromSlider(number) {
      return result[number];
    },
    getFromNumber(number) {
      return _.indexOf(result, number);
    },
    getMax() {
      return _.last(result);
    },
    getClosest(number) {
      return result.reduce((prev, curr) => {
        return Math.abs(curr - number) < Math.abs(prev - number) ? curr : prev;
      });
    },
  };
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function createPaywallData(paywall, data) {
  return !paywall ? <Icon name="icon-lock" /> : data;
}

export function getGroupBy(preset) {
  const filter = preset.filterState;
  const filterFormationValue = _.find(filter.list, { name: 'formation_id' });
  const filterPositionValue = _.find(filter.list, {
    name: 'position_first_id',
  });

  const splittedPosition = filterPositionValue.mode;
  const splittedFormation = filterFormationValue.splitted || false;

  let playersGroupBy = 'position_2';

  if (splittedPosition && splittedFormation) {
    playersGroupBy = `formation_position_${splittedPosition}`;
  } else if (splittedPosition) {
    playersGroupBy = `position_${splittedPosition}`;
  } else if (splittedFormation) {
    playersGroupBy = `formation_position_${splittedPosition}`;
  }

  return playersGroupBy;
}

export function getSeason(preset, mode) {
  const filter = preset.filterState;

  const seasonList = filter.list.find(l => l.name === 'season_id');
  const seasonSlider = filter.slider.find(sl => sl.name === 'season_id');

  let season = DEFAULT_SEASON;

  if (seasonList && seasonList.data[0] > 1000) {
    season = seasonList.data[0];
  }

  if (
    (seasonSlider && mode === 'player') ||
    (seasonList && seasonList.data[0] < 1000 && seasonList.data[0] !== 0)
  ) {
    const startDateRaw = _.get(
      seasonSlider,
      'data[1]',
      _.get(seasonList, 'data[0]', null),
    );
    const startDate = moment()
      .subtract(startDateRaw, 'month')
      .format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');

    season = `${startDate}/${endDate}`;
  }

  if (seasonList && seasonList.data[0] === 0) {
    season = 0;
  }

  return season;
}

export function renderNumberValue(
  value,
  renderer = v => v,
  empty = 'N/D',
  write_zero = false,
  mins_played,
) {
  if (
    value === undefined ||
    isNaN(+value) ||
    typeof +value !== 'number' ||
    value === 0
  ) {
    return '-';
  }
  return renderer(value);
}

export function renderRatingValue(
  value,
  rating,
  mins_played,
  renderer = v => v,
) {
  if (
    value === undefined ||
    value === null ||
    isNaN(+value) ||
    typeof +value !== 'number' ||
    mins_played < NO_RATING_UNDER_MINS ||
    rating === null
  ) {
    return 'N/D';
  }
  return renderer(value);
}
