import { createSelector } from 'reselect';
import _ from 'lodash';
import { sort } from 'fast-sort';

import { removeDiacritics } from '../helper';

export const getPlayers = createSelector(
  [state => state.app.playersMetaData],
  players => {
    const playersFiltered = [];

    for (const id in players) {
      if (players[id].data) playersFiltered.push(players[id]);
    }

    return sort(playersFiltered).asc('last_name');
  },
);

export const getTeams = createSelector([state => state.app.teams], teams => {
  const teamsFiltered = [];

  for (const id in teams) {
    if (teams[id].data) teamsFiltered.push(teams[id]);
  }

  return _.sortBy(teamsFiltered, 'team_name');
});

export const getMatches = createSelector(
  [state => state.app.matchesLastData, state => state.app.teams],
  (matches, teams) => {
    return _.orderBy(matches, 'date', 'desc').map(match => {
      const team_name_home = _.get(
        teams,
        `${match.team_id_home}.team_name`,
        'Unknown',
      );
      const team_name_away = _.get(
        teams,
        `${match.team_id_away}.team_name`,
        'Unknown',
      );

      return {
        ...match,
        searchString: removeDiacritics(
          `${team_name_home.toLowerCase()} ${team_name_away.toLowerCase()}`,
        ),
      };
    });
  },
);
