import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';

import Icon from '../../../ui/svg-icon';
import { createPaywallData } from '../../../../helper';
import eventTracker from '../../../../helper/event-tracker';

const TopPlayer = props => {
  const { player, type, teams, positions, user } = props;
  let player_name = player.short_name;

  if (player.first_name || player.last_name) {
    player_name = [player.first_name, player.last_name].join(' ');
  } else if (player.short_name) {
    player_name = player.short_name;
  } else {
    player_name = 'Unknown player';
  }

  const birth_date = _.get(player, 'birth_date', 'N/D');
  const age = birth_date
    ? `${moment().diff(moment(birth_date, 'YYYY-MM-DD'), 'years')}yr`
    : 'N/D';
  const team_name = _.get(teams, `[${player.team_id}].short_club_name`, 'N/D');
  const position_name = _.get(
    positions,
    `[${player.position_id}].position_name`,
    'N/D',
  );
  const logo = (
    <span
      style={{
        backgroundImage: `url(${`https://static.sp7f.com/v2/teams/${player.team_id}.jpg`})`,
      }}
      key={player.player_id}
      className="team-logo-small"
    />
  );

  const leagues = user.leagues || [];
  const isActive = leagues.includes(+player.league_id);

  let rating;
  let last_mo_date;
  let curr_mo_date;

  if (type === 'rising') {
    rating = (
      <span className="player-rating">
        <span
          className={`player-rating__rise ${
            player.trend < 0 ? 'negative' : ''
          }`}
        >
          {+player.trend > 0 ? (
            <span className="player-rating__indicator">
              <span className="player-rating__indicator__icon">
                <Icon name="wc-position-rise" />
              </span>
              <span className="player-rating__indicator__value">
                {`+${player.trend}`}
              </span>
            </span>
          ) : (
            <span className="player-rating__indicator">
              <span className="player-rating__indicator__icon">
                <Icon name="wc-position-fall" />
              </span>
              <span className="player-rating__indicator__value">
                {player.trend}
              </span>
            </span>
          )}
        </span>
      </span>
    );
    curr_mo_date = moment().format('YYYY-MM-DD');
    last_mo_date = moment().subtract(6, 'month').format('YYYY-MM-DD');
  } else {
    rating = (
      <span className="player-rating">
        <span className="player-rating__rating">
          <span className="player-rating__indicator">
            <span className="player-rating__indicator__icon">
              <Icon name="logo" />
            </span>
            <span className="player-rating__indicator__value">
              {createPaywallData(isActive, player.rating)}
            </span>
          </span>
        </span>
      </span>
    );
  }

  return (
    <Link
      to={
        type === 'rising'
          ? `/details/player/${player.player_id}/${player.league_id}/${last_mo_date}/${curr_mo_date}/summary`
          : `/details/player/${player.player_id}`
      }
      className={`welcome__top-player ${!isActive ? 'inactive' : ''}`}
      key={player.player_id}
      onClick={() => {
        eventTracker.trackEvent('Welcome', `Click player in ${type} top`);
      }}
    >
      <div className="image-wrapper">
        <span
          className="player-photo"
          style={{
            backgroundImage: `url("https://static.sp7f.com/v2/players/${player.player_id}.jpg")`,
          }}
        />
        <span className={`player-locked ${!isActive ? 'show' : 'hide'}`}>
          <Icon name="icon-lock" />
        </span>
      </div>
      <span className="player-info">
        <div className="player-name">
          <span className="name">{player_name}</span>
          <Icon name="chevron-right" />
        </div>
        <div className="player-description">
          <span className="player-description-item">{age}</span>
          <span className="player-description-separator"> · </span>
          <span className="player-description-item">{position_name}</span>
        </div>
        <div className="player-description">
          <span className="player-description-logo">{logo}</span>
          <span className="player-description-item">{team_name}</span>
        </div>
      </span>
      {rating}
    </Link>
  );
};

const mapStateToProps = state => ({
  teams: state.app.teams,
  positions: state.app.positions,
  user: state.auth.user,
});

TopPlayer.propTypes = {
  teams: PropTypes.object,
  positions: PropTypes.object,
  player: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(TopPlayer);
