import {
  IndicatorType,
  ListType,
} from '../../../../../reducers/overview/types';
import { ModeType } from '../../../../../types/Dict';

export interface CollapseList {
  height?: number;
  open: boolean;
}

export enum CollapseGroupType {
  CORE = 'core',
  PROFILE = 'profile',
  OVERALL = 'overall',
  AGAINST_BALL = 'againstball',
  ON_BALL = 'onball',
}

export interface CollapseGroup {
  icon: string | null;
  open: boolean;
  /*lists: ListType[];
  sliders: IndicatorType[];*/
  height?: number;
}

interface Collapse {
  groups: {
    [key in CollapseGroupType]?: CollapseGroup;
  };
  lists: {
    [key in ListType]?: CollapseList;
  };
}

/* --- STATE --- */
export interface OverviewFilterState {
  [ModeType.PLAYER]: {
    collapse: Collapse;
  };
  [ModeType.TEAM]: {
    collapse: Collapse;
  };
  [ModeType.MATCH]: {
    collapse: Collapse;
  };
}
