import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import Dropdown from 'react-dropdown';
import Datepicker from 'react-datepicker/dist/react-datepicker';
import Highlighter from 'react-highlight-words';

import { removeDiacritics } from '../../../helper/index';

export default class User extends Component {
  static propTypes = {
    delete: PropTypes.func.isRequired,
    setEdit: PropTypes.func.isRequired,
    searchString: PropTypes.string,
    roles: PropTypes.array,
    groups: PropTypes.array,
    locales: PropTypes.array,
    types: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      edit: {
        firstName: '',
        lastName: '',
        email: '',
        active: true,
        expireDate: null,
        roleId: null,
        lang: null,
        type: false,
      },
      errors: {
        firstName: false,
        lastName: false,
        email: false,
      },
    };

    this.getRole = this.getRole.bind(this);
    this.getGroup = this.getGroup.bind(this);
    this.changeExpireDate = this.changeExpireDate.bind(this);
    this.save = this.save.bind(this);
    this.getLocale = this.getLocale.bind(this);
    this.getType = this.getType.bind(this);
  }

  componentWillMount() {
    const {
      firstName,
      lastName,
      email,
      groupId,
      active,
      expireDate,
      roleId,
      locale,
      type,
    } = this.props.user;
    this.setState({
      edit: {
        firstName,
        lastName,
        email,
        groupId,
        active,
        expireDate,
        roleId,
        locale,
        type,
      },
    });
  }

  getRole(roleId) {
    const { roles } = this.props;

    if (!roles.length || !roleId) return '';

    return _.find(roles, { id: roleId }).name;
  }

  getLocale(locale) {
    const { locales } = this.props;

    if (!locales.length || !locale) return '';

    return _.find(locales, { id: locale }).name;
  }

  getType(type) {
    const { types } = this.props;

    if (!types.length || !type) return '';

    return _.find(types, { id: type }).name;
  }

  getGroup(id) {
    const { groups } = this.props;

    if (!groups.length || !id) {
      return '';
    }
    const group = _.find(groups, { id });
    return group && group.name ? group.name : '';
  }

  deleteUser(id) {
    if (!window.confirm('Do you really want to delete user?')) return;

    this.props.delete(id);
  }

  activate(id) {
    const { edit } = this.state;

    this.setState({ edit: { ...edit, active: !edit.active } });
    this.props.activate({ user: { ...edit, active: !edit.active, id } });
  }

  toggleEditMode(id) {
    const {
      firstName,
      lastName,
      email,
      groupId,
      active,
      expireDate,
      roleId,
      locale,
      type,
    } = this.props.user;

    this.props.setEdit(id);

    if (!id) {
      this.setState({
        edit: {
          firstName,
          lastName,
          email,
          groupId,
          active,
          expireDate,
          roleId,
          locale,
          type,
        },
        errors: {
          firstName: false,
          lastName: false,
          email: false,
        },
      });
    }
  }

  save() {
    const errors = _.cloneDeep(this.state.errors);
    const {
      firstName,
      lastName,
      email,
      groupId,
      active,
      expireDate,
      roleId,
      locale,
      type,
    } = this.state.edit;
    const { id } = this.props.user;

    function validateEmail(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

    if (!firstName.length) {
      errors.firstName = true;
    }
    if (!lastName.length) {
      errors.lastName = true;
    }
    if (!email.length || !validateEmail(email)) {
      errors.email = true;
    }

    if (
      firstName.length > 1 &&
      lastName.length > 1 &&
      email.length &&
      validateEmail(email)
    ) {
      const error = this.props.edit({
        id,
        firstName,
        lastName,
        email,
        groupId,
        active,
        expireDate,
        roleId,
        locale,
        type,
      });

      if (error) {
        errors[error] = true;
        this.setState({ errors });
      } else {
        this.props.setEdit(null);
        this.setState({
          errors: {
            firstName: false,
            lastName: false,
            email: false,
            expireDate: null,
            roleId: null,
          },
        });
      }
    } else {
      this.setState({ errors });
    }
  }

  _changeEditValue(value, input) {
    const edit = _.cloneDeep(this.state.edit);
    const errors = _.cloneDeep(this.state.errors);
    edit[input] = value;
    errors[input] = false;

    this.setState({ edit, errors });
  }

  changeExpireDate(date) {
    this.setState({
      ...this.state,
      edit: {
        ...this.state.edit,
        expireDate: date,
      },
    });
  }

  render() {
    const { editMode, searchString } = this.props;
    const { id } = this.props.user;
    const {
      firstName,
      lastName,
      email,
      groupId,
      active,
      expireDate,
      roleId,
      locale,
      type,
    } = this.state.edit;
    const { errors } = this.state;

    if (editMode) {
      const groups = this.props.groups.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      const roles = this.props.roles.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      const locales = this.props.locales.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      const types = this.props.types.map(({ id, name }) => ({
        value: id,
        label: name,
      }));

      const groupValue = _.find(groups, { value: groupId });
      const roleValue = _.find(roles, { value: roleId });
      const localeValue = _.find(locales, { value: locale });
      const typeValue = _.find(types, { value: type });

      return (
        <tr className={`${editMode ? 'edit' : ''}`}>
          <td>{id}</td>
          <td>
            <input
              className={`${errors.firstName ? 'invalid' : ''}`}
              onChange={event =>
                this._changeEditValue(event.target.value, 'firstName')
              }
              type="text"
              value={firstName}
              placeholder="First name"
            />
          </td>
          <td>
            <input
              className={`${errors.lastName ? 'invalid' : ''}`}
              onChange={event =>
                this._changeEditValue(event.target.value, 'lastName')
              }
              type="text"
              value={lastName}
              placeholder="Last name"
            />
          </td>
          <td>
            <input
              className={`${errors.email ? 'invalid' : ''}`}
              onChange={event =>
                this._changeEditValue(event.target.value, 'email')
              }
              type="text"
              value={email}
              placeholder="Email"
            />
          </td>
          <td>
            <Datepicker
              onChange={date => {
                const dateValue = moment(date);

                if (dateValue.isValid()) {
                  this.changeExpireDate(dateValue.toDate());
                } else {
                  this.changeExpireDate(date);
                }
              }}
              dateFormat="yyyy-MM-dd"
              selected={
                moment(expireDate).isValid()
                  ? moment(expireDate).toDate()
                  : null
              }
              placeholder="Expire date"
              isClearable
            />
          </td>
          <td className={`${groupValue ? 'selected' : ''}`}>
            <Dropdown
              options={groups}
              onChange={option => {
                this._changeEditValue(option.value, 'groupId');
              }}
              value={groupValue}
              placeholder="Group"
            />
          </td>
          <td>
            <Dropdown
              options={roles}
              onChange={option => {
                this._changeEditValue(option.value, 'roleId');
              }}
              value={roleValue}
              placeholder="Group"
            />
          </td>
          <td>
            <Dropdown
              options={locales}
              onChange={option => {
                this._changeEditValue(option.value, 'locale');
              }}
              value={localeValue}
              placeholder="Locale"
            />
          </td>
          <td>
            <Dropdown
              options={types}
              onChange={option => {
                this._changeEditValue(option.value, 'type');
              }}
              value={typeValue}
              placeholder="Type"
            />
          </td>
          <td className="controls-col">
            <a className="green-btn" href="#" onClick={this.save}>
              Save
            </a>
            <a
              className="red-btn"
              href="#"
              onClick={this.toggleEditMode.bind(this, null)}
            >
              Cancel
            </a>
          </td>
        </tr>
      );
    }
    return (
      <tr>
        <td>
          <span className="align">{id}</span>
        </td>
        <td>
          <span className="align">
            <Highlighter
              highlightClassName="word_highlight"
              searchWords={searchString.split(/\s+/)}
              textToHighlight={firstName}
              sanitize={text => removeDiacritics(text)}
            />
          </span>
        </td>
        <td>
          <span className="align">
            <Highlighter
              highlightClassName="word_highlight"
              searchWords={searchString.split(/\s+/)}
              textToHighlight={lastName}
              sanitize={text => removeDiacritics(text)}
            />
          </span>
        </td>
        <td>
          <span className="align">{email}</span>
        </td>
        <td>
          <span className="align">
            {expireDate ? moment(expireDate).format('YYYY-MM-DD') : '–'}
          </span>
        </td>
        <td>
          <span className="align">{this.getGroup(groupId)}</span>
        </td>
        <td>
          <span className="align">{this.getRole(roleId)}</span>
        </td>
        <td>
          <span className="align">{this.getLocale(locale)}</span>
        </td>
        <td>
          <span className="align">{this.getType(type)}</span>
        </td>
        <td className="controls-col">
          <a
            className={`${active ? 'orange' : 'blue'}-btn`}
            href="#"
            onClick={this.activate.bind(this, id)}
          >
            {active ? 'Deactivate' : 'Activate'}
          </a>
          <a
            className="green-btn"
            href="#"
            onClick={this.toggleEditMode.bind(this, id)}
          >
            Edit
          </a>
          <a
            className="red-btn"
            href="#"
            onClick={this.deleteUser.bind(this, id)}
          >
            Delete
          </a>
        </td>
      </tr>
    );
  }
}
