import { OverviewState } from '../types';
import { player } from './player';
import { team } from './team';
import { match } from './match';
import { rankings } from './rankings';

export const initialState: OverviewState = {
  changeNum: 0,
  showTooltip: { id: null, x: null, y: null },
  sortedDataList: [],
  player,
  team,
  match,
  rankings,
  activePointData: {
    player: null,
    team: null,
    match: null,
  },
};
