import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Slider from '../../../../../ui/slider';
import { IndicatorType } from '../../../../../../reducers/overview/types';
import { selectSliderFilter } from '../../../../../../reducers/overview/selectors';
import { ModeType } from '../../../../../../types/Dict';
import { RootState } from '../../../../../../types';
import { changeSliderVal } from '../../../../../../actions/overview';
import lang from '../../../../../../lang';

interface Props {
  sliderName: IndicatorType;
  mode: ModeType;
}

export function SliderField(props: Props) {
  const { mode, sliderName } = props;
  const dispatch = useDispatch();
  const slider = useSelector((state: RootState) =>
    selectSliderFilter(state, { mode, sliderName }),
  );

  if (!slider) return null;

  const { minMax, step, data } = slider;
  const minDistance = 0.5;

  const onChanged = value => {
    dispatch(changeSliderVal(sliderName, value, mode));
  };

  return (
    <div className="filters__slider">
      <h2>{lang[sliderName]}</h2>
      <Slider
        value={data}
        min={minMax[0]}
        max={minMax[1]}
        step={step}
        minDistance={minDistance}
        onChanged={onChanged}
      />
    </div>
  );
}
