import {
  IndicatorType,
  ListType,
  ModeState,
  TeamListsId,
  TeamSlidersId,
} from '../types';

export const team: ModeState = {
  layouts: {
    contentWidth: null,
    visualHeight: null,
    tableHeight: 0,
    sidebarShow: true,
    tableVisible: 'hidden',
    initialTableShow: false,
  },
  scatterplot: {
    xVal: IndicatorType.STABILITY,
    yVal: IndicatorType.DEFENCE,
    cVal: IndicatorType.SEASON_RATING,
    sVal: IndicatorType.OFFENCE,
    cRange: [
      '#ecd100',
      '#c6cd19',
      '#a3c92b',
      '#84c43c',
      '#68c04c',
      '#4dbd5e',
      '#31ba71',
      '#00b886',
    ],
    xDomain: null,
    yDomain: null,
    cDomain: null,
    lassoSelection: null,
    legendList: [
      IndicatorType.SEASON_RATING,
      IndicatorType.OFFENCE,
      IndicatorType.DEFENCE,
      IndicatorType.STABILITY,
      IndicatorType.TACKLE_OFFENCE,
      IndicatorType.TACKLE_DEFENCE,
      IndicatorType.INTERCEPT_OFFENCE,
      IndicatorType.INTERCEPT_DEFENCE,
      IndicatorType.BLOCKED,
      IndicatorType.SAVE,
      IndicatorType.AERIAL_OFFENCE,
      IndicatorType.AERIAL_DEFENCE,
      IndicatorType.TAKE_ON,
      IndicatorType.CROSSING,
      IndicatorType.PASS_SHORT,
      IndicatorType.PASS_LONG,
      IndicatorType.RECEPTION,
      IndicatorType.DELIVERY,
      IndicatorType.SCORING,
      IndicatorType.SCORING_EFFORT,
    ],
    sortLegendList: [
      IndicatorType.ALPHABETICALLY,
      IndicatorType.SEASON_RATING,
      IndicatorType.OFFENCE,
      IndicatorType.DEFENCE,
      IndicatorType.STABILITY,
      IndicatorType.TACKLE_OFFENCE,
      IndicatorType.TACKLE_DEFENCE,
      IndicatorType.INTERCEPT_OFFENCE,
      IndicatorType.INTERCEPT_DEFENCE,
      IndicatorType.BLOCKED,
      IndicatorType.SAVE,
      IndicatorType.AERIAL_OFFENCE,
      IndicatorType.AERIAL_DEFENCE,
      IndicatorType.TAKE_ON,
      IndicatorType.CROSSING,
      IndicatorType.PASS_SHORT,
      IndicatorType.PASS_LONG,
      IndicatorType.RECEPTION,
      IndicatorType.DELIVERY,
      IndicatorType.SCORING,
      IndicatorType.SCORING_EFFORT,
    ],
  },
  table: {
    sortBy: IndicatorType.SEASON_RATING,
    sortDirection: 'DESC',
    sortedData: null,
    xVal: IndicatorType.STABILITY,
    yVal: IndicatorType.DEFENCE,
    cVal: IndicatorType.SEASON_RATING,
    sVal: IndicatorType.OFFENCE,
  },
  filterComponents: {
    filterLoaded: false,
    changeFilter: {
      slider: [], // filter data for 250 min minutes at load
      list: [ListType.LEAGUE_ID], // filter out international leagues
    },
    switcher: {},
    slider: {
      [IndicatorType.SEASON_RATING]: {
        id: TeamSlidersId[IndicatorType.SEASON_RATING],
        name: IndicatorType.SEASON_RATING,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.STABILITY]: {
        id: TeamSlidersId[IndicatorType.DEFENCE],
        name: IndicatorType.STABILITY,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.DEFENCE]: {
        id: TeamSlidersId[IndicatorType.DEFENCE],
        name: IndicatorType.DEFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.OFFENCE]: {
        id: TeamSlidersId[IndicatorType.OFFENCE],
        name: IndicatorType.OFFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.TACKLE_DEFENCE]: {
        id: TeamSlidersId[IndicatorType.TACKLE_DEFENCE],
        name: IndicatorType.TACKLE_DEFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.TACKLE_OFFENCE]: {
        id: TeamSlidersId[IndicatorType.TACKLE_OFFENCE],
        name: IndicatorType.TACKLE_OFFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.INTERCEPT_DEFENCE]: {
        id: TeamSlidersId[IndicatorType.INTERCEPT_DEFENCE],
        name: IndicatorType.INTERCEPT_DEFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.INTERCEPT_OFFENCE]: {
        id: TeamSlidersId[IndicatorType.INTERCEPT_OFFENCE],
        name: IndicatorType.INTERCEPT_OFFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.BLOCKED]: {
        id: TeamSlidersId[IndicatorType.BLOCKED],
        name: IndicatorType.BLOCKED,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.SAVE]: {
        id: TeamSlidersId[IndicatorType.SAVE],
        name: IndicatorType.SAVE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.AERIAL_OFFENCE]: {
        id: TeamSlidersId[IndicatorType.AERIAL_OFFENCE],
        name: IndicatorType.AERIAL_OFFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.AERIAL_DEFENCE]: {
        id: TeamSlidersId[IndicatorType.AERIAL_DEFENCE],
        name: IndicatorType.AERIAL_DEFENCE,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.TAKE_ON]: {
        id: TeamSlidersId[IndicatorType.TAKE_ON],
        name: IndicatorType.TAKE_ON,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.CROSSING]: {
        id: TeamSlidersId[IndicatorType.CROSSING],
        name: IndicatorType.CROSSING,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.PASS_SHORT]: {
        id: TeamSlidersId[IndicatorType.PASS_SHORT],
        name: IndicatorType.PASS_SHORT,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.PASS_LONG]: {
        id: TeamSlidersId[IndicatorType.PASS_LONG],
        name: IndicatorType.PASS_LONG,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.RECEPTION]: {
        id: TeamSlidersId[IndicatorType.RECEPTION],
        name: IndicatorType.RECEPTION,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.DELIVERY]: {
        id: TeamSlidersId[IndicatorType.DELIVERY],
        name: IndicatorType.DELIVERY,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.SCORING]: {
        id: TeamSlidersId[IndicatorType.SCORING],
        name: IndicatorType.SCORING,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.SCORING_EFFORT]: {
        id: TeamSlidersId[IndicatorType.SCORING_EFFORT],
        name: IndicatorType.SCORING_EFFORT,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.5,
      },
      [IndicatorType.COMPETITION_LEVEL]: {
        id: TeamSlidersId[IndicatorType.COMPETITION_LEVEL],
        name: IndicatorType.COMPETITION_LEVEL,
        data: [0, 10],
        minMax: [0, 10],
        step: 0.25,
      },
    },
    list: {
      [ListType.SEASON_ID]: {
        id: TeamListsId[ListType.SEASON_ID],
        name: ListType.SEASON_ID,
        data: [],
      },
      [ListType.LEAGUE_ID]: {
        id: TeamListsId[ListType.LEAGUE_ID],
        name: ListType.LEAGUE_ID,
        data: [],
      },
      [ListType.TEAM_ID]: {
        id: TeamListsId[ListType.TEAM_ID],
        name: ListType.TEAM_ID,
        data: [],
      },
      [ListType.FORMATION_ID]: {
        id: TeamListsId[ListType.FORMATION_ID],
        name: ListType.FORMATION_ID,
        data: [],
      },
      [ListType.FAVORITE_LEAGUES]: {
        id: TeamListsId[ListType.FAVORITE_LEAGUES],
        name: ListType.FAVORITE_LEAGUES,
        data: [],
      },
    },
  },
};
