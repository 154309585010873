import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import Dropdown from 'react-dropdown';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';

import SeasonSelect from '../layouts/header/season-select';
import Image from '../../ui/image';
import Icon from '../../ui/svg-icon';
import { DataItem } from '../../ui/data-item';
import NotesMobile from '../../favorites/notes/components/notes-mobile';
import { database } from '../../../constants/database';

import {
  changeFormation,
  changeLeague,
  changePosition,
  changeSeason,
} from '../../../actions/details';
import { addToFavorite, removeFromFavorite } from '../../../actions/app';
import lang from '../../../lang';

import FavoriteStar from '../../ui/favorite-star-with-category';

class TopBar extends Component {
  static defaultProps = {
    tab: 'summary',
  };

  constructor(props) {
    super(props);

    this.renderMainInfo = this.renderMainInfo.bind(this);
    this.renderProfileDetails = this.renderProfileDetails.bind(this);
    this.changeSeason = this.changeSeason.bind(this);
    this.changeLeague = this.changeLeague.bind(this);
    this.changeFormation = this.changeFormation.bind(this);
    this.changePosition = this.changePosition.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
  }

  renderMainInfo() {
    const { information, mode } = this.props.details;
    const { teams } = this.props.app;
    const {
      first_name = '',
      last_name = '',
      team_name = '',
      league_id = '',
      team_id = '',
      league_name = null,
      country_name = '',
    } = information;
    const name_last =
      last_name.length > 15 ? `${last_name.substring(0, 15)}.` : last_name;
    let name_team = team_name;
    let emblemUrl;

    if (!team_name && team_id) {
      name_team = teams[team_id].team_name;
    }

    name_team =
      name_team && name_team.length > 20
        ? `${name_team.substring(0, 20)}.`
        : name_team;

    switch (mode) {
      case 'player':
        emblemUrl = team_id ? `${database.assets}teams/${team_id}.jpg` : '';

        return (
          <div>
            <div className="firstname">{first_name}</div>
            <div className="lastname">{name_last}</div>
            <Link className="team" to={`/details/team/${team_id}/summary`}>
              {emblemUrl ? <Image src={emblemUrl} aspectRatio={0.769} /> : ''}
              {` ${name_team != null ? name_team : 'Unknown'}`}
            </Link>
          </div>
        );

      case 'team':
        emblemUrl = league_id
          ? `${database.assets}leagues/${league_id}.png`
          : '';

        return (
          <div>
            <div className="lastname">{name_team}</div>
            <div className="team">
              {emblemUrl ? <Image src={emblemUrl} aspectRatio={0.769} /> : null}
              {league_name ? ` ${league_name},` : ''} {` ${country_name}`}
            </div>
          </div>
        );
    }
  }

  renderProfileDetails() {
    const { information = [], mode } = this.props.details;
    const { app } = this.props;
    const {
      first_nationality,
      weight,
      age,
      preferred_foot,
      height,
      manager_first_name,
      manager_last_name,
      stadium_name,
      deceased,
      contract_expiry,
      birth_date,
      country_id,
      marketvalue,
    } = information;

    const weightHeight = [
      weight && weight !== 'Unknown' ? `${weight} kg` : null,
      height && height !== 'Unknown' ? `${height} cm` : null,
    ]
      .filter(indicator => indicator)
      .join(' / ');
    const weightHeightDesc = [
      weight && weight !== 'Unknown' ? lang.weight : null,
      height && height !== 'Unknown' ? lang.height : null,
    ]
      .filter(indicator => indicator)
      .join(' / ');

    let birthYear = 'N/D';
    const marketvalueF = marketvalue > 0 ? `${marketvalue}` : 'N/D';
    if (moment(birth_date).isValid()) {
      birthYear = moment(birth_date).get('year');
    }

    const nationality = _.get(
      app.countriesByAbbr,
      `[${country_id}].country`,
      'N/D',
    );
    const flag = `https://static.sp7f.com/v2/countries/${country_id}.jpg`;

    let ageFormated = +age ? `${age} (${birthYear})` : null;

    switch (mode) {
      case 'player':
        return (
          <div>
            <DataItem
              text={nationality}
              desc={lang.nation}
              image={flag}
              aspectRatio={125 / 75}
              imageClass="country-flag"
              reverse
            />
            <DataItem text={marketvalueF} desc={lang.marketvalue} reverse />
            <DataItem text={weightHeight} desc={weightHeightDesc} reverse />
            <DataItem text={ageFormated || 'N/D'} desc={lang.age} reverse />
            <DataItem text={preferred_foot} desc={lang.foot} reverse />
            <DataItem
              text={
                contract_expiry && contract_expiry != 1900
                  ? contract_expiry
                  : '–'
              }
              desc={lang.contract}
              reverse
            />
            <DataItem
              text={
                deceased && moment(deceased, 'YYYY-MM-DD').format('YYYY-MM-DD')
              }
              desc={lang.deceased}
              reverse
            />
          </div>
        );
        break;
      case 'team':
        return (
          <div>
            <DataItem text={stadium_name} desc={lang.stadium} reverse />
            {manager_first_name || manager_last_name ? (
              <DataItem
                text={`${manager_first_name} ${manager_last_name}`}
                desc={lang.coach}
                reverse
              />
            ) : null}
          </div>
        );
        break;
    }
  }

  changeSeason(season) {
    const { id, current_league, mode } = this.props.details;
    const { tab, changeSeason } = this.props;

    changeSeason(season);
    browserHistory.push(
      `/details/${mode}/${id}/${current_league}/${season.join('/')}/${tab}`,
    );
  }

  changeLeague(value) {
    const { id, current_season, mode } = this.props.details;
    const { tab, changeLeague } = this.props;

    changeLeague(value);
    browserHistory.push(
      `/details/${mode}/${id}/${value}/${current_season.join('/')}/${tab}`,
    );
  }

  changeFormation(value) {
    const { id, current_season, current_league, mode } = this.props.details;
    const { tab, changeFormation } = this.props;

    changeFormation(value);
    browserHistory.push(
      `/details/${mode}/${id}/${current_league}/${current_season.join(
        '/',
      )}/${tab}?formation=${value}`,
    );
  }

  changePosition(value) {
    const { id, current_season, current_league, mode } = this.props.details;
    const { tab, changePosition } = this.props;

    changePosition(value);
    browserHistory.push(
      `/details/${mode}/${id}/${current_league}/${current_season.join(
        '/',
      )}/${tab}?position=${value}`,
    );
  }

  renderFilter() {
    const {
      current_league,
      current_season,
      performance_current,
      current_formation,
      current_position,
      information,
      lineups,
      position,
      mode,
    } = this.props.details;
    const { seasons, leagues, formations, positions } = this.props.app;

    if (!_.size(information)) return null;

    let within6months = false;
    let data = _.get(information, 'data', '');
    let dateData = '';

    const season_active = current_season || performance_current.season_id;

    if (current_season.length === 2) {
      within6months =
        parseInt(
          moment
            .duration(
              moment().subtract(6, 'month').diff(moment(current_season[0])),
            )
            .asMonths(),
          10,
        ) <= 0;

      if (within6months) {
        dateData = _.get(information, `date_data`);
      }
    }

    const comb = data ? data.replace(/ /g, '').split(',') : [];
    const dateComb =
      dateData && within6months ? dateData.replace(/ /g, '').split(',') : [];

    let season_available = [parseInt(season_active)];

    const league_available = _.chain([...comb, ...dateComb])
      .map(SPL => SPL.split(':'))
      .filter(([season]) => {
        if (current_season.length === 1 && +season === +current_season[0]) {
          return true;
        } else if (current_season.length === 2) {
          if (
            season.indexOf('-') === -1 &&
            +season >=
              +current_season[0].substr(0, current_season[0].indexOf('-')) &&
            +season <=
              +current_season[1].substr(0, current_season[1].indexOf('-'))
          ) {
            return true;
          } else if (
            season.indexOf('-') > -1 &&
            new Date(`${season}-31`) >= new Date(current_season[0]) &&
            new Date(`${season}-01`) <= new Date(current_season[1])
          ) {
            return true;
          }
        }

        return false;
      })
      .map(([, league]) => parseInt(league, 10))
      .uniq()
      .sortBy()
      .value();

    season_available = _.sortBy(_.uniq(season_available));

    // generate dropdown with all seasons and leagues
    const season_options = [];
    let season_defaultOption = null;
    _.each(season_available, d => {
      let season_active_label = seasons.filter(s => s.id == d);
      season_active_label =
        season_active_label.length > 0 ? season_active_label[0].name : '';
      season_options.push({ value: d, label: season_active_label });

      if (season_active == d)
        season_defaultOption = season_options[season_options.length - 1];
    });

    const league_options = [];
    let league_defaultOption = null;
    _.each(league_available, d => {
      league_options.push({
        value: d,
        label: _.get(leagues, `${d}.name`, 'Unknown league'),
      });
      if (current_league == d)
        league_defaultOption = league_options[league_options.length - 1];
    });
    // if only one league exist deactivate dropdown
    const league_dropdown_status = league_options.length <= 1;

    const formationOptions = [
      { value: '0', label: 'All' },
      ...lineups.map(lineup => ({
        value: `${lineup.formation_id}`,
        label: _.get(formations, `${lineup.formation_id}.name`),
      })),
    ];

    const league = {
      disabled: league_dropdown_status,
      options: league_options.filter(
        league => league.label && league.label.length,
      ),
      value: league_defaultOption,
    };

    const formation = {
      disabled: false,
      options: formationOptions,
      value: current_formation
        ? _.find(formationOptions, { value: current_formation })
        : { value: '0', label: 'All' },
    };

    let positionSelectProps;
    if (mode === 'player') {
      const positionsByGroup = _.groupBy(
        position,
        ({ position_id }) => positions[position_id].upper_position_id,
      );
      const options = [];

      for (const upper_position_id in positionsByGroup) {
        for (const upper_position_id in positionsByGroup) {
          options.push({
            value: `${upper_position_id}`,
            label: _.get(positions, `${upper_position_id}.position_name`, ''),
            className: 'position_group',
          });

          positionsByGroup[upper_position_id].map(({ position_id }) =>
            options.push({
              value: `${position_id}`,
              label: _.get(positions, `${position_id}.position_name`, ''),
              className: 'position_detail',
            }),
          );
        }
      }

      const positionOptions = [
        {
          value: '0',
          label: 'Overall',
          className: 'position_group',
        },
        ...options,
      ];

      positionSelectProps = {
        disabled: false,
        options: positionOptions,
        value: current_position
          ? _.find(positionOptions, { value: `${current_position}` })
          : {
              value: '0',
              label: 'Overall',
              className: 'position_group',
            },
      };
    }

    return (
      <div>
        <div className="row">
          <div className="col-5 header__actionButtons_container season">
            <SeasonSelect onChange={this.changeSeason} />
          </div>

          <div className="col-1">&nbsp;</div>

          <div className="col-6 header__actionButtons_container league">
            <DataItem desc={lang.league} reverse>
              <div className="data-text">
                <Dropdown
                  {...league}
                  onChange={e => {
                    this.changeLeague(e.value);
                  }}
                  placeholder=""
                />
              </div>
            </DataItem>
          </div>
        </div>
        {mode === 'team' ? (
          <div className="row">
            <div className="col-5 header__actionButtons_container formation">
              <DataItem desc={lang.formation_id} reverse>
                <div className="data-text">
                  <Dropdown
                    {...formation}
                    onChange={e => {
                      this.changeFormation(e.value);
                    }}
                    placeholder=""
                  />
                </div>
              </DataItem>
            </div>
          </div>
        ) : null}
        {mode === 'player' ? (
          <div className="row">
            <div className="col-5 header__actionButtons_container position">
              <DataItem desc={lang.position_id} reverse>
                <div className="data-text">
                  <Dropdown
                    {...positionSelectProps}
                    onChange={e => {
                      this.changePosition(e.value);
                    }}
                    placeholder=""
                  />
                </div>
              </DataItem>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  pdfLink(url, event) {
    event.preventDefault();
    const pdfWindow = window.open('', '', '');

    axios.get('/file/token').then(result => {
      const { token } = result.data;
      if (token) {
        pdfWindow.location = `${url}&token=${token}`;
      }
    });
  }

  render() {
    const {
      id,
      current_league,
      current_season,
      performance_current,
      current_formation,
      information,
      mode,
    } = this.props.details;
    const { seasons, leagues, favorites } = this.props.app;

    const url_placeholder = `${database.assets}img/profile-placeholder.png`;

    const season_active = current_season || performance_current.season_id;

    const comb =
      information && information.data
        ? information.data.replace(/ /g, '').split(',')
        : [];
    let season_available = [parseInt(season_active)];
    let league_available = [];
    _.each(comb, (d, i) => {
      const v = d.split(':');
      let y = v[0],
        l = v[1];
      const found = _.findIndex(season_available, o => o == y);
      if (found == -1) season_available.push(parseInt(y));
      if (y == season_active) league_available.push(parseInt(l));
    });

    season_available = _.sortBy(season_available);
    league_available = _.sortBy(league_available);

    // generate dropdown with all seasons and leagues
    const season_options = [];
    let season_defaultOption = null;
    _.each(season_available, d => {
      let season_active_label = seasons.filter(s => s.id == d);
      season_active_label =
        season_active_label.length > 0 ? season_active_label[0].name : '';
      season_options.push({ value: d, label: season_active_label });

      if (season_active == d)
        season_defaultOption = season_options[season_options.length - 1];
    });

    const league_options = [];
    let league_defaultOption = null;
    _.each(league_available, d => {
      league_options.push({
        value: d,
        label: _.get(leagues, `${d}.name`, ''),
      });
      if (current_league == d)
        league_defaultOption = league_options[league_options.length - 1];
    });
    // if only one league exist deactivate dropdown
    const league_dropdown_status = league_options.length <= 1;

    const league = {
      disabled: league_dropdown_status,
      options: league_options,
      value: league_defaultOption,
    };

    const season = {
      disabled: false,
      options: season_options,
      value: season_defaultOption,
    };

    const favorite = _.find(
      favorites[mode],
      favorite => favorite[`${mode}_id`] == id,
    );

    const changeFavorite = () => {
      this.props[favorite ? 'removeFromFavorite' : 'addToFavorite']({
        id,
        mode,
      });
    };

    return (
      <div className="bar-container">
        <div className="header">
          <div className="header__profile_key">
            <div className="row">
              <div className="col-3">
                <Image
                  src={
                    id
                      ? `${database.assets}${mode}s/${id}.jpg`
                      : url_placeholder
                  }
                  backgroundColor={mode === 'team' ? 'white' : null}
                  aspectRatio={0.768}
                />
              </div>
              <div className="header__profile_key_name">
                {this.renderMainInfo()}
              </div>
            </div>
          </div>

          <div className="header__actionButtons_container primary">
            {mode === 'player' ? (
              <FavoriteStar
                className="btn_star"
                favorite={favorite}
                item={information}
                mode="player"
              />
            ) : null}
            {mode === 'player' ? (
              <NotesMobile player_id={id} eventTypes={['click', 'touchend']} />
            ) : null}
            {mode ? (
              <a
                className="icon-pdf"
                onClick={this.pdfLink.bind(
                  this,
                  `${database.pdf.details}${mode}/${id}/${current_season.join(
                    '/',
                  )}/${current_league}/Details?sortAlternatives=score${
                    current_formation ? `&formation=${current_formation}` : ''
                  }`,
                )}
                target="_blank"
                href="#"
              >
                <Icon name="download" width="22px" height="22px" />
              </a>
            ) : null}
          </div>

          <div className="header__profile_detail">
            {this.renderProfileDetails()}
          </div>

          <div className="header__filter_select">{this.renderFilter()}</div>
        </div>
      </div>
    );
  } // `${database.pdf.details}${mode}/${id}/${current_season}/${current_league}/Details${ current_formation ? ('?formation=' + current_formation) : '' }`
}

export default connect(
  state => ({
    details: state.details,
    app: state.app,
  }),
  {
    changeSeason,
    changeLeague,
    addToFavorite,
    removeFromFavorite,
    changeFormation,
    changePosition,
  },
)(TopBar);
