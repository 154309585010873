import React, { Component } from 'react';
import { Link, withRouter } from 'react-router';

import Image from '../ui/image';

class NotFound extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previousLocation: window.previousLocation,
    };

    this.goBack = this.goBack.bind(this);
  }

  goBack(event) {
    event && event.preventDefault();

    const { mode, router } = this.props;
    const { previousLocation } = this.state;

    if (previousLocation) {
      router.goBack();
    } else {
      router.replace(`/overview/${mode}`);
    }
  }

  render() {
    const { mode } = this.props;
    const { previousLocation } = this.state;

    return (
      <div>
        <div className="header-container">
          <div className="bar-container">
            <div className="row header">
              <div className="col-3 header__profile_key">
                <div className="row">
                  <div className="col-3">
                    <Image
                      src={null}
                      backgroundColor="white"
                      aspectRatio={0.768}
                    />
                  </div>
                </div>
              </div>
              <div className="col-9 header__profile_key">
                <div className="header__actionButtons_container primary">
                  <Link to={`/overview/${mode}`}>
                    <div className="icon-close" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="nav-container" />
        </div>

        <div className="content-container">
          <div className="not-found">
            <div className="not-found-text">{`No data for this ${mode}`}</div>
            <div className="not-found-link">
              <a href="#" onClick={this.goBack}>
                Go {previousLocation ? 'back' : 'to main'}{' '}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NotFound);
