import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

const AxisLeft = props => {
  const { yScale } = props;
  const axisLeft = d3.svg.axis().scale(yScale).orient('left');

  const axisRef = axis => {
    d3.select(axis).call(axisLeft);
  };

  return <g className="axis axis--y" ref={axisRef} />;
};

AxisLeft.propTypes = {
  yScale: PropTypes.func.isRequired,
};

export default AxisLeft;
