import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';

import NotesList from '../notes-list';
import {
  createNote,
  selectNote,
  updateNotesFilter,
} from '../../../../../actions/notes';
import { addToFavorite } from '../../../../../actions/app';
import './notes-aside.scss';

class NotesAside extends Component {
  constructor(props) {
    super(props);

    this.createNote = this.createNote.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { emptyNote, notes, player_id, selectedNote } = newProps;
    const isPlayerHaveNotes = notes.filter(p => p.id === player_id).length;
    if ((!notes.length || (player_id && !isPlayerHaveNotes)) && !selectedNote) {
      newProps.selectNote(emptyNote);
    }
  }

  render() {
    const { height, filteredNotes, emptyNote, notes, player_id, filter } =
      this.props;

    const list = [...filteredNotes];

    const isPlayerHaveNotes = list.length;

    if (
      (!notes.length || (player_id && !isPlayerHaveNotes)) &&
      !filter.search
    ) {
      list.push(emptyNote);
    }

    return (
      <div className="notes-aside">
        <div className="notes-add">
          <div className="search-field-container">
            <input
              type="text"
              name="search"
              placeholder="Search..."
              className="search-field"
              id="NotesSearch"
              value={this.props.search}
              onChange={e => {
                this.props.updateNotesFilter({ search: e.target.value });
              }}
            />
            <label htmlFor="NotesSearch">
              <span className="icon icon-search" />
            </label>
          </div>
          <button className="note-add-button" onClick={this.createNote} />
        </div>
        <Scrollbars style={{ height }} autoHide>
          <div className="notes-aside__wrapper">
            <NotesList notes={list} player_id={player_id} />
          </div>
        </Scrollbars>
      </div>
    );
  }

  createNote() {
    const { user, filter, playerOptions, player_id, favorites } = this.props;

    if (!playerOptions.length && !player_id) {
      alert(
        'Please add some favorite players, or start creating notes on player profile page',
      );
      return;
    }

    if (!player_id) {
      this.props.createNote(user, filter.player_id || playerOptions[0].value);
      return;
    }

    this.props.createNote(user, player_id);
  }
}

export default connect(
  state => ({
    favorites: state.app.favorites.player,
    search: state.notes.filter.search,
    filteredNotes: state.notes.filteredNotes,
    user: state.auth.user,
    filter: state.notes.filter,
    notes: state.notes.notes,
    selectedNote: state.notes.selectedNote,
    emptyNote: state.notes.emptyNote,
    playerOptions: state.notes.dictionaries.playersOptions,
  }),
  {
    updateNotesFilter,
    createNote,
    addToFavorite,
    selectNote,
  },
)(NotesAside);
