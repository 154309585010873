import React, { Component } from 'react';

class Help extends Component {
  render() {
    return (
      <div className="help-page">
        <h1>GETTING HELP CONCERNING</h1>

        <ul className="bookmarks">
          <li>
            <a href="#Scoutpanel">Scoutpanel</a>
          </li>
          <li>
            <a href="#Overview">Overview</a>
          </li>
          <li>
            <a href="#Profile">Profile</a>
            <ul>
              <li>
                <a href="#Profile-summary">Summary</a>
              </li>
              <li>
                <a href="#Profile-performance">Performance</a>
              </li>
              <li>
                <a href="#Profile-history">History</a>
              </li>
              <li>
                <a href="#Profile-alternatives">Alternatives</a>
              </li>
            </ul>
          </li>
        </ul>

        <h3 id="Scoutpanel">Scoutpanel</h3>
        <p>
          Scoutpanel is based on a proprietary set of metrics called EPI, which
          stands for Effective Performance Indicators. These metrics consider
          the exact position, importance and outcome of an event in evaluating
          player performance.
        </p>
        <p>
          Every event is assigned a specific value depending on a variety of
          factors, which figures into a total score per category. This score is
          then converted into a 0-10 rating, where the boundaries are set by
          positive and negative benchmark performances within the group of
          players playing on the same position. The grading is centered on the 5
          as much as possible, but performance distributions don’t always allow
          the rating center to be on an exact 5.
        </p>
        <p>
          Every game with more than 20 minutes of playing time by a player is
          scaled to 90 minutes and then evaluated and rated individually for
          that player’s position, before this is averaged to a per game score in
          each performance category for every season and competition the player
          participated in. This overall score is not derived from an average of
          all category ratings, but based on the overall points score, which
          gives the different performance categories an inherent specific
          weight.
        </p>
        <p>
          Scoutpanel is divided largely into three interfaces: First: the main
          overview, consisting of a scatter plot, filters and a live table.
          Second: the player profiles. And third: the comparison feature.
        </p>
        <p>
          There are two kinds of visualization repeatedly encountered within
          Scoutpanel: Radar charts and comparative bar charts.
        </p>
        <p>
          Comparative Bar Chart: The first bar shows the player’s rating and is
          colored green when his rating is above average for his position in the
          given competition in the selected season, and red when his rating is
          below average. The second bar, the light gray one, shows that average
          rating he’s being measured against. The most-right bar shows the best
          player on that position with the competition and year.
        </p>
        <p>
          Radar Chart: This chart plots the performance categories along their
          respective axes, which gives a quick impression of a player’s specific
          profile in regards to his strengths and weaknesses. The light gray
          radar painted beneath the colored player profile as the average for
          that position in the given competition and season.
        </p>

        <h3 id="Overview">Overview</h3>
        <p>
          This is the main view when starting Scoutpanel. It consists of the
          scatter plot in the top left, a filter bar on the right, and a live
          table of players available within the current filter configuration
          down left.
        </p>
        <p>
          ZOOM IN, ZOOM OUT, LASSO: These three buttons allow zooming in,
          zooming out and selecting players with a free hand lasso tool. The
          lasso tool also filters the players shown in the table below.
        </p>
        <p>
          SCATTER PLOT: This is the scatter plot. Players available within the
          current filter criteria are visualized on four axes here: x, y, bubble
          size and bubble color.
        </p>
        <p>
          SCATTER PLOT AXES: The visualization axes can be assigned any of the
          performance categories also available as filter on the right hand
          side.
        </p>
        <p>
          LIVE TABLE: The live table shows players available after applying the
          specified filter. The column heads can be clicked to sort the table as
          desired.
        </p>
        <p>
          SEARCH, COMPARISON: These two buttons allow immediate access to other
          areas of Scoutpanel: The player search function and the player
          comparison tool.
        </p>
        <p>
          MINIMIZE FILTER BAR ARROW: This arrow button allows minimizing the
          filter bar to increase screen space for the scatter plot and live
          table.
        </p>
        <p>
          CURRENTLY CONSIDERED NUMBER OF PLAYERS: This gives the number of
          players available from the data set within the given filtering
          criteria.
        </p>
        <p>RESET: Reset all filters to default settings.</p>
        <p>
          FILTER BAR: The filter bar. You can select a season, age,
          competitions, teams, positions and values within the different
          performance categories.
        </p>
        <p>
          USERSNAP: Start Usersnap. Report bugs and give immediate feedback on
          features with an integrated screenshot tool that allows you to
          highlight, annotate and comment your screen, which is sent directly to
          the Scoutpanel development team.
        </p>

        <h3 id="Profile">Profile</h3>
        <h4 id="Profile-summary">Summary</h4>
        <p>This is the main view when looking at a player profile.</p>
        <p>
          PICTURE AND BIO: Picture, name, biographic and biometric information
        </p>
        <p>
          COMPETITION AND SEASON: Currently selected season and competition.
          From this dropdown menu all seasons and competitions available in the
          database can be selected. In addition to actual seasons, there are
          additional options that allow taking a look at his performance in the
          last 5, 10 and 20 matches.
        </p>
        <p>
          COMPARE PLAYER: Take player profile to compare screen, search for
          another player, or return to overview
        </p>
        <p>
          POSITIONS: The positions a player has played in the selected season
          and competition. The opacity of circles on the pitch shows how much of
          his time the player has spent playing a particular position. The
          lighter the color, the less has he played there. The size of the bars
          below position names show this as well. They also give an impression
          of his playing has been split among the positions.
        </p>
        <p>
          ALIGNMENT: A player’s alignment is an expression of how offensive or
          defensive he interpreted his position and how his offensive
          contributions relate to his defensive ones, compared to other players
          on his position. This includes “Very Defensive”, “Defensive”,
          “Neutral”, “Offensive”, and “Very Offensive”.
        </p>
        <p>
          BASIC STATS: A short season overview for the player, naming his team,
          the competition, the year and some basic playing stats: How many
          matches he played, how many minutes he was on the pitch, how many
          goals he scored and how many assists he delivered.
        </p>
        <p>
          SEASON RATING: The player’s season rating. This is the average of all
          his single ratings for games he played. Please be aware that ratings
          based on a few short games may be more volatile than ratings based on
          my many matches of 90 minutes.
        </p>
        <p>
          STABILITY: The stability rating gives a measure of how much the
          player’s single performance deviate from his rating. A 10 would be the
          most stable player on his position, a 0 the most unstable one in the
          data set.
        </p>
        <p>
          DEFENSIVE RATING: Here all performance categories that describe his
          defensive contribution are summed up in a defensive rating. This is
          again scaled among his peers on the same position
        </p>
        <p>
          OFFENSIVE RATING: All categories concerning his offensive contribution
          are summed up in his offensive rating.
        </p>
        <p>
          COMPETITION RANKING: When all players of the selected competition are
          ranked according to their overall rating, this gives the player’s
          position within that ranking.
        </p>
        <p>
          TEAM RANKING: When all players of the selected player’s team are
          ranked according to their overall ranking, this gives the player’s
          position within his team.
        </p>
        <p>
          TACKLE OFFENSIVE; Tackle rating based on those events, when the player
          is not in control of the ball and attempts to win the ball from an
          opposing player on the ground. This describes the volume, importance
          and success of a player’s tackle attempts in positions that are
          potentially valuable in offensive considerations.
        </p>
        <p>
          TACKLE DEFENSIVE; Tackle rating based on those events, when the player
          is not in control of the ball and attempts to win the ball from an
          opposing player on the ground. This describes the volume, importance
          and success of a player’s tackle attempts in positions that are of
          varying value in defensive considerations.
        </p>
        <p>
          INTERCEPT DEFENSIVE: Rating based on the defensive value of player
          intercepting the ball during an opponent’s pass.
        </p>
        <p>
          INTERCEPT OFFENSIVE: This rating is based on the offensive value of an
          intercept. Please note that offensive here is in relation to the
          implicit offensive value of a position when the player intercepts the
          ball.
        </p>
        <p>
          AERIAL OFFENSIVE: Aerial Offensive rating describes the players volume
          and success in winning aerial duels in positions that are potentially
          valuable in offensive considerations. Offensive here does not describe
          ball possession prior to the aerial event.
        </p>
        <p>
          AERIAL DEFENSIVE: Aerial Defensive rating describes the player’s
          volume and success in winning aerial duels in position of varying
          defensive importance. This does not describe ball possession prior to
          the aerial event.
        </p>
        <p>
          BLOCKED SHOTS: This describes the volume and importance of those
          events when a defending player blocked a shot at goal from an opposing
          player.
        </p>
        <p>
          BLOCKED PASS: This describes the volume and importance of those events
          when a defending player blocks an attempt at a pass close to the
          opposing player looking to deliver a pass. The defender is spaced in a
          way that he would be considered too far away for a tackle, and too
          close to consider his action an intercept.
        </p>
        <p>
          TAKE-ON: The take-on rating describes the volume, success and
          importance of the player trying to beat a defending player by
          dribbling past him with the ball at his feet.
        </p>
        <p>
          CROSSING: The crossing rating describes the volume, success and
          importance of the player’s crosses.
        </p>
        <p>
          PASS SHORT: The short passes rating describes the volume, success and
          importance of the player’s passes with a length below 32 meters.
        </p>
        <p>
          PASS LONG: The long passes rating describes the volume, success and
          importance of the player’s passes with a length of more than 32
          meters.
        </p>
        <p>
          FINISHING: The finishing rating describes the player’s efficiency in
          front of goal. This is not the same as a simple success ratio in terms
          of goals versus shots, but looks at what kind of positional value the
          player gave on missed attempts versus what his goals have been worth.
          Players with the same number of shots and goals can have different
          finishing ratings, depending on the position they’ve taken their shots
          from.
        </p>
        <p>
          SHOTS: The shots rating describes the sum and quality of the positions
          the player has taken his shots from.
        </p>
        <p>
          DELIVERY: This describes the sum and quality of the positions, where
          the player’s passes have been successfully received. The standard pass
          rating is based on the difference of value from where player passes to
          where it is received, while DELIVERY only concerns itself with the
          value of the end points.
        </p>
        <p>
          RECEPTION: This is the counter part to DELIVERY, and describes the sum
          and quality of the positions where a player has successfully received
          a pass.
        </p>

        <h4 id="Profile-performance">Performance</h4>
        <p>
          The performance tab shows the player’s EPI stats in a larger form and
          provides the ranking bar chart for each performance category for his
          playing position and league.
        </p>
        <p>
          The ranking bar charts are also given for non-EPI standard statistics.
          These bar charts use raw values on a per 90 minutes basis instead of a
          grading system.
        </p>

        <h4 id="Profile-history">History</h4>
        <p>
          The History page gives on overview over the player’s currently
          selected season and competition. It shows ratings per matchday split
          into overall rating as well as separate ones for defense and offense.
          Only games with a playing time of at least 20 minutes are considered
          and rated.
        </p>

        <h4 id="Profile-alternatives">Alternatives</h4>
        <p>
          This page presents a number of player that could be interesting to
          look at as alternatives to the selected player, trying to match his
          playing profile and performance. There are three different sections,
          depending on what role is currently required for team purposes:
          Immediate, Experience, Perspective.
        </p>
        <p>
          The algorithm tries to restrict the player pool according to the given
          formal criteria of each category, but widens those filters in small
          increments until a player pool of at least 30 players is given, which
          are then sorted according to how well they fit the original player,
          considering the spread and configuration of performance profiles among
          the given player population.
        </p>
        <p>
          Immediate describes a player that could potentially be considered as a
          drop-in replacement in rating and playing style, with enough matches
          played to have a sufficient sample size.
        </p>
        <p>
          Experience describes players in an older age group that might perform
          slightly worse, but given their age could be suited for backup
          purposes. Assuming that they might be backups in their current team
          and play less than first 11 starters, their requirements for playing
          time are bit relaxed.
        </p>
        <p>
          Perspective shows younger players who already show similar playing
          style and could grow into a player filling a specific role related to
          the currently selected player. Given their age, restrictions on
          playing time and overall ratings are lowered.
        </p>
      </div>
    );
  }
}

export default Help;
