import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { ColorScale, PolarChart } from 'd3-react-touch';
import BarChartGroup from '../modules/bar-chart-group';
import StandardStats from '../layouts/standard-stats';
import Icon from '../../ui/svg-icon';

import { BENCHMARK_COLOR, COLOR_SCALE } from '../../../constants';

import {
  createBarChartsBenchmark,
  createPolarCharts,
  getPerformanceFromAnalyse,
} from '../../../helper/';
import lang from '../../../lang';

class Performance extends Component {
  constructor(props) {
    super(props);

    this._renderBarCharts = this._renderBarCharts.bind(this);
  }

  _renderBarCharts(bars, className, delayStart) {
    const { teams } = this.props.app;
    const { information, current_season, current_league, current_formation } =
      this.props.details;
    const width = 115,
      height = 50,
      margin = {
        top: 13,
        right: 0,
        bottom: 1,
        left: 0,
      };
    const renderBenchmarkName = id => (teams[id] ? teams[id].abk : '');
    let animationDelayCounter = 0;

    return bars.map((bar, i) => {
      animationDelayCounter++;
      return (
        <div key={i} className={className}>
          <div className="center">
            <BarChartGroup
              {...{
                data: bar,
                width,
                height,
                margin,
                link: `/details/team/:id/${current_league}/${current_season.join(
                  '/',
                )}/summary${
                  current_formation ? `?formation=${current_formation}` : ''
                }`,
                name: information.abk,
                benchmarkName: renderBenchmarkName(bar.benchmark_id),
                animationDelay: delayStart + animationDelayCounter * 200,
                showZero: true,
                indicator: bar.indicator,
              }}
            />
          </div>
        </div>
      );
    });
  }

  render() {
    const { league_analyse, information, mean, isAccessGranted } =
      this.props.details;
    let radars_against,
      radars_vals_against,
      bars_own_against,
      radars_vals_on,
      radars_onball,
      bars_own_against_ind,
      bars_own_onball_ind,
      bars_own_onball;

    radars_vals_against = [
      'tackle_defence',
      'tackle_offence',
      'intercept_defence',
      'intercept_offence',
      'blocked',
      'save',
      'aerial_defence',
      'aerial_offence',
    ];
    radars_vals_on = [
      'take_on',
      'crossing',
      'pass_short',
      'pass_long',
      'corner',
      'delivery',
      'scoring',
      'scoring_effort',
    ];
    bars_own_against_ind = [
      'tackle_defence',
      'tackle_offence',
      'intercept_defence',
      'intercept_offence',
      'blocked',
      'save',
      'aerial_defence',
      'aerial_offence',
    ];
    bars_own_onball_ind = [
      'take_on',
      'crossing',
      'pass_short',
      'pass_long',
      'corner',
      'delivery',
      'scoring',
      'scoring_effort',
    ];

    radars_against = createPolarCharts(
      {
        ...getPerformanceFromAnalyse(
          league_analyse,
          information,
          radars_vals_against,
        ),
        ...mean,
      },
      radars_vals_against,
    );
    radars_onball = createPolarCharts(
      {
        ...getPerformanceFromAnalyse(
          league_analyse,
          information,
          radars_vals_on,
        ),
        ...mean,
      },
      radars_vals_on,
    );
    bars_own_against = createBarChartsBenchmark(
      {
        ...getPerformanceFromAnalyse(
          league_analyse,
          information,
          bars_own_against_ind,
        ),
        ...mean,
      },
      bars_own_against_ind,
      true,
    );
    bars_own_onball = createBarChartsBenchmark(
      {
        ...getPerformanceFromAnalyse(
          league_analyse,
          information,
          bars_own_onball_ind,
        ),
        ...mean,
      },
      bars_own_onball_ind,
      true,
    );

    let radar_width = 270,
      radar_height = 320,
      radar_margin = {
        top: 25,
        right: 20,
        bottom: 20,
        left: 20,
      };

    const performanceFromAnalyse = getPerformanceFromAnalyse(
      league_analyse,
      information,
      [
        'real_tackles',
        'real_tackles_pc_',
        'real_aerial_duels',
        'real_aerial_duels_pc_',
        'real_clearances',
        'real_interception',
        'real_blocks',
        'real_free_kicks',
        'real_corners',
        'real_dribblings',
        'real_dribblings_pc_',
        'real_shots',
        'real_in_box_shots',
        'real_goal_conversion',
        'real_key_passes',
        'real_passes',
        'real_pass_pc_',
        'real_crosses',
        'real_long_balls',
        'real_through_balls',
        'real_chances_created',
      ],
    );

    return (
      <div className={`performance ${!isAccessGranted ? 'blurred' : ''}`}>
        <div className="content_group performance_ownstats">
          <div className="row">
            <div className="h4">
              <Icon name="ranking-winner" width="33px" height="28px" />
              <span>{lang.ownstats}</span>
              <span className="description">
                {lang.performance_description}
              </span>
            </div>
          </div>

          <div>
            <ColorScale
              domain={radars_against.yDomain}
              colors={COLOR_SCALE}
              benchmarkColor={BENCHMARK_COLOR}
            />
          </div>

          <div className="row">
            <div className="col-6 chart_group radar against ">
              <PolarChart
                animation
                animationDelay={1000}
                height={radar_height}
                axisTicks={[2, 4, 6, 8]}
                margin={radar_margin}
                tooltips={radars_against.tooltips}
                classNames={radars_against.classes}
                data={radars_against.items}
                xDomain={radars_against.xDomain}
                colors={COLOR_SCALE}
                interpolationType="linear-closed"
              />

              {_.chunk(bars_own_against, 4).map((bars, i) => (
                <div className="bar_chart_group row" key={i}>
                  {this._renderBarCharts(bars, 'col-3', (i + 1) * 1000)}
                </div>
              ))}
            </div>

            <div className="divider_vertical" />

            <div className="col-6 chart_group radar on ">
              <PolarChart
                animation
                animationDelay={1200}
                height={radar_height}
                axisTicks={[2, 4, 6, 8]}
                margin={radar_margin}
                tooltips={radars_onball.tooltips}
                classNames={radars_onball.classes}
                data={radars_onball.items}
                xDomain={radars_onball.xDomain}
                colors={COLOR_SCALE}
                interpolationType="linear-closed"
              />

              {_.chunk(bars_own_onball, 4).map((bars, i) => (
                <div className="bar_chart_group row" key={i}>
                  {this._renderBarCharts(bars, 'col-3', (i + 1) * 1000)}
                </div>
              ))}
            </div>
          </div>
        </div>

        <StandardStats
          performance={{ ...performanceFromAnalyse, ...mean }}
          renderBarCharts={this._renderBarCharts}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    details: state.details,
    app: state.app,
    overview: state.overview,
  };
}

export default connect(mapStateToProps)(Performance);
