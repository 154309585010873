import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';

import './compare.scss';

import { reset } from '../../actions/compare';

import Header from './layouts/header';
import Content from './layouts/content';

class Compare extends Component {
  static propTypes = {
    reset: PropTypes.func,
    params: PropTypes.object,
  };

  componentWillMount() {
    this.props.reset();
  }

  render() {
    const height = document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.innerHeight;
    const { mode, id, second } = this.props.params;
    const { app, progress } = this.props;

    /*TODO: Move to function*/
    let message = '';
    switch (progress.message) {
      case 'favorite':
        message = 'Loading your favorites...';
        break;
      case 'decode':
        message = 'Loading data...';
        break;
      case 'preset':
        message = 'Loading your presets...';
        break;
      case 'matches_performance':
        message = 'Loading all matches...';
        break;
      case 'teams_perforamnce':
        message = 'Loading team data...';
        break;
      case 'matches':
        message = 'Loading new matches...';
        break;
      case 'meta':
        message = 'Loading all players...';
        break;
      default:
        message = "Let's go...";
        break;
    }

    if (app.overviewLoading) {
      return (
        <div className="compare" style={{ height: height }}>
          <Header {...{ mode }} />
          <div
            className={`loader-small ${
              app.overviewLoading ? 'page-opacity on' : 'page-opacity'
            }`}
          >
            <div className="loader-container">
              <div className="loader" />
            </div>
            <div className="loader-message">{message}</div>
            <div className="loader-bg" />
          </div>
        </div>
      );
    }

    return (
      <div className="compare">
        <Scrollbars style={{ height }} autoHide>
          <Header {...{ mode }} />
          <Content {...{ mode, id, second }} />
        </Scrollbars>
      </div>
    );
  }
}

export default connect(
  state => ({
    app: state.app,
    compare: state.compare,
    overview: state.overview,
    progress: state.app.load_progress,
  }),
  { reset },
)(Compare);
