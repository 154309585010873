import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Player from '../player';
import './player_rank.scss';
import {
  INDICATOR_SHORTHANDS,
  SECOND_SCORE_COLORSCALE,
} from '../../../../constants';
import Lang from '../../../../lang';
import eventTracker from '../../../../helper/event-tracker';

export default class PlayersRank extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPosition: 0, // All positions,
    };
  }

  renderNavigation(upperPosition) {
    const { positions, data, split_mode } = this.props;
    const { selectedPosition } = this.state;

    const positionsWithAll = [{ id: 0, position_name: 'All' }, ...positions];

    let tooltipText;
    let tooltipTextDetail;
    switch (+split_mode) {
      case 1:
        tooltipText =
          'All matches on all positions are considered, with every player listed on the position he has spent the most playing time on. Player will only appear once anywhere.';
        tooltipTextDetail =
          'All matches on all positions are considered, with every player listed on the detail position he has spent the most playing time on. Player will only appear once at most anywhere.';
        break;
      case 2:
        tooltipText =
          'All matches on all positions within positional group are considered. Player can appear in several position groups if he meets the other filter criteria.';
        tooltipTextDetail =
          'All matches on all positions within positional group are considered, with every player listed on the detail position he has spent the most playing time on with position group. Player will only appear once anywhere. Player can appear in several position groups if he meets the other filter criteria.';
        break;
      case 3:
        tooltipText =
          'Matches for every detailed position are considered separately, but all detailed positions are given in this list. A player can appear multiple times here for every detail position if he meets the other filter criteria.';
        tooltipTextDetail =
          'Matches for every detailed positions are considered separetely and shown here. Every player can only appear once on the list.';
        break;
    }

    return (
      <ul className="player-ranking__navigation-list">
        {positionsWithAll.map(p => {
          if (data[p.id] && data[p.id].length) {
            return (
              <li
                key={p.id}
                className={`player-ranking__navigation-item ${
                  selectedPosition === p.id ? 'active' : ''
                }`}
                onClick={() => {
                  if (p.id !== 0) {
                    eventTracker.trackEvent(
                      'Rankings',
                      'Select detailed position',
                    );
                  } else {
                    eventTracker.trackEvent(
                      'Rankings',
                      'Select upper position',
                    );
                  }

                  this.props.updatePositionSelection(upperPosition.id, p.id);

                  this.setState({ selectedPosition: p.id });
                }}
                data-tooltip-content={
                  p.id === 0 ? tooltipText : tooltipTextDetail
                }
                data-tooltip-id={`tooltip_${upperPosition.id}`}
                data-type="light"
                data-place="bottom"
                data-effect="solid"
                data-delay-show={500}
                data-class="ranking-nav-tooltip"
              >
                {p.position_name}
              </li>
            );
          }
          return (
            <li
              key={p.id}
              className="player-ranking__navigation-item inactive"
              data-tooltip-content={
                p.id === 0 ? tooltipText : tooltipTextDetail
              }
              data-tooltip-id={`tooltip_${upperPosition.id}`}
              data-type="light"
              data-place="bottom"
              data-effect="solid"
              data-delay-show={500}
              data-class="ranking-nav-tooltip"
            >
              {p.position_name}
            </li>
          );
        })}
      </ul>
    );
  }

  renderPlayersList(data, mean, settings) {
    let second_score_min;
    const second_score_max = +(_.maxBy(data, p => +p[settings.colorBy]) || {})[
      settings.colorBy
    ];

    if (settings.colorBy !== 'contract_expiry_year') {
      second_score_min = +(_.minBy(data, p => +p[settings.colorBy]) || {})[
        settings.colorBy
      ];
    } else {
      second_score_min = +(_.minBy(
        data.filter(p => p.contract_expiry_year > 0),
        p => +p[settings.colorBy],
      ) || {})[settings.colorBy];
    }

    const domain = [second_score_min, second_score_max];

    return (
      <div className="player-ranking__list-container">
        <ul className="player-ranking__list">
          {data
            .slice(0, 20)
            .map((p, index) => this.renderPlayer(p, index, domain, mean))}
        </ul>
        <div className="player-ranking__list-legend">
          <span className="legend">{Lang[settings.colorBy]}</span>
          <span className="second_score">{second_score_min}</span>
          {SECOND_SCORE_COLORSCALE.map(c => (
            <span className="color" key={c} style={{ backgroundColor: c }} />
          ))}
          <span className="second_score">{second_score_max}</span>
        </div>
      </div>
    );
  }

  renderPlayer(player, index, domain, mean) {
    const { containerWidth, upperPosition, split_mode } = this.props;
    const { selectedPosition } = this.state;
    return (
      <li
        className="player-rankings__list-item"
        key={`${player.player_id}_${player.league_id}_${player.team_id}_${player.position_first_id}_${player.formation_id}`}
      >
        <Player
          player={player}
          index={index}
          domain={domain}
          containerWidth={containerWidth}
          selectedPosition={selectedPosition || upperPosition.id}
          mean={mean}
          split_mode={split_mode}
        />
      </li>
    );
  }

  render() {
    const { selectedPosition } = this.state;
    const data = this.props.data[selectedPosition] || [];
    const mean = this.props.mean[selectedPosition] || {};
    const { positions, settings, upperPosition } = this.props;

    const colorBy = settings.colorBy;

    return (
      <div className="player-ranking">
        <h4>{this.props.upperPosition.description}</h4>
        <div className="player-ranking__navigation">
          {positions.length > 1 ? this.renderNavigation(upperPosition) : null}
          <ReactTooltip id={`tooltip_${upperPosition.id}`} />
        </div>
        <div className="player-ranking__heading">
          <div className="h_rank">Rating</div>
          <div className="h_color">{INDICATOR_SHORTHANDS[colorBy]}</div>
        </div>
        {data.length ? (
          settings.rankBy.length ? (
            this.renderPlayersList(data, mean, settings)
          ) : (
            <div className="player-ranking__empty-message">
              Please select indicators
              <br /> for player rankings
            </div>
          )
        ) : (
          <div className="player-ranking__empty-message">
            No players with this position satisfy
            <br /> filter conditions
          </div>
        )}
      </div>
    );
  }
}

PlayersRank.propTypes = {
  upperPosition: PropTypes.object,
  positions: PropTypes.array,
  split_mode: PropTypes.number,
  data: PropTypes.object,
  containerWidth: PropTypes.number,
  mean: PropTypes.object,
  updatePositionSelection: PropTypes.func,
};
