import * as types from '../constants';

export default roles(
  {},

  {
    [types.ROLES](state, action) {
      const roles = action.payload?.data;

      return roles;
    },
  },
);

function roles(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}
