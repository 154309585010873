import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { push, replace } from 'react-router-redux';
import _ from 'lodash';

import Nav from '../nav';
import Icon from '../../ui/svg-icon';
import lang from '../../../lang';

import NotesFilter from './components/notes-filter';
import NotesAside from './components/notes-aside';
import NotesContent from './components/notes-content';

import { getFavorites } from '../../../actions/favorites';
import { loading } from '../../../actions/app';
import {
  fetchNotes,
  fetchNoteTags,
  selectNote,
  updateNotesDictionaries,
  updateNotesFilter,
} from '../../../actions/notes';
import { database } from '../../../constants/database';

import './notes.scss';

const getWindowHeight = (minus = 0) =>
  document.documentElement.clientHeight
    ? document.documentElement.clientHeight - minus
    : window.innerHeight - minus;

class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: getWindowHeight(),
    };

    this.handleResize = this.handleResize.bind(this);
  }

  componentWillMount() {
    const promises = [];

    if (!this.props.favorites.player.length) {
      promises.push(this.props.getFavorites({ mode: 'player' }));
    }

    if (!this.props.notes.tags.length) {
      promises.push(this.props.fetchNoteTags());
    }

    if (!this.props.notes.notes.length) {
      promises.push(this.props.fetchNotes());
    }

    if (this.props.favorites.player) {
      this.preparePlayerDictionaries(this.props);
    }
    if (this.props.notes.tags) {
      this.prepareTagsDictionaries(this.props);
    }

    if (this.props.notes.notes) {
      this.prepareNotesDictionaries(this.props);
      this.preparePlayerDictionaries(this.props);
    }

    if (this.props.player_id) {
      this.props.selectNote(null);
      this.props.updateNotesFilter({ player_id: this.props.player_id });
    } else {
      this.props.updateNotesFilter({ player_id: null });
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.favorites.player !== newProps.favorites.player) {
      this.preparePlayerDictionaries(newProps);
    }

    if (this.props.notes.tags !== newProps.notes.tags) {
      this.prepareTagsDictionaries(newProps);
    }

    if (this.props.notes.notes !== newProps.notes.notes) {
      this.prepareNotesDictionaries(newProps);
      this.preparePlayerDictionaries(newProps);
      this.props.updateNotesFilter(newProps.notes.filter);
    }

    if (this.props.app.playersMetaData !== newProps.app.playersMetaData) {
      this.preparePlayerDictionaries(newProps);
    }

    if (this.props.notes.filteredNotes !== newProps.notes.filteredNotes) {
      if (!newProps.notes.mode) {
        if (newProps.notes.filteredNotes.length) {
          this.props.selectNote(newProps.notes.filteredNotes[0]);
        } else {
          this.props.selectNote(null);
        }
      }
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  prepareNotesDictionaries(props) {
    const { notes, tags } = props.notes;
    const { player_id } = props;

    let temp_notes = notes;

    if (player_id) {
      temp_notes = temp_notes.filter(n => +n.player_id === +player_id);
    }

    const notesByKey = _.keyBy(temp_notes, 'id');
    const notesByPlayer = _.groupBy(temp_notes, 'player_id');
    const notesByUser = _.groupBy(
      temp_notes,
      note => `${note.user.firstName} ${note.user.lastName}`,
    );
    const notesByTag = {};
    tags.forEach(t => {
      const tagId = t.id;
      notesByTag[tagId] = temp_notes.filter(note =>
        note.tags.find(t => t.id === tagId),
      );
    });

    this.props.updateNotesDictionaries({
      notesByKey,
      notesByPlayer,
      notesByUser,
      notesByTag,
    });
  }

  preparePlayerDictionaries(props) {
    const { playersMetaData, teams } = props.app;
    const notes = props.notes.notes;
    const favoritePlayersRaw = props.favorites.player;

    const notFavoritePlayers = [];

    notes.forEach(n => {
      const fp = favoritePlayersRaw.find(fp => +fp.player_id === +n.player_id);
      if (!fp) {
        notFavoritePlayers.push({ player_id: n.player_id });
      }
    });

    const allPlayers = [
      ...favoritePlayersRaw,
      ..._.uniqBy(notFavoritePlayers, 'player_id'),
    ];

    const favoritePlayers = allPlayers.length
      ? allPlayers.map(fp => {
          const data = playersMetaData[fp.player_id];
          const teamId = _.get(data, 'team_id', null);
          const link = `${fp.player_id}`;

          return {
            first_name: _.get(data, 'first_name', null),
            last_name: _.get(data, 'last_name', null),
            short_name: _.get(data, 'short_name', null),
            link: `/details/player/${link}/`,
            team_id: teamId,
            team_name: _.get(teams, `[${teamId}].team_name`, ''),
            player_id: fp.player_id,
            player_img: `${database.assets}players/${fp.player_id}.jpg`,
            team_img: `${database.assets}teams/${teamId}.jpg`,
          };
        })
      : [];

    const favoritePlayersByTeam = _.groupBy(favoritePlayers, 'team_id');
    const favoritePlayersByKey = _.keyBy(favoritePlayers, 'player_id');
    const playersOptions = favoritePlayers.map(fp => {
      return {
        value: fp.player_id,
        label: `${fp.first_name} ${fp.last_name}`,
        team: fp.team_name,
        player_img: fp.player_img,
        team_img: fp.team_img,
        last_name: fp.last_name || fp.first_name || fp.short_name,
      };
    });

    const teamsByKey = {};
    _.forOwn(favoritePlayersByTeam, (value, team_id) => {
      teamsByKey[team_id] = {
        team_name: _.get(teams, `[${team_id}].team_name`, ''),
        team_id,
        team_img: `${database.assets}teams/${team_id}.jpg`,
      };
    });

    this.props.updateNotesDictionaries({
      favoritePlayersByTeam,
      favoritePlayersByKey,
      teamsByKey,
      playersOptions,
    });
  }

  prepareTagsDictionaries(props) {
    const { tags } = props.notes;
    const tagsByKey = _.groupBy(tags, 'id');
    const tagsOptions = tags.map(t => ({ value: t.id, label: t.tag }));

    this.props.updateNotesDictionaries({
      tagsByKey,
      tagsOptions,
    });
  }

  handleResize() {
    let minus = 0;

    const header = document.querySelector('.header-container');
    const nav = document.querySelector('.nav-container');
    const headerHeight = header ? header.offsetHeight : 0;
    const navHeight = nav ? nav.offsetHeight : 0;
    minus += headerHeight + navHeight + 15;
    const height = getWindowHeight(minus);

    this.setState({ height });
  }

  render() {
    const { height } = this.state;
    const { player_id, inactive } = this.props;

    if (!player_id) {
      return (
        <div className="favorites notes">
          <div className="header-container">
            <div className="container">
              <div className="row">
                <div className="col-4">
                  <div className="info-logo">
                    <Icon name="favorite" width="20px" height="20px" />
                    <span>{lang.favorite_players}</span>
                  </div>
                </div>
                <div className="col-4">&nbsp;</div>
                <div className="col-4">
                  <div className="btn pull-right">
                    <Link to="/overview/player" className="icon-close" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Nav />
          <div className="content-container notes-content-container">
            <div className="container notes-desktop-container">
              <div className="notes-desktop-body">
                <NotesFilter height={height} />
                <NotesAside height={height} />
                <NotesContent height={height} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={`favorites notes ${inactive ? 'blurred' : ''}`}>
        <div className="notes-content-container player-notes">
          <div className="container notes-desktop-container">
            <div className="notes-desktop-body">
              <NotesFilter height={height} player_id={player_id} />
              <NotesAside height={height} player_id={player_id} />
              <NotesContent height={height} player_id={player_id} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    app: state.app,
    favorites: state.app.favorites,
    user: state.auth.user,
    notes: state.notes,
    auth: state.auth,
    account: state,
  }),
  {
    getFavorites,
    updateNotesDictionaries,
    fetchNoteTags,
    fetchNotes,
    loading,
    replace,
    push,
    updateNotesFilter,
    selectNote,
  },
)(Notes);
