import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import * as ReactDOM from 'react-dom';
import { browserHistory, Router } from 'react-router';
import { Provider } from 'react-redux';
import axios from 'axios';
import MediaQuery from 'react-responsive';
import initReactFastclick from 'react-fastclick';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ReactModal from 'react-modal';

import { GlobalStyle } from './styles/global-styles';

import desktop from './routes/desktop';
import mobile from './routes/mobile';

// Load store and initial state
import configureAppStore from './store/store';
import initialState from './store/initialState';
import lang from './lang';

// Init store
export const store = configureAppStore(initialState);

// Init container div for app
const MOUNT_NODE = document.getElementById('app');

ReactModal.setAppElement('#app');

// Init fastclick lib after load
window.addEventListener('load', () => {
  // FastClick.attach(document.body);
  initReactFastclick();
});

// Init axios config
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;

ReactDOM.render(
  <Provider store={store}>
    <MediaQuery minWidth={769}>
      <Router history={browserHistory} routes={desktop(store)} />
    </MediaQuery>
    <MediaQuery maxWidth={768}>
      <Router history={browserHistory} routes={mobile(store)} />
    </MediaQuery>
    <ReactTooltip variant="light" id="TooltipHelp" className="tooltip-popup" />
    <ReactTooltip
      id="Indicator"
      className="indicator-popup"
      variant="light"
      clickable
      /*effect="solid"*/
      render={({ content: indicator, activeAnchor }) => {
        const icon =
          indicator !== 'corner'
            ? `epi_images epi_images-${indicator}`
            : 'epi_images epi_images-crossing';

        return (
          <div>
            <div>
              <div className={icon} />
              <div className="epi_description">
                <h4>{lang[indicator]}</h4>
                {lang[`${indicator}_desc_short`]}
              </div>
            </div>
          </div>
        );
      }}
    >
      Test
    </ReactTooltip>
    <GlobalStyle />
  </Provider>,
  MOUNT_NODE,
);
