import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Image from '../../ui/image';
import lang from '../../../lang';

import { setActivePointData } from '../../../actions/overview';
import { database } from '../../../constants/database';

class Tooltip extends Component {
  static propTypes = {
    setActivePointData: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    const { setActivePointData } = this.props;

    setActivePointData({
      mode: 'match',
      data: null,
    });
  }

  render() {
    const { activePointData, filteredData } = this.props;

    const { teams, leagues } = this.props;
    const activeMatch = activePointData.match;

    if (!activeMatch) return null;

    const { match_id } = activeMatch;

    let item = filteredData.find(match => match.match_id == match_id);

    if (!item) return null;
    let id = match_id;

    const emblemUrl = item.league_id
      ? `${database.assets}leagues/${item.league_id}.png`
      : '';

    return (
      <div>
        <div className="row match-tooltip">
          <div className="col-3">
            <Image
              src={
                item.team_id_home
                  ? `${database.assets}teams/${item.team_id_home}.jpg`
                  : ''
              }
              aspectRatio={0.769}
              backgroundColor="white"
            />
          </div>

          <div className="col-6">
            <div className="tooltip__content_header">
              <div className="tooltip__content_header-container">
                <div className="btn" onClick={() => this.onClose()}>
                  <div className="icon-close" />
                </div>
              </div>
            </div>

            <div className="row tooltip__content_name">
              <div className="lastname">
                {_.get(teams, `${item.team_id_home}.short_club_name`, null)}
              </div>
            </div>

            <div className="row tooltip__content_stats">
              <div className="goals">
                {item.homegoals} :{item.awaygoals}
              </div>
              <div className="team">
                {item.league_id ? (
                  <Image src={emblemUrl} aspectRatio={0.769} />
                ) : (
                  ''
                )}{' '}
                {item.league_id && leagues
                  ? leagues[item.league_id]
                    ? leagues[item.league_id].name
                    : ''
                  : ''}
              </div>
            </div>

            <div className="row tooltip__content_name">
              <div className="lastname">
                {_.get(teams, `${item.team_id_away}.short_club_name`, null)}
              </div>
            </div>
          </div>

          <div className="col-3 noSpacing away">
            <Image
              src={
                item.team_id_away
                  ? `${database.assets}teams/${item.team_id_away}.jpg`
                  : ''
              }
              aspectRatio={0.769}
              backgroundColor="white"
            />
          </div>
        </div>

        <div className="row tooltip__content_actionbar">
          <Link
            to={`/details/match/${`${id}/${item.league_id}/${item.season_id}`}/summary`}
            className="col-12 callAction"
          >
            <span className="icon-more" />
            {lang.details}
          </Link>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    teams: state.app.teams,
    leagues: state.app.leagues,
    positions: state.app.positions,
    season_current: state.app.season_current,
    playersMetaData: state.app.playersMetaData,
    filterComponents: state.overview.player,
    favorites: state.app.favorites,
    activePointData: state.overview.activePointData,
  }),
  { setActivePointData },
)(Tooltip);
