import lang from '../lang';

export * from './api';

export const LOAD_DATA = 'LOAD_DATA';
export const LOAD_DATA_PROGRESS = 'LOAD_DATA_PROGRESS';
export const LOAD_PLAYERS_PERFORMANCE = 'LOAD_PLAYERS_PERFORMANCE';
export const CHANGE_PLAYERS_GROUP_BY = 'CHANGE_PLAYERS_GROUP_BY';
export const LOAD_TEAMS_PERFORMANCE = 'LOAD_TEAMS_PERFORMANCE';
export const FETCH_DATA_FOR_OVERVIEW = 'FETCH_DATA_FOR_OVERVIEW';
export const OVERVIEW_LOADING = 'OVERVIEW_LOADING';
export const LOADING = 'LOADING';

export const FETCH_DATA_FOR_WELCOME = 'FETCH_DATA_FOR_WELCOME';
export const START_WELCOME_LOAD = 'START_WELCOME_LOAD';

export const LOAD_DATA_OVERVIEW = 'LOAD_DATA_OVERVIEW';
export const FILTER_DATA = 'FILTER_DATA';
export const SORT_TABLE = 'SORT_TABLE';
export const UPDATE_OVERVIEW_POSITIONS_FILTER =
  'UPDATE_OVERVIEW_POSITIONS_FILTER';
export const CHANGE_SLIDER_VAL = 'CHANGE_SLIDER_VAL';
export const CHANGE_SLIDER_HELPER_VAL = 'CHANGE_SLIDER_HELPER_VAL';
export const CHANGE_LIST_HELPER_VAL = 'CHANGE_LIST_HELPER_VAL';
export const CHANGE_SCATTERPLOT_DOMAIN = 'CHANGE_SCATTERPLOT_DOMAIN';
export const CHANGE_SCATTERPLOT_LEGEND = 'CHANGE_SCATTERPLOT_LEGEND';
export const CHANGE_SCATTERPLOT_WIDTH = 'CHANGE_SCATTERPLOT_WIDTH';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SELECTION_LASSO = 'SELECTION_LASSO';
/*export const TOGGLE_FILTER_COLLAPSE = 'TOGGLE_FILTER_COLLAPSE';*/
export const TOGGLE_FILTER_SWITCHER = 'TOGGLE_FILTER_SWITCHER';
export const CHANGE_MAX_OF_SLIDER_FILTER = 'CHANGE_MAX_OF_SLIDER_FILTER';
export const CHANGE_SLIDER_MIN_MAX = 'CHANGE_SLIDER_MIN_MAX';
export const SET_ACTIVE_POINT_DATA = 'SET_ACTIVE_POINT_DATA';
export const OVERVIEW = {
  CHANGE_LIST_OPEN: 'OVERVIEW:CHANGE_LIST_OPEN',
  CHANGE_LIST_ACTIVE: 'OVERVIEW:CHANGE_LIST_ACTIVE',
  ADD_TO_FAVORITE_LEAGUES_LIST: 'OVERVIEW:ADD_TO_FAVORITE_LEAGUES_LIST',
  REMOVE_FROM_FAVORITE_LEAGUES_LIST:
    'OVERVIEW:REMOVE_FROM_FAVORITE_LEAGUES_LIST',
};

export const LOAD_DATA_DETAILS = 'LOAD_DATA_DETAILS';
export const CHANGE_SEASON = 'CHANGE_SEASON';
export const CHANGE_LEAGUE = 'CHANGE_LEAGUE';
export const CHANGE_FORMATION = 'CHANGE_FORMATION';
export const CHANGE_POSITION = 'CHANGE_POSITION';
export const CHANGE_LEAGUE_COMPARISON_MATCHDAYS =
  'CHANGE_LEAGUE_COMPARISON_MATCHDAYS';

export const LOAD_SELECT_COMPARE = 'LOAD_SELECT_COMPARE';
export const REMOVE_COMPARE = 'REMOVE_COMPARE';
export const ADD_COMPARE = 'ADD_COMPARE';
export const RESET_COMPARE = 'RESET_COMPARE';
export const CHANGE_EDIT = 'CHANGE_EDIT';

export const AUTH_LOGIN_REQUEST_SEND = 'AUTH_LOGIN_REQUEST_SEND';
export const AUTH_LOGIN_REQUEST_SUCCESS = 'AUTH_LOGIN_REQUEST_SUCCESS';
export const AUTH_LOGIN_REQUEST_ERROR = 'AUTH_LOGIN_REQUEST_ERROR';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CHECK = 'AUTH_CHECK';
export const SET_PRIVACY = 'SET_PRIVACY';

export const PASSWORD_RESET = 'PASSWORD_RESET';

export const GET_GROUPS = 'GET_GROUPS';
export const ADD_GROUP = 'ADD_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';

export const GET_USER_TYPES = 'GET_USER_TYPES';

export const LOAD_MATCH_DETAILS = 'LOAD_MATCH_DETAILS';

export const ADD_TO_FAVORITE = 'ADD_TO_FAVORITE';
export const REMOVE_FROM_FAVORITE = 'REMOVE_FROM_FAVORITE';

export const GET_FAVORITES = 'GET_FAVORITES';
export const CHANGE_FAVORITES_FILTER = 'CHANGE_FAVORITES_FILTER';
export const CHANGE_FAVORITES_CATEGORY = 'CHANGE_FAVORITES_CATEGORY';

export const LOAD_QUOTE = 'LOAD_QUOTE';

export const DATA_ERROR_MODAL = 'DATA_ERROR_MODAL';
export const LOAD_PLAYER_DATA_FIELD = 'LOAD_PLAYER_DATA_FIELD';

/* Shadow teams */
export const FETCH_SHADOW_TEAMS = 'FETCH_SHADOW_TEAMS';
export const CREATE_SHADOW_TEAM = 'CREATE_SHADOW_TEAM';
export const UPDATE_SHADOW_TEAM = 'UPDATE_SHADOW_TEAM';
export const COPY_SHADOW_TEAM = 'COPY_SHADOW_TEAM';
export const DELETE_SHADOW_TEAM = 'DELETE_SHADOW_TEAM';
export const OPEN_SHADOW_TAB = 'OPEN_SHADOW_TAB';
export const CLOSE_SHADOW_TAB = 'CLOSE_SHADOW_TAB';
export const FETCH_SHADOW_TABS = 'FETCH_SHADOW_TABS';

/* Filter presets */
export const ADD_FILTER_PRESET = 'ADD_FILTER_PRESET';
export const REMOVE_FILTER_PRESET = 'REMOVE_FILTER_PRESET';
export const FETCH_FILTER_PRESETS = 'FETCH_FILTER_PRESETS';
export const SET_FILTER_PRESET = 'SET_FILTER_PRESET';

/* NOTES */
export const FETCH_NOTES = 'FETCH_NOTES';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const CREATE_NOTE = 'CREATE_NOTE';
export const UPDATE_NOTE_FILTER = 'UPDATE_NOTE_FILTER';
export const RESET_NOTE_FILTER = 'RESET_NOTE_FILTER';
export const UPDATE_NOTE_DICTIONARIES = 'UPDATE_NOTE_DICTIONARIES';
export const SELECT_NOTE = 'SELECT_NOTE';
export const FETCH_NOTE_TAGS = 'FETCH_NOTE_TAGS';
export const CREATE_NOTE_TAG = 'CREATE_NOTE_TAG';
export const SET_NOTE_MODE = 'SET_NOTE_MODE';

/* FAVORITE LEAGUES */
export const FETCH_FAVORITE_LEAGUES = 'FETCH_FAVORITE_LEAGUES';
export const ADD_FAVORITE_LEAGUE = 'ADD_FAVORITE_LEAGUE';
export const REMOVE_FAVORITE_LEAGUE = 'REMOVE_FAVORITE_LEAGUE';

export const SET_DOMESTIC_LEAGUES = 'SET_DOMESTIC_LEAGUES';
export const CHANGE_POSITION_SPLIT_MODE = 'CHANGE_POSITION_SPLIT_MODE';

export const CHANGE_OVERVIEW_RANKINGS_SETTINGS_RANK =
  'CHANGE_OVERVIEW_RANKINGS_SETTINGS_RANK';
export const CHANGE_OVERVIEW_RANKINGS_SETTINGS_COLOR =
  'CHANGE_OVERVIEW_RANKINGS_SETTINGS_COLOR';

export const DEFAULT_SEASON = 2019;
export const NO_RATING_UNDER_MINS = 20;

export const FETCH_SEARCH = 'FETCH_SEARCH';
export const START_FETCH_SEARCH = 'START_FETCH_SEARCH';
export const RESET_SEARCH = 'RESET_SEARCH';

export const FAVORITE_CATEGORIES = [
  {
    val: 0,
    label: lang.none,
  },
  {
    val: 1,
    label: lang.perspective,
  },
  {
    val: 2,
    label: lang.focus,
  },
  {
    val: 3,
    label: lang.immediately,
  },
];

export const EPI_SKILLIMPORTANCE_STRENGTHS_BY_POS = [
  {},
  {
    pos: 'GK',
    skills: {
      blocked: 1,
      claim: 2,
      pass_short: 3,
      keeper_throw: 4,
      pass_long: 5,
      reception: 6,
      intercept_defence: 7,
      delivery: 8,
    },
  },
  {
    pos: 'CB',
    skills: {
      tackle_defence: 1,
      intercept_defence: 2,
      aerial_defence: 3,
      pass_short: 4,
      delivery: 5,
      pass_long: 6,
      aerial_offence: 7,
      take_on: 8,
    },
  },
  {
    pos: 'DW',
    skills: {
      tackle_defence: 1,
      intercept_defence: 2,
      take_on: 3,
      delivery: 4,
      crossing: 5,
      pass_short: 6,
      reception: 7,
      pass_long: 8,
      scoring_effort: 9,
      scoring: 10,
    },
  },
  {
    pos: 'DM',
    skills: {
      intercept_defence: 1,
      tackle_defence: 2,
      pass_short: 3,
      delivery: 4,
      pass_long: 5,
      take_on: 6,
      scoring_effort: 7,
      scoring: 8,
      aerial_defence: 9,
    },
  },
  {
    pos: 'CM',
    skills: {
      intercept_defence: 1,
      intercept_offence: 2,
      pass_short: 3,
      delivery: 4,
      take_on: 5,
      reception: 6,
      pass_long: 7,
      tackle_defence: 8,
      tackle_offence: 9,
      scoring_effort: 10,
      scoring: 11,
      aerial_defence: 12,
    },
  },
  {
    pos: 'AM',
    skills: {
      delivery: 1,
      take_on: 2,
      scoring_effort: 3,
      scoring: 4,
      reception: 5,
      pass_short: 6,
      intercept_offence: 7,
      tackle_offence: 8,
    },
  },
  {
    pos: 'OW',
    skills: {
      delivery: 1,
      take_on: 2,
      scoring_effort: 3,
      scoring: 4,
      reception: 5,
      crossing: 6,
      pass_short: 7,
      intercept_offence: 8,
      tackle_offence: 9,
    },
  },
  {
    pos: 'FW',
    skills: {
      scoring_effort: 1,
      scoring: 2,
      reception: 3,
      aerial_offence: 4,
      take_on: 5,
      delivery: 6,
      intercept_offence: 7,
      tackle_offence: 8,
    },
  },
];

export const EPI_SKILLIMPORTANCE_WEAKNESSES_BY_POS = [
  {},
  {
    pos: 'GK',
    skills: {
      blocked: 1,
      claim: 2,
      pass_short: 3,
      keeper_throw: 4,
      pass_long: 5,
      reception: 6,
      intercept_defence: 7,
    },
  },
  {
    pos: 'CB',
    skills: {
      tackle_defence: 1,
      intercept_defence: 2,
      aerial_defence: 3,
      pass_short: 4,
      delivery: 5,
      pass_long: 6,
      aerial_offence: 7,
    },
  },
  {
    pos: 'DW',
    skills: {
      tackle_defence: 1,
      intercept_defence: 2,
      take_on: 3,
      delivery: 4,
      crossing: 5,
      pass_short: 6,
      reception: 7,
    },
  },
  {
    pos: 'DM',
    skills: {
      intercept_defence: 1,
      tackle_defence: 2,
      pass_short: 3,
      delivery: 4,
      pass_long: 5,
      take_on: 6,
      scoring_effort: 7,
      scoring: 8,
      aerial_defence: 9,
    },
  },
  {
    pos: 'CM',
    skills: {
      intercept_defence: 1,
      intercept_offence: 2,
      pass_short: 3,
      delivery: 4,
      take_on: 5,
      reception: 6,
      pass_long: 7,
      tackle_defence: 8,
      tackle_offence: 9,
      scoring_effort: 10,
      scoring: 11,
      aerial_defence: 12,
    },
  },
  {
    pos: 'AM',
    skills: {
      delivery: 1,
      take_on: 2,
      scoring_effort: 3,
      scoring: 4,
      reception: 5,
      pass_short: 6,
      intercept_offence: 7,
      tackle_offence: 8,
    },
  },
  {
    pos: 'OW',
    skills: {
      delivery: 1,
      take_on: 2,
      scoring_effort: 3,
      scoring: 4,
      reception: 5,
      crossing: 6,
      pass_short: 7,
      intercept_offence: 8,
      tackle_offence: 9,
    },
  },
  {
    pos: 'FW',
    skills: {
      scoring_effort: 1,
      scoring: 2,
      reception: 3,
      aerial_offence: 4,
      take_on: 5,
      delivery: 6,
      intercept_offence: 7,
      tackle_offence: 8,
    },
  },
];

export const COLOR_SCALE = [
  '#6D362D',
  '#7A3C43',
  '#80455B',
  '#7D5374',
  '#716389',
  '#5A7598',
  '#3C859E',
  '#1B949A',
  '#1DA18E',
  '#44AB7A',
  '#6DB465',
];
export const MINUTES_COLOR_SCALE = [
  '#c3adae',
  '#bfc0cf',
  '#bac4d2',
  '#b6c5d3',
  '#adcdd5',
  '#aaced4',
  '#aad0d4',
  '#aad3d1',
  '#afd7cb',
  '#b4d9c7',
  '#c2ddbf',
];
export const SECOND_SCORE_COLORSCALE = [
  '#a397b2',
  '#a9a8bc',
  '#aebcc8',
  '#b6c7c9',
  '#bed3ca',
  '#dae3c5',
  '#f7f1c0',
];
export const BENCHMARK_COLOR = '#0c5b48';

export const GROUP_PLAYERS = [
  'position_2',
  'position_1',
  'position_3',
  'formation_position_1',
  'formation_position_2',
  'formation_position_3',
];

export const INDICATOR_SHORTHANDS = {
  seasonAge: 'Age',
  season_mins: 'Mins',
  contract_expiry_year: 'Exp',
  season_rating: 'Rat',
  marketvalue: 'Val',
  stability: 'Sta',
  defence: 'Def',
  offence: 'Off',
  contribution_defence: '%de',
  contribution_offence: '%of',
  contribution_pass: '%pa',
  tackle_defence: 'Tdf',
  tackle_offence: 'Tof',
  intercept_defence: 'Idf',
  intercept_offence: 'Iof',
  blocked: 'BPA',
  save: 'BSH',
  aerial_offence: 'Aof',
  aerial_defence: 'Adf',
  take_on: 'Ton',
  crossing: 'Cro',
  pass_short: 'Psh',
  pass_long: 'Plo',
  reception: 'Rec',
  delivery: 'Del',
  scoring: 'Fin',
  scoring_effort: 'Sho',
};

export const ROLES = 'ROLES';

export const UPDATE_USER = 'USERS:UPDATE_USER';
export const DELETE_USER = 'USERS:DELETE_USER';
export const ADD_USER = 'USERS:ADD_USER';
export const GET_USERS = 'USERS:GET_USERS';
