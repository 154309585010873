import React from 'react';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';

import './page-loader.scss';

const PageLoader = props => {
  const loader = useSelector(state => state.loader);

  return createPortal(
    <div className={`page-loader ${loader.length ? 'loading' : ''}`}>
      <div className="loader" />
    </div>,
    document.body,
  );
};

PageLoader.propTypes = {};

export default PageLoader;
